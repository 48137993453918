import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { AJOList, AJOState } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'preact/hooks';
import { JSXInternal } from 'preact/src/jsx';
import { CaretDownFill, CaretRightFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import AppManager from 'src/commun/AppManager';
import { Spinner } from 'src/commun/component/Spinner';
import { get, post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import { PerfectTable } from 'src/commun/table/PerfectTable';
import Toast from 'src/commun/toast/Toast';
import Utils from 'src/commun/Utils';
import { BasePage } from 'src/component/BasePage';
import SelectClientModal from 'src/component/client/SelectClientModal';
import { TableHoraireDevisStatList } from 'src/component/horaire/TableHoraireDevisStatList';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';
import SelectProductModal from 'src/component/product/SelectProductModal';
import { AddIcon } from 'src/icons/AddIcon';
import { SearchIcon } from 'src/icons/SearchIcon';
import { CloseDevisModal } from 'src/modal/CloseDevisModal';
import { AddElemClientModal } from 'src/modal/vendor/AddElemClientModal';
import Devis from 'src/object/Devis';
import Client from 'src/object/profile/Client';
import Product from 'src/object/tag/Product';
import { SERVER_URL } from 'src/utils/Constant';

interface IExportDevisHorraireButtonProps {
    devis: Devis;
}

const ExportButton = (props: IExportDevisHorraireButtonProps) => {
    const { devis } = props;

    const [loading, setLoading] = useState(false);

    let res = undefined;
    if (loading) {
        res = <Spinner color="blue" />;
    } else {
        res = (
            <Button
                variant="contained"
                color="primary"
                className="!pl-3 gap-2"
                onClick={() => {
                    setLoading(true);
                    post('/devis/export/stat', {
                        id: devis.getAjoIdentifier(),
                    }).then((res: any) => {
                        setLoading(false);
                        if (Result.isSuccess(res)) {
                            const link = document.createElement('a');
                            link.href = SERVER_URL + '/' + res['result']['where'];
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    });
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path
                        fillRule="evenodd"
                        d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                        clipRule="evenodd"
                    />
                </svg>
                Exporter
            </Button>
        );
    }
    return res;
};

const CloseButton = (props: IExportDevisHorraireButtonProps) => {
    const { devis } = props;

    const [show, setShow] = useState(false);

    return (
        <Box>
            <Button
                variant="contained"
                color="error"
                className="!pl-3 gap-2"
                onClick={() => {
                    setShow(true);
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path
                        fillRule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                        clipRule="evenodd"
                    />
                </svg>
                Fermer
            </Button>
            <CloseDevisModal
                devis={devis}
                show={show}
                handleClose={() => {
                    setShow(false);
                }}
            />
        </Box>
    );
};

export const PricePage = () => {
    const [tab, setTab] = useState('progress');

    const [stateDevisList, setStateDevisList] = useState(new AJOState(new AJOList<Devis>(Devis)));
    stateDevisList.setUpdate(setStateDevisList);
    AppManager.updater().add(stateDevisList);

    const [search, setSearch] = useState('');

    const [loadingProgress, setLoadingProgress] = useState(true);
    const [loadingClose, setLoadingClose] = useState(true);
    const [startLoadingProgress, setStartLoadingProgress] = useState(false);
    const [startLoadingClose, setStartLoadingClose] = useState(false);

    const fetchProgress = () => {
        if (!startLoadingProgress) {
            setStartLoadingProgress(true);
            get('/devis/list/progress', {}, () => {
                const devisList = stateDevisList.get()!.getList();
                for (const devis of devisList) {
                    devis.paperList.setList([]);
                    devis.timerList.setList([]);
                }
            }).then((res: any) => {
                setLoadingProgress(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, stateDevisList, true);
                }
            });
        }
    };

    const fetchClose = () => {
        if (!startLoadingClose) {
            setStartLoadingClose(true);
            get('/devis/list/close', {}, () => {
                const devisList = stateDevisList.get()!.getList();
                for (const devis of devisList) {
                    devis.paperList.setList([]);
                    devis.timerList.setList([]);
                }
            }).then((res: any) => {
                setLoadingClose(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, stateDevisList, true);
                }
            });
        }
    };

    let list = stateDevisList.get()!.getList();
    list = list.filter((d: Devis) => {
        let res: boolean = false;
        if (tab === 'progress' && d.close.get() !== true) {
            res = true;
        }
        if (tab === 'close' && d.close.get() === true) {
            res = true;
        }
        return res;
    });

    useEffect(() => {
        fetchProgress();
    }, []);

    const [selectDevis, setSelectDevis] = useState<Devis | null>(null);
    const [showAddDevis, setShowAddDevis] = useState(false);

    const [selectOffre, setSelectOffre] = useState<Product[]>([]);
    const [showSelectOffre, setShowSelectOffre] = useState(false);

    const [selectClient, setSelectClient] = useState<Client[]>([]);
    const [showSelectClient, setShowSelectClient] = useState(false);

    const cols = [
        {
            canSort: true,
            sort: 1,
            funSort: (d1: Devis, d2: Devis) => {
                return d1.getName().localeCompare(d2.getName());
            },
            hasFilter: true,
            funFilter: (d: Devis) => {
                return Utils.isSearch(search, d.getName());
            },
            name: 'Nom',
            render: (devis: Devis) => {
                return (
                    <Box className="flex flex-row gap-2">
                        <div
                            className="flex items-center p-1.5 gap-1"
                            onClick={() => {
                                // Check if selectList of table contain there is elem
                                if (selectDevis?.getAjoIdentifier() === devis.getAjoIdentifier()) {
                                    setSelectDevis(null);
                                } else {
                                    setSelectDevis(devis);
                                }
                                AppManager.updater().makeUpdate();
                            }}
                        >
                            {selectDevis?.getAjoIdentifier() === devis.getAjoIdentifier() ? (
                                <CaretDownFill />
                            ) : (
                                <CaretRightFill />
                            )}
                        </div>
                        <Link to={'/devis/' + (devis.getAjoIdentifier() ?? '')}>
                            <Typography className={'!text-' + devis.getColorSpec() + '-700'} variant="h6">
                                {devis.getName()}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            name: 'Client',
            hasFilter: selectClient.length > 0,
            canFilter: true,
            funFilter: (devis: Devis) => {
                let can = false;
                let i = 0;
                while (i < selectClient.length && !can) {
                    can = devis.client.get()?.getAjoIdentifier() === selectClient[i].getAjoIdentifier();
                    i++;
                }
                return can;
            },
            onFilterPress: () => {
                setShowSelectClient(true);
            },
            onDeletePress: () => {
                setSelectClient([]);
            },
            render: (devis: Devis) => {
                return (
                    <Link to={'/client/' + (devis.client.get()?.getAjoIdentifier() ?? '')}>
                        <Card
                            className={'!bg-' + devis.getColorSpec() + '-200'}
                            sx={{
                                p: 1,
                            }}
                        >
                            <Typography>{devis.client.get()?.name.get() ?? ''}</Typography>
                        </Card>
                    </Link>
                );
            },
        },
        {
            name: 'Offre',
            hasFilter: selectOffre.length > 0,
            canFilter: true,
            funFilter: (devis: Devis) => {
                let can = false;
                let i = 0;
                while (i < selectOffre.length && !can) {
                    can = devis.product.get()?.getAjoIdentifier() === selectOffre[i].getAjoIdentifier();
                    i++;
                }
                return can;
            },
            onFilterPress: () => {
                setShowSelectOffre(true);
            },
            onDeletePress: () => {
                setSelectOffre([]);
            },
            render: (devis: Devis) => {
                return (
                    <div className="block">
                        <div
                            className={
                                'block text-white rounded-lg text-sm py-1 px-2 bg-' + devis.product.get()?.getColor()
                            }
                        >
                            <Typography>{devis.product.get()?.name.get() ?? ''}</Typography>
                        </div>
                    </div>
                );
            },
        },
    ];

    cols.push({
        name: 'Action',
        render: (devis: Devis) => {
            return (
                <div className="flex flex-row gap-3">
                    <ExportButton devis={devis} />
                    {tab === 'progress' && <CloseButton devis={devis} />}
                </div>
            );
        },
    } as any);

    return (
        <BasePage className="flex-col flex items-center">
            <DashboardNavBar
                tab={tab}
                onTab={(tab: string) => {
                    if (tab === 'progress') {
                        fetchProgress();
                    }
                    if (tab === 'close') {
                        fetchClose();
                    }
                    setTab(tab);
                }}
                tabList={[
                    { value: 'progress', label: 'En cours' },
                    { value: 'close', label: 'Fermer' },
                ]}
            />
            <Container maxWidth={false}>
                <Box sx={{ mb: 3, mt: 3 }}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            m: -1,
                        }}
                    >
                        <Typography sx={{ m: 1 }} variant="h4">
                            {tab === 'progress' ? 'Prix de vente en cours' : 'Prix de vente fermé'}
                        </Typography>
                        <Box sx={{ m: 1 }}>
                            <Button
                                color="primary"
                                variant="contained"
                                className="!pl-3 gap-2"
                                onClick={() => {
                                    setShowAddDevis(true);
                                }}
                            >
                                <AddIcon />
                                Ajouter un prix de vente
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Card>
                            <CardContent className="">
                                <Box className="flex  items-center flex-row">
                                    <Box sx={{ maxWidth: 700 }}>
                                        <TextField
                                            fullWidth
                                            value={search}
                                            onChange={(e) => {
                                                setSearch((e.target as any).value);
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SvgIcon color="action" fontSize="small">
                                                            <SearchIcon />
                                                        </SvgIcon>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            placeholder="Rechercher un prix de vente"
                                            variant="outlined"
                                        />
                                    </Box>
                                    <Box className="flex-1"></Box>
                                    <Box></Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
                <Card
                    sx={{
                        mb: 3,
                    }}
                >
                    <PerfectTable
                        bonusTrClass={(devis: Devis) => {
                            return (
                                'bg-' + devis.getColorSpec() + '-100 ' + ' hover:!bg-' + devis.getColorSpec() + '-200'
                            );
                        }}
                        columns={cols as any}
                        nothing={
                            <Box>
                                <Typography variant="h6" className="text-center">
                                    {tab === 'progress' ? 'Aucun prix de vente en cours' : 'Aucun prix de vente fermé'}
                                </Typography>
                            </Box>
                        }
                        list={list}
                        loading={tab === 'progress' ? loadingProgress : loadingClose}
                        bonusRender={(elem: Devis) => {
                            let res: JSXInternal.Element = <></>;
                            if (elem.getAjoIdentifier() === selectDevis?.getAjoIdentifier()) {
                                selectDevis?.getStat(() => {});
                                res = (
                                    <TableHoraireDevisStatList
                                        loading={elem.loadStat}
                                        color={elem.getColorSpec()}
                                        list={[elem]}
                                    />
                                );
                            }
                            return res;
                        }}
                    />
                </Card>
            </Container>

            <div className="absolute">
                {showAddDevis && (
                    <AddElemClientModal
                        show={showAddDevis}
                        mode="devis"
                        clientBase={null}
                        templateList={[]}
                        handleClose={() => {
                            setShowAddDevis(false);
                        }}
                    />
                )}
            </div>
            <SelectProductModal
                onClose={() => {
                    setShowSelectOffre(false);
                }}
                open={showSelectOffre}
                selectList={selectOffre}
                onSelectList={(l) => {
                    setSelectOffre([...l]);
                }}
            />
            <SelectClientModal
                onClose={() => {
                    setShowSelectClient(false);
                }}
                open={showSelectClient}
                selectList={selectClient}
                onSelectList={(l) => {
                    setSelectClient([...l]);
                }}
            />
        </BasePage>
    );
};
