import Prestation from '../object/profile/Prestation';
import { PrestationView } from './PrestationView';

export interface IPrestationListProps {
    prestationList: Prestation[];
    checkView: boolean;

    checkList?: Prestation[];
    onCheck?: (p: Prestation) => void;
}
export const PrestationList: React.FC<IPrestationListProps> = (props) => {
    const { prestationList, checkList = [], onCheck = undefined, checkView } = props;
    return (
        <div className="flex flex-row gap-2 flex-wrap">
            {prestationList.map((prestation, index) => {
                return (
                    <div key={'prestation-' + index}>
                        <PrestationView
                            checked={
                                checkList.find((p) => p.getAjoIdentifier() === prestation.getAjoIdentifier()) !==
                                undefined
                            }
                            onCheck={onCheck}
                            prestation={prestation}
                            checkMode={checkView}
                        />
                    </div>
                );
            })}
        </div>
    );
};
