import { Box, Typography, Container, Button, Card, CardContent } from '@mui/material';
import { useState } from 'react';
import { InsertPaperModal } from 'src/modal/vendor/InsertPaperModal';
import AppManager from '../../commun/AppManager';
import { EuroSpan } from '../../commun/component/EuroSpan';
import { PourcentSpan } from '../../commun/component/PourcentSpan';
import { AddIcon } from '../../icons/AddIcon';
import { BinIcon } from '../../icons/BinIcon';
import { EditIcon } from '../../icons/EditIcon';
import { StatistiqueIcon } from '../../icons/StatistiqueIcon';
import BasePrice from '../../object/BasePrice';
import Budget from '../../object/Budget';
import { Paper } from '../../object/Paper';
import { BudgetCommentTable } from '../budget/BudgetCommentTable';
import { BudgetFillTable } from '../budget/BudgetFillTable';
import { BudgetTable } from '../budget/BudgetTable';
import { PaperFillTable } from '../budget/PaperFillTable';
import PaperModal from '../budget/PaperModal';
import { PaperStatView } from '../budget/PaperStatView';

export const DevisCommentView = (props: { basePrice: BasePrice }) => {
    const { basePrice } = props;

    basePrice.fetchComment();

    const paper = basePrice.getSelectedPaper() ?? new Paper();

    return (
        <Container maxWidth={false}>
            <Box sx={{ mb: 3, mt: 3 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: 3,
                        flexWrap: 'wrap',
                        m: -1,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        {'Prestation de votre prix de vente'}
                    </Typography>
                </Box>
            </Box>
            <Box className="shadow-xl" sx={{ mb: 3 }}>
                <BudgetCommentTable
                    basePrice={basePrice}
                    paper={paper}
                    list={basePrice.getBudgetInPrestationList() ?? []}
                    loading={!basePrice.loadComment}
                />
            </Box>
            <Box sx={{ mb: '50vh' }}></Box>
        </Container>
    );
};
