import { Box, Button, ButtonGroup, Card, Container } from '@mui/material';
import { useState } from 'preact/hooks';
import BasePrice from 'src/object/BasePrice';
import Budget from 'src/object/Budget';
import Devis from 'src/object/Devis';
import { BudgetCommandCompliteTable } from '../budget/BudgetCommandCompliteTable';
import { BudgetCommandFinishTable } from '../budget/BudgetCommandFinishTable';
import { BudgetFillTable } from '../budget/BudgetFillTable';

export const DevisCommandView = (props: { basePrice: Devis }) => {
    const { basePrice } = props;
    basePrice.fetchCommand();
    const [tab, setTab] = useState('progress');

    let budgetList = [] as Budget[];
    if (tab === 'progress') {
        budgetList = basePrice.getBudgetInProgressList();
    } else if (tab === 'command') {
        budgetList = basePrice.getBudgetInCommandList();
    } else if (tab === 'complite') {
        budgetList = basePrice.getBudgetInCompliteList();
    }

    return (
        <Container
            className="!w-full !flex flex-col "
            sx={{
                gap: 3,
            }}
            maxWidth={false}
        >
            <Card
                className="!w-full"
                sx={{
                    p: 3,
                    backgroundColor: 'background.paper',
                }}
            >
                <ButtonGroup className="w-full !items-center !justify-center flex">
                    <Button
                        variant={tab === 'progress' ? 'contained' : 'outlined'}
                        color="primary"
                        sx={
                            tab === 'command'
                                ? {
                                      flex: 1,
                                      borderColor: 'primary.lighter',
                                      color: 'primary.lighter',
                                  }
                                : {
                                      flex: 1,
                                      borderColor: 'primary.main',
                                      borderWidth: '1px',
                                      borderStyle: 'solid',
                                  }
                        }
                        onClick={() => {
                            setTab('progress');
                        }}
                    >
                        Prêt
                    </Button>
                    <Button
                        variant={tab === 'command' ? 'contained' : 'outlined'}
                        color="primary"
                        sx={
                            tab === 'progress'
                                ? {
                                      flex: 1,
                                      borderColor: 'primary.lighter',
                                      color: 'primary.lighter',
                                  }
                                : {
                                      flex: 1,
                                      borderColor: 'primary.main',
                                      borderWidth: '1px',
                                      borderStyle: 'solid',
                                  }
                        }
                        onClick={() => {
                            setTab('command');
                        }}
                    >
                        Commandé
                    </Button>
                    <Button
                        variant={tab === 'complite' ? 'contained' : 'outlined'}
                        color="primary"
                        sx={
                            tab === 'command'
                                ? {
                                      flex: 1,
                                      borderColor: 'primary.lighter',
                                      color: 'primary.lighter',
                                  }
                                : {
                                      flex: 1,
                                      borderColor: 'primary.main',
                                      borderWidth: '1px',
                                      borderStyle: 'solid',
                                  }
                        }
                        onClick={() => {
                            setTab('complite');
                        }}
                    >
                        À completer
                    </Button>
                </ButtonGroup>
            </Card>
            <Box className="shadow-xl">
                {tab === 'command' && (
                    <BudgetCommandFinishTable
                        basePrice={basePrice}
                        paper={basePrice.getRootPaper()!}
                        list={budgetList}
                        loading={!basePrice.loadCommand}
                        color="white"
                    />
                )}
                {tab === 'complite' && (
                    <BudgetFillTable
                        basePrice={basePrice}
                        paper={basePrice.getRootPaper()!}
                        list={budgetList}
                        loading={!basePrice.loadCommand}
                        color="white"
                    />
                )}
                {tab === 'progress' && (
                    <BudgetCommandCompliteTable
                        basePrice={basePrice}
                        paper={basePrice.getRootPaper()!}
                        list={budgetList}
                        loading={!basePrice.loadCommand}
                        color="white"
                    />
                )}
            </Box>
        </Container>
    );
};
