import { Link } from 'react-router-dom';
import { CheckBox } from '../commun/component/CheckBox';
import Prestation from '../object/profile/Prestation';

export interface IPrestationViewProps {
    prestation: Prestation;
    checkMode: boolean;

    checked?: boolean;
    onCheck?: (p: Prestation) => void;
}
export const PrestationView: React.FC<IPrestationViewProps> = (props) => {
    const { checkMode, prestation, checked, onCheck } = props;
    let content = prestation.name.get();
    let res;
    if (checkMode) {
        if (checked === undefined) {
            throw new Error('checked is undefined in PrestationView in checkMode');
        }
        if (onCheck === undefined) {
            throw new Error('onCheck is undefined in PrestationView in checkMode');
        }
        res = (
            <label
                className=" text-black dark:text-white bg-white py-0.5 px-1.5 dark:bg-black border border-slate-500 dark:border-slate-400 gap-2 rounded-lg flex items-center gap-1"
                htmlFor={'prestation-' + prestation.getAjoIdentifier()}
            >
                <CheckBox
                    onChange={() => onCheck(prestation)}
                    checked={checked}
                    id={'prestation-' + prestation.getAjoIdentifier()}
                    name={'prestation-' + prestation.getAjoIdentifier()}
                />
                {content}
            </label>
        );
    } else {
        res = (
            <Link to={prestation.getLink()}>
                <div className=" text-black dark:text-white py-0.5 px-1.5 border border-slate-500 dark:border-slate-400 gap-2 rounded-lg flex items-center gap-1">
                    {content}
                </div>
            </Link>
        );
    }
    return res;
};
