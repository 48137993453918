import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { IPerfectTableColumn, PerfectTable } from 'src/commun/table/PerfectTable';
import Utils from 'src/commun/Utils';
import { AddIcon } from 'src/icons/AddIcon';
import { SearchIcon } from 'src/icons/SearchIcon';
import { AddElemClientModal } from 'src/modal/vendor/AddElemClientModal';
import Devis from 'src/object/Devis';
import Client from 'src/object/profile/Client';
import Product from 'src/object/tag/Product';
import SelectProductModal from '../product/SelectProductModal';
import SelectClientModal from './SelectClientModal';

export interface IClientDevisViewProps {
    list: Devis[];
    loading: boolean;
    close?: boolean;
    client?: Client;
}

export const ClientDevisView = (props: IClientDevisViewProps) => {
    const { list, client = null, loading, close } = props;

    const [search, setSearch] = useState('');
    const [selectDevis, setSelectDevis] = useState<Devis | null>(null);
    const [showAddDevis, setShowAddDevis] = useState(false);

    const [selectOffre, setSelectOffre] = useState<Product[]>([]);
    const [showSelectOffre, setShowSelectOffre] = useState(false);

    const [selectClient, setSelectClient] = useState<Client[]>([]);
    const [showSelectClient, setShowSelectClient] = useState(false);

    const cols: IPerfectTableColumn[] = [
        {
            canSort: true,
            sort: 1,
            funSort: (d1: Devis, d2: Devis) => {
                return d1.getName().localeCompare(d2.getName());
            },
            hasFilter: true,
            funFilter: (d: Devis) => {
                return Utils.isSearch(search, d.getName());
            },
            name: 'Nom',
            render: (devis: Devis) => {
                return (
                    <Box className="flex flex-row gap-2">
                        <Link to={'/devis/' + (devis.getAjoIdentifier() ?? '')}>
                            <Typography className={'!text-' + devis.getColorSpec() + '-700'} variant="h6">
                                {devis.getName()}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
    ];
    if (client === null) {
        cols.push({
            name: 'Client',
            hasFilter: selectClient.length > 0,
            canFilter: true,
            funFilter: (devis: Devis) => {
                let can = false;
                let i = 0;
                while (i < selectClient.length && !can) {
                    can = devis.client.get()?.getAjoIdentifier() === selectClient[i].getAjoIdentifier();
                    i++;
                }
                return can;
            },
            onFilterPress: () => {
                setShowSelectClient(true);
            },
            onDeletePress: () => {
                setSelectClient([]);
            },
            render: (devis: Devis) => {
                return (
                    <Link to={'/client/' + (devis.client.get()?.getAjoIdentifier() ?? '')}>
                        <Card
                            className={'!bg-' + devis.getColorSpec() + '-200'}
                            sx={{
                                p: 1,
                            }}
                        >
                            <Typography>{devis.client.get()?.name.get() ?? ''}</Typography>
                        </Card>
                    </Link>
                );
            },
        });
    }
    cols.push({
        name: 'Offre',
        hasFilter: selectOffre.length > 0,
        canFilter: true,
        funFilter: (devis: Devis) => {
            let can = false;
            let i = 0;
            while (i < selectOffre.length && !can) {
                can = devis.product.get()?.getAjoIdentifier() === selectOffre[i].getAjoIdentifier();
                i++;
            }
            return can;
        },
        onFilterPress: () => {
            setShowSelectOffre(true);
        },
        onDeletePress: () => {
            setSelectOffre([]);
        },
        render: (devis: Devis) => {
            return (
                <div className="block">
                    <div
                        className={
                            'block text-white rounded-lg text-sm py-1 px-2 bg-' + devis.product.get()?.getColor()
                        }
                    >
                        <Typography>{devis.product.get()?.name.get() ?? ''}</Typography>
                    </div>
                </div>
            );
        },
    });

    return (
        <Container maxWidth={false}>
            <Box sx={{ mb: 3, mt: 3 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        m: -1,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        {close === undefined
                            ? (client?.getName() + ' - ' ?? '') + 'Prix de vente'
                            : close
                            ? 'Prix de vente fermé'
                            : 'Prix de vente en cours'}
                    </Typography>
                    {!close && (
                        <Box sx={{ m: 1 }}>
                            <Button
                                color="primary"
                                variant="contained"
                                className="!pl-3 gap-2"
                                onClick={() => {
                                    setShowAddDevis(true);
                                }}
                            >
                                <AddIcon />
                                Ajouter un prix de vente
                            </Button>
                        </Box>
                    )}
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Card>
                        <CardContent className="">
                            <Box className="flex  items-center flex-row">
                                <Box sx={{ maxWidth: 700 }}>
                                    <TextField
                                        fullWidth
                                        value={search}
                                        onChange={(e) => {
                                            setSearch((e.target as any).value);
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon color="action" fontSize="small">
                                                        <SearchIcon />
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder="Rechercher un prix de vente"
                                        variant="outlined"
                                    />
                                </Box>
                                <Box className="flex-1"></Box>
                                <Box></Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            <Card
                sx={{
                    mb: 3,
                }}
            >
                <PerfectTable
                    bonusTrClass={(devis: Devis) => {
                        return 'bg-' + devis.getColorSpec() + '-100 ' + ' hover:!bg-' + devis.getColorSpec() + '-200';
                    }}
                    columns={cols as any}
                    nothing={
                        <Box>
                            <Typography variant="h6" className="text-center">
                                {close === undefined
                                    ? 'Aucun prix de vente'
                                    : close
                                    ? 'Aucun prix de vente fermé'
                                    : 'Aucun prix de vente en cours'}
                            </Typography>
                        </Box>
                    }
                    list={list}
                    loading={loading}
                />
            </Card>
            <SelectProductModal
                onClose={() => {
                    setShowSelectOffre(false);
                }}
                open={showSelectOffre}
                selectList={selectOffre}
                onSelectList={(l) => {
                    setSelectOffre([...l]);
                }}
            />
            <SelectClientModal
                onClose={() => {
                    setShowSelectClient(false);
                }}
                open={showSelectClient}
                selectList={selectClient}
                onSelectList={(l) => {
                    setSelectClient([...l]);
                }}
            />

            <div className="absolute">
                {showAddDevis && (
                    <AddElemClientModal
                        show={showAddDevis}
                        mode="devis"
                        clientBase={client}
                        templateList={[]}
                        handleClose={() => {
                            setShowAddDevis(false);
                        }}
                    />
                )}
            </div>
        </Container>
    );
};
