import { AJOObject, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import { Button } from '../commun/component/Button';
import { CheckBox } from '../commun/component/CheckBox';
import { UserSelectModal } from '../modal/user/SelectUserModal';
import User from '../object/User';
import { DeleteSvg } from './DeleteSvg';
import { LittleAddButton } from './LittleAddButton';
import { UserElem } from './UserElem';

export interface IUserListProps {
    userList: User[];
    checkView: boolean;

    canAdd?: boolean;
    canDelete?: boolean;
    canDeleteCondi?: (u: User) => boolean;
    parent?: AJOObject;

    checkList?: User[];
    onCheck?: (p: User) => void;
}
export const UserList: React.FC<IUserListProps> = (props) => {
    const {
        canDeleteCondi = () => true,
        userList,
        canDelete = false,
        canAdd = false,
        checkList = undefined,
        onCheck = undefined,
        parent,
        checkView,
    } = props;

    const [showModal, setShowModal] = useState(false);

    if (checkView) {
        if (onCheck === undefined) {
            throw new Error('onCheck is undefined in UserList in checkMode');
        }
        if (checkList === undefined) {
            throw new Error('checkList is undefined in UserList in checkMode');
        }
    }
    return (
        <div className="flex flex-row gap-2 flex-wrap">
            {checkView
                ? userList.map((user: User, index: number) => {
                      return (
                          <label
                              htmlFor={'user-' + index}
                              key={'user-' + index}
                              className="px-2 py-1 flex items-center gap-2 border border-slate-500 dark:border-slate-400 rounded-lg"
                          >
                              <CheckBox
                                  checked={
                                      checkList!.find((e: User) => e.getAjoIdentifier() === user.getAjoIdentifier()) !==
                                      undefined
                                  }
                                  onChange={() => {
                                      onCheck !== undefined && onCheck(user);
                                  }}
                                  name={'user-' + index}
                                  id={'user-' + index}
                              />
                              <span>{user.getFullName()}</span>
                          </label>
                      );
                  })
                : userList.map((user: User, index: number) => {
                      return (
                          <UserElem user={user} canDelete={canDelete && canDeleteCondi(user)} key={'user-' + index} />
                      );
                  })}
            {canAdd && (
                <LittleAddButton
                    onAdd={() => {
                        setShowModal(true);
                    }}
                    text="Ajouter"
                ></LittleAddButton>
            )}
            {parent && (
                <div className="absolute">
                    <UserSelectModal parent={parent} show={showModal} onClose={() => setShowModal(false)} />
                </div>
            )}
        </div>
    );
};
