import { AJOList, AJOState } from 'mp-js-react-auto-json-object';
import React, { useEffect } from 'react';
import AppManager from '../commun/AppManager';
import { Button } from '../commun/component/Button';
import { get, post } from '../commun/HTTP';
import Result from '../commun/Result';
import Utils from '../commun/Utils';
import { AddSvg } from '../component/AddSvg';
import { BasePage } from '../component/BasePage';
import { RecapTimerView } from '../component/horaire/RecapTimerView';
import { TableCpRecup } from '../component/horaire/TableCpRecup';
import { TableHoraireDevis } from '../component/horaire/TableHoraireDevis';
import { TableHoraireDevisStatList } from '../component/horaire/TableHoraireDevisStatList';
import { TableHoraireUser } from '../component/horaire/TableHoraireUser';
import { AddFerieModal } from '../modal/timer/AddFerieModal';
import Devis from '../object/Devis';
import { Timer } from '../object/Timer';
import User from '../object/User';

export const ManagePage = () => {
    AppManager.startUpdater();

    const [showAddFerie, setShowAddFerie] = React.useState(false);
    const [date, setDate] = React.useState(new Date());
    const [diff, setDiff] = React.useState(0);

    const [type, setType] = React.useState(0);

    const [stateUserList, setStateUserList] = React.useState(new AJOState(new AJOList<User>(User)));
    stateUserList.setUpdate(setStateUserList);
    const [loadingUser, setLoadingUser] = React.useState([] as Date[]);

    const [stateDevisList, setStateDevisList] = React.useState(new AJOState(new AJOList<Devis>(Devis)));
    stateDevisList.setUpdate(setStateDevisList);
    const [loadingDevis, setLoadingDevis] = React.useState(true);

    AppManager.updater().add(stateUserList);
    AppManager.updater().add(stateDevisList);

    let finalDate = new Date(date.getTime() + diff * 7 * 24 * 60 * 60 * 1000);

    useEffect(() => {
        if (type === 0) {
            if (loadingUser.find((d: Date) => d.getTime() === finalDate.getDate()) === undefined) {
                get('/timer/user_list', {
                    date: finalDate.getFullYear() + '-' + (finalDate.getMonth() + 1) + '-' + finalDate.getDate(),
                })
                    .then((result) => {
                        if (Result.isSuccess(result)) {
                            Result.applyResult(result, stateUserList);
                        }

                        setLoadingUser([...loadingUser, finalDate]);

                        const haveIdList = [];
                        for (let user of stateUserList.get()!.getList()) {
                            if (user.global_recup.get() === undefined) {
                                post('/timer/recup', {
                                    id: user.getAjoIdentifier(),
                                })
                                    .then((res: any) => {
                                        if (Result.isSuccess(res)) {
                                            Result.applyResult(res, stateUserList, true);
                                            AppManager.makeUpdate();
                                        }
                                    })
                                    .catch((err) => {});
                            }
                        }

                        /*post('/recup', {
                            id_list: haveIdList,
                        }).then((res: any) => {
                            if (Result.isSuccess(res)) {
                                Result.applyResult(res, stateUserList);
                            }
                        });
                        post('/recup_comming', {
                            id_list: haveIdList,
                        }).then((res: any) => {
                            if (Result.isSuccess(res)) {
                                Result.applyResult(res, stateUserList);
                            }
                        });*/
                    })
                    .catch((err) => {});
            }
        } else {
            if (loadingDevis) {
                setLoadingDevis(true);
                get('/devis/list', {}).then((result) => {
                    setLoadingDevis(false);
                    if (Result.isSuccess(result)) {
                        Result.applyResult(result, stateDevisList);
                    }
                });
            }
        }
    }, [type, diff]);

    let userList = (stateUserList.get()?.getList() ?? []).filter((user) => {
        return (user.delete.get() ?? false) === false;
    });

    let devisList = (stateDevisList.get()?.getList() ?? []).filter((user) => {
        return true;
    });

    let fullTimerList = [] as Timer[];
    userList.forEach((user) => {
        fullTimerList = fullTimerList.concat(user.timerList.getList() ?? []);
    });

    let topTimerList = fullTimerList.filter((timer) => {
        return timer.isRecup() && timer.isPendding();
    });

    let content;

    if (type === 0) {
        content = [
            <div key={0} className="flex gap-4 flex-row items-center">
                <Button
                    color="slate"
                    pb={1.5}
                    pt={1.5}
                    pl={1.5}
                    pr={1.5}
                    onClick={() => {
                        setDiff(diff - 1);
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path
                            fillRule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clipRule="evenodd"
                        />
                    </svg>
                </Button>
                <p className="font-medium text-center flex-1 text-xl">
                    {Utils.getCurrentWeek(Utils.getMonday(finalDate))}
                </p>
                <Button
                    pb={1.5}
                    pt={1.5}
                    pl={1.5}
                    pr={1.5}
                    color="slate"
                    onClick={() => {
                        setDiff(diff + 1);
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path
                            fillRule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                        />
                    </svg>
                </Button>
                <Button
                    onClick={() => {
                        setShowAddFerie(true);
                    }}
                    gap={2}
                    color="blue"
                >
                    <AddSvg />
                    Jour non travaillé
                </Button>
                <AddFerieModal
                    show={showAddFerie}
                    onClose={() => {
                        setShowAddFerie(false);
                    }}
                />
            </div>,
            <div key={1} className="pb-24">
                <TableHoraireUser
                    loading={loadingUser.find((d: Date) => d.getTime() === finalDate.getTime()) === undefined}
                    current={finalDate}
                    list={userList}
                />
            </div>,
        ];
    } else {
        content = (
            <div key={0} className="pb-24">
                <TableHoraireDevis current={finalDate} loading={loadingDevis} list={devisList} />
            </div>
        );
    }

    return (
        <BasePage className="w-full h-full flex box-border flex-row gap-3 px-3 pb-24">
            <div className="flex pb-24 flex-col flex-1 pt-3 gap-3">
                {topTimerList.length !== 0 && (
                    <div>
                        <TableCpRecup list={topTimerList} />
                    </div>
                )}
                <div className="flex flex-row gap-2">
                    <Button
                        color="red"
                        className="flex-1"
                        outline={type !== 0}
                        onClick={() => {
                            setType(0);
                        }}
                    >
                        COLABORATEUR
                    </Button>
                    <Button
                        color="blue"
                        className="flex-1"
                        outline={type !== 1}
                        onClick={() => {
                            setType(1);
                        }}
                    >
                        PRIX DE VENTE
                    </Button>
                </div>
                {content}
            </div>
            {type === 0 && (
                <div>
                    <RecapTimerView
                        baseClass=""
                        startDate={Utils.getMonday(finalDate)}
                        endDate={Utils.getSunday(finalDate)}
                        timerList={fullTimerList}
                    />
                </div>
            )}
        </BasePage>
    );
};
