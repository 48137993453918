import { AJOList } from 'mp-js-react-auto-json-object';
import React from 'react';
import AppManager from '../../commun/AppManager';
import { ComplexInput } from '../../commun/component/ComplexInput';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import Utils from '../../commun/Utils';
import Address from '../../object/Address';
import { IModalProps } from '../base/IModalProps';
import { JSXInternal } from 'preact/src/jsx';
import { IModalState } from '../base/IModalState';
import { addressAddSuccess, addressEditSuccess, errorManager } from '../vendor/CategoryErrorManager';

export interface IAddressModalProps extends IModalProps {
    onAddressChange: (id: string) => void;
    baseName?: string;
    address?: Address | null;
    action: 'add' | 'edit';
}
export interface IAddressModalState extends IModalState {
    name: string;
    postalCode: string;
    numberAndStreet: string;
    city: string;
    bonus: string;
}

export class AddressModal extends React.Component<IAddressModalProps, IAddressModalState> {
    constructor(props: IAddressModalProps) {
        /**
         * DEFAULT PROPS
         */

        super(props);
        /**
         * CREATE STATE
         * */
        this.state = this.initState();
    }

    initState(): IAddressModalState {
        const { action, baseName = '', address } = this.props;
        if (action === 'edit') {
            return {
                name: address!.name.get(),
                numberAndStreet: address!.number_and_street.get(),
                postalCode: address!.postal_code.get() + '',
                city: address!.city.get(),
                bonus: address!.bonus.get(),
                error: ErrorHTTP.NO_ERROR(),
                loading: false,
            };
        } else {
            return {
                name: baseName,
                numberAndStreet: '',
                postalCode: '',
                city: '',
                bonus: '',
                error: ErrorHTTP.NO_ERROR(),
                loading: false,
            };
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IAddressModalProps>,
        prevState: Readonly<IAddressModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.show !== this.props.show) {
            if (this.props.show) {
                Utils.blur();

                this.setState(this.initState());
            }
        }
    }

    getSuccess() {
        const { action } = this.props;
        let res: ErrorHTTP;
        if (action === 'add') {
            res = addressAddSuccess;
        } else {
            res = addressEditSuccess;
        }
        return res;
    }

    makeAction(): void {
        const { action } = this.props;
        const { name, numberAndStreet, bonus, postalCode, city } = this.state;

        this.setState({
            loading: true,
            error: ErrorHTTP.NO_ERROR(),
        });

        let params: { [key: string]: any } = {
            name: name,
            bonus: bonus,
            number_and_street: numberAndStreet,
            postal_code: parseInt(postalCode),
            city: city,
        };

        if (action === 'edit') {
            params['id'] = this.props.address!.getAjoIdentifier();
        }

        post('/address/update', params).then((res: any) => {
            this.setState({
                loading: false,
            });

            if (Result.isSuccess(res)) {
                Result.applyResult(res, AppManager.updater(), true);

                let address = new Address();
                Result.applyResult(res, address, true);
                this.props.onAddressChange(address.getAjoIdentifier());

                this.props.onClose();

                let errorSuccess = this.getSuccess();
                AppManager.addToast(new Toast(errorManager.get(errorSuccess), 'success'));
            } else {
                this.setState({
                    error: Result.getError(res),
                });
            }
        });
    }

    getTitle(): string {
        const { action } = this.props;
        let res: string;
        if (action === 'add') {
            res = 'Ajouter une adresse';
        } else {
            res = 'Modifier une adresse';
        }
        return res;
    }

    getDone(): string {
        const { action } = this.props;
        let res: string;
        if (action === 'add') {
            res = 'Ajouter';
        } else {
            res = 'Modifier';
        }
        return res;
    }

    render(): JSXInternal.Element {
        const { name, bonus, numberAndStreet, postalCode, city, loading, error } = this.state;
        const { show, onClose } = this.props;

        return (
            <Modal
                title={this.getTitle()}
                done={this.getDone()}
                loading={loading}
                error={error}
                errorManager={errorManager}
                show={show}
                onDone={() => {
                    this.makeAction();
                }}
                handleClose={onClose}
            >
                <ComplexInput
                    typeValue="string"
                    placeholder="Nom"
                    value={name}
                    setValue={(e: string) => this.setState({ name: e })}
                />
                <ComplexInput
                    typeValue="string"
                    placeholder="Batiment, appartement ..."
                    value={bonus}
                    setValue={(e: string) => this.setState({ bonus: e })}
                />
                <ComplexInput
                    typeValue="string"
                    placeholder="N° et nom de rue"
                    value={numberAndStreet}
                    setValue={(e: string) => this.setState({ numberAndStreet: e })}
                />
                <ComplexInput
                    typeValue="string"
                    placeholder="Code postal"
                    value={postalCode}
                    setValue={(e: string) => this.setState({ postalCode: e })}
                />
                <ComplexInput
                    typeValue="string"
                    placeholder="Ville"
                    value={city}
                    setValue={(e: string) => this.setState({ city: e })}
                />
            </Modal>
        );
    }
}
