import { ClientIcon } from '../../icons/ClientIcon';
import { CommandIcon } from '../../icons/CommandIcon';
import { HelpIcon } from '../../icons/HelpIcon';
import { HomeIcon } from '../../icons/HomeIcon';
import { UserListIcon } from '../../icons/UserListIcon';
import { StoreIcon } from '../../icons/StoreIcon';
import { Box, Button, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { NavItem } from './NavItem';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from './Logo';
import AppManager from 'src/commun/AppManager';

const itemList = [
    {
        href: '/',
        icon: <HomeIcon />,
        title: 'Accueil',
    },
    {
        href: '/vendor',
        icon: <StoreIcon />,
        title: 'Fournisseur',
    },
    {
        href: '/client',
        icon: <ClientIcon />,
        title: 'Client',
    },
    {
        href: '/command',
        icon: <CommandIcon />,
        title: 'Bon de commande',
    },
    {
        href: '/board',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                    fillRule="evenodd"
                    d="M1.5 7.125c0-1.036.84-1.875 1.875-1.875h6c1.036 0 1.875.84 1.875 1.875v3.75c0 1.036-.84 1.875-1.875 1.875h-6A1.875 1.875 0 011.5 10.875v-3.75zm12 1.5c0-1.036.84-1.875 1.875-1.875h5.25c1.035 0 1.875.84 1.875 1.875v8.25c0 1.035-.84 1.875-1.875 1.875h-5.25a1.875 1.875 0 01-1.875-1.875v-8.25zM3 16.125c0-1.036.84-1.875 1.875-1.875h5.25c1.036 0 1.875.84 1.875 1.875v2.25c0 1.035-.84 1.875-1.875 1.875h-5.25A1.875 1.875 0 013 18.375v-2.25z"
                    clip-rule="evenodd"
                />
            </svg>
        ),
        title: 'Récap horaire',
    },
    {
        href: '/user',
        icon: <UserListIcon />,
        title: 'Utilisateur',
    },
    {
        href: '/help',
        icon: <HelpIcon />,
        title: 'Aide',
    },
];

const manageList = [
    {
        href: '/recup',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                <path
                    fillRule="evenodd"
                    d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                    clipRule="evenodd"
                />
            </svg>
        ),
        title: 'Récupération',
    },
    {
        href: '/heure',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
                    clipRule="evenodd"
                />
            </svg>
        ),
        title: 'Heure',
    },
    {
        href: '/price',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.902 7.098a3.75 3.75 0 013.903-.884.75.75 0 10.498-1.415A5.25 5.25 0 008.005 9.75H7.5a.75.75 0 000 1.5h.054a5.281 5.281 0 000 1.5H7.5a.75.75 0 000 1.5h.505a5.25 5.25 0 006.494 2.701.75.75 0 00-.498-1.415 3.75 3.75 0 01-4.252-1.286h3.001a.75.75 0 000-1.5H9.075a3.77 3.77 0 010-1.5h3.675a.75.75 0 000-1.5h-3c.105-.14.221-.274.348-.402z"
                    clipRule="evenodd"
                />
            </svg>
        ),
        title: 'Prix de vente',
    },
    {
        href: '/close',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path
                    fillRule="evenodd"
                    d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                    clipRule="evenodd"
                />
            </svg>
        ),
        title: 'Fermeture',
    },
];

export interface DashboardSidebarProps {
    open: boolean;
    onClose: () => void;
}

export const DashboardSideBar = (props: DashboardSidebarProps) => {
    const { open, onClose } = props;
    const router = useLocation();
    const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false,
    });

    useEffect(
        () => {
            /*if (!router.) {
                return;
            }*/

            if (open) {
                onClose?.();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [router.pathname],
    );

    const content = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <div>
                    <Box sx={{ p: 3 }}>
                        <Link to="/">
                            <Logo className="" />
                        </Link>
                    </Box>
                </div>
                <Divider
                    sx={{
                        borderColor: '#2D3748',
                        mb: 3,
                    }}
                />
                <Box
                    sx={{
                        mb: 3,
                    }}
                >
                    {itemList.map((item) => (
                        <NavItem key={item.title} icon={item.icon} href={item.href} title={item.title} />
                    ))}
                </Box>
                {(AppManager.getUser() as any)?.root.get() === true && (
                    <Divider
                        sx={{
                            borderColor: '#2D3748',
                            mb: 3,
                        }}
                    />
                )}
                {(AppManager.getUser() as any)?.root.get() === true && (
                    <Box sx={{ flexGrow: 1 }}>
                        {manageList.map((item) => (
                            <NavItem key={item.title} icon={item.icon} href={item.href} title={item.title} />
                        ))}
                    </Box>
                )}
            </Box>
        </>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.900',
                        color: '#FFFFFF',
                        width: 280,
                    },
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.900',
                    color: '#FFFFFF',
                    width: 280,
                },
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};
