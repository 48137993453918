import { useEffect, useState } from 'react';
import { get } from '../commun/HTTP';
import { BasePage } from '../component/BasePage';
import AppManager from '../commun/AppManager';
import { AJOList, AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import User from '../object/User';
import Result from '../commun/Result';
import Utils from '../commun/Utils';
import { JSXInternal } from 'preact/src/jsx';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { SearchIcon } from 'src/icons/SearchIcon';
import { PerfectTable } from 'src/commun/table/PerfectTable';
import { DeleteUserModal } from 'src/modal/DeleteUserModal';
import { EditUserModal } from 'src/modal/EditUserModal';
import ActionManager from 'src/commun/action/ActionManager';
import MakeAction from 'src/commun/action/Action';
import { AddUserModal } from 'src/modal/AddUserModal';
import { AddIcon } from 'src/icons/AddIcon';

export function UserPage() {
    const [selectedList, updateSelectedList] = useState(new Array<string>());
    const [loading, updateLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [stateList, updateStateList] = useState(new AJOState<AJOList<User>>(new AJOList<User>(User)));
    stateList.setUpdate(updateStateList);

    useEffect(() => {
        get('/user/get', {
            search: '',
        }).then((res: any) => {
            if (Result.isSuccess(res)) {
                updateLoading(false);

                stateList.get()!.reset();

                Result.applyResult(res, stateList, true);
            }
        });
    }, []);
    useEffect(() => {
        updateSelectedList([]);
    }, [search]);

    const list: User[] = [];
    const cleanSearchList: string[] = Utils.clean(search).split(' ');

    for (let i = 0; i < stateList.get()!.size(); i++) {
        const elem = stateList.get()!.get(i);

        let good = cleanSearchList.length == 1 && cleanSearchList[0] == '';
        let y = 0;

        while (!good && y < cleanSearchList.length) {
            const cleanSearch = cleanSearchList[y];

            if (
                cleanSearch.length != 0 &&
                (Utils.clean(elem.firstname.get()).includes(cleanSearch) ||
                    Utils.clean(elem.lastname.get()).includes(cleanSearch))
            ) {
                good = true;
            }
            y++;
        }

        if (good) {
            list.push(elem);
        }
    }

    const [showAdd, setShowAdd] = useState(false);

    const [editUser, setEditUser] = useState<User | null>(null);
    const [deleteUser, setDeleteUser] = useState<User | null>(null);
    const [revokUser, setRevokUser] = useState<User | null>(null);

    return (
        <BasePage className="flex-col flex items-center">
            <DashboardNavBar />
            <Container>
                <Box sx={{ mb: 3, mt: 3 }}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            m: -1,
                        }}
                    >
                        <Typography sx={{ m: 1 }} variant="h4">
                            Utilisateur
                        </Typography>
                        <Box sx={{ m: 1 }}>
                            <Button
                                color="primary"
                                variant="contained"
                                className="!pl-3 gap-2"
                                onClick={() => {
                                    setShowAdd(true);
                                }}
                            >
                                <AddIcon />
                                Ajouter un utilisateur
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Card>
                            <CardContent className="">
                                <Box className="flex  items-center flex-row">
                                    <Box sx={{ maxWidth: 700 }}>
                                        <TextField
                                            fullWidth
                                            value={search}
                                            onChange={(e) => {
                                                setSearch((e.target as any).value);
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SvgIcon color="action" fontSize="small">
                                                            <SearchIcon />
                                                        </SvgIcon>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            placeholder="Rechercher un utilisateur"
                                            variant="outlined"
                                        />
                                    </Box>
                                    <Box className="flex-1"></Box>
                                    <Box></Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
                <Card
                    sx={{
                        mb: 3,
                    }}
                >
                    <PerfectTable
                        columns={[
                            {
                                name: 'Nom',
                                sort: 1,
                                canSort: true,
                                funSort: (a: User, b: User) => {
                                    return (a.lastname.get() ?? '').localeCompare(b.lastname.get() ?? '');
                                },
                                hasFilter: false,
                                funFilter: (a: User) => {
                                    return (
                                        Utils.isSearch(search, a.lastname.get()) ||
                                        Utils.isSearch(search, a.firstname.get()) ||
                                        Utils.isSearch(search, a.email.get())
                                    );
                                },
                                render: (user: User) => {
                                    return (
                                        <Box>
                                            <Typography variant="h6">
                                                {user.firstname.get()} {user.lastname.get()}
                                            </Typography>
                                            <Typography>{user.email.get()}</Typography>
                                        </Box>
                                    );
                                },
                            },
                            {
                                name: 'Numéro de téléphone',
                                render: (user: User) => {
                                    return (
                                        <Box>
                                            <Typography>{user.phone.get()}</Typography>
                                        </Box>
                                    );
                                },
                            },
                            {
                                name: 'Rôle',
                                render: (user: User) => {
                                    let content: any;
                                    if (user.delete.get() === true) {
                                        content = (
                                            <Typography className="!font-semibold text-red-700">
                                                Utilisateur supprimé
                                            </Typography>
                                        );
                                    } else if (user.root.get() === true) {
                                        content = (
                                            <Typography className="!font-semibold text-green-700">
                                                Utilisateur racine
                                            </Typography>
                                        );
                                    } else {
                                        content = (
                                            <Typography className="!font-semibold text-blue-700">
                                                Chef de projet
                                            </Typography>
                                        );
                                    }
                                    return <Box>{content}</Box>;
                                },
                            },
                            {
                                name: 'Action',
                                render: (user: User) => {
                                    return (
                                        <Box className="flex flex-row gap-3">
                                            {ActionManager.canDoAction(
                                                MakeAction.EDIT_USER,
                                                AppManager.getUser() as any,
                                                user,
                                            ) && (
                                                <Button
                                                    className="flex items-center flex-row !pl-3 gap-2"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        setEditUser(user);
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                                        <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                                    </svg>
                                                    Modifier
                                                </Button>
                                            )}
                                            {ActionManager.canDoAction(
                                                MakeAction.DELETE_USER,
                                                AppManager.getUser() as any,
                                                user,
                                            ) && (
                                                <Button
                                                    className="flex items-center flex-row !pl-3 gap-2"
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => {
                                                        setDeleteUser(user);
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                    Supprimer
                                                </Button>
                                            )}
                                            {ActionManager.canDoAction(
                                                MakeAction.REVOK_USER,
                                                AppManager.getUser() as any,
                                                user,
                                            ) && (
                                                <Button
                                                    className="flex items-center flex-row !pl-3 gap-2"
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => {
                                                        setRevokUser(user);
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                    Révoquer
                                                </Button>
                                            )}
                                        </Box>
                                    );
                                },
                            },
                        ]}
                        loading={loading}
                        list={list}
                        nothing={
                            <Box className="flex flex-col items-center p-12">
                                <Typography variant="h6">Aucun utilisateur</Typography>
                            </Box>
                        }
                    />
                </Card>

                {editUser && (
                    <EditUserModal
                        stateList={stateList}
                        user={editUser}
                        show={editUser !== null}
                        handleClose={() => {
                            setEditUser(null);
                        }}
                    />
                )}

                {deleteUser && (
                    <DeleteUserModal
                        type="simple"
                        order={true}
                        stateList={stateList}
                        user={deleteUser}
                        show={deleteUser !== null}
                        handleClose={() => {
                            setDeleteUser(null);
                        }}
                    />
                )}

                {revokUser && (
                    <DeleteUserModal
                        type="simple"
                        order={false}
                        stateList={stateList}
                        user={revokUser}
                        show={revokUser !== null}
                        handleClose={() => {
                            setRevokUser(null);
                        }}
                    />
                )}
                {showAdd && (
                    <AddUserModal
                        stateList={stateList}
                        show={showAdd}
                        handleClose={() => {
                            setShowAdd(false);
                        }}
                    />
                )}
            </Container>
        </BasePage>
    );
}
