import { JSXInternal } from 'preact/src/jsx';

export interface IDownloadSvgProps extends JSXInternal.SVGAttributes<SVGSVGElement> {}

export const DownloadSvg: React.FC<IDownloadSvgProps> = (props) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            />
        </svg>
    );
};
