import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { Color } from '../../commun/Constant';
import { PerfectTable } from '../../commun/table/PerfectTable';
import Utils from '../../commun/Utils';
import { ArrowDownCircle } from '../../icons/ArrowDownCircle';
import { ArrowRightCircle } from '../../icons/ArrowRightCircle';
import Service from '../../object/Service';
import Category from '../../object/tag/Category';
import Label from '../../object/tag/Label';
import { BudgetTable } from '../budget/BudgetTable';
import SelectCategoryModal from '../category/SelectCategoryModal';
import { SimpleCategoryList } from '../category/SimpleCategoryList';
import SelectLabelModal from '../label/SelectLabelModal';
import { SimpleLabelList } from '../label/SimpleLabelList';
import { NoteView } from '../note/NoteView';
import { JSXInternal } from 'preact/src/jsx';
import { NoteList } from '../NoteList';
import Budget from 'src/object/Budget';

export interface IServiceTableProps {
    list: Service[];
    loading: boolean;
    color?: Color;
}

export const ServiceTable = (props: IServiceTableProps) => {
    const { list, loading } = props;

    const [categoryList, setCategoryList] = useState([] as Category[]);
    const [labelList, setLabelList] = useState([] as Label[]);

    const [categoryShow, setCategoryShow] = useState(false);
    const [labelShow, setLabelShow] = useState(false);

    const [search, setSearch] = useState('');
    const [openElement, setOpenElement] = useState<Service | null>(null);

    return (
        <>
            <PerfectTable
                loading={loading}
                list={list}
                color={props.color ?? 'white'}
                nothing={
                    <Box className="flex flex-col gap-3 items-center justify-center">
                        <Typography>{'Aucune prestation trouvée.'}</Typography>
                    </Box>
                }
                columns={[
                    {
                        canSort: true,
                        sort: 1,
                        funSort: (e1: Service, e2: Service) => {
                            return e1.prestation.get()!.name.get().localeCompare(e2.prestation.get()!.name.get());
                        },

                        name: 'Nom',

                        hasFilter: true,
                        funFilter: (service: Service) => {
                            const finalSearch = Utils.clean(search.toLowerCase());
                            let can =
                                Utils.clean(service.prestation.get()!.name.get()).toLowerCase().includes(finalSearch) ||
                                search.length === 0;
                            return can;
                        },
                        render: (elem: Service) => {
                            return (
                                <Box className="font-semibold flex flex-row items-center gap-1">
                                    {openElement?.getAjoIdentifier() !== elem.getAjoIdentifier() ? (
                                        <ArrowRightCircle
                                            onClick={() => {
                                                setOpenElement(elem);
                                            }}
                                        />
                                    ) : (
                                        <ArrowDownCircle
                                            onClick={() => {
                                                setOpenElement(null);
                                            }}
                                        />
                                    )}
                                    {elem.prestation.get()!.name.get()}
                                </Box>
                            );
                        },
                    },
                    {
                        name: 'Catégorie',

                        canSort: true,
                        sort: 0,
                        funSort: (e1: Service, e2: Service) => {
                            return -e1.getCategoryList().length + e2.getCategoryList().length;
                        },

                        canFilter: true,
                        hasFilter: categoryList.length !== 0,
                        funFilter: (elem: Service) => {
                            let can = false;
                            let i = 0;
                            while (!can && i < categoryList.length) {
                                const category = categoryList[i];
                                can =
                                    elem.getCategoryList().find((p: Category) => {
                                        return p.getAjoIdentifier() === category.getAjoIdentifier();
                                    }) !== undefined;
                                i += 1;
                            }
                            return can;
                        },
                        onDeletePress: () => {
                            setCategoryList([]);
                        },
                        onFilterPress: () => {
                            setCategoryShow(true);
                        },
                        render: (elem: Service) => {
                            return <SimpleCategoryList list={elem.getCategoryList()} />;
                        },
                    },
                    {
                        name: 'Label',

                        canSort: true,
                        sort: 0,
                        funSort: (e1: Service, e2: Service) => {
                            return -e1.getLabelList().length + e2.getLabelList().length;
                        },

                        canFilter: true,
                        hasFilter: labelList.length !== 0,
                        funFilter: (elem: Service) => {
                            let can = false;
                            let i = 0;
                            while (!can && i < labelList.length) {
                                const label = labelList[i];
                                can =
                                    elem.getLabelList().find((p: Label) => {
                                        return p.getAjoIdentifier() === label.getAjoIdentifier();
                                    }) !== undefined;
                                i += 1;
                            }
                            return can;
                        },
                        onDeletePress: () => {
                            setLabelList([]);
                        },
                        onFilterPress: () => {
                            setLabelShow(true);
                        },
                        render: (elem: Service) => {
                            return <SimpleLabelList list={elem.getLabelList()} />;
                        },
                    },
                    {
                        name: 'Note',

                        canSort: true,
                        sort: 0,
                        funSort: (e1: Service, e2: Service) => {
                            if (isNaN(e1.getAverageNote())) {
                                return 1;
                            }
                            if (isNaN(e2.getAverageNote())) {
                                return -1;
                            }
                            return -e1.getAverageNote() + e2.getAverageNote();
                        },

                        render: (elem: Service) => {
                            return <NoteView note={elem.getAverageNote()} />;
                        },
                    },
                    {
                        name: 'Commentaire',

                        canSort: true,
                        sort: 0,
                        funSort: (e1: Service, e2: Service) => {
                            return -e1.getNoteList().length + e2.getNoteList().length;
                        },

                        render: (elem: Service) => {
                            return <NoteList noteList={elem.getNoteList()} />;
                        },
                    },
                ]}
                bonusRender={(elem: Service) => {
                    let res: JSXInternal.Element = <></>;
                    if (elem.getAjoIdentifier() === openElement?.getAjoIdentifier()) {
                        elem.fetchClientListInfo();
                        res = (
                            <BudgetTable
                                list={elem.budgetList.getList() as Budget[]}
                                loading={elem.serviceClientListLoading}
                                color="blue"
                            />
                        );
                    }
                    return res;
                }}
            />
            <SelectCategoryModal
                onClose={() => {
                    setCategoryShow(false);
                }}
                open={categoryShow}
                selectList={categoryList}
                onSelectList={(l) => {
                    setCategoryList([...l]);
                }}
            />
            <SelectLabelModal
                onClose={() => {
                    setLabelShow(false);
                }}
                open={labelShow}
                selectList={labelList}
                onSelectList={(l) => {
                    setLabelList([...l]);
                }}
            />
        </>
    );
};
