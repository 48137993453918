import { AJOObject, AJOProperties } from 'mp-js-react-auto-json-object';
import { Color } from '../../commun/Constant';
import { JSXInternal } from 'preact/src/jsx';
export abstract class TagObject extends AJOObject {
    public name: AJOProperties<any>;
    private color: AJOProperties<any>;
    public pos: AJOProperties<any>;

    constructor(name: string) {
        super(name);
        this.name = new AJOProperties('name');
        this.color = new AJOProperties('color');
        this.pos = new AJOProperties('pos');
    }

    public getColor(): Color {
        return (this.color.get() as Color) ?? 'blue';
    }

    public isBonus(): boolean {
        return false;
    }
}
