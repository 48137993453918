import { AJOList, AJOObject, AJOUpdater } from 'mp-js-react-auto-json-object';
import React from 'react';
import AppManager from '../../commun/AppManager';
import { ComplexInput } from '../../commun/component/ComplexInput';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { JSXInternal } from 'preact/src/jsx';
import Utils from '../../commun/Utils';
import { ListComplexSelect } from '../../component/ListComplexSelect';
import { AddressSelect } from '../../component/select/AddressSelect';
import Address from '../../object/Address';
import { Agence } from '../../object/Agence';
import { ActionModal, IActionModalProps, IActionModalState } from '../base/ActionModal';
import { agenceAddSuccess, agenceEditSuccess, errorManager } from '../vendor/CategoryErrorManager';

export interface IAgenceModalProps extends IActionModalProps<Agence> {
    parent?: AJOObject;
    addressAjoList?: AJOList<Address>;
    baseCode?: string;
    address?: Address;
    action: 'add' | 'edit';
}

export interface IAgenceModalState extends IActionModalState {
    code: string;
    siret: string;
    code_ape: string;
    email: string;
    licence_de_voyage: string;
    capital_social: number | undefined;
    juridique_name: string;
    display_name: string;
    num: string;
    address: Address | null;
}
interface AgenceInput {
    name: string;
    placeholder: string;
    type: 'eur' | 'string';
}

export class AgenceModal extends ActionModal<Agence, IAgenceModalProps, IAgenceModalState> {
    private agence_input_list: AgenceInput[] = [];

    constructor(props: IAgenceModalProps) {
        /**
         * DEFAULT PROPS
         */
        super(props, agenceAddSuccess, agenceEditSuccess);

        this.state = this.initState();

        this.agence_input_list = [
            { name: 'code', placeholder: 'Code', type: 'string' },
            { name: 'siret', placeholder: 'Siret', type: 'string' },
            { name: 'display_name', placeholder: 'Nom', type: 'string' },
            { name: 'email', placeholder: 'Email', type: 'string' },
            { name: 'num', placeholder: 'Numéro de téléphone', type: 'string' },
            {
                name: 'juridique_name',
                placeholder: 'Nom juridique ( Exemple: SARL EPS GRANDS ÉVÉNEMENTS )',
                type: 'string',
            },
            { name: 'capital_social', placeholder: 'Capital social', type: 'eur' },
            { name: 'code_ape', placeholder: 'Code APE', type: 'string' },
            { name: 'licence_de_voyage', placeholder: 'Licence de voyage', type: 'string' },
        ] as AgenceInput[];
    }

    getSuccess() {
        const { action } = this.props;
        let res: ErrorHTTP;
        if (action === 'add') {
            res = agenceAddSuccess;
        } else {
            res = agenceEditSuccess;
        }
        return res;
    }

    initState(): IAgenceModalState {
        const { action, elem, baseCode } = this.props;
        let res: IAgenceModalState;
        if (action === 'add') {
            res = {
                code: baseCode ?? '',
                siret: '',
                code_ape: '',
                email: '',
                licence_de_voyage: '',
                capital_social: undefined,
                juridique_name: '',
                display_name: '',
                num: '',
                address: null,
                error: ErrorHTTP.NO_ERROR(),
                loading: false,
            };
        } else {
            res = {
                code: elem?.code.get() ?? '',
                siret: elem?.siret.get() ?? '',
                code_ape: elem?.code_ape.get() ?? '',
                email: elem?.email.get() ?? '',
                licence_de_voyage: elem?.licence_de_voyage.get() ?? '',
                capital_social: elem?.capital_social.get() ?? undefined,
                juridique_name: elem?.juridique_name.get() ?? '',
                display_name: elem?.display_name.get() ?? '',
                num: elem?.num.get() ?? '',
                address: elem?.address.get() ?? null,
                error: ErrorHTTP.NO_ERROR(),
                loading: false,
            };
        }
        return res;
    }

    getUrl(): string {
        const { parent } = this.props;
        let res: string;
        if (parent === undefined) {
            res = '/agence/update';
        } else {
            res = '/devis/update';
        }
        return res;
    }

    getSendParams(): { [key: string]: any } {
        const { parent, action } = this.props;
        let params: { [key: string]: any } = {};

        if (parent === undefined) {
            params = { ...this.state };
            params.capital_social = parseFloat(params.capital_social);
        } else {
            params = {
                id: parent.getAjoIdentifier(),
                agence: { ...this.state },
            };
            params.agence.capital_social = parseFloat(params.agence.capital_social);
        }
        params.address = {
            id: this.state.address?.getAjoIdentifier() ?? '',
        };
        if (action === 'edit') {
            params['id'] = this.props.elem!.getAjoIdentifier();
        }
        return params;
    }

    componentDidUpdate(
        prevProps: Readonly<IAgenceModalProps>,
        prevState: Readonly<IAgenceModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.show !== this.props.show) {
            this.setState(this.initState());
            if (this.props.show) {
                Utils.blur();
            }
        }
    }

    render(): JSXInternal.Element {
        return super.render();
    }

    contentModal(): JSXInternal.Element | JSXInternal.Element[] {
        const { address } = this.state;

        let array_input = [] as JSXInternal.Element[];
        for (let i = 0; i < this.agence_input_list.length; i++) {
            let agence_input = this.agence_input_list[i];

            array_input.push(
                <ComplexInput
                    key={i}
                    placeholder={agence_input.placeholder}
                    value={this.state[agence_input.name as keyof IAgenceModalState] + ''}
                    typeValue={agence_input.type}
                    setValue={(value) => {
                        this.setState({ [agence_input.name]: value } as any);
                    }}
                />,
            );
        }

        if (this.props.show) {
            array_input.push(
                <AddressSelect
                    canAdd={true}
                    key={this.agence_input_list.length}
                    ajoList={this.props.addressAjoList}
                    elem={address ?? undefined}
                    placeholder="Adresse de l'agence"
                    onObjectSelect={(address) => {
                        this.setState({
                            address: address,
                        });
                    }}
                />,
            );
        }
        return array_input;
    }

    getTitle(): string {
        const { action } = this.props;
        let res: string;
        if (action === 'add') {
            res = 'Ajouter une agence';
        } else {
            res = 'Modifier une agence';
        }
        return res;
    }

    getDone(): string {
        const { action } = this.props;
        let res: string;
        if (action === 'add') {
            res = 'Ajouter';
        } else {
            res = 'Modifier';
        }
        return res;
    }
    public createGeneric() {
        return Agence;
    }
}
