import { AJOList, AJOState } from 'mp-js-react-auto-json-object';
import React from 'react';
import AppManager from '../../commun/AppManager';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import { JSXInternal } from 'preact/src/jsx';
import Toast from '../../commun/toast/Toast';
import { DevisClientSelect } from '../../component/select/DevisClientSelect';
import { SectionSelect } from '../../component/select/SectionSelect';
import { Action } from '../../object/Action';
import Devis from '../../object/Devis';
import Client from '../../object/profile/Client';
import { Section } from '../../object/Section';
import { devisClientNoSelect, elementAddSuccess, errorManager } from './CategoryErrorManager';
import MpModal from 'src/component/extends/MpModal';
import { Box } from '@mui/material';

interface IAddTimerModalProps {
    show: boolean;
    onClose: () => void;
    stateTimerList: AJOState<AJOList<Devis | Action>>;
}

interface IAddTimerModalState {
    elem: Devis | Action | null;
    section: Section | null;
    loading: boolean;
    error: ErrorHTTP;
}

export class AddTimerModal extends React.Component<IAddTimerModalProps, IAddTimerModalState> {
    constructor(props: IAddTimerModalProps) {
        super(props);

        this.state = this.initState();
    }

    initState(): IAddTimerModalState {
        return {
            elem: null,
            section: null,
            loading: false,
            error: ErrorHTTP.NO_ERROR(),
        };
    }

    componentDidUpdate(
        prevProps: Readonly<IAddTimerModalProps>,
        prevState: Readonly<IAddTimerModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.show !== this.props.show) {
            if (this.props.show) {
                this.setState(this.initState());
            }
        }
    }

    render(): JSXInternal.Element {
        const { onClose, show, stateTimerList } = this.props;
        //loading={this.state.loading}
        return (
            <MpModal
                overflow={false}
                title="Nouvelle élément"
                done="Démarrer"
                open={show}
                onSubmit={() => {
                    this.setState({ loading: true });
                    if (this.state.elem != null) {
                        post('/timer/point', {
                            id_object: this.state.elem.getAjoIdentifier(),
                            id_section: this.state.section?.getAjoIdentifier() ?? '',
                            name_section: '',
                        }).then((res: any) => {
                            this.setState({ loading: false });
                            if (Result.isSuccess(res)) {
                                Result.applyResult(res, stateTimerList, true);
                                AppManager.addToast(new Toast(errorManager.get(elementAddSuccess), 'success'));
                                onClose();
                            } else {
                                this.setState({ error: Result.getError(res) });
                            }
                        });
                    } else {
                        this.setState({ error: devisClientNoSelect, loading: false });
                    }
                }}
                onClose={() => {
                    onClose();
                }}
            >
                <Box className="">
                    <DevisClientSelect
                        special={true}
                        onObjectSelect={(e) => {
                            this.setState({
                                elem: e,
                            });
                        }}
                        elem={this.state.elem ?? undefined}
                        canAdd={true}
                        placeholder="Devis / Action"
                    />
                    <div className={'h-3'}></div>
                    <SectionSelect
                        onObjectSelect={(e) => {
                            this.setState({
                                section: e,
                            });
                        }}
                        elem={this.state.section ?? undefined}
                        canAdd={true}
                        placeholder="Section"
                    />
                </Box>
            </MpModal>
        );
    }
}
