import { AJOState } from 'mp-js-react-auto-json-object';
import { TableRowInterface } from './interface/TableRowInterface';
import { AJOProperties } from 'mp-js-react-auto-json-object';
import { Comptable } from './Comptable';
import { Paper } from './Paper';
import { TagObject } from './tag/TagObject';
import { MargeReduc } from './marge_reduc/MargeReduc';

export class Marge extends MargeReduc {
    public static _TYPE: string = 'Marge';

    constructor() {
        super(Marge._TYPE);
    }

    public static build(): Marge {
        return new Marge();
    }
}
