import { AJOObject, AJOProperties } from 'mp-js-react-auto-json-object';

export default class Nego extends AJOObject {
    public static _TYPE: string = 'Nego';

    public pourcent: AJOProperties<any>;

    constructor() {
        super(Nego._TYPE);

        this.pourcent = new AJOProperties('pourcent');
    }

    public pourcentMath(): number {
        return this.pourcent.get() / 100.0 + 1.0;
    }

    public static build(): Nego {
        return new Nego();
    }
}
