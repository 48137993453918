import { AJOObject, AJOProperties } from 'mp-js-react-auto-json-object';

export default class Permission extends AJOObject {
    public static ADMINISTRATOR = 0;

    public static MANAGE_DEVIS = 4;
    public static MANAGE_TEMPLATE = 1;
    public static BLOCK_ELEMENT = 2;
    public static UNBLOCK_ELEMENT = 3;

    public static MANAGE_USER = 5;
    public static MANAGE_ROLE = 6;
    public static MANAGE_VENDOR = 7;
    public static MANAGE_PRESTATION = 8;
    public static MANAGE_CATEGORY = 9;
    public static MANAGE_LABEL = 10;
    public static MANAGE_CLIENT = 11;
    public static MANAGE_HONORAIRE = 12;
    public static MANAGE_PRODUCT = 13;
    public static MANAGE_VA = 14;
    public static MANAGE_TAXE = 15;
    public static MANAGE_AGENCE = 17;
    public static MANAGE_ADDRESS = 18;
    public static MANAGE_SECTION = 19;

    public getTitle(): string {
        let typeInt = this.type.get();
        let res = '';
        if (typeInt === Permission.ADMINISTRATOR) {
            res = 'Administrateur';
        } else if (typeInt === Permission.MANAGE_TEMPLATE) {
            res = 'Gérer les templates';
        } else if (typeInt === Permission.BLOCK_ELEMENT) {
            res = 'Rendre des élément bloqué';
        } else if (typeInt === Permission.UNBLOCK_ELEMENT) {
            res = 'Passer outre les éléments bloqués';
        } else if (typeInt === Permission.MANAGE_DEVIS) {
            res = 'Gérer les prix de vente';
        } else if (typeInt === Permission.MANAGE_USER) {
            res = 'Gérer les utilisateurs';
        } else if (typeInt === Permission.MANAGE_ROLE) {
            res = 'Gérer les rôles';
        } else if (typeInt === Permission.MANAGE_VENDOR) {
            res = 'Gérer les fournisseurs';
        } else if (typeInt === Permission.MANAGE_PRESTATION) {
            res = 'Gérer les prestations';
        } else if (typeInt === Permission.MANAGE_CATEGORY) {
            res = 'Gérer les catégories';
        } else if (typeInt === Permission.MANAGE_LABEL) {
            res = 'Gérer les labels';
        } else if (typeInt === Permission.MANAGE_CLIENT) {
            res = 'Gérer les clients';
        } else if (typeInt === Permission.MANAGE_HONORAIRE) {
            res = 'Gérer les honoraires';
        } else if (typeInt === Permission.MANAGE_PRODUCT) {
            res = 'Gérer les offres';
        } else if (typeInt === Permission.MANAGE_VA) {
            res = 'Gérer les valeurs ajoutées';
        } else if (typeInt === Permission.MANAGE_TAXE) {
            res = 'Gérer les tva';
        }
        return res;
    }

    public static _TYPE: string = 'Permission';

    public type: AJOProperties<any>;

    constructor() {
        super(Permission._TYPE);

        this.type = new AJOProperties('type');
    }

    public static build(): Permission {
        return new Permission();
    }
}
