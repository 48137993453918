export default class MakeAction {
    public static DELETE_USER = 0;
    public static REVOK_USER = 4;
    public static ADD_USER = 1;
    public static PASSWORD_USER = 2;
    public static EDIT_USER = 3;
    public static CHANGE_ROLE = 5;
    public static RESET_PASSWORD = 6;
    public static ADD_ROLE = 7;
    public static DELETE_ROLE = 8;
    public static EDIT_ROLE = 9;

    private type: number;
    private test: (elem1: any, elem2: any) => boolean;
    constructor(type: number, test: (elem1: any, elem2: any) => boolean) {
        this.type = type;
        this.test = test;
    }
    public getType(): number {
        return this.type;
    }
    public can(elem1: any, elem2: any): boolean {
        return this.test(elem1, elem2);
    }
}
