import { useEffect, useRef, useState } from 'react';
import AppManager from '../AppManager';
import Utils from '../Utils';
import { JSXInternal } from 'preact/src/jsx';
import { Input } from './Input';

export interface IComplexInputProps extends JSXInternal.HTMLAttributes<HTMLInputElement> {
    typeValue?: 'eur' | 'number' | 'float' | 'usd' | 'pourcent' | 'select_search' | 'string';

    bgColor?: string;
    bgColorDark?: string;

    textColor?: string;
    textColorDark?: string;

    placeholderColor?: string;
    placeholderColorDark?: string;

    ringColor?: string;
    ringColorDark?: string;

    inputRef?: React.RefObject<HTMLInputElement>;

    onArrowDownKey?: (event?: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => void;
    onArrowUpKey?: (event?: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => void;
    onCtrlArrowDownKey?: (event?: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => void;
    onCtrlArrowUpKey?: (event?: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => void;

    onBlurSend?: (value: string) => void;

    setValue: (e: string) => void;
    value: string;

    compliteText?: string;
    beforeText?: string;

    pt?: number;
    pb?: number;
    pl?: number;
    pr?: number;

    h?: number;
    focusInput?: boolean;
    forceFocus?: boolean;
    block?: boolean;

    onBlur?: (e: JSXInternal.TargetedFocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: JSXInternal.TargetedFocusEvent<HTMLInputElement>) => void;

    onKeyUp?: (e: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => void;

    onChange?: (event: JSXInternal.TargetedEvent<HTMLInputElement, Event>) => void;
}

export const ComplexInput: React.FunctionComponent<IComplexInputProps> = (props) => {
    const [isFocus, setIsFocus] = useState(false);

    const {
        setValue,
        value,
        compliteText = '',
        beforeText = '',
        typeValue = 'eur',
        bgColor = 'gray-200',
        bgColorDark = 'gray-500',
        textColor = 'black',
        textColorDark = 'white',
        placeholderColor = 'gray-500',
        placeholderColorDark = 'gray-300',
        ringColor = 'blue-500',
        ringColorDark = 'blue-400',
        focusInput,
        forceFocus = false,
        pt = 2,
        pb = 2,
        pl = 3,
        pr = 3,
        h = undefined,
        block = false,
        inputRef,
        onBlurSend,
        onArrowDownKey,
        onArrowUpKey,
        onCtrlArrowDownKey,
        onCtrlArrowUpKey,
        onClick,
        disabled = false,
        ...rest
    } = props;
    let ref = useRef<HTMLInputElement>(null);
    if (inputRef != undefined) {
        ref = inputRef;
    }

    useEffect(() => {
        if (focusInput === true) {
            ref.current?.focus();
            if (forceFocus) {
                setTimeout(() => {
                    ref.current?.focus();
                }, 200);
            }
        }
    }, [focusInput]);

    let classNameList = [
        'block',
        'pl-' + pl,
        'pr-' + pr,
        'pb-' + pb,
        'pt-' + pt,
        'text-' + textColor,
        'rounded-lg',
        'bg-' + bgColor,
        'border',
        'border-gray-400',
        'dark:border-gray-300',
        'focus:ring-blue-500',
        'focus:border-blue-500',
        'dark:bg-' + bgColorDark,
        'dark:text-' + textColorDark,
        'dark:focus:ring-red-900',
        'dark:focus:border-red-900',
    ];

    if (isFocus) {
        classNameList.push(
            'outline-none',
            'ring',
            'ring-' + ringColor,
            'dark:ring-' + ringColorDark,
            'dark:border-blue-600',
        );
    }

    classNameList.push((props.className ?? '') as string);
    let classNameDiv = Utils.compressClass(props, 'relative', 'flex', ...classNameList);
    let classNameInput = Utils.compressClass(
        {},
        'outline-0',
        'w-full',
        '',
        'appearance-none',
        'bg-transparent',
        'placeholder:text-' + placeholderColor,
        'dark:placeholder:text-' + placeholderColorDark + ' ',
    );
    if (h != undefined) {
        classNameInput += ' ' + 'h-' + h;
    }
    let onFocus = (event: JSXInternal.TargetedFocusEvent<HTMLInputElement>) => {
        setIsFocus(true);
        if (props.onFocus != undefined) {
            props.onFocus(event);
        }
    };
    let onBlur = (event: JSXInternal.TargetedFocusEvent<HTMLInputElement>) => {
        setIsFocus(false);
        setValue(transformString(value, true));
        let endValue = value;
        if (props.onBlur != undefined) {
            props.onBlur(event);
        }
        if (props.onBlurSend != undefined) {
            props.onBlurSend(transformString(endValue, true));
        }
    };
    let chainMove = (ctrl: number, up: number, down: number) => {
        let move = makeMove(ctrl, up, down);
        if (move) {
            setTimeout(() => {
                chainMove(ctrl, up, down);
            }, 500);
        }
    };
    let makeMove = (
        ctrl: number,
        up: number,
        down: number,
        event?: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>,
    ): boolean => {
        let res: boolean = false;
        if (
            ctrl !== -1 &&
            up !== -1 &&
            ctrl < up &&
            ctrl === AppManager.getDatePress(AppManager.CTRL_LEFT_KEY) &&
            up === AppManager.getDatePress(AppManager.UP_KEY)
        ) {
            let onCtrlUpKey = props.onCtrlArrowUpKey;
            if (onCtrlUpKey != undefined) {
                onCtrlUpKey(event);
            }
        } else if (
            ctrl !== -1 &&
            down !== -1 &&
            ctrl < down &&
            ctrl === AppManager.getDatePress(AppManager.CTRL_LEFT_KEY) &&
            down === AppManager.getDatePress(AppManager.DOWN_KEY)
        ) {
            let onCtrlDownKey = props.onCtrlArrowDownKey;
            if (onCtrlDownKey != undefined) {
                onCtrlDownKey(event);
            }
        } else if (up !== -1 && up === AppManager.getDatePress(AppManager.UP_KEY)) {
            let onUpKey = props.onArrowUpKey;
            if (onUpKey != undefined) {
                onUpKey(event);
            }
        } else if (down !== -1 && down === AppManager.getDatePress(AppManager.DOWN_KEY)) {
            let onDownKey = props.onArrowDownKey;
            if (onDownKey != undefined) {
                onDownKey(event);
            }
        }
        if (typeof value === 'string') {
            if (
                typeValue === 'eur' ||
                typeValue === 'float' ||
                typeValue === 'usd' ||
                typeValue === 'pourcent' ||
                typeValue === 'number'
            ) {
                if (value.length !== 0) {
                    let val;
                    let end = '';
                    let indexDot = value.indexOf('.');
                    if (indexDot !== -1) {
                        end = value.substring(indexDot);
                    }
                    if (
                        ctrl !== -1 &&
                        up !== -1 &&
                        ctrl < up &&
                        ctrl === AppManager.getDatePress(AppManager.CTRL_LEFT_KEY) &&
                        up === AppManager.getDatePress(AppManager.UP_KEY)
                    ) {
                        val = parseFloat(value) + 10;
                    } else if (
                        ctrl !== -1 &&
                        down !== -1 &&
                        ctrl < down &&
                        ctrl === AppManager.getDatePress(AppManager.CTRL_LEFT_KEY) &&
                        down === AppManager.getDatePress(AppManager.DOWN_KEY)
                    ) {
                        if (parseFloat(value) <= 10) {
                            end = end.replace(/[1-9]/g, '0');
                        }
                        val = parseFloat(value) - 10;
                    } else if (up !== -1 && up === AppManager.getDatePress(AppManager.UP_KEY)) {
                        val = parseFloat(value) + 1;
                    } else if (down !== -1 && down === AppManager.getDatePress(AppManager.DOWN_KEY)) {
                        if (parseFloat(value) <= 1) {
                            end = end.replace(/[1-9]/g, '0');
                        }
                        val = parseFloat(value) - 1;
                    }
                    if (val !== undefined) {
                        res = true;
                        setValue(transformString(Math.max(Math.floor(val), 0.0).toFixed(0) + end));
                    }
                } else {
                    if (
                        ctrl !== -1 &&
                        up !== -1 &&
                        ctrl < up &&
                        ctrl === AppManager.getDatePress(AppManager.CTRL_LEFT_KEY) &&
                        up === AppManager.getDatePress(AppManager.UP_KEY)
                    ) {
                        res = true;
                        setValue(10 + '');
                    } else if (
                        ctrl !== -1 &&
                        down !== -1 &&
                        ctrl < down &&
                        ctrl === AppManager.getDatePress(AppManager.CTRL_LEFT_KEY) &&
                        down === AppManager.getDatePress(AppManager.DOWN_KEY)
                    ) {
                        res = true;
                        setValue(0 + '');
                    } else if (up !== -1 && up === AppManager.getDatePress(AppManager.UP_KEY)) {
                        res = true;
                        setValue(1 + '');
                    } else if (down !== -1 && down === AppManager.getDatePress(AppManager.DOWN_KEY)) {
                        res = true;
                        setValue(0 + '');
                    }
                }
            }
        }
        return res;
    };
    let onKeyPress = (event: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => {
        let ctrl = AppManager.getDatePress(AppManager.CTRL_LEFT_KEY);
        let up = AppManager.getDatePress(AppManager.UP_KEY);
        let down = AppManager.getDatePress(AppManager.DOWN_KEY);
        makeMove(ctrl, up, down, event);
        setTimeout(() => {
            chainMove(ctrl, up, down);
        }, 1000);
    };
    let onKeyUp = (event: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => {
        let onKeyUp = props.onKeyUp;
        if (onKeyUp !== undefined) {
            onKeyUp(event);
        }
    };
    let onKeyDown = (event: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => {
        onKeyPress(event);
        let onKeyDown = props.onKeyDown;
        if (onKeyDown !== undefined) {
            onKeyDown(event);
        }
    };
    let transformString = (value: string, hard: boolean = false): string => {
        value = value + '';
        let res = value;
        if (typeValue === 'eur' || typeValue === 'usd' || typeValue === 'pourcent' || typeValue === 'float') {
            let newValue = value.replace(',', '.');
            newValue = newValue.replace(/[^\d.]/g, '');

            let countDot = 0;
            let foundDot = false;
            let countAfterDot = 0;

            for (let i = 0; i < newValue.length; i++) {
                if (foundDot) {
                    countAfterDot++;
                }

                if (newValue[i] === '.') {
                    countDot++;
                    foundDot = true;
                }
            }

            if (countAfterDot > 2) {
                newValue = newValue.substring(0, newValue.length - countAfterDot + 2);
            }

            if (countDot > 1) {
                newValue = value;
            }

            res = newValue;
        }
        if (typeValue === 'number') {
            let newValue = value.replace(',', '.');
            newValue = newValue.replace(/[^\d.]/g, '');
            res = newValue;
        }
        if (hard) {
            if (typeValue === 'eur') {
                if (res.length === 0) {
                    //res = '0.00';
                } else {
                    let indexDot = res.indexOf('.');
                    if (indexDot === -1) {
                        res = res + '.00';
                    } else if (indexDot === res.length - 1) {
                        res = res + '00';
                    } else if (indexDot === res.length - 2) {
                        res = res + '0';
                    }
                }
            } else if (typeValue === 'usd') {
                if (res.length === 0) {
                    //res = '0.00';
                } else {
                    let indexDot = res.indexOf('.');
                    if (indexDot === -1) {
                        res = res + '.00';
                    } else if (indexDot === res.length - 1) {
                        res = res + '00';
                    } else if (indexDot === res.length - 2) {
                        res = res + '0';
                    }
                }
            }
        }
        return res;
    };
    let onChange = (event: JSXInternal.TargetedEvent<HTMLInputElement, Event>) => {
        let newValue = transformString((event.target as any)?.value);
        setValue(newValue);
        let onChange = props.onChange;
        if (onChange != null) {
            onChange(event);
        }
    };

    let onMouseDown = (event: JSXInternal.TargetedMouseEvent<HTMLDivElement>) => {
        if (ref?.current !== document.activeElement) {
            (ref?.current as HTMLInputElement).focus();
        }

        event.preventDefault();
        event.stopPropagation();

        if (onClick !== undefined) {
            (onClick as any)();
        }
    };

    let end: any;
    let start: any;
    let center: any;
    if (typeValue === 'eur') {
        end = '€';
    } else if (typeValue === 'usd') {
        end = '$';
    } else if (typeValue === 'select_search') {
        center = (
            <span className=" absolute flex">
                <span className="opacity-0">{beforeText.replaceAll(' ', '\u00a0')}</span>
                <div className="bg-blue-600">
                    <span className="bg-blue-600 text-white whitespace-nowrap">
                        {compliteText.replaceAll(' ', '\u00a0')}
                    </span>
                </div>
            </span>
        );
        end = (
            <div
                className={
                    'flex text-' +
                    placeholderColor +
                    ' dark:text-' +
                    placeholderColorDark +
                    ' inset-y-0 left-0 items-center pointer-events-none'
                }
            >
                <svg
                    className="ml-1 w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    ></path>
                </svg>
            </div>
        );
    } else if (typeValue === 'pourcent') {
        end = '%';
    }

    return (
        <div
            className={classNameDiv + ' relative ' + (disabled ? 'opacity-[0.6]' : '')}
            onClick={(e) => {
                onMouseDown(e);
            }}
        >
            <span className="select-none flex items-center">{start}</span>
            {center}
            <Input
                {...rest}
                disabled={disabled}
                onFocus={onFocus}
                onBlur={onBlur}
                className={classNameInput}
                onChange={(e) => {
                    onChange(e);
                }}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                value={transformString(value ?? '')}
                inputRef={ref}
            />
            <span className="select-none flex items-center">{end}</span>

            {block && (
                <div
                    className="text-center inset-0 absolute z-[40]"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                ></div>
            )}
        </div>
    );
};
