import { AJOUpdater } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import { Button } from '../../commun/component/Button';
import Utils from '../../commun/Utils';
import { AddPaperModal } from '../../modal/vendor/AddPaperModal';
import BasePrice from '../../object/BasePrice';
import { Paper } from '../../object/Paper';
import { LittleAddButton } from '../LittleAddButton';
import { PaperView } from './PaperView';

export interface IPaperListProps {
    paperList: Paper[];
    basePrice: BasePrice;

    id: string;
    field: string;
    selectedPaper: string | null;
    onClick: (paper: Paper) => void;
    className?: string;
    canAdd?: boolean;
}
/**
 * Build a paper list view based on the list of paper object.
 * @param props : IPaperListProps { paperList: Paper[] }
 * @returns JSX.Element : PaperList construc a reprensentation of the paper list
 */
export const PaperList: React.FC<IPaperListProps> = (props) => {
    const { paperList, basePrice, onClick, canAdd = false, className = '', field, selectedPaper, id } = props;

    // boolean to show add modal or not
    const [showAddModal, updateShowAddModal] = useState(false);

    // Check if user can add aper

    // Element to add a paper
    let addBlock;
    if (canAdd) {
        addBlock = [
            <div key="0">
                <LittleAddButton
                    text=""
                    onAdd={() => {
                        updateShowAddModal(true);
                    }}
                ></LittleAddButton>
            </div>,
            <AddPaperModal
                key={'2'}
                field={field.toLowerCase()}
                id={id}
                handleClose={() => updateShowAddModal(false)}
                show={showAddModal}
            />,
        ];
    }
    return (
        <div className={'flex gap-2 flex-row ' + className}>
            {addBlock}
            {paperList.map((paper, index) => {
                return (
                    <div key={'paperview-' + index}>
                        <PaperView
                            onClick={() => onClick(paper)}
                            selected={paper.getAjoIdentifier() == selectedPaper}
                            paper={paper}
                        ></PaperView>
                    </div>
                );
            })}
        </div>
    );
};
