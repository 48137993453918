import { AJOObject, AJOProperties } from 'mp-js-react-auto-json-object';

export default class Taxe extends AJOObject {
    public static _TYPE: string = 'Taxe';

    public code: AJOProperties<any>;
    public pourcent: AJOProperties<any>;

    constructor() {
        super(Taxe._TYPE);

        this.code = new AJOProperties('code');
        this.pourcent = new AJOProperties('pourcent');
    }

    public pourcentMath(): number {
        return this.pourcent.get() / 100.0 + 1.0;
    }

    public static build(): Taxe {
        return new Taxe();
    }
}
