import { AJOList, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import AppManager from '../commun/AppManager';
import { get } from '../commun/HTTP';
import Result from '../commun/Result';
import { CategoryHonoraire } from '../object/CategoryHonoraire';
import Category from '../object/tag/Category';
import CategoryEvent from '../object/tag/CategoryEvent';
import Label from '../object/tag/Label';
import Product from '../object/tag/Product';
import { TagList } from './TagList';

export interface ITagListFetchProps {
    field: string;
    strongSimple?: boolean;
    selectList: (Category | Label)[];
    onSelectList: (list: any[]) => void;
    simple: boolean;
    canAdd: boolean;
}
export const TagListFetch: React.FC<ITagListFetchProps> = (props) => {
    const { field, strongSimple = false, selectList = [], onSelectList, simple, canAdd } = props;

    const [stateList, setStateList] = useState(
        new AJOState(
            new AJOList<Category | Label | CategoryEvent | CategoryHonoraire | Product>([
                Category,
                Label,
                Product,
                CategoryHonoraire,
                CategoryEvent,
            ]),
        ),
    );
    stateList.setUpdate(setStateList);

    const [loading, setLoading] = useState(true);

    const fetch = () => {
        get('/' + field + '/list').then((res: any) => {
            setLoading(false);
            Result.applyResult(res, stateList, true);
        });
    };

    useEffect(() => {
        fetch();
    }, []);

    const onCheck = (item: Category | CategoryHonoraire | Label) => {
        let list;
        if (simple) {
            if (!strongSimple) {
                if (selectList.find((i) => i.getAjoIdentifier() === item.getAjoIdentifier()) !== undefined) {
                    list = selectList.filter((elem) => elem.getAjoIdentifier() !== item.getAjoIdentifier());
                } else {
                    list = [item];
                }
            } else {
                list = [item];
            }
        } else {
            if (selectList.find((i) => i.getAjoIdentifier() === item.getAjoIdentifier()) !== undefined) {
                list = selectList.filter((elem) => elem.getAjoIdentifier() !== item.getAjoIdentifier());
            } else {
                list = [...selectList, item];
            }
        }
        onSelectList(list);
    };

    AppManager.updater().add(stateList);

    return (
        <TagList
            field={field}
            onCheck={onCheck}
            list={stateList.get()!.getList()}
            checkMode={true}
            selectList={selectList}
            canAdd={canAdd}
            loading={loading}
        />
    );
};
