import { AJOElement, AJOList, AJOObject, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { title } from 'process';
import { useEffect, useState } from 'react';
import { ComplexInput } from '../../commun/component/ComplexInput';
import { ComplexSelect } from '../../commun/component/ComplexSelect';
import { Color, ModalBottomMode } from '../../commun/Constant';
import ErrorHTTP from '../../commun/ErrorHTTP';
import ErrorManager from '../../commun/ErrorManager';
import ErrorMessage from '../../commun/ErrorMessage';
import { get, post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Utils from '../../commun/Utils';
import { SelectColor } from '../../component/SelectColor';
import Category from '../../object/tag/Category';
import Client from '../../object/profile/Client';
import Devis from '../../object/Devis';
import Label from '../../object/tag/Label';
import Prestation from '../../object/profile/Prestation';
import Vendor from '../../object/profile/Vendor';
import { categoryAddSuccess, categoryEditSuccess, errorManager, labelEditSuccess } from './CategoryErrorManager';
import Product from '../../object/tag/Product';
import Toast from '../../commun/toast/Toast';
import AppManager from '../../commun/AppManager';
import Service from '../../object/Service';
import { Marge } from '../../object/Marge';
import { VirgineListComplexSelect } from '../../component/VirgineListComplexSelect';
import { ListComplexSelect } from '../../component/ListComplexSelect';

export interface ICategoryModalProps {
    handleClose: () => void;

    parent?: AJOElement;

    show: boolean;
    field: string;

    elem?: Category | Label | null;
    action: 'new' | 'join' | 'edit';

    title: string;
    button: string;
    placeholder: string;
    successError: ErrorHTTP;
}

export const CategoryModal: React.FC<ICategoryModalProps> = (props) => {
    const { field, action, elem, title, button, placeholder, handleClose, show, parent, successError } = props;

    const [stateList, updateStateList] = useState(new AJOState<AJOList<AJOObject>>(new AJOList<AJOObject>()));
    stateList.setUpdate(updateStateList);

    const [selectId, updateSelectId] = useState('-1');
    const [loading, updateLoad] = useState(false);
    const [error, updateError] = useState(ErrorHTTP.NO_ERROR());

    const [newCat, updateNewCate] = useState(false);

    const [name, updateName] = useState(elem?.name.get() ?? '');
    const [margeMax, updateMargeMax] = useState('');
    const [margeMin, updateMargeMin] = useState('');
    const [color, updateColor] = useState<Color>(elem?.getColor() ?? 'blue');

    useEffect(() => {
        if (!show) {
            updateError(ErrorHTTP.NO_ERROR());
            updateLoad(false);

            updateName(elem?.name.get() ?? '');
            updateColor(elem?.getColor() ?? 'blue');
            if (elem instanceof Product) {
                updateMargeMin(elem.min.get() ?? '');
                updateMargeMax(elem.max.get() ?? '');
            } else if (elem instanceof Marge) {
                updateMargeMin(elem.value.get() ?? '');
            }

            updateSelectId('-1');
            updateNewCate(false);
        }
    }, [show]);

    const fetchList = () => {
        if (action === 'join') {
            get('/' + field + '/list', {}).then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, stateList, true);
                }
            });
        }
    };

    useEffect(() => {
        if (show) {
            fetchList();
            stateList.get()!.reset();
        }
    }, [show]);

    const onAddCategory = () => {
        updateLoad(true);
        updateError(ErrorHTTP.NO_ERROR());

        if (action === 'join') {
            let params: any = {
                id: parent!.getAjoIdentifier(),
            };

            const cat: any = {};
            if (!newCat) {
                cat['id'] = selectId;
            } else {
                cat['name'] = name;
                cat['color'] = color;
            }
            let link = '';
            if (parent instanceof Service) {
                if (field === 'category') {
                    link = '/prestation/update';
                    if (parent.prestation.get() != null) {
                        params = {
                            id: parent.prestation.get()!.getAjoIdentifier(),
                            category: [cat],
                        };
                    } else {
                        params = {
                            id: parent.getAjoParent()!.getAjoParent()!.getAjoIdentifier(),
                            category: [cat],
                        };
                    }
                } else {
                    link = '/service/update';
                    params = {
                        id: parent.getAjoIdentifier(),
                        label: [cat],
                    };
                }
            } else {
                link = '/' + parent!.getAjoType().toLowerCase() + '/update';
                params[field] = cat;
            }
            post(link, params).then((res: any) => {
                updateLoad(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, AppManager.updater(), true);
                    Result.applyResult(res, parent, true);
                    if (newCat) {
                        fetchList();
                    }
                    updateError(successError);

                    AppManager.addToast(new Toast(errorManager.get(successError), 'success'));
                    props.handleClose();
                } else {
                    updateError(Result.getError(res));
                }
            });
        } else {
            updateLoad(true);
            if (field === 'marge') {
                const paramsMarge: any = {};
                if (action === 'edit') {
                    paramsMarge['id'] = elem!.getAjoIdentifier();
                }
                paramsMarge['name'] = name;
                paramsMarge['color'] = color;
                paramsMarge['pourcent'] = parseFloat(margeMin);
                const finalParams = {
                    id: parent!.getAjoIdentifier(),
                    marge: paramsMarge,
                };
                post('/' + parent!.getAjoType().toLowerCase() + '/update', finalParams).then((res: any) => {
                    updateLoad(false);
                    if (Result.isSuccess(res)) {
                        Result.applyResult(res, AppManager.updater(), true);
                        Result.applyResult(res, parent, true);
                        updateError(successError);

                        AppManager.addToast(new Toast(errorManager.get(successError), 'success'));
                        props.handleClose();
                    } else {
                        updateError(Result.getError(res));
                    }
                });
            } else {
                const params: any = {};
                if (action === 'edit') {
                    params['id'] = elem!.getAjoIdentifier();
                }
                params['name'] = name;
                params['color'] = color;
                params['max'] = parseFloat(margeMax);
                params['min'] = parseFloat(margeMin);
                post('/' + field + '/update', params).then((res: any) => {
                    updateLoad(false);
                    if (Result.isSuccess(res)) {
                        Result.applyResult(res, AppManager.updater(), true);
                        Result.applyResult(res, parent, true);
                        updateError(successError);

                        AppManager.addToast(new Toast(errorManager.get(successError), 'success'));
                        props.handleClose();
                    } else {
                        updateError(Result.getError(res));
                    }
                });
            }
        }
    };
    let content;
    if (action === 'edit') {
        content = (
            <div className="flex flex-col gap-4">
                <ComplexInput typeValue="string" setValue={updateName} value={name} placeholder={placeholder} />

                {field !== 'marge' && (
                    <div className="flex flex-col gap-4">
                        <SelectColor color={color} onSelect={(color) => updateColor(color)} />
                    </div>
                )}
                {field === 'marge' && (
                    <ComplexInput
                        typeValue="pourcent"
                        setValue={updateMargeMin}
                        value={margeMin}
                        placeholder={'Marge'}
                    />
                )}
                {field === 'product' && (
                    <ComplexInput
                        typeValue="pourcent"
                        setValue={updateMargeMin}
                        value={margeMin}
                        placeholder={'Marge brute minimal'}
                    />
                )}
                {field === 'product' && (
                    <ComplexInput
                        typeValue="pourcent"
                        setValue={updateMargeMax}
                        value={margeMax}
                        placeholder={'Marge brute maximal'}
                    />
                )}
            </div>
        );
    } else if (action === 'new') {
        content = (
            <div className="flex flex-col gap-4">
                <ComplexInput typeValue="string" setValue={updateName} value={name} placeholder={placeholder} />
                {field !== 'marge' && (
                    <div className="flex flex-col gap-4">
                        <SelectColor color={color} onSelect={(color) => updateColor(color)} />
                    </div>
                )}
                {field === 'marge' && (
                    <ComplexInput
                        typeValue="pourcent"
                        setValue={updateMargeMin}
                        value={margeMin}
                        placeholder={'Marge'}
                    />
                )}
                {field === 'product' && (
                    <ComplexInput
                        typeValue="pourcent"
                        setValue={updateMargeMin}
                        value={margeMin}
                        placeholder={'Marge brute minimal'}
                    />
                )}
                {field === 'product' && (
                    <ComplexInput
                        typeValue="pourcent"
                        setValue={updateMargeMax}
                        value={margeMax}
                        placeholder={'Marge brute maximal'}
                    />
                )}
            </div>
        );
    } else if (action === 'join') {
        content = (
            <div className="flex flex-col gap-4">
                <ComplexSelect
                    add={true}
                    onAdd={(name) => {
                        updateName(name);
                        updateNewCate(true);
                        updateColor('blue');
                    }}
                    cancelAdd={() => {
                        updateNewCate(false);
                    }}
                    loadTitle={(elem: any) => elem.name.get()}
                    selectId={selectId}
                    placeholder={placeholder}
                    onSelectIdChange={(select) => {
                        updateSelectId(select);
                    }}
                    filter={(elem, search) => Utils.clean(elem.name.get()).startsWith(Utils.clean(search))}
                    color="base"
                    elemList={stateList.get()!.getList()}
                ></ComplexSelect>
                {newCat && (
                    <div className="flex flex-col gap-4">
                        <p>La catéogrie {name} n'existe pas vous pourrez l'ajouter après avoir choisit sa couleur.</p>
                        <SelectColor color={color} onSelect={(color) => updateColor(color)} />
                    </div>
                )}
            </div>
        );
    }
    return (
        <Modal
            onDone={onAddCategory}
            show={show}
            done={button}
            bottomMode={'action'}
            title={title}
            loading={loading}
            color={'blue'}
            error={error}
            errorManager={errorManager}
            handleClose={() => {
                handleClose();
                updateError(ErrorHTTP.NO_ERROR());
            }}
        >
            {content}
        </Modal>
    );
};
