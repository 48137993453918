import { Box, TextField, Typography } from '@mui/material';
import { Component, createRef } from 'react';
import Contact from 'src/object/Contact';
import Client from 'src/object/profile/Client';
import AppManager from '../../commun/AppManager';
import { Spinner } from '../../commun/component/Spinner';
import { post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import Vendor from '../../object/profile/Vendor';
import { getErrorMessage } from '../../utils/Error';
import MpModal, { IBaseModalProps } from '../extends/MpModal';

export interface IDelContactModalProps extends IBaseModalProps {
    contact: Contact | null;
    baseName?: string;
    vendor: Vendor | Client;
    onCreate?: (contact: Contact) => void;
}

export interface IDelContactModalState {
    name: string;
    email: string;
    loading: boolean;
    error: string;
}

export default class DelContactModal extends Component<IDelContactModalProps, IDelContactModalState> {
    inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: IDelContactModalProps) {
        super(props);

        this.state = this.initState();
        this.inputRef = createRef();
    }

    initState() {
        const p: IDelContactModalState = {
            name: this.props.contact?.name.get() ?? this.props.baseName ?? '',
            email: this.props.contact?.email.get() ?? '',
            loading: false,
            error: '',
        };
        return p;
    }

    componentDidUpdate(
        prevProps: Readonly<IDelContactModalProps>,
        prevState: Readonly<IDelContactModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.open !== this.props.open) {
            this.setState(this.initState());
        }
        if (prevProps.open !== this.props.open && this.props.open) {
            setTimeout(() => {
                this.inputRef.current?.focus();
            });
        }
    }

    addPrestation() {
        const { loading, name, email } = this.state;
        const { contact, vendor } = this.props;
        this.setState({
            loading: true,
            error: '',
        });
        let params: any;
        let url: string;
        url = '/' + vendor.getAjoType().toLowerCase() + '/update';
        params = {
            id: vendor.getAjoIdentifier(),
            contact: {
                id_del: contact?.getAjoIdentifier(),
            },
        };
        post(url, params).then((res: any) => {
            this.setState({
                loading: false,
            });
            if (Result.isSuccess(res)) {
                try {
                    Result.applyResult(res, AppManager.updater(), true);
                } catch {}

                this.props.onClose();
                AppManager.addToast(new Toast('Le contact a été supprimé avec succès.', 'success'));
            } else {
                this.setState({
                    error: getErrorMessage(Result.getErrorStr(res)),
                });
            }
        });
    }

    render() {
        const { open, contact, onClose } = this.props;
        const { loading, email, name, error } = this.state;

        return (
            <MpModal
                open={open}
                onClose={onClose}
                onSubmit={() => {
                    this.addPrestation();
                }}
                doneColor={'failure'}
                title={'Supprimer un contact'}
                done={'Supprimer'}
            >
                {loading ? (
                    <Box className="flex items-center justify-center py-12">
                        <Spinner color="red" className="" />
                    </Box>
                ) : (
                    <Box className="flex flex-col !text-gray-600 gap-2">
                        <Typography className="!text-sm !font-semibold">
                            Êtes-vous sûr de vouloir supprimer cette ligne, cette action est irréversible.
                        </Typography>
                        {error !== '' && (
                            <Typography
                                component={'p'}
                                className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                            >
                                {error}
                            </Typography>
                        )}
                    </Box>
                )}
            </MpModal>
        );
    }
}
