import { Button } from './Button';
import { ComplexInput } from './ComplexInput';
import { JSXInternal } from 'preact/src/jsx';

export interface ISearchInputProps extends JSXInternal.HTMLAttributes<HTMLInputElement> {
    inputRef?: React.RefObject<HTMLInputElement>;
    setValue: (a: any) => any;
    value: string;
}

export const SearchInput: React.FunctionComponent<ISearchInputProps> = (props) => {
    const { setValue, value, ...rest } = props;

    return (
        <div className="flex flex-row w-full gap-3">
            <div className="flex-1">
                <ComplexInput
                    value={value}
                    setValue={setValue}
                    placeholder={'Rechercher'}
                    typeValue="string"
                    h={5}
                    pl={2.5}
                    pr={2.5}
                    pt={2.5}
                    pb={2.5}
                    {...(rest as any)}
                />
            </div>
            <Button color="blue" pl={2.5} pr={2.5} pt={2.5} pb={2.5} className="z-[4] top-0 right-0" selected={true}>
                <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                </svg>
            </Button>
        </div>
    );
};
