import { Typography } from '@mui/material';

export const NoteView = (props: { note: number }) => {
    let res;
    if (isNaN(props.note)) {
        res = <Typography>Aucune note</Typography>;
    } else {
        res = <Typography>{props.note} / 5</Typography>;
    }
    return res;
};
