import { Box, Card, CardContent, Chip, Container, Typography } from '@mui/material';
import { AJOList, AJOState } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';
import AppManager from '../commun/AppManager';
import { Spinner } from '../commun/component/Spinner';
import { get, post } from '../commun/HTTP';
import Result from '../commun/Result';
import Utils from '../commun/Utils';
import { BasePage } from '../component/BasePage';
import { Separator } from '../component/Separator';
import BasePrice from '../object/BasePrice';
import { Message } from '../object/Message';

export const MessagePage = () => {
    AppManager.startUpdater();

    const [loading, setLoading] = useState(true);
    const [stateMessageList, setStateMessageList] = useState(new AJOState(new AJOList<Message>(Message)));
    stateMessageList.setUpdate(setStateMessageList);

    AppManager.updater().add(stateMessageList);

    useEffect(() => {
        get('/message/get', {}).then((res: any) => {
            setLoading(false);
            if (Result.isSuccess(res)) {
                Result.applyResult(res, stateMessageList, true);
            }
        });
        get('/timer/see', {}).then((res: any) => {
            if (Result.isSuccess(res)) {
                Result.applyResult(res, AppManager.getUser(), true);
            }
        });
    }, []);

    return (
        <BasePage className="flex-col flex items-center">
            <DashboardNavBar />
            <Container
                sx={{
                    mx: 3,
                    mb: 3,
                }}
            >
                <Box sx={{ mb: 3, mt: 3 }}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            m: -1,
                        }}
                    >
                        <Typography sx={{ m: 1 }} variant="h4">
                            Vos messages
                        </Typography>
                    </Box>
                </Box>
                {loading && (
                    <Card>
                        <Box className="flex flex-col justify-center items-center p-24">
                            <Spinner color="blue" />
                        </Box>
                    </Card>
                )}
                {!loading &&
                    stateMessageList
                        .get()!
                        .getList()!
                        .map((message, index) => {
                            return (
                                <Card sx={{ mt: 3 }} className="w-full flex flex-col gap-3">
                                    <CardContent className="flex flex-col gap-2">
                                        <div className="flex flex-row items-center">
                                            <Typography className="flex-1" variant="h6">
                                                {message.content.get()}
                                            </Typography>
                                            {message.content.get().includes('acceptée') ? (
                                                <Chip size="medium" color="success" label="Accepté" />
                                            ) : (
                                                <Chip size="medium" color="error" label="Refusé" />
                                            )}
                                        </div>
                                        <Typography>{'Réponse le ' + Utils.toDDMMYY(message.date.get())}</Typography>
                                    </CardContent>
                                </Card>
                            );
                        })}
            </Container>
        </BasePage>
    );
};
/*
 {stateMessageList
                            .get()!
                            .getList()!
                            .map((message, index) => {
                                return (
                                    <div className="w-full flex flex-col gap-3">
                                        {index != 0 && <Separator />}
                                        <div className="flex flex-row items-center">
                                            <span className="text-lg flex-1 font-medium">{message.content.get()}</span>
                                            {message.content.get().includes('acceptée') ? (
                                                <div
                                                    className={
                                                        'bg-green-600 font-medium flex items-center justify-center px-2.5 py-0.5 text-white rounded-full'
                                                    }
                                                >
                                                    <span>Accepté</span>
                                                </div>
                                            ) : (
                                                <div
                                                    className={
                                                        'bg-red-600 font-medium flex items-center justify-center px-2.5 py-0.5 text-white rounded-full'
                                                    }
                                                >
                                                    <span>Refusé</span>
                                                </div>
                                            )}
                                        </div>
                                        <span className="text-lg">
                                            {'Réponse le ' + Utils.toDDMMYY(message.date.get())}
                                        </span>
                                    </div>
                                );
                            })} */
