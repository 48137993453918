import { AJOProperties, AJOObject } from 'mp-js-react-auto-json-object';
import { TagObject } from './tag/TagObject';
export class CategoryHonoraire extends TagObject {
    public static _TYPE: string = 'CategoryHonoraire';

    constructor() {
        super(CategoryHonoraire._TYPE);
    }

    public static build(): CategoryHonoraire {
        return new CategoryHonoraire();
    }
}
