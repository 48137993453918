import { AJOList, AJOObject } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import AppManager from '../../commun/AppManager';
import { JSXInternal } from 'preact/src/jsx';
import { PerfectAJOList } from '../../commun/object/PerfectAJOList';
import Budget from '../../object/Budget';
import Vendor from '../../object/profile/Vendor';
import VendorModal from '../vendor/VendorModal';
import { CustomSelect } from './CustomSelect';
import React from 'preact/compat';
import BasePrice from 'src/object/BasePrice';

export interface IVendorSelectProps {
    vendorList: PerfectAJOList<Vendor>;
    budget: Budget;
    basePrice: BasePrice;
}

export const VendorSelect = (props: IVendorSelectProps) => {
    const { vendorList, budget, basePrice } = props;

    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');

    return (
        <>
            <CustomSelect
                disabled={budget.bon_commande.get() !== null || !basePrice.canAct()}
                list={vendorList.getList()}
                id={'vendor-' + budget.getAjoIdentifier()}
                value={budget.vendor.get()}
                getValue={(elem: any) => elem.name.get() ?? ''}
                placeholder="Fourisseur"
                onBlur={() => {
                    budget.leaveFocus();
                }}
                onFocus={() => {
                    budget.getFocus();
                    setName('');
                    vendorList?.need();
                }}
                onCreate={(str: string) => {
                    setName(str);
                    setOpen(true);
                }}
                onSelect={(item: any) => {
                    if (item instanceof Vendor) {
                        budget.vendor.set(item);
                        budget.addEdit({
                            temp_vendor: item.getAjoIdentifier(),
                            temp_prestation: budget.prestation.get()?.getAjoIdentifier() ?? null,
                        });
                        AppManager.makeUpdate();
                    } else if (budget.vendor.get() !== null) {
                        budget.vendor.set(null);
                        budget.addEdit({
                            temp_vendor: '-1',
                        });
                        AppManager.makeUpdate();
                    }
                }}
            />
            <VendorModal
                vendor={null}
                baseName={name}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                onCreate={(vendor: Vendor) => {
                    budget.addEdit({
                        vendor: {
                            temp_vendor: vendor.getAjoIdentifier(),
                            temp_prestation: budget.prestation.get()?.getAjoIdentifier() ?? null,
                        },
                    });
                    budget.vendor.set(vendor);
                    AppManager.makeUpdate();
                }}
            />
        </>
    );
};
