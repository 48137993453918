import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { Grid, Pagination } from '@mui/material';
import { PerfectTable } from '../../commun/table/PerfectTable';
import Utils from '../../commun/Utils';
import { AddIcon } from '../../icons/AddIcon';
import { SearchIcon } from '../../icons/SearchIcon';
import Label from '../../object/tag/Label';
import { BinIcon } from '../../icons/BinIcon';
import { EditIcon } from '../../icons/EditIcon';
import { SwitchIcon } from '../../icons/SwitchIcon';
import AddLabelModal from './AddLabelModal';
import FusionLabelModal from './FusionLabelModal';
import DelLabelModal from './DelLabelModal ';

export interface ILabelTableProps {
    list: Label[];
    loading: boolean;
}

export const LabelTable = (props: ILabelTableProps) => {
    const [search, setSearch] = useState('');
    const [label, setLabel] = useState<Label | undefined>(undefined);

    const [addLabelShow, setAddLabelShow] = useState(false);
    const [delLabelShow, setDelLabelShow] = useState(false);
    const [fusionLabelShow, setFusionLabelShow] = useState(false);

    const list = props.list.filter((Label: Label) => {
        return Utils.isSearch(search, Label.name.get());
    });

    return (
        <Container maxWidth={false}>
            <Box sx={{ mb: 3, mt: 3 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        m: -1,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        Label
                    </Typography>
                    <Box sx={{ m: 1 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            className="!pl-3 gap-2"
                            onClick={() => {
                                setLabel(undefined);
                                setAddLabelShow(true);
                            }}
                        >
                            <AddIcon />
                            Ajouter un label
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Card>
                        <CardContent className="w-full">
                            <Box className="flex w-full items-center flex-row">
                                <Box sx={{ maxWidth: 500 }}>
                                    <TextField
                                        fullWidth
                                        value={search}
                                        onChange={(e) => {
                                            setSearch((e.target as any).value);
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon color="action" fontSize="small">
                                                        <SearchIcon />
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder="Rechercher un label"
                                        variant="outlined"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            <Box>
                <Grid container spacing={3}>
                    {list.map((value: Label, i: number) => {
                        return (
                            <Grid key={i} item lg={4} md={4} xs={6}>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    <CardContent
                                        className={'bg-' + value.getColor() + '-600 flex items-center justify-center'}
                                    >
                                        <Box>
                                            <Typography className="text-white !text-lg py-3 !font-bold text-center">
                                                {value.name.get()}
                                            </Typography>
                                        </Box>
                                    </CardContent>

                                    <Box className="flex flex-row p-2 gap-2">
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className="!pl-3 flex-1 gap-2"
                                            onClick={() => {
                                                setLabel(value);
                                                setAddLabelShow(true);
                                            }}
                                        >
                                            <EditIcon />
                                            Modifier
                                        </Button>
                                        <Button
                                            color="success"
                                            variant="contained"
                                            className="!pl-3 flex-1 gap-2"
                                            onClick={() => {
                                                setLabel(value);
                                                setFusionLabelShow(true);
                                            }}
                                        >
                                            <SwitchIcon />
                                            Fusionner
                                        </Button>
                                        <Button
                                            color="error"
                                            variant="contained"
                                            className="!pl-3 flex-1 gap-2"
                                            onClick={() => {
                                                setLabel(value);
                                                setDelLabelShow(true);
                                            }}
                                        >
                                            <BinIcon />
                                            Supprimer
                                        </Button>
                                    </Box>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
            <AddLabelModal
                label={label}
                open={addLabelShow}
                onClose={() => {
                    setAddLabelShow(false);
                }}
            />
            <FusionLabelModal
                list={props.list}
                label={label ?? new Label()}
                open={fusionLabelShow}
                onClose={() => {
                    setFusionLabelShow(false);
                }}
            />
            <DelLabelModal
                label={label ?? new Label()}
                open={delLabelShow}
                onClose={() => {
                    setDelLabelShow(false);
                }}
            />
        </Container>
    );
};
