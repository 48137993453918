import { AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import AppManager from '../../commun/AppManager';
import { ComplexInput } from '../../commun/component/ComplexInput';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import { PaperView } from '../../component/paper/PaperView';
import { Paper } from '../../object/Paper';
import { clientAddSuccess, errorManager, paperAddSuccess, vendorAddSuccess } from './CategoryErrorManager';

export interface IAddPaperModalProps {
    id: string;
    field: string;
    show: boolean;
    handleClose: () => void;
}
export const AddPaperModal: React.FC<IAddPaperModalProps> = (props) => {
    const { handleClose, field, show, id } = props;

    const [name, updateName] = useState('');
    const [func, updateFunc] = useState<0 | 1>(0);
    const [error, updateError] = useState(ErrorHTTP.NO_ERROR());
    const [loading, updateLoad] = useState(false);

    useEffect(() => {
        updateError(ErrorHTTP.NO_ERROR());
        updateLoad(false);
        updateName('');
        updateFunc(0);
    }, [show]);

    const onAdd = () => {
        updateError(ErrorHTTP.NO_ERROR());
        updateLoad(true);
        post('/' + field + '/paper/create', {
            id_template: id,
            id_devis: id,
            name: name,
            func: func,
        }).then((res: any) => {
            updateLoad(false);
            if (Result.isSuccess(res)) {
                Result.applyResult(res, AppManager.updater(), true);
                updateError(paperAddSuccess);

                AppManager.addToast(new Toast(errorManager.get(paperAddSuccess), 'success'));
                props.handleClose();
            } else {
                updateError(Result.getError(res));
            }
        });
    };

    const paperPestation = new Paper();
    paperPestation.name.set('Prestation');
    paperPestation.func.set(0);
    paperPestation.default.set(false);

    const paperHonoraire = new Paper();
    paperHonoraire.name.set('Honoraire');
    paperHonoraire.func.set(1);
    paperHonoraire.default.set(false);
    return (
        <Modal
            title="Ajouter une feuille"
            handleClose={handleClose}
            show={show}
            loading={loading}
            error={error}
            errorManager={errorManager}
            done="Ajouter"
            onDone={onAdd}
        >
            <ComplexInput placeholder="Nom" name="name_paper" value={name} setValue={updateName} typeValue="string" />
            <div className="grid grid-cols-2 gap-2">
                <PaperView full={true} selected={func == 0} onClick={() => updateFunc(0)} paper={paperPestation} />
                <PaperView full={true} selected={func == 1} onClick={() => updateFunc(1)} paper={paperHonoraire} />
            </div>
        </Modal>
    );
};
