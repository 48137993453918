import { Box, TextField, Typography } from '@mui/material';
import { Component, createRef } from 'react';
import AppManager from '../../commun/AppManager';
import { Spinner } from '../../commun/component/Spinner';
import { post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import Label from '../../object/tag/Label';
import { getErrorMessage } from '../../utils/Error';
import MpModal, { IBaseModalProps } from '../extends/MpModal';
import { SelectLabelList } from './SelectLabelList';
import { JSXInternal } from 'preact/src/jsx';

export interface IFusionLabelModalProps extends IBaseModalProps {
    label: Label;
    list: Label[];
}

export interface IFusionLabelModalState {
    inLabel: Label | null;
    loading: boolean;
    error: string;
}

export default class FusionLabelModal extends Component<IFusionLabelModalProps, IFusionLabelModalState> {
    constructor(props: IFusionLabelModalProps) {
        super(props);

        this.state = this.initState();
    }

    initState() {
        const p: IFusionLabelModalState = {
            inLabel: null,
            loading: false,
            error: '',
        };
        return p;
    }

    componentDidUpdate(
        prevProps: Readonly<IFusionLabelModalProps>,
        prevState: Readonly<IFusionLabelModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.open !== this.props.open) {
            this.setState(this.initState());
        }
    }

    addVendor() {
        const { inLabel } = this.state;
        this.setState({
            loading: true,
            error: '',
        });
        if (inLabel !== null) {
            post('/label/update', {
                id_child_fussion: [this.props.label.getAjoIdentifier()],
                id_parent_fussion: inLabel.getAjoIdentifier(),
            }).then((res: any) => {
                this.setState({
                    loading: false,
                });
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, AppManager.updater(), true);
                    this.props.onClose();
                    AppManager.addToast(new Toast('Le label a été fusionnée avec succès.', 'success'));
                } else {
                    this.setState({
                        error: getErrorMessage(Result.getErrorStr(res)),
                    });
                }
            });
        } else {
            this.setState({
                loading: false,
                error: 'Veuillez sélectionner un label.',
            });
        }
    }

    render(): JSXInternal.Element {
        const { open, onClose } = this.props;
        const { loading, error, inLabel } = this.state;
        return (
            <MpModal
                open={open}
                onClose={onClose}
                onSubmit={() => {
                    this.addVendor();
                }}
                title={'Fusionner un label'}
                done={'Fusionner'}
            >
                {loading ? (
                    <Box className="flex items-center justify-center py-12">
                        <Spinner color="blue" className="" />
                    </Box>
                ) : (
                    <Box className="flex flex-col !text-gray-600 gap-3">
                        <Typography className="!text-sm !font-semibold">FUSIONNER DANS</Typography>
                        <SelectLabelList
                            list={this.props.list}
                            single={true}
                            selectList={inLabel !== null ? [inLabel] : []}
                            onSelectList={(l) => {
                                if (l.length > 0) {
                                    this.setState({
                                        inLabel: l[0],
                                    });
                                } else {
                                    this.setState({
                                        inLabel: null,
                                    });
                                }
                            }}
                        />
                        {error !== '' && (
                            <Typography
                                component={'p'}
                                className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                            >
                                {error}
                            </Typography>
                        )}
                    </Box>
                )}
            </MpModal>
        );
    }
}
