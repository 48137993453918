export interface IEuroSpanProps {
    value: number;
    className?: string;
}
export const EuroSpan: React.FC<IEuroSpanProps> = (props) => {
    const { value, className = '' } = props;
    if (isNaN(value) || !isFinite(value)) {
        return <span className={className}>0.00 €</span>;
    } else {
        const addEnd = (str: string) => {
            str = str.replace(',', '.');
            if (str.indexOf('.') === -1) {
                str = str + '.00';
            } else if (str.indexOf('.') >= str.length - 2) {
                str = str + '0';
            } else {
                str = str;
            }
            let splitStr = str.split('.');

            return parseInt(splitStr[0]).toLocaleString('fr') + '.' + splitStr[1];
        };
        return (
            <span className={className + ' no-break no-wrap whitespace-nowrap'}>{addEnd(value.toFixed(2))}&nbsp;€</span>
        );
    }
};
