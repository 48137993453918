import { AJOProperties } from 'mp-js-react-auto-json-object';
import { AJOObject } from 'mp-js-react-auto-json-object';
export class Jour extends AJOObject {
    public static _TYPE: string = 'Jour';

    public date: AJOProperties<any>;
    public type: AJOProperties<any>;

    constructor() {
        super(Jour._TYPE);

        this.date = new AJOProperties('date');
        this.type = new AJOProperties('type');
    }

    public static build(): Jour {
        return new Jour();
    }

    public getDate(): Date {
        return new Date(this.date.get());
    }
}
