import { CategoryHonoraire } from './CategoryHonoraire';
import { AJOProperties, AJOObject, AJOSimple } from 'mp-js-react-auto-json-object';
export class Honoraire extends AJOObject {
    public static _TYPE: string = 'Honoraire';
    public name: AJOProperties<any>;
    public category: AJOSimple<CategoryHonoraire>;

    constructor() {
        super(Honoraire._TYPE);
        this.name = new AJOProperties('name', this);
        this.category = new AJOSimple(CategoryHonoraire, 'has_honoraire_category', this);
    }

    public static build(): Honoraire {
        return new Honoraire();
    }

    public getCategoryList(): CategoryHonoraire[] {
        return this.category.get() != null ? [this.category.get()!] : ([] as CategoryHonoraire[]);
    }
}
