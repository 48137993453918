import { useState } from 'react';
import { PerfectTable } from '../../commun/table/PerfectTable';
import Vendor from '../../object/profile/Vendor';
import { JSXInternal } from 'preact/src/jsx';

import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    Typography,
    Container,
} from '@mui/material';
import { SearchIcon } from '../../icons/SearchIcon';
import Utils from '../../commun/Utils';
import { AddIcon } from '../../icons/AddIcon';
import AddVendorModal from './AddVendorModal';
import { EuroSpan } from '../../commun/component/EuroSpan';
import { PourcentSpan } from '../../commun/component/PourcentSpan';
import { InfoIcon } from '../../icons/InfoIcon';
import { ArrowRightCircle } from '../../icons/ArrowRightCircle';
import { ArrowDownCircle } from '../../icons/ArrowDownCircle';
import { ServiceStatTable } from '../prestation/ServiceStatTable';
import { Link } from 'react-router-dom';

export interface IVendorStatTableProps {
    list: Vendor[];
    loading: boolean;
    onChangeType: () => void;
}

export const VendorStatTable = (props: IVendorStatTableProps) => {
    const { list, loading } = props;

    const [addVendorShow, setAddVendorShow] = useState(false);

    const [search, setSearch] = useState('');
    const [openElement, setOpenElement] = useState<Vendor | null>(null);

    return (
        <Box>
            <Box sx={{ mb: 3, mt: 3 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        m: -1,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        Fournisseur
                    </Typography>
                    <Box sx={{ m: 1 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            className="!pl-3 gap-2"
                            onClick={() => {
                                setAddVendorShow(true);
                            }}
                        >
                            <AddIcon />
                            Ajouter un fournisseur
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ mt: 3 }} className="">
                    <Card>
                        <CardContent className="">
                            <Box className="flex  items-center flex-row">
                                <Box sx={{ maxWidth: 500 }}>
                                    <TextField
                                        fullWidth
                                        value={search}
                                        onChange={(e) => {
                                            setSearch((e.target as any).value);
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon color="action" fontSize="small">
                                                        <SearchIcon />
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder="Rechercher un fournisseur"
                                        variant="outlined"
                                    />
                                </Box>
                                <Box className="flex-1"></Box>
                                <Box>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className="!pl-3 gap-2"
                                        onClick={() => {
                                            props.onChangeType();
                                        }}
                                    >
                                        <InfoIcon />
                                        Information
                                    </Button>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            <Card>
                <PerfectTable
                    loading={loading}
                    list={list}
                    nothing={
                        <Box className="flex flex-col gap-3 items-center justify-center">
                            <Typography>{'Aucun fournisseur trouvé.'}</Typography>
                            <Button
                                onClick={() => {
                                    setAddVendorShow(true);
                                }}
                                color="primary"
                                variant="contained"
                            >
                                Ajouter un fournisseur
                            </Button>
                        </Box>
                    }
                    columns={[
                        {
                            canSort: true,
                            sort: 1,
                            funSort: (e1: Vendor, e2: Vendor) => {
                                return e1.name.get().localeCompare(e2.name.get());
                            },

                            name: 'Nom',

                            hasFilter: true,
                            funFilter: (vendor: Vendor) => {
                                const finalSearch = Utils.clean(search.toLowerCase());
                                let can =
                                    Utils.clean(vendor.name.get()).toLowerCase().includes(finalSearch) ||
                                    search.length === 0;
                                if (!can) {
                                    let i = 0;
                                    while (!can && i < vendor.getPrestationList().length) {
                                        can = Utils.clean(vendor.getPrestationList()[i].name.get())
                                            .toLowerCase()
                                            .includes(finalSearch);
                                        i++;
                                    }
                                    i = 0;
                                    while (!can && i < vendor.getCategoryList().length) {
                                        can = Utils.clean(vendor.getCategoryList()[i].name.get())
                                            .toLowerCase()
                                            .includes(finalSearch);
                                        i++;
                                    }
                                    i = 0;
                                    while (!can && i < vendor.getLabelList().length) {
                                        can = Utils.clean(vendor.getLabelList()[i].name.get())
                                            .toLowerCase()
                                            .includes(finalSearch);
                                        i++;
                                    }
                                }
                                return can;
                            },
                            render: (elem: Vendor) => {
                                return (
                                    <Box className="font-semibold flex flex-row items-center gap-1">
                                        {openElement?.getAjoIdentifier() !== elem.getAjoIdentifier() ? (
                                            <ArrowRightCircle
                                                onClick={() => {
                                                    setOpenElement(elem);
                                                }}
                                            />
                                        ) : (
                                            <ArrowDownCircle
                                                onClick={() => {
                                                    setOpenElement(null);
                                                }}
                                            />
                                        )}
                                        <Link to={'/vendor/' + elem.getAjoIdentifier()}>{elem.name.get()}</Link>
                                    </Box>
                                );
                            },
                        },
                        {
                            name: 'Nb prestation',

                            canSort: true,
                            sort: 0,
                            funSort: (e1: Vendor, e2: Vendor) => {
                                return -e1.getBudgetList().length + e2.getBudgetList().length;
                            },

                            render: (elem: Vendor) => {
                                return <Typography>{elem.getBudgetList().length}</Typography>;
                            },
                        },
                        {
                            name: 'Achat total',

                            canSort: true,
                            sort: 0,
                            funSort: (e1: Vendor, e2: Vendor) => {
                                return -e1.achat() + e2.achat();
                            },

                            render: (elem: Vendor) => {
                                return (
                                    <Typography>
                                        <EuroSpan value={elem.achat()} />
                                    </Typography>
                                );
                            },
                        },
                        {
                            name: 'VA',

                            canSort: true,
                            sort: 0,
                            funSort: (e1: Vendor, e2: Vendor) => {
                                return -e1.va() + e2.va();
                            },

                            render: (elem: Vendor) => {
                                return (
                                    <Typography>
                                        <PourcentSpan value={elem.va()} />
                                    </Typography>
                                );
                            },
                        },
                        {
                            name: 'PV HT',

                            canSort: true,
                            sort: 0,
                            funSort: (e1: Vendor, e2: Vendor) => {
                                return -e1.prixVenteHT() + e2.prixVenteHT();
                            },

                            render: (elem: Vendor) => {
                                return (
                                    <Typography>
                                        <EuroSpan value={elem.prixVenteHT()} />
                                    </Typography>
                                );
                            },
                        },
                        {
                            name: 'TVA',

                            canSort: true,
                            sort: 0,
                            funSort: (e1: Vendor, e2: Vendor) => {
                                return -e1.tax() + e2.tax();
                            },

                            render: (elem: Vendor) => {
                                return (
                                    <Typography>
                                        <PourcentSpan value={elem.tax()} />
                                    </Typography>
                                );
                            },
                        },
                        {
                            name: 'PV TTC',

                            canSort: true,
                            sort: 0,
                            funSort: (e1: Vendor, e2: Vendor) => {
                                return -e1.prixVenteTTC() + e2.prixVenteTTC();
                            },

                            render: (elem: Vendor) => {
                                return (
                                    <Typography>
                                        <EuroSpan value={elem.prixVenteTTC()} />
                                    </Typography>
                                );
                            },
                        },
                    ]}
                    bonusRender={(elem: Vendor) => {
                        let res: JSXInternal.Element = <></>;
                        if (elem.getAjoIdentifier() === openElement?.getAjoIdentifier()) {
                            elem.fetchPrestationListInfo();

                            res = (
                                <ServiceStatTable
                                    color="red"
                                    loading={elem.prestationListLoading}
                                    list={elem.serviceList.getList()}
                                />
                            );
                        }
                        return res;
                    }}
                />
            </Card>
            <AddVendorModal
                open={addVendorShow}
                onClose={() => {
                    setAddVendorShow(false);
                }}
            />
        </Box>
    );
};
