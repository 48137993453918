import { AJOList, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { get } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import { Col } from '../../commun/table/Col';
import { TagList } from '../../component/TagList';
import { TypeListSelect } from '../../component/TypeListSelect';
import Category from '../../object/tag/Category';
import Label from '../../object/tag/Label';
import Product from '../../object/tag/Product';
import { TagObject } from '../../object/tag/TagObject';

export interface ISelectTagListModalProps {
    col: Col<any>;
    field: string;
}

export const SelectTagListModal: React.FC<ISelectTagListModalProps> = (props) => {
    const { col, field } = props;

    const [selectList, updateSelectList] = useState(new Array<any>(col.filterList));

    useEffect(() => {
        updateSelectList(col.filterList);
    }, [col.filterList]);

    const [stateList, updateStateList] = useState(
        new AJOState<AJOList<Category>>(new AJOList<TagObject>([Category, Label, Product])),
    );
    stateList.setUpdate(updateStateList);

    const [loading, updateLoading] = useState(true);

    useEffect(() => {
        updateLoading(true);

        if (field === 'product') {
            get('/product_category/list').then((res: any) => {
                updateLoading(false);
                Result.applyResult(res, stateList, true);
            });
        } else {
            get('/' + field + '/list').then((res: any) => {
                updateLoading(false);
                Result.applyResult(res, stateList, true);
            });
        }
    }, []);

    const selectElem = (elem: Category | Label) => {
        if (selectList.find((e) => e.getAjoIdentifier() === elem.getAjoIdentifier()) === undefined) {
            updateSelectList([...selectList, elem]);
        } else {
            updateSelectList(selectList.filter((e) => e.getAjoIdentifier() !== elem.getAjoIdentifier()));
        }
    };

    let title;
    if (field === 'category') {
        title = 'Sélectionner des catégories';
    } else if (field === 'label') {
        title = 'Sélectionner des labels';
    } else {
        title = 'Sélectionner des offres';
    }

    return (
        <Modal
            onDone={() => {
                col.filterList = selectList;
                col.closeModal();
            }}
            show={col.showFilterModal}
            done={'Sélectionner'}
            title={title}
            color={'blue'}
            handleClose={() => col.closeModal()}
        >
            <TagList
                field={field}
                loading={loading}
                selectList={selectList}
                onCheck={selectElem}
                checkMode={true}
                list={stateList.get()!.getList()}
            />
        </Modal>
    );
};
