import { AJOState } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Prestation from '../object/profile/Prestation';
import { ProfilePage } from './ProfilePage';

export function PrestationPage() {
    const { id } = useParams();

    const [prestation, updatePrestation] = useState(new AJOState<Prestation>(Prestation.build()));
    prestation.setUpdate(updatePrestation);

    return <ProfilePage elem={prestation.get()!} id={id!} />;
}
