import { AJOList, AJOObject, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { title } from 'process';
import { useEffect, useState } from 'react';
import { ComplexInput } from '../../commun/component/ComplexInput';
import { TextArea } from '../../commun/component/TextArea';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { get, post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Utils from '../../commun/Utils';
import { SelectColor } from '../../component/SelectColor';
import Client from '../../object/profile/Client';
import Contact from '../../object/Contact';
import ParentSevice from '../../object/profile/ParentSevice';
import Vendor from '../../object/profile/Vendor';
import {
    clientEditSuccess,
    errorManager,
    paperEditSuccess,
    prestationEditSuccess,
    sectionEditSuccess,
    vendorEditSuccess,
} from './CategoryErrorManager';
import AppManager from '../../commun/AppManager';
import Toast from '../../commun/toast/Toast';
import { Paper } from '../../object/Paper';
import { Section } from '../../object/Section';

export interface IEditVendorModalProps {
    handleClose: () => void;
    callback?: () => void;
    profile: ParentSevice | Client | Paper | Section;
    show: boolean;
    noDesc?: boolean;
}

export const EditVendorModal: React.FC<IEditVendorModalProps> = (props) => {
    const { profile, handleClose, callback = () => {}, show, noDesc = false } = props;

    const [stateList, updateStateList] = useState(new AJOState<AJOList<AJOObject>>(new AJOList<AJOObject>()));
    stateList.setUpdate(updateStateList);

    const [loading, updateLoad] = useState(false);
    const [error, updateError] = useState(ErrorHTTP.NO_ERROR());

    const [name, updateName] = useState(profile.name.get());
    const [description, updateDescription] = useState(
        (profile instanceof Vendor || profile instanceof Client) && profile?.description.get(),
    );

    useEffect(() => {
        if (show) {
            updateError(ErrorHTTP.NO_ERROR());
            updateName(profile.name.get());
            updateDescription((profile instanceof Vendor || profile instanceof Client) && profile.description.get());
        }
    }, [show]);

    const onAddCategory = () => {
        updateLoad(true);
        updateError(ErrorHTTP.NO_ERROR());

        const params: any = {
            id: profile!.getAjoIdentifier(),
            name: name,
        };
        if (!noDesc) {
            params['description'] = description;
        }

        post('/' + profile.getAjoType().toLowerCase() + '/update', params).then((res: any) => {
            updateLoad(false);
            if (Result.isSuccess(res)) {
                Result.applyResult(res, profile, true);
                Result.applyResult(res, AppManager.updater(), true);

                callback();

                if (profile.getAjoType().toLowerCase() === 'vendor') {
                    AppManager.addToast(new Toast(errorManager.get(vendorEditSuccess), 'success'));
                    updateError(vendorEditSuccess);
                    props.handleClose();
                } else if (profile.getAjoType().toLowerCase() === 'client') {
                    AppManager.addToast(new Toast(errorManager.get(clientEditSuccess), 'success'));
                    updateError(clientEditSuccess);
                    props.handleClose();
                } else if (profile.getAjoType().toLowerCase() === 'prestation') {
                    AppManager.addToast(new Toast(errorManager.get(prestationEditSuccess), 'success'));
                    updateError(prestationEditSuccess);
                    props.handleClose();
                } else if (profile.getAjoType().toLowerCase() === 'paper') {
                    AppManager.addToast(new Toast(errorManager.get(paperEditSuccess), 'success'));
                    updateError(paperEditSuccess);
                    props.handleClose();
                } else if (profile.getAjoType().toLowerCase() === 'section') {
                    AppManager.addToast(new Toast(errorManager.get(sectionEditSuccess), 'success'));
                    updateError(sectionEditSuccess);
                    props.handleClose();
                }
            } else {
                updateError(Result.getError(res));
            }
        });
    };

    const getTitle = () => {
        if (profile instanceof Vendor) {
            return 'le fournisseur';
        } else if (profile instanceof Client) {
            return 'le client';
        } else if (profile instanceof Paper) {
            return 'la feuille';
        } else if (profile instanceof Section) {
            return 'la section';
        } else {
            return 'la prestation';
        }
    };

    return (
        <Modal
            onDone={onAddCategory}
            show={show}
            done={'Modifier'}
            title={'Modifier ' + getTitle()}
            loading={loading}
            color={'blue'}
            error={error}
            errorManager={errorManager}
            handleClose={() => {
                handleClose();
                updateError(ErrorHTTP.NO_ERROR());
            }}
        >
            <ComplexInput typeValue="string" placeholder="Nom" value={name} setValue={updateName} />
            {!noDesc && (profile instanceof Vendor || profile instanceof Client) && (
                <TextArea
                    value={description}
                    onChange={(e) => updateDescription((e.target as any).value)}
                    placeholder="Description"
                ></TextArea>
            )}
        </Modal>
    );
};
