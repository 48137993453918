import { AJOList, AJOObject } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import BasePrice from 'src/object/BasePrice';
import AppManager from '../../commun/AppManager';
import Autocomplete from '../../commun/component/Autocomplite';
import { post } from '../../commun/HTTP';
import { PerfectAJOList } from '../../commun/object/PerfectAJOList';
import Budget from '../../object/Budget';
import Vendor from '../../object/profile/Vendor';
import Valeur from '../../object/Valeur';
import VendorModal from '../vendor/VendorModal';
import { CustomSelect } from './CustomSelect';

export interface IVaSelectProps {
    vaList: PerfectAJOList<Valeur>;
    budget: Budget;
    basePrice: BasePrice;
}

export const VaSelect = (props: IVaSelectProps) => {
    const { vaList, budget, basePrice } = props;

    return (
        <>
            <CustomSelect
                className=" w-32 "
                id={'va-' + budget.getAjoIdentifier()}
                disabled={budget.bon_commande.get() !== null || !basePrice.canAct()}
                list={vaList.getList()}
                value={budget.valeur.get() ?? budget.valeur_flat.get()}
                getValue={(elem: Valeur | undefined | number) => {
                    if (elem == undefined) {
                        return '';
                    } else if (typeof elem === 'number') {
                        return elem + ' %';
                    } else {
                        return elem.pourcent.get() + ' %' ?? '';
                    }
                }}
                placeholder="VA"
                onBlur={() => {
                    budget.leaveFocus();
                }}
                onFocus={() => {
                    budget.getFocus();
                    vaList?.need();
                }}
                onCreate={(str: string) => {
                    str = str.replace(' %', '');
                    str = str.replace('%', '');
                    str = str.replace(',', '.');

                    budget.valeur_flat.set(parseFloat(str));

                    if (budget.valeur.get() !== null) {
                        budget.addEdit({
                            valeur_flat: parseFloat(str),
                            valeur: '-1',
                        });
                        budget.valeur.set(null);
                    } else {
                        budget.addEdit({
                            valeur_flat: parseFloat(str),
                        });
                    }
                }}
                onSelect={(item: any) => {
                    if (item instanceof Valeur) {
                        budget.addEdit({
                            valeur: item.getAjoIdentifier(),
                        });
                        budget.valeur_flat.set(undefined);
                        budget.valeur.set(item);

                        AppManager.makeUpdate();
                    } else if (budget.valeur.get() !== null) {
                        budget.addEdit({
                            valeur_flat: '',
                            valeur: '-1',
                        });
                        budget.valeur_flat.set(undefined);
                        budget.valeur.set(null);
                        AppManager.makeUpdate();
                    } else {
                        budget.addEdit({
                            valeur_flat: '',
                        });
                        budget.valeur_flat.set(undefined);
                        AppManager.makeUpdate();
                    }
                }}
            />
        </>
    );
};
