import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-date-picker';
import Utils from '../Utils';

export interface ICustomDatePickerProps {
    onChange: (date: Date) => void;
    onCalendarOpen?: () => void;
    onCalendarClose?: () => void;
    value: Date | null | undefined;
    focus?: boolean;
    block?: boolean;
    className?: string;
    onTab?: (e: KeyboardEvent) => void;
}

export const CustomDatePicker: React.FunctionComponent<ICustomDatePickerProps> = (props) => {
    const {
        onChange,
        className = '',
        onTab,
        onCalendarOpen,
        onCalendarClose,
        block = false,
        focus = false,
        value,
    } = props;

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            if (e.target?.constructor.name !== 'HTMLInputElement' && focus && (e.key === 'Tab' || e.key === 'Enter')) {
                if (onTab != undefined) {
                    onTab(e);
                }
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [focus]);

    if (focus) {
        Utils.blur();
    }

    return (
        <div className={' ' + className + ' ' + block ? 'relative w-full ' : ''}>
            <DatePicker
                onCalendarOpen={() => {
                    setOpen(true);
                }}
                onCalendarClose={() => {
                    setOpen(false);
                }}
                calendarClassName={'rounded-md mt-1 '}
                className={
                    ' pl-1 pr-1 pb-1 pt-1  border border-gray-600 dark:border-gray-400 placeholder:text-gray-500 dark:placeholder:text-gray-300 outline-0 rounded-md flex-1 text-black bg-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:text-white dark:focus:ring-red-900 dark:focus:border-red-900 focus:outline-none focus:ring focus:ring-blue-700 focus:dark:ring-blue-600' +
                    className +
                    ' w-full ' +
                    (open ? 'z-50' : '')
                }
                onChange={onChange}
                value={value}
            />
            {block && <div className="absolute inset-0 z-[40]"></div>}
        </div>
    );
};
