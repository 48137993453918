import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Utils from 'src/commun/Utils';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';
import { AddIcon } from 'src/icons/AddIcon';
import { SearchIcon } from 'src/icons/SearchIcon';
import { SERVER_URL } from 'src/utils/Constant';
import { BasePage } from '../component/BasePage';

export function HelpPage() {
    const [search, setSearch] = useState('');
    const [helpSelect, updateHelpSelect] = useState<any>(undefined);

    return (
        <BasePage className="flex-row w-full justify-center gap-4">
            <DashboardNavBar />
            <Box sx={{ m: 3 }}>
                <Container>
                    <Box sx={{ mb: 3, mt: 3 }}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                m: -1,
                            }}
                        >
                            <Typography sx={{ m: 1 }} variant="h4">
                                Fiche d'aide
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 3 }} className="">
                            <Card>
                                <CardContent className="">
                                    <Box className="flex  items-center flex-row">
                                        <Box sx={{ maxWidth: 500 }}>
                                            <TextField
                                                fullWidth
                                                value={search}
                                                onChange={(e) => {
                                                    setSearch((e.target as any).value);
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SvgIcon color="action" fontSize="small">
                                                                <SearchIcon />
                                                            </SvgIcon>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                placeholder="Rechercher un fiche d'aide"
                                                variant="outlined"
                                            />
                                        </Box>
                                        <Box className="flex-1"></Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box sx={{ mt: 3 }} className="">
                            {Utils.isSearch(search, 'Ge-Steam') && (
                                <Card>
                                    <CardContent className="">
                                        <Box className="flex flex-row flex-wrap">
                                            <Box className="flex-1 flex flex-col gap-2">
                                                <Typography variant="h5">Ge-Steam</Typography>
                                                <Typography variant="body1">
                                                    Apprener tout sur Ge-Steam, en lisant la notice explicative.
                                                </Typography>
                                            </Box>
                                            <Box className="flex items-center justify-center">
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={SERVER_URL + '/help/' + 'Ge-Steam'.toLowerCase() + '.pdf'}
                                                >
                                                    <Button
                                                        tabIndex={-1}
                                                        color="primary"
                                                        className="!pl-3 gap-2"
                                                        variant="contained"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            fill="currentColor"
                                                            className="w-6 h-6"
                                                        >
                                                            <path d="M11.25 4.533A9.707 9.707 0 006 3a9.735 9.735 0 00-3.25.555.75.75 0 00-.5.707v14.25a.75.75 0 001 .707A8.237 8.237 0 016 18.75c1.995 0 3.823.707 5.25 1.886V4.533zM12.75 20.636A8.214 8.214 0 0118 18.75c.966 0 1.89.166 2.75.47a.75.75 0 001-.708V4.262a.75.75 0 00-.5-.707A9.735 9.735 0 0018 3a9.707 9.707 0 00-5.25 1.533v16.103z" />
                                                        </svg>
                                                        Ouvrir
                                                    </Button>
                                                </a>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            )}
                        </Box>
                    </Box>
                </Container>
            </Box>
        </BasePage>
    );
}

/*


                        >
                        <div className="flex flex-col gap-3 flex-wrap">
                            {list.map((elem, index) => {
                                return (
                                    <a
                                        href={SERVER_URL + '/help/' + elem.name.toLowerCase() + '.pdf'}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div
                                            className="rounded-lg flex flex-col gap-2 border border-slate-300 dark:border-gray-500 p-3 bg-white dark:bg-gray-800"
                                            key={index}
                                        >
                                            <p className="font-semibold text-lg">{elem.name}</p>
                                            <p className="">{elem.text}</p>
                                            <div>
                                                <Button color="blue">Ouvrir</Button>
                                            </div>
                                        </div>
                                    </a>
                                );
                            })}
                        </div>
*/
