import { AJOList } from 'mp-js-react-auto-json-object';
import React from 'react';
import AppManager from '../../commun/AppManager';
import { Button } from '../../commun/component/Button';
import { ComplexInput } from '../../commun/component/ComplexInput';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import Utils from '../../commun/Utils';
import { JSXInternal } from 'preact/src/jsx';
import { Marge } from '../../object/Marge';
import { Reduc } from '../../object/marge_reduc/Reduc';
import { Paper } from '../../object/Paper';
import { IModalProps } from '../base/IModalProps';
import { IModalState } from '../base/IModalState';
import {
    addMargeSuccess,
    addReducSuccess,
    addressAddSuccess,
    addressEditSuccess,
    editMargeSuccess,
    editReducSuccess,
    errorManager,
} from '../vendor/CategoryErrorManager';
import MpModal from 'src/component/extends/MpModal';
import { Box, Typography } from '@mui/material';
import { Spinner } from 'src/commun/component/Spinner';

export interface IMargeReducModalProps extends IModalProps {
    onMargeReducChange: (id: string) => void;
    baseName?: string;
    marge?: Marge;
    action: 'add' | 'edit';
    type: 'marge' | 'reduc';
    paper: Paper;
}
export interface IMargeReducModalState extends IModalState {
    name: string;
    func: number;
    value: string;
}

export class MargeReducModal extends React.Component<IMargeReducModalProps, IMargeReducModalState> {
    constructor(props: IMargeReducModalProps) {
        /**
         * DEFAULT PROPS
         */

        super(props);
        /**
         * CREATE STATE
         * */
        this.state = this.initState();
    }

    initState(): IMargeReducModalState {
        const { action, baseName = '', marge } = this.props;
        if (action === 'edit') {
            return {
                name: marge!.name.get(),
                func: marge!.func.get(),
                value: marge!.value.get() + '',

                error: ErrorHTTP.NO_ERROR(),
                loading: false,
            };
        } else {
            return {
                name: baseName,
                func: 0,

                value: '',

                loading: false,
                error: ErrorHTTP.NO_ERROR(),
            };
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IMargeReducModalProps>,
        prevState: Readonly<IMargeReducModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.show !== this.props.show) {
            if (this.props.show) {
                Utils.blur();

                this.setState(this.initState());
            }
        }
    }

    getSuccess() {
        const { action, type } = this.props;
        let res: ErrorHTTP;
        if (action === 'add') {
            if (type === 'marge') {
                res = addMargeSuccess;
            } else {
                res = addReducSuccess;
            }
        } else {
            if (type === 'marge') {
                res = editMargeSuccess;
            } else {
                res = editReducSuccess;
            }
        }
        return res;
    }

    makeAction(): void {
        const { action, marge, paper, type } = this.props;
        const { name, value, func } = this.state;

        this.setState({
            loading: true,
            error: ErrorHTTP.NO_ERROR(),
        });

        let params: { [key: string]: any } = {
            name: name,
            func: func,
            value: parseFloat(value),
            id: marge?.getAjoIdentifier() ?? '',
        };

        let paramsFinal: any = {
            id: paper?.getAjoIdentifier() ?? '',
        };
        paramsFinal[type] = params;

        post('/paper/update', paramsFinal).then((res: any) => {
            this.setState({
                loading: false,
            });

            if (Result.isSuccess(res)) {
                Result.applyResult(res, AppManager.updater(), true);

                if (type === 'marge') {
                    let marge = new Marge();
                    Result.applyResult(res, marge, true);
                    this.props.onMargeReducChange(marge.getAjoIdentifier());
                } else {
                    let reduc = new Reduc();
                    Result.applyResult(res, reduc, true);
                    this.props.onMargeReducChange(reduc.getAjoIdentifier());
                }

                this.props.onClose();
                if (type === 'marge') {
                    AppManager.addToast(
                        new Toast(
                            'La marge a été ' + (action === 'add' ? 'ajoutée' : 'modifiée') + ' avec succès.',
                            'success',
                        ),
                    );
                } else {
                    AppManager.addToast(
                        new Toast(
                            'La remise commerciale a été ' +
                                (action === 'add' ? 'ajoutée' : 'modifiée') +
                                ' avec succès.',
                            'success',
                        ),
                    );
                }
            } else {
                this.setState({
                    error: Result.getError(res),
                });
            }
        });
    }

    getTitle(): string {
        const { action, type } = this.props;
        let res: string;
        if (action === 'add') {
            if (type === 'marge') {
                res = 'Ajouter une marge';
            } else {
                res = 'Ajouter une remise commerciale';
            }
        } else {
            if (type === 'marge') {
                res = 'Modifier une marge';
            } else {
                res = 'Modifier une remise commerciale';
            }
        }
        return res;
    }

    getDone(): string {
        const { action } = this.props;
        let res: string;
        if (action === 'add') {
            res = 'Ajouter';
        } else {
            res = 'Modifier';
        }
        return res;
    }

    render(): JSXInternal.Element {
        const { name, loading, func, error } = this.state;
        const { show, onClose } = this.props;

        const paperPestation = new Paper();
        paperPestation.name.set('pourcentage'.toUpperCase());
        paperPestation.func.set(0);
        paperPestation.default.set(false);

        const paperHonoraire = new Paper();
        paperHonoraire.name.set('FIXE');
        paperHonoraire.func.set(1);
        paperHonoraire.default.set(false);

        let list = [paperPestation, paperHonoraire];

        const select = (p: Paper) => {
            for (const elem of list) {
                elem.default.set(p === elem);
                if (p === elem) {
                    this.setState({
                        func: elem.func.get(),
                    });
                }
            }
        };

        return (
            <MpModal
                title={this.getTitle()}
                done={this.getDone()}
                open={show}
                onSubmit={() => {
                    this.makeAction();
                }}
                onClose={onClose}
            >
                {loading ? (
                    <Box className="flex items-center justify-center py-12">
                        <Spinner color="blue" className="" />
                    </Box>
                ) : (
                    <div className={'flex flex-col gap-3'}>
                        <ComplexInput
                            typeValue="string"
                            placeholder="Nom"
                            value={name}
                            setValue={(e: string) => this.setState({ name: e })}
                        />
                        <div className="grid grid-cols-2 gap-3">
                            <Button
                                border={2}
                                outline={paperPestation.func.get() !== func}
                                pb={1.5}
                                pt={1.5}
                                pr={2.5}
                                pl={2.5}
                                gap={1}
                                color={'sky'}
                                onClick={() => select(paperPestation)}
                            >
                                <span>{paperPestation.name.get()}</span>
                            </Button>
                            <Button
                                border={2}
                                outline={paperHonoraire.func.get() !== func}
                                pb={1.5}
                                pt={1.5}
                                pr={2.5}
                                pl={2.5}
                                gap={1}
                                color={'violet'}
                                onClick={() => select(paperHonoraire)}
                            >
                                <span>{paperHonoraire.name.get()}</span>
                            </Button>
                        </div>

                        {func === 1 && (
                            <ComplexInput
                                type={'text'}
                                placeholder={'Prix'}
                                typeValue={'eur'}
                                setValue={(e) => {
                                    this.setState({
                                        value: e,
                                    });
                                }}
                                value={this.state.value}
                            />
                        )}

                        {func === 0 && (
                            <ComplexInput
                                placeholder={'Pourcentage'}
                                type={'text'}
                                typeValue={'pourcent'}
                                setValue={(e) => {
                                    this.setState({
                                        value: e,
                                    });
                                }}
                                value={this.state.value}
                            />
                        )}
                        {!error.equals(ErrorHTTP.NO_ERROR()) && (
                            <Typography
                                component={'p'}
                                className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                            >
                                {errorManager.get(error)}
                            </Typography>
                        )}
                    </div>
                )}
            </MpModal>
        );
    }
}
