import { JSXInternal } from 'preact/src/jsx';

export interface IFormProps extends JSXInternal.HTMLAttributes<HTMLFormElement> {}
export const Form: React.FunctionComponent<IFormProps> = (props) => {
    return (
        <form
            {...props}
            className={
                'rounded-xl border bg-white  border-gray-200 dark:border-slate-500 p-2 sm:p-6 dark:bg-gray-800 flex flex-col gap-5 ' +
                props.className
            }
        >
            {props.children}
        </form>
    );
};
