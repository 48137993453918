import e from 'express';
import { useEffect, useState } from 'react';

interface ITimerViewProps {
    date: Date;
    dateEnd?: Date;
    show?: boolean;
    canJour?: boolean;
    breakWord?: boolean;
}

export const TimerView: React.FC<ITimerViewProps> = (props: ITimerViewProps) => {
    const { date, dateEnd, show = false, canJour = true, breakWord = false} = props;
    let ccDate = new Date();
    if (dateEnd !== undefined) {
        ccDate = new Date(dateEnd);
    }

    if (ccDate.getTime() < 0) {
        let oldTimer = ccDate.getTime();
        ccDate.setTime(-oldTimer);
        date.setTime(0);
    }

    const [time, setTime] = useState(Date.now());
    let final = ccDate.getTime() - date.getTime();

    useEffect(() => {
        if (dateEnd === undefined) {
            const interval = setInterval(() => setTime(Date.now()), 1000 * 60);
            return () => {
                clearInterval(interval);
            };
        }
    }, []);

    let joursInt = 0;
    if (canJour) {
        joursInt = Math.floor(final / 7 / 60.0 / 60.0 / 1000.0);
    }
    if (joursInt < 0) {
        joursInt = 0;
    }
    let moinJour = final - joursInt * 7 * 60.0 * 60.0 * 1000.0;

    let hoursInt = Math.floor(moinJour / 60.0 / 60.0 / 1000.0);
    if (hoursInt < 0) {
        hoursInt = 0;
    }

    let moinHours = moinJour - hoursInt * 60.0 * 60.0 * 1000.0;

    let minutesInt = Math.floor(moinHours / 60.0 / 1000.0);
    if (minutesInt < 0) {
        minutesInt = 0;
    }

    let moinMinutes = moinHours - minutesInt * 60.0 * 1000.0;

    let secondsInt = Math.floor(moinMinutes / 1000.0);
    if (secondsInt < 0) {
        secondsInt = 0;
    }

    let jours = joursInt + '';
    let hours = hoursInt + '';
    let minutes = minutesInt + '';

    if (jours === '0') {
        jours = '';
    }

    if (hours === '0') {
        hours = '';
    }

    if (minutes === '0') {
        minutes = '';
    }

    let finalStr = '';
    if (jours !== '') {
        finalStr += ' ' + jours + 'j';
    }
    if (hours !== '') {
        finalStr += ' ' + hours + 'h';
    }
    if (minutes !== '') {
        finalStr += ' ' + minutes + 'm';
    }
    if (finalStr === '') {
        finalStr = ' 0m';
    }

    if(breakWord){
        if ((dateEnd?.getTime() ?? 0) < 0) {
            return <span className="font-medium text-md !break-words">- {finalStr}</span>;
        } else {
            return <span className="font-medium text-md !break-words">{finalStr}</span>;
        }
    }
    else {
        if ((dateEnd?.getTime() ?? 0) < 0) {
            return <span className="font-medium text-md whitespace-nowrap">- {finalStr}</span>;
        } else {
            return <span className="font-medium text-md whitespace-nowrap">{finalStr}</span>;
        }
    }
};
