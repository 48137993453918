import { AJOInstance, AJOList, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { get } from '../commun/HTTP';
import Result from '../commun/Result';
import { LabelCol } from '../commun/col/LabelCol';
import { SearchInput } from '../commun/component/SearchInput';
import {
    categoryAddSuccess,
    categoryRemoveSuccess,
    labelAddSuccess,
    labelRemoveSuccess,
} from '../modal/vendor/CategoryErrorManager';
import Label from '../object/tag/Label';
import Category from '../object/tag/Category';
import { IconRow } from '../commun/col/IconRow';
import { TablePrestationList } from '../component/TablePrestationList';
import { AddElemVendorModal } from '../modal/vendor/AddElemVendorModal';
import AppManager from '../commun/AppManager';
import { VendorTable } from '../component/vendor/VendorTable';
import { VendorStatTable } from '../component/vendor/VendorStatTable';
import { Tabs, Tab, Box, IconButton } from '@mui/material';
import Vendor from '../object/profile/Vendor';
import { BasePage } from '../component/BasePage';
import { CategoryTable } from '../component/category/CategoryTable';
import { LabelTable } from '../component/label/LabelTable';
import MenuIcon from '@mui/icons-material/Menu';
import { DashboardNavBar } from '../component/nav/DashboardNavBar';

export function BDDVendorPage() {
    const [tab, setTab] = useState('vendor');
    const [search, setSearch] = useState('');
    const [show, setShow] = useState(false);

    // State for category list
    const [stateCategoryList, updateStateCategoryList] = useState(
        new AJOState<AJOList<Category>>(new AJOList(Category)),
    );
    stateCategoryList.setUpdate(updateStateCategoryList);

    // boolean for loading of category list
    const [loadingCategoryList, updateLoadingCategoryList] = useState(true);

    // State for label list
    const [stateLabelList, updateStateLabelList] = useState(new AJOState<AJOList<Label>>(new AJOList(Label)));
    stateLabelList.setUpdate(updateStateLabelList);

    // boolean for loading of label list
    const [loadingLabelList, updateLoadingLabelList] = useState(true);

    // State for list search
    const [stateList, setStateList] = useState(new AJOState(new AJOList<Vendor>(Vendor)));
    stateList.setUpdate(setStateList);

    const [loadingList, updateLoadingList] = useState(true);
    const [loadingStatList, updateLoadingStatList] = useState(true);
    const [type, updateType] = useState('info');

    AppManager.updater().add(stateList);
    AppManager.updater().add(stateCategoryList);
    AppManager.updater().add(stateLabelList);

    // Fetch vendor list with search
    const fetchList = () => {
        if (loadingList) {
            get('/vendor/search', {
                search: search,
            }).then((res: any) => {
                updateLoadingList(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, stateList, true);
                }
            });
        }
    };
    // Fetch vendor list with search for stat
    const fetchListStat = () => {
        if (loadingStatList) {
            get(
                '/vendor/search/stat',
                {
                    search: search,
                },
                () => {
                    const vendorList = stateList.get()!.getList();
                    for (const vendor of vendorList) {
                        vendor.serviceList.setList([]);
                    }
                },
            ).then((res: any) => {
                updateLoadingStatList(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, stateList, true);
                }
            });
        }
    };

    const fetchCategory = () => {
        updateLoadingCategoryList(true);
        get('/category/list').then((res: any) => {
            updateLoadingCategoryList(false);
            if (Result.isSuccess(res)) {
                Result.applyResult(res, stateCategoryList, true);
            }
        });
    };

    const fetchLabel = () => {
        updateLoadingLabelList(true);
        get('/label/list').then((res: any) => {
            updateLoadingLabelList(false);
            if (Result.isSuccess(res)) {
                Result.applyResult(res, stateLabelList, true);
            }
        });
    };

    // AT THE INIT OF COMPONENT
    useEffect(() => {
        fetchCategory();
        fetchLabel();
        fetchList();
    }, []);

    return (
        <BasePage className="h-full items-start">
            <Box sx={{ mb: 3 }} className="flex-col">
                <DashboardNavBar
                    tab={tab}
                    tabList={[
                        {
                            label: 'Fournisseur',
                            value: 'vendor',
                        },
                        {
                            label: 'Catégorie',
                            value: 'category',
                        },
                        {
                            label: 'Label',
                            value: 'label',
                        },
                    ]}
                    onTab={setTab}
                />

                {tab === 'vendor' && (
                    <Box
                        className="flex-1 "
                        sx={{
                            mx: 3,
                        }}
                    >
                        {type === 'info' && (
                            <VendorTable
                                loading={loadingList}
                                list={stateList.get()!.getList()}
                                onChangeType={() => {
                                    fetchListStat();
                                    updateType('stat');
                                }}
                            />
                        )}
                        {type === 'stat' && (
                            <VendorStatTable
                                loading={loadingStatList}
                                list={stateList.get()!.getList()}
                                onChangeType={() => {
                                    fetchList();
                                    updateType('info');
                                }}
                            />
                        )}
                    </Box>
                )}

                {tab === 'category' && (
                    <Box className="min-w-full">
                        <CategoryTable loading={loadingCategoryList} list={stateCategoryList.get()!.getList()} />
                    </Box>
                )}

                {tab === 'label' && (
                    <Box className="min-w-full">
                        <LabelTable loading={loadingLabelList} list={stateLabelList.get()!.getList()} />
                    </Box>
                )}
            </Box>
        </BasePage>
    );
}
