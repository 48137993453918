import { AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { TableRowInterface } from '../../object/interface/TableRowInterface';
import { Col } from './Col';
import Table from './Table';

export interface IRowInterface
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> {
    elem: AJOObject;
    i: number;
    table: Table<AJOObject>;
    bg: string;
}
export const Row: React.FC<IRowInterface> = (props) => {
    const { elem, table, i, bg } = props;

    const [state, updateState] = useState(new AJOState(elem));
    state.setUpdate(updateState);

    if ('TableRowInterface' in elem) {
        (elem as unknown as TableRowInterface).rowState = state;
    }

    const renderCol = (col: Col<any>) => {
        let content;
        let res = '';
        if (table.isAlpha(elem)) {
            res = ' opacity-50';
        }
        if (table.bonusContour !== undefined) {
            content = table.bonusContour(elem, <div className={'p-2 ' + res}>{col.render(elem)}</div>);
        } else if (table.link(elem) === '') {
            content = <div className={'p-2 ' + res}>{col.render(elem)}</div>;
        } else {
            content = (
                <Link to={table.link(elem)}>
                    <div className={'p-2 ' + res}>{col.render(elem)}</div>
                </Link>
            );
        }
        return content;
    };

    let bonusClass = '';
    if (table.focused(elem)) {
        bonusClass = 'box-border border-8 rounded-md border-blue-700 dark:border-blue-500 bg-blue-400 dark:bg-blue-700';
    } else {
        if (table.subRender === undefined || table.subRender(elem) === undefined) {
            bonusClass = 'border-b-2 border-gray-500 dark:border-slate-400';
        } else {
            bonusClass = 'dark:border-slate-400';
        }
    }

    return (
        <tr
            className={
                ' dark:text-white ' + bg + ' ' + bonusClass + ' ' + table.lineStyle(elem) + ' ' + table.bgFunc(elem)
            }
            key={table.key + '-' + i}
        >
            {table.colList.map(
                (row, indexRow) =>
                    row.isVisible() && <td key={table.key + '-' + i + '-' + indexRow}>{renderCol(row)}</td>,
            )}
        </tr>
    );
};
