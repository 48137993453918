import { Box, Container } from '@mui/material';
import { AJOState } from 'mp-js-react-auto-json-object';
import { Spinner } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppManager from 'src/commun/AppManager';
import { BasePage } from 'src/component/BasePage';
import { ClientContactView } from 'src/component/client/ClientContactView';
import { ClientDevisView } from 'src/component/client/ClientDevisView';
import { ClientFacturationView } from 'src/component/client/ClientFacturationView';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';
import Client from '../object/profile/Client';
import { ProfilePage } from './ProfilePage';

export function ClientPage() {
    const { id } = useParams();

    const [client, updateVendor] = useState(new AJOState<Client>(Client.build()));
    client.setUpdate(updateVendor);

    AppManager.updater().add(client);

    const [tab, setTab] = useState('devis');

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        client.get()?.get(id, () => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {}, [tab]);

    return (
        <BasePage className="flex-col flex items-center">
            <DashboardNavBar
                tab={tab}
                onTab={setTab}
                tabList={[
                    {
                        label: 'Devis',
                        value: 'devis',
                    },
                    {
                        label: 'Contact',
                        value: 'contact',
                    },
                    {
                        label: 'Facturation',
                        value: 'facturation',
                    },
                ]}
            />
            {loading ? (
                <Container maxWidth={false}>
                    <Box className="w-full h-full">
                        <Spinner color={'blue'} />
                    </Box>
                </Container>
            ) : (
                <Box className="w-full ">
                    {tab === 'devis' && (
                        <ClientDevisView
                            list={client.get()?.devisList.getList() ?? []}
                            loading={loading}
                            client={client.get() ?? undefined}
                        />
                    )}
                    {tab === 'contact' && <ClientContactView client={client.get()!} />}
                    {tab === 'facturation' && <ClientFacturationView client={client.get()!} />}
                </Box>
            )}
        </BasePage>
    );
}
