import { Box, Typography } from '@mui/material';
import { AJOList, AJOState } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MpModal from 'src/component/extends/MpModal';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';
import AppManager from '../commun/AppManager';
import { Button } from '../commun/component/Button';
import { ComplexInput } from '../commun/component/ComplexInput';
import { Spinner } from '../commun/component/Spinner';
import { TextRaimbow } from '../commun/component/TextRaimbow';
import { Title } from '../commun/component/Title';
import ErrorHTTP from '../commun/ErrorHTTP';
import { get, post } from '../commun/HTTP';
import { Modal } from '../commun/modal/Modal';
import Result from '../commun/Result';
import Toast from '../commun/toast/Toast';
import { BasePage } from '../component/BasePage';
import { EditUserModal } from '../modal/EditUserModal';
import { errorManager, passwordChangeSuccess } from '../modal/vendor/CategoryErrorManager';
import Devis from '../object/Devis';
import User from '../object/User';

export function AccountPage() {
    const [loading, setLoading] = useState(false);
    const [showEdit, updateShowEdit] = useState(false);
    const [showPassword, updateShowPassword] = useState(false);

    const [loadPassword, setLoadPassword] = useState(false);
    const [errorPassword, setErrorPassword] = useState(ErrorHTTP.NO_ERROR());

    const [actuPassword, updateActuPassword] = useState('');
    const [newPassword, updateNewPassword] = useState('');
    const [confNewPassword, updateConfNewPassword] = useState('');

    useEffect(() => {
        setLoadPassword(false);
        setErrorPassword(ErrorHTTP.NO_ERROR());

        updateActuPassword('');
        updateNewPassword('');
        updateConfNewPassword('');
    }, [showPassword]);

    let content;
    if (loading) {
        content = (
            <div className="flex pb-24 items-center justify-center">
                <Spinner color="blue" />
            </div>
        );
    } else {
        content = (
            <div className="pb-24">
                <h1 className="flex w-full flex-col gap-6 mt-5 text-center">
                    <TextRaimbow text={(AppManager.getUser()! as User).getFullName()} className="text-5xl font-bold" />
                </h1>

                <div className="flex justify-center items-center flex-col gap-10 mt-8">
                    <p className="w-96 text-center">
                        Bienvenue sur l'espace de votre profile, vous pouvez changer vos informations, modifier votre
                        mot de passe ou vous deconnecter.
                    </p>

                    <div className="flex flex-col gap-4">
                        <Link to={'/calendar/' + (AppManager.getUser()! as User).getAjoIdentifier()}>
                            <Button onClick={() => {}} className="w-52 whitespace-nowrap" color="blue">
                                Mes heures
                            </Button>
                        </Link>
                        <Button
                            onClick={() => {
                                updateShowEdit(true);
                            }}
                            className="w-52 whitespace-nowrap"
                            color="blue"
                        >
                            Informations
                        </Button>
                        <Button
                            onClick={() => {
                                updateShowPassword(true);
                            }}
                            className="w-52 whitespace-nowrap"
                            color="blue"
                        >
                            Mot de passe
                        </Button>
                        <Button
                            onClick={() => {
                                // Clear all the localStorages except the token
                                Object.keys(localStorage).forEach((key) => {
                                    if (
                                        key !== 'accessToken' &&
                                        key !== 'refreshToken' &&
                                        key !== 'access_token' &&
                                        key !== 'refresh_token'
                                    ) {
                                        localStorage.removeItem(key);
                                    }
                                });
                                AppManager.addToast(new Toast('Votre cache a été réinitialisé avec succès', 'success'));
                            }}
                            className="w-52 whitespace-nowrap"
                            color="blue"
                        >
                            Réinitialiser mon cache
                        </Button>
                        <Button
                            onClick={() => {
                                setLoading(true);
                                post('/user/logout', {}).then((res: any) => {
                                    if (Result.isSuccess(res)) {
                                        AppManager.addToast(
                                            new Toast('Vous avez été déconnecté avec succès', 'success'),
                                        );
                                        AppManager.setAuthenticated(false);
                                        AppManager.navigate('/');
                                    } else {
                                        AppManager.addToast(new Toast(errorManager.get(Result.getError(res)), 'error'));
                                    }
                                });
                            }}
                            className="w-52 whitespace-nowrap"
                            color="blue"
                        >
                            Déconnexion
                        </Button>
                    </div>

                    <div className="absolute">
                        {showEdit && (
                            <EditUserModal
                                user={AppManager.getUser()! as User}
                                show={showEdit}
                                handleClose={() => {
                                    updateShowEdit(false);
                                }}
                            />
                        )}
                    </div>

                    <div className="absolute">
                        {showPassword && (
                            <MpModal
                                done="Modifier"
                                title="Modifier votre mot de passe"
                                onSubmit={() => {
                                    setLoadPassword(true);
                                    setErrorPassword(ErrorHTTP.NO_ERROR());

                                    post('/user/password/update', {
                                        old_password: actuPassword,
                                        new_password: newPassword,
                                        new_password_conf: confNewPassword,
                                    }).then((res: any) => {
                                        setLoadPassword(false);

                                        if (Result.isSuccess(res)) {
                                            setErrorPassword(ErrorHTTP.NO_ERROR());
                                            updateShowPassword(false);

                                            AppManager.addToast(
                                                new Toast(errorManager.get(passwordChangeSuccess), 'success'),
                                            );
                                        } else {
                                            setErrorPassword(Result.getError(res));
                                        }
                                    });
                                }}
                                open={showPassword}
                                onClose={() => {
                                    updateShowPassword(false);
                                }}
                            >
                                {loadPassword ? (
                                    <Box className="flex items-center justify-center p-10">
                                        <Spinner color="blue" />
                                    </Box>
                                ) : (
                                    <Box className="flex flex-col gap-3">
                                        <ComplexInput
                                            placeholder="Mot de passe"
                                            type="password"
                                            typeValue="string"
                                            setValue={updateActuPassword}
                                            value={actuPassword}
                                        />

                                        <ComplexInput
                                            placeholder="Nouveau mot de passe"
                                            type="password"
                                            typeValue="string"
                                            setValue={updateNewPassword}
                                            value={newPassword}
                                        />

                                        <ComplexInput
                                            placeholder="Confirmation du nouveau mot de passe"
                                            type="password"
                                            typeValue="string"
                                            setValue={updateConfNewPassword}
                                            value={confNewPassword}
                                        />

                                        {!errorPassword.equals(ErrorHTTP.NO_ERROR()) && (
                                            <Typography
                                                component={'p'}
                                                className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                                            >
                                                {errorManager.get(errorPassword)}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            </MpModal>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <BasePage className="h-full w-full">
            <DashboardNavBar />
            <Box className="flex-col h-full w-full flex items-center justify-center">{content}</Box>
        </BasePage>
    );
}
