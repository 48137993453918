import styled, { css } from 'styled-components';

type ContextMenuProps = {
    top: number;
    left: number;
};

export const ContextMenu = styled.div<ContextMenuProps>`
    position: absolute;
    width: 230px;
    ${(props: any) => css`
        top: ${props.top}px;
        left: ${props.left}px;
    `}
`;
