import { AJOList, AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { ComplexSelect } from '../commun/component/ComplexSelect';
import { get } from '../commun/HTTP';
import Result from '../commun/Result';
import Utils from '../commun/Utils';
import { Agence } from '../object/Agence';
import Taxe from '../object/Taxe';
import Valeur from '../object/Valeur';

export interface IListComplexSelectProps {
    field: string;
    placeholder: string;
    onSelectId: (id: string) => void;
    onSelectName: (name: string) => void;
    onSelectObject?: (object: any | null) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    onConfirmChange?: (selectName: string, selectId: string, tabOrder: boolean) => void;
    canAdd: boolean;
    baseName?: string;
    baseId?: string;
    htmlName?: string;
    focusInput?: boolean;
    ajoList?: AJOList<any>;
}

export const ListComplexSelect: React.FC<IListComplexSelectProps> = (props) => {
    const {
        field,
        canAdd,
        baseId = '',
        htmlName,
        focusInput,
        baseName = '',
        onConfirmChange = () => {},
        onSelectObject = () => {},
        onFocus = () => {},
        onBlur = () => {},
        onSelectId,
        onSelectName,
        placeholder,
        ajoList,
    } = props;

    const [name, updateName] = useState(baseName);
    const [selectId, updateSelectId] = useState(baseId);

    useEffect(() => {
        if (!focusInput) {
            updateSelectId(baseId);
            updateName(baseName);
        }
    }, [baseId, baseName]);

    const [stateList, updateStateList] = useState(
        new AJOState<AJOList<AJOObject>>(ajoList ?? new AJOList<AJOObject>()),
    );
    stateList.setUpdate(updateStateList);

    const fetchList = () => {
        if (ajoList === undefined) {
            get('/' + field + '/list', {}).then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, stateList, true);
                }
            });
        }
    };

    useEffect(() => {
        fetchList();
    }, []);

    // get function title
    let list = stateList.get()!.getList();
    let loadTitle;
    let loadDescription;
    let filter;
    let finalBaseName = '';
    if (field === 'taxe' || field === 'vendortaxe') {
        loadTitle = (elem: Taxe) => elem.code.get() + '';
        loadDescription = (elem: Taxe) => elem.pourcent.get() + ' %';
        list.sort((a: any, b: any) => {
            return a.pourcent.get() - b.pourcent.get();
        });
        if (name != undefined) {
            finalBaseName = name;
        }
        filter = (elem: Taxe, search: string) =>
            Utils.clean(elem.code.get() + '').startsWith(Utils.clean(search)) ||
            Utils.clean(elem.pourcent.get() + ' %').startsWith(Utils.clean(search));
    } else if (field === 'agence') {
        loadTitle = (elem: Agence) => elem.code.get();
        loadDescription = (elem: Agence) => elem.display_name.get() + '';
        if (name != undefined) {
            finalBaseName = name;
        }
        filter = (elem: Agence, search: string) => {
            return Utils.clean(elem.code.get()).startsWith(Utils.clean(search));
        };
    } else if (field === 'valeur') {
        loadTitle = (elem: Valeur) => elem.pourcent.get() + ' %';
        loadDescription = (elem: Valeur) => elem.description.get() + '';
        if (name != undefined) {
            finalBaseName = name + ' %';
        }
        list.sort((a: any, b: any) => {
            return a.pourcent.get() - b.pourcent.get();
        });
        filter = (elem: Valeur, search: string) => {
            return Utils.clean(elem.pourcent.get() + ' %').startsWith(Utils.clean(search));
        };
    } else {
        if (name != undefined) {
            finalBaseName = name;
        }
        loadTitle = (elem: any) => {
            return elem.name.get();
        };
        loadDescription = (elem: any) => '';
        filter = (elem: any, search: string) => Utils.clean(elem.name.get()).startsWith(Utils.clean(search));
    }
    return (
        <ComplexSelect
            selectId={selectId}
            baseSearch={baseName + ''}
            onConfirmChange={onConfirmChange}
            focusInput={focusInput}
            onSelectIdChange={(select) => {
                updateSelectId(select);
                onSelectId(select);
                onSelectName('');
                onSelectObject(list.find((elem) => elem.getAjoIdentifier() === select)!);
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            elemList={list}
            color="base"
            add={true}
            onAdd={(name) => {
                onSelectName(name);
                updateSelectId('');
            }}
            name={htmlName}
            placeholder={placeholder}
            filter={filter}
            loadDescription={loadDescription}
            loadTitle={loadTitle}
        ></ComplexSelect>
    );
};
