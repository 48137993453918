import { Box } from '@mui/material';
import { AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import { Spinner } from 'src/commun/component/Spinner';
import { DevisArchiveView } from 'src/component/base_price/DevisArchiveView';
import { DevisCommandView } from 'src/component/base_price/DevisCommandView';
import { DevisCommentView } from 'src/component/base_price/DevisCommentView';
import { DevisExportView } from 'src/component/base_price/DevisExportView';
import { DevisHonoraireView } from 'src/component/base_price/DevisHonoraireView';
import { DevisOptionView } from 'src/component/base_price/DevisOptionView';
import Template from 'src/object/Template';
import AppManager from '../commun/AppManager';
import { BasePage } from '../component/BasePage';
import { DevisGeneralView } from '../component/base_price/DevisGeneralView';
import { DevisPrestationView } from '../component/base_price/DevisPrestationView';
import { DashboardNavBar } from '../component/nav/DashboardNavBar';
import BasePrice from '../object/BasePrice';
import Devis from '../object/Devis';
import { CheckCircle } from '@mui/icons-material';
import { ActionManagerView } from 'src/component/ActionManagerView';
import { ActivityPointerView } from 'src/component/ActivityPointerView';
import { post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';

export interface IBasePricePageProps {
    id: any;
    basePriceState: AJOState<BasePrice>;
}
const COUNT_LOAD_NEED = 2;
export const BasePricePage: React.FC<IBasePricePageProps> = (props: IBasePricePageProps) => {
    const { basePriceState, id } = props;

    const [name, setName] = useState('');
    const [num, setNum] = useState('');

    const [start, setStart] = useState<Date | undefined>(undefined);
    const [end, setEnd] = useState<Date | undefined>(undefined);

    const [productSelectList, setProductSelectList] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    let [countLoad, setCountLoad] = useState(0);

    const basePrice = basePriceState.get()!;

    useEffect(() => {
        basePrice.get(id, (res) => {
            setLoading(false);

            setName(basePrice.name.get());
            setNum(basePrice.nb_personne.get());

            setStart(basePrice.start_date.get() !== undefined ? new Date(basePrice.start_date.get()) : undefined);
            setEnd(basePrice.end_date.get() !== undefined ? new Date(basePrice.end_date.get()) : undefined);

            if (basePrice.product.get() != null) {
                setProductSelectList([basePrice.product.get()!]);
            }

            countLoad = countLoad + 1;
            setCountLoad(countLoad);

            basePrice.fetchAll(() => {});
            post('/budget/heure_full', {
                id,
            }).then((res) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, basePrice, true);
                }
            });
        });
    }, []);

    AppManager.startUpdater();

    AppManager.updater().add(basePriceState);
    basePrice.startPaperView();
    AppManager.updater().add(basePrice.prestationList!);
    AppManager.updater().add(basePrice.vendorList!);
    AppManager.updater().add(basePrice.valeurList!);
    AppManager.updater().add(basePrice.taxeList!);
    AppManager.updater().add(basePrice.honoraireList!);
    AppManager.updater().add(basePrice.addressList!);
    AppManager.updater().add(basePrice.agenceList!);

    const [showInfoValidate, setShowInfoValidate] = useState(false);

    let isShow = true;
    const [tab, setTab] = useState('general');

    if (tab === 'prestation') {
        if (
            basePrice.getSelectedPaper() == null ||
            basePrice.getSelectedPaper()!.func.get() === 1 ||
            basePrice.getSelectedPaper()!.archive.get() === true
        ) {
            let i = 0;
            let found = false;

            while (!found && i < basePrice.paperList!.size()) {
                if (basePrice.paperList!.get(i)!.func.get() === 0 && basePrice.paperList!.get(i)!.root.get() === true) {
                    basePrice.selectedPaper = basePrice.paperList!.get(i)!.getAjoIdentifier();

                    found = true;
                }
                i++;
            }
            if (!found) {
                i = 0;
                found = false;

                while (!found && i < basePrice.paperList!.size()) {
                    if (basePrice.paperList!.get(i)!.func.get() === 0) {
                        basePrice.selectedPaper = basePrice.paperList!.get(i)!.getAjoIdentifier();

                        found = true;
                    }
                    i++;
                }
            }
        }
    }

    if (tab === 'honoraire') {
        if (
            basePrice.getSelectedPaper() == null ||
            basePrice.getSelectedPaper()!.func.get() === 0 ||
            basePrice.getSelectedPaper()!.archive.get() === true
        ) {
            let i = 0;
            let found = false;

            while (!found && i < basePrice.paperList!.size()) {
                if (basePrice.paperList!.get(i)!.func.get() === 1 && basePrice.paperList!.get(i)!.root.get() === true) {
                    basePrice.selectedPaper = basePrice.paperList!.get(i)!.getAjoIdentifier();

                    found = true;
                }
                i++;
            }
            if (!found) {
                i = 0;
                found = false;

                while (!found && i < basePrice.paperList!.size()) {
                    if (basePrice.paperList!.get(i)!.func.get() === 1) {
                        basePrice.selectedPaper = basePrice.paperList!.get(i)!.getAjoIdentifier();

                        found = true;
                    }
                    i++;
                }
            }
        }
    }

    if (tab === 'archive') {
        if (basePrice.getSelectedPaper() == null || basePrice.getSelectedPaper()!.archive.get() !== true) {
            let i = 0;
            let found = false;

            while (!found && i < basePrice.paperList!.size()) {
                if (basePrice.paperList!.get(i)!.archive.get() === true) {
                    basePrice.selectedPaper = basePrice.paperList!.get(i)!.getAjoIdentifier();

                    found = true;
                }
                i++;
            }
        }
    }

    basePrice.onTab = setTab;

    return (
        <BasePage className="flex flex-col !h-full !w-full items-start relative">
            <Box className="!w-full !h-full">
                {loading ? (
                    <Box className="!h-full w-full flex">
                        <DashboardNavBar />
                        <Box className="flex items-center justify-center h-full w-full">
                            <Spinner color="blue" />
                        </Box>
                    </Box>
                ) : (
                    <Box className="">
                        <Box sx={{ mb: 3 }} className="flex flex-col">
                            {basePrice instanceof Template ? (
                                <DashboardNavBar
                                    tab={tab}
                                    onTab={setTab}
                                    tabList={[
                                        { label: 'Générale', value: 'general' },
                                        { label: 'Prestation', value: 'prestation' },
                                        { label: 'Honoraire', value: 'honoraire' },
                                    ]}
                                />
                            ) : (
                                <DashboardNavBar
                                    tab={tab}
                                    onTab={setTab}
                                    tabList={[
                                        { label: 'Générale', value: 'general' },
                                        { label: 'Prestation', value: 'prestation' },
                                        { label: 'Honoraire', value: 'honoraire' },
                                        { label: 'Exporter', value: 'export' },
                                        { label: 'Option', value: 'option' },
                                        { label: 'Commande', value: 'command' },
                                        { label: 'Commentaire', value: 'comment' },
                                        { label: 'Archive', value: 'archive' },
                                    ]}
                                />
                            )}
                        </Box>
                        <Box className="w-full">
                            {tab === 'general' && <DevisGeneralView basePrice={basePrice} />}
                            {tab === 'prestation' && <DevisPrestationView basePrice={basePrice} />}
                            {tab === 'honoraire' && <DevisHonoraireView basePrice={basePrice} />}
                            {tab === 'export' && <DevisExportView basePrice={basePrice} />}
                            {basePrice instanceof Devis && tab === 'option' && (
                                <DevisOptionView basePrice={basePrice} />
                            )}
                            {basePrice instanceof Devis && tab === 'command' && (
                                <DevisCommandView basePrice={basePrice as any} />
                            )}
                            {basePrice instanceof Devis && tab === 'archive' && (
                                <DevisArchiveView basePrice={basePrice} />
                            )}
                            {basePrice instanceof Devis && tab === 'comment' && (
                                <DevisCommentView basePrice={basePrice} />
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
            <ActionManagerView />
            <ActivityPointerView />
        </BasePage>
    );
};
