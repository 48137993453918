import { Box, TextField, Typography } from '@mui/material';
import { JSXInternal } from 'preact/src/jsx';
import { Component, createRef } from 'react';
import AppManager from '../../commun/AppManager';
import { Spinner } from '../../commun/component/Spinner';
import { Color } from '../../commun/Constant';
import { post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import { Paper } from '../../object/Paper';
import Category from '../../object/tag/Category';
import { getErrorMessage } from '../../utils/Error';
import MpModal, { IBaseModalProps } from '../extends/MpModal';
import { SelectColor } from '../SelectColor';

export interface IDelPaperModalProps extends IBaseModalProps {
    paper: Paper;
}

export interface IDelPaperModalState {
    loading: boolean;
    error: string;
}

export default class DelPaperModal extends Component<IDelPaperModalProps, IDelPaperModalState> {
    constructor(props: IDelPaperModalProps) {
        super(props);

        this.state = this.initState();
    }

    initState() {
        const p: IDelPaperModalState = {
            loading: false,
            error: '',
        };
        return p;
    }

    componentDidUpdate(
        prevProps: Readonly<IDelPaperModalProps>,
        prevState: Readonly<IDelPaperModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.open !== this.props.open) {
            this.setState(this.initState());
        }
    }

    addVendor() {
        this.setState({
            loading: true,
            error: '',
        });
        post('/paper/delete', {
            id: this.props.paper.getAjoIdentifier(),
        }).then((res: any) => {
            this.setState({
                loading: false,
            });
            if (Result.isSuccess(res)) {
                Result.applyResult(res, AppManager.updater(), true);

                AppManager.addToast(new Toast('La feuille a été supprimée avec succès.', 'success'));
                this.props.onClose();
            } else {
                this.setState({
                    error: getErrorMessage(Result.getErrorStr(res)),
                });
            }
        });
    }

    render(): JSXInternal.Element {
        const { open, onClose } = this.props;
        const { loading, error } = this.state;
        return (
            <MpModal
                open={open}
                onClose={onClose}
                onSubmit={() => {
                    this.addVendor();
                }}
                doneColor="failure"
                title={'Supprimer une feuille'}
                done={'Supprimer'}
            >
                {loading ? (
                    <Box className="flex items-center justify-center py-12">
                        <Spinner color="blue" className="" />
                    </Box>
                ) : (
                    <Box className="flex flex-col !text-gray-700 gap-3">
                        <Typography className="!font-medium">
                            Voulez-vous vraiment supprimer cette feuille ?<br />
                            Cette action est irréversible.
                        </Typography>

                        {error !== '' && (
                            <Typography
                                component={'p'}
                                className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                            >
                                {error}
                            </Typography>
                        )}
                    </Box>
                )}
            </MpModal>
        );
    }
}
