import { AJOList, AJOObject } from 'mp-js-react-auto-json-object';
import { useEffect, useRef, useState } from 'react';
import Address from 'src/object/Address';
import BasePrice from 'src/object/BasePrice';
import Client from 'src/object/profile/Client';
import AppManager from '../../commun/AppManager';
import Autocomplete from '../../commun/component/Autocomplite';
import { post } from '../../commun/HTTP';
import { PerfectAJOList } from '../../commun/object/PerfectAJOList';
import Budget from '../../object/Budget';
import AddressModal from '../address/AddressModal';
import { CustomSelect } from './CustomSelect';

export interface IAddressFlexSelectProps {
    addressList: PerfectAJOList<Address>;
    onAddressSelect: (a: Address | null) => void;
    address: Address | null;
}

export const AddressFlexSelect = (props: IAddressFlexSelectProps) => {
    const { addressList, address } = props;
    const ref = useRef<HTMLInputElement>();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');

    return (
        <>
            <CustomSelect
                ref={ref}
                className="w-full"
                id={'address-select'}
                list={addressList.getList()}
                value={address}
                getValue={(elem: Address) => elem.fullNameAddress() ?? ''}
                placeholder={'Adresse de livraison'}
                onBlur={() => {}}
                onFocus={() => {
                    setName('');
                    addressList?.need();
                }}
                onCreate={(str: string) => {
                    setName(str);
                    setOpen(true);
                }}
                onSelect={(item: any) => {
                    if (item instanceof Address) {
                        props.onAddressSelect(item);
                    } else {
                        props.onAddressSelect(null);
                    }
                }}
            />
            <AddressModal
                address={null}
                baseName={name}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                onCreate={(address: Address) => {
                    props.onAddressSelect(address);
                }}
            />
        </>
    );
};
