import { AJOObject, AJOProperties } from 'mp-js-react-auto-json-object';
import { Color } from '../../commun/Constant';

export abstract class BaseProfile extends AJOObject {
    public name: AJOProperties<any>;
    constructor(type: string) {
        super(type);
        this.name = new AJOProperties('name', this);
    }
    abstract getColor(): Color;
    abstract getTitle(): string;
    abstract getName(): string;
}
