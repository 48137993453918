import { Box, Typography } from '@mui/material';
import { AJOElement, AJOList, AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import MpModal from 'src/component/extends/MpModal';
import MakeAction from '../commun/action/Action';
import ActionManager from '../commun/action/ActionManager';
import AppManager from '../commun/AppManager';
import { ComplexInput } from '../commun/component/ComplexInput';
import { ErrorText } from '../commun/component/ErrorText';
import { Spinner } from '../commun/component/Spinner';
import ErrorHTTP from '../commun/ErrorHTTP';
import ErrorManager from '../commun/ErrorManager';
import ErrorMessage from '../commun/ErrorMessage';
import { post } from '../commun/HTTP';
import { IModalProps, Modal } from '../commun/modal/Modal';
import Result from '../commun/Result';
import Toast from '../commun/toast/Toast';
import Utils from '../commun/Utils';
import User from '../object/User';

export interface IDeleteUserModalProps extends IModalProps {
    user?: User;
    selectIdList?: string[];
    order?: boolean;
    type: 'simple' | 'multiple';
    stateList: AJOState<AJOList<User>>;
}

export const DeleteUserModal: React.FunctionComponent<IDeleteUserModalProps> = (props) => {
    const { user, type, stateList, order = true, selectIdList = [], ...rest } = props;

    const errorManager = new ErrorManager('user/delete');

    /**
     * REST
     */
    const [count, setCount] = useState(0);

    errorManager.addMessage(ErrorMessage.NO_PERMISSION());
    errorManager.addMessage(ErrorMessage.NO_PERMISSION_ROOT());

    if (type == 'simple') {
        if (!user!.delete.get()) {
            errorManager.add(
                ErrorHTTP.SUCCESS().setColor('green-600').setColorDark('green-300'),
                "L'utilisateur a été supprimé avec succès.",
            );
        } else {
            errorManager.add(
                ErrorHTTP.SUCCESS().setColor('green-600').setColorDark('green-300'),
                "L'utilisateur peut de nouveau utiliser la plateforme.",
            );
        }
    } else {
        if (count > 0) {
            if (count == 1) {
                errorManager.add(
                    ErrorHTTP.SUCCESS().setColor('green-600').setColorDark('green-300'),
                    order
                        ? 'Un utilisateur a été supprimé avec succès.'
                        : 'Un utilisateur peut de nouveau utiliser la plateforme.',
                );
            } else {
                errorManager.add(
                    ErrorHTTP.SUCCESS().setColor('green-600').setColorDark('green-300'),
                    order
                        ? count + ' utilisateurs ont été supprimés avec succès.'
                        : count + ' utilisateurs peuvent de nouveau utiliser la plateforme.',
                );
            }
        } else {
            errorManager.add(ErrorHTTP.SUCCESS(), 'Aucun utilisateur demandé.');
        }
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(ErrorHTTP.NO_ERROR());

    const onDone = () => {
        setError(ErrorHTTP.NO_ERROR());
        setLoading(true);
        if (type === 'simple') {
            post('/user/delete', {
                id: user!.getAjoIdentifier(),
                order: order,
            }).then((res: any) => {
                setLoading(false);
                if (Result.isSuccess(res)) {
                    setError(ErrorHTTP.SUCCESS());
                    Result.applyResult(res, user, true);
                    Result.applyResult(res, AppManager.updater(), true);
                    AppManager.addToast(new Toast(errorManager.get(ErrorHTTP.SUCCESS()), 'success'));
                    props.handleClose();
                } else {
                    setError(Result.getError(res));
                }
            });
        } else {
            post('/user/delete', {
                id: selectIdList,
                order: order,
            }).then((res: any) => {
                setLoading(false);
                if (Result.isSuccess(res)) {
                    setError(ErrorHTTP.SUCCESS());
                    setCount(Result.getResult(res)['user'].length);
                    Result.applyResult(res, AppManager.updater(), true);
                    AppManager.addToast(new Toast(errorManager.get(ErrorHTTP.SUCCESS()), 'success'));
                    props.handleClose();
                } else {
                    setError(Result.getError(res));
                }
            });
        }
    };

    let doneText = '';
    let titleText = '';
    let descriptionText = '';
    doneText = order ? 'Supprimer' : 'Révoquer';
    titleText = order ? 'Supprimer ' + user!.getFullName() : 'Révoquer ' + user!.getFullName();
    descriptionText = order
        ? "Voulez-vous vraiment supprimer cet utilisateur ? Une fois l'utilisateur supprimé, toutes ces actions resteront visibles, mais il ne pourra plus effectuer une nouvelle action et une nouvelle connexion."
        : "Voulez-vous vraiment révoquer cet utilisateur ? Une fois l'utilisateur révoqué, il pourra établir une nouvelle connexion et effectuer de nouvelles actions.";

    return (
        <MpModal
            doneColor="failure"
            done={doneText}
            title={titleText}
            onSubmit={onDone}
            onClose={props.handleClose}
            open={props.show}
        >
            {props.loading ? (
                <Box className="flex items-center justify-center p-12">
                    <Spinner color="blue" />
                </Box>
            ) : (
                <Box className="flex flex-col gap-3">
                    <p className="text-black dark:text-white">{descriptionText}</p>

                    {!error.equals(ErrorHTTP.NO_ERROR()) && (
                        <Typography
                            component={'p'}
                            className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                        >
                            {errorManager.get(error)}
                        </Typography>
                    )}
                </Box>
            )}
        </MpModal>
    );
};
