import { Box, Typography } from '@mui/material';
import { Color } from '../../commun/Constant';
import { DateView } from '../../commun/DateView';
import { PerfectTable } from '../../commun/table/PerfectTable';
import Address from '../../object/Address';
import Budget from '../../object/Budget';
import Contact from '../../object/Contact';
import { AddressView } from '../AddressView';
import { ContactView } from '../ContactView';
import { NoteList } from '../NoteList';
import { NoteView } from '../note/NoteView';
import { EuroSpan } from '../../commun/component/EuroSpan';
import { PourcentSpan } from '../../commun/component/PourcentSpan';
import { Link } from 'react-router-dom';

export interface IBudgetStatTableProps {
    list: Budget[];
    loading: boolean;
    color: Color;
}

export const BudgetStatTable = (props: IBudgetStatTableProps) => {
    const { loading, color, list } = props;
    return (
        <PerfectTable
            loading={loading}
            list={list}
            color={props.color ?? 'white'}
            nothing={
                <Box className="flex flex-col gap-3 items-center justify-center">
                    <Typography>{'Aucun devis trouvé.'}</Typography>
                </Box>
            }
            columns={[
                {
                    canSort: true,
                    sort: 1,
                    funSort: (e1: Budget, e2: Budget) => {
                        return (e1.prestation.get()?.name.get() ?? '').localeCompare(
                            e2.prestation.get()?.name.get() ?? '',
                        );
                    },

                    name: 'Date',

                    render: (elem: Budget) => {
                        return <DateView date={new Date(elem.getDate() ?? '')} />;
                    },
                },
                {
                    name: 'Client',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Budget, e2: Budget) => {
                        return (e1.getDevis()?.client.get()?.name.get() ?? '').localeCompare(
                            e2.getDevis()?.client.get()?.name.get() ?? '',
                        );
                    },

                    render: (elem: Budget) => {
                        return (
                            <Link to={'/client/' + elem.getDevis()?.client.get()?.getAjoIdentifier()}>
                                <Typography>{elem.getDevis()?.client.get()?.name.get() ?? ''}</Typography>
                            </Link>
                        );
                    },
                },
                {
                    name: 'Prix de vente',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Budget, e2: Budget) => {
                        return -(e1.getDevis()?.name.get() ?? '').localeCompare(e2.getDevis()!.name.get());
                    },

                    render: (elem: Budget) => {
                        return (
                            <Link to={'/devis/' + elem.getDevis()?.getAjoIdentifier()}>
                                <Typography>{elem.getDevis()?.name.get() ?? ''}</Typography>
                            </Link>
                        );
                    },
                },
                {
                    name: 'Note',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Budget, e2: Budget) => {
                        return -e1.getAverageNote() + e2.getAverageNote();
                    },

                    render: (elem: Budget) => {
                        return (
                            <Typography>
                                <NoteView note={elem.getAverageNote()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'Quantité',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Budget, e2: Budget) => {
                        return -e1.quantite() + e2.quantite();
                    },

                    render: (elem: Budget) => {
                        return <Typography>{elem.quantite()}</Typography>;
                    },
                },
                {
                    name: 'Achat total',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Budget, e2: Budget) => {
                        return -e1.achat() + e2.achat();
                    },

                    render: (elem: Budget) => {
                        return (
                            <Typography>
                                <EuroSpan value={elem.achat()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'VA',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Budget, e2: Budget) => {
                        return -e1.va() + e2.va();
                    },

                    render: (elem: Budget) => {
                        return (
                            <Typography>
                                <PourcentSpan value={elem.va()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'PV HT',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Budget, e2: Budget) => {
                        return -e1.prixVenteHT() + e2.prixVenteHT();
                    },

                    render: (elem: Budget) => {
                        return (
                            <Typography>
                                <EuroSpan value={elem.prixVenteHT()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'TVA',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Budget, e2: Budget) => {
                        return -e1.tax() + e2.tax();
                    },

                    render: (elem: Budget) => {
                        return (
                            <Typography>
                                <PourcentSpan value={elem.tax()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'PV TTC',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Budget, e2: Budget) => {
                        return -e1.prixVenteTTC() + e2.prixVenteTTC();
                    },

                    render: (elem: Budget) => {
                        return (
                            <Typography>
                                <EuroSpan value={elem.prixVenteTTC()} />
                            </Typography>
                        );
                    },
                },
            ]}
        />
    );
};
