import { AJOList, AJOObject } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import BasePrice from 'src/object/BasePrice';
import Contact from 'src/object/Contact';
import Nego from 'src/object/Nego';
import User from 'src/object/User';
import AppManager from '../../commun/AppManager';
import Autocomplete from '../../commun/component/Autocomplite';
import { post } from '../../commun/HTTP';
import { PerfectAJOList } from '../../commun/object/PerfectAJOList';
import Budget from '../../object/Budget';
import VendorModal from '../vendor/VendorModal';
import { CustomSelect } from './CustomSelect';

export interface IChefProjectSelectProps {
    userList: AJOList<User>;
    budget: Budget;
    basePrice: BasePrice;
}

export const ChefProjectSelect = (props: IChefProjectSelectProps) => {
    const { userList, basePrice, budget } = props;

    return (
        <>
            <CustomSelect
                className=" w-40 "
                id={'user-' + budget.getAjoIdentifier()}
                disabled={budget.bon_commande.get() !== null || !basePrice.canAct()}
                list={userList.getList()}
                value={budget.user.get()}
                getValue={(elem: User | undefined | null) => {
                    if (elem === undefined || elem === null) {
                        return '';
                    } else {
                        return elem.getFullName() ?? '';
                    }
                }}
                placeholder="Chef de projet"
                onBlur={() => {
                    budget.leaveFocus();
                }}
                onFocus={() => {
                    budget.getFocus();
                }}
                onCreate={(str: string) => {}}
                onSelect={(item: any) => {
                    if (item instanceof User) {
                        budget.user.set(item);
                        budget.addEdit({
                            user: item.getAjoIdentifier(),
                        });
                        AppManager.makeUpdate();
                    } else if (budget.user.get() !== null) {
                        budget.user.set(null);
                        budget.addEdit({
                            user: -1,
                        });
                        AppManager.makeUpdate();
                    }
                }}
            />
        </>
    );
};
