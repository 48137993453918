import { NbPrestaColInterface } from '../../commun/col/NbPresta';
import { Comptable } from '../Comptable';
import { ProductColInterface } from '../../commun/col/ProduitCol';
import { AJOList, AJOProperties, AJOSimple, AJOUpdater } from 'mp-js-react-auto-json-object';
import { NameColInterface } from '../../commun/col/NameCol';
import { Color } from '../../commun/Constant';
import { get } from '../../commun/HTTP';
import Result from '../../commun/Result';
import Address from '../Address';
import Contact from '../Contact';
import Devis from '../Devis';
import Product from '../tag/Product';
import Utils from '../../commun/Utils';
import AppManager from '../../commun/AppManager';
import { IObjectSelectable } from '../../component/select/ObjectSelect';
import { TimerParent } from '../TimerInterface';
import { ClientColInterface } from '../../commun/col/ClientCol';

export default class Client
    extends TimerParent
    implements
        ClientColInterface,
        IObjectSelectable,
        NameColInterface,
        ProductColInterface,
        NbPrestaColInterface,
        Comptable
{
    public Comptable = true;
    public NbPrestaColInterface = true;
    public DevisColInterface = true;
    public ProductColInterface = true;
    public NameColInterface = true;
    public IObjectSelectable = true;
    public ClientColInterface = true;

    public getClient(): Client {
        return this;
    }

    public getSelectTitle() {
        return this.getName();
    }
    public getSelectDescription() {
        return '';
    }

    public static bonusFilter = (search: string) => {
        return (vendor: Client) => {
            const finalSearch = Utils.clean(search.toLowerCase());
            let can = Utils.clean(vendor.name.get()).toLowerCase().includes(finalSearch);
            if (!can) {
                let i = 0;
                while (!can && i < vendor.getDevisList().length) {
                    can = Utils.clean(vendor.getDevisList()[i].name.get()).toLowerCase().includes(finalSearch);
                    let j = 0;
                    while (!can && j < vendor.getDevisList()[i].getUserList().length) {
                        let user = vendor.getDevisList()[i].getUserList()[j];
                        can = Utils.clean(user.getFullName()).toLowerCase().includes(finalSearch);
                        j++;
                    }
                    i++;
                }
                i = 0;
                while (!can && i < vendor.getDevisList().length) {
                    can = Utils.clean(vendor.getDevisList()[i].num.get() + '')
                        .toLowerCase()
                        .includes(finalSearch);
                    i++;
                }
                i = 0;
                while (!can && i < vendor.getProductList().length) {
                    can = Utils.clean(vendor.getProductList()[i].name.get()).toLowerCase().includes(finalSearch);
                    i++;
                }
            }
            return can;
        };
    };

    public getName(): string {
        return this.name.get();
    }

    public getLink(): string {
        return `/client/${this.getAjoIdentifier()}`;
    }

    public getColor(): Color {
        return 'red';
    }

    public getTitle(): string {
        return 'Client';
    }

    public static _TYPE: string = 'Client';

    public name: AJOProperties<any>;
    public devisList: AJOList<Devis>;
    public contactList: AJOList<Contact>;
    public facturation: AJOSimple<Address>;
    public description: AJOProperties<any>;

    constructor() {
        super(Client._TYPE);

        this.name = new AJOProperties('name', this);
        this.description = new AJOProperties('description', this);
        this.devisList = new AJOList<Devis>(Devis, 'has_devis', this, (a: Devis, b: Devis) => {
            return -new Date(a.date.get()).getTime() + new Date(b.date.get()).getTime();
        });
        this.facturation = new AJOSimple<Address>(Address, 'HAS_CLIENT_FACTURATION'.toLowerCase(), this);
        this.contactList = new AJOList<Contact>(Contact, 'has_client_contact', this);
    }

    public static build(): Client {
        return new Client();
    }

    public get(id: any, callback: () => void): void {
        const obj = this;
        get('/client/get', { id: id }).then((data) => {
            Result.applyResult(data, obj, true);
            callback();
        });
    }

    public getStat(id: any, callback: () => void): void {
        const obj = this;
        get('/client/get/stat', { id: id }).then((data) => {
            Result.applyResult(data, obj, true);
            callback();
        });
    }

    public getProductList(): Product[] {
        let list: Product[] = [];
        for (let devis of this.getDevisList()) {
            if (
                devis.product.get() != null &&
                !list.find((c: Product) => (devis.product.get()!.getAjoIdentifier() ?? '') == c.getAjoIdentifier())
            ) {
                list.push(devis.product.get()!);
            }
        }
        return list;
    }
    public getDevisList(): Devis[] {
        return this.devisList.getList();
    }

    public managerStart = false;
    public managerLoading = true;

    public fetchManagerListInfo(updater: AJOUpdater) {
        const obj = this;
        if (!this.managerStart) {
            this.managerStart = true;
            get('/client/user', { id: this.getAjoIdentifier() }).then((data) => {
                obj.managerLoading = false;
                AppManager.updater().makeUpdate();
                Result.applyResult(data, obj, true);
            });
        }
    }

    public getNbPresta(): number {
        return this.getDevisList().length;
    }

    public achat(): number {
        let sum = 0;
        for (let service of this.getDevisList()) {
            sum += service.achat();
        }
        return sum;
    }
    public quantite(): number {
        let quantite = 0;
        return quantite;
    }
    public prixUnitaire(): number {
        let res = 0;
        return res;
    }
    public va(): number {
        let res = 0;
        res = (this.prixVenteHT() / this.achat() - 1) * 100;

        if (!isFinite(res)) {
            res = 0;
        }

        return res;
    }
    public prixVenteHT(): number {
        let sum = 0;
        for (let service of this.getDevisList()) {
            sum += service.prixVenteHT();
        }

        if (!isFinite(sum)) {
            sum = 0;
        }

        return sum;
    }
    public tax(): number {
        let res = 0;

        res = (this.prixVenteTTC() / this.prixVenteHT() - 1) * 100;

        if (!isFinite(res)) {
            res = 0;
        }
        return res;
    }
    public prixVenteTTC(): number {
        let res = 0;

        let sum = 0;
        for (let service of this.getDevisList()) {
            sum += service.prixVenteTTC();
        }
        res = sum;

        if (!isFinite(res)) {
            res = 0;
        }
        return res;
    }
}
