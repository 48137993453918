import { AJOObject, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import AppManager from '../../commun/AppManager';
import { ComplexInput } from '../../commun/component/ComplexInput';
import Table from '../../commun/table/Table';
import Budget from '../../object/Budget';
import { Paper } from '../../object/Paper';
import { ListComplexSelect } from '../ListComplexSelect';
import { BudgetFieldView } from './BudgetFieldView';
import { RightClickBudget } from './RightClickBudget';
import ReactStars from 'react-stars';
import { post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import { TextArea } from '../../commun/component/TextArea';
import BasePrice from 'src/object/BasePrice';

export interface IBudgetCommentViewProps {
    budget: Budget;
    basePrice: BasePrice;
}
export const BudgetCommentView: React.FC<IBudgetCommentViewProps> = (props) => {
    const { basePrice, budget } = props;

    const [value, setValue] = useState(budget.getMyNote()?.comment.get() ?? undefined);

    const ratingChanged = (value: string) => {
        if (budget.getMyNote() == null) {
            post('/note/update', {
                comment: value,
                budget: {
                    id: budget.getAjoIdentifier(),
                },
            }).then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, AppManager.updater(), true, true);
                }
            });
        } else {
            post('/note/update', {
                id: budget.getMyNote()?.getAjoIdentifier(),
                comment: value,
            }).then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, AppManager.updater(), true, true);
                }
            });
        }
    };

    if (budget instanceof Budget) {
        return (
            <TextArea
                rows={4}
                disabled={!basePrice.canAct()}
                placeholder="Commentaire"
                onBlur={(e) => {
                    ratingChanged((e.target as any).value);
                }}
                value={value}
                onChange={(e) => {
                    setValue((e.target as any).value);
                }}
            />
        );
    } else {
        return null;
    }
};
