import { AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import { CaretDownFill, CaretRightFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import AppManager from '../../commun/AppManager';
import { Button } from '../../commun/component/Button';
import { EuroSpan } from '../../commun/component/EuroSpan';
import { PourcentSpan } from '../../commun/component/PourcentSpan';
import { Color, Size } from '../../commun/Constant';
import { Col } from '../../commun/table/Col';
import Table from '../../commun/table/Table';
import { TableView } from '../../commun/table/TableView';
import Utils from '../../commun/Utils';
import { Action } from '../../object/Action';
import Devis from '../../object/Devis';
import { JSXInternal } from 'preact/src/jsx';
import { Timer } from '../../object/Timer';
import User from '../../object/User';
import { EditSvg } from '../EditSvg';
import { TimerView } from '../TimerView';
import { TableHoraireSectionList } from './TableHoraireSectionList';
import { UserTimerModal } from './UserTimerModal';

interface ITableHoraireActionDevisListProps {
    list: Timer[];
    parent: User;
    size: Size;
    date: Date;
}

export const TableHoraireActionDevisList: React.FC<ITableHoraireActionDevisListProps> = (props) => {
    const { list, date, parent } = props;

    let monday = Utils.getMonday(date);
    let sunday = Utils.getSunday(date);

    const makeTable = () => {
        const table = new Table<Devis | Action>('user');
        let nomCol = new Col<Devis | Action>('Nom');
        nomCol.sort = (a: Devis | Action, b: Devis | Action) => {
            return a.name.get().localeCompare(b.getAjoIdentifier());
        };
        nomCol.type = 'sort';
        nomCol.senseSort = 1;
        nomCol.render = (elem: Devis | Action) => {
            let res;
            if (elem instanceof Devis) {
                res = (
                    <Link to={'/devis/' + elem.getAjoIdentifier()}>
                        <p className={'font-semibold text-' + elem.getColorSpec() + '-700'}>{elem.name.get()}</p>
                    </Link>
                );
            } else {
                res = <p className={'font-semibold text-' + elem.getColorSpec() + '-700'}>{elem.name.get()}</p>;
            }
            return (
                <div className="flex flex-row gap-2 items-center">
                    <div
                        className="flex items-center gap-1"
                        onClick={() => {
                            // Check if selectList of table contain there is elem
                            if (
                                table.selectList.find(
                                    (e: AJOObject) => elem.getAjoIdentifier() === e.getAjoIdentifier(),
                                ) !== undefined
                            ) {
                                table.removeFromSelectList(elem);
                            } else {
                                table.addToSelectList(elem);
                            }
                            AppManager.updater().makeUpdate();
                        }}
                    ></div>
                    {res}
                </div>
            );
        };
        table.addCol(nomCol);

        let dureeCol = new Col<Devis | Action>('Durée');
        dureeCol.sort = (a: Devis | Action, b: Devis | Action) => {
            return a.name.get().localeCompare(b.getAjoIdentifier());
        };
        dureeCol.type = 'sort';
        dureeCol.senseSort = 0;
        dureeCol.render = (elem: Devis | Action) => {
            return (
                <TimerView
                    canJour={false}
                    date={new Date(0)}
                    dateEnd={new Date(Timer.getTimerByAny(list, monday, sunday, elem))}
                />
            );
        };
        table.addCol(dureeCol);

        table.colList.forEach((col: Col<Devis | Action>) => {
            col.table = table;
        });

        return table;
    };

    const [stateTable, setStateTable] = useState(new AJOState(makeTable()));
    stateTable.setUpdate(setStateTable);

    let table = stateTable.get()!;

    table.preventUpdate = true;
    table.state = stateTable;

    table.bgFunc = (elem: Devis | Action) => {
        return 'bg-' + elem.getColorSpec() + '-100';
    };

    table.list = Timer.getClientDevisList(list, Utils.getMonday(date), Utils.getSunday(date));

    table.parent = parent as unknown as Table<AJOObject>;
    table.size = 'sm';
    table.nothingRender = () => {
        return (
            <div>
                <p className="text-center p-24">Aucun élément</p>
            </div>
        );
    };
    table.subTable = true;
    table.subTableRender = (elem: Devis | Action) => {
        let res = undefined;
        if (table.isSelect(elem)) {
            res = <TableHoraireSectionList date={date} timerList={list} parent={elem} size="sm" />;
        }
        return res;
    };
    table.preventUpdate = false;

    return <TableView table={table}></TableView>;
};
