import { TableRowInterface } from './interface/TableRowInterface';
import { Comptable } from './Comptable';
import { Marge } from './Marge';
import { CategoryHonoraire } from './CategoryHonoraire';
import { CategoryColInterface } from './../commun/col/CategoryCol';
import { AJOList, AJOProperties, AJOSimple, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { AJOObject } from 'mp-js-react-auto-json-object';
import { Color, ElemBudget, sortElemBudget } from '../commun/Constant';
import { get, post } from '../commun/HTTP';
import Result from '../commun/Result';
import Budget from './Budget';
import Template from './Template';
import Category from './tag/Category';
import { BudgetManager } from './BudgetManager';
import Vendor from './profile/Vendor';
import AppManager from '../commun/AppManager';
import Utils from '../commun/Utils';
import Devis from './Devis';
import User from './User';
import { Reduc } from './marge_reduc/Reduc';
import BasePrice from './BasePrice';
export class Paper extends BudgetManager implements TableRowInterface, CategoryColInterface, Comptable {
    public rowState: AJOState<Paper> | null = null;

    public Comptable = true;

    public CategoryColInterface = true;
    public TableRowInterface = true;

    public static _TYPE: string = 'Paper';

    public name: AJOProperties<any>;
    public comment: AJOProperties<any>;

    public default: AJOProperties<any>;
    public func: AJOProperties<any>;
    public date_now: AJOProperties<any>;

    public template: AJOSimple<Template>;
    public paper: AJOSimple<Paper>;
    public devis: AJOSimple<Devis>;
    public validate: AJOProperties<any>;
    public archive: AJOProperties<any>;
    public archive_date: AJOProperties<any>;
    public root: AJOProperties<any>;
    public comptable: AJOProperties<any>;
    public quantity: AJOProperties<any>;

    public budgetList: AJOList<Budget | Paper | Marge | Reduc>;
    public margeList: AJOList<Marge>;
    public reducList: AJOList<Reduc>;
    public stricBudgetList: AJOList<Budget>;
    public paperList: AJOList<Paper>;

    constructor() {
        super(Paper._TYPE, 'has_budget');

        this.name = new AJOProperties('name', this);
        this.root = new AJOProperties('root', this);
        this.comment = new AJOProperties('comment', this);
        this.date_now = new AJOProperties('date_now', this);
        this.default = new AJOProperties('default', this);
        this.validate = new AJOProperties('validate', this);
        this.archive = new AJOProperties('archive', this);
        this.archive_date = new AJOProperties('archive_date', this);
        this.paper = new AJOSimple<Paper>(Paper, 'has_paper_child', this);
        this.devis = new AJOSimple<Devis>(Devis, 'has_paper', this);
        this.func = new AJOProperties('func', this);
        this.comptable = new AJOProperties('comptable', this);
        this.template = new AJOSimple<Template>(Template, 'has_paper', this);
        this.quantity = new AJOProperties('HAS_PAPER_CHILD.quantity'.toLowerCase(), this);
        this.margeList = new AJOList<Marge>(Marge, 'has_marge', this);

        this.reducList = new AJOList<Marge>(Reduc, 'has_reduc', this);

        this.stricBudgetList = new AJOList<Budget>(Budget, ['has_budget'], this, sortElemBudget);

        this.paperList = new AJOList<Paper>(Paper, ['has_paper_child'], this, sortElemBudget);

        this.budgetList = new AJOList<Budget | Paper | Marge | Reduc>(
            [Budget, Marge, Paper, Reduc],
            ['has_budget', 'has_marge', 'has_paper_child', 'has_reduc'],
            this,
            sortElemBudget,
        );
    }

    public getDevis(): Devis | null {
        let res: Devis | null = null;
        if (this.devis.get() == null) {
            res = this.paper.get()?.getDevis() ?? null;
        } else {
            res = this.devis.get()!;
        }
        if (res == null) {
            // try with parent
            let paper = this.getAjoParent()?.getAjoParent() ?? null;
            while (paper instanceof Paper) {
                paper = paper?.getAjoParent()?.getAjoParent() ?? null;
            }
            if ((paper as any) instanceof Devis) {
                res = paper as any;
            }
        }
        return res;
    }

    public getBasePrice(): BasePrice | null {
        let res: BasePrice | null = null;

        if (res == null) {
            // try with parent
            let paper = this.getAjoParent()?.getAjoParent() ?? null;
            while (paper instanceof Paper) {
                paper = paper?.getAjoParent()?.getAjoParent() ?? null;
            }
            if ((paper as any) instanceof BasePrice) {
                res = paper as any;
            }
        }
        return res;
    }

    public getTemplate(): Template | null {
        let res: Template | null = null;
        if (this.template.get() == null) {
            res = this.paper.get()?.getTemplate() ?? null;
        } else {
            res = this.template.get()!;
        }
        if (res == null) {
            // try with parent
            let paper = this.getAjoParent()?.getAjoParent() ?? null;
            while (paper instanceof Paper) {
                paper = paper?.getAjoParent()?.getAjoParent() ?? null;
            }
            if (paper?.getAjoType() === 'Template') {
                res = paper as any;
            }
        }
        return res;
    }

    public getRootPaper(): Paper {
        let root: Paper = this;
        while (root.getAjoParent()?.getAjoParent() instanceof Paper) {
            root = root.getAjoParent()!.getAjoParent()! as Paper;
        }
        return root;
    }

    public removeFocus() {
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                if (budget.focus) {
                    budget.focus = false;
                    budget.rowState?.makeUpdate();
                } else if ((budget as any) instanceof Paper) {
                    (budget as any as Paper).removeFocus();
                }
            }
        }
    }

    public removeAllFocus() {
        this.getRootPaper().removeFocus();
    }

    public static build(): Paper {
        return new Paper();
    }
    public getBudgetName(): string {
        return '';
    }

    public getColor(): Color {
        if (this.func.get() == 0) {
            return 'red';
        } else {
            return 'blue';
        }
    }

    public _start: boolean = false;
    public get start(): boolean {
        return this._start;
    }
    public set start(start: boolean) {
        this._start = start;
        this.makeUpdate(true);
    }

    public loadingAdd: boolean = false;
    public _load: boolean = false;
    public get load(): boolean {
        return this._load;
    }
    public set load(load: boolean) {
        this._load = load;
        this.makeUpdate(true);
    }

    public fetch() {
        if (!this.start && !this.load) {
            this.load = false;
            this.start = true;
            get(
                '/paper/get',
                {
                    id: this.getAjoIdentifier(),
                },
                () => {
                    for (const budget of this.budgetList.getList()) {
                        if (budget instanceof Budget) {
                            budget.price.set(undefined);
                            budget.quantity.set(undefined);
                        }
                    }
                },
                true,
            ).then((res: any) => {
                this.removeAllFocus();
                this.load = true;
                this.start = false;
                Result.applyResult(res, this, true);
                Result.applyResult(res, AppManager.updater(), true);
            });
        }
    }

    public getHonoraireCategoryList(): CategoryHonoraire[] {
        let res: Category[] = [];
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget && budget.honoraire.get() != null) {
                let cateList = budget.honoraire.get()!.getCategoryList();
                for (const cate of cateList) {
                    if (res.find((c: Category) => cate.getAjoIdentifier() === c.getAjoIdentifier()) === undefined) {
                        res.push(cate);
                    }
                }
            }
        }
        return res;
    }

    public getCategoryList(): Category[] {
        let res: Category[] = [];
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget && budget.prestation.get() != null) {
                let cateList = budget.prestation.get()!.getCategoryList();
                for (const cate of cateList) {
                    if (res.find((c: Category) => cate.getAjoIdentifier() === c.getAjoIdentifier()) === undefined) {
                        res.push(cate);
                    }
                }
            }
        }
        return res;
    }
    public getCategoryListRec(): Category[] {
        let res: Category[] = [];
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget && budget.prestation.get() != null) {
                let cateList = budget.prestation.get()!.getCategoryList();
                for (const cate of cateList) {
                    if (res.find((c: Category) => cate.getAjoIdentifier() === c.getAjoIdentifier()) === undefined) {
                        res.push(cate);
                    }
                }
            } else if (budget instanceof Paper) {
                let cateList = (budget as Paper).getCategoryListRec();
                for (const cate of cateList) {
                    if (res.find((c: Category) => cate.getAjoIdentifier() === c.getAjoIdentifier()) === undefined) {
                        res.push(cate);
                    }
                }
            }
        }
        return res;
    }

    public delete(paper: Paper) {
        post('/paper/delete/paper', {
            id: paper.getAjoIdentifier(),
            id_child: this.getAjoIdentifier(),
        }).then((res: any) => {
            Result.applyResult(res, AppManager.updater(), true);
        });
    }

    public reInit() {
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                budget.reInit();
            }
        }
    }

    public achatNoOption(): number {
        let res = 0;
        for (let budget of this.budgetList.getList()) {
            if (budget instanceof Paper) {
                res += budget.achatNoOption();
            } else if (budget instanceof Budget) {
                if (budget.option.get() !== true) {
                    res += budget.achat();
                }
            } else {
                if ('Comptable' in budget && !(budget instanceof Option)) {
                    res += (budget as unknown as Comptable).achat();
                }
            }
        }
        return res;
    }

    public honoraire(): number {
        let res = 0;
        for (let budget of this.budgetList.getList()) {
            if (budget instanceof Paper) {
                res += budget.honoraire();
            } else if (budget instanceof Budget) {
                if (budget.getAjoParent()?.getAjoParent() instanceof Paper) {
                    const paper = budget.getAjoParent()?.getAjoParent() as Paper;
                    if (paper.func.get() === 1) {
                        res += budget.prixVenteHT();
                    }
                }
            } else {
                if (budget.getAjoParent()?.getAjoParent() instanceof Paper) {
                    const paper = budget.getAjoParent()?.getAjoParent() as Paper;
                    if (paper.func.get() === 1) {
                        res += (budget as unknown as Comptable).prixVenteHT();
                    }
                }
            }
        }
        return res;
    }

    public honoraireNoOption(): number {
        let res = 0;
        for (let budget of this.budgetList.getList()) {
            if (budget instanceof Paper) {
                res += budget.honoraireNoOption();
            } else if (budget instanceof Budget) {
                if (budget.getAjoParent()?.getAjoParent() instanceof Paper) {
                    const paper = budget.getAjoParent()?.getAjoParent() as Paper;
                    if (paper.func.get() === 1) {
                        if (budget.option.get() !== true) {
                            res += budget.prixVenteHT();
                        }
                    }
                }
            } else {
                if (budget.getAjoParent()?.getAjoParent() instanceof Paper) {
                    const paper = budget.getAjoParent()?.getAjoParent() as Paper;
                    if (paper.func.get() === 1) {
                        res += (budget as unknown as Comptable).prixVenteHT();
                    }
                }
            }
        }
        return res;
    }

    public prixVenteHTNoOption(): number {
        let res = 0;
        for (let budget of this.budgetList.getList()) {
            if (budget instanceof Paper) {
                res += budget.prixVenteHTNoOption();
            } else if (budget instanceof Budget) {
                if (budget.option.get() !== true) {
                    res += budget.prixVenteHT();
                }
            } else {
                if ('Comptable' in budget && !(budget instanceof Option)) {
                    res += (budget as unknown as Comptable).prixVenteHT();
                }
            }
        }
        return res;
    }

    public prixVenteHTNoOptionNoReducNoMarge(): number {
        let res = 0;
        for (let budget of this.budgetList.getList()) {
            if (budget instanceof Paper) {
                res += budget.prixVenteHTNoOption();
            } else if (budget instanceof Budget) {
                if (budget.option.get() !== true) {
                    res += budget.prixVenteHT();
                }
            } else {
                if ('Comptable' in budget && !(budget instanceof Reduc) && !(budget instanceof Marge)) {
                    res += (budget as unknown as Comptable).prixVenteHT();
                }
            }
        }
        return res;
    }

    public prixVenteHTNoReducNoMarge(): number {
        let res = 0;
        for (let budget of this.budgetList.getList()) {
            if ('Comptable' in budget && !(budget instanceof Reduc) && !(budget instanceof Marge)) {
                res += (budget as unknown as Comptable).prixVenteHT();
            }
        }
        if (!isFinite(res)) {
            res = 0;
        }
        return res;
    }

    public prixVenteHTNoReduc(): number {
        let res = 0;
        for (let budget of this.budgetList.getList()) {
            if ('Comptable' in budget && !(budget instanceof Reduc)) {
                res += (budget as unknown as Comptable).prixVenteHT();
            }
        }
        if (!isFinite(res)) {
            res = 0;
        }
        return res;
    }
    public achat(): number {
        return super.achat() * this.facteurPage();
    }
    public prixVenteHT(): number {
        return super.prixVenteHT() * this.facteurPage();
    }
    public prixVenteTTC(): number {
        return super.prixVenteTTC() * this.facteurPage();
    }
    public prixVenteHTNo(): number {
        let res = this.prixVenteHT();
        return res;
    }
    public marge(): number {
        let res = 0;
        if (this.func.get() === 0) {
            res = this.prixVenteHT() - this.achat();
        } else {
            res = this.prixVenteHT();
        }
        return res;
    }
    public margeNoOption(): number {
        let res = 0;
        if (this.func.get() === 0) {
            res = this.prixVenteHTNoOption() - this.achatNoOption();
        } else {
            res = this.prixVenteHTNoOption();
        }
        return res;
    }

    public facteurPage(): number {
        if (this.getAjoParent()?.getAjoParent() instanceof Paper) {
            return this.quantity.get() ?? 1;
        } else {
            return 1;
        }
    }

    public canAct(force: boolean = false): boolean {
        return (
            ((this.getDevis()?.canAct() ?? false) || (this.getTemplate()?.canAct() ?? false)) &&
            (this.comptable.get() === true || this.archive.get() !== true || force)
        );
    }

    public getBonCommandeBudgetList(): Budget[] {
        let res: Budget[] = [];
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                if (budget.bon_commande.get() != null) {
                    if (res.find((b: Budget) => b.getAjoIdentifier() === budget.getAjoIdentifier()) === undefined) {
                        res.push(budget);
                    }
                }
            } else if ((budget as any) instanceof Paper) {
                res = res.concat((budget as any).getBonCommandeBudgetList());
                // Remove duplicate budget
                res = res.filter((item, pos) => {
                    return res.findIndex((b: Budget) => b.getAjoIdentifier() === item.getAjoIdentifier()) == pos;
                });
            }
        }
        return res;
    }

    public getFuncCompliteBudgetList(func: number, complite: boolean): Budget[] {
        let res: Budget[] = [];
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                if (budget.choice.get() !== false) {
                    if (
                        func === this.func.get() &&
                        budget.complite() == complite &&
                        budget.bon_commande.get() == null
                    ) {
                        if (res.find((b: Budget) => b.getAjoIdentifier() === budget.getAjoIdentifier()) === undefined) {
                            res.push(budget);
                        }
                    }
                }
            } else if ((budget as any) instanceof Paper) {
                res = res.concat((budget as any).getFuncCompliteBudgetList(func, complite));
                // Remove duplicate budget
                res = res.filter((item, pos) => {
                    return res.findIndex((b: Budget) => b.getAjoIdentifier() === item.getAjoIdentifier()) == pos;
                });
            }
        }
        return res;
    }

    public getFuncBudgetList(func: number): Budget[] {
        let res: Budget[] = [];
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                if (func === this.func.get() && budget.choice.get() !== false) {
                    res.push(budget);
                }
            } else if ((budget as any) instanceof Paper) {
                res = res.concat((budget as any).getFuncBudgetList(func));
            }
        }
        return res;
    }

    public getOptionBudgetList(func: number): Budget[] {
        let res: Budget[] = [];
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                if (budget.option.get() === true && func === this.func.get() && budget.bon_commande.get() == null) {
                    res.push(budget);
                }
            } else if ((budget as any) instanceof Paper) {
                res = res.concat((budget as any).getOptionBudgetList(func));
            }
        }
        return res;
    }

    public getOptionHonoraireCategoryList(): CategoryHonoraire[] {
        let categoryList = [] as CategoryHonoraire[];
        let i = 0;
        while (i < this.budgetList.getList().length) {
            let budget = this.budgetList.getList()[i];
            if (
                budget instanceof Budget &&
                budget.option.get() === true &&
                budget.honoraire.get() != null &&
                budget.honoraire.get()!.category.get() != null
            ) {
                let cateList = budget.honoraire.get()!.getCategoryList();
                for (const cate of cateList) {
                    if (
                        categoryList.find(
                            (c: CategoryHonoraire) => c.getAjoIdentifier() === cate.getAjoIdentifier(),
                        ) === undefined
                    ) {
                        categoryList.push(cate);
                    }
                }
            } else if ((budget as any) instanceof Paper) {
                categoryList = categoryList.concat((budget as any).getOptionHonoraireCategoryList());
            }
            i += 1;
        }
        return categoryList;
    }

    public getOptionCategoryList(): Category[] {
        let categoryList = [] as Category[];
        let i = 0;
        while (i < this.budgetList.getList().length) {
            let budget = this.budgetList.getList()[i];
            if (
                budget instanceof Budget &&
                budget.option.get() === true &&
                budget.prestation.get() != null &&
                budget.bon_commande.get() == null &&
                budget.prestation.get()!.category.get() != null
            ) {
                let cateList = budget.prestation.get()!.getCategoryList();
                for (const cate of cateList) {
                    if (
                        categoryList.find((c: Category) => c.getAjoIdentifier() === cate.getAjoIdentifier()) ===
                        undefined
                    ) {
                        categoryList.push(cate);
                    }
                }
            } else if ((budget as any) instanceof Paper) {
                categoryList = categoryList.concat((budget as any).getOptionCategoryList());
            }
            i += 1;
        }
        return categoryList;
    }

    public achatBef(): number {
        let res = 0;
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                res += budget.achatBef();
            } else if ((budget as any) instanceof Paper) {
                res += (budget as any).achatBef();
            }
        }
        return res;
    }

    public achatBefHonoraire(): number {
        let res = 0;
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                if (this.func.get() === 1) {
                    res += budget.achatBef();
                }
            } else if ((budget as any) instanceof Paper) {
                res += (budget as any).achatBefHonoraire();
            }
        }
        if (isNaN(res)) {
            res = 0;
        }
        return res;
    }

    public prixVenteHTHonoraire(): number {
        let res = 0;
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                if (this.func.get() === 1) {
                    res += budget.prixVenteHT();
                }
            } else if ((budget as any) instanceof Paper) {
                res += (budget as any).prixVenteHTHonoraire();
            }
        }
        if (isNaN(res)) {
            res = 0;
        }
        return res;
    }

    public nego(): number {
        let res = (1 - this.prixVenteHTHonoraire() / this.achatBefHonoraire()) * 100;

        if (isNaN(res)) {
            res = 0;
        }

        return res;
    }

    public getRecCategoryList(): Category[] {
        let categoryList = [] as Category[];
        let i = 0;
        while (i < this.budgetList.getList().length) {
            let budget = this.budgetList.getList()[i];
            if (
                budget instanceof Budget &&
                budget.prestation.get() != null &&
                budget.prestation.get()!.category.get() != null
            ) {
                let cateList = budget.prestation.get()!.getCategoryList();
                for (const cate of cateList) {
                    if (
                        categoryList.find((c: Category) => c.getAjoIdentifier() === cate.getAjoIdentifier()) ===
                        undefined
                    ) {
                        categoryList.push(cate);
                    }
                }
            } else if ((budget as any) instanceof Paper) {
                categoryList = categoryList.concat((budget as any).getRecCategoryList());
            }
            i += 1;
        }
        return categoryList;
    }

    private _openDelete: boolean = false;
    public get openDelete(): boolean {
        return this._openDelete;
    }
    public set openDelete(value: boolean) {
        this._openDelete = value;
        this.rowState?.makeUpdate();
    }

    public getVendorList(): Vendor[] {
        let vendorList = [] as Vendor[];
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget && budget.choice.get() !== false && budget.vendor.get() != null) {
                let found = vendorList.find(
                    (v: Vendor) => v.getAjoIdentifier() === budget.vendor.get()?.getAjoIdentifier() ?? '',
                );
                if (!found) {
                    vendorList.push(budget.vendor.get()!);
                }
            } else if ((budget as any) instanceof Paper) {
                let childList = (budget as any).getVendorList();
                for (const child of childList) {
                    let found = vendorList.find((v: Vendor) => v.getAjoIdentifier() === child.getAjoIdentifier());
                    if (!found) {
                        vendorList.push(child);
                    }
                }
            }
        }
        return vendorList;
    }

    public getVendorBudgetList(vendor: Vendor): Budget[] {
        let res: Budget[] = [];

        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                if (vendor.getAjoIdentifier() === budget.vendor.get()?.getAjoIdentifier()) {
                    res.push(budget);
                }
            } else if ((budget as any) instanceof Paper) {
                res = res.concat((budget as any).getVendorBudgetList(vendor));
            }
        }
        return res;
    }

    public getName(): string {
        let base = this.name.get();
        if (this.archive.get()) {
            base += ' au ' + Utils.toDDMMYY(this.archive_date.get());
        }
        return base;
    }

    public forfaitHonoraire(): number {
        let res = 0;
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                if (this.func.get() === 1) {
                    res += budget.prixVenteHT();
                }
            } else if ((budget as any) instanceof Paper) {
                res += (budget as any).forfaitHonoraire();
            }
        }
        if (isNaN(res)) {
            res = 0;
        }
        return res;
    }

    public minute(): number {
        let res = 0;
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                if (this.func.get() === 1) {
                    res += (budget.quantity.get() ?? 0) * (budget.nb_personnel.get() ?? 0);
                }
            } else if ((budget as any) instanceof Paper) {
                res += (budget as any).minute();
            }
        }
        return res;
    }

    public jour(): number {
        let res = this.minute();
        return res / 60 / 7;
    }

    public override va(): number {
        let res = 0;
        if (this.func.get() === 0) {
            res = (this.marge() / this.prixVenteHT()) * 100;
        } else {
            res = 0;
        }
        return res;
    }

    public vaNoOption(): number {
        let res = 0;
        if (this.func.get() === 0) {
            res = (this.margeNoOption() / this.prixVenteHTNoOption()) * 100;
        } else {
            res = 0;
        }
        return res;
    }

    public getPrestationList(): Budget[] {
        let res: Budget[] = [];
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                if (this.func.get() === 0) {
                    res.push(budget);
                }
            } else if ((budget as any) instanceof Paper) {
                res.push(...(budget as any).getPrestationList());
            }
        }
        return res;
    }

    public canEdit(): boolean {
        return this.archive.get() !== true || this.comptable.get() === true;
    }

    public getRecBudgetList(): Budget[] {
        let list = [] as Budget[];
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                list.push(budget);
            } else if ((budget as any) instanceof Paper) {
                list.push(...(budget as any).getRecBudgetList());
            }
        }
        return list;
    }

    /**
     * Regarde si le papier peut etre supprimé
     */
    public canDelete(): boolean {
        if (this.root.get()) {
            return false;
        }
        if (this.archive.get()) {
            return false;
        }
        return true;
    }

    getBudgetInProgressList(): Budget[] {
        let res = [] as Budget[];
        if (this.func.get() === 0) {
            for (const budget of this.budgetList.getList()) {
                if (budget instanceof Budget) {
                    if (budget.complite() && budget.bon_commande.get() === null) {
                        res.push(budget);
                    }
                }
            }
        }
        for (const paper of this.paperList.getList()) {
            res.push(...paper.getBudgetInProgressList());
        }
        return res;
    }

    getBudgetInPrestationList(): Budget[] {
        let res = [] as Budget[];
        if (this.func.get() === 0) {
            for (const budget of this.budgetList.getList()) {
                if (budget instanceof Budget) {
                    res.push(budget);
                }
            }
        }
        for (const paper of this.paperList.getList()) {
            res.push(...paper.getBudgetInPrestationList());
        }
        return res;
    }

    getBudgetInOptionList(): Budget[] {
        let res = [] as Budget[];
        if (this.func.get() === 0) {
            for (const budget of this.budgetList.getList()) {
                if (budget instanceof Budget) {
                    if (budget.option.get() === true) {
                        res.push(budget);
                    }
                }
            }
        }
        for (const paper of this.paperList.getList()) {
            res.push(...paper.getBudgetInOptionList());
        }
        return res;
    }

    getBudgetInCommandList(): Budget[] {
        let res = [] as Budget[];
        if (this.func.get() === 0) {
            for (const budget of this.budgetList.getList()) {
                if (budget instanceof Budget) {
                    if (budget.bon_commande.get() !== null) {
                        res.push(budget);
                    }
                }
            }
        }
        for (const paper of this.paperList.getList()) {
            res.push(...paper.getBudgetInCommandList());
        }
        return res;
    }

    getBudgetInCompliteList(): Budget[] {
        let res = [] as Budget[];
        if (this.func.get() === 0) {
            for (const budget of this.budgetList.getList()) {
                if (budget instanceof Budget) {
                    if (budget.bon_commande.get() === null && !budget.complite()) {
                        res.push(budget);
                    }
                }
            }
        }
        for (const paper of this.paperList.getList()) {
            res.push(...paper.getBudgetInCompliteList());
        }
        return res;
    }
}
