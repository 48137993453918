import { Box, Button, Typography } from '@mui/material';
import { JSXInternal } from 'preact/src/jsx';
import React from 'react';
import BasePrice from 'src/object/BasePrice';
import AppManager from '../commun/AppManager';
import { ComplexInput } from '../commun/component/ComplexInput';
import { Spinner } from '../commun/component/Spinner';
import { TextArea } from '../commun/component/TextArea';
import ErrorHTTP from '../commun/ErrorHTTP';
import { post, postFile } from '../commun/HTTP';
import Result from '../commun/Result';
import Toast from '../commun/toast/Toast';
import { errorManager, uploadSuccess } from '../modal/vendor/CategoryErrorManager';
import { BonCommande } from '../object/BonCommande';
import { SERVER_URL } from '../utils/Constant';
import { DeleteSvg } from './DeleteSvg';
import { EditSvg } from './EditSvg';
import MpModal from './extends/MpModal';

interface IBudgetFieldViewProps {
    bonCommande: BonCommande;
    basePrice: BasePrice;
}

interface IBudgetFieldViewState {
    showEmailEdit: boolean;
    showXlsxEdit: boolean;
    showDelete: boolean;
    loading: boolean;
    loadingSend: boolean;
    error: ErrorHTTP;
    content: string;
    email: string;
    subject: string;
    file: any;
}

export class BonCommandeView extends React.Component<IBudgetFieldViewProps, IBudgetFieldViewState> {
    constructor(props: IBudgetFieldViewProps) {
        super(props);

        this.state = this.initState();
    }

    initState(): IBudgetFieldViewState {
        return {
            showEmailEdit: false,
            showXlsxEdit: false,
            showDelete: false,
            loading: false,
            loadingSend: false,
            file: null,
            error: ErrorHTTP.NO_ERROR(),
            content: this.props.bonCommande.content.get() ?? '',
            email: this.props.bonCommande.email.get() ?? '',
            subject: this.props.bonCommande.subject.get() ?? '',
        };
    }

    componentDidUpdate(
        prevProps: Readonly<IBudgetFieldViewProps>,
        prevState: Readonly<IBudgetFieldViewState>,
        snapshot?: any,
    ): void {
        if (prevState.showEmailEdit !== this.state.showEmailEdit) {
            this.setState({
                content: this.props.bonCommande.content.get() ?? '',
                email: this.props.bonCommande.email.get() ?? '',
                subject: this.props.bonCommande.subject.get() ?? '',
                loading: false,
                error: ErrorHTTP.NO_ERROR(),
            });
        }
        if (prevState.showXlsxEdit !== this.state.showXlsxEdit) {
            this.setState({
                file: null,
                loading: false,
                error: ErrorHTTP.NO_ERROR(),
            });
        }
    }

    render(): JSXInternal.Element {
        const { bonCommande, basePrice } = this.props;
        const { file, loading, loadingSend, showDelete, error, showEmailEdit, showXlsxEdit, content, subject, email } =
            this.state;
        return (
            <div className="flex p-3 flex-row gap-3 w-full items-center">
                <Typography variant="h6" className="text-white">
                    {bonCommande.code.get()}
                </Typography>
                {basePrice.canAct() && (
                    <Box>
                        {loadingSend ? (
                            <Button className="flex items-center gap-2" color="success" variant="contained">
                                <Spinner color="white" />
                            </Button>
                        ) : (
                            <Button
                                className="flex items-center gap-2"
                                color="success"
                                variant="contained"
                                onClick={() => {
                                    this.setState({
                                        loadingSend: true,
                                    });
                                    bonCommande.reSend(() => {
                                        this.setState({
                                            loadingSend: false,
                                        });
                                    });
                                }}
                            >
                                {bonCommande.status_email.get() == -1 ? 'Envoyer' : 'Renvoyer'}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                </svg>
                            </Button>
                        )}
                    </Box>
                )}
                <a
                    href={SERVER_URL + '/command/' + bonCommande.code.get() + '.pdf'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button className="flex items-center gap-2" color="primary" variant="contained">
                        PDF
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </Button>
                </a>
                <a
                    href={SERVER_URL + '/command/' + bonCommande.code.get() + '.docx'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button className="flex items-center gap-2" color="primary" variant="contained">
                        Excel
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </Button>
                </a>
                {basePrice.canAct() && (
                    <Button
                        className="flex items-center gap-2"
                        color="primary"
                        variant="contained"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            //this.initParams();
                            this.setState({
                                showEmailEdit: true,
                            });
                        }}
                    >
                        Email
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </Button>
                )}
                {basePrice.canAct() && (
                    <Button
                        className="flex items-center gap-2"
                        color="primary"
                        variant="contained"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            //this.initParams();
                            this.setState({
                                showXlsxEdit: true,
                            });
                        }}
                    >
                        Modifier
                        <EditSvg className="h-5 w-5" />
                    </Button>
                )}
                {basePrice.canAct() && (
                    <Button
                        className="flex items-center gap-2"
                        color="error"
                        variant="contained"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            //this.initParams();
                            this.setState({
                                showDelete: true,
                            });
                        }}
                    >
                        Supprimer
                        <DeleteSvg className="h-5 w-5" />
                    </Button>
                )}

                <div
                    className="absolute"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <MpModal
                        title="Modifier le mail"
                        done="Modifier"
                        onClose={() => {
                            this.setState({
                                showEmailEdit: false,
                            });
                        }}
                        open={showEmailEdit}
                        onSubmit={() => {
                            this.setState({
                                loading: true,
                                error: ErrorHTTP.NO_ERROR(),
                            });

                            post('/bon_commande/update', {
                                id: bonCommande.getAjoIdentifier(),
                                content: content,
                                subject: subject,
                                email: email,
                            }).then((res: any) => {
                                this.setState({
                                    loading: false,
                                });
                                if (Result.isSuccess(res)) {
                                    Result.applyResult(res, AppManager.updater(), true);

                                    this.setState({
                                        showEmailEdit: false,
                                        error: ErrorHTTP.NO_ERROR(),
                                    });

                                    AppManager.addToast(new Toast('Le mail a été modifié avec succès.', 'success'));
                                } else {
                                    this.setState({
                                        error: Result.getError(res),
                                    });
                                }
                                AppManager.makeUpdate();
                            });
                        }}
                    >
                        {loading ? (
                            <Box className="flex items-center justify-center py-12">
                                <Spinner color="blue" className="" />
                            </Box>
                        ) : (
                            <Box className="flex w-full flex-col gap-3">
                                <ComplexInput
                                    typeValue="string"
                                    placeholder="Destinataire"
                                    value={email}
                                    setValue={(value) => {
                                        this.setState({
                                            email: value,
                                        });
                                    }}
                                />
                                <ComplexInput
                                    typeValue="string"
                                    placeholder="Sujet"
                                    value={subject}
                                    setValue={(value) => {
                                        this.setState({
                                            subject: value,
                                        });
                                    }}
                                />
                                <TextArea
                                    className="w-full bg-gray-300 placeholder:text-gray-600 !border-gray-600 border rounded-lg py-2 px-3 focus:!border focus:!ring focus:!ring-blue-500 focus:!border-blue-500"
                                    onChange={(e) => {
                                        this.setState({
                                            content: (e.target as any)?.value ?? '',
                                        });
                                    }}
                                    rows={8}
                                    value={content}
                                ></TextArea>

                                {!error.equals(ErrorHTTP.NO_ERROR()) && (
                                    <Typography
                                        component={'p'}
                                        className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                                    >
                                        {errorManager.get(error)}
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </MpModal>
                </div>
                <div
                    className="absolute"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <MpModal
                        title="Modifier le tableau excel"
                        done="Modifier"
                        onClose={() => {
                            this.setState({
                                showXlsxEdit: false,
                            });
                        }}
                        open={showXlsxEdit}
                        onSubmit={() => {
                            this.setState({
                                loading: true,
                                error: ErrorHTTP.NO_ERROR(),
                            });

                            const url = '/xlsx_update';

                            const formData = new FormData();
                            formData.append('id', bonCommande.getAjoIdentifier());
                            formData.append('file', file);

                            postFile(url, formData).then((response) => {
                                if (Result.isSuccess(response)) {
                                    this.setState({
                                        loading: false,
                                        error: uploadSuccess,
                                        showXlsxEdit: false,
                                    });

                                    AppManager.addToast(new Toast(errorManager.get(uploadSuccess), 'success'));
                                } else {
                                    this.setState({
                                        loading: false,
                                        error: Result.getError(response),
                                    });
                                }
                                AppManager.makeUpdate();
                            });
                        }}
                    >
                        {loading ? (
                            <Box className="flex items-center justify-center py-12">
                                <Spinner color="blue" className="" />
                            </Box>
                        ) : (
                            <Box>
                                {showXlsxEdit && (
                                    <input
                                        type="file"
                                        onChange={(e) => {
                                            if (
                                                (e.target as any)?.files != null &&
                                                (e.target as any).files.length > 0
                                            ) {
                                                this.setState({
                                                    file: (e.target as any).files[0],
                                                });
                                            }
                                        }}
                                    />
                                )}

                                {!error.equals(ErrorHTTP.NO_ERROR()) && (
                                    <Typography
                                        component={'p'}
                                        className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                                    >
                                        {errorManager.get(error)}
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </MpModal>
                </div>
                <div
                    className="absolute"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <MpModal
                        title="Supprimer le bon de commande"
                        done="Supprimer"
                        onClose={() => {
                            this.setState({
                                showDelete: false,
                            });
                        }}
                        doneColor="failure"
                        open={showDelete}
                        onSubmit={() => {
                            this.setState({
                                loading: true,
                                error: ErrorHTTP.NO_ERROR(),
                            });
                            post('/commande/delete', {
                                id: bonCommande.getAjoIdentifier(),
                            }).then((res: any) => {
                                if (Result.isSuccess(res)) {
                                    this.setState({
                                        showDelete: false,
                                    });
                                    Result.applyResult(res, AppManager.updater(), true);
                                    this.setState({
                                        loading: false,
                                        error: ErrorHTTP.NO_ERROR(),
                                    });
                                    AppManager.addToast(
                                        new Toast('Le bon de commande a été supprimé avec succès.', 'success'),
                                    );
                                } else {
                                    this.setState({
                                        loading: false,
                                        error: Result.getError(res),
                                    });
                                }
                            });
                        }}
                    >
                        {loading ? (
                            <Box className="flex items-center justify-center py-12">
                                <Spinner color="blue" className="" />
                            </Box>
                        ) : (
                            <Box>
                                <p className="text-black">
                                    Êtes-vous sûre de vouloir supprimer le bon de commande cette action est
                                    irréversible.
                                </p>

                                {!error.equals(ErrorHTTP.NO_ERROR()) && (
                                    <Typography
                                        component={'p'}
                                        className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                                    >
                                        {errorManager.get(error)}
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </MpModal>
                </div>
            </div>
        );
    }
}
