import { Box, Typography } from '@mui/material';
import { AJOList, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { Spinner } from 'src/commun/component/Spinner';
import MpModal from 'src/component/extends/MpModal';
import AppManager from '../../commun/AppManager';
import { Button } from '../../commun/component/Button';
import { CheckBox } from '../../commun/component/CheckBox';
import { ComplexInput } from '../../commun/component/ComplexInput';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { get, post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import Utils from '../../commun/Utils';
import { ListComplexSelect } from '../../component/ListComplexSelect';
import { PaperView } from '../../component/paper/PaperView';
import { ClientSelect } from '../../component/select/ClientSelect';
import { TemplateList } from '../../component/TemplateList';
import { UserList } from '../../component/UserList';
import { Action } from '../../object/Action';
import Devis from '../../object/Devis';
import { Paper } from '../../object/Paper';
import Permission from '../../object/Permission';
import Client from '../../object/profile/Client';
import Prestation from '../../object/profile/Prestation';
import Template from '../../object/Template';
import User from '../../object/User';
import {
    actionAddSuccess,
    actionEditSuccess,
    clientAddSuccess,
    clientEditSuccess,
    clientNotSelect,
    devisAddSuccess,
    errorManager,
    paperAddSuccess,
    vendorAddSuccess,
} from './CategoryErrorManager';

export interface IAddElemClientModalProps {
    show: boolean;
    baseName?: string;
    templateList: Template[];
    mode: 'both_action' | 'both' | 'client' | 'devis' | 'action';
    handleClose: () => void;
    clientBase: Client | null;
    refElem?: Client | Devis | Action | null;
    onCreate?: (elam: Client | Devis | Action) => void;
}
export const AddElemClientModal: React.FC<IAddElemClientModalProps> = (props) => {
    const {
        templateList,
        refElem = null,
        clientBase,
        onCreate = () => {},
        baseName = '',
        handleClose,
        show,
        mode,
    } = props;

    let baseFunc = 0;
    if (mode == 'both') {
        baseFunc = 0;
    } else if (mode == 'both_action') {
        baseFunc = 1;
    } else if (mode == 'action') {
        baseFunc = 2;
    } else if (mode == 'client') {
        baseFunc = 0;
    } else if (mode == 'devis') {
        baseFunc = 1;
    }

    const [name, updateName] = useState(baseName);

    const [choiceClient, updateChoiceClient] = useState(clientBase == null);

    const [func, updateFunc] = useState<0 | 1 | -1 | 2>(baseFunc as 0 | 1 | -1 | 2);

    const [error, updateError] = useState(ErrorHTTP.NO_ERROR());
    const [loading, updateLoad] = useState(false);
    const [report, updateReport] = useState(false);

    const [client, updateClient] = useState<Client | null>(clientBase);

    useEffect(() => {
        if (show) {
            Utils.blur();
        }
        updateError(ErrorHTTP.NO_ERROR());
        updateLoad(false);

        updateClient(clientBase);
        updateName(baseName);
        updateReport(false);

        updateChoiceClient(clientBase == null);

        updateFunc(baseFunc as 0 | 1 | -1);
    }, [show]);

    const paperPestation = new Paper();
    paperPestation.name.set('Client');
    paperPestation.func.set(0);
    paperPestation.default.set(false);

    const paperHonoraire = new Paper();
    paperHonoraire.name.set('Prix de vente');
    paperHonoraire.func.set(1);
    paperHonoraire.default.set(false);

    const paperAction = new Paper();
    paperAction.name.set('Action');
    paperAction.func.set(2);
    paperAction.default.set(false);

    let list = [paperPestation, paperHonoraire, paperAction];

    const select = (p: Paper) => {
        for (const elem of list) {
            elem.default.set(p === elem);
            if (p === elem) {
                updateFunc(elem.func.get());
            }
        }
    };

    // State for category list
    const [stateUserList, updateStateUserList] = useState(new AJOState<AJOList<User>>(new AJOList(User)));
    stateUserList.setUpdate(updateStateUserList);

    const [userList, updateUserList] = useState([AppManager.getUser()!] as User[]);
    const checkUser = (user: User) => {
        if (user.getAjoIdentifier() !== AppManager.getUser()!.getAjoIdentifier()) {
            if (userList.find((elem) => elem.getAjoIdentifier() == user.getAjoIdentifier()) != undefined) {
                updateUserList(userList.filter((elem) => elem.getAjoIdentifier() != user.getAjoIdentifier()));
            } else {
                updateUserList([...userList, user]);
            }
        }
    };

    useEffect(() => {
        if (show) {
            get('/user/list').then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, stateUserList.get()!, true);
                }
            });
        }
    }, [show]);

    const onAdd = () => {
        updateError(ErrorHTTP.NO_ERROR());
        updateLoad(true);
        if (func === 0) {
            let params: { [key: string]: any } = {
                name: name,
            };
            if (refElem !== null) {
                params.id = refElem.getAjoIdentifier();
            }
            post('/client/update', params).then((res: any) => {
                updateLoad(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, AppManager.updater(), true);
                    if (refElem !== null) {
                        updateError(clientEditSuccess);
                    } else {
                        updateError(clientAddSuccess);
                    }

                    let elem = new Client();
                    Result.applyResult(res, elem, true);
                    onCreate(elem);

                    if (refElem !== null) {
                        AppManager.addToast(new Toast(errorManager.get(clientEditSuccess), 'success'));
                        props.handleClose();
                    } else {
                        AppManager.addToast(new Toast(errorManager.get(clientAddSuccess), 'success'));
                        props.handleClose();
                    }
                } else {
                    updateError(Result.getError(res));
                }
            });
        } else if (func === 2) {
            let params: { [key: string]: any } = {
                name: name,
            };
            if (refElem !== null) {
                params.id = refElem.getAjoIdentifier();
            }
            post('/action/update', params).then((res: any) => {
                updateLoad(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, AppManager.updater(), true);
                    if (refElem !== null) {
                        updateError(actionEditSuccess);
                    } else {
                        updateError(actionAddSuccess);
                    }

                    let elem = new Action();
                    Result.applyResult(res, elem, true);
                    onCreate(elem);

                    if (refElem !== null) {
                        AppManager.addToast(new Toast(errorManager.get(actionEditSuccess), 'success'));
                        props.handleClose();
                    } else {
                        AppManager.addToast(new Toast(errorManager.get(actionAddSuccess), 'success'));
                        props.handleClose();
                    }
                } else {
                    updateError(Result.getError(res));
                }
            });
        } else {
            if (client != null) {
                post('/devis/create', {
                    name: name,
                    report: report,
                    id_user_list: userList.map((elem) => elem.getAjoIdentifier()),
                    id_client: client!.getAjoIdentifier(),
                }).then((res: any) => {
                    updateLoad(false);
                    if (Result.isSuccess(res)) {
                        Result.applyResult(res, AppManager.updater(), true);
                        updateError(devisAddSuccess);

                        let elem = new Devis();
                        Result.applyResult(res, elem, true);
                        onCreate(elem);

                        AppManager.addToast(new Toast(errorManager.get(devisAddSuccess), 'success'));
                        props.handleClose();
                    } else {
                        updateError(Result.getError(res));
                    }
                });
            } else {
                updateLoad(false);
                updateError(clientNotSelect);
            }
        }
    };

    let title = 'Ajouter un élément';
    let done = 'Ajouter';
    if (mode === 'action') {
        if (refElem === null) {
            title = 'Ajouter une action';
            done = 'Ajouter';
        } else {
            title = 'Modifier une action';
            done = 'Modifier';
        }
    } else if (mode === 'devis') {
        if (refElem === null) {
            title = 'Ajouter un prix de vente';
            done = 'Ajouter';
        } else {
            title = 'Modifier un prix de vente';
            done = 'Modifier';
        }
    } else if (mode === 'client') {
        if (refElem !== null) {
            title = 'Modifier un client';
            done = 'Modifier';
        } else {
            title = 'Ajouter un client';
            done = 'Ajouter';
        }
    }

    let namePlaceholder = '';
    if (func === 2) {
        namePlaceholder = "Nom de l'action";
    } else if (func === 0) {
        namePlaceholder = 'Nom du client';
    } else {
        namePlaceholder = 'Nom du prix de vente';
    }

    if (mode === 'devis' && refElem !== null) {
        return (
            <MpModal title={title} onClose={handleClose} open={show} done={done} onSubmit={() => {}}>
                <p>Vous ne pouvez pas modifier un prix de vente ici, rendez-vous sur sa page pour le modifier.</p>
            </MpModal>
        );
    } else {
        /*
        
                loading={loading}
                error={error}
                errorManager={errorManager}
         */
        return (
            <MpModal title={title} overflow={false} onClose={handleClose} open={show} done={done} onSubmit={onAdd}>
                {loading ? (
                    <Box className="flex items-center justify-center p-8">
                        <Spinner color="blue" />
                    </Box>
                ) : (
                    <Box className="flex flex-col gap-3">
                        <ComplexInput
                            placeholder={namePlaceholder}
                            name="name_paper"
                            value={name}
                            setValue={updateName}
                            typeValue="string"
                        />
                        {mode == 'both' && (
                            <div className="grid grid-cols-2 gap-2">
                                <Button
                                    border={2}
                                    outline={paperPestation.func.get() !== func}
                                    pb={1.5}
                                    pt={1.5}
                                    pr={2.5}
                                    pl={2.5}
                                    gap={1}
                                    color={'red'}
                                    onClick={() => select(paperPestation)}
                                >
                                    <span>{paperPestation.name.get()}</span>
                                </Button>
                                <Button
                                    border={2}
                                    outline={paperHonoraire.func.get() !== func}
                                    pb={1.5}
                                    pt={1.5}
                                    pr={2.5}
                                    pl={2.5}
                                    gap={1}
                                    color={'violet'}
                                    onClick={() => select(paperHonoraire)}
                                >
                                    <span>{paperHonoraire.name.get()}</span>
                                </Button>
                            </div>
                        )}
                        {mode == 'both_action' && (
                            <div className="grid grid-cols-2 gap-2">
                                <Button
                                    border={2}
                                    outline={paperHonoraire.func.get() !== func}
                                    pb={1.5}
                                    pt={1.5}
                                    pr={2.5}
                                    pl={2.5}
                                    gap={1}
                                    color={'violet'}
                                    onClick={() => select(paperHonoraire)}
                                >
                                    <span>{paperHonoraire.name.get()}</span>
                                </Button>
                                <Button
                                    border={2}
                                    outline={paperAction.func.get() !== func}
                                    pb={1.5}
                                    pt={1.5}
                                    pr={2.5}
                                    pl={2.5}
                                    gap={1}
                                    color={'purple'}
                                    onClick={() => select(paperAction)}
                                >
                                    <span>{paperAction.name.get()}</span>
                                </Button>
                            </div>
                        )}
                        {func === 1 && choiceClient && (
                            <ClientSelect
                                placeholder="Client"
                                elem={client ?? undefined}
                                onObjectSelect={(client) => {
                                    updateClient(client);
                                }}
                                canAdd={AppManager.hasPermission(Permission.MANAGE_CLIENT)}
                            />
                        )}
                        {func === 1 && (
                            <div className="flex flex-col gap-2">
                                <p>Choix des chefs de projet</p>
                                <UserList
                                    onCheck={(p) => {
                                        checkUser(p);
                                    }}
                                    checkList={userList}
                                    userList={stateUserList.get()!.getList()}
                                    checkView={true}
                                />
                            </div>
                        )}

                        {!error.equals(ErrorHTTP.NO_ERROR()) && (
                            <Typography
                                component={'p'}
                                className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                            >
                                {errorManager.get(error)}
                            </Typography>
                        )}
                    </Box>
                )}
            </MpModal>
        );
    }
};
