import { AJOObject, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { CategoryCol } from '../commun/col/CategoryCol';
import { ComCol } from '../commun/col/ComCol';
import { IconRow } from '../commun/col/IconRow';
import { LabelCol } from '../commun/col/LabelCol';
import { NameCol } from '../commun/col/NameCol';
import { NoteAverageCol } from '../commun/col/NoteAverageCol';
import { StatCol } from '../commun/col/StatCol';
import { Button } from '../commun/component/Button';
import { Color, Size } from '../commun/Constant';
import ErrorHTTP from '../commun/ErrorHTTP';
import { post } from '../commun/HTTP';
import Result from '../commun/Result';
import { Col } from '../commun/table/Col';
import Table, { TypeTable } from '../commun/table/Table';
import { TableView } from '../commun/table/TableView';
import Utils from '../commun/Utils';
import { errorManager, prestationAddSuccess, vendorAddSuccess } from '../modal/vendor/CategoryErrorManager';
import { StatTable } from '../modal/vendor/StatTable';
import Category from '../object/tag/Category';
import Label from '../object/tag/Label';
import Prestation from '../object/profile/Prestation';
import Vendor from '../object/profile/Vendor';
import Service from '../object/Service';
import { TableBudgetList } from './TableBudgetList';
import AppManager from '../commun/AppManager';
import Toast from '../commun/toast/Toast';
import Budget from '../object/Budget';

export interface ITablePrestationListProps {
    serviceList: Service[];
    loading: boolean;

    parent?: Table<Vendor>;
    parentObj?: Vendor | Prestation;

    sourceTable?: Table<Service>;

    size: Size;
    canAction?: boolean;
    rounded?: boolean;

    bg?: Color;

    bonusFilter?: (service: Service) => boolean;
    onTypeChange?: (type: TypeTable) => void;
}

export const TablePrestationList: React.FC<ITablePrestationListProps> = (props) => {
    const {
        serviceList,
        sourceTable,
        bonusFilter,
        canAction = false,
        parentObj = null,
        rounded = false,
        parent,
        bg,
        size,
        loading,
        onTypeChange = () => {},
    } = props;

    const [prestationName, setPrestationName] = useState('');
    const [prestationId, setPrestationId] = useState('');
    const [selectCategoryList, setSelectCategoryList] = useState(new Array<Category>());
    const [selectLabelList, setSelectLabelList] = useState(new Array<Label>());

    const makeTable = () => {
        const tableInit = new Table<Service>('prestation');

        tableInit.size = size;
        tableInit.errorManager = errorManager;
        tableInit.parent = parent as unknown as Table<AJOObject>;

        if (parentObj instanceof Vendor || parentObj == null) {
            const nameCol = new NameCol<Service>(
                tableInit,
                true,
                (service: Service) => {
                    return service.prestation.get()!;
                },
                'Prestation',
                parentObj,
                'Aucune prestation',
                setPrestationName,
                setPrestationId,
            );
            tableInit.addCol(nameCol);
        } else {
            const nameCol = new NameCol<Service>(
                tableInit,
                true,
                (service: Service) => {
                    return service.vendor.get()!;
                },
                'Prestation',
                parentObj,
                'Aucun fournisseur',
                setPrestationName,
                setPrestationId,
            );
            tableInit.addCol(nameCol);
        }

        if (parentObj instanceof Vendor || parentObj == null) {
            const categoryCol = new CategoryCol<Service>(
                tableInit,
                canAction,
                canAction,
                canAction,
                (service: Service) => service,
                'Catégorie',
                setSelectCategoryList,
                selectCategoryList,
                parentObj,
            );
            tableInit.addCol(categoryCol);
        } else {
            const categoryCol = new CategoryCol<Service>(
                tableInit,
                canAction,
                canAction,
                canAction,
                (service: Service) => parentObj,
                'Catégorie',
                setSelectCategoryList,
                selectCategoryList,
                parentObj,
            );
            tableInit.addCol(categoryCol);
        }

        const labelCol = new LabelCol<Service>(
            tableInit,
            canAction,
            canAction,
            canAction,
            (service: Service) => service,
            'Label',
            setSelectLabelList,
            selectLabelList,
            parentObj,
        );
        tableInit.addCol(labelCol);

        const noteCol = new NoteAverageCol<Service>(tableInit);
        tableInit.addCol(noteCol);

        const comCol = new ComCol<Service>(tableInit);
        tableInit.addCol(comCol);

        StatCol.addNbPresta(tableInit);
        StatCol.addQuantite(tableInit);
        StatCol.addPU(tableInit);
        StatCol.addStatCol(tableInit);

        const iconRow = new IconRow(tableInit);
        tableInit.addCol(iconRow);

        tableInit.nothingRender = () => {
            return (
                <div className="w-full p-24 text-center">
                    <p className="text-base">Aucune prestation trouvée.</p>
                </div>
            );
        };

        tableInit.subTable = true;
        tableInit.subTableRender = (elem: Service) => {
            let res = undefined;
            if (tableInit.isSelect(elem)) {
                elem.fetchClientListInfo();
                res = (
                    <TableBudgetList
                        parentObj={elem}
                        budgetList={elem.budgetList.getList() as Budget[]}
                        parent={tableInit}
                        size="sm"
                        bg="green"
                        loading={elem.serviceClientListLoading}
                    />
                );
            }
            return res;
        };

        return tableInit;
    };
    const [stateTable, setStateTable] = useState(new AJOState(makeTable()));
    stateTable.setUpdate(setStateTable);

    // GET TABLE FOR REST OF CODE INDEPENDENT OF STATE
    const table = stateTable.get()!;

    table.preventUpdate = true;

    table.colList.forEach((col: Col<Service>) => {
        if (col instanceof CategoryCol) {
            col.update(selectCategoryList, setSelectCategoryList);
        }
        if (col instanceof LabelCol) {
            col.update(selectLabelList, setSelectLabelList);
        }
        if (col instanceof NameCol) {
            col.update(setPrestationName, setPrestationId);
        }
    });

    if (sourceTable != undefined) {
        table.fit(sourceTable);
    }

    // Set State to table
    table.state = stateTable;

    // Set load to table
    table.loading = loading;

    // PARAM OF TABLE
    table.list = serviceList;
    table.bg = bg;

    const addPrestation = () => {
        if (parentObj != null) {
            table.errorTopRow = ErrorHTTP.NO_ERROR();
            table.loadTopRow = true;

            const prestation: any = {};
            let category: any = {};
            const label = new Array<any>();

            selectCategoryList.forEach((elem: Category) => {
                category = {
                    id: elem.getAjoIdentifier(),
                };
            });

            selectLabelList.forEach((elem: Label) => {
                label.push({
                    id: elem.getAjoIdentifier(),
                });
            });

            if (prestationId == '') {
                prestation['name'] = prestationName;
            } else {
                prestation['id'] = prestationId;
            }
            const service: any = {};
            service['category'] = category;
            service['label'] = label;
            service['date_now'] = Utils.getActuDateSend();
            if (parentObj.getAjoType().toLowerCase() === 'vendor') {
                service['prestation'] = prestation;
            } else {
                service['vendor'] = prestation;
            }
            post('/' + parentObj.getAjoType().toLowerCase() + '/update', {
                id: parentObj.getAjoIdentifier(),
                service: service,
            }).then((res: any) => {
                table.loadTopRow = false;
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, AppManager.updater(), true);
                    if (parentObj.getAjoType().toLowerCase() === 'prestation') {
                        AppManager.addToast(new Toast(errorManager.get(vendorAddSuccess), 'success'));
                    } else {
                        AppManager.addToast(new Toast(errorManager.get(prestationAddSuccess), 'success'));
                    }
                    table.showTopRow = false;
                    sourceTable?.fit(table);
                } else {
                    table.errorTopRow = Result.getError(res);
                }
            });
        }
    };
    table.bonusFilter = bonusFilter ?? (() => true);
    table.concludeTopTableRender = () => {
        return (
            <div className="flex justify-end p-3 gap-3">
                <Button
                    color="gray"
                    onClick={() => {
                        table.showTopRow = false;
                        sourceTable?.fit(table);
                    }}
                >
                    Annuler
                </Button>
                <Button
                    color="blue"
                    onClick={() => {
                        addPrestation();
                    }}
                >
                    Ajouter
                </Button>
            </div>
        );
    };

    table.preventUpdate = false;

    useEffect(() => {
        onTypeChange(table.type);
    }, [table.type]);

    return <TableView table={table} rounded={rounded}></TableView>;
};
