import { Box, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Component } from 'react';
import AppManager from '../../commun/AppManager';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import { JSXInternal } from 'preact/src/jsx';
import { errorManager } from '../vendor/CategoryErrorManager';
import MpModal from 'src/component/extends/MpModal';
import { Spinner } from 'src/commun/component/Spinner';

export interface IAddFerieProps {
    show: boolean;
    onClose: () => void;
}
export interface IAddFerieState {
    error: ErrorHTTP;
    loading: boolean;
    date: Date;
}

export class AddFerieModal extends Component<IAddFerieProps, IAddFerieState> {
    constructor(props: IAddFerieProps) {
        super(props);

        this.state = this.initState();
    }

    initState(): IAddFerieState {
        return {
            error: ErrorHTTP.NO_ERROR(),
            loading: false,
            date: new Date(),
        };
    }

    componentDidUpdate(prevProps: Readonly<IAddFerieProps>, prevState: Readonly<IAddFerieState>, snapshot?: any): void {
        if (this.props.show !== prevProps.show) {
            this.setState(this.initState());
        }
    }

    addFerie() {
        this.setState({
            loading: true,
            error: ErrorHTTP.NO_ERROR(),
        });
        const { date } = this.state;
        post('/timer/ferie', {
            date: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
        }).then((res: any) => {
            this.setState({
                loading: false,
            });
            if (Result.isSuccess(res)) {
                this.props.onClose();
                Result.applyResult(res, AppManager.updater(), true);
                // Make toast
                AppManager.addToast(new Toast('Jour non travaillé ajouté', 'success'));
            } else {
                this.setState({
                    error: Result.getError(res),
                });
            }
        });
    }

    render(): JSXInternal.Element {
        const { show, onClose } = this.props;
        const { loading, error } = this.state;
        return (
            <MpModal
                open={show}
                onSubmit={() => {
                    this.addFerie();
                }}
                done="Ajouter"
                title="Ajouter un jour non travaillé"
                onClose={onClose}
            >
                {loading ? (
                    <Box className="p-10 flex items-center justify-center">
                        <Spinner color="blue" />
                    </Box>
                ) : (
                    <Box className="flex flex-col gap-3">
                        <DatePicker
                            className="flex-1"
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params: any) => {
                                return <TextField {...params} />;
                            }}
                            onChange={(e: any) => {
                                if (e !== null) {
                                    this.setState({
                                        date: (e as any).toDate(),
                                    });
                                } else {
                                    this.setState({
                                        date: new Date(),
                                    });
                                }
                            }}
                            value={this.state.date}
                        />
                        {!error.equals(ErrorHTTP.NO_ERROR()) && (
                            <Typography
                                component={'p'}
                                className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                            >
                                {errorManager.get(error)}
                            </Typography>
                        )}
                    </Box>
                )}
            </MpModal>
        );
    }
}
