import { AJOList, AJOUpdater } from 'mp-js-react-auto-json-object';
import { JSXInternal } from 'preact/src/jsx';
import { get } from '../../commun/HTTP';
import Result from '../../commun/Result';
import { AddressModal } from '../../modal/addess/AddressModal';
import { CategoryEventModal } from '../../modal/category/CategoryEventModal';
import Address from '../../object/Address';
import Permission from '../../object/Permission';
import CategoryEvent from '../../object/tag/CategoryEvent';
import { IObjectSelectProps, IObjectSelectState, ObjectSelect } from './ObjectSelect';

export interface ICategoryEventSelectProps extends IObjectSelectProps<CategoryEvent> {
    placeholder: string;
    categoryEventAjoList?: AJOList<CategoryEvent>;
}

export class CategoryEventSelect extends ObjectSelect<CategoryEvent, ICategoryEventSelectProps> {
    createGenericObject() {
        return CategoryEvent;
    }
    constructor(props: ICategoryEventSelectProps) {
        /**
         * DEFAULT PROPS
         */

        super(props, Permission.MANAGE_CATEGORY, 'category_event');

        /**
         * CREATE STATE
         */
        this.state = this.initState();

        // FUNCTION USE TO NOTIFY PARENT COMPONENT
        this.state.ajoList.setUpdate(() => this.forceUpdate());
    }

    fetchList() {
        if (this.state.isAjoListInflate) {
            get('/category_event/list').then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, this.state.ajoList, true);
                }
                this.setState({
                    isAjoListInflate: true,
                });
            });
        }
    }

    renderModal(): JSXInternal.Element {
        const { categoryEventAjoList } = this.props;
        const { elem } = this.state;
        let obj = this;
        return (
            <CategoryEventModal
                elem={elem ?? undefined}
                action={elem == null ? 'add' : 'edit'}
                onObjectChange={(id) => {
                    obj.onElemChange(id);
                }}
                show={this.state.showModal}
                onClose={() => this.setState({ showModal: false })}
            />
        );
    }

    render(): JSXInternal.Element {
        return super.render();
    }
}
