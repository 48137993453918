import { Masonry } from '@mui/lab';
import { Box, Typography, Container, Card } from '@mui/material';

import { useState } from 'preact/hooks';
import AppManager from 'src/commun/AppManager';
import { EuroSpan } from 'src/commun/component/EuroSpan';
import { PourcentSpan } from 'src/commun/component/PourcentSpan';
import { Spinner } from 'src/commun/component/Spinner';
import Result from 'src/commun/Result';
import { CloseDevisModal } from 'src/modal/CloseDevisModal';
import { UserSelectModal } from 'src/modal/user/SelectUserModal';
import Devis from 'src/object/Devis';
import User from 'src/object/User';
import { BasePricePage } from 'src/page/BasePricePage';
import { AddIcon } from '../../icons/AddIcon';
import BasePrice from '../../object/BasePrice';
import MpModal from '../extends/MpModal';
import { UserList } from '../UserList';
import { BasePriceProfilView } from './BasePriceProfilView';
import { post } from 'src/commun/HTTP';
import { SERVER_URL } from 'src/utils/Constant';
import { Button } from 'src/commun/component/Button';

export const DevisGeneralView = (props: { basePrice: BasePrice }) => {
    const { basePrice } = props;
    const [openAddUser, setOpenAddUser] = useState(false);
    if (basePrice instanceof Devis && basePrice.getAjoIdentifier() != undefined) {
        basePrice.fetchHonoraire();
    }

    const [openDelete, setOpenDelete] = useState(false);

    return (
        <Container maxWidth={false}>
            <Box sx={{ mb: 3, mt: 3 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 3,
                        m: -1,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        {basePrice.name.get() ?? ''}
                    </Typography>
                    {basePrice instanceof Devis && basePrice.close.get() === undefined && basePrice.canAct() && (
                        <Box sx={{ m: 1 }}>
                            <Button
                                color="red"
                                className="!pl-3 gap-2"
                                onClick={() => {
                                    setOpenDelete(true);
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                Fermer le prix de vente
                            </Button>
                            <CloseDevisModal
                                show={openDelete}
                                handleClose={() => {
                                    setOpenDelete(false);
                                }}
                                devis={basePrice}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
            <Masonry columns={2} spacing={3}>
                {basePrice instanceof Devis && (
                    <Card>
                        <Box
                            sx={{
                                p: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1.5,
                            }}
                        >
                            <Typography variant="h5">Information</Typography>
                            <BasePriceProfilView basePrice={basePrice} />
                        </Box>
                    </Card>
                )}
                <Card>
                    <Box
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1.5,
                        }}
                    >
                        <Typography variant="h5">Budget</Typography>
                        <div className="flex flex-col gap-2">
                            {basePrice instanceof Devis && (
                                <div className="flex  gap-2 flex-row">
                                    <span>Chiffre d'affaires par personne</span>
                                    <span className="flex-1 text-right">
                                        <EuroSpan value={basePrice.prixVenteHT() / basePrice.nb_personne.get()} />
                                    </span>
                                </div>
                            )}
                            {
                                <div className="flex  gap-2 flex-row">
                                    <span>Achat Total sans option</span>
                                    <span className="flex-1 text-right">
                                        <EuroSpan value={basePrice.achatNoOption()} />
                                    </span>
                                </div>
                            }
                            {
                                <div className="flex  gap-2 flex-row">
                                    <span>Honoraire sans option</span>
                                    <span className="flex-1 text-right">
                                        <EuroSpan value={basePrice.honoraireNoOption()} />
                                    </span>
                                </div>
                            }

                            <div className="flex flex-row  gap-2 text-lg font-semibold ">
                                <span>Chiffre d'affaires sans option</span>
                                <span className="flex-1 text-right">
                                    {basePrice.isGoodVa() ? (
                                        <span className={'text-green-700'}>
                                            <EuroSpan value={basePrice.prixVenteHTNoOption()} />
                                        </span>
                                    ) : (
                                        <span className={'text-red-700'}>
                                            <EuroSpan value={basePrice.prixVenteHTNoOption()} />
                                        </span>
                                    )}
                                </span>
                            </div>

                            <div className="flex font-medium flex-row">
                                <span>Marge brute sans option</span>
                                <span className="flex-1 text-right">
                                    {basePrice.isGoodVa() ? (
                                        <span className={'text-green-700'}>
                                            <EuroSpan value={basePrice.margeNoOption()} />
                                        </span>
                                    ) : (
                                        <span className={'text-red-700'}>
                                            <EuroSpan value={basePrice.margeNoOption()} />
                                        </span>
                                    )}
                                </span>
                            </div>

                            <div className="flex flex-row text-lg font-semibold">
                                <span>Taux MB sans option</span>
                                <span className="flex-1 text-right">
                                    {basePrice.isGoodVa() ? (
                                        <span className={'text-green-700'}>
                                            <PourcentSpan value={basePrice.vaNoOption()} />
                                        </span>
                                    ) : (
                                        <span className={'text-red-700'}>
                                            <PourcentSpan value={basePrice.vaNoOption()} />
                                        </span>
                                    )}
                                </span>
                            </div>

                            {
                                <div className="flex  gap-2 flex-row">
                                    <span>Achat Total avec option</span>
                                    <span className="flex-1 text-right">
                                        <EuroSpan value={basePrice.achat()} />
                                    </span>
                                </div>
                            }

                            {
                                <div className="flex  gap-2 flex-row">
                                    <span>Honoraire avec option</span>
                                    <span className="flex-1 text-right">
                                        <EuroSpan value={basePrice.honoraire()} />
                                    </span>
                                </div>
                            }

                            <div className="flex flex-row  gap-2 text-lg font-semibold">
                                <span>Chiffre d'affaires avec option</span>
                                <span className="flex-1 text-right">
                                    {basePrice.isGoodVa() ? (
                                        <span className={'text-green-700'}>
                                            <EuroSpan value={basePrice.prixVenteHT()} />
                                        </span>
                                    ) : (
                                        <span className={'text-red-700'}>
                                            <EuroSpan value={basePrice.prixVenteHT()} />
                                        </span>
                                    )}
                                </span>
                            </div>

                            <div className="flex font-medium flex-row">
                                <span>Marge brute avec option</span>
                                <span className="flex-1 text-right">
                                    {basePrice.isGoodVa() ? (
                                        <span className={'text-green-700'}>
                                            <EuroSpan value={basePrice.marge()} />
                                        </span>
                                    ) : (
                                        <span className={'text-red-700'}>
                                            <EuroSpan value={basePrice.marge()} />
                                        </span>
                                    )}
                                </span>
                            </div>

                            <div className="flex flex-row text-lg font-semibold">
                                <span>Taux MB avec option</span>
                                <span className="flex-1 text-right">
                                    {basePrice.isGoodVa() ? (
                                        <span className={'text-green-700'}>
                                            <PourcentSpan value={basePrice.va()} />
                                        </span>
                                    ) : (
                                        <span className={'text-red-700'}>
                                            <PourcentSpan value={basePrice.va()} />
                                        </span>
                                    )}
                                </span>
                            </div>

                            {!basePrice.isGoodVa() && basePrice.getProductList().length > 0 && (
                                <span className={'text-lg font-medium text-red-700'}>{basePrice.explainVa()}</span>
                            )}
                        </div>
                    </Box>
                </Card>
                {
                    <Card>
                        <Box
                            sx={{
                                p: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1.5,
                            }}
                        >
                            <div className={'flex flex-row justify-between'}>
                                <Typography variant="h5">Honoraire</Typography>
                                <Button
                                    onClick={() => {
                                        post('/budget/heure_file', {
                                            id: basePrice.getAjoIdentifier(),
                                        }).then((res: any) => {
                                            if (Result.isSuccess(res)) {
                                                const link = document.createElement('a');
                                                link.href = SERVER_URL + '/' + res['result']['where'];
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                            }
                                        });
                                    }}
                                    color="blue"
                                >
                                    Exporter
                                </Button>
                            </div>
                            {basePrice.getAjoIdentifier() === undefined ||
                            (basePrice instanceof Devis && basePrice.loadingHonoraire) ? (
                                <div className={'flex items-center justify-center p-20'}>
                                    <Spinner className={'h-10 w-10'} color="blue" />
                                </div>
                            ) : (
                                <div className="flex text-base font-normal flex-col gap-2">
                                    <div className="flex flex-row">
                                        <p>Minutes estimées</p>
                                        <span className="flex-1 text-right">
                                            <p>{basePrice.minute().toFixed(2)} m</p>
                                        </span>
                                    </div>
                                    <div className="flex flex-row">
                                        <p>Jours estimés</p>
                                        <span className="flex-1 text-right">
                                            <p>{basePrice.jour().toFixed(2)} j</p>
                                        </span>
                                    </div>
                                    {basePrice instanceof Devis && (
                                        <div className="flex flex-row">
                                            <p>Minutes engagées</p>
                                            <span className="flex-1 text-right">
                                                <p>{basePrice.newMinuteTimer().toFixed(2)} m</p>
                                            </span>
                                        </div>
                                    )}
                                    {basePrice instanceof Devis && (
                                        <div className="flex flex-row">
                                            <p>Jours engagés</p>
                                            <span className="flex-1 text-right">
                                                <p>{basePrice.newJourTimer().toFixed(2)} j</p>
                                            </span>
                                        </div>
                                    )}
                                    {basePrice instanceof Devis && (
                                        <div className="flex flex-row">
                                            <p>Minutes disponibles</p>
                                            <span className="flex-1 text-right">
                                                <p className={'' + (basePrice.minuteEcart() < 0 && 'text-red-700')}>
                                                    {basePrice.minuteEcart().toFixed(2)} m
                                                </p>
                                            </span>
                                        </div>
                                    )}
                                    {basePrice instanceof Devis && (
                                        <div className="flex flex-row">
                                            <p>Jours disponibles</p>
                                            <span className="flex-1 text-right">
                                                <p className={'' + (basePrice.jourEcart() < 0 && 'text-red-700')}>
                                                    {basePrice.jourEcart().toFixed(2)} j
                                                </p>
                                            </span>
                                        </div>
                                    )}
                                    {basePrice instanceof Devis && (
                                        <div className="flex flex-row text-lg font-semibold">
                                            <span>Écart</span>
                                            <span className="flex-1 text-right">
                                                {basePrice.ecart() >= 0 ? (
                                                    <span className={'text-green-700'}>
                                                        +<EuroSpan value={basePrice.ecart()} />
                                                    </span>
                                                ) : (
                                                    <span className={'text-red-700'}>
                                                        <EuroSpan value={basePrice.ecart()} />
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    )}
                                    {basePrice instanceof Devis && (
                                        <div className="flex flex-row">
                                            <p>Taux MB Estimé</p>
                                            <span className="flex-1 text-right">
                                                {basePrice.mbHEstime().toFixed(2)} €
                                            </span>
                                        </div>
                                    )}
                                    {basePrice instanceof Devis && (
                                        <div className="flex flex-row">
                                            <p>Taux MB Réel</p>
                                            <span className="flex-1 text-right">
                                                <p className={'' + (basePrice.jourEcart() < 0 && 'text-red-700')}>
                                                    {basePrice.mbHReel().toFixed(2)} €
                                                </p>
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )}
                        </Box>
                    </Card>
                }
                {basePrice instanceof Devis && (
                    <Card>
                        <Box
                            sx={{
                                p: 3,
                                gap: 3,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                }}
                            >
                                <Typography className="flex-1" variant="h5">
                                    Chef de projet
                                </Typography>
                                {basePrice.canAct() && (
                                    <Box>
                                        <Button
                                            color="blue"
                                            className="!pl-3 gap-2"
                                            onClick={() => {
                                                setOpenAddUser(true);
                                            }}
                                        >
                                            <AddIcon />
                                            Ajouter un chef de projet
                                        </Button>
                                        <div className="absolute">
                                            <UserSelectModal
                                                parent={basePrice}
                                                show={openAddUser}
                                                onClose={() => setOpenAddUser(false)}
                                            />
                                        </div>
                                    </Box>
                                )}
                            </Box>
                            <UserList
                                parent={basePrice}
                                canAdd={false}
                                canDelete={basePrice.canAct()}
                                canDeleteCondi={(user: User) => {
                                    return (
                                        user.getAjoIdentifier() !== AppManager.getUser()!.getAjoIdentifier() &&
                                        (user.root.get() !== true ||
                                            (user.root.get() === true &&
                                                (AppManager.getUser() as any)?.root.get() === true))
                                    );
                                }}
                                userList={basePrice.userList.getList()}
                                checkView={false}
                            />
                        </Box>
                    </Card>
                )}
            </Masonry>
        </Container>
    );
};
