import { AJOObject } from 'mp-js-react-auto-json-object';
import { Link } from 'react-router-dom';
import { AccordeonTable } from '../../component/AccordeonTable';
import { DoubleDateModal } from '../../modal/vendor/DoubleDateModal';
import { Comptable } from '../../object/Comptable';
import { Paper } from '../../object/Paper';
import { EuroSpan } from '../component/EuroSpan';
import { PourcentSpan } from '../component/PourcentSpan';
import { Col } from '../table/Col';
import Table from '../table/Table';

export class VACol<Type extends AJOObject> extends Col<Type> {
    constructor(table: Table<Type>, get: (elem: Type) => AJOObject = (elem) => elem, name: string = 'VA') {
        super(name);

        this.sumRender = (list: Type[]) => {
            let res = 0;

            let prixVente = 0;
            let achat = 0;
            for (let elem of list) {
                if ('Comptable' in get(elem)) {
                    let inter = get(elem) as unknown as Comptable;
                    prixVente += inter.prixVenteHT();
                    achat += inter.achat();
                }
            }

            res = (prixVente / achat - 1) * 100;
            if (!isFinite(res)) {
                res = 0;
            }
            return <PourcentSpan value={Math.abs(res)} />;
        };

        this.render = (elem: Type) => {
            if ('Comptable' in get(elem)) {
                let inter = get(elem) as unknown as Comptable;
                let res;
                res = <PourcentSpan value={inter.va()} />;
                return res;
            } else {
                throw new Error('Comptable not found in elem');
            }
        };
        this.sort = (a: Type, b: Type) => {
            if ('Comptable' in get(a) && 'Comptable' in get(b)) {
                let aInter = get(a) as unknown as Comptable;
                let bInter = get(b) as unknown as Comptable;
                if (isNaN(bInter.va())) {
                    return -1;
                } else if (isNaN(aInter.va())) {
                    return 1;
                } else {
                    return bInter.va() - aInter.va();
                }
            } else {
                throw new Error('Comptable not found in a or b');
            }
        };
        this.type = 'sort';
        this.typeRender = 'stat';
        this.senseSort = 0;
        this.table = table;
    }
}
