import { SERVER_URL } from '../utils/Constant';

class MpPromise {
    thenCb: (data: any) => void;
    catchCb: (data: any) => void;
    constructor() {
        this.thenCb = (data: any) => {};
        this.catchCb = (data: any) => {};
    }
    then(callback: (data: any) => void) {
        this.thenCb = callback;
        return this;
    }
    catch(callback: (data: any) => void) {
        this.catchCb = callback;
        return this;
    }
}
export function get(
    action: string,
    params: { [key: string]: any } = {},
    between: () => void = () => {},
    skipRestore?: boolean,
): MpPromise {
    Object.keys(localStorage).forEach((key) => {
        if (key !== 'accessToken' && key !== 'refreshToken' && key !== 'access_token' && key !== 'refresh_token') {
            localStorage.removeItem(key);
        }
    });

    if (skipRestore === undefined) {
        skipRestore = false;
    }

    const mpPromise = new MpPromise();

    let paramStr = '';

    let i = 0;
    for (let key in params) {
        if (i == 0) {
            paramStr += '?';
        } else {
            paramStr += '&';
        }
        paramStr += key + '=' + params[key];
        i++;
    }

    let res = null;
    try {
        const finalUrl = SERVER_URL + action + paramStr;
        /*if (!skipRestore) {
            const oldResponse = localStorage.getItem(finalUrl);
            if (oldResponse != null) {
                setTimeout(() => {
                    try {
                        between();
                        mpPromise.thenCb(JSON.parse(oldResponse));
                    } catch (e) {}
                });
            }
        }*/

        fetch(finalUrl, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => {
                if (!response.ok) {
                    res = {
                        status: 'failed',
                        error: 'went_wrong',
                    };
                    mpPromise.thenCb(res);
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                // between();
                // localStorage.setItem(finalUrl, JSON.stringify(response));
                mpPromise.thenCb(response);
            })
            .catch((error) => {
                res = {
                    status: 'failed',
                    error: 'went_wrong',
                };
                mpPromise.catchCb(res);
            });
    } catch (error) {
        mpPromise.thenCb(res);
    }
    return mpPromise;
}

export async function post(action: string, params: { [key: string]: any } = {}) {
    let res = null;
    try {
        const response = await fetch(SERVER_URL + action, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(params),
            credentials: 'include',
        });
        if (!response.ok) {
            res = {
                status: 'failed',
                error: 'went_wrong',
            };
        } else {
            res = await response.json();
        }
    } catch (error) {
        res = {
            status: 'failed',
            error: 'went_wrong',
        };
    }
    return res;
}

export async function postFile(action: string, fromData: FormData) {
    let res = null;
    try {
        const response = await fetch(SERVER_URL + action, {
            method: 'POST',
            body: fromData,
            credentials: 'include',
        });
        if (!response.ok) {
            res = {
                status: 'failed',
                error: 'went_wrong',
            };
        } else {
            res = await response.json();
        }
    } catch (error) {
        res = {
            status: 'failed',
            error: 'went_wrong',
        };
    }
    return res;
}
