import { AJOObject } from 'mp-js-react-auto-json-object';
import { Link } from 'react-router-dom';
import { AccordeonTable } from '../../component/AccordeonTable';
import { DoubleDateModal } from '../../modal/vendor/DoubleDateModal';
import { Comptable } from '../../object/Comptable';
import { Marge } from '../../object/Marge';
import { MargeReduc } from '../../object/marge_reduc/MargeReduc';
import { EuroSpan } from '../component/EuroSpan';
import { Col } from '../table/Col';
import Table from '../table/Table';

export class PrixVenteTTCCol<Type extends AJOObject> extends Col<Type> {
    constructor(table: Table<Type>, get: (elem: Type) => AJOObject = (elem) => elem, name: string = 'PV TTC') {
        super(name);

        this.sumRender = (list: Type[]) => {
            let sum = 0;
            for (let elem of list) {
                if ('Comptable' in get(elem)) {
                    let inter = get(elem) as unknown as Comptable;
                    sum += inter.prixVenteTTC();
                } else if (get(elem) instanceof MargeReduc) {
                } else {
                    throw new Error('Comptable not found in elem');
                }
            }
            return <EuroSpan value={sum} />;
        };

        this.render = (elem: Type) => {
            if ('Comptable' in get(elem)) {
                let inter = get(elem) as unknown as Comptable;
                let res;
                res = <EuroSpan value={inter.prixVenteTTC()} />;
                return res;
            } else {
                throw new Error('Comptable not found in elem');
            }
        };
        this.sort = (a: Type, b: Type) => {
            if ('Comptable' in get(a) && 'Comptable' in get(b)) {
                let aInter = get(a) as unknown as Comptable;
                let bInter = get(b) as unknown as Comptable;
                if (isNaN(bInter.prixVenteTTC())) {
                    return -1;
                } else if (isNaN(aInter.prixVenteTTC())) {
                    return 1;
                } else {
                    return bInter.prixVenteTTC() - aInter.prixVenteTTC();
                }
            } else {
                throw new Error('Comptable not found in a or b');
            }
        };
        this.type = 'sort';
        this.typeRender = 'stat';
        this.senseSort = 0;
        this.table = table;
    }
}
