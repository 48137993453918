import { IObjectSelectable } from '../component/select/ObjectSelect';
import { AJOObject, AJOProperties } from 'mp-js-react-auto-json-object';
import { TimerParent } from './TimerInterface';
import { Color } from '../commun/Constant';
export class Action extends TimerParent implements IObjectSelectable {
    public getColor(): Color {
        return 'lime';
    }

    public getTitle(): string {
        return 'Action';
    }

    public static _TYPE: string = 'Action';

    public laoding: boolean = false;

    public name: AJOProperties<any>;

    constructor() {
        super(Action._TYPE);

        this.laoding = false;
        this.name = new AJOProperties('name');
    }

    public getSelectTitle(): string {
        return this.name.get() ?? '';
    }
    public getName(): string {
        return this.name.get() ?? '';
    }
    public getSelectDescription(): string {
        return '';
    }

    public static build(): Action {
        return new Action();
    }
}
