import { AJOList, AJOObject, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import AppManager from '../../commun/AppManager';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { get, post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import { TagList } from '../../component/TagList';
import Category from '../../object/tag/Category';
import { JSXInternal } from 'preact/src/jsx';
import Label from '../../object/tag/Label';
import { errorManager, fusionCategorySuccess, fusionLabelSuccess } from './CategoryErrorManager';

export interface IFusionCategoryModalProps {
    refList: (Label | Category)[];
    show: boolean;

    field: string;
    handleClose: () => void;
}
export const FusionCategoryModal: React.FC<IFusionCategoryModalProps> = (props) => {
    const { refList, field, show, handleClose } = props;

    const [stateList, updateStateList] = useState(
        new AJOState<AJOList<Category>>(new AJOList<Category | Label>([Category, Label])),
    );
    stateList.setUpdate(updateStateList);

    const [loading, updateLoading] = useState(true);

    useEffect(() => {
        updateLoading(true);
        get('/' + field + '/list').then((res: any) => {
            updateLoading(false);
            Result.applyResult(res, stateList, true);
        });
    }, []);

    const [selectList, updateSelectList] = useState<Array<Category | Label>>([]);
    const [newElem, updateNewElem] = useState<Category | Label | null>(null);
    const [loadingSend, updateLoadingSend] = useState(false);

    const [error, updateError] = useState<ErrorHTTP>(ErrorHTTP.NO_ERROR());

    useEffect(() => {
        updateError(ErrorHTTP.NO_ERROR());
    }, [show]);

    const send = () => {
        updateLoadingSend(true);
        updateError(ErrorHTTP.NO_ERROR());

        let list_id = [];

        for (let elem of selectList) {
            list_id.push(elem.getAjoIdentifier());
        }

        post('/' + field + '/update', {
            id_child_fussion: list_id,
            id_parent_fussion: newElem?.getAjoIdentifier() ?? '',
        }).then((res: any) => {
            updateLoadingSend(false);
            if (Result.isSuccess(res)) {
                Result.applyResult(res, AppManager.updater(), true);
                if (field === 'label') {
                    updateError(fusionLabelSuccess);

                    AppManager.addToast(new Toast(errorManager.get(fusionLabelSuccess), 'success'));
                    props.handleClose();
                } else {
                    updateError(fusionCategorySuccess);

                    AppManager.addToast(new Toast(errorManager.get(fusionCategorySuccess), 'success'));
                    props.handleClose();
                }
            } else {
                updateError(Result.getError(res));
            }
        });
    };
    const selectElem = (elem: Category | Label) => {
        if (selectList.find((e) => e.getAjoIdentifier() === elem.getAjoIdentifier()) === undefined) {
            updateSelectList([...selectList, elem]);
        } else {
            updateSelectList(selectList.filter((e) => e.getAjoIdentifier() !== elem.getAjoIdentifier()));
        }
    };
    const selectNewElem = (elem: Category | Label) => {
        updateNewElem(elem);
    };

    return (
        <Modal
            title={field === 'category' ? 'Fusionner des catégories' : 'Fussionner des labels'}
            show={show}
            error={error}
            onDone={() => {
                send();
            }}
            loading={loadingSend}
            errorManager={errorManager}
            done={'Fusionner'}
            handleClose={handleClose}
            bottomMode="action"
        >
            <p className="text-black dark:text-white">
                {field === 'category' ? 'Anciennes catégories' : 'Anciens labels'}
            </p>
            <TagList
                checkMode={true}
                selectList={selectList}
                onCheck={selectElem}
                field={field}
                loading={loading}
                list={stateList.get()!.getList()}
            />

            <p className="text-black dark:text-white">
                {field === 'category' ? 'Nouvelle catégorie' : 'Nouveau label'}
            </p>
            <TagList
                checkMode={true}
                selectList={newElem !== null ? [newElem] : []}
                onCheck={selectNewElem}
                field={field}
                loading={loading}
                list={stateList.get()!.getList()}
            />
        </Modal>
    );
};
