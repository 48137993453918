import { Card, Typography } from '@mui/material';
import { AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import { Button } from '../commun/component/Button';
import { AddressModal } from '../modal/vendor/AddressModal';
import { addressEditSuccess, contactEditSuccess } from '../modal/vendor/CategoryErrorManager';
import Address from '../object/Address';
import { DeleteSvg } from './DeleteSvg';
import { EditSvg } from './EditSvg';

export interface IAddressViewProps {
    address: Address;
    onDelete?: () => void;
    canDelete: boolean;
    canEdit: boolean;
    parent?: AJOObject;
}

export const AddressView: React.FunctionComponent<IAddressViewProps> = (props) => {
    //onDelete
    const { address, parent, canEdit, canDelete, onDelete } = props;

    const [showModal, updateShowModal] = useState(false);

    if (address.getAjoIdentifier() === undefined) {
        return <Typography>Aucune adresse</Typography>;
    }
    return (
        <Card className="flex flex-row p-2 rounded-lg gap-2 w-full">
            <div className="flex flex-col justify-center flex-1">
                <span className="text-sm font-medium text-black dark:text-white">{address.name.get()}</span>
                <span className="text-sm text-gray-600 dark:text-gray-300">{address.fullAddress()}</span>
            </div>
            {canEdit && (
                <div className="flex justify-center items-center">
                    <Button
                        color="blue"
                        pb={1.5}
                        pt={1.5}
                        pr={1.5}
                        pl={1.5}
                        onClick={() => {
                            updateShowModal(true);
                        }}
                        className={'focus:ring-4 rounded-md text-black dark:text-white'}
                    >
                        <EditSvg className="h-5 w-5" />
                    </Button>
                </div>
            )}
            {canDelete && (
                <div className="flex justify-center items-center">
                    <Button
                        color="red"
                        pb={1.5}
                        pt={1.5}
                        pr={1.5}
                        pl={1.5}
                        onClick={onDelete}
                        className={'focus:ring-4 rounded-md text-black dark:text-white'}
                    >
                        <DeleteSvg className="h-5 w-5" />
                    </Button>
                    {canEdit && (
                        <AddressModal
                            successError={addressEditSuccess}
                            button="Modifier"
                            title="Modifier une adresse"
                            field="contact"
                            edit={true}
                            show={showModal}
                            address={address}
                            handleClose={() => updateShowModal(false)}
                            parent={parent!}
                        />
                    )}
                </div>
            )}
        </Card>
    );
    /*{canDelete&&<div className="flex justify-center items-center">
            <button onClick={onDelete} className={"focus:ring-4 rounded-md text-black dark:text-white focus:ring-red-400 dark:focus:ring-red-300"}><svg className="cursor-pointer h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg></button>
        </div>}*/
};
