import { AJOUpdater } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import { Button } from '../commun/component/Button';
import { EuroSpan } from '../commun/component/EuroSpan';
import Utils from '../commun/Utils';
import { MargeReducModal } from '../modal/marge/MargeModal';
import { delMargeSuccess, editMargeSuccess } from '../modal/vendor/CategoryErrorManager';
import { CategoryModal } from '../modal/vendor/CategoryModal';
import { RemoveCategoryModal } from '../modal/vendor/RemoveCategoryModal';
import { Marge } from '../object/Marge';
import { MargeReduc } from '../object/marge_reduc/MargeReduc';
import { Paper } from '../object/Paper';
import { DeleteSvg } from './DeleteSvg';
import { EditSvg } from './EditSvg';

export interface IMargeViewProps {
    marge: MargeReduc;

    paper: Paper;
    index: number;
}
export const MargeReducView = (props: IMargeViewProps) => {
    const { marge, paper, index } = props;

    const [showEdit, setShowEdit] = useState(false);
    const [showDel, setShowDel] = useState(false);

    let canEdit = paper.canAct();

    return (
        <div className={'w-100 gap-3 items-center flex flex-row '}>
            {canEdit && (
                <Button
                    pt={1}
                    pl={1}
                    pb={1}
                    pr={1}
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowDel(true);
                    }}
                    color={'red'}
                >
                    <DeleteSvg className="h-5 w-5" />
                </Button>
            )}
            {canEdit && (
                <Button
                    pt={1}
                    pl={1}
                    pb={1}
                    pr={1}
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowEdit(true);
                    }}
                    color={'blue'}
                >
                    <EditSvg className="h-5 w-5" />
                </Button>
            )}
            {marge.func.get() === 0 ? (
                <span className=" text-left">
                    {marge.name.get() +
                        ' - ' +
                        (marge.func.get() === 0 ? marge.value.get() + ' %' : marge.value.get() + ' €')}
                </span>
            ) : (
                <span className=" text-left">{marge.name.get()}</span>
            )}
            <span className="flex-1 flex justify-end">
                <EuroSpan value={Math.abs(marge.prixVenteHT())} />
            </span>
            <div className="absolute" onClick={Utils.stopPropagation()}>
                <MargeReducModal
                    marge={marge}
                    onMargeReducChange={() => {}}
                    action="edit"
                    type={marge instanceof Marge ? 'marge' : 'reduc'}
                    paper={paper}
                    show={showEdit}
                    onClose={() => {
                        setShowEdit(false);
                    }}
                />
                <RemoveCategoryModal
                    elem={marge}
                    field={marge instanceof Marge ? 'marge' : 'reduc'}
                    parent={paper}
                    successError={delMargeSuccess}
                    title={marge instanceof Marge ? 'Supprimer la marge' : 'Supprimer la remise exceptionnelle'}
                    textDelete={
                        marge instanceof Marge
                            ? 'Êtes-vous sûr de vouloir supprimer cette marge.'
                            : 'Êtes-vous sûr de vouloir supprimer cette remise exceptionnelle.'
                    }
                    show={showDel}
                    handleClose={() => {
                        setShowDel(false);
                    }}
                />
            </div>
        </div>
    );
};
