type Position = 'right' | 'left';

export default class PageObject {
    private path: string;
    private buttonList: string[];
    private name: string;
    private from: string;
    private to: string;
    private ring: string;
    private select: boolean;
    private pos: Position;

    constructor(
        path: string,
        buttonList: string[],
        name: string,
        from: string,
        to: string,
        ring: string,
        select: boolean,
        pos: Position,
    ) {
        this.path = path;
        this.buttonList = buttonList;
        this.name = name;
        this.from = from;
        this.to = to;
        this.ring = ring;
        this.select = select;
        this.pos = pos;
    }

    public getPath(): string {
        return this.path;
    }

    public setPath(path: string): void {
        this.path = path;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getFrom(): string {
        return this.from;
    }

    public setFrom(from: string): void {
        this.from = from;
    }

    public getTo(): string {
        return this.to;
    }

    public setTo(to: string): void {
        this.to = to;
    }

    public getRing(): string {
        return this.ring;
    }

    public setRing(ring: string): void {
        this.ring = ring;
    }

    public isSelect(path: string): boolean {
        // check if any button is a part of the path
        for (let button of this.buttonList) {
            if ((button.length == 1 && path === button) || (button.length != 1 && path.startsWith(button))) {
                return true;
            }
        }
        return false;
    }

    public setSelect(select: boolean): void {
        this.select = select;
    }

    public getPos(): Position {
        return this.pos;
    }

    public setPos(pos: Position): void {
        this.pos = pos;
    }
}
