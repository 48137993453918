import { AJOObject, AJOProperties } from 'mp-js-react-auto-json-object';
import { TagObject } from './TagObject';

export default class Category extends TagObject {
    public static _TYPE: string = 'Category';

    constructor() {
        super(Category._TYPE);
    }

    public static build(): Category {
        return new Category();
    }
}
