import { Box, Typography } from '@mui/material';
import { AJOList, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import React from 'react';
import AppManager from '../commun/AppManager';
import { Button } from '../commun/component/Button';
import { Spinner } from '../commun/component/Spinner';
import ErrorHTTP from '../commun/ErrorHTTP';
import { post } from '../commun/HTTP';
import { Modal } from '../commun/modal/Modal';
import Result from '../commun/Result';
import Toast from '../commun/toast/Toast';
import { errorManager, sectionAddSuccess, sectionNotSelect } from '../modal/vendor/CategoryErrorManager';
import Devis from '../object/Devis';
import Client from '../object/profile/Client';
import { Section } from '../object/Section';
import { TimerParent } from '../object/TimerInterface';
import MpModal from './extends/MpModal';
import { HonoraireButton } from './HonoraireButton';
import { LittleAddButton } from './LittleAddButton';
import { SectionSelect } from './select/SectionSelect';
import { Separator } from './Separator';
import { TimerView } from './TimerView';

interface IHonoraireViewProps {
    value: TimerParent;
    stateTimerList: AJOState<AJOList<TimerParent>>;
}

interface IHonoraireViewState {}

export class HonoraireView extends React.Component<IHonoraireViewProps, IHonoraireViewState> {
    render() {
        const { value, stateTimerList } = this.props;

        const updater = new AJOUpdater().add(stateTimerList);

        return (
            <div
                className={
                    'break-inside-avoid-column rounded-lg w-full mb-3 border border-' +
                    value.getColorSpec() +
                    '-200 dark:border-' +
                    value.getColorSpec() +
                    '-500 p-3 bg-' +
                    value.getColorSpec() +
                    '-100 dark:bg-gray-800'
                }
            >
                <div className="flex flex-col gap-3 w-full">
                    <div className={'flex flex-row text-' + value.getColorSpec() + '-800 gap-3 items-center'}>
                        {value.getStartTimer(null) != null ? (
                            <div className="flex flex-col">
                                <p className={'text-lg text-' + value.getColorSpec() + '-900 font-semibold'}>
                                    {value.name.get()}
                                </p>
                                {value.getStartTimer(null) != null && (
                                    <TimerView canJour={false} date={value.getStartTimer(null)!} />
                                )}
                            </div>
                        ) : (
                            <p className={'text-lg text-' + value.getColorSpec() + '-900 font-semibold'}>
                                {value.name.get()}
                            </p>
                        )}

                        <div className="flex-1"></div>

                        <HonoraireButton updater={updater} value={value} section={null} />
                    </div>

                    {value.getSectionList().map((section, index) => {
                        return (
                            <div key={index} className={'flex  text-' + value.getColorSpec() + '-800 flex-col gap-3'}>
                                <Separator />
                                <div className="flex flex-row gap-3 items-center">
                                    {value.getStartTimer(section) != null ? (
                                        <div className="flex flex-col">
                                            <p className=" font-medium"> - {section.name.get()}</p>
                                            {value.getStartTimer(section) != null && (
                                                <TimerView date={value.getStartTimer(section)!} />
                                            )}
                                        </div>
                                    ) : (
                                        <p className=" font-medium"> - {section.name.get()}</p>
                                    )}

                                    <div className="flex-1"></div>

                                    <HonoraireButton
                                        updater={new AJOUpdater().add(stateTimerList)}
                                        value={value}
                                        section={section}
                                    />
                                </div>
                            </div>
                        );
                    })}

                    <div className={'flex flex-col  text-' + value.getColorSpec() + '-800 gap-3'}>
                        <Separator />
                        <div
                            className="flex flex-row gap-3 items-center"
                            onClick={() => {
                                value.setShowTimer(true);
                            }}
                        >
                            <p className="font-medium">Ajouter une section</p>
                            <div className="flex-1"></div>
                            <LittleAddButton text="" onAdd={() => {}} />
                        </div>
                    </div>

                    <div className="absolute">
                        {value.isShowTimer() && (
                            <MpModal
                                overflow={false}
                                onSubmit={() => {
                                    value.setErrorTimer(ErrorHTTP.NO_ERROR());
                                    if (value.getSectionTimer() != null) {
                                        value.setLoadingModalTimer(true);

                                        post('/timer/point', {
                                            id_object: value.getAjoIdentifier(),
                                            id_section: value.getSectionTimer()?.getAjoIdentifier() ?? '',
                                            name_section: '',
                                        }).then((res: any) => {
                                            value.setLoadingModalTimer(false);

                                            if (Result.isSuccess(res)) {
                                                Result.applyResult(res, updater, true);
                                                AppManager.addToast(
                                                    new Toast(errorManager.get(sectionAddSuccess), 'success'),
                                                );
                                                value.setShowTimer(false);
                                            } else {
                                                value.setErrorTimer(Result.getError(res));
                                            }
                                        });
                                    } else {
                                        value.setErrorTimer(sectionNotSelect);
                                    }
                                }}
                                done="Démarrer"
                                onClose={() => {
                                    value.setShowTimer(false);
                                }}
                                open={value.isShowTimer()}
                                title="Ajouter une section"
                            >
                                {value.isLoadingModalTimer() ? (
                                    <Box className="flex items-center justify-center p-10">
                                        <Spinner color="blue" />
                                    </Box>
                                ) : (
                                    <Box>
                                        <SectionSelect
                                            canAdd={true}
                                            placeholder="Section"
                                            elem={value.getSectionTimer() ?? undefined}
                                            onObjectSelect={(section) => {
                                                value.setSectionTimer(section);
                                            }}
                                        />
                                        {!value.getErrorTimer().equals(ErrorHTTP.NO_ERROR()) && (
                                            <Typography
                                                component={'p'}
                                                className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                                            >
                                                {errorManager.get(value.getErrorTimer())}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            </MpModal>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
