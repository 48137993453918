import { JSXInternal } from 'preact/src/jsx';
import { get } from '../../commun/HTTP';
import Result from '../../commun/Result';
import { AddressModal } from '../../modal/addess/AddressModal';
import { ProductModal } from '../../modal/product/ProductModal';
import Address from '../../object/Address';
import Permission from '../../object/Permission';
import CategoryEvent from '../../object/tag/CategoryEvent';
import Product from '../../object/tag/Product';
import { IObjectSelectProps, IObjectSelectState, ObjectSelect } from './ObjectSelect';

export interface IProductSelectProps extends IObjectSelectProps<Product> {
    placeholder: string;
}

export class ProductSelect extends ObjectSelect<Product, IProductSelectProps> {
    createGenericObject() {
        return Product;
    }
    constructor(props: IProductSelectProps) {
        /**
         * DEFAULT PROPS
         */

        super(props, Permission.MANAGE_PRODUCT, 'product');

        /**
         * CREATE STATE
         */
        this.state = this.initState();

        // FUNCTION USE TO NOTIFY PARENT COMPONENT
        this.state.ajoList.setUpdate(() => this.forceUpdate());
    }

    fetchList() {
        if (this.state.isAjoListInflate) {
            get('/product/list').then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, this.state.ajoList, true);
                }
                this.setState({
                    isAjoListInflate: true,
                });
            });
        }
    }

    renderModal(): JSXInternal.Element {
        const { ajoList } = this.props;
        const { elem } = this.state;
        let obj = this;
        return (
            <ProductModal
                elem={elem ?? undefined}
                action={elem == null ? 'add' : 'edit'}
                baseName={this.state.search}
                onObjectChange={(id) => {
                    obj.onElemChange(id);
                }}
                show={this.state.showModal}
                onClose={() => this.setState({ showModal: false })}
            />
        );
    }

    render(): JSXInternal.Element {
        return super.render();
    }
}
