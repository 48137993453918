import { AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import { CaretDownFill, CaretRightFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import AppManager from '../../commun/AppManager';
import { IconRow } from '../../commun/col/IconRow';
import { Button } from '../../commun/component/Button';
import { Spinner } from '../../commun/component/Spinner';
import { post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import { Col } from '../../commun/table/Col';
import Table from '../../commun/table/Table';
import { TableView } from '../../commun/table/TableView';
import Utils from '../../commun/Utils';
import User from '../../object/User';
import { SERVER_URL } from '../../utils/Constant';
import { DownloadSvg } from '../DownloadSvg';
import { EditSvg } from '../EditSvg';
import { TimerView } from '../TimerView';
import { TableHoraireActionDevisList } from './TableHoraireActionDevisList';
import { TableHoraireDevisStatList } from './TableHoraireDevisStatList';
import { TableHoraireSectionList } from './TableHoraireSectionList';
import { UserTimerModal } from './UserTimerModal';

interface IExportUserHorraireButtonProps {
    user: User;
    current: Date;
}

const ExportUserHorraireButton = (props: IExportUserHorraireButtonProps) => {
    const { user, current } = props;

    const [loading, setLoading] = useState(false);

    let res = undefined;
    if (loading) {
        res = <Spinner color="blue" />;
    } else {
        res = (
            <Button
                pb={1}
                pl={1}
                pr={1}
                pt={1}
                color="blue"
                onClick={() => {
                    setLoading(true);
                    post('/export/user', {
                        date: current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate(),
                        id_user: user.getAjoIdentifier(),
                    }).then((res: any) => {
                        setLoading(false);
                        if (Result.isSuccess(res)) {
                            const link = document.createElement('a');
                            link.href = SERVER_URL + '/' + res['result']['where'];
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    });
                }}
            >
                <DownloadSvg className="w-5 h-5" />
            </Button>
        );
    }
    return res;
};

interface IExportAllHorraireButtonProps {
    current: Date;
}

const ExportAllHorraireButton = (props: IExportAllHorraireButtonProps) => {
    const { current } = props;

    const [loading, setLoading] = useState(false);

    let res = undefined;
    if (loading) {
        res = <Spinner color="blue" />;
    } else {
        res = (
            <Button
                pb={1}
                pl={1}
                pr={1}
                pt={1}
                color="blue"
                onClick={() => {
                    setLoading(true);
                    post('/export/all', {
                        date: current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate(),
                    }).then((res: any) => {
                        setLoading(false);
                        if (Result.isSuccess(res)) {
                            const link = document.createElement('a');
                            link.href = SERVER_URL + '/' + res['result']['where'];
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    });
                }}
            >
                <DownloadSvg className="w-5 h-5" />
            </Button>
        );
    }
    return res;
};

interface ITableHoraireUserProps {
    list: User[];
    loading: boolean;
    current: Date;
}

export const TableHoraireUser: React.FC<ITableHoraireUserProps> = (props) => {
    const { list, loading, current } = props;

    const makeTable = () => {
        const table = new Table<User>('user');

        return table;
    };

    const [tableState, setTableState] = useState(new AJOState(makeTable()));
    tableState.setUpdate(setTableState);

    let table = tableState.get() as Table<User>;

    table.loading = loading;
    table.preventUpdate = true;

    table.list = list.filter((user) => {
        return user.getTimerFull(Utils.getMonday(current), Utils.getSunday(current)).getTime() > 0;
    });
    table.colList = [];

    let nomCol = new Col<User>('Nom');
    nomCol.render = (elem: User) => {
        return (
            <div className="flex flex-row gap-2 items-center">
                <div
                    className="flex items-center gap-1"
                    onClick={() => {
                        // Check if selectList of table contain there is elem
                        if (
                            table.selectList.find(
                                (e: AJOObject) => elem.getAjoIdentifier() === e.getAjoIdentifier(),
                            ) !== undefined
                        ) {
                            table.removeFromSelectList(elem);
                        } else {
                            table.addToSelectList(elem);
                        }
                        AppManager.updater().makeUpdate();
                    }}
                ></div>
                <Link to={'/calendar/' + elem.getAjoIdentifier()}>
                    <Button color="blue" pt={1.5} pb={1.5} pr={1.5} pl={1.5}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </Button>
                </Link>
                <p className="font-semibold text-black dark:text-white flex gap-1">{elem.getFullName()}</p>
                <Button
                    color="blue"
                    onClick={() => {
                        elem.show = true;
                        AppManager.updater().makeUpdate();
                    }}
                    pt={1.5}
                    pb={1.5}
                    pr={1.5}
                    pl={1.5}
                >
                    <EditSvg className="h-5 w-5" />
                </Button>
                <UserTimerModal
                    handleClose={() => {
                        elem.show = false;
                        AppManager.updater().makeUpdate();
                    }}
                    show={elem.show}
                    user={elem}
                />
            </div>
        );
    };
    table.addCol(nomCol);
    let contractCol = new Col<User>('Contrat');
    contractCol.render = (user: User) => {
        return (
            <TimerView
                date={new Date(0)}
                canJour={false}
                dateEnd={new Date(user.nb_heure.get() * 60 * 60 * 1000)}
            ></TimerView>
        );
    };
    table.addCol(contractCol);
    let totalCol = new Col<User>('Total');
    totalCol.render = (user: User) => {
        return (
            <TimerView
                date={new Date(0)}
                canJour={false}
                dateEnd={user.getTimerFull(Utils.getMonday(current), Utils.getSunday(current))}
            ></TimerView>
        );
    };
    table.addCol(totalCol);
    let recupCol = new Col<User>('Recup');
    recupCol.render = (user: User) => {
        if (user.recup.get() === undefined) {
            return <p>Chargement ...</p>;
        } else {
            return <TimerView date={new Date(0)} dateEnd={new Date(user.recup.get() * 1000)}></TimerView>;
        }
    };
    table.addCol(recupCol);

    let recupCommingCol = new Col<User>('Recup à venir');
    recupCol.render = (user: User) => {
        if (user.recup_comming.get() === undefined) {
            return <p>Chargement ...</p>;
        } else {
            return <TimerView date={new Date(0)} dateEnd={new Date(user.recup_comming.get() * 1000)}></TimerView>;
        }
    };
    table.addCol(recupCommingCol);

    let iconCol = new IconRow(table);
    table.addCol(iconCol);
    iconCol.render = (elem: User) => {
        return (
            <div className="flex items-end justify-end">
                <ExportUserHorraireButton current={current} user={elem} />
            </div>
        );
    };
    iconCol.superRender = () => {
        return (
            <div className="flex items-end justify-end">
                <ExportAllHorraireButton current={current} />
            </div>
        );
    };

    table.subTable = true;
    table.subTableRender = (elem: User) => {
        let res = undefined;
        if (table.isSelect(elem)) {
            //elem.fetchDevisListInfo();
            res = (
                <TableHoraireActionDevisList date={current} parent={elem} list={elem.timerList.getList()} size="sm" />
            );
        }
        return res;
    };
    table.nothingRender = () => {
        return (
            <div className="flex flex-col p-24 items-center justify-center">
                <p>Aucun collaborateur n'a travaillé cette semaine.</p>
            </div>
        );
    };
    table.preventUpdate = false;

    return <TableView rounded={true} table={table}></TableView>;
};
