import { AJOObject } from 'mp-js-react-auto-json-object';
import Table from '../table/Table';
import { AchatCol } from './AchatCol';
import { NbPrestaCol } from './NbPresta';
import { PrixVenteCol } from './PrixVenteCol';
import { PrixVenteTTCCol } from './PrixVenteTTCCol';
import { PUCol } from './PUCol';
import { QuantiteCol } from './QuantiteCol';
import { TaxeCol } from './TaxeCol';
import { VACol } from './VACol';

export class StatCol {
    public static addStatCol(table: Table<any>): void {
        table.addCol(new AchatCol(table));
        table.addCol(new VACol(table));
        table.addCol(new PrixVenteCol(table));
        table.addCol(new TaxeCol(table));
        table.addCol(new PrixVenteTTCCol(table));
    }
    public static addQuantite(table: Table<any>): void {
        table.addCol(new QuantiteCol(table));
    }
    public static addPU(table: Table<any>): void {
        table.addCol(new PUCol(table));
    }
    public static addNbPresta(table: Table<any>, name: string = 'Nb presta'): void {
        table.addCol(new NbPrestaCol(table, (elem: any) => elem, name));
    }
}
