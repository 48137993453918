export const PRODUCTION = true;

let url;
if (PRODUCTION) {
    url = 'https://api.ge-steam.com';
} else {
    url = 'http://localhost:5001';
}

export const SERVER_URL: string = url;
