import { Box, Typography } from '@mui/material';
import { useState } from 'preact/hooks';
import AppManager from 'src/commun/AppManager';
import { Spinner } from 'src/commun/component/Spinner';
import { post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import Toast from 'src/commun/toast/Toast';
import MpModal from 'src/component/extends/MpModal';
import Devis from 'src/object/Devis';
import { SERVER_URL } from 'src/utils/Constant';

interface ICloseDevisModalProps {
    show: boolean;
    handleClose: () => void;
    devis: Devis;
}

export const CloseDevisModal = (props: ICloseDevisModalProps) => {
    const { show, handleClose, devis } = props;

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    return (
        <MpModal
            title="Fermer le prix de vente"
            done="Fermer"
            doneColor="failure"
            onSubmit={() => {
                setLoading(true);
                setError('');
                post('/devis/close', {
                    id: devis.getAjoIdentifier(),
                }).then((res: any) => {
                    if (Result.isSuccess(res)) {
                        Result.applyResult(res, AppManager.updater(), true);
                        post('/devis/export/stat', {
                            id: devis.getAjoIdentifier(),
                        }).then((res: any) => {
                            setLoading(false);
                            if (Result.isSuccess(res)) {
                                AppManager.addToast(
                                    new Toast('Le prix de vente a été clôturé avec succès.', 'success'),
                                );
                                const link = document.createElement('a');
                                link.href = SERVER_URL + '/' + res['result']['where'];
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            } else {
                                setError(
                                    "Le prix de vente a été clôturé avec succès mais une erreur est survenue lors de l'exportation.",
                                );
                            }
                        });
                    } else {
                        setError('Une erreur est survenue lors de la clôture du prix de vente.');
                    }
                });
            }}
            open={show}
            onClose={handleClose}
        >
            {loading ? (
                <Box className="flex items-center justify-center py-12">
                    <Spinner color="blue" className="" />
                </Box>
            ) : (
                <Box className="flex flex-col !text-gray-700 gap-3">
                    <Typography className="!font-medium">
                        Voulez-vous vraiment fermer ce prix de vente ?<br />
                        Cette action est irréversible.
                    </Typography>

                    {error !== '' && (
                        <Typography
                            component={'p'}
                            className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                        >
                            {error}
                        </Typography>
                    )}
                </Box>
            )}
        </MpModal>
    );
};
