import { AJOList, AJOState } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { SearchInput } from '../commun/component/SearchInput';
import { get } from '../commun/HTTP';
import Result from '../commun/Result';
import Utils from '../commun/Utils';
import { BasePage } from '../component/BasePage';
import { ComplexSection } from '../component/ComplexSection';
import { LittleAddButton } from '../component/LittleAddButton';
import { Separator } from '../component/Separator';
import {
    agenceAddSuccess,
    categoryAddSuccess,
    categoryRemoveSuccess,
    productAddSuccess,
    productRemoveSuccess,
    templateAddSuccess,
    templateRemoveSuccess,
} from '../modal/vendor/CategoryErrorManager';
import Client from '../object/profile/Client';
import Product from '../object/tag/Product';

import Template from '../object/Template';
import { AddElemClientModal } from '../modal/vendor/AddElemClientModal';
import AppManager from '../commun/AppManager';
import { Agence } from '../object/Agence';
import CategoryEvent from '../object/tag/CategoryEvent';
import Devis from '../object/Devis';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';
import { Box } from '@mui/material';
import { ClientDevisView } from 'src/component/client/ClientDevisView';
import { ClientDevisCloseView } from 'src/component/client/ClientDevisCloseView';
import { ClientCategoryView } from 'src/component/client/ClientCategoryView';
import { ClientOffreView } from 'src/component/client/ClientOffreView';
import { ClientAgenceView } from 'src/component/client/ClientAgenceView';
import { ClientTemplateView } from 'src/component/client/ClientTemplateView';

export function BDDClientPage() {
    const [tab, setTab] = useState('progress');

    // State for category list
    const [stateCategoryList, updateStateCategoryList] = useState(
        new AJOState<AJOList<CategoryEvent>>(new AJOList(CategoryEvent)),
    );
    stateCategoryList.setUpdate(updateStateCategoryList);
    const [loadingCategoryList, updateLoadingCategoryList] = useState(true);
    AppManager.updater().add(stateCategoryList);
    const fetchCategory = () => {
        if (loadingCategoryList) {
            updateLoadingCategoryList(true);
            get('/category_event/list').then((res: any) => {
                updateLoadingCategoryList(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, stateCategoryList, true);
                }
            });
        }
    };

    // State for agence list
    const [stateAgenceList, updateStateAgenceList] = useState(new AJOState<AJOList<Agence>>(new AJOList(Agence)));
    stateAgenceList.setUpdate(updateStateAgenceList);
    const [loadingAgenceList, updateLoadingAgenceList] = useState(true);
    AppManager.updater().add(stateAgenceList);
    const fetchAgence = () => {
        if (loadingAgenceList) {
            updateLoadingAgenceList(true);
            get('/agence/list').then((res: any) => {
                updateLoadingAgenceList(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, stateAgenceList, true);
                }
            });
        }
    };

    // State for offre list
    const [stateOffreList, updateStateOffreList] = useState(new AJOState<AJOList<Product>>(new AJOList(Product)));
    stateOffreList.setUpdate(updateStateOffreList);
    const [loadingOffreList, updateLoadingOffreList] = useState(true);
    AppManager.updater().add(stateOffreList);
    const fetchOffre = () => {
        if (loadingOffreList) {
            updateLoadingOffreList(true);
            get('/product_category/list').then((res: any) => {
                updateLoadingOffreList(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, stateOffreList, true);
                }
            });
        }
    };

    const [stateDevisList, setStateDevisList] = useState(new AJOState(new AJOList<Devis>(Devis)));
    stateDevisList.setUpdate(setStateDevisList);
    AppManager.updater().add(stateDevisList);

    const [startLoadingProgress, setStartLoadingProgress] = useState(false);
    const [startLoadingClose, setStartLoadingClose] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(true);
    const [loadingClose, setLoadingClose] = useState(true);

    const fetchDevisProgress = () => {
        if (!startLoadingProgress) {
            setStartLoadingProgress(true);
            get('/devis/list/progress', {}).then((res: any) => {
                setLoadingProgress(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, stateDevisList, true);
                }
            });
        }
    };

    const fetchDevisClose = () => {
        if (!startLoadingClose) {
            setStartLoadingClose(true);
            get('/devis/list/close', {}).then((res: any) => {
                setLoadingClose(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, stateDevisList, true);
                }
            });
        }
    };

    useEffect(() => {
        if (tab === 'progress') {
            fetchDevisProgress();
        } else if (tab === 'close') {
            fetchDevisClose();
        } else if (tab === 'category') {
            fetchCategory();
        } else if (tab === 'agence') {
            fetchAgence();
        } else if (tab === 'offre') {
            fetchOffre();
        }
    }, [tab]);

    return (
        <BasePage className="flex-col flex items-center">
            <DashboardNavBar
                tab={tab}
                onTab={(tab: string) => {
                    setTab(tab);
                }}
                tabList={[
                    { value: 'progress', label: 'En cours' },
                    { value: 'close', label: 'Fermer' },
                    { value: 'template', label: 'Template' },
                    { value: 'offre', label: 'Offre' },
                    { value: 'category', label: 'Catégorie' },
                    { value: 'agence', label: 'Agence' },
                ]}
            />
            <Box className="h-full w-full">
                {tab === 'progress' && (
                    <ClientDevisView
                        list={stateDevisList
                            .get()!
                            .getList()
                            .filter((d) => {
                                return d.close.get() !== true;
                            })}
                        loading={loadingProgress}
                        close={false}
                    />
                )}

                {tab === 'close' && (
                    <ClientDevisView
                        list={stateDevisList
                            .get()!
                            .getList()
                            .filter((d) => {
                                return d.close.get() === true;
                            })}
                        loading={loadingClose}
                        close={true}
                    />
                )}

                {tab === 'category' && (
                    <ClientCategoryView list={stateCategoryList.get()!.getList()} loading={loadingCategoryList} />
                )}

                {tab === 'offre' && (
                    <ClientOffreView list={stateOffreList.get()!.getList()} loading={loadingOffreList} />
                )}

                {tab === 'agence' && (
                    <ClientAgenceView list={stateAgenceList.get()!.getList()} loading={loadingAgenceList} />
                )}

                {tab === 'template' && <ClientTemplateView />}
            </Box>
        </BasePage>
    );
}
