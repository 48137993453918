import { AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import AppManager from '../../commun/AppManager';
import { ComplexInput } from '../../commun/component/ComplexInput';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import { TagListFetch } from '../../component/TagListFetch';
import Template from '../../object/Template';
import { clientAddSuccess, errorManager, templateAddSuccess, vendorAddSuccess } from './CategoryErrorManager';

export interface IAddTemplateModalProps {
    show: boolean;
    field: string;
    action: 'add' | 'update';
    elem?: Template;
    handleClose: () => void;
}
export const AddTemplateModal: React.FC<IAddTemplateModalProps> = (props) => {
    const { field, elem, action, handleClose, show } = props;

    const [name, updateName] = useState('');
    const [error, updateError] = useState(ErrorHTTP.NO_ERROR());
    const [loading, updateLoad] = useState(false);

    useEffect(() => {
        updateError(ErrorHTTP.NO_ERROR());
        updateLoad(false);
        updateName('');
    }, [show]);

    const onAdd = () => {
        updateError(ErrorHTTP.NO_ERROR());
        updateLoad(true);
        let params: any = {
            name: name,
        };
        if (action === 'update') {
            params['id'] = elem!.getAjoIdentifier();
        }
        if (name !== '' && name !== undefined) {
            post('/template/create', params).then((res: any) => {
                updateLoad(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, AppManager.updater(), true);
                    AppManager.addToast(new Toast(errorManager.get(templateAddSuccess), 'success'));
                    props.handleClose();
                } else {
                    updateError(Result.getError(res));
                }
            });
        }
    };
    return (
        <Modal
            title="Ajouter un template"
            handleClose={handleClose}
            show={show}
            loading={loading}
            error={error}
            errorManager={errorManager}
            done="Ajouter"
            onDone={onAdd}
        >
            <ComplexInput
                placeholder="Nom"
                name="name_template"
                value={name}
                setValue={updateName}
                typeValue="string"
            />
        </Modal>
    );
};
