import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { Grid, Pagination } from '@mui/material';
import { PerfectTable } from '../../commun/table/PerfectTable';
import Utils from '../../commun/Utils';
import { AddIcon } from '../../icons/AddIcon';
import { SearchIcon } from '../../icons/SearchIcon';
import Category from '../../object/tag/Category';
import { BinIcon } from '../../icons/BinIcon';
import { EditIcon } from '../../icons/EditIcon';
import { SwitchIcon } from '../../icons/SwitchIcon';
import FusionCategoryModal from '../category/FusionCategoryModal';
import AddCategoryModal from '../category/AddCategoryModal';
import DelCategoryModal from '../category/DelCategoryModal ';
import CategoryEvent from 'src/object/tag/CategoryEvent';

export interface IClientCategoryViewProps {
    list: CategoryEvent[];
    loading: boolean;
}

export const ClientCategoryView = (props: IClientCategoryViewProps) => {
    const [search, setSearch] = useState('');

    const list = props.list.filter((category: CategoryEvent) => {
        return Utils.isSearch(search, category.name.get());
    });

    return (
        <Container maxWidth={false}>
            <Box sx={{ mb: 3, mt: 3 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        m: -1,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        Categorie
                    </Typography>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Card>
                        <CardContent className="w-full">
                            <Box className="flex w-full items-center flex-row">
                                <Box sx={{ maxWidth: 500 }}>
                                    <TextField
                                        fullWidth
                                        value={search}
                                        onChange={(e) => {
                                            setSearch((e.target as any).value);
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon color="action" fontSize="small">
                                                        <SearchIcon />
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder="Rechercher une catégorie"
                                        variant="outlined"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            <Box>
                <Grid container spacing={3}>
                    {list.map((value: Category, i: number) => {
                        return (
                            <Grid key={i} item lg={4} md={4} xs={6}>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                        p: 3,
                                    }}
                                    className={'flex items-center justify-center !bg-' + value.getColor() + '-600'}
                                >
                                    <Typography className="text-white !text-lg py-5 !font-bold text-center">
                                        {value.name.get()}
                                    </Typography>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </Container>
    );
};
