import { RefObject } from 'react';
import { JSXInternal } from 'preact/src/jsx';

export interface ICheckBoxProps extends JSXInternal.HTMLAttributes<HTMLInputElement> {
    inputRef?: RefObject<HTMLInputElement>;
    ring?: boolean;
}

export const CheckBox: React.FunctionComponent<ICheckBoxProps> = (props) => {
    const { inputRef, ring = false, id, name, value, ...rest } = props;
    if (props.checked) {
        return (
            <input
                checked
                id={id}
                value={value}
                name={name}
                type="checkbox"
                className={
                    'w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 ' +
                    (ring
                        ? ' focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 '
                        : '') +
                    ' dark:bg-gray-700 dark:border-gray-600'
                }
                {...rest}
            />
        );
    } else {
        return (
            <input
                id={id}
                value={value}
                name={name}
                type="checkbox"
                className={
                    'w-4 h-4 text-blue-600 bg-gray-100 rounded border border-gray-300 ' +
                    (ring
                        ? ' focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 '
                        : '') +
                    ' dark:bg-gray-700 dark:border-gray-600'
                }
                {...rest}
            />
        );
    }
};
