import { AJOElement, AJOObject, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import { Button } from '../commun/component/Button';
import { ButtonAdd } from '../commun/component/ButtonAdd';
import {
    categoryAddSuccess,
    categoryDeleteSuccess,
    categoryChangeSuccess,
    categoryRemoveSuccess,
    labelDeleteSuccess,
    labelRemoveSuccess,
    labelAddSuccess,
} from '../modal/vendor/CategoryErrorManager';
import { CategoryModal } from '../modal/vendor/CategoryModal';
import { RemoveCategoryModal } from '../modal/vendor/RemoveCategoryModal';
import Category from '../object/tag/Category';
import Label from '../object/tag/Label';
import Service from '../object/Service';
import Vendor from '../object/profile/Vendor';
import { EditSvg } from './EditSvg';
import { LittleAddButton } from './LittleAddButton';
import { Tag } from './Tag';
import { Spinner } from '../commun/component/Spinner';
import Product from '../object/tag/Product';
import { TagObject } from '../object/tag/TagObject';
import Budget from '../object/Budget';

export interface ITagListProps {
    checkMode?: boolean;

    canDelete?: boolean;
    canAdd?: boolean;
    canEdit?: boolean;

    loading: boolean;

    showAddNothing?: boolean;
    field: string;

    parent?: AJOElement;

    list: TagObject[];
    selectList?: TagObject[];
    onCheck?: (e: TagObject) => void;
}

export const TagList: React.FC<ITagListProps> = (props) => {
    const {
        loading,
        showAddNothing = true,
        canAdd = false,
        canEdit = false,
        canDelete = false,
        field,
        parent,
        checkMode = false,
        selectList,
        onCheck,
        list,
    } = props;

    if (checkMode) {
        if (selectList === undefined) {
            throw new Error('selectList is undefined in TagList in checkMode');
        }
        if (onCheck === undefined) {
            throw new Error('onCheck is undefined in TagList in checkMode');
        }
    }

    const [selectElem, updateElem] = useState<Category | Label | undefined>(undefined);

    const [removeShow, updateRemoveShow] = useState(false);
    const [addShow, updateAddShow] = useState(false);

    const getTextDelete = (elem: Category | Label) => {
        let textDelete = '';
        if (elem instanceof Category) {
            if (parent != undefined) {
                if (parent instanceof Vendor) {
                    textDelete = 'Êtes-vous sûr de vouloir supprimer cette catégorie du fournisseur.';
                } else if (parent instanceof Service) {
                    textDelete = 'Êtes-vous sûr de vouloir supprimer cette catégorie de la prestation du fournisseur.';
                }
            } else {
                textDelete =
                    'Êtes-vous sûr de vouloir supprimer cette catégorie, elle sera supprimée de tous les éléments de la base de données.';
            }
        } else if (elem instanceof Label) {
            if (parent != undefined) {
                if (parent instanceof Vendor) {
                    textDelete = 'Êtes-vous sûr de vouloir supprimer ce label du fournisseur.';
                } else if (parent instanceof Service || parent instanceof Budget) {
                    textDelete = 'Êtes-vous sûr de vouloir supprimer ce label de la prestation du fournisseur.';
                }
            } else {
                textDelete =
                    'Êtes-vous sûr de vouloir supprimer ce label, il sera supprimé de tous les éléments de la base de données.';
            }
        }
        return textDelete;
    };

    let fullAddButton;

    if (field === 'label') {
        fullAddButton = <LittleAddButton text="Label" onAdd={() => updateAddShow(true)}></LittleAddButton>;
    } else {
        fullAddButton = <LittleAddButton text="Ajouter" onAdd={() => updateAddShow(true)}></LittleAddButton>;
    }

    let littleAddButton = <LittleAddButton text="" onAdd={() => updateAddShow(true)}></LittleAddButton>;

    let littleEditButton = (
        <Button pb={1} pt={1} pr={1} pl={1} onClick={() => updateAddShow(true)} color="blue">
            <EditSvg className="h-5 w-5 text-white" />
        </Button>
    );

    let button;
    if (showAddNothing && !loading) {
        if (parent === undefined) {
            // In this case there is no limit of category and label
            if (canAdd) {
                if (list.length == 0) {
                    button = fullAddButton;
                } else {
                    button = littleAddButton;
                }
            }
        } else if (!(parent instanceof Vendor)) {
            if (canEdit) {
                if (field === 'category') {
                    // There is a limit of 1 category
                    if (list.length == 0) {
                        button = fullAddButton;
                    } else {
                        button = littleEditButton;
                    }
                } else {
                    // there is no limit of label
                    if (list.length == 0) {
                        button = fullAddButton;
                    } else {
                        button = littleAddButton;
                    }
                }
            }
        }
    }

    return (
        <div className="flex gap-2 flex-wrap">
            {list.map((elem) => {
                return (
                    <div key={elem.getAjoIdentifier()}>
                        <Tag
                            onDelete={(elem) => {
                                updateElem(elem);
                                updateRemoveShow(true);
                            }}
                            onCheck={onCheck}
                            elem={elem}
                            checked={
                                selectList?.find((e) => e.getAjoIdentifier() === elem.getAjoIdentifier()) !== undefined
                            }
                            check={checkMode}
                            canEdit={canEdit}
                            canDelete={canDelete}
                        />
                    </div>
                );
            })}

            {button}

            {loading && (
                <div className="flex-1 flex justify-center items-center">
                    <Spinner color="blue" />
                </div>
            )}

            {selectElem instanceof Label && (
                <RemoveCategoryModal
                    elem={selectElem}
                    parent={parent instanceof Budget ? parent.service.get()! : parent}
                    field={'label'}
                    title={'Supprimer un label'}
                    textDelete={getTextDelete(selectElem)}
                    show={removeShow}
                    successError={parent != undefined ? labelRemoveSuccess : labelDeleteSuccess}
                    handleClose={() => updateRemoveShow(false)}
                />
            )}
            {selectElem instanceof Category && (
                <RemoveCategoryModal
                    elem={selectElem}
                    parent={parent instanceof Budget ? parent.service.get()! : parent}
                    field={'category'}
                    title={'Supprimer une catégorie'}
                    textDelete={getTextDelete(selectElem)}
                    show={removeShow}
                    successError={parent != undefined ? categoryRemoveSuccess : categoryDeleteSuccess}
                    handleClose={() => updateRemoveShow(false)}
                />
            )}
            {field === 'category' && (
                <CategoryModal
                    action={parent !== undefined ? 'join' : 'new'}
                    parent={parent instanceof Budget ? parent.service.get()! : parent}
                    field={field}
                    placeholder={'Catégorie'}
                    title={list.length === 0 || parent == undefined ? 'Ajouter une catégorie' : 'Changer la catégorie'}
                    button={list.length === 0 || parent == undefined ? 'Ajouter' : 'Changer'}
                    successError={list.length === 0 || parent == undefined ? categoryAddSuccess : categoryChangeSuccess}
                    show={addShow}
                    handleClose={() => updateAddShow(false)}
                />
            )}
            {field === 'categoryhonoraire' && (
                <CategoryModal
                    action={parent !== undefined ? 'join' : 'new'}
                    parent={parent instanceof Budget ? parent.service.get()! : parent}
                    field={field}
                    placeholder={'Catégorie'}
                    title={list.length === 0 || parent == undefined ? 'Ajouter une catégorie' : 'Changer la catégorie'}
                    button={list.length === 0 || parent == undefined ? 'Ajouter' : 'Changer'}
                    successError={list.length === 0 || parent == undefined ? categoryAddSuccess : categoryChangeSuccess}
                    show={addShow}
                    handleClose={() => updateAddShow(false)}
                />
            )}
            {field === 'label' && (
                <CategoryModal
                    action={parent !== undefined ? 'join' : 'new'}
                    parent={parent instanceof Budget ? parent.service.get()! : parent}
                    field={field}
                    placeholder={'Label'}
                    title={'Ajouter un label'}
                    button={'Ajouter'}
                    successError={labelAddSuccess}
                    show={addShow}
                    handleClose={() => updateAddShow(false)}
                />
            )}
        </div>
    );
};
