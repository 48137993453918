import { AJOObject, AJOProperties } from 'mp-js-react-auto-json-object';

export class Message extends AJOObject {
    public content: AJOProperties<any>;
    public date: AJOProperties<any>;

    public static _TYPE: string = 'Message';

    constructor() {
        super(Message._TYPE);

        this.content = new AJOProperties('content', this);
        this.date = new AJOProperties('date', this);
    }

    public static build(): Message {
        return new Message();
    }
}
