import { AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Devis from '../object/Devis';
import { Paper } from '../object/Paper';
import Template from '../object/Template';
import { BasePricePage } from './BasePricePage';

export function DevisPage() {
    const { id } = useParams();

    const [stateDevis, setStateDevis] = useState(new AJOState<Devis>(Devis.build()));
    stateDevis.setUpdate(setStateDevis);

    return <BasePricePage id={id} basePriceState={stateDevis} />;
}
