import React, { useEffect, useState } from 'react';
import AppManager from '../AppManager';
import { Button } from '../component/Button';
import { ErrorText } from '../component/ErrorText';
import { Spinner } from '../component/Spinner';
import { Color, ModalBottomMode } from '../Constant';
import ErrorHTTP from '../ErrorHTTP';
import ErrorManager from '../ErrorManager';
import Utils from '../Utils';

export interface IModalProps {
    title?: string;
    done?: string;
    cancel?: string;

    errorManager?: ErrorManager;
    error?: ErrorHTTP;

    loading?: boolean;

    show: boolean;
    color?: Color;
    bottomMode?: ModalBottomMode;
    onDone?: () => void;
    handleClose: () => void;
}

export const Modal: React.FunctionComponent<IModalProps> = (props) => {
    const {
        title = '',
        color = 'blue',
        onDone,
        cancel = 'Annuler',
        done = 'Modifier',
        show,
        handleClose,
        bottomMode = 'action',
        children,
        loading = false,
        error,
        errorManager,
        ...rest
    } = props;

    const stopPropagation = (event: any) => {
        event.stopPropagation();
    };
    useEffect(() => {
        if (show) {
            Utils.blur();
        }
    }, [show]);

    let doneButtonView;

    if (show && onDone != undefined) {
        AppManager.get().onEnter = onDone;
    }

    doneButtonView = (
        <Button
            onClick={(e) => {
                e.preventDefault();
                if (onDone != undefined) {
                    onDone();
                }
            }}
            textSize="sm"
            pb={2.5}
            pt={2.5}
            pl={5}
            type="submit"
            pr={5}
            color={color}
            selected={true}
        >
            {done}
        </Button>
    );
    //<button data-modal-toggle="defaultModal" type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={onDone}>{done}</button>

    return (
        <div
            id="defaultModal"
            aria-hidden="true"
            className={
                (show ? '' : 'hidden') +
                ' overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full bg-opacity-50 bg-black flex justify-center pt-8'
            }
            onMouseDown={handleClose}
        >
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
                <div
                    className="relative bg-white rounded-lg shadow border border-gray-200 dark:border-slate-500 dark:bg-gray-800"
                    onMouseDown={stopPropagation}
                >
                    <div className="flex justify-between items-start px-6 py-4 rounded-t border-b dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">{title}</h3>
                        <button
                            onClick={handleClose}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                            <svg
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>

                    <div className="flex flex-col p-6 gap-4">
                        {children}
                        {loading && (
                            <div className="flex items-center justify-center">
                                <Spinner color="blue"></Spinner>
                            </div>
                        )}
                        {error && errorManager && <ErrorText error={error} manager={errorManager}></ErrorText>}
                    </div>

                    {bottomMode == 'action' ? (
                        <div className="flex items-center justify-end p-6 gap-4 rounded-b border-t border-gray-200 dark:border-gray-600">
                            <button
                                data-modal-toggle="defaultModal"
                                type="button"
                                className="text-gray-700 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-400 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                onClick={handleClose}
                            >
                                {cancel}
                            </button>
                            {doneButtonView}
                        </div>
                    ) : (
                        <div className="flex items-center justify-end p-6 gap-4 rounded-b border-t border-gray-200 dark:border-gray-600">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleClose();
                                }}
                                textSize="sm"
                                pb={2.5}
                                pt={2.5}
                                pl={5}
                                type="submit"
                                pr={5}
                                color="blue"
                                selected={true}
                            >
                                {'Fermer'}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
