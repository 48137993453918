import { useEffect, useState } from 'react';
import AppManager from '../../commun/AppManager';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import { PrestationSelect } from '../../component/select/PrestationSelect';
import { TagListFetch } from '../../component/TagListFetch';
import Permission from '../../object/Permission';
import Prestation from '../../object/profile/Prestation';
import Vendor from '../../object/profile/Vendor';
import Template from '../../object/Template';
import React from 'react';
import {
    errorManager,
    prestationAddSuccess,
    prestationNotSelect,
    vendorAddSuccess,
    vendorNotSelect,
} from './CategoryErrorManager';
import { VendorSelect } from '../../component/select/VendorSelect';

export interface IAddServiceModalProps {
    show: boolean;
    parent: any;
    handleClose: () => void;
}
export const AddServiceModal: React.FC<IAddServiceModalProps> = (props) => {
    const { parent, handleClose, show } = props;

    const [error, updateError] = useState(ErrorHTTP.NO_ERROR());
    const [loading, updateLoad] = useState(false);
    const [prestation, updatePrestation] = useState<Prestation | null>(null);
    const [vendor, updateVendor] = useState<Vendor | null>(null);

    const [labelList, setLabelList] = useState<any>([]);

    useEffect(() => {
        updateError(ErrorHTTP.NO_ERROR());
        updateLoad(false);
    }, [show]);

    const onAdd = () => {
        updateError(ErrorHTTP.NO_ERROR());
        updateLoad(true);

        if (parent instanceof Vendor) {
            if (prestation == null) {
                updateLoad(false);
                updateError(prestationNotSelect);
            } else {
                let sendLabelList = [];
                for (const label of labelList) {
                    sendLabelList.push({
                        id: label.getAjoIdentifier(),
                    });
                }

                let prestationParams: any = {};
                prestationParams.id = prestation!.getAjoIdentifier();

                post('/service/update', {
                    vendor: {
                        id: parent.getAjoIdentifier(),
                    },
                    prestation: prestationParams,
                    label: sendLabelList,
                }).then((res: any) => {
                    updateLoad(false);
                    if (Result.isSuccess(res)) {
                        Result.applyResult(res, AppManager.updater(), true, true);
                        Result.applyResult(res, AppManager.updater(), true, true);
                        updateError(prestationAddSuccess);

                        AppManager.addToast(new Toast(errorManager.get(prestationAddSuccess), 'success'));
                        props.handleClose();
                    } else {
                        updateError(Result.getError(res));
                    }
                });
            }
        } else {
            if (vendor == null) {
                updateLoad(false);
                updateError(vendorNotSelect);
            } else {
                let sendLabelList = [];
                for (const label of labelList) {
                    sendLabelList.push({
                        id: label.getAjoIdentifier(),
                    });
                }

                let vendorParams: any = {};
                vendorParams.id = vendor!.getAjoIdentifier();

                post('/service/update', {
                    prestation: {
                        id: parent.getAjoIdentifier(),
                    },
                    vendor: vendorParams,
                    label: sendLabelList,
                }).then((res: any) => {
                    updateLoad(false);
                    if (Result.isSuccess(res)) {
                        Result.applyResult(res, AppManager.updater(), true, true);
                        Result.applyResult(res, AppManager.updater(), true, true);
                        updateError(vendorAddSuccess);

                        AppManager.addToast(new Toast(errorManager.get(vendorAddSuccess), 'success'));
                        props.handleClose();
                    } else {
                        updateError(Result.getError(res));
                    }
                });
            }
        }
    };

    let title = '';
    if (parent instanceof Vendor) {
        title = 'Ajouter une prestation';
    } else if (parent instanceof Prestation) {
        title = 'Ajouter un fournisseur';
    }
    return (
        <Modal
            title={title}
            handleClose={handleClose}
            show={show}
            loading={loading}
            error={error}
            errorManager={errorManager}
            done="Ajouter"
            onDone={onAdd}
        >
            {parent instanceof Vendor && [
                <PrestationSelect
                    placeholder="Prestation"
                    elem={prestation ?? undefined}
                    onObjectSelect={(prestation) => {
                        updatePrestation(prestation);
                    }}
                    canAdd={AppManager.hasPermission(Permission.MANAGE_PRESTATION)}
                />,
                <TagListFetch
                    canAdd={AppManager.hasPermission(Permission.MANAGE_CATEGORY)}
                    onSelectList={setLabelList}
                    selectList={labelList}
                    simple={false}
                    field="label"
                />,
            ]}
            {parent instanceof Prestation && [
                <VendorSelect
                    placeholder="Fournisseur"
                    elem={vendor ?? undefined}
                    onObjectSelect={(vendor) => {
                        updateVendor(vendor);
                    }}
                    canAdd={AppManager.hasPermission(Permission.MANAGE_VENDOR)}
                />,
            ]}
        </Modal>
    );
};
