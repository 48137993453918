import { AJOUpdater } from 'mp-js-react-auto-json-object';
import React from 'react';
import { Button } from '../commun/component/Button';
import { Spinner } from '../commun/component/Spinner';
import { post } from '../commun/HTTP';
import Result from '../commun/Result';
import { Section } from '../object/Section';
import { TimerParent } from '../object/TimerInterface';

interface IHonoraireButtonProps {
    value: TimerParent;
    section: Section | null;
    updater: AJOUpdater;
}

interface IHonoraireButtonState {}

export class HonoraireButton extends React.Component<IHonoraireButtonProps, IHonoraireButtonState> {
    render() {
        const { value, section, updater } = this.props;

        let content;
        if (value.isLoadingTimer(section)) {
            content = <Spinner color="blue" />;
        } else {
            content = (
                <Button
                    color={value.isPlaying(section) ? 'red' : 'blue'}
                    pb={1.5}
                    pt={1.5}
                    pl={1.5}
                    pr={1.5}
                    onClick={() => {
                        value.setLoadingTimer(section, true);
                        post('/timer/point', {
                            id_object: value.getAjoIdentifier(),
                            id_section: section?.getAjoIdentifier() ?? '',
                            name_section: '',
                        }).then((res: any) => {
                            value.setLoadingTimer(section, false);
                            //resetTimer();
                            Result.applyResult(res, updater, true);
                        });
                    }}
                >
                    {value.isPlaying(section) ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                fillRule="evenodd"
                                d="M6.75 5.25a.75.75 0 01.75-.75H9a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H7.5a.75.75 0 01-.75-.75V5.25zm7.5 0A.75.75 0 0115 4.5h1.5a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H15a.75.75 0 01-.75-.75V5.25z"
                                clipRule="evenodd"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                                clipRule="evenodd"
                            />
                        </svg>
                    )}
                </Button>
            );
        }

        return content;
    }
}
