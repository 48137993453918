import { Comptable } from './Comptable';
import { Agence } from './Agence';
import { Marge } from './Marge';
import { Honoraire } from './Honoraire';
import { Paper } from './Paper';
import { Group } from './Group';
import { UserColInterface } from '../commun/col/UserCol';
import { AJOList, AJOObject, AJOProperties, AJOSimple, AJOUpdater } from 'mp-js-react-auto-json-object';
import { NameColInterface } from '../commun/col/NameCol';
import { NumColInterface } from '../commun/col/NumCol';
import { Color } from '../commun/Constant';
import Budget from './Budget';
import { BudgetManager } from './BudgetManager';
import Product from './tag/Product';
import Client from './profile/Client';
import User from './User';
import { ProductColInterface } from '../commun/col/ProduitCol';
import { get, post } from '../commun/HTTP';
import Result from '../commun/Result';
import Utils from '../commun/Utils';
import { CategoryColInterface } from '../commun/col/CategoryCol';
import Category from './tag/Category';
import Prestation from './profile/Prestation';
import Vendor from './profile/Vendor';
import Valeur from './Valeur';
import Taxe from './Taxe';
import CategoryEvent from './tag/CategoryEvent';
import { CategoryHonoraire } from './CategoryHonoraire';
import AppManager from '../commun/AppManager';
import Toast from '../commun/toast/Toast';
import { devisValidateSuccess, errorManager } from '../modal/vendor/CategoryErrorManager';
import Address from './Address';
import Nego from './Nego';
import { TimerParent } from './TimerInterface';
import { PerfectAJOList } from '../commun/object/PerfectAJOList';

export default abstract class BasePrice extends TimerParent implements NameColInterface, Comptable {
    public NameColInterface = true;
    public Comptable = true;

    public getLink(): string {
        return `/template/${this.getAjoIdentifier()}`;
    }

    public fetchAll(callback: () => void) {
        if (!this.isBlock()) {
            get('/prestation_category/list', {}, () => {
                this.prestationList?.setList([]);
            }).then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, this.prestationList, true);
                    this.makeUpdate(true);
                    callback();
                }
            });
        }
    }

    public canAct(): boolean {
        return true;
    }

    public getName(): string {
        return this.name.get();
    }

    public abstract isBlock(): boolean;

    public static _TYPE: string = 'Template';

    public onTab: ((str: string) => void) | null = null;

    public name: AJOProperties<any>;
    public date: AJOProperties<any>;
    public start_date: AJOProperties<any>;
    public end_date: AJOProperties<any>;
    public nb_personne: AJOProperties<any>;
    public product: AJOSimple<Product>;
    public groupList: AJOList<Group>;
    public paperList: AJOList<Paper>;

    private _selectedPaper: string = '';
    public get selectedPaper(): string {
        return this._selectedPaper;
    }
    public set selectedPaper(selectedPaper: string) {
        this._selectedPaper = selectedPaper;
    }

    public prestationList: PerfectAJOList<Prestation> | null = null;
    public vendorList: PerfectAJOList<Vendor> | null = null;
    public valeurList: PerfectAJOList<Valeur> | null = null;
    public taxeList: PerfectAJOList<Taxe> | null = null;
    public addressList: PerfectAJOList<Address> | null = null;
    public honoraireList: PerfectAJOList<Honoraire> | null = null;
    public negoList: PerfectAJOList<Nego> | null = null;
    public agenceList: PerfectAJOList<Agence> | null = null;
    public productList: PerfectAJOList<Product> | null = null;
    public categoryEventList: PerfectAJOList<CategoryEvent> | null = null;
    public fullUserList: PerfectAJOList<User> | null = null;

    public startPaperView() {
        if (this.prestationList == null) {
            this.honoraireList = new PerfectAJOList<Honoraire>(
                '/honoraire_category/list',
                () => {
                    return {};
                },
                Honoraire,
            );

            this.prestationList = new PerfectAJOList<Prestation>(
                '/prestation_category/list',
                () => {
                    return {};
                },
                Prestation,
            );
            this.vendorList = new PerfectAJOList<Vendor>(
                '/vendor_label_contact/list',
                () => {
                    return {};
                },
                Vendor,
            );
            this.addressList = new PerfectAJOList<Address>(
                '/address/list',
                () => {
                    return {};
                },
                Address,
            );
            this.valeurList = new PerfectAJOList<Valeur>(
                '/valeur/list',
                () => {
                    return {};
                },
                Valeur,
            );
            this.taxeList = new PerfectAJOList<Taxe>(
                '/taxe/list',
                () => {
                    return {};
                },
                Taxe,
            );
            this.agenceList = new PerfectAJOList<Agence>(
                '/agence/list',
                () => {
                    return {};
                },
                Agence,
            );
            this.productList = new PerfectAJOList<Product>(
                '/product/list',
                () => {
                    return {};
                },
                Product,
            );
            this.negoList = new PerfectAJOList<Nego>(
                '/nego/list',
                () => {
                    return {};
                },
                Nego,
            );
            this.categoryEventList = new PerfectAJOList<CategoryEvent>(
                '/category_event/list',
                () => {
                    return {};
                },
                CategoryEvent,
            );
            this.fullUserList = new PerfectAJOList<User>(
                '/user/list',
                () => {
                    return {};
                },
                User,
            );
        }
    }

    constructor(type: string) {
        super(type);

        this.name = new AJOProperties('name', this);
        this.date = new AJOProperties('date', this);
        this.start_date = new AJOProperties('start_date', this);
        this.end_date = new AJOProperties('end_date', this);
        this.nb_personne = new AJOProperties('nb_personne', this);
        this.product = new AJOSimple<Product>(Product, 'has_product', this);
        this.groupList = new AJOList<Group>(Group, 'has_group', this);
        this.paperList = new AJOList<Paper>(Paper, 'has_paper', this, (a: Paper, b: Paper) => {
            if (a instanceof Paper && b instanceof Paper) {
                let aDate = new Date(a.archive.get() ? a.archive_date.get() : 0).getTime();
                let bDate = new Date(b.archive.get() ? b.archive_date.get() : 0).getTime();

                if (aDate === 0 && a.root.get() === true && !a.archive.get()) {
                    return -1;
                }
                if (aDate === 0 && bDate === 0) {
                    if (a.func.get() === 0) {
                        return -1;
                    } else {
                        return 1;
                    }
                } else if (aDate === 0 && bDate !== 0) {
                    return -1;
                } else if (aDate !== 0 && bDate === 0) {
                    return 1;
                } else {
                    let diff = aDate - bDate;
                    if (Math.abs(diff) <= 1000 * 10) {
                        if (a.func.get() === 0) {
                            return -1;
                        } else {
                            return 1;
                        }
                    } else {
                        return -diff;
                    }
                }
            }
            return 0;
        });
    }

    public explainVa(): string {
        return (
            "Pour l'offre " +
            this.getProductList()[0].name.get() +
            ', il faut un taux de marge brute entre ' +
            this.getProductList()[0].min.get() +
            ' % et ' +
            this.getProductList()[0].max.get() +
            ' %.'
        );
    }

    abstract get(id: any, callback: (res: any) => void): void;

    public getProductList(): Product[] {
        if (this.product.get()) {
            return [this.product.get()!];
        }
        return [];
    }

    public getSelectedPaper(): Paper | null {
        let paper = null;
        let i = 0;
        while (paper == null && i < this.paperList.getList().length) {
            if (this.paperList.getList()[i].getAjoIdentifier() === this.selectedPaper) {
                paper = this.paperList.getList()[i];
            }
            i += 1;
        }
        return paper;
    }

    public getValidatePaper(): Paper | null {
        let paper = null;
        let i = 0;
        while (paper == null && i < this.paperList.getList().length) {
            if (this.paperList.getList()[i].validate.get()) {
                paper = this.paperList.getList()[i];
            }
            i += 1;
        }
        return paper;
    }

    public getRootPaper(): Paper | null {
        let paper = null;
        let i = 0;
        while (paper == null && i < this.paperList.getList().length) {
            if (this.paperList.getList()[i].root.get()) {
                paper = this.paperList.getList()[i];
            }
            i += 1;
        }
        return paper;
    }

    public getComptablePaper(): Paper | null {
        let paper = null;
        let i = 0;
        while (paper == null && i < this.paperList.getList().length) {
            if (this.paperList.getList()[i].comptable.get() && this.paperList.getList()[i].root.get() === true) {
                paper = this.paperList.getList()[i];
            }
            i += 1;
        }
        i = 0;
        while (paper == null && i < this.paperList.getList().length) {
            if (this.paperList.getList()[i].archive.get() !== true) {
                if (this.paperList.getList()[i].root.get() === true) {
                    paper = this.paperList.getList()[i];
                }
            }
            i += 1;
        }
        return paper;
    }

    public getOptionBudgetList(): Budget[] {
        let budgetList = [] as Budget[];
        let i = 0;
        while (i < this.paperList.getList().length) {
            budgetList.push(...this.paperList.getList()[i].getOptionBudgetList(0));
            budgetList.push(...this.paperList.getList()[i].getOptionBudgetList(1));
            i += 1;
        }
        return budgetList;
    }

    public getRecCategoryList(): Category[] {
        let categoryList = [] as Category[];
        let i = 0;
        if (this.getValidatePaper() != null) {
            let paperCategoryList = this.getValidatePaper()!.getRecCategoryList();
            // add only elemet not already in the list
            let j = 0;
            while (j < paperCategoryList.length) {
                if (!categoryList.some((c) => c.getAjoIdentifier() === paperCategoryList[j].getAjoIdentifier())) {
                    categoryList.push(paperCategoryList[j]);
                }
                j += 1;
            }
            i += 1;
        }
        return categoryList;
    }
    public getOptionCategoryList(): Category[] {
        let categoryList = [] as Category[];
        let i = 0;
        if (this.getValidatePaper() != null) {
            let paperCategoryList = this.getValidatePaper()!.getOptionCategoryList();
            // add only elemet not already in the list
            let j = 0;
            while (j < paperCategoryList.length) {
                if (!categoryList.some((c) => c.getAjoIdentifier() === paperCategoryList[j].getAjoIdentifier())) {
                    categoryList.push(paperCategoryList[j]);
                }
                j += 1;
            }
            i += 1;
        }
        return categoryList;
    }
    public getOptionHonoraireCategoryList(): CategoryHonoraire[] {
        let categoryList = [] as CategoryHonoraire[];
        let i = 0;
        if (this.getValidatePaper() != null) {
            let paperCategoryList = this.getValidatePaper()!.getOptionHonoraireCategoryList();
            // add only elemet not already in the list
            let j = 0;
            while (j < paperCategoryList.length) {
                if (!categoryList.some((c) => c.getAjoIdentifier() === paperCategoryList[j].getAjoIdentifier())) {
                    categoryList.push(paperCategoryList[j]);
                }
                j += 1;
            }
            i += 1;
        }
        return categoryList;
    }

    public loadOption: boolean = false;
    public startOption: boolean = false;
    public fetchOption() {
        if (!this.startOption && !this.loadOption) {
            this.startOption = true;
            let obj = this;
            get(
                '/devis/get/option',
                {
                    id: this.getAjoIdentifier(),
                },
                () => {},
                true,
            ).then((res: any) => {
                obj.startOption = false;
                if (Result.isSuccess(res)) {
                    obj.loadOption = true;
                    Result.applyResult(res, obj, true);
                }
            });
        }
    }

    public loadComment: boolean = false;
    public startComment: boolean = false;
    public fetchComment() {
        if (!this.startComment && !this.loadComment) {
            this.startComment = true;
            let obj = this;
            get(
                '/devis/get/comment',
                {
                    id: this.getAjoIdentifier(),
                },
                () => {},
                true,
            ).then((res: any) => {
                obj.startComment = false;
                if (Result.isSuccess(res)) {
                    obj.loadComment = true;
                    Result.applyResult(res, obj, true);
                }
            });
        }
    }

    public forfaitHonoraire(): number {
        return this.getComptablePaper()?.forfaitHonoraire() ?? 0;
    }

    public minute(): number {
        return this.getComptablePaper()?.minute() ?? 0;
    }

    public jour(): number {
        return this.getComptablePaper()?.jour() ?? 0;
    }
    public marge(): number {
        let res = 0;
        res = this.prixVenteHT() - this.achat();
        return res;
    }
    public loadCommand: boolean = false;
    public startCommand: boolean = false;
    public fetchCommand() {
        if (!this.startCommand && !this.loadCommand) {
            this.startCommand = true;
            let obj = this;
            get(
                '/devis/get/command',
                {
                    id: this.getAjoIdentifier(),
                },
                () => {},
                true,
            ).then((res: any) => {
                obj.startCommand = false;
                if (Result.isSuccess(res)) {
                    obj.loadCommand = true;
                    Result.applyResult(res, obj, true);
                }
            });
        }
    }

    public devisUnValidate(setLoading: (loading: boolean) => void) {
        setLoading(true);
        post('/devis/unvalidate', {
            id: this.getAjoIdentifier(),
        }).then((res: any) => {
            setLoading(false);
            if (Result.isSuccess(res)) {
                Result.applyResult(res, AppManager.updater(), true);
                AppManager.addToast(new Toast(errorManager.get(devisValidateSuccess), 'success'));
            } else {
                AppManager.addToast(new Toast(errorManager.get(Result.getError(res)), 'error'));
            }
        });
    }

    public quantite(): number {
        return this.getComptablePaper()?.quantite() ?? 0;
    }
    public prixUnitaire(): number {
        return this.getComptablePaper()?.prixUnitaire() ?? 0;
    }
    public achat(): number {
        return this.getComptablePaper()?.achat() ?? 0;
    }
    public achatNoOption(): number {
        return this.getComptablePaper()?.achatNoOption() ?? 0;
    }
    public honoraire(): number {
        return this.getComptablePaper()?.honoraire() ?? 0;
    }
    public honoraireNoOption(): number {
        return this.getComptablePaper()?.honoraireNoOption() ?? 0;
    }
    public va(): number {
        return this.getComptablePaper()?.va() ?? 0;
    }
    public prixVenteHT(): number {
        return this.getComptablePaper()?.prixVenteHT() ?? 0;
    }
    public prixVenteHTNoOption(): number {
        return this.getComptablePaper()?.prixVenteHTNoOption() ?? 0;
    }
    public vaNoOption(): number {
        return this.getComptablePaper()?.vaNoOption() ?? 0;
    }

    public margeNoOption(): number {
        let res = 0;
        res = this.prixVenteHTNoOption() - this.achatNoOption();
        return res;
    }

    public tax(): number {
        return this.getComptablePaper()?.tax() ?? 0;
    }
    public prixVenteTTC(): number {
        return this.getComptablePaper()?.prixVenteTTC() ?? 0;
    }
    public getBudgetCommandList(): string[] {
        const budegtList = [] as string[];
        const allBudgetDevis = this.getValidatePaper()!.getFuncCompliteBudgetList(0, true);
        for (const budget of allBudgetDevis) {
            if (budget.fullComplite() && budget.bon_commande.get() == null) {
                budegtList.push(budget.getAjoIdentifier());
            }
        }
        return budegtList;
    }

    abstract isGoodVa(): boolean;

    public getBudgetInProgressList(): Budget[] {
        return this.getRootPaper()!.getBudgetInProgressList();
    }

    public getBudgetInCompliteList(): Budget[] {
        return this.getRootPaper()!.getBudgetInCompliteList();
    }

    public getBudgetInCommandList(): Budget[] {
        return this.getRootPaper()!.getBudgetInCommandList();
    }

    public getBudgetInOptionList(): Budget[] {
        return this.getRootPaper()!.getBudgetInOptionList();
    }

    public getBudgetInPrestationList(): Budget[] {
        return this.getRootPaper()!.getBudgetInPrestationList();
    }
}
