import { AJOList, AJOObject, AJOProperties, AJOSimple } from 'mp-js-react-auto-json-object';
import { Color } from '../../commun/Constant';
import { get } from '../../commun/HTTP';
import Result from '../../commun/Result';
import Address from '../Address';
import Category from '../tag/Category';
import ParentSevice from './ParentSevice';
import { ProfilInterface } from '../interface/ProfilInterface';
import { NameColInterface } from '../../commun/col/NameCol';
import { LabelColInterface } from '../../commun/col/LabelCol';
import { CategoryColInterface } from '../../commun/col/CategoryCol';
import { IObjectSelectable } from '../../component/select/ObjectSelect';

export default class Prestation
    extends ParentSevice
    implements IObjectSelectable, ProfilInterface, NameColInterface, CategoryColInterface, LabelColInterface
{
    public NameColInterface = true;
    public CategoryColInterface = true;
    public LabelColInterface = true;
    public IObjectSelectable = true;

    public getSelectTitle(): string {
        return this.getName();
    }

    public getSelectDescription(): string {
        return '';
    }

    public getName(): string {
        return this.name.get();
    }
    public getLink(): string {
        return `/prestation/${this.getAjoIdentifier()}`;
    }

    public getColor(): Color {
        return 'green';
    }
    public getTitle(): string {
        return 'Prestation';
    }

    public static _TYPE: string = 'Prestation';
    public category: AJOSimple<Category>;

    constructor() {
        super(Prestation._TYPE);
        this.category = new AJOSimple(Category, 'has_prestation_category', this);
    }

    public static build(): Prestation {
        return new Prestation();
    }
    public get(id: any, callback: () => void): void {
        const obj = this;
        get('/prestation/get', { id: id }).then((data) => {
            Result.applyResult(data, obj, true);
            callback();
        });
    }
    public getStat(id: any, callback: () => void): void {
        const obj = this;
        get('/prestation/get/stat', { id: id }).then((data) => {
            Result.applyResult(data, obj, true);
            callback();
        });
    }

    public getCategoryList(): Category[] {
        // get all category of service list
        let categoryList: Category[] = [];
        if (this.category.get()) {
            categoryList.push(this.category.get()!);
        }
        return categoryList;
    }
}
