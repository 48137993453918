import { Box, Typography } from '@mui/material';
import { JSXInternal } from 'preact/src/jsx';
import React from 'react';
import { Spinner } from 'src/commun/component/Spinner';
import AppManager from '../commun/AppManager';
import { Button } from '../commun/component/Button';
import ErrorHTTP from '../commun/ErrorHTTP';
import { post } from '../commun/HTTP';
import { Modal } from '../commun/modal/Modal';
import Result from '../commun/Result';
import Toast from '../commun/toast/Toast';
import { TimerModal } from '../modal/TimerModal';
import { errorManager } from '../modal/vendor/CategoryErrorManager';
import Devis from '../object/Devis';
import Client from '../object/profile/Client';
import { Section } from '../object/Section';
import { Timer } from '../object/Timer';
import User from '../object/User';
import { DeleteSvg } from './DeleteSvg';
import { EditSvg } from './EditSvg';
import MpModal from './extends/MpModal';
import { TimerView } from './TimerView';

interface ITimerCalendarProps {
    user: User;
    top: number;
    bottom: number;
    width: number;
    left: number;
    quartHour: number;

    color: string;

    timer: Timer;
    day: number;
    canEdit: boolean;

    timerList: Timer[];
}
interface ITimerCalendarState {
    showEdit: boolean;
    showDelete: boolean;
    errorDelete: ErrorHTTP;
    loadingDelete: boolean;
}

export class TimerCalendarView extends React.Component<ITimerCalendarProps, ITimerCalendarState> {
    constructor(props: ITimerCalendarProps) {
        super(props);

        this.state = this.initState();
    }

    initState() {
        return {
            showEdit: false,

            showDelete: false,
            errorDelete: ErrorHTTP.NO_ERROR(),
            loadingDelete: false,
        };
    }

    componentDidUpdate(
        prevProps: Readonly<ITimerCalendarProps>,
        prevState: Readonly<ITimerCalendarState>,
        snapshot?: any,
    ): void {
        if (this.state.showDelete != prevState.showDelete) {
            if (!this.state.showDelete) {
                this.setState(this.initState());
            }
        }
    }
    onMouseDown(e: JSXInternal.TargetedMouseEvent<HTMLTableCellElement | HTMLTableRowElement>, typeMove: number) {
        const { timer, quartHour } = this.props;
        if (!timer.startEvent) {
            timer.startAction(e, quartHour, typeMove);
        }
    }
    onMouseUp(e: JSXInternal.TargetedMouseEvent<HTMLTableRowElement>) {
        const { timer, timerList, day } = this.props;

        if (
            Math.abs(e.timeStamp - timer.startTimeStamp) < 1000 &&
            e.pageX === timer.startEventX &&
            e.pageY === timer.startEventY
        ) {
            timer.stopAction();
            for (let timer of timerList) {
                timer.setShow(false, day, 0);
            }
            if (timer.isShow(day)) {
                timer.setShow(false, day, 0);
            } else {
                timer.setShow(
                    true,
                    day,
                    e.clientY - (e.currentTarget.parentElement?.getBoundingClientRect().top ?? 0) - 10,
                );
            }
            AppManager.makeUpdate();
        }
    }
    onMouseLeave(e: JSXInternal.TargetedMouseEvent<HTMLTableRowElement>) {}
    onMouseEnter(e: JSXInternal.TargetedMouseEvent<HTMLTableRowElement>) {}

    render() {
        const { timerList, day, top, bottom, width, left, color, timer } = this.props;
        const { showEdit, showDelete } = this.state;

        let roundedTop = '';
        if (top !== 0) {
            roundedTop = ' rounded-t-md ';
        }

        let roundedBottom = '';
        if (bottom !== 0) {
            roundedBottom = ' rounded-b-md ';
        }

        let zindex = 'z-[5]';
        if (timer.startEvent) {
            zindex = 'z-[6]';
        }

        const statuTimer = (timer: Timer) => {
            let content = undefined;
            if (timer.isMaladie()) {
                content = undefined;
            } else if (timer.isFerie()) {
                content = undefined;
            } else if (timer.isCpRecupFerie() && timer.isPendding()) {
                content = (
                    <div
                        className={
                            'bg-' +
                            color +
                            '-600 font-medium mt-2 mb-2 flex items-center justify-center px-2.5 py-0.5 text-white rounded-full'
                        }
                    >
                        <span>En attente</span>
                    </div>
                );
            } else if (timer.isCpRecupFerie() && timer.isValidate()) {
                content = (
                    <div
                        className={
                            'bg-green-600 font-medium mt-2 mb-2 flex items-center justify-center px-2.5 py-0.5 text-white rounded-full'
                        }
                    >
                        <span>Accepté</span>
                    </div>
                );
            } else if (timer.isCpRecupFerie() && timer.isRefuse()) {
                content = (
                    <div
                        className={
                            'bg-red-600 font-medium mt-2 mb-2 flex items-center justify-center px-2.5 py-0.5 text-white rounded-full'
                        }
                    >
                        <span>Refusé</span>
                    </div>
                );
            }
            return <div className="flex">{content}</div>;
        };
        return [
            <tr
                key={'0'}
                style={{
                    top: 'calc(' + top + '% )',
                    left: left + 'px',
                    bottom: 'calc(' + bottom + '% )',
                    width: width + 'px',
                }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onMouseDown={(e) => {
                    if (this.props.canEdit && !timer.isFerie()) {
                        this.onMouseDown(e, Timer.MOVE);
                    }
                }}
                onMouseUp={(e) => {
                    this.onMouseUp(e);
                }}
                onMouseLeave={(e) => {
                    this.onMouseLeave(e);
                }}
                onMouseEnter={(e) => {
                    this.onMouseEnter(e);
                }}
                className={zindex + ' absolute overflow-hidden timer min-h-[0.25rem] bg-' + color + '-100 '}
            >
                <td className=" relative gap-1 h-full w-full">
                    <div className="h-full w-full flex flex-col pl-2 pt-1">
                        <div className="flex flex-row gap-1 items-center">
                            <span className={' font-semibold text-' + color + '-700'}>{timer.getName()}</span>
                            {timer.edit.get() === true && <EditSvg className={'text-' + color + '-700 h-5 w-5'} />}
                        </div>
                        {statuTimer(timer)}
                        {timer.section.get() != null && (
                            <span className={' font-medium text-sm text-' + color + '-600'}>
                                {timer.section.get()!.name.get()}
                            </span>
                        )}
                    </div>
                </td>
                <td className={' absolute right-0 left-0 top-0 h-0.5 w-full bg-' + color + '-700'}></td>
                <td className={' absolute right-0 left-0 bottom-0 h-0.5 w-full bg-' + color + '-700'}></td>
                <td
                    className={' absolute right-0 top-0 h-5 w-5 bg-' + color + '-700'}
                    onMouseDown={(e) => {
                        if (this.props.canEdit && !timer.isFerie()) {
                            this.onMouseDown(e, Timer.UP);
                        }
                    }}
                ></td>
                <td
                    className={' absolute right-0 bottom-0 h-5 w-5 bg-' + color + '-700'}
                    onMouseDown={(e) => {
                        if (this.props.canEdit && !timer.isFerie()) {
                            this.onMouseDown(e, Timer.DOWN);
                        }
                    }}
                ></td>
            </tr>,
            timer.isShow(day) && (
                <tr
                    key={'1'}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className={
                        ' z-[6] border border-slate-400 bg-white rounded-md absolute overflow-hidden flex flex-col gap-2 pl-2  pr-2 pb-2 pt-1'
                    }
                    style={{
                        top: 'min(59rem, ' + timer.topModal + 'px)',

                        left: left + width + 10 + 'px',
                        width: width + 50,
                    }}
                >
                    <td className=" flex flex-col">
                        <div className="flex flex-row gap-1 items-center">
                            <span className={' font-semibold text-' + color + '-700'}>{timer.getName()}</span>
                            {timer.edit.get() === true && <EditSvg className={'text-' + color + '-700 h-5 w-5'} />}
                        </div>

                        {statuTimer(timer)}

                        {timer.section.get() != null && (
                            <span className={' font-medium text-sm text-' + color + '-600'}>
                                {timer.section.get()!.name.get()}
                            </span>
                        )}

                        <span className={' text-sm '}>
                            {<TimerView date={new Date(0)} canJour={false} dateEnd={new Date(timer.getDuration())} />}
                        </span>

                        {this.props.canEdit && !timer.isFerie() && (
                            <div className="flex flex-col mt-2 gap-2">
                                <Button
                                    pt={1.5}
                                    pb={1.5}
                                    pr={1.5}
                                    pl={1.5}
                                    textSize="sm"
                                    gap={1}
                                    color="blue"
                                    onClick={() => {
                                        this.setState({ showEdit: true });
                                    }}
                                >
                                    <EditSvg className="h-5 w-5" />
                                    Modifier
                                </Button>
                                <Button
                                    pt={1.5}
                                    pb={1.5}
                                    pr={1.5}
                                    pl={1.5}
                                    textSize="sm"
                                    gap={1}
                                    color="green"
                                    onClick={() => {
                                        timer.setShow(false, day, 0);
                                        (timer.getAjoParent()?.getAjoParent() as any).copyTimer = timer;
                                        AppManager.makeUpdate();
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                                        <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                                    </svg>
                                    Dupliquer
                                </Button>
                                <Button
                                    pt={1.5}
                                    pb={1.5}
                                    pr={1.5}
                                    pl={1.5}
                                    textSize="sm"
                                    gap={1}
                                    color="red"
                                    onClick={() => {
                                        this.setState({ showDelete: true });
                                    }}
                                >
                                    <DeleteSvg className="h-5 w-5" />
                                    Supprimer
                                </Button>
                            </div>
                        )}
                    </td>
                </tr>
            ),
            <tr
                key={2}
                className="absolute"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <td
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {showEdit && (
                        <TimerModal
                            user={this.props.user}
                            endDate={null}
                            startDate={null}
                            startHour={null}
                            endHour={null}
                            timer={timer}
                            action="edit"
                            show={showEdit}
                            handleClose={() => {
                                this.setState({ showEdit: false });
                            }}
                        />
                    )}
                </td>
                <td
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {showDelete && (
                        <MpModal
                            open={showDelete}
                            title="Supprimer l'horaire"
                            onSubmit={() => {
                                this.setState({
                                    loadingDelete: true,
                                    errorDelete: ErrorHTTP.NO_ERROR(),
                                });
                                post('/timer/delete', {
                                    id: timer.getAjoIdentifier(),
                                }).then((res: any) => {
                                    if (Result.isSuccess(res)) {
                                        this.setState({
                                            loadingDelete: false,
                                            showDelete: false,
                                        });
                                        Result.applyResult(res, AppManager.updater(), true);
                                        AppManager.addToast(new Toast("L'horaire a été supprimé.", 'success'));
                                    } else {
                                        this.setState({
                                            errorDelete: Result.getError(res),
                                            loadingDelete: false,
                                        });
                                    }
                                });
                            }}
                            onClose={() => {
                                this.setState({ showDelete: false });
                            }}
                            doneColor={'failure'}
                            done={'Supprimer'}
                        >
                            {this.state.loadingDelete ? (
                                <Box className="flex items-center justify-center p-10">
                                    <Spinner color="blue" />
                                </Box>
                            ) : (
                                <Box className="flex flex-col gap-3">
                                    <p>
                                        Êtes-vous sûr de vouloir supprimer cette horaire, cette action est irréversible.
                                    </p>

                                    {!this.state.errorDelete.equals(ErrorHTTP.NO_ERROR()) && (
                                        <Typography
                                            component={'p'}
                                            className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                                        >
                                            {errorManager.get(this.state.errorDelete)}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        </MpModal>
                    )}
                </td>
            </tr>,
        ];
    }
}
