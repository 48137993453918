import ErrorHTTP from '../ErrorHTTP';
import ErrorManager from '../ErrorManager';

export interface IErrorTextProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    error: ErrorHTTP;
    manager: ErrorManager;
    topSpacing?: number;
    bottomSpacing?: number;
}

export const ErrorText: React.FunctionComponent<IErrorTextProps> = (props) => {
    const { error, manager, topSpacing = 0, bottomSpacing = 0, ...rest } = props;
    let res;
    if (error.equals(ErrorHTTP.NO_ERROR())) {
        res = null;
    } else {
        res = (
            <span
                className={
                    'pt-' +
                    topSpacing +
                    ' pb-' +
                    bottomSpacing +
                    ' text-center text-' +
                    manager.getColor(error) +
                    ' dark:text-' +
                    manager.getDarkColor(error)
                }
                {...rest}
            >
                {manager.get(error)}
            </span>
        );
    }
    return res;
};
