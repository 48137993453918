import { AJOObject, AJOUpdater } from 'mp-js-react-auto-json-object';
import { Col } from '../table/Col';
import Table from '../table/Table';
import Product from '../../object/tag/Product';
import { TagList } from '../../component/TagList';
import { SelectTagListModal } from '../../modal/vendor/SelectTagListModal';
import AppManager from '../AppManager';
import Permission from '../../object/Permission';
import { TagListFetch } from '../../component/TagListFetch';

export interface ProductColInterface {
    ProductColInterface: boolean;
    getProductList(): Product[];
}

export class ProductCol<Type extends AJOObject> extends Col<Type> {
    private canAdd: boolean = false;
    private parent: AJOObject | null = null;

    constructor(
        table: Table<Type>,
        cate: Product[],

        canEdit: boolean = false,
        canAdd: boolean = false,
        canDelete: boolean = false,

        get: (elem: Type) => AJOObject = (elem) => elem,
        name: string = 'Prestation',

        selectProduct: (cate: Product[]) => void = () => {},
        parent: AJOObject | null = null,
    ) {
        super(name);

        this.parent = parent;
        this.canAdd = canAdd;

        this.table = table;
        this.render = (elem: Type) => {
            if ('ProductColInterface' in get(elem)) {
                canEdit = canEdit && AppManager.hasPermission(Permission.MANAGE_PRODUCT);
                canAdd = canAdd && AppManager.hasPermission(Permission.MANAGE_PRODUCT);
                canDelete = canDelete && AppManager.hasPermission(Permission.MANAGE_PRODUCT);

                let inter = get(elem) as unknown as ProductColInterface;
                let res;
                res = (
                    <div className="flex gap-2 flex-wrap">
                        <TagList
                            loading={false}
                            canAdd={canAdd}
                            canEdit={canEdit}
                            field={'Product'}
                            list={inter.getProductList()}
                            canDelete={canDelete}
                            parent={elem}
                        ></TagList>
                    </div>
                );
                return res;
            } else {
                throw new Error('ProductColInterface not found in elem');
            }
        };
        this.sort = (a: Type, b: Type) => {
            if ('ProductColInterface' in get(a) && 'ProductColInterface' in get(b)) {
                let aInter = get(a) as unknown as ProductColInterface;
                let bInter = get(b) as unknown as ProductColInterface;
                return -aInter.getProductList().length + bInter.getProductList().length;
            } else {
                throw new Error('ProductColInterface not found in a or b');
            }
        };
        const obj = this;
        this.update(cate, selectProduct);
        this.renderFilterModal = () => {
            return <SelectTagListModal field="product" col={obj} />;
        };
        this.filter = (elem: Type) => {
            if ('ProductColInterface' in get(elem)) {
                let inter = get(elem) as unknown as ProductColInterface;
                let can = obj.filterList.length === 0;
                let i = 0;
                // check if service has Product in elem list of Product row
                while (!can && i < obj.filterList.length) {
                    can =
                        inter
                            .getProductList()
                            .find((e: Product) => e.getAjoIdentifier() == obj.filterList[i].getAjoIdentifier()) !=
                        undefined;
                    i++;
                }
                return can;
            } else {
                throw new Error('ProductColInterface not found in elem');
            }
        };
        this.typeRender = 'both';
        this.type = 'sort_filter';
        this.senseSort = 0;
    }

    public update(cate: Product[], selectProduct: (cate: Product[]) => void = () => {}) {
        const obj = this;
        this.renderTopRow = () => {
            if (obj.parent != null) {
                if ('ProductColInterface' in obj.parent) {
                    let inter = obj.parent as unknown as ProductColInterface;

                    let canAdd = obj.canAdd && AppManager.hasPermission(Permission.MANAGE_PRODUCT);

                    return (
                        <TagListFetch
                            simple={true}
                            selectList={cate}
                            onSelectList={selectProduct}
                            canAdd={canAdd}
                            field={'Product'}
                        />
                    );
                } else {
                    throw new Error('ProductColInterface not found in parent');
                }
            }
        };
    }
}
