import AppManager from '../../commun/AppManager';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { get, post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import { errorManager, sectionAddSuccess } from '../../modal/vendor/CategoryErrorManager';
import { EditVendorModal } from '../../modal/vendor/VendorEditModal';
import Address from '../../object/Address';
import Permission from '../../object/Permission';
import { Section } from '../../object/Section';
import { EditSvg } from '../EditSvg';
import { JSXInternal } from 'preact/src/jsx';
import { IObjectSelectProps, ObjectSelect } from './ObjectSelect';
import MpModal from '../extends/MpModal';
import { Box } from '@mui/material';
import { Spinner } from 'src/commun/component/Spinner';

export interface ISectionSelectProps extends IObjectSelectProps<Section> {
    placeholder: string;
}

export class SectionSelect extends ObjectSelect<Section, ISectionSelectProps> {
    createGenericObject() {
        return Section;
    }
    constructor(props: ISectionSelectProps) {
        /**
         * DEFAULT PROPS
         */

        super(props, Permission.MANAGE_SECTION, 'section');

        /**
         * CREATE STATE
         */
        this.state = this.initState();

        // FUNCTION USE TO NOTIFY PARENT COMPONENT
        this.state.ajoList.setUpdate(() => this.forceUpdate());
        AppManager.updater().add(this.state.ajoList);
    }

    fetchList() {
        if (this.state.isAjoListInflate) {
            get('/section/list').then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, this.state.ajoList, true);
                }
                this.setState({
                    isAjoListInflate: true,
                });
            });
        }
    }

    renderModal(): JSXInternal.Element {
        const { ajoList, elem } = this.state;
        if (ajoList != undefined) {
            AppManager.updater().add(ajoList);
        }
        let obj = this;
        if (elem === undefined || elem == null) {
            return (
                <MpModal
                    onSubmit={() => {
                        this.setState({
                            loadingModal: true,
                            errorModal: ErrorHTTP.NO_ERROR(),
                        });
                        post('/section/update', {
                            name: this.state.search,
                        }).then((res: any) => {
                            this.setState({
                                loadingModal: false,
                            });
                            if (Result.isSuccess(res)) {
                                let section = new Section();
                                Result.applyResult(res, section, true);
                                obj.props.onObjectSelect(section);

                                Result.applyResult(res, this.state.ajoList, true);
                                Result.applyResult(res, AppManager.updater(), true);

                                AppManager.addToast(new Toast(errorManager.get(sectionAddSuccess), 'success'));

                                this.setState({
                                    showModal: false,
                                    loadingModal: false,
                                    errorModal: ErrorHTTP.NO_ERROR(),
                                });
                            } else {
                                this.setState({
                                    errorModal: Result.getError(res),
                                });
                            }
                        });
                    }}
                    onClose={() => {
                        this.setState({
                            showModal: false,
                            loadingModal: false,
                            errorModal: ErrorHTTP.NO_ERROR(),
                        });
                    }}
                    title={'Nouvelle section'}
                    done="Ajouter"
                    open={this.state.showModal}
                >
                    {this.state.loadingModal ? (
                        <Box className="flex items-center justify-center p-10">
                            <Spinner color="blue" />
                        </Box>
                    ) : (
                        <Box className="flex flex-col gap-3">
                            <p>La section {this.state.name} n'existe pas dans la base de donnée. Valider son ajout.</p>
                        </Box>
                    )}
                </MpModal>
            );
        } else {
            return (
                <EditVendorModal
                    show={this.state.showModal}
                    handleClose={() => {
                        this.setState({
                            showModal: false,
                        });
                    }}
                    callback={() => {
                        this.onElemChange(elem.getAjoIdentifier());
                    }}
                    profile={this.state.elem!}
                />
            );
        }
    }

    render(): JSXInternal.Element {
        return super.render();
    }
}
