import MakeAction from './Action';

export default class ActionManager {
    // Manage list of action, chack if action exists in list and if it can be done
    private static instance: ActionManager;
    private actionList: MakeAction[];
    private constructor() {
        this.actionList = [];
    }
    public static get(): ActionManager {
        if (ActionManager.instance == null) {
            ActionManager.instance = new ActionManager();
        }
        return ActionManager.instance;
    }
    public static addAction(action: MakeAction): ActionManager {
        ActionManager.get().actionList.push(action);
        return ActionManager.get();
    }
    public static canDoAction(action: number, elem1: any = undefined, elem2: any = undefined): boolean {
        for (let i = 0; i < ActionManager.get().actionList.length; i++) {
            if (ActionManager.get().actionList[i].getType() == action) {
                return ActionManager.get().actionList[i].can(elem1, elem2);
            }
        }
        return false;
    }
    public static canDoActionList(action: number, elem1: any = undefined, elem2: any[] = []): boolean {
        for (let i = 0; i < ActionManager.get().actionList.length; i++) {
            if (ActionManager.get().actionList[i].getType() == action) {
                for (let j = 0; j < elem2.length; j++) {
                    if (ActionManager.get().actionList[i].can(elem1, elem2[j])) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
    // Get list of all elem2 list taht fit the can method of action type
    public static listDoAction(action: number, elem1: any = undefined, elem2: any[] = []): any[] {
        let elem2List: any[] = [];
        for (let i = 0; i < ActionManager.get().actionList.length; i++) {
            if (ActionManager.get().actionList[i].getType() == action) {
                for (let j = 0; j < elem2.length; j++) {
                    if (ActionManager.get().actionList[i].can(elem1, elem2[j])) {
                        elem2List.push(elem2[j]);
                    }
                }
            }
        }
        return elem2List;
    }
}
