import { AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import { CaretDownFill, CaretRightFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import AppManager from '../../commun/AppManager';
import { Button } from '../../commun/component/Button';
import { EuroSpan } from '../../commun/component/EuroSpan';
import { PourcentSpan } from '../../commun/component/PourcentSpan';
import { Color, Size } from '../../commun/Constant';
import { Col } from '../../commun/table/Col';
import Table from '../../commun/table/Table';
import { TableView } from '../../commun/table/TableView';
import Utils from '../../commun/Utils';
import { Action } from '../../object/Action';
import Devis from '../../object/Devis';
import { Section } from '../../object/Section';
import { Timer } from '../../object/Timer';
import User from '../../object/User';
import { EditSvg } from '../EditSvg';
import { TimerView } from '../TimerView';
import { UserTimerModal } from './UserTimerModal';

interface ITableHoraireSectionProps {
    timerList: Timer[];
    parent: Devis | Action;
    size: Size;
    date: Date;
}

export const TableHoraireSectionList: React.FC<ITableHoraireSectionProps> = (props) => {
    const { timerList, date, parent } = props;

    let monday = Utils.getMonday(date);
    let sunday = Utils.getSunday(date);

    const makeTable = () => {
        const table = new Table<Section>('user');
        let nomCol = new Col<Section>('Nom');
        nomCol.sort = (a: Section, b: Section) => {
            return a.name.get().localeCompare(b.getAjoIdentifier());
        };
        nomCol.type = 'sort';
        nomCol.senseSort = 1;
        nomCol.render = (elem: Section) => {
            let res = <p className={'font-medium text-' + parent.getColorSpec() + '-600'}>{elem.name.get()}</p>;
            return <div className="flex flex-row gap-2 items-center">{res}</div>;
        };
        table.addCol(nomCol);

        let dureeCol = new Col<Section>('Durée');
        dureeCol.sort = (a: Section, b: Section) => {
            return a.name.get().localeCompare(b.getAjoIdentifier());
        };
        dureeCol.type = 'sort';
        dureeCol.senseSort = 0;
        dureeCol.render = (elem: Section) => {
            return (
                <TimerView
                    canJour={false}
                    date={new Date(0)}
                    dateEnd={new Date(Timer.getTimerByAnySection(timerList, monday, sunday, parent, elem))}
                />
            );
        };
        table.addCol(dureeCol);

        table.colList.forEach((col: Col<Section>) => {
            col.table = table;
        });

        return table;
    };

    const [stateTable, setStateTable] = useState(new AJOState(makeTable()));
    stateTable.setUpdate(setStateTable);

    let table = stateTable.get()!;

    table.preventUpdate = true;
    table.state = stateTable;

    table.bgFunc = (elem: Section) => {
        return 'bg-' + parent.getColorSpec() + '-200';
    };

    table.list = Timer.getSectionByAny(timerList, Utils.getMonday(date), Utils.getSunday(date), parent);

    table.parent = parent as unknown as Table<AJOObject>;
    table.size = 'sm';
    table.nothingRender = () => {
        return (
            <div>
                <p className="text-center p-24">Aucun élément</p>
            </div>
        );
    };
    table.preventUpdate = false;

    return <TableView table={table}></TableView>;
};
