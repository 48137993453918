export interface IToggleProps extends React.HTMLAttributes<HTMLInputElement> {
    text: string;
}

export const Toggle: React.FunctionComponent<IToggleProps> = (props) => {
    const { id, text, ...rest } = props;
    return (
        <div className="flex items-center ">
            <label htmlFor={id} className="flex relative items-center cursor-pointer gap-2">
                {text}
                <div className="inline-flex relative items-center cursor-pointer">
                    <input type="checkbox" value="" id={id} className="sr-only peer" {...rest} />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </div>
            </label>
        </div>
    );
};
