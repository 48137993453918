import { AJOObject } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import { EditSvg } from '../../component/EditSvg';
import { categoryEditSuccess } from '../../modal/vendor/CategoryErrorManager';
import { CategoryModal } from '../../modal/vendor/CategoryModal';
import { BonCommande } from '../../object/BonCommande';
import { TagObject } from '../../object/tag/TagObject';
import { Button } from '../component/Button';
import { Col } from './Col';
import Table from './Table';

export interface ISectionTrProps {
    tag: TagObject;
    table: Table<AJOObject>;
    list: AJOObject[];
}
export const SectionTr: React.FC<ISectionTrProps> = (props) => {
    const { tag, table, list } = props;
    const [show, setShow] = useState(false);
    return (
        <>
            {[
                <tr
                    key="0"
                    className={
                        'bg-' +
                        tag.getColor() +
                        '-700 dark:bg-' +
                        tag.getColor() +
                        '-600 ' +
                        (!tag.isBonus() ? 'border-b' : '') +
                        ' border-gray-200 dark:border-slate-500 text-base text-white  '
                    }
                >
                    {table.colList.map(
                        (col, indexCol) =>
                            col.skip && (
                                <td
                                    key={table.key + '-' + tag.getAjoIdentifier() + '-' + indexCol}
                                    className="p-2"
                                ></td>
                            ),
                    )}
                    <td className="p-2 gap-2 h-full">
                        <div className="flex flex-row gap-2">
                            <p className="text-base font-medium text-white">{tag.name.get()}</p>
                            {!(tag instanceof BonCommande) && (
                                <Button
                                    color={tag.getColor()}
                                    pb={0}
                                    pt={0}
                                    pr={0}
                                    pl={0}
                                    onClick={() => {
                                        setShow(true);
                                    }}
                                >
                                    <EditSvg className="text-white h-5 w-5" />
                                </Button>
                            )}
                            <div className="absolute">
                                {show && (
                                    <CategoryModal
                                        show={show}
                                        field={tag.getAjoType().toLowerCase()}
                                        action="edit"
                                        elem={tag}
                                        title="Modifier la catégorie"
                                        placeholder="Nom"
                                        button="Modifier"
                                        successError={categoryEditSuccess}
                                        handleClose={() => setShow(false)}
                                    />
                                )}
                            </div>
                        </div>
                    </td>
                    {table.type === 'stat' &&
                        table.colList.slice(1 + (table.colList[0].skip ? 1 : 0), table.colList.length).map(
                            (row, indexRow) =>
                                !row.skip &&
                                row.isVisible() && (
                                    <td key={table.key + '-' + tag.getAjoIdentifier() + '-' + indexRow} className="p-2">
                                        {row.sumRender(list)}
                                    </td>
                                ),
                        )}
                </tr>,
                tag.isBonus() && (
                    <tr
                        key="1"
                        className={
                            'bg-' +
                            tag.getColor() +
                            '-700 dark:bg-' +
                            tag.getColor() +
                            '-600 border-b border-gray-200 dark:border-slate-500 text-base text-white  '
                        }
                    >
                        {table.colList.map(
                            (col, indexCol) =>
                                col.skip && (
                                    <td
                                        key={table.key + '-' + tag.getAjoIdentifier() + '-' + indexCol}
                                        className="p-2"
                                    ></td>
                                ),
                        )}
                    </tr>
                ),
            ]}
        </>
    );
};
