import { AJOList, AJOState } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import AppManager from '../commun/AppManager';
import { Accordion } from '../commun/component/Accordion';
import { ComplexInput } from '../commun/component/ComplexInput';
import { ErrorText } from '../commun/component/ErrorText';
import { PermLine } from '../commun/component/PermLine';
import { Spinner } from '../commun/component/Spinner';
import ErrorHTTP from '../commun/ErrorHTTP';
import ErrorManager from '../commun/ErrorManager';
import ErrorMessage from '../commun/ErrorMessage';
import { post } from '../commun/HTTP';
import { IModalProps, Modal } from '../commun/modal/Modal';
import Result from '../commun/Result';
import Toast from '../commun/toast/Toast';
import Permission from '../object/Permission';
import Role from '../object/Role';
import User from '../object/User';

export interface IRoleModalProps extends IModalProps {
    typeAction?: 'add' | 'update';
    currentRole?: Role;
    preName?: string;
    stateList: AJOState<AJOList<Role>>;
    selectIdList: string[];
}

const permList = [
    {
        type: 0,
        title: 'Administrateur',
        description:
            "Donne à l'utilisateur tout les droits sur la plateforme. Il peut donc créer, modifier, supprimer des utilisateurs. Il régit les permissions et les rôles. Il a aussi tout les autre permissions.",
    },
];
const list: number[] = [];
export const RoleModal: React.FunctionComponent<IRoleModalProps> = (props) => {
    const { typeAction = 'add', currentRole, preName = '', ...rest } = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(ErrorHTTP.NO_ERROR());

    const [selectTypeList, updateSelectedTypeList] = useState(
        typeAction == 'update' ? currentRole!.getPermTypeList() : new Array<number>(),
    );

    const [name, setName] = useState(typeAction == 'update' ? currentRole?.name.get() ?? '' : preName);

    const errorManager = new ErrorManager('/role/' + typeAction);
    /**
     * LASTNAME
     */
    errorManager.addMessage(ErrorMessage.BAD_NAME_ENTER());
    errorManager.addMessage(ErrorMessage.BAD_NAME_MIN());
    errorManager.addMessage(ErrorMessage.BAD_NAME_MAX());
    errorManager.addMessage(ErrorMessage.BAD_NAME_TYPE());
    /**
     * REST
     */
    errorManager.addMessage(ErrorMessage.NO_PERMISSION());
    errorManager.addMessage(ErrorMessage.BAD_VALUE());

    if (typeAction == 'add') {
        errorManager.add(
            ErrorHTTP.SUCCESS().setColor('green-600').setColorDark('green-300'),
            'Le rôle a été ajouté avec succès.',
        );
    } else {
        errorManager.add(
            ErrorHTTP.SUCCESS().setColor('green-600').setColorDark('green-300'),
            'Le rôle a été modifié avec succès.',
        );
    }

    const onCheck = (type: number) => {
        if (type in selectTypeList) {
            updateSelectedTypeList(selectTypeList.filter((t) => t != type));
        } else {
            selectTypeList.push(type);
            updateSelectedTypeList([...selectTypeList]);
        }
    };
    const onDone = () => {
        setLoading(true);
        setError(ErrorHTTP.NO_ERROR());
        if (typeAction == 'add') {
            post('/role/add', {
                name: name,
                permission_list: selectTypeList,
            }).then((res: any) => {
                setLoading(false);
                if (Result.isSuccess(res)) {
                    setError(ErrorHTTP.SUCCESS());
                    Result.applyResult(res, AppManager.updater(), true);

                    AppManager.addToast(new Toast(errorManager.get(ErrorHTTP.SUCCESS()), 'success'));
                    props.handleClose();
                } else {
                    setError(Result.getError(res));
                }
            });
        } else if (typeAction == 'update') {
            post('/role/update', {
                id: currentRole!.getAjoIdentifier(),
                name: name,
                permission_list: selectTypeList,
            }).then((res: any) => {
                setLoading(false);
                if (Result.isSuccess(res)) {
                    setError(ErrorHTTP.SUCCESS());
                    Result.applyResult(res, AppManager.updater(), true);

                    AppManager.addToast(new Toast(errorManager.get(ErrorHTTP.SUCCESS()), 'success'));
                    props.handleClose();
                } else {
                    setError(Result.getError(res));
                }
            });
        }
    };

    return (
        <Modal
            onDone={onDone}
            done={typeAction === 'add' ? 'Ajouter' : 'Modifier'}
            title={typeAction === 'add' ? 'Nouveau rôle' : 'Modifier un rôle'}
            {...rest}
        >
            <ComplexInput
                placeholder="Nom"
                name="role-name"
                value={name}
                typeValue="string"
                type="text"
                setValue={setName}
            ></ComplexInput>

            <div className="px-1">
                {permList.map((perm, index) => (
                    <PermLine
                        onChange={(e) => onCheck(perm.type)}
                        checked={perm.type in selectTypeList}
                        key={'perm-' + index}
                        type={Permission.ADMINISTRATOR}
                        title={perm.title}
                        description={perm.description}
                    ></PermLine>
                ))}
            </div>

            <Spinner color="blue"></Spinner>

            <ErrorText topSpacing={2} manager={errorManager} error={error}></ErrorText>
        </Modal>
    );
};
