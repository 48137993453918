import React from 'react';
import AppManager from '../../commun/AppManager';
import { get } from '../../commun/HTTP';
import Result from '../../commun/Result';
import { AddressModal } from '../../modal/addess/AddressModal';
import { AddElemClientModal } from '../../modal/vendor/AddElemClientModal';
import Permission from '../../object/Permission';
import Client from '../../object/profile/Client';
import { JSXInternal } from 'preact/src/jsx';
import { IObjectSelectProps, IObjectSelectState, ObjectSelect } from './ObjectSelect';

export interface IClientSelectProps extends IObjectSelectProps<Client> {
    placeholder: string;
}

export class ClientSelect extends ObjectSelect<Client, IClientSelectProps> {
    createGenericObject(): new (...args: any) => Client {
        return Client;
    }
    constructor(props: IClientSelectProps) {
        /**
         * DEFAULT PROPS
         */

        super(props, Permission.MANAGE_CLIENT, 'client');

        /**
         * CREATE STATE
         */
        this.state = this.initState();

        // FUNCTION USE TO NOTIFY PARENT COMPONENT
        this.state.ajoList.setUpdate(() => this.forceUpdate());
    }

    fetchList() {
        if (this.state.isAjoListInflate) {
            get('/client/list').then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, this.state.ajoList, true);
                }
                this.setState({
                    isAjoListInflate: true,
                });
            });
        }
    }

    renderModal(): JSXInternal.Element {
        const { elem } = this.state;
        let obj = this;
        return (
            <AddElemClientModal
                show={this.state.showModal}
                mode="client"
                refElem={elem}
                onCreate={(elem: any) => {
                    this.props.onObjectSelect(elem);
                }}
                clientBase={null}
                baseName={this.state.search}
                templateList={[]}
                handleClose={() => {
                    this.setState({
                        showModal: false,
                    });
                }}
            />
        );
    }

    render(): JSXInternal.Element {
        AppManager.updater().add(this.state.ajoList);
        return super.render();
    }
}
