import { AJOUpdater } from 'mp-js-react-auto-json-object';
import { JSXInternal } from 'preact/src/jsx';
import { Button } from '../../commun/component/Button';
import { Paper } from '../../object/Paper';
import { RightClickPaper } from './RightClickPaper';

export interface IPaperViewProps extends JSXInternal.HTMLAttributes<HTMLButtonElement> {
    paper: Paper;
    selected: boolean;
    full?: boolean;
}
/**
 * Build a paper view based on the paper object.
 * @param props : IPaperViewProps { paper: Paper }
 * @returns JSX.Element : PaperView construc a reprensentation of the paper
 */
export const PaperView: React.FC<IPaperViewProps> = (props) => {
    const { paper, full = false, selected, ...rest } = props;

    // Default icon if paper is default
    let defaultIcon;
    if (paper.default.get() === true) {
        defaultIcon = (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                    fillRule="evenodd"
                    d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
                    clipRule="evenodd"
                />
            </svg>
        );
    } else if (paper.root.get() === true) {
        defaultIcon = (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                    fillRule="evenodd"
                    d="M10.496 2.132a1 1 0 00-.992 0l-7 4A1 1 0 003 8v7a1 1 0 100 2h14a1 1 0 100-2V8a1 1 0 00.496-1.868l-7-4zM6 9a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1zm3 1a1 1 0 012 0v3a1 1 0 11-2 0v-3zm5-1a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1z"
                    clipRule="evenodd"
                />
            </svg>
        );
    }

    // icon of paper
    let paperIcon;
    if (paper.func.get() === 0) {
        paperIcon = (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                <path
                    fillRule="evenodd"
                    d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                    clipRule="evenodd"
                />
            </svg>
        );
    } else {
        paperIcon = (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                    clipRule="evenodd"
                />
            </svg>
        );
    }

    /*if(upd4ater===undefined){
        return <Button {...rest} border={2} outline={!selected} pb={1.5} pt={1.5} pr={2.5} pl={2.5} gap={1} color={paper.getColor()}>
            {defaultIcon}
            <span>{paper.getName()}</span>
            {paperIcon}
        </Button>
    }
    else {*/
    return full ? (
        <Button
            {...rest}
            border={2}
            outline={!selected}
            pb={1.5}
            pt={1.5}
            pr={2.5}
            pl={2.5}
            gap={1}
            color={paper.getColor()}
        >
            {defaultIcon}
            <span className="whitespace-nowrap">{paper.getName()}</span>
            {paperIcon}
        </Button>
    ) : (
        <RightClickPaper
            full={full}
            paper={paper}
            children={
                <Button
                    {...rest}
                    border={2}
                    outline={!selected}
                    pb={1.5}
                    pt={1.5}
                    pr={2.5}
                    pl={2.5}
                    gap={1}
                    color={paper.getColor()}
                >
                    {defaultIcon}
                    <span className="whitespace-nowrap">{paper.getName()}</span>
                    {paperIcon}
                </Button>
            }
        />
    );
    //}
};
