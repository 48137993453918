import { Box, Typography } from '@mui/material';
import { useState } from 'preact/hooks';
import { EuroSpan } from 'src/commun/component/EuroSpan';
import { PourcentSpan } from 'src/commun/component/PourcentSpan';
import { Color } from 'src/commun/Constant';
import { IPerfectTableGroup, PerfectTable } from 'src/commun/table/PerfectTable';
import BasePrice from 'src/object/BasePrice';
import Budget from 'src/object/Budget';
import { Paper } from 'src/object/Paper';
import { BudgetCommentView } from '../paper/BudgetCommentView';
import { BudgetNoteView } from '../paper/BudgetNoteView';
import { PrestationSelect } from './PrestationSelect';
import { PriceInput } from './PriceInput';
import { QuantityInput } from './QuantityInput';
import { VendorSelect } from './VendorSelect';

export interface IBudgetCommentTableProps {
    list: Budget[];
    basePrice: BasePrice;
    paper: Paper;
    loading: boolean;
}

export const BudgetCommentTable = (props: IBudgetCommentTableProps) => {
    const { loading, basePrice, paper, list } = props;

    const [deleteBudget, setDeleteBudget] = useState<Budget | null>(null);

    let groups: IPerfectTableGroup[] = [];
    for (const b of list) {
        const cat = b.prestation.get()?.category.get() ?? null;
        if (groups.find((e) => e.id === (cat?.getAjoIdentifier() ?? '')) === undefined) {
            const budgetList = [] as Budget[];
            for (const b of list) {
                const cbCat = b.prestation.get()?.category.get() ?? null;
                if ((cbCat?.getAjoIdentifier() ?? '') == (cat?.getAjoIdentifier() ?? '')) {
                    budgetList.push(b);
                }
            }

            groups.push({
                id: cat?.getAjoIdentifier() ?? '',
                render: () => (
                    <Box className="flex flex-col gap-3 items-center p-4 justify-center">
                        <Typography className={'!font-semibold w-full !text-white '}>
                            {cat?.name.get() ?? 'Sans catégorie'}
                        </Typography>
                    </Box>
                ),
                color: cat?.getColor() ?? 'gray',
                getList: (list: any[]) => {
                    return budgetList;
                },
            });
        }
    }
    groups.sort((a, b) => {
        if (a.id === '') {
            return -1;
        } else if (b.id === '') {
            return 1;
        } else {
            return b.id < a.id ? 1 : -1;
        }
    });
    return (
        <Box>
            <PerfectTable
                loading={loading}
                list={list}
                color={'white'}
                nothing={
                    <Box className="flex flex-col gap-3 items-center justify-center">
                        <Typography>{'Aucune prestation commandée.'}</Typography>
                    </Box>
                }
                bonusTrClass={(elem: any) => {
                    return elem.focus && '!border-8 !border-blue-600';
                }}
                bonusTdClass={(elem: any) => {
                    return elem.focus && '!bg-blue-200';
                }}
                groups={groups}
                columns={[
                    {
                        name: 'Prestation',

                        render: (elem: Budget) => {
                            return (
                                <Box className="flex flex-col items-center justify-center">
                                    {elem.alias.get() !== undefined &&
                                        elem.alias.get() !== undefined &&
                                        elem.alias.get() !== '' && (
                                            <Typography className="mb-3">{elem.alias.get()}</Typography>
                                        )}
                                    <Typography className="mb-3">{elem.prestation.get()?.name.get()}</Typography>
                                </Box>
                            );
                        },
                    },
                    {
                        name: 'Fournisseur',

                        render: (elem: Budget) => {
                            return <Typography className="mb-3">{elem.vendor.get()?.name.get()}</Typography>;
                        },
                    },
                    {
                        name: 'Note',

                        render: (elem: Budget) => {
                            return <BudgetNoteView basePrice={basePrice} budget={elem} />;
                        },
                    },
                    {
                        name: 'Commentaire',

                        render: (elem: Budget) => {
                            return <BudgetCommentView basePrice={basePrice} budget={elem} />;
                        },
                    },
                ]}
            />
        </Box>
    );
};
