import { AJOObject } from 'mp-js-react-auto-json-object';
import { AccordeonTable } from '../../component/AccordeonTable';
import { DoubleDateModal } from '../../modal/vendor/DoubleDateModal';
import { Col } from '../table/Col';
import Table from '../table/Table';

export interface DateColInterface {
    DateColInterface: boolean;
    getDate(): string | undefined;
}

export class DateCol<Type extends AJOObject> extends Col<Type> {
    constructor(table: Table<Type>, get: (elem: Type) => Type = (elem) => elem, name: string = 'Date') {
        super(name);
        this.render = (elem: Type) => {
            if ('DateColInterface' in get(elem)) {
                let inter = get(elem) as unknown as DateColInterface;
                let res;
                if (inter.getDate() !== undefined) {
                    res = (
                        <div className="flex flex-row gap-1 items-center">
                            <p>{new Date(inter.getDate()!).toLocaleDateString('fr')}</p>
                        </div>
                    );
                } else {
                    res = (
                        <div className="flex flex-row gap-1 items-center">
                            <p>Aucune date</p>
                        </div>
                    );
                }
                return res;
            } else {
                throw new Error('DateColInterface not found in elem');
            }
        };
        this.sort = (a: Type, b: Type) => {
            if ('DateColInterface' in get(a) && 'DateColInterface' in get(b)) {
                let aInter = get(a) as unknown as DateColInterface;
                let bInter = get(b) as unknown as DateColInterface;
                return -new Date(aInter.getDate() ?? '').getTime() + new Date(bInter.getDate() ?? '').getTime();
            } else {
                throw new Error('DateColInterface not found in a or b');
            }
        };
        let obj = this;
        this.renderFilterModal = () => {
            return <DoubleDateModal col={obj} />;
        };
        this.filter = (elem: Type) => {
            if ('DateColInterface' in get(elem)) {
                let inter = get(elem) as unknown as DateColInterface;
                let res = true;
                if (this.hasFilter()) {
                    let time = new Date(inter.getDate() ?? '').getTime();
                    res = time <= this.endDate!.getTime() && time >= this.startDate!.getTime();
                }
                return res;
            } else {
                throw new Error('DateColInterface not found in elem');
            }
        };
        this.table = table;
        this.type = 'sort_filter';
        this.senseSort = 1;
    }
}
