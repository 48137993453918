import { AJOList, AJOObject } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import BasePrice from 'src/object/BasePrice';
import Contact from 'src/object/Contact';
import Nego from 'src/object/Nego';
import AppManager from '../../commun/AppManager';
import Autocomplete from '../../commun/component/Autocomplite';
import { post } from '../../commun/HTTP';
import { PerfectAJOList } from '../../commun/object/PerfectAJOList';
import Budget from '../../object/Budget';
import Vendor from '../../object/profile/Vendor';
import Valeur from '../../object/Valeur';
import ContactModal from '../contact/ContactModal';
import VendorModal from '../vendor/VendorModal';
import { CustomSelect } from './CustomSelect';

export interface IContactSelectProps {
    vendorList: PerfectAJOList<Vendor>;
    budget: Budget;
    basePrice: BasePrice;
}

export const ContactSelect = (props: IContactSelectProps) => {
    const { vendorList, budget, basePrice } = props;

    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');

    const contactList = [] as Contact[];
    let vendor = null;
    let i = 0;
    while (vendor == null && i < vendorList.getList().length) {
        if (vendorList.getList()[i].getAjoIdentifier() == budget.vendor.get()?.getAjoIdentifier()) {
            vendor = vendorList.getList()[i];
        }
        i++;
    }
    if (vendor != null) {
        contactList.push(...vendor.contactList.getList());
    }

    return (
        <>
            <CustomSelect
                className=" w-40 "
                id={'contact-' + budget.getAjoIdentifier()}
                disabled={budget.bon_commande.get() !== null || !basePrice.canAct()}
                list={contactList}
                value={budget.contact.get()}
                getValue={(elem: Contact | undefined | null) => {
                    if (elem === undefined || elem === null) {
                        return '';
                    } else {
                        return elem.name.get() ?? '';
                    }
                }}
                placeholder="Contact"
                onBlur={() => {
                    budget.leaveFocus();
                }}
                onFocus={() => {
                    budget.getFocus();
                    vendorList?.need();
                }}
                onCreate={(str: string) => {
                    setName(str);
                    setOpen(true);
                }}
                onSelect={(item: any) => {
                    if (item instanceof Contact) {
                        budget.contact.set(item);
                        budget.addEdit({
                            contact: item.getAjoIdentifier(),
                        });
                        AppManager.makeUpdate();
                    } else if (budget.contact.get() !== null) {
                        budget.contact.set(null);
                        budget.addEdit({
                            contact: -1,
                        });
                        AppManager.makeUpdate();
                    }
                }}
            />
            <ContactModal
                vendor={vendor ?? new Vendor()}
                contact={null}
                open={open}
                baseName={name}
                onClose={() => {
                    setOpen(false);
                }}
                onCreate={(contact: Contact) => {
                    post('/vendor/update', {
                        id: budget.vendor.get()?.getAjoIdentifier(),
                        contact: {
                            id: contact.getAjoIdentifier(),
                        },
                    }).then((res) => {
                        budget.addEdit({
                            contact: contact.getAjoIdentifier(),
                        });
                    });
                    budget.contact.set(contact);
                    AppManager.makeUpdate();
                }}
            />
        </>
    );
};
