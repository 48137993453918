import { AJOObject } from 'mp-js-react-auto-json-object';
import { Col } from '../table/Col';
import Table from '../table/Table';
import Label from '../../object/tag/Label';
import { TagList } from '../../component/TagList';
import { SelectTagListModal } from '../../modal/vendor/SelectTagListModal';
import AppManager from '../AppManager';
import Permission from '../../object/Permission';
import { TagListFetch } from '../../component/TagListFetch';

export interface LabelColInterface {
    LabelColInterface: boolean;
    getLabelList(): Label[];
}

export class LabelCol<Type extends AJOObject> extends Col<Type> {
    private canAdd: boolean = false;
    private parent: AJOObject | null = null;
    constructor(
        table: Table<Type>,
        canEdit: boolean = false,
        canAdd: boolean = false,
        canDelete: boolean = false,
        get: (elem: Type) => AJOObject | null = (elem) => elem,
        name: string = 'Label',
        selectLabel: (cate: Label[]) => void = () => {},
        cate: Label[] = [],
        parent: AJOObject | null = null,
    ) {
        super(name);
        this.parent = parent;
        this.canAdd = canAdd;
        this.table = table;
        this.typeRender = 'info';
        this.render = (elem: Type) => {
            if (get(elem) == null) {
                return <p>Aucun fournisseur, aucune prestation</p>;
            }
            if ('LabelColInterface' in get(elem)!) {
                if (get(elem)!.getAjoIdentifier() !== undefined) {
                    canEdit = canEdit && AppManager.hasPermission(Permission.MANAGE_LABEL);
                    canAdd = canAdd && AppManager.hasPermission(Permission.MANAGE_LABEL);
                    canDelete = canDelete && AppManager.hasPermission(Permission.MANAGE_LABEL);

                    let inter = get(elem) as unknown as LabelColInterface;
                    let res;
                    res = (
                        <div className="flex gap-2 flex-wrap">
                            <TagList
                                loading={false}
                                canAdd={canAdd}
                                canEdit={canEdit}
                                field={'label'}
                                list={inter.getLabelList()}
                                canDelete={canDelete}
                                parent={elem}
                            ></TagList>
                        </div>
                    );
                    return res;
                }
                return undefined;
            } else {
                throw new Error('LabelColInterface not found in elem');
            }
        };
        this.sort = (a: Type, b: Type) => {
            if (get(a) == null || get(b) == null) {
                return 0;
            }
            if ('LabelColInterface' in get(a)! && 'LabelColInterface' in get(b)!) {
                let aInter = get(a) as unknown as LabelColInterface;
                let bInter = get(b) as unknown as LabelColInterface;
                return -aInter.getLabelList().length + bInter.getLabelList().length;
            } else {
                throw new Error('LabelColInterface not found in a or b');
            }
        };
        const obj = this;
        this.update(cate, selectLabel);
        this.renderFilterModal = () => {
            return <SelectTagListModal field="label" col={obj} />;
        };
        this.filter = (elem: Type) => {
            if (get(elem) == null) {
                return false;
            }
            if ('LabelColInterface' in get(elem)!) {
                let inter = get(elem) as unknown as LabelColInterface;
                let can = obj.filterList.length === 0;
                let i = 0;
                // check if service has Label in elem list of Label row
                while (!can && i < obj.filterList.length) {
                    can =
                        inter
                            .getLabelList()
                            .find((e: Label) => e.getAjoIdentifier() == obj.filterList[i].getAjoIdentifier()) !=
                        undefined;
                    i++;
                }
                return can;
            } else {
                throw new Error('LabelColInterface not found in elem');
            }
        };
        this.typeRender = 'info';
        this.type = 'sort_filter';
        this.senseSort = 0;
    }
    public update(cate: Label[], selectLabel: (cate: Label[]) => void = () => {}) {
        let obj = this;

        this.renderTopRow = () => {
            if (obj.parent != null) {
                if ('LabelColInterface' in obj.parent) {
                    let inter = obj.parent as unknown as LabelColInterface;

                    let canAdd = obj.canAdd && AppManager.hasPermission(Permission.MANAGE_CATEGORY);

                    return (
                        <TagListFetch
                            simple={false}
                            selectList={cate}
                            onSelectList={selectLabel}
                            canAdd={canAdd}
                            field={'label'}
                        />
                    );
                } else {
                    throw new Error('LabelColInterface not found in parent');
                }
            }
        };
    }
}
