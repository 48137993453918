import { CheckBox } from '@mui/icons-material';
import { Card } from '@mui/material';
import { useEffect, useState } from 'react';
import Prestation from '../../object/profile/Prestation';

export interface ISimplePrestationListProps {
    list: Prestation[];
    selectList: Prestation[];
    onSelectList: (list: Prestation[]) => void;
}

export const SelectPrestationList = (props: ISimplePrestationListProps) => {
    const [selected, setSelected] = useState<Prestation[]>(props.selectList);

    useEffect(() => {
        setSelected(props.selectList);
    }, [props.selectList.length]);

    useEffect(() => {
        props.onSelectList(selected);
    }, [selected]);

    return (
        <div className="flex gap-2 flex-wrap">
            {props.list.map((prestation: Prestation, index: number) => {
                return (
                    <div className="border rounded-lg px-2 py-1">
                        <div className="flex items-center gap-2">
                            <input
                                checked={
                                    selected.find(
                                        (item) => item.getAjoIdentifier() === prestation.getAjoIdentifier(),
                                    ) !== undefined
                                }
                                onChange={() => {
                                    if (selected.includes(prestation)) {
                                        setSelected(
                                            selected.filter(
                                                (value: Prestation) =>
                                                    value.getAjoIdentifier() !== prestation.getAjoIdentifier(),
                                            ),
                                        );
                                    } else {
                                        setSelected([...selected, prestation]);
                                    }
                                }}
                                id={prestation.getAjoIdentifier()}
                                type="checkbox"
                                value=""
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label htmlFor={prestation.getAjoIdentifier()} className="text-sm">
                                {prestation.name.get()}
                            </label>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
