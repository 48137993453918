import { Box, TextField, Typography } from '@mui/material';
import { Component, createRef } from 'react';
import Contact from 'src/object/Contact';
import Client from 'src/object/profile/Client';
import AppManager from '../../commun/AppManager';
import { Spinner } from '../../commun/component/Spinner';
import { post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import Vendor from '../../object/profile/Vendor';
import { getErrorMessage } from '../../utils/Error';
import MpModal, { IBaseModalProps } from '../extends/MpModal';

export interface IContactModalProps extends IBaseModalProps {
    contact: Contact | null;
    baseName?: string;
    vendor: Vendor | Client;
    onCreate?: (contact: Contact) => void;
}

export interface IContactModalState {
    name: string;
    email: string;
    loading: boolean;
    error: string;
}

export default class ContactModal extends Component<IContactModalProps, IContactModalState> {
    inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: IContactModalProps) {
        super(props);

        this.state = this.initState();
        this.inputRef = createRef();
    }

    initState() {
        const p: IContactModalState = {
            name: this.props.contact?.name.get() ?? this.props.baseName ?? '',
            email: this.props.contact?.email.get() ?? '',
            loading: false,
            error: '',
        };
        return p;
    }

    componentDidUpdate(
        prevProps: Readonly<IContactModalProps>,
        prevState: Readonly<IContactModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.open !== this.props.open) {
            this.setState(this.initState());
        }
        if (prevProps.open !== this.props.open && this.props.open) {
            setTimeout(() => {
                this.inputRef.current?.focus();
            });
        }
    }

    addPrestation() {
        const { loading, name, email } = this.state;
        const { contact, vendor } = this.props;
        this.setState({
            loading: true,
            error: '',
        });
        let params: any;
        let url: string;
        if (contact === null) {
            url = '/' + vendor.getAjoType().toLowerCase() + '/update';
            params = {
                id: vendor.getAjoIdentifier(),
                contact: {
                    name: name,
                    email: email,
                },
            };
        } else {
            url = '/' + vendor.getAjoType().toLowerCase() + '/update';
            params = {
                id: vendor.getAjoIdentifier(),
                contact: {
                    id: contact.getAjoIdentifier(),
                    name: name,
                    email: email,
                },
            };
        }
        post(url, params).then((res: any) => {
            this.setState({
                loading: false,
            });
            if (Result.isSuccess(res)) {
                Result.applyResult(res, AppManager.updater(), true);

                const p = Contact.build();
                p.setAjoIdentifier(res.result.path3[0].has_vendor_contact[0]._id);
                p.name.set(res.result.path3[0].has_vendor_contact[0].name);
                p.email.set(res.result.path3[0].has_vendor_contact[0].email);
                this.props.onCreate?.(p);

                this.props.onClose();
                if (contact === null) {
                    AppManager.addToast(new Toast('Le contact a été ajouté avec succès.', 'success'));
                } else {
                    AppManager.addToast(new Toast('Le contact a été modifié avec succès.', 'success'));
                }
            } else {
                this.setState({
                    error: getErrorMessage(Result.getErrorStr(res)),
                });
            }
        });
    }

    render() {
        const { open, contact, onClose } = this.props;
        const { loading, email, name, error } = this.state;

        return (
            <MpModal
                open={open}
                onClose={onClose}
                onSubmit={() => {
                    this.addPrestation();
                }}
                title={contact === null ? 'Ajouter un contact' : 'Modifier un contact'}
                done={contact === null ? 'Ajouter' : 'Modifier'}
            >
                {loading ? (
                    <Box className="flex items-center justify-center py-12">
                        <Spinner color="blue" className="" />
                    </Box>
                ) : (
                    <Box className="flex flex-col !text-gray-600 gap-2">
                        <Typography className="!text-sm !font-semibold">NOM DU CONTACT</Typography>
                        <TextField
                            inputProps={{
                                ref: this.inputRef,
                            }}
                            fullWidth
                            value={name}
                            onChange={(e) => {
                                this.setState({ name: (e.target as any)?.value ?? '' });
                            }}
                            placeholder="Nom du contact"
                            variant="outlined"
                        />

                        <Typography className="!text-sm !font-semibold">EMAIL DU CONTACT</Typography>
                        <TextField
                            fullWidth
                            value={email}
                            onChange={(e) => {
                                this.setState({ email: (e.target as any)?.value ?? '' });
                            }}
                            placeholder="Email du contact"
                            variant="outlined"
                        />

                        {error !== '' && (
                            <Typography
                                component={'p'}
                                className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                            >
                                {error}
                            </Typography>
                        )}
                    </Box>
                )}
            </MpModal>
        );
    }
}
