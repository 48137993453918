import { useLocation } from 'react-router-dom';
import AppManager from '../commun/AppManager';
import { Spinner } from '../commun/component/Spinner';
import PageManager from '../commun/PageManager';
import { ToastParentView } from '../commun/toast/ToastParentView';
import { ToastView } from '../commun/toast/ToastView';
import User from '../object/User';
import { Connection } from '../page/Connection';
import { DeletePage } from '../page/DeletePage';
import { PasswordPage } from '../page/PasswordPage';
import { Footer } from './Footer';
import { DashboardLayout } from './nav/DashboardLayout';
import { DashboardNavBar } from './nav/DashboardNavBar';
import { NavBar } from './NavBar';
import ToastManagerView from './toast/ToastManagerView';

export interface IBasePageProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const BasePage: React.FunctionComponent<IBasePageProps> = (props) => {
    const { children, ...rest } = props;

    let content;
    if (!AppManager.isLoad()) {
        content = (
            <>
                <DashboardNavBar />
                <div
                    {...props}
                    className={'bg-slate-200 dark:bg-gray-900 flex-1 flex items-center justify-center absolute inset-0'}
                >
                    {<Spinner color="blue"></Spinner>}
                </div>
            </>
        );
    } else if (!AppManager.isAuthenticated()) {
        content = (
            <>
                <DashboardNavBar />
                <div {...props} className={'min-w-full h-full dark:bg-gray-900 flex items-center justify-center'}>
                    {<Connection basePage={false}></Connection>}
                </div>
            </>
        );
    } else if ((AppManager.getUser() as User)?.delete?.get() ?? false) {
        content = (
            <>
                <DashboardNavBar />
                <div {...props} className="min-w-full h-full dark:bg-gray-900 flex items-center justify-center">
                    <DeletePage />
                </div>
            </>
        );
    } else if (
        ((AppManager.getUser() as User)?.set?.get() ?? false) ||
        ((AppManager.getUser() as User)?.first?.get() ?? false)
    ) {
        content = (
            <>
                <DashboardNavBar />
                <div {...props} className="min-w-full h-full dark:bg-gray-900 flex items-center justify-center">
                    <PasswordPage />
                </div>
            </>
        );
    } else {
        content = (
            <div
                {...props}
                className={
                    'bg-slate-200 h-full dark:bg-gray-900 flex-1 w-full overflow-y-auto  overflow-x-auto ' +
                    props.className
                }
            >
                {children}
            </div>
        );
    }

    return (
        <DashboardLayout>
            {content}
            <ToastManagerView></ToastManagerView>
        </DashboardLayout>
    );
};
