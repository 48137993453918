import { AJOList, AJOUpdater } from 'mp-js-react-auto-json-object';
import { get } from '../../commun/HTTP';
import Result from '../../commun/Result';
import { AgenceModal } from '../../modal/agence/AgenceModal';
import Address from '../../object/Address';
import { Agence } from '../../object/Agence';
import Permission from '../../object/Permission';
import { JSXInternal } from 'preact/src/jsx';
import { IObjectSelectProps, IObjectSelectState, ObjectSelect } from './ObjectSelect';

export interface IAgenceSelectProps extends IObjectSelectProps<Agence> {
    placeholder: string;
    addressAjoList?: AJOList<Address>;
}

export class AgenceSelect extends ObjectSelect<Agence, IAgenceSelectProps> {
    createGenericObject() {
        return Agence;
    }
    constructor(props: IAgenceSelectProps) {
        /**
         * DEFAULT PROPS
         */

        super(props, Permission.MANAGE_AGENCE, 'agence');

        /**
         * CREATE STATE
         */
        this.state = this.initState();

        // FUNCTION USE TO NOTIFY PARENT COMPONENT
        this.state.ajoList.setUpdate(() => this.forceUpdate());
    }

    fetchList() {
        if (this.state.isAjoListInflate) {
            get('/agence/list').then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, this.state.ajoList, true);
                }
                this.setState({
                    isAjoListInflate: true,
                });
            });
        }
    }

    renderModal(): JSXInternal.Element {
        const { addressAjoList } = this.props;
        const { elem } = this.state;
        let obj = this;
        return (
            <AgenceModal
                addressAjoList={addressAjoList}
                elem={elem ?? undefined}
                action={elem == null ? 'add' : 'edit'}
                onObjectChange={(id) => {
                    obj.onElemChange(id);
                }}
                onClose={() => this.setState({ showModal: false })}
                show={this.state.showModal}
                baseCode={this.state.name}
            />
        );
    }

    render(): JSXInternal.Element {
        return super.render();
    }
}
