import { AJOElement, AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { CaretDownFill, CaretRight, CaretUpFill } from 'react-bootstrap-icons';
import Table from './Table';

/*export default class Row {

    name: string;
    render: (a: any) => any;
    type: 'check' | 'sort' | 'string' | 'filter' | 'sort_filter';
    filter: (a: any) => boolean;
    sort: (a: any, b: any) => number;
    top: () => any = ()=>{};
    renderModal: () => any = ()=>{};
    senseSort: 0 | 1 | -1;
    state:AJOState<Table<any>> | undefined;
    table:Table<any> | undefined;
    
    filterListElem:any[] = [];
    selectListElem:AJOObject[] = [];

    showModal: boolean = false;
    visible: boolean = true;
    primary: boolean = false;
    canCoef: boolean = false;
    coef: number = 1;

    startDate : Date | null = null
    endDate : Date | null = null

    score : (a:any,) => number = ()=>{return 0};
    
    constructor(name: string, render: (a: any) => any, type: 'check' | 'filter' | 'sort' | 'string' | 'sort_filter' = 'string', filter: (a: any) => boolean = () => true, sort : (a:any, b:any)=>number = ()=>{return 0}, top:()=>any=()=>{}, senseSort: 0 | 1 | -1 = 0) {
        this.name = name;
        this.render = render;
        this.type = type;
        this.filter = filter;
        this.sort = sort;
        this.top = top;
        this.senseSort = senseSort;
    }

    public isVisible(): boolean {
        return this.visible;
    }

    public setVisible(visible: boolean): Row {
        this.visible = visible;
        if(this.state!=undefined){
            this.state.makeUpdate()
        }
        return this;
    }

    public setFilterListElem(listElem: AJOObject[]) : Row {
        this.filterListElem = listElem;
        if(this.state!=undefined){
            this.state.makeUpdate()
        }
        return this;
    }

    public setSelectListElem(selectListElem: AJOObject[]) : Row {
        this.selectListElem = selectListElem;
        if(this.state!=undefined){
            this.state.makeUpdate()
        }
        return this;
    }

    public hasFilter(): boolean {
        return this.filterListElem.length != 0 || this.startDate!=null || this.endDate!=null;
    }
    public resetFilter() {
        this.filterListElem = [];
        this.setStartDate(null)
        this.setEndDate(null)
        if(this.state!=undefined){
            this.state.makeUpdate();
        }
    }

    public setRenderModal(renderModal: () => any) : Row {
        this.renderModal = renderModal;
        return this;
    }
    public setFilter(filter: (a: any) => boolean) : Row {
        this.filter = filter;
        return this;
    }


    public setState(state: AJOState<Table<any>>) : Row {
        this.state = state;
        return this;
    }

    public openModal(){
        this.showModal = true;
        if(this.state!=undefined){
            this.state.makeUpdate();
        }
    }

    public closeModal(){
        this.showModal = false;
        if(this.state!=undefined){
            this.state.makeUpdate();
        }
    }

    public nextsenseSort() {
        let oldsenseSort = this.senseSort;
        this.table?.resetsenseSort()
        this.senseSort = oldsenseSort;
        if (this.senseSort !== 1){
            this.senseSort++;
        }
        else {
            this.senseSort = -1;
        }
        if(this.state!=undefined){
            this.state.makeUpdate()
        }
    }

    public getsenseSortJsx():JSX.Element{
        let res:JSX.Element;
        if(this.senseSort === 0){
            res = <CaretRight/>
        }
        else if(this.senseSort === 1){
            res = <CaretDownFill/>
        }
        else{
            res = <CaretUpFill/>
        }
        return res;
    }

    public setStartDate(date:Date|null){
        this.startDate = date;
        if(this.state!=undefined){
            this.state.makeUpdate();
        }
    }
    public setEndDate(date:Date|null){
        this.endDate = date;
        if(this.state!=undefined){
            this.state.makeUpdate();
        }
    }
}*/
type Sort = 'check' | 'switch' | 'sort' | 'string' | 'filter' | 'sort_filter' | 'add';
type SenseSort = 0 | 1 | -1;
type TypeRender = 'info' | 'stat' | 'both';

export class Col<Type extends AJOObject> {
    // Name of column
    private _name: string;
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
        this.makeUpdate();
    }

    // Function used to render a element
    private _sumRender: (a: Type[]) => JSX.Element | undefined = () => undefined;
    public get sumRender(): (a: Type[]) => JSX.Element | undefined {
        return this._sumRender;
    }
    public set sumRender(value: (a: Type[]) => JSX.Element | undefined) {
        this._sumRender = value;
        this.makeUpdate();
    }

    // Function used to make action connect to col
    private _act: () => void = () => {};
    public get act(): () => void {
        return this._act;
    }
    public set act(value: () => void) {
        this._act = value;
    }

    // Function used to render a element
    private _render: (a: Type) => JSX.Element | undefined = () => {
        return undefined;
    };
    public get render(): (a: Type) => JSX.Element | undefined {
        return this._render;
    }
    public set render(value: (a: Type) => JSX.Element | undefined) {
        this._render = value;
        this.makeUpdate();
    }

    // Function used to top render a element
    private _superRender: () => JSX.Element | undefined = () => {
        return undefined;
    };
    public get superRender(): () => JSX.Element | undefined {
        return this._superRender;
    }
    public set superRender(value: () => JSX.Element | undefined) {
        this._superRender = value;
        this.makeUpdate();
    }

    // col shoule be skip for sub stection
    private _skip: boolean = false;
    public get skip(): boolean {
        return this._skip;
    }
    public set skip(value: boolean) {
        this._skip = value;
        this.makeUpdate();
    }

    // table that contain this column
    private _table: Table<Type> | undefined;
    public get table(): Table<Type> | undefined {
        return this._table;
    }
    public set table(value: Table<Type> | undefined) {
        this._table = value;
        this.makeUpdate();
    }

    // on what kind of table should this row visible
    private _typeRender: TypeRender = 'both';
    public get typeRender(): TypeRender {
        return this._typeRender;
    }
    public set typeRender(value: TypeRender) {
        this._typeRender = value;
        this.makeUpdate();
    }

    // Type of column
    private _type: Sort = 'string';
    public get type(): Sort {
        return this._type;
    }
    public set type(value: Sort) {
        this._type = value;
    }

    // Function used to filter a col
    // True element will be displayed
    // False element will be hidden
    private _filter: (a: Type) => boolean = () => true;
    public get filter(): (a: Type) => boolean {
        return this._filter;
    }
    public set filter(value: (a: Type) => boolean) {
        this._filter = value;
        this.makeUpdate();
    }

    // Function used to sort a col
    // Return 0 if a = b
    // Return 1 if a > b
    // Return -1 if a < b
    private _sort: (a: Type, b: Type) => number = () => 0;
    public get sort(): (a: Type, b: Type) => number {
        return this._sort;
    }
    public set sort(value: (a: Type, b: Type) => number) {
        this._sort = value;
        this.makeUpdate();
    }

    // Set sense of sort
    // 0 : no sort
    // 1 : sort asc
    // -1 : sort desc
    private _senseSort: SenseSort = 0;
    public get senseSort(): SenseSort {
        return this._senseSort;
    }
    public set senseSort(value: SenseSort) {
        this._senseSort = value;
        this.makeUpdate();
    }

    // State to notify when a change occurs
    private _state: AJOState<Table<Type>> | null = null;
    public get state(): AJOState<Table<Type>> | null {
        return this._state;
    }
    public set state(value: AJOState<Table<Type>> | null) {
        this._state = value;
        this.makeUpdate();
    }

    // Optional list, used to test if elem got all this filter l
    private _filterList: AJOObject[] = [];
    public get filterList(): AJOObject[] {
        return this._filterList;
    }
    public set filterList(value: AJOObject[]) {
        this._filterList = value;
        this.makeUpdate();
    }

    // Optional list, used to test if elem got all this filter l
    private _flexList: any[] = [];
    public get flexList(): any[] {
        return this._flexList;
    }
    public set flexList(value: any[]) {
        this._flexList = value;
        this.makeUpdate();
    }

    // Optional list, of selected element
    private _selectList: Type[] = [];
    public get selectList(): Type[] {
        return this._selectList;
    }
    public set selectList(value: Type[]) {
        this._selectList = value;
        this.makeUpdate();
    }

    // If row can sort by date
    private _startDate: Date | null = null;
    public get startDate(): Date | null {
        return this._startDate;
    }
    public set startDate(value: Date | null) {
        this._startDate = value;
        this.makeUpdate();
    }
    private _endDate: Date | null = null;
    public get endDate(): Date | null {
        return this._endDate;
    }
    public set endDate(value: Date | null) {
        this._endDate = value;
        this.makeUpdate();
    }

    // Coef applied to row
    private _coef: number = 1;
    public get coef(): number {
        return this._coef;
    }
    public set coef(value: number) {
        this._coef = value;
        this.makeUpdate();
    }

    // Row should be shown
    private _visible: boolean = true;
    public get visible(): boolean {
        return this._visible;
    }
    public set visible(value: boolean) {
        this._visible = value;
        this.makeUpdate();
    }

    // If row is primary, it cannot be hidden
    private _primary: boolean = true;
    public get primary(): boolean {
        return this._primary;
    }
    public set primary(value: boolean) {
        this._primary = value;
        this.makeUpdate();
    }

    // function used to calculate the score of a row
    private _score: (a: Type) => number = () => {
        return 0;
    };
    public get score(): (a: Type) => number {
        return this._score;
    }
    public set score(value: (a: Type) => number) {
        this._score = value;
        this.makeUpdate();
    }

    // function used to calculate the score of a row
    private _renderTopRow: () => JSX.Element | undefined = () => {
        return undefined;
    };
    public get renderTopRow(): () => JSX.Element | undefined {
        return this._renderTopRow;
    }
    public set renderTopRow(value: () => JSX.Element | undefined) {
        this._renderTopRow = value;
    }

    // function used to calculate the score of a row
    private _renderBottomRow: () => JSX.Element | undefined = () => {
        return undefined;
    };
    public get renderBottomRow(): () => JSX.Element | undefined {
        return this._renderBottomRow;
    }
    public set renderBottomRow(value: () => JSX.Element | undefined) {
        this._renderBottomRow = value;
    }

    // function to create model to filter
    private _renderFilterModal: () => JSX.Element | undefined = () => {
        return undefined;
    };
    public get renderFilterModal(): () => JSX.Element | undefined {
        return this._renderFilterModal;
    }
    public set renderFilterModal(value: () => JSX.Element | undefined) {
        this._renderFilterModal = value;
    }

    // varibale that indicate if fileter modal is show
    private _showFilterModal: boolean = false;
    public get showFilterModal(): boolean {
        return this._showFilterModal;
    }
    public set showFilterModal(value: boolean) {
        this._showFilterModal = value;
    }

    public openModal() {
        this.showFilterModal = true;
        if (this.state != undefined) {
            this.state.makeUpdate();
        }
    }

    public closeModal() {
        this.showFilterModal = false;
        if (this.state != undefined) {
            this.state.makeUpdate();
        }
    }

    constructor(name: string) {
        this._name = name;
    }

    // Notify state that a change occurs
    private makeUpdate() {
        if (this._state != null && this.table != undefined && !this.table.preventUpdate) {
            this._state.makeUpdate();
        }
    }

    public hasFilter(): boolean {
        return (
            this.flexList.length != 0 || this.filterList.length != 0 || this.endDate != null || this.startDate != null
        );
    }

    public nextSenseSort(): Col<Type> {
        let oldTypeSort = this.senseSort;
        this.table?.resetSenseSort();
        this.senseSort = oldTypeSort;
        if (this.senseSort !== 1) {
            this.senseSort++;
        } else {
            this.senseSort = -1;
        }
        if (this.state != undefined) {
            this.state.makeUpdate();
        }
        return this;
    }

    public getSenseSortJsx(): JSX.Element {
        let res: JSX.Element;
        if (this.senseSort === 0) {
            res = <CaretRight />;
        } else if (this.senseSort === 1) {
            res = <CaretDownFill />;
        } else {
            res = <CaretUpFill />;
        }
        return res;
    }

    public resetFilter(): Col<Type> {
        this.flexList = [];
        this.filterList = [];
        this.startDate = null;
        this.endDate = null;
        this.makeUpdate();
        return this;
    }

    public isVisible(): boolean {
        return this.visible && (this.typeRender === 'both' || this.typeRender === this.table?.type);
    }
}
