import { AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import Table from '../commun/table/Table';
import { TableView } from '../commun/table/TableView';
import Budget from '../object/Budget';
import Service from '../object/Service';
import { DateCol } from '../commun/col/DateCol';
import { Color, Size } from '../commun/Constant';
import { NameCol } from '../commun/col/NameCol';
import { NumCol } from '../commun/col/NumCol';
import { NoteAverageCol } from '../commun/col/NoteAverageCol';
import { ComCol } from '../commun/col/ComCol';
import { IconRow } from '../commun/col/IconRow';
import { ContactCol } from '../commun/col/ContactCol';
import { AddressCol } from '../commun/col/AddressCol';
import { StatCol } from '../commun/col/StatCol';
import Devis from '../object/Devis';
import { CategoryCol } from '../commun/col/CategoryCol';
import { LabelCol } from '../commun/col/LabelCol';
import { TagObject } from '../object/tag/TagObject';
import AppManager from '../commun/AppManager';
import Permission from '../object/Permission';

export interface ITableBudgetListProps {
    budgetList: Budget[];
    size: Size;
    parentObj: Service | Devis;
    parent?: Table<Service> | Table<Devis>;
    bg?: Color;
    loading: boolean;
}

export const TableBudgetList: React.FC<ITableBudgetListProps> = (props) => {
    const { parentObj, budgetList, parent, bg, size, loading } = props;
    let res: any = undefined;

    const makeTable = () => {
        const tableInit = new Table<Budget>('budget');
        tableInit.size = size;
        tableInit.parent = parent as unknown as Table<AJOObject>;

        const dateCol = new DateCol<Budget>(tableInit);
        tableInit.addCol(dateCol);

        if (parentObj instanceof Service) {
            const nameCol = new NameCol<Budget>(
                tableInit,
                false,
                (elem: Budget) => elem.getDevis()?.client.get() ?? null,
                'Client',
            );
            nameCol.senseSort = 0;
            tableInit.addCol(nameCol);

            const nameDevisCol = new NameCol<Budget>(
                tableInit,
                false,
                (elem: Budget) => elem.getDevis() ?? null,
                'Nom prix de vente',
            );
            nameDevisCol.senseSort = 0;
            tableInit.addCol(nameDevisCol);
        } else {
            const nameCol = new NameCol<Budget>(
                tableInit,
                false,
                (elem: Budget) => elem.vendor.get() ?? null,
                'Fournisseur',
                undefined,
                'Aucun fournisseur',
            );
            nameCol.senseSort = 0;
            tableInit.addCol(nameCol);

            const productCol = new NameCol<Budget>(
                tableInit,
                false,
                (elem: Budget) => elem.prestation.get() ?? null,
                'Prestation',
                undefined,
                'Aucune prestation',
            );
            productCol.senseSort = 0;
            tableInit.addCol(productCol);

            const labelCol = new LabelCol<Budget>(
                tableInit,
                AppManager.hasPermission(Permission.MANAGE_LABEL),
                AppManager.hasPermission(Permission.MANAGE_LABEL),
                AppManager.hasPermission(Permission.MANAGE_LABEL),
                (elem: Budget) => elem.service.get() ?? null,
            );
            tableInit.addCol(labelCol);
        }

        const contactCol = new ContactCol<Budget>(tableInit);
        tableInit.addCol(contactCol);

        const addressCol = new AddressCol<Budget>(tableInit);
        tableInit.addCol(addressCol);

        const noteAverageCol = new NoteAverageCol<Budget>(tableInit);
        tableInit.addCol(noteAverageCol);

        const comCol = new ComCol<Budget>(tableInit);
        tableInit.addCol(comCol);

        StatCol.addQuantite(tableInit);
        StatCol.addPU(tableInit);
        StatCol.addStatCol(tableInit);

        const iconCol = new IconRow(tableInit);
        tableInit.addCol(iconCol);

        tableInit.nothingRender = () => {
            if (parentObj instanceof Devis) {
                return (
                    <div className="w-full p-16 text-center">
                        <p className="text-base">Aucune prestation pour ce prix de vente.</p>
                    </div>
                );
            } else {
                return (
                    <div className="w-full p-16 text-center">
                        <p className="text-base">Ce produit n'apparaît sur aucun prix de vente.</p>
                    </div>
                );
            }
        };

        return tableInit;
    };

    const [tableState, updateTable] = useState(new AJOState(makeTable()));
    tableState.setUpdate(updateTable);

    const table = tableState.get()!;

    table.preventUpdate = true;

    table.state = tableState;
    table.loading = loading;

    table.filterTag = (elem: Budget, tag: TagObject) => {
        let res = false;
        if (elem.prestation.get() != null) {
            let categoryList = elem.prestation.get()!.getCategoryList();
            for (const category of categoryList) {
                res = res || tag.getAjoIdentifier() === category.getAjoIdentifier();
            }
        }
        return res;
    };

    if (parentObj instanceof Devis) {
        table.taggedTable = true;
        table.listTag = parentObj.getCategoryList();
    }

    if (table.loading) {
        table.list = [];
    } else {
        table.list = budgetList;
    }
    table.bg = bg;

    table.size = size;
    table.preventUpdate = false;

    return (
        <div>
            <TableView rounded={false} table={table}></TableView>
        </div>
    );
};
