import { DatePicker } from '@mui/x-date-pickers';
import { Box, TextField } from '@mui/material';
import { useState } from 'react';
import Budget from '../../object/Budget';
import Utils from 'src/commun/Utils';
import { useEffect } from 'react';
import BasePrice from 'src/object/BasePrice';

export interface IDateDevisInputProps {
    budget: Budget;
    basePrice: BasePrice;
}

export const DateDevisInput = (props: IDateDevisInputProps) => {
    const { budget, basePrice } = props;

    const [val, setVal] = useState(budget.devis_date.get() ?? null);
    useEffect(() => {
        setVal(budget.devis_date.get() ?? null);
    }, [budget.getAjoIdentifier()]);
    return (
        <Box>
            <DatePicker
                className="flex-1"
                onFocus={() => {
                    budget.getFocus();
                }}
                inputFormat="DD/MM/YYYY"
                renderInput={(params: any) => {
                    return (
                        <TextField
                            variant="outlined"
                            onClick={(e: Event) => {
                                e.stopPropagation();
                            }}
                            {...params}
                            disabled={budget.bon_commande.get() !== null || !basePrice.canAct()}
                            onBlur={() => {
                                if (budget.devis_date.get() === null) {
                                    budget.addEdit({
                                        devis_date: budget.devis_date.get(),
                                    });
                                } else {
                                    budget.addEdit({
                                        devis_date: Utils.isoString(new Date(budget.devis_date.get())),
                                    });
                                }
                            }}
                            tabIndex={-1}
                            className="bg-gray-300 w-48 placeholder:text-gray-600 !border-gray-600 border rounded-lg py-2 px-3 focus:!border focus:!ring focus:!ring-blue-500 focus:!border-blue-500"
                        />
                    );
                }}
                onChange={(e: any) => {
                    let newVal;
                    if (e !== null) {
                        newVal = (e as any).toDate();
                        newVal.setTime(newVal.getTime() - newVal.getTimezoneOffset() * 60 * 1000);
                        try {
                            budget.addEdit({
                                devis_date: newVal.toISOString(),
                            });
                            setVal(newVal);
                        } catch (e) {}
                    } else {
                        newVal = null;
                        budget.addEdit({
                            devis_date: '',
                        });
                        setVal(newVal);
                    }
                    budget.devis_date.set(newVal);
                }}
                value={val}
            />
        </Box>
    );
};
