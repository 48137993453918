import { AJOList, AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import AppManager from '../../commun/AppManager';
import { JSXInternal } from 'preact/src/jsx';
import { PerfectAJOList } from '../../commun/object/PerfectAJOList';
import Budget from '../../object/Budget';
import Vendor from '../../object/profile/Vendor';
import VendorModal from '../vendor/VendorModal';
import { CustomSelect } from './CustomSelect';
import React from 'preact/compat';
import AddLabelModal from '../label/AddLabelModal';
import Label from 'src/object/tag/Label';

export interface ILabelSelectProps {
    selectLabel: (label: Label | null) => void;
    label: Label | null;
}

export const LabelSelect = (props: ILabelSelectProps) => {
    const [labelList, setLabelList] = useState(
        new AJOState(
            new PerfectAJOList(
                '/label/list',
                () => {
                    return {};
                },
                Label,
                undefined,
            ),
        ),
    );
    labelList.setUpdate(setLabelList);

    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');

    return (
        <>
            <CustomSelect
                list={labelList.get()!.getList()}
                id={'label-select'}
                className={'w-full'}
                value={null}
                getValue={(elem: any) => elem.name.get() ?? ''}
                placeholder="Label"
                onBlur={() => {}}
                onFocus={() => {
                    setName('');
                    labelList.get()!.need();
                }}
                onCreate={(str: string) => {
                    setName(str);
                    setOpen(true);
                }}
                onSelect={(item: any) => {
                    if (item instanceof Label) {
                        props.selectLabel(item);
                    } else {
                        props.selectLabel(null);
                    }
                }}
            />
            <AddLabelModal
                baseName={name}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                onCreate={(label: Label) => {
                    props.selectLabel(label);
                    AppManager.makeUpdate();
                }}
            />
        </>
    );
};
