import { Typography } from '@mui/material';
import { PerfectTable } from 'src/commun/table/PerfectTable';
import { EuroSpan } from '../../commun/component/EuroSpan';
import { PourcentSpan } from '../../commun/component/PourcentSpan';
import Devis from '../../object/Devis';

interface ITableHoraireDevisListProps {
    list: Devis[];
    color: string;
    loading: boolean;
}

export const TableHoraireDevisStatList: React.FC<ITableHoraireDevisListProps> = (props) => {
    const { list, color, loading } = props;
    return (
        <PerfectTable
            list={list}
            loading={loading}
            color={color as any}
            columns={[
                {
                    name: 'Achat',
                    render: (d: Devis) => {
                        return (
                            <Typography>
                                <EuroSpan value={d.achat()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'PV HT',
                    render: (d: Devis) => {
                        return (
                            <Typography>
                                <EuroSpan value={d.prixVenteHT()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'Marge',
                    render: (d: Devis) => {
                        return (
                            <Typography>
                                <EuroSpan value={d.marge()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'Taux MB',
                    render: (d: Devis) => {
                        return (
                            <Typography>
                                <PourcentSpan value={d.va()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'Minutes estimées',
                    render: (d: Devis) => {
                        return (
                            <Typography>
                                <span>{d.minute().toFixed(2)} m</span>
                            </Typography>
                        );
                    },
                },
                {
                    name: 'Minutes engagées',
                    render: (d: Devis) => {
                        return (
                            <Typography>
                                <span>{d.newJourTimer().toFixed(2)} m</span>
                            </Typography>
                        );
                    },
                },
                {
                    name: 'Minutes disponibles',
                    render: (d: Devis) => {
                        return (
                            <Typography>
                                <span>{d.minuteEcart().toFixed(2)} m</span>
                            </Typography>
                        );
                    },
                },
                {
                    name: 'Écart',
                    render: (d: Devis) => {
                        return (
                            <Typography>
                                <EuroSpan value={d.ecart()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'MB/H estimée',
                    render: (d: Devis) => {
                        return (
                            <Typography>
                                <EuroSpan value={d.mbHEstime()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'MB/H engagée',
                    render: (d: Devis) => {
                        return (
                            <Typography>
                                <EuroSpan value={d.mbHReel()} />
                            </Typography>
                        );
                    },
                },
            ]}
        />
    );
};
