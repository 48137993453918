import { AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Template from '../object/Template';
import { BasePricePage } from './BasePricePage';

export function TemplatePage() {
    const { id } = useParams();

    const [stateTemplate, setStateTemplate] = useState(new AJOState<Template>(Template.build()));
    stateTemplate.setUpdate(setStateTemplate);

    return <BasePricePage id={id} basePriceState={stateTemplate} />;
}
