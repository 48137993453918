import { AJOInstance, AJOList, AJOObject } from 'mp-js-react-auto-json-object';
import React from 'react';
import AppManager from '../../commun/AppManager';
import { ComplexInput } from '../../commun/component/ComplexInput';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import { JSXInternal } from 'preact/src/jsx';
import Utils from '../../commun/Utils';
import Address from '../../object/Address';
import { IModalProps } from './IModalProps';
import { IModalState } from './IModalState';
import { addressAddSuccess, addressEditSuccess, errorManager } from '../vendor/CategoryErrorManager';
import MpModal from 'src/component/extends/MpModal';
import { Box, Typography } from '@mui/material';
import { Spinner } from 'src/commun/component/Spinner';

export interface IActionModalProps<T extends AJOObject> extends IModalProps {
    onObjectChange: (id: string) => void;
    baseName?: string;
    elem?: T;
    placeholder?: string;
    action: 'add' | 'edit';
}
export interface IActionModalState extends IModalState {}

export abstract class ActionModal<
    T extends AJOObject,
    P extends IActionModalProps<T>,
    S extends IActionModalState,
> extends React.Component<P, S> {
    private successAddError: ErrorHTTP;
    private successEditError: ErrorHTTP;

    constructor(props: P, successAddError: ErrorHTTP, successEditError: ErrorHTTP) {
        /**
         * DEFAULT PROPS
         */

        super(props);
        this.successAddError = successAddError;
        this.successEditError = successEditError;
        /**
         * CREATE STATE
         * */
        this.state = this.initState();
    }

    abstract contentModal(): JSXInternal.Element[] | JSXInternal.Element;

    abstract initState(): S;

    componentDidUpdate(
        prevProps: Readonly<IActionModalProps<T>>,
        prevState: Readonly<IActionModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.show !== this.props.show) {
            if (this.props.show) {
                Utils.blur();

                this.setState(this.initState());
            }
        }
    }

    getSuccess() {
        const { action } = this.props;

        let res: ErrorHTTP;

        if (action === 'add') {
            res = this.successAddError;
        } else {
            res = this.successEditError;
        }

        return res;
    }

    getSendParams(): { [key: string]: any } {
        const { action } = this.props;

        let params: { [key: string]: any } = this.state;

        if (action === 'edit') {
            params['id'] = this.props.elem!.getAjoIdentifier();
        }

        return params;
    }

    abstract getUrl(): string;

    abstract createGeneric(): new (...args: any) => T;

    makeAction(): void {
        const { action } = this.props;

        this.setState({
            loading: true,
            error: ErrorHTTP.NO_ERROR(),
        });

        let params = this.getSendParams();

        post(this.getUrl(), params).then((res: any) => {
            this.setState({
                loading: false,
            });

            if (Result.isSuccess(res)) {
                Result.applyResult(res, AppManager.updater(), true);

                let address = new (this.createGeneric())();
                Result.applyResult(res, address, true);
                this.props.onObjectChange(address.getAjoIdentifier());

                this.props.onClose();

                let errorSuccess = this.getSuccess();
                AppManager.addToast(new Toast(errorManager.get(errorSuccess), 'success'));
            } else {
                this.setState({
                    error: Result.getError(res),
                });
            }
        });
    }

    getTitle(): string {
        const { action } = this.props;
        let res: string;
        if (action === 'add') {
            res = 'Ajouter';
        } else {
            res = 'Modifier';
        }
        return res;
    }

    getDone(): string {
        const { action } = this.props;
        let res: string;
        if (action === 'add') {
            res = 'Ajouter';
        } else {
            res = 'Modifier';
        }
        return res;
    }

    render(): JSXInternal.Element {
        const { loading, error } = this.state;
        const { show, onClose } = this.props;

        /*
            loading={loading}
            error={error}
            errorManager={errorManager}
        */

        return (
            <MpModal
                title={this.getTitle()}
                done={this.getDone()}
                open={show}
                onSubmit={() => {
                    this.makeAction();
                }}
                onClose={onClose}
            >
                {loading ? (
                    <Box className="flex items-center justify-center p-10">
                        <Spinner color="blue" />
                    </Box>
                ) : (
                    <Box className="flex flex-col gap-3">
                        {this.contentModal()}
                        {!error.equals(ErrorHTTP.NO_ERROR()) && (
                            <Typography
                                component={'p'}
                                className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                            >
                                {errorManager.get(error)}
                            </Typography>
                        )}
                    </Box>
                )}
            </MpModal>
        );
    }
}
