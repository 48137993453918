import { Box, TextField, Typography } from '@mui/material';
import { Component, createRef } from 'react';
import { ComplexInput } from 'src/commun/component/ComplexInput';
import Budget from 'src/object/Budget';
import AppManager from '../../commun/AppManager';
import { Spinner } from '../../commun/component/Spinner';
import { post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import Vendor from '../../object/profile/Vendor';
import { getErrorMessage } from '../../utils/Error';
import MpModal, { IBaseModalProps } from '../extends/MpModal';

export interface IPriceModalProps extends IBaseModalProps {
    budget: Budget;
    baseName?: string;
}

export interface IPriceModalState {
    price: string;
    loading: boolean;
    error: string;
}

export default class PriceModal extends Component<IPriceModalProps, IPriceModalState> {
    inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: IPriceModalProps) {
        super(props);

        this.state = this.initState();
        this.inputRef = createRef();
    }

    initState() {
        const p: IPriceModalState = {
            price: (this.props.budget?.prixVenteHT() ?? 0) + '',
            loading: false,
            error: '',
        };
        return p;
    }

    componentDidUpdate(
        prevProps: Readonly<IPriceModalProps>,
        prevState: Readonly<IPriceModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.open !== this.props.open) {
            this.setState(this.initState());
        }
        if (prevProps.open !== this.props.open && this.props.open) {
            setTimeout(() => {
                this.inputRef.current?.focus();
            });
        }
    }

    addPrestation() {
        const { loading, price } = this.state;
        const { budget } = this.props;
        if (parseFloat(price) < budget.achat()) {
            this.setState({
                error: "Le prix de vente doit être supérieur au prix d'achat",
            });
        } else if (parseFloat(price) === 0) {
            budget.valeur_flat.set(0);
            budget.addEdit({
                valeur_flat: 0,
            });
            budget.valeur_flat.set(0);
            this.props.onClose();
        } else {
            let va_flat = (parseFloat(price) / budget.quantity.get() / budget.price.get() - 1) * 100;
            if (budget.valeur.get()) {
                budget.addEdit({
                    valeur: '-1',
                    valeur_flat: va_flat,
                });
                budget.valeur.set(null);
                budget.valeur_flat.set(va_flat);
            } else {
                budget.addEdit({
                    valeur_flat: va_flat,
                });
                budget.valeur_flat.set(va_flat);
            }
            this.props.onClose();
        }
        /*this.setState({
            loading: true,
            error: '',
        });
        let params: any;
        let url: string;
        if (vendor === null) {
            url = '/vendor/update';
            params = {
                name: name,
            };
        } else {
            url = '/vendor/update';
            params = {
                id: vendor.getAjoIdentifier(),
                name: name,
            };
        }
        if (vendor !== null) {
            params['id'] = vendor.getAjoIdentifier();
        }
        post(url, params).then((res:any) => {
            this.setState({
                loading: false,
            });
            if (Result.isSuccess(res)) {
                Result.applyResult(res, AppManager.updater(), true);

                const p = Vendor.build();
                Result.applyResult(res, p, true);
                this.props.onCreate?.(p);

                this.props.onClose();
                if (vendor === null) {
                    AppManager.addToast(new Toast('Le fournisseur a été ajouté avec succès.', 'success'));
                } else {
                    AppManager.addToast(new Toast('Le fournisseur a été modifié avec succès.', 'success'));
                }
            } else {
                this.setState({
                    error: getErrorMessage(Result.getErrorStr(res)),
                });
            }
        });*/
    }

    render() {
        const { open, budget, onClose } = this.props;
        const { loading, price, error } = this.state;

        return (
            <MpModal
                open={open}
                onClose={onClose}
                onSubmit={() => {
                    this.addPrestation();
                }}
                title={'Choisir le prix de vente'}
                done={'Choisir'}
            >
                {loading ? (
                    <Box className="flex items-center justify-center py-12">
                        <Spinner color="blue" className="" />
                    </Box>
                ) : (
                    <Box className="flex flex-col !text-gray-600 gap-2">
                        <Typography className="!text-sm !font-semibold">PRIX DE VENTE</Typography>

                        <ComplexInput
                            disabled={false}
                            value={price + ''}
                            typeValue="eur"
                            setValue={(e) => {
                                this.setState({ price: e + '' });
                                AppManager.makeUpdate();
                            }}
                            placeholder="Prix de vente"
                            className="bg-gray-300 w-32 placeholder:text-gray-600 border-gray-400 border rounded-lg py-2 px-3 focus:!border focus:!ring focus:!ring-blue-500 focus:!border-blue-500"
                        />

                        {error !== '' && (
                            <Typography
                                component={'p'}
                                className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                            >
                                {error}
                            </Typography>
                        )}
                    </Box>
                )}
            </MpModal>
        );
    }
}
