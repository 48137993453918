import { AJOUpdater } from 'mp-js-react-auto-json-object';
import { AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { JSXInternal } from 'preact/src/jsx';
import { NavigateFunction } from 'react-router-dom';
import User from '../object/User';
import Toast from './toast/Toast';

export default class AppManager {
    private updater: AJOUpdater = new AJOUpdater();

    public static TOAST_TIME: number = 3000;

    public static CTRL_LEFT_KEY: string = 'ControlLeft';
    public static SHIFT_LEFT_KEY: string = 'ShiftLeft';
    public static ALT_LEFT_KEY: string = 'AltLeft';
    public static UP_KEY: string = 'ArrowUp';
    public static DOWN_KEY: string = 'ArrowDown';
    public static TAB_KEY: string = 'Tab';
    public static ENTER_KEY: string = 'Enter';
    public static ESCAPE_KEY: string = 'Escape';

    public globalCallBack: (res: any) => void = () => {};
    public openSideBar: () => void = () => {};

    private static instance: AppManager;

    private toastList: Toast[];
    private user: AJOState<AJOObject> | null;
    public onEnter: (() => void) | null = null;

    private keyDic: { [key: string]: Date } = {};

    private navigate: NavigateFunction | null;

    private loading: boolean;

    private authenticated: boolean;
    private updateAuthenticated: React.StateUpdater<boolean> | null;

    private constructor() {
        this.user = null;

        this.toastList = [];

        this.loading = false;

        this.authenticated = false;
        this.updateAuthenticated = null;

        this.navigate = null;
    }

    public static startUpdater() {
        AppManager.get().updater = new AJOUpdater();
        if (AppManager.getUser() instanceof User) {
            AppManager.get().updater.add(AppManager.getUser()! as User);
        }
    }

    public static updater(): AJOUpdater {
        return AppManager.get().updater;
    }

    public static nowLoad() {
        AppManager.get().loading = true;
    }
    public static isLoad() {
        return AppManager.get().loading;
    }

    public static init() {
        AppManager.instance = new AppManager();
    }

    public static setUserState(user: AJOState<AJOObject>) {
        AppManager.instance.user = user;
    }
    public static getUser(): AJOObject | null | undefined {
        return AppManager.instance.user?.get();
    }
    public static setUser(user: AJOObject | null) {
        AppManager.instance.user?.set(user);
    }

    public static get(): AppManager {
        if (!AppManager.instance) {
            throw new Error('AppManager not initialized');
        }
        return AppManager.instance;
    }
    public static setNavigate(navigate: NavigateFunction) {
        this.navigate = navigate;
    }
    public static navigate(to: string) {
        AppManager.get().navigate!(to);
    }
    public static removeToast(toast: Toast): void {
        let index = AppManager.get().toastList.indexOf(toast);
        if (index != -1) {
            AppManager.get().toastList.splice(index, 1);
            AppManager.makeUpdate();
        }
    }
    public static addToast(toast: Toast) {
        AppManager.get().toastList.push(toast);
        AppManager.get().user?.makeUpdate();
    }
    public static getToastList(): Toast[] {
        return AppManager.get().toastList;
    }
    public static makeUpdate() {
        AppManager.get().user?.makeUpdate();
    }

    public static isPress(code: string): boolean {
        return AppManager.get().keyDic[code]?.getTime() > 0;
    }

    public static getDatePress(code: string): number {
        return AppManager.get().keyDic[code]?.getTime() ?? -1;
    }

    public static isCtrlPress(): boolean {
        return AppManager.isPress(AppManager.CTRL_LEFT_KEY);
    }

    public static isShiftPress(): boolean {
        return AppManager.isPress(AppManager.SHIFT_LEFT_KEY);
    }

    public static isAltPress(): boolean {
        return AppManager.isPress(AppManager.ALT_LEFT_KEY);
    }

    public static isUpPress(): boolean {
        return AppManager.isPress(AppManager.UP_KEY);
    }

    public static isDownPress(): boolean {
        return AppManager.isPress(AppManager.DOWN_KEY);
    }

    public static isAuthenticated(): boolean {
        return AppManager.get().authenticated;
    }
    public static setAuthenticated(authenticated: boolean) {
        AppManager.get().authenticated = authenticated;
        AppManager.get().updateAuthenticated!(authenticated);
    }
    public static setUpdateAuthenticated(updateAuthenticated: React.StateUpdater<boolean>): void {
        AppManager.get().updateAuthenticated = updateAuthenticated;
    }
    public static setup(document: Document): void {
        document.onkeydown = (e: KeyboardEvent) => {
            if ((e.target as any).type !== 'textarea' && e.code === 'Enter' && AppManager.get().onEnter !== null) {
                AppManager.get().onEnter!();
            }
            AppManager.get().keyDic[e.code + ''] = new Date();
        };
        document.onkeyup = (e: KeyboardEvent) => {
            delete AppManager.get().keyDic[e.code + ''];
        };
    }

    public static onKeyUp(e: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>): void {
        delete AppManager.get().keyDic[e.code + ''];
    }

    public static onKeyDown(e: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>): void {
        AppManager.get().keyDic[e.code] = new Date();
    }

    public static hasPermission(action: number) {
        return (AppManager.getUser() as User)?.hasPermission(action, false) ?? false;
    }
}
