import { AJOList, AJOObject, AJOProperties, AJOSimple, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import AppManager from '../commun/AppManager';
import { AddressColInterface } from '../commun/col/AddressCol';
import { CategoryColInterface } from '../commun/col/CategoryCol';
import { ComColInterface } from '../commun/col/ComCol';
import { ContactColInterface } from '../commun/col/ContactCol';
import { DateColInterface } from '../commun/col/DateCol';
import { NoteAverageColInterface } from '../commun/col/NoteAverageCol';
import { post } from '../commun/HTTP';
import Result from '../commun/Result';
import Address from './Address';
import BasePrice from './BasePrice';
import { BonCommande } from './BonCommande';
import { CategoryHonoraire } from './CategoryHonoraire';
import { Comptable } from './Comptable';
import Contact from './Contact';
import Devis from './Devis';
import { Honoraire } from './Honoraire';
import Note from './Note';
import { Paper } from './Paper';
import Prestation from './profile/Prestation';
import Vendor from './profile/Vendor';
import Service from './Service';
import { TableRowInterface } from './interface/TableRowInterface';
import Category from './tag/Category';
import Taxe from './Taxe';
import User from './User';
import Valeur from './Valeur';
import { TableRowNextInterface } from './interface/TableRowNextInterface';
import Nego from './Nego';
import { TagObject } from './tag/TagObject';
import React from 'preact/compat';
import { MutableRef } from 'preact/hooks';
import Toast from 'src/commun/toast/Toast';
import { addView, removeView } from 'src/component/ActionManagerView';
import { addActivityView, removeActivityView } from 'src/component/ActivityPointerView';

export default class Budget
    extends AJOObject
    implements
        TableRowInterface,
        Comptable,
        CategoryColInterface,
        ContactColInterface,
        DateColInterface,
        NoteAverageColInterface,
        ComColInterface,
        AddressColInterface,
        TableRowNextInterface
{
    public rowState: AJOState<Budget> | null = null;

    public Comptable: boolean = true;

    public TableRowNextInterface = true;
    public AddressColInterface = true;
    public DateColInterface = true;
    public CategoryColInterface = true;
    public NoteAverageColInterface = true;
    public ComColInterface = true;
    public ContactColInterface = true;
    public TableRowInterface = true;

    public next: Budget | null = null;

    public static _TYPE: string = 'Budget';

    public comment: AJOProperties<any>;
    public price: AJOProperties<any>;
    public quantity: AJOProperties<any>;
    public date: AJOProperties<any>;
    public email: AJOProperties<any>;
    public lock: AJOProperties<any>;
    public rounded: AJOProperties<any>;
    public typeRounded: AJOProperties<any>;
    public option: AJOProperties<any>;
    public free: AJOProperties<any>;
    public date_now: AJOProperties<any>;
    public devis_date: AJOProperties<any>;
    public livraison_date: AJOProperties<any>;
    public livraison_date_end: AJOProperties<any>;
    public prestation: AJOSimple<Prestation>;
    public vendor: AJOSimple<Vendor>;
    public user: AJOSimple<User>;
    public nb_personnel: AJOProperties<any>;
    public alias: AJOProperties<any>;
    public ref_devis: AJOProperties<any>;
    public choice: AJOProperties<any>;

    public forceEdit: boolean = false;

    public taxe: AJOSimple<Taxe>;
    public bon_commande: AJOSimple<BonCommande>;
    public taxe_vendor: AJOSimple<Taxe>;

    public valeur: AJOSimple<Valeur>;
    public valeur_flat: AJOProperties<any>;

    public nego: AJOSimple<Nego>;
    public nego_flat: AJOProperties<any>;

    public address: AJOSimple<Address>;
    public paper: AJOSimple<Paper>;
    public contact: AJOSimple<Contact>;
    public honoraire: AJOSimple<Honoraire>;
    public noteList: AJOList<Note>;

    public lock_quantity: AJOProperties<any>;

    public static ROUND_MIN = 2;
    public static ROUND_MAX = 1;
    public static ROUND_MATH = 0;

    public isLock(field: string) {
        if (field === 'vendor') {
            return this.lock_vendor.get();
        } else if (field === 'prestation') {
            return this.lock_prestation.get();
        } else if (field === 'valeur') {
            return this.lock_valeur.get();
        } else if (field === 'honoraire') {
            return this.lock_honoraire.get();
        } else if (field === 'contact') {
            return this.vendor.get() == null;
        } else if (field === 'facturation') {
            return this.vendor.get() == null;
        } else if (field === 'taxe') {
            return this.lock_taxe.get();
        } else if (field === 'price') {
            return this.lock_price.get();
        } else if (field === 'quantity') {
            return this.lock_quantity.get();
        } else if (field === 'nb_personnel') {
            return this.lock_nb_personnel.get();
        } else {
            return false;
        }
    }
    public lockAnySend(paper: AJOObject, field: string) {
        let obj = this;

        let param: any = {
            id: this.getAjoIdentifier(),
        };
        param[field] = !((obj as any)[field].get() ?? false);

        this.actionList.push({ 'AppManager.updater()': AppManager.updater(), paper: paper, action: param });

        this.rowState?.makeUpdate();

        this.makeAction();

        // (obj as any)[field(!((obj as any)[field].get()??false));
        AppManager.updater().makeUpdate();
    }
    public lockQuantitySend(paper: AJOObject) {
        this.lockAnySend(paper, 'lock_quantity');
    }
    public lock_nb_personnel: AJOProperties<any>;
    public lockNbPersonnelSend(paper: AJOObject) {
        this.lockAnySend(paper, 'lock_nb_personnel');
    }
    public lock_price: AJOProperties<any>;
    public lockPriceSend(paper: AJOObject) {
        this.lockAnySend(paper, 'lock_price');
    }
    public lock_vendor: AJOProperties<any>;
    public lockTempVendorSend(paper: AJOObject) {
        this.lockAnySend(paper, 'lock_vendor');
    }
    public lock_prestation: AJOProperties<any>;
    public lockTempPrestationSend(paper: AJOObject) {
        this.lockAnySend(paper, 'lock_prestation');
    }
    public lock_valeur: AJOProperties<any>;
    public lockValeurSend(paper: AJOObject) {
        this.lockAnySend(paper, 'lock_valeur');
    }
    public lock_honoraire: AJOProperties<any>;
    public lockHonnoraireSend(paper: AJOObject) {
        this.lockAnySend(paper, 'lock_honoraire');
    }
    public lock_taxe: AJOProperties<any>;
    public lockTaxeSend(paper: AJOObject) {
        this.lockAnySend(paper, 'lock_taxe');
    }

    public service: AJOSimple<Service>;

    constructor() {
        super(Budget._TYPE);

        this.comment = new AJOProperties('comment', this);

        this.lock_quantity = new AJOProperties('lock_quantity', this);
        this.lock_nb_personnel = new AJOProperties('lock_nb_personnel', this);
        this.lock_price = new AJOProperties('lock_price', this);
        this.lock_vendor = new AJOProperties('lock_vendor', this);
        this.lock_prestation = new AJOProperties('lock_prestation', this);
        this.lock_valeur = new AJOProperties('lock_valeur', this);
        this.lock_honoraire = new AJOProperties('lock_honoraire', this);
        this.lock_taxe = new AJOProperties('lock_taxe', this);

        this.choice = new AJOProperties('choice');
        this.email = new AJOProperties('email');
        this.price = new AJOProperties('price');
        this.rounded = new AJOProperties('rounded');
        this.typeRounded = new AJOProperties('type_rounded');
        this.alias = new AJOProperties('alias');
        this.quantity = new AJOProperties('quantity');
        this.date = new AJOProperties('date');
        this.lock = new AJOProperties('lock');
        this.nb_personnel = new AJOProperties('nb_personnel');
        this.option = new AJOProperties('option');
        this.free = new AJOProperties('free');
        this.date_now = new AJOProperties('date_now');
        this.ref_devis = new AJOProperties('ref_devis');

        this.prestation = new AJOSimple(Prestation, 'temp_prestation', this);
        this.vendor = new AJOSimple(Vendor, 'temp_vendor', this);
        this.user = new AJOSimple(User, 'temp_user', this);

        this.devis_date = new AJOProperties('devis_date');
        this.livraison_date_end = new AJOProperties('livraison_date_end');
        this.livraison_date = new AJOProperties('livraison_date');

        this.paper = new AJOSimple(Paper, 'has_budget', this);
        this.taxe = new AJOSimple(Taxe, 'has_taxe', this);
        this.bon_commande = new AJOSimple(BonCommande, 'has_bon_commande', this);
        this.taxe_vendor = new AJOSimple(Taxe, 'has_taxe_vendor', this);
        this.address = new AJOSimple(Address, 'has_budget_address', this);

        this.valeur = new AJOSimple(Valeur, 'has_valeur', this);
        this.valeur_flat = new AJOProperties('valeur_flat', this);

        this.nego = new AJOSimple(Nego, 'has_nego', this);
        this.nego_flat = new AJOProperties('nego_flat', this);

        this.honoraire = new AJOSimple(Honoraire, 'has_honoraire', this);
        this.service = new AJOSimple(Service, 'has_promesse', this);
        this.contact = new AJOSimple(Contact, 'has_budget_contact', this);

        this.noteList = new AJOList<Note>(Note, 'has_critique', this);
    }

    public getDate(): string | undefined {
        return this.livraison_date.get();
    }

    public static build(): Budget {
        return new Budget();
    }
    public isAverageNote(): boolean {
        return !isNaN(this.getAverageNote());
    }
    public getAverageNote(): number {
        let sum = 0;
        let count = 0;
        for (let note of this.noteList.getList()) {
            if (note.rate.get() !== undefined) {
                sum += note.rate.get();
                count += 1;
            }
        }
        return sum / count;
    }

    public quantite(): number {
        if (this.quantity.get() === undefined) {
            return 0;
        } else {
            return parseFloat(this.quantity.get());
        }
    }

    private static PRICE_MINUTE: number = 88.0 / 60.0;

    public prixUnitaire(): number {
        if (this.choice.get() === undefined || this.choice.get() === true) {
            if (this.price.get() === undefined) {
                return 0;
            } else {
                return parseFloat(this.price.get());
            }
        } else {
            return 0;
        }
    }
    public prixVenteUnitaire(): number {
        return this.price.get() * (1 + this.va() / 100);
    }
    public getDevis(): Devis | null {
        return this.paper.get()?.getDevis() ?? null;
    }

    public countMinute(): number {
        let res = 1;
        if (this.nb_personnel.get() !== undefined) {
            res = parseFloat(this.nb_personnel.get());
        }
        return res * this.quantite();
    }

    public achatBef(): number {
        return this.countMinute() * this.prixUnitaire();
    }

    public achat(): number {
        if (this.getPaper() == null || this.getPaper()!.func.get() === 0) {
            return this.prixUnitaire() * (this.quantity.get() ?? 0);
        } else {
            return 0;
        }
    }

    public va(): number {
        let res: number = 0;
        if (this.valeur.get() == null) {
            if (this.valeur_flat.get() === undefined) {
                if (this.nego.get() == null) {
                    res = this.nego_flat.get() ?? 0;
                } else {
                    res = this.nego.get()?.pourcent.get() ?? 0;
                }
            } else {
                res = this.valeur_flat.get() ?? 0;
            }
        } else {
            res = this.valeur.get()?.pourcent.get() ?? 0;
        }
        return res;
    }
    public prixVenteHT(): number {
        let res: number = 0;
        if (this.free.get() === true) {
            return 0;
        }
        if (this.getPaper() == null || this.getPaper()!.func.get() === 0) {
            res = (this.achat() * (this.va() + 100)) / 100;
        } else {
            res = (this.achatBef() * (this.va() + 100)) / 100;
        }

        if (this.rounded.get() !== 0 && this.rounded.get() !== undefined) {
            let pow = Math.pow(10, this.rounded.get() + 2);

            if (this.typeRounded.get() === Budget.ROUND_MATH) {
                res = Math.round(res * pow) / pow;
            } else if ((this.typeRounded.get() ?? Budget.ROUND_MAX) === Budget.ROUND_MAX) {
                res = Math.ceil(res * pow) / pow;
            } else if (this.typeRounded.get() === Budget.ROUND_MIN) {
                res = Math.floor(res * pow) / pow;
            }
        } else {
            res = res;
        }

        if (isNaN(res)) {
            res = 0;
        }
        return res;
    }
    public tax(): number {
        return this.taxe.get()?.pourcent.get() ?? 0;
    }
    public prixVenteTTC(): number {
        return this.prixVenteHT() * (this.taxe.get()?.pourcentMath() ?? 1);
    }
    public getNoteList(): Note[] {
        return this.noteList.getList();
    }
    public getContact(): Contact | null {
        return this.contact.get();
    }
    public getAddress(): Address | null {
        return this.address.get();
    }

    private actionList: any[] = [];
    private start = false;

    private _focus = false;
    public get focus() {
        return this._focus;
    }
    public set focus(value) {
        this._focus = value;
    }

    private _indexFocus: number = -1;
    public get indexFocus() {
        return this._indexFocus;
    }
    public set indexFocus(value) {
        this._indexFocus = value;
    }

    public addAction(indexFocus: number, paper: AJOObject, action: any) {
        const id = new Date().getTime();
        this.actionList.push({ paper: paper, action: action, id: id });

        this.rowState?.makeUpdate();

        this.makeAction();

        addView(id);
        addActivityView(id, {
            ...action,
            id,
        });
    }

    public addEdit(action: any) {
        const id = new Date().getTime();
        this.actionList.push({ paper: this.getAjoParent() as Paper, action: action, id: id });

        this.rowState?.makeUpdate();

        this.makeAction();

        addView(id);
        addActivityView(id, {
            ...action,
            id,
        });
    }

    public static getCategoryBonCommandeList(list: Budget[]): TagObject[] {
        let categoryList: Category[] = [];
        for (let budget of list) {
            let budgetCategoryList = budget.getCategoryList();

            if (budget.bon_commande.get() != null) {
                let bc = budget.bon_commande.get()!;

                if (!categoryList.find((c: TagObject) => c.getAjoIdentifier() === bc.getAjoIdentifier())) {
                    categoryList.push(bc);
                }
            } else if (budgetCategoryList.length > 0) {
                for (let category of budgetCategoryList) {
                    if (!categoryList.find((c: Category) => c.getAjoIdentifier() === category.getAjoIdentifier())) {
                        categoryList.push(category);
                    }
                }
            }
        }
        return categoryList;
    }

    public static getCategoryList(list: Budget[]): Category[] {
        let categoryList: Category[] = [];
        for (let budget of list) {
            let budgetCategoryList = budget.getCategoryList();

            if (budgetCategoryList.length > 0) {
                for (let category of budgetCategoryList) {
                    if (!categoryList.find((c: Category) => c.getAjoIdentifier() === category.getAjoIdentifier())) {
                        categoryList.push(category);
                    }
                }
            }
        }
        return categoryList;
    }

    public getCategoryList(): Category[] {
        if ((this.prestation.get()?.getCategoryList() ?? []).length === 0) {
            return this.service.get()?.getCategoryList() ?? [];
        } else {
            return this.prestation.get()?.getCategoryList() ?? [];
        }
    }

    public makeAction() {
        if (!this.start) {
            this.start = true;
            let action = this.actionList.shift();
            if (action) {
                let obj = this;
                const idLoading = action['id'];
                if (this.getAjoIdentifier() !== undefined && this.getAjoIdentifier() !== '-1') {
                    action['action']['id'] = this.getAjoIdentifier();
                    if (
                        (action['action']['vendor'] !== undefined || action['action']['prestation'] !== undefined) &&
                        this.prestation.get() != null &&
                        this.vendor.get() != null
                    ) {
                        let service: Service | null = null;
                        let i = 0;

                        while (service == null && i < this.vendor.get()!.serviceList.getList().length) {
                            let s = this.vendor.get()!.serviceList.getList()[i];
                            if (s.prestation.get()?.getAjoIdentifier() === this.prestation.get()?.getAjoIdentifier()) {
                                service = s;
                            }
                            i += 1;
                        }

                        if (service != null) {
                            this.service.set(service);
                            this.rowState?.makeUpdate();
                            action['action']['service'] = {
                                id: service.getAjoIdentifier(),
                            };
                        } else {
                            let labelList = [];
                            for (let label of this.vendor.get()!.getLabelList()) {
                                labelList.push({
                                    id: label.getAjoIdentifier(),
                                });
                            }

                            action['action']['service'] = {
                                vendor: [
                                    {
                                        id: this.vendor.get()!.getAjoIdentifier(),
                                    },
                                ],
                                prestation: [
                                    {
                                        id: this.prestation.get()!.getAjoIdentifier(),
                                    },
                                ],
                                label: labelList,
                            };
                        }
                    } else if (this.prestation.get() == null || this.vendor.get() == null) {
                        if (this.service.get() != null) {
                            action['action']['service'] = {
                                id_del: this.service.get()!.getAjoIdentifier(),
                            };
                            this.service.set(null);
                            this.rowState?.makeUpdate();
                        }
                    }
                }
                post('/budget/update2', {
                    id: this.getAjoIdentifier(),
                    ...action['action'],
                })
                    .then((res: any) => {
                        if (Result.isSuccess(res)) {
                            obj.setAjoIdentifier(null);

                            Result.applyResult(res, obj, true);
                            Result.applyResult(res, AppManager.updater(), true);

                            obj.start = false;
                            obj.makeAction();

                            AppManager.makeUpdate();

                            removeView(idLoading, '1');
                            removeActivityView(idLoading, '1', Result.getResult(res));
                        } else {
                            AppManager.addToast(
                                new Toast(
                                    "Une erreur s'est produite lors de l'action. Veuillez recharger la page.",
                                    'error',
                                ),
                            );
                            removeView(idLoading, '2');
                            removeActivityView(idLoading, '2', Result.getError(res));
                        }
                    })
                    .catch((err) => {
                        AppManager.addToast(
                            new Toast(
                                "Une erreur s'est produite lors de l'action. Veuillez recharger la page.",
                                'error',
                            ),
                        );
                        removeView(idLoading, '2');
                        removeActivityView(idLoading, '2', 'went_wrong');
                    });
            } else {
                this.start = false;
            }
        }
    }

    public lockSend(paper: AJOObject) {
        let obj = this;
        post('/' + paper.getAjoType().toLowerCase() + '/update', {
            id: paper.getAjoIdentifier(),
            budget: [
                {
                    id: this.getAjoIdentifier(),
                    lock: !(this.lock.get() ?? false),
                },
            ],
        }).then((res: any) => {
            //Result.applyResult(res, obj, true);
        });
        obj.lock.set(!(this.lock.get() ?? false));
        AppManager.updater().makeUpdate();
    }

    public duplicate(paper: AJOObject) {
        let obj = this;
        let params: any = {
            copy_id: this.getAjoIdentifier(),
            go_id: this.getAjoParent()?.getAjoParent()?.getAjoIdentifier() ?? '',
            from_id: this.getAjoParent()?.getAjoParent()?.getAjoIdentifier() ?? '',
        };

        let clone = Object.assign(Object.create(Object.getPrototypeOf(this)), this) as Budget;
        clone.setAjoIdentifier('-1');
        clone.reInit();
        clone.rowState = null;

        clone.start = true;
        clone.focus = false;
        post('/' + paper.getAjoType().toLowerCase() + '/update', {
            id: paper.getAjoIdentifier(),
            budget: [params],
        }).then((res: any) => {
            delete res['result']['path2'];

            clone.start = false;
            clone.setAjoIdentifier(null);
            Result.applyResult(res, clone, true);

            clone.reInit();
            clone.rowState = null;

            AppManager.updater().makeUpdate();
        });

        try {
            (paper as any).budgetList.getList().push(clone);
        } catch (e) {}

        AppManager.updater().makeUpdate();
    }

    public optionSend(paper: AJOObject) {
        let obj = this;
        post('/' + paper.getAjoType().toLowerCase() + '/update', {
            id: paper.getAjoIdentifier(),
            budget: [
                {
                    id: this.getAjoIdentifier(),
                    option: !(obj.option.get() ?? false),
                },
            ],
        }).then((res: any) => {
            //Result.applyResult(res, obj, true);
        });
        obj.option.set(!(obj.option.get() ?? false));
        AppManager.updater().makeUpdate();
    }

    public optionAccept(paper: AJOObject) {
        let obj = this;
        post('/' + paper.getAjoType().toLowerCase() + '/update', {
            id: paper.getAjoIdentifier(),
            budget: [
                {
                    id: this.getAjoIdentifier(),
                    option: false,
                },
            ],
        }).then((res: any) => {
            //Result.applyResult(res, obj, true);
        });
        obj.option.set(false);
        AppManager.updater().makeUpdate();
    }

    public getHonoraireCategoryList(): CategoryHonoraire[] {
        return this.honoraire.get() != null ? this.honoraire.get()!.getCategoryList() : ([] as CategoryHonoraire[]);
    }

    public locked(paper: AJOObject, forceEdit: boolean): boolean {
        let lock = this.lock.get();
        let res: boolean = false;
        if (paper instanceof Paper) {
            if (lock) {
                res = true;
            } else if (paper.comptable.get() ?? false) {
                res = false;
            } else {
                res = paper.archive.get() ?? false;
            }

            if (!res) {
                res = !paper.canAct() && !forceEdit;
            }
        }

        return res || this.bon_commande.get() != null;
    }
    public lockedAlpha(paper: AJOObject): boolean {
        let lock = this.lock.get();
        return lock ?? false;
    }

    public getMyNote(): Note | null {
        let id_user = AppManager.getUser()?.getAjoIdentifier() ?? '';
        let note = null;
        let i = 0;
        while (note == null && i < this.noteList.getList().length) {
            let ccNote = this.noteList.getList()[i];

            if (ccNote.user.get()?.getAjoIdentifier() == id_user) {
                note = ccNote;
            }
            i += 1;
        }
        return note;
    }

    public getLockClass(paper: AJOObject, forceEdit: boolean, field: string) {
        let classHide: string;
        if (this.locked(paper, forceEdit) || this.isLock(field) || this.bon_commande.get() != null) {
            classHide = 'relative';
        } else {
            classHide = '';
        }
        if (this.lockedAlpha(paper) || this.isLock(field)) {
            classHide += ' opacity-50';
        }
        return classHide;
    }

    public isIndexLock(i: number) {
        if (i == 0) {
            return this.lock_prestation.get() ?? false;
        } else if (i == 1) {
            return this.lock_vendor.get() ?? false;
        } else if (i == 2) {
            return this.lock_quantity.get() ?? false;
        } else if (i == 3) {
            return this.lock_price.get() ?? false;
        } else if (i == 4) {
            return this.lock_valeur.get() ?? false;
        } else if (i == 5) {
            return this.lock_taxe.get() ?? false;
        } else {
            return false;
        }
    }

    public lastIndex = 0;

    public nextIndex(index: number) {
        this.indexFocus = index;
        let i = this.indexFocus + 1;
        while (this.isIndexLock(i) && this.lastIndex === 6) {
            i++;
        }
        if (i == this.lastIndex) {
            i = 0;
            if (this.next != null) {
                this.next.focus = true;
                this.next.indexFocus = 0;
                this.next.rowState?.makeUpdate();
            }
            this.focus = false;
            this.indexFocus = 0;
        } else {
            this.focus = true;
            this.indexFocus = i;
        }
        this.rowState?.makeUpdate();
        return i;
    }

    public reInit() {
        let objAny = this as any;
        let keys = Object.keys(objAny);
        for (let key of keys) {
            if (key.startsWith('init')) {
                objAny[key] = false;
            }
        }
    }

    public setSelectName(field: string, value: string) {
        (this as any)['selectName' + this.capitalizeFirstLetter(field)] = value;
        this.rowState?.makeUpdate();
    }

    public setSearch(field: string, value: string) {
        (this as any)['search' + this.capitalizeFirstLetter(field)] = value;
        this.rowState?.makeUpdate();
    }

    public setSelectId(field: string, value: string) {
        (this as any)['selectId' + this.capitalizeFirstLetter(field)] = value;
        this.rowState?.makeUpdate();
    }

    /**
     * PRESTATTION
     */
    public capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    public init(field: string, fieldPrimary: string, elem: any) {
        if ((this as any)['init' + this.capitalizeFirstLetter(field)] === undefined) {
            (this as any)['init' + this.capitalizeFirstLetter(field)] = false;
            (this as any)['search' + this.capitalizeFirstLetter(field)] = '';
            (this as any)['selectName' + this.capitalizeFirstLetter(field)] = '';
            (this as any)['selectId' + this.capitalizeFirstLetter(field)] = '';
        }

        if (!(this as any)['init' + this.capitalizeFirstLetter(field)]) {
            (this as any)['init' + this.capitalizeFirstLetter(field)] = true;
            let name = '';

            if (elem != null) {
                if (field === 'address' || field === 'facturation') {
                    name = elem.getTitle();
                } else if (field === 'user') {
                    name = elem.getFullName();
                } else if (field === 'nego') {
                    name = -elem.pourcent.get() + ' %';
                } else if (field === 'valeur') {
                    name = elem.pourcent.get() + ' %';
                } else {
                    name = (elem[fieldPrimary]?.get() ?? '') + '';
                }
            } else {
                if (field === 'valeur' && this.valeur_flat.get() !== undefined) {
                    name = (this.valeur_flat.get() ?? '') + ' %';
                }
                if (field === 'nego' && this.nego_flat.get() !== undefined) {
                    name = -this.nego_flat.get() + ' %';
                }
            }
            (this as any)['search' + this.capitalizeFirstLetter(field)] = name + '';
            (this as any)['selectName' + this.capitalizeFirstLetter(field)] = name + '';
            (this as any)['selectId' + this.capitalizeFirstLetter(field)] = elem?.getAjoIdentifier() ?? '';
        }
    }

    public getPaper(): Paper | null {
        let res: Paper | null;
        if (this.paper.get() != null) {
            res = this.paper.get()!;
        } else {
            res = (this.getAjoParent()?.getAjoParent() as Paper) ?? null;
        }
        return res;
    }

    public fullComplite(): boolean {
        let res = this.complite();
        if (this.vendor.get() == null) {
            res = false;
        }
        if (this.taxe_vendor.get() == null) {
            res = false;
        }
        if (this.user.get() == null) {
            res = false;
        }
        /*if(this.livraison_date.get()==null||this.livraison_date.get()===undefined||this.livraison_date.get()===""){
            res = false;
        }*/
        /*if(this.address.get()==null){
            res = false;
        }*/
        if (this.contact.get() == null) {
            res = false;
        }
        return res;
    }

    public complite(): boolean {
        let res = true;
        if (this.getPaper()?.getAjoType() === 'FlexBonCommande') {
            if (this.prestation.get() == null) {
                res = false;
            }
            if (this.vendor.get() == null) {
                res = false;
            }
            if (
                this.quantity.get() == null ||
                this.quantity.get() === undefined ||
                this.quantity.get() === 0 ||
                this.quantity.get() === ''
            ) {
                res = false;
            }
            if (this.price.get() == null || this.price.get() === undefined || this.price.get() === '') {
                res = false;
            }
        } else if (this.getPaper() != null && this.getPaper()!.func.get() === 1) {
            if (this.honoraire.get() == null) {
                res = false;
            }
            if (
                this.quantity.get() == null ||
                this.quantity.get() === undefined ||
                this.quantity.get() === 0 ||
                this.quantity.get() === ''
            ) {
                res = false;
            }
            if (this.price.get() == null || this.price.get() === undefined || this.price.get() === '') {
                res = false;
            }
            if (
                this.nb_personnel.get() == null ||
                this.nb_personnel.get() === undefined ||
                this.nb_personnel.get() === ''
            ) {
                res = false;
            }
            if (this.taxe.get() == null) {
                res = false;
            }
            if (this.nego.get() == null && (this.nego_flat.get() === undefined || this.nego_flat.get() === '')) {
                res = false;
            }
        } else {
            if (this.prestation.get() == null) {
                res = false;
            }
            if (this.vendor.get() == null) {
                res = false;
            }
            if (
                this.quantity.get() == null ||
                this.quantity.get() === undefined ||
                this.quantity.get() === 0 ||
                this.quantity.get() === ''
            ) {
                res = false;
            }
            if (this.price.get() == null || this.price.get() === undefined || this.price.get() === '') {
                res = false;
            }
            if (this.taxe.get() == null) {
                res = false;
            }
            if (this.valeur.get() == null && (this.valeur_flat.get() === undefined || this.valeur_flat.get() === '')) {
                res = false;
            }
        }
        if (this.option.get() == true && this.choice.get() !== true) {
            res = false;
        }
        return res;
    }

    private _openDelete: boolean = false;
    public get openDelete(): boolean {
        return this._openDelete;
    }
    public set openDelete(value: boolean) {
        this._openDelete = value;
        this.rowState?.makeUpdate();
    }

    public sendTypeRounded(typeRounded: number, paper: AJOObject) {
        let obj = this;
        post('/' + paper.getAjoType().toLowerCase() + '/update', {
            id: paper.getAjoIdentifier(),
            budget: [
                {
                    id: obj.getAjoIdentifier(),
                    type_rounded: typeRounded,
                },
            ],
        }).then((res: any) => {
            if (Result.isSuccess(res)) {
                this.rowState?.makeUpdate();
            }
        });
        this.typeRounded.set(typeRounded);
        this.rowState?.makeUpdate();
        AppManager.updater().makeUpdate();
    }

    public removeDecimale(paper: AJOObject) {
        let actuRounded = this.rounded.get() ?? 0;
        let obj = this;

        let res = this.prixVenteHT();
        let number = res.toFixed(2);

        let count = 0;
        for (let i = number.length - 1; i >= 0; i--) {
            if (number[i] !== '0' && number[i] !== '.') {
                break;
            }
            if (number[i] === '0') {
                count++;
            }
        }
        count++;

        const id = new Date().getTime();
        addView(id);
        addActivityView(id, {
            id: obj.getAjoIdentifier(),
            rounded: -count,
        });

        post('/budget/update2', {
            id: obj.getAjoIdentifier(),
            rounded: -count,
        })
            .then((res: any) => {
                if (Result.isSuccess(res)) {
                    this.rowState?.makeUpdate();
                    removeView(id, '1');
                    removeActivityView(id, '1', Result.getResult(res));
                } else {
                    removeView(id, '2');
                    removeActivityView(id, '2', Result.getError(res));
                }
            })
            .catch((err: any) => {
                removeView(id, '2');
                removeActivityView(id, '2', 'went_wrong');
            });
        this.rounded.set(-count);
        this.rowState?.makeUpdate();
        AppManager.updater().makeUpdate();
    }

    public addDecimale(paper: AJOObject) {
        let actuRounded = this.rounded.get() ?? 0;
        let obj = this;

        let finalRounded = actuRounded;
        let numberZero = 0;
        let good = true;

        while (good && finalRounded !== 0) {
            let res = this.prixVenteHT();
            let number = res.toFixed(2);

            let count = 0;
            for (let i = number.length - 1; i >= 0; i--) {
                if (number[i] !== '0' && number[i] !== '.') {
                    break;
                }
                if (number[i] === '0') {
                    count++;
                }
            }
            if (numberZero !== 0 && numberZero !== count) {
                good = false;
            } else {
                finalRounded += 1;
                numberZero = count;
                this.rounded.set(finalRounded);
            }
        }
        if (finalRounded <= 0) {
            const id = new Date().getTime();
            addView(id);
            addActivityView(id, {
                id: obj.getAjoIdentifier(),
                rounded: -finalRounded,
            });

            post('/budget/update2', {
                id: obj.getAjoIdentifier(),
                rounded: -finalRounded,
            })
                .then((res: any) => {
                    if (Result.isSuccess(res)) {
                        this.rowState?.makeUpdate();
                        removeView(id, '1');
                        removeActivityView(id, '1', Result.getResult(res));
                    } else {
                        removeView(id, '2');
                        removeActivityView(id, '2', Result.getError(res));
                    }
                })
                .catch((err: any) => {
                    removeView(id, '2');
                    removeActivityView(id, '2', 'went_wrong');
                });
            this.rounded.set(finalRounded);
            this.rowState?.makeUpdate();
            AppManager.updater().makeUpdate();
        }
    }

    public aliasSend(paper: AJOObject, alias: string) {
        let obj = this;
        post('/' + paper.getAjoType().toLowerCase() + '/update', {
            id: paper.getAjoIdentifier(),
            budget: [
                {
                    id: obj.getAjoIdentifier(),
                    alias: alias,
                },
            ],
        }).then((res: any) => {
            if (Result.isSuccess(res)) {
                Result.applyResult(res, obj, true);
                this.rowState?.makeUpdate();
            }
        });
        this.alias.set(alias.trim().toUpperCase());
        this.rowState?.makeUpdate();
    }

    public canAct(forceEdit: boolean): boolean {
        return ((this.getPaper()?.canAct() ?? false) && this.bon_commande.get() == null) || forceEdit;
    }

    public leaveFocus() {
        if (this.getAjoIdentifier() === '-1') {
            return;
        }
        this.focus = false;
        this.getPaper()?.removeAllFocus();
        AppManager.makeUpdate();
    }

    public getFocus() {
        this.getPaper()?.removeAllFocus();
        this.focus = true;
        AppManager.makeUpdate();
    }

    public focusVendor() {
        this.getFocus();

        /*setTimeout(() => {
            (document.querySelector('#vendor-' + this.getAjoIdentifier()) as HTMLElement)!.focus();
            document.querySelector('#vendor-' + this.getAjoIdentifier())?.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
            });
        }, 200);*/

        AppManager.makeUpdate();
    }
}
