import { AJOList, AJOObject, AJOUpdater } from 'mp-js-react-auto-json-object';
import React from 'react';
import AppManager from '../../commun/AppManager';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import { JSXInternal } from 'preact/src/jsx';
import Toast from '../../commun/toast/Toast';
import { UserSelect } from '../../component/select/UserSelect';
import User from '../../object/User';
import { errorManager, selectUserError, userAddSuccess } from '../vendor/CategoryErrorManager';
import MpModal from 'src/component/extends/MpModal';
import { Box, Typography } from '@mui/material';
import { Spinner } from 'src/commun/component/Spinner';

export interface IUserSelectModalProps {
    ajoList?: AJOList<User>;
    show: boolean;

    parent: AJOObject;
    onClose: () => void;
}
export interface IUserSelectModalState {
    user: User | null;

    error: ErrorHTTP;
    loading: boolean;
}

export class UserSelectModal extends React.Component<IUserSelectModalProps, IUserSelectModalState> {
    constructor(props: IUserSelectModalProps) {
        /**
         * DEFAULT PROPS
         */

        super(props);

        /**
         * CREATE STATE
         * */
        this.state = this.initState();
    }

    public initState(): IUserSelectModalState {
        return {
            user: null,
            error: ErrorHTTP.NO_ERROR(),
            loading: false,
        };
    }

    public componentDidUpdate(
        prevProps: Readonly<IUserSelectModalProps>,
        prevState: Readonly<IUserSelectModalState>,
        snapshot?: any,
    ): void {
        if (this.props.show !== prevProps.show) {
            this.setState(this.initState());
        }
    }

    public url(): string {
        return '/devis/update';
    }

    public params(): { [key: string]: any } {
        const { user } = this.state;
        const { parent } = this.props;

        let params: { [key: string]: any } = {};
        params.id = parent.getAjoIdentifier();
        params.user = {
            id: user?.getAjoIdentifier() ?? '',
        };

        return params;
    }

    public addUser() {
        if (this.state.user) {
            this.setState({ loading: true, error: ErrorHTTP.NO_ERROR() });

            post(this.url(), this.params()).then((res: any) => {
                this.setState({ loading: false });
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, AppManager.updater(), true);

                    this.props.onClose();

                    AppManager.addToast(new Toast(errorManager.get(userAddSuccess), 'success'));
                } else {
                    this.setState({ error: Result.getError(res) });
                }
            });
        } else {
            this.setState({
                error: selectUserError,
            });
        }
    }

    public render(): JSXInternal.Element {
        const { error, loading } = this.state;
        const { ajoList, show, onClose } = this.props;
        return (
            <MpModal
                overflow={false}
                open={show}
                onSubmit={() => {
                    this.addUser();
                }}
                done="Ajouter"
                title="Ajouter un utilisateur"
                onClose={onClose}
            >
                {loading ? (
                    <Box className="flex items-center justify-center">
                        <Spinner color="blue" />
                    </Box>
                ) : (
                    <Box className="flex flex-col gap-3">
                        <UserSelect
                            canAdd={false}
                            elem={this.state.user ?? undefined}
                            onObjectSelect={(u) => {
                                this.setState({ user: u });
                            }}
                            haveEditButton={false}
                            placeholder="Utilisateur"
                            ajoList={ajoList}
                        />

                        {!error.equals(ErrorHTTP.NO_ERROR()) && (
                            <Typography
                                component={'p'}
                                className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                            >
                                {errorManager.get(error)}
                            </Typography>
                        )}
                    </Box>
                )}
            </MpModal>
        );
    }
}
