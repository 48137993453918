import { AJOList, AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import MakeAction from '../commun/action/Action';
import ActionManager from '../commun/action/ActionManager';
import AppManager from '../commun/AppManager';
import { Button } from '../commun/component/Button';
import { CheckBox } from '../commun/component/CheckBox';
import { SearchInput } from '../commun/component/SearchInput';
import { get } from '../commun/HTTP';
import Result from '../commun/Result';
import { Col } from '../commun/table/Col';
import Table from '../commun/table/Table';
import { TableView } from '../commun/table/TableView';
import Utils from '../commun/Utils';
import { BasePage } from '../component/BasePage';
import { DeleteRoleModal } from '../modal/DeleteRoleModal';
import { RoleModal } from '../modal/RoleModal';
import Permission from '../object/Permission';
import { JSXInternal } from 'preact/src/jsx';
import Role from '../object/Role';
import User from '../object/User';

export function RolePage() {
    const [selectedList, updateSelectedList] = useState(new Array<string>());
    const [loading, updateLoading] = useState(true);
    const [search, updateSearch] = useState('');
    const [stateList, updateStateList] = useState(new AJOState(new AJOList<Role>(undefined, Role._TYPE)));
    stateList.setUpdate(updateStateList);

    useEffect(() => {
        get('/role/get', {
            search: '',
        }).then((res: any) => {
            if (Result.isSuccess(res)) {
                updateLoading(false);

                stateList.get()!.reset();

                Result.applyResult(res, stateList, true);
            }
        });
    }, []);
    useEffect(() => {
        updateSelectedList([]);
    }, [search]);

    const list: Role[] = [];
    const cleanSearchList: string[] = Utils.clean(search).split(' ');

    for (let i = 0; i < stateList.get()!.size(); i++) {
        const elem = stateList.get()!.get(i);

        let good = cleanSearchList.length == 1 && cleanSearchList[0] == '';
        let y = 0;

        while (!good && y < cleanSearchList.length) {
            const cleanSearch = cleanSearchList[y];

            if (cleanSearch.length != 0 && Utils.clean(elem.name.get()).includes(cleanSearch)) {
                good = true;
            }
            y++;
        }

        if (good) {
            list.push(elem);
        }
    }

    const [editRoleId, updateEditRoleId] = useState(-1);
    const [deleteRoleId, updateDeleteRoleId] = useState(-1);
    const [showEdit, updateShowEdit] = useState(false);
    const [showDelete, updateShowDelete] = useState(false);
    const [showAdd, updateShowAdd] = useState(false);

    const table = new Table<AJOObject>('role');
    /*table.setLineStyle((elem): string => {
        return (elem as Role).delete.get()
            ? 'bg-red-400 dark:bg-red-700 bg-opacity-20 dark:bg-opacity-20 hover:bg-opacity-50 hover:bg-red-400 hover:dark:bg-opacity-50 hover:dark:bg-red-900'
            : ' hover:bg-gray-100 hover:dark:bg-gray-800 ';
    });

    const checkChange = (e: any, user: User) => {
        e.target.checked = !e.target.checked;
        const id = user.getAjoIdentifier();
        if (selectedList.indexOf(id) != -1) {
            let index = selectedList.indexOf(id);
            selectedList.splice(index, 1);
        } else {
            selectedList.push(id);
        }
        updateSelectedList([...selectedList]);
    };
    table.addCol(
        new Row('', (role:any) => {
            return (
                <div>
                    <CheckBox
                        id={'checkbox-role-' + role.getAjoIdentifier()}
                        name={'checkbox-user-' + role.getAjoIdentifier()}
                        checked={selectedList.indexOf(role.getAjoIdentifier()) != -1}
                        onChange={(e) => checkChange(e, role)}
                    />
                </div>
            );
        }, "check"),
    );
    table.addCol(
        new Row('Nom', (role: Role) => {
            return (
                <div>
                    <p>{role.name.get()}</p>
                </div>
            );
        }),
    );

    table.addCol(
        new Row('', (role: any) => {

            let buttonEdit;
            let buttonDelete;
            
            if (ActionManager.canDoAction(Action.EDIT_ROLE, you, role)) {
                buttonEdit = (
                    <Button
                        onClick={(e) => {
                            openEdit(e, role);
                        }}
                        textSize="sm"
                        pb={2}
                        pt={2}
                        pl={2}
                        pr={2}
                        color="blue"
                        selected={true}
                    >
                        <svg
                            className="h-5 w-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                            <path
                                fillRule="evenodd"
                                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        Modifier
                    </Button>
                );
            }
            if (ActionManager.canDoAction(Action.DELETE_ROLE, you, role)) {
                buttonDelete = (
                    <Button
                        onClick={(e) => {
                            openDelete(e, role);
                        }}
                        textSize="sm"
                        pb={2}
                        pt={2}
                        pl={2}
                        pr={2}
                        color="red"
                        selected={true}
                    >
                        <svg
                            className="h-5 w-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        Supprimer
                    </Button>
                );
            }
            return (
                <div className="flex gap-4">
                    {buttonEdit}
                    {buttonDelete}
                </div>
            );
        }),
    );
    table.setList(list);*/

    const openEdit = (event: JSXInternal.TargetedMouseEvent<HTMLButtonElement>, role: Role) => {
        updateEditRoleId(role.getAjoIdentifier());
        updateShowEdit(true);
    };

    const openDelete = (event: JSXInternal.TargetedMouseEvent<HTMLButtonElement>, role: Role) => {
        updateDeleteRoleId(role.getAjoIdentifier());
        updateShowDelete(true);
    };

    const openAdd = (event: JSXInternal.TargetedMouseEvent<HTMLButtonElement>) => {
        updateShowAdd(true);
    };

    let you = AppManager.getUser() as User;

    let editRole;

    let i = 0;
    let found = false;

    while (!found && i < stateList.get()!.size()) {
        if (stateList.get()!.get(i).getAjoIdentifier() == editRoleId) {
            editRole = stateList.get()!.get(i);
            found = true;
        }
        i++;
    }

    let deleteRole;

    i = 0;
    found = false;

    while (!found && i < stateList.get()!.size()) {
        if (stateList.get()!.get(i).getAjoIdentifier() == deleteRoleId) {
            deleteRole = stateList.get()!.get(i);
            found = true;
        }
        i++;
    }

    return (
        <BasePage className="flex-col items-center w-full">
            <div className="flex flex-col gap-5 h-full w-full p-5 xl:w-3/4">
                <div className="flex flex-row gap-5">
                    <div className="relative w-full">
                        <SearchInput value={search} setValue={updateSearch} />
                    </div>
                    {ActionManager.canDoAction(MakeAction.ADD_ROLE, you) && (
                        <Button onClick={openAdd} color="blue" gap={0} pl={5} pr={7}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg>
                            Ajouter
                        </Button>
                    )}
                </div>
                <TableView selectedList={selectedList} updateSelectedList={updateSelectedList} table={table} />

                {showAdd && (
                    <RoleModal
                        stateList={stateList}
                        selectIdList={selectedList}
                        show={showAdd}
                        handleClose={() => {
                            updateShowAdd(false);
                        }}
                    />
                )}
                {showEdit && (
                    <RoleModal
                        stateList={stateList}
                        selectIdList={selectedList}
                        show={showEdit}
                        typeAction={'update'}
                        currentRole={editRole}
                        handleClose={() => {
                            updateShowEdit(false);
                        }}
                    />
                )}
                {showDelete && (
                    <DeleteRoleModal
                        type="simple"
                        stateList={stateList}
                        selectIdList={selectedList}
                        show={showDelete}
                        currentRole={deleteRole}
                        handleClose={() => {
                            updateShowEdit(false);
                        }}
                    />
                )}
            </div>
        </BasePage>
    );
}
