import { AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import { TableRowInterface } from '../../object/interface/TableRowInterface';
import { TableRowNextInterface } from '../../object/interface/TableRowNextInterface';
import { TagObject } from '../../object/tag/TagObject';
import { Col } from './Col';
import { SectionTr } from './SectionTr';
import Table from './Table';

export interface ISectionTBodyProps {
    tag: TagObject;
    table: Table<AJOObject>;
    list: AJOObject[];
    nextList: AJOObject[];
    index: number;
    renderElem: (tag: TagObject, elem: any, list: any[], index: number) => JSX.Element;
}
export const SectionTBody: React.FC<ISectionTBodyProps> = (props) => {
    const { nextList, tag, table, list, renderElem, index } = props;

    const [state, updateState] = useState(new AJOState(tag));
    state.setUpdate(updateState);

    if ('TableRowInterface' in tag) {
        (tag as unknown as TableRowInterface).rowState = state;
    }

    const makeElem = (elem: AJOObject, i: number) => {
        if ('TableRowNextInterface' in elem) {
            if (i === list.length - 1) {
                (elem as unknown as TableRowNextInterface).next = nextList[0];
            } else {
                (elem as unknown as TableRowNextInterface).next = list[i + 1];
            }
        }
        return elem;
    };

    return (
        <tbody className={' bg-' + tag.getColor() + '-200 dark:bg-' + tag.getColor() + '-900 '}>
            <SectionTr table={table} tag={tag} list={list} />
            {list.map((elem, indexElem) => {
                return renderElem(tag, makeElem(elem, indexElem), list, indexElem);
            })}
        </tbody>
    );
};
