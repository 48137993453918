import { AJOList, AJOProperties, AJOSimple, AJOUpdater } from 'mp-js-react-auto-json-object';
import AppManager from '../../commun/AppManager';
import { CategoryColInterface } from '../../commun/col/CategoryCol';
import { LabelColInterface } from '../../commun/col/LabelCol';
import { NameColInterface } from '../../commun/col/NameCol';
import { NbPrestaColInterface } from '../../commun/col/NbPresta';
import { PrestationColInterface } from '../../commun/col/PrestationCol';
import { Color } from '../../commun/Constant';
import { get, post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import { IObjectSelectable } from '../../component/select/ObjectSelect';
import Address from '../Address';
import { Comptable } from '../Comptable';
import Contact from '../Contact';
import ParentSevice from './ParentSevice';
import Prestation from './Prestation';

export default class Vendor
    extends ParentSevice
    implements
        IObjectSelectable,
        NameColInterface,
        PrestationColInterface,
        CategoryColInterface,
        LabelColInterface,
        Comptable,
        NbPrestaColInterface
{
    public NbPrestaColInterface = true;
    public Comptable = true;
    public LabelColInterface = true;
    public CategoryColInterface = true;
    public PrestationColInterface = true;
    public NameColInterface = true;

    public IObjectSelectable = true;

    public getSelectTitle(): string {
        return this.getName();
    }

    public getSelectDescription(): string {
        return '';
    }

    public getColor(): Color {
        return 'red';
    }
    public getTitle(): string {
        return 'Fournisseur';
    }
    public static _TYPE: string = 'Vendor';

    public description: AJOProperties<any>;

    public contactList: AJOList<Contact>;
    public facturation: AJOSimple<Address>;

    constructor() {
        super(Vendor._TYPE);

        this.name = new AJOProperties('name');
        this.description = new AJOProperties('description', this);

        this.contactList = new AJOList(Contact, 'has_vendor_contact', this);
        this.facturation = new AJOSimple(Address, 'has_facturation', this);
    }

    public static build(): Vendor {
        return new Vendor();
    }

    public get(id: any, callback: () => void): void {
        const obj = this;
        get('/vendor/get', { id: id }).then((data) => {
            Result.applyResult(data, obj, true);
            callback();
        });
    }
    public getStat(id: any, callback: () => void): void {
        const obj = this;
        get('/vendor/get/stat', { id: id }).then((data) => {
            Result.applyResult(data, obj, true);
            callback();
        });
    }
    public getName(): string {
        return this.name.get();
    }
    public getLink(): string {
        return `/vendor/${this.getAjoIdentifier()}`;
    }
    public getPrestationList(): Prestation[] {
        let list = [] as Prestation[];
        for (const service of this.serviceList.getList()) {
            if (service.prestation.get() != null) {
                list.push(service.prestation.get()!);
            }
        }
        return list;
    }

    public prestationListFetch = false;
    public prestationListStart = false;
    public prestationListLoading = true;

    public fetchPrestationListInfo(): void {
        if (!this.prestationListFetch && !this.prestationListStart) {
            this.prestationListLoading = true;
            this.prestationListStart = true;
            get('/vendor/prestation', {
                id: this.getAjoIdentifier(),
            }).then((res: any) => {
                if (Result.isSuccess(res)) {
                    this.prestationListFetch = true;
                    this.prestationListLoading = false;
                    this.prestationListStart = false;
                    Result.applyResult(res, this, true);
                    Result.applyResult(res, AppManager.updater(), true);
                    AppManager.updater().makeUpdate();
                }
            });
        }
    }

    public achat(): number {
        let sum = 0;
        for (let service of this.serviceList.getList()) {
            sum += service.achat();
        }
        return sum;
    }
    public quantite(): number {
        let quantite = 0;
        return quantite;
    }
    public prixUnitaire(): number {
        let res = 0;
        return res;
    }
    public va(): number {
        let res = 0;
        res = (this.prixVenteHT() / this.achat() - 1) * 100;

        if (!isFinite(res)) {
            res = 0;
        }

        return res;
    }
    public prixVenteHT(): number {
        let sum = 0;
        for (let service of this.serviceList.getList()) {
            sum += service.prixVenteHT();
        }

        if (!isFinite(sum)) {
            sum = 0;
        }

        return sum;
    }
    public tax(): number {
        let res = 0;

        res = (this.prixVenteTTC() / this.prixVenteHT() - 1) * 100;

        if (!isFinite(res)) {
            res = 0;
        }
        return res;
    }
    public prixVenteTTC(): number {
        let res = 0;

        let sum = 0;
        for (let service of this.serviceList.getList()) {
            sum += service.prixVenteTTC();
        }
        res = sum;

        if (!isFinite(res)) {
            res = 0;
        }
        return res;
    }

    public getNbPresta(): number {
        let sum = 0;
        for (let service of this.serviceList.getList()) {
            sum += service.getNbPresta();
        }
        return sum;
    }
}
