import { AJOList, AJOObject, AJOProperties } from 'mp-js-react-auto-json-object';
import Permission from './Permission';

export default class Role extends AJOObject {
    public static _TYPE: string = 'Role';

    public name: AJOProperties<any>;
    public description: AJOProperties<any>;
    public delete: AJOProperties<any>;
    public permissionList: AJOList<Permission>;
    public base: AJOProperties<any>;

    constructor() {
        super(Role._TYPE);

        this.name = new AJOProperties('name');
        this.base = new AJOProperties('base');
        this.description = new AJOProperties('description');
        this.delete = new AJOProperties('delete');
        this.permissionList = new AJOList(Permission, 'has_permission', this);
    }

    public hasPermission(type: number): boolean {
        let res = false;
        let i = 0;
        while (!res && i < this.permissionList.size()) {
            if (this.permissionList.get(i).type.get() === type) {
                res = true;
            }
            i += 1;
        }
        return res;
    }

    public getPermTypeList(): number[] {
        let permTypeList = [];
        for (let i = 0; i < this.permissionList.size(); i++) {
            permTypeList.push(this.permissionList.get(i).type.get());
        }
        return permTypeList;
    }

    public static build(): Role {
        return new Role();
    }
}
