import { AJOList, AJOObject, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import React, { useEffect, useState } from 'react';
import AppManager from '../commun/AppManager';
import { ComplexSelect } from '../commun/component/ComplexSelect';
import { ModalBottomMode } from '../commun/Constant';
import ErrorHTTP from '../commun/ErrorHTTP';
import { get, post } from '../commun/HTTP';
import { Modal } from '../commun/modal/Modal';
import Result from '../commun/Result';
import Utils from '../commun/Utils';
import { AddressModal } from '../modal/vendor/AddressModal';
import { CategoryModal } from '../modal/vendor/CategoryModal';
import { AddContactModal } from '../modal/vendor/AddContactModal';
import { RemoveCategoryModal } from '../modal/vendor/RemoveCategoryModal';
import Address from '../object/Address';
import Category from '../object/tag/Category';
import Contact from '../object/Contact';
import Label from '../object/tag/Label';
import Permission from '../object/Permission';
import User from '../object/User';
import Vendor from '../object/profile/Vendor';
import { AddressView } from './AddressView';
import { ComplexSectonList as ComplexSectionList } from './ComplexSectonList';
import { ContactView } from './ContactView';
import { Tag } from './Tag';
import { TagList } from './TagList';
import { FusionCategoryModal } from '../modal/vendor/FusionCategoryModal';
import { AddTemplateModal } from '../modal/vendor/AddTemplateModal';
import { TemplateList } from './TemplateList';
import { ProductSelect } from './select/OffreSelect';
import { AgenceSelect } from './select/AgenceSelect';
import { AgenceModal } from '../modal/agence/AgenceModal';
import { Agence } from '../object/Agence';
import { CategoryEventSelect } from './select/CategoryEventSelect';
import { ProductModal } from '../modal/product/ProductModal';

export interface IComplexSectionProps {
    titleAdd: string;
    title: string;
    titleDel: string;
    textDelete: string;
    canDelete?: boolean;
    list: any[];
    ajoList?: AJOList<any>;
    bonusAjoList?: AJOList<any>;

    parent?: AJOObject;
    field: string;

    loading: boolean;

    textNothing: string;
    buttonAdd: string;

    addSuccessError: ErrorHTTP;
    delSuccessError: ErrorHTTP;

    canAdd: boolean;
    canFusion?: boolean;
}
export const ComplexSection: React.FC<IComplexSectionProps> = (props) => {
    const {
        textNothing,
        bonusAjoList,
        loading,
        ajoList,
        buttonAdd,
        titleDel,
        textDelete,
        parent,
        field,
        title,
        canFusion = false,
        titleAdd,
        list,
        addSuccessError,
        delSuccessError,
        canAdd,
        canDelete = canAdd,
    } = props;

    const [stateList, updateStateList] = useState(new AJOState<AJOList<AJOObject>>(new AJOList<AJOObject>()));
    stateList.setUpdate(updateStateList);

    const [fusionCategoryShow, updateFusionCategoryShow] = useState(false);

    const [selectCategoryShow, updateSelectCategoryShow] = useState(false);

    const [removeCategoryShow, updateRemoveCategoryShow] = useState(false);
    const [removeCategory, updateRemoveCategory] = useState<AJOObject | null>(null);

    const canEdit = (AppManager.getUser() as User)?.hasPermission(Permission.MANAGE_CATEGORY, false) ?? false;

    let compute;
    let computeAll;
    if (field === 'template') {
        computeAll = () => {
            return <TemplateList loading={loading} canEdit={canEdit} canDelete={canDelete} templateList={list} />;
        };
    } else if (field === 'category_event') {
        computeAll = () => {
            return (
                <CategoryEventSelect
                    ajoList={ajoList}
                    canAdd={canAdd}
                    placeholder="Catégorie"
                    onObjectSelect={() => {}}
                />
            );
        };
    } else if (field === 'product') {
        computeAll = () => {
            return <ProductSelect canAdd={canAdd} ajoList={ajoList} placeholder="Offre" onObjectSelect={() => {}} />;
        };
    } else if (field === 'agence') {
        computeAll = () => {
            return <AgenceSelect canAdd={canAdd} ajoList={ajoList} placeholder="Agence" onObjectSelect={() => {}} />;
        };
    } else if (field === 'category' || field === 'label') {
        computeAll = () => {
            return list.length !== 0 ? (
                <TagList
                    checkMode={false}
                    loading={loading}
                    showAddNothing={false}
                    canAdd={canAdd}
                    canEdit={canEdit}
                    canDelete={canDelete}
                    field={field}
                    parent={parent}
                    list={list}
                />
            ) : (
                <p className="text-sm">{textNothing}</p>
            );
        };
    } else if (field === 'address' || field === 'facturation') {
        compute = (elem: Address) => {
            return (
                <div>
                    <AddressView
                        address={elem}
                        canEdit={canEdit}
                        canDelete={field === 'facturation' && canEdit}
                        parent={parent}
                        onDelete={() => {
                            updateRemoveCategoryShow(true);
                            updateRemoveCategory(elem);
                        }}
                    />
                </div>
            );
        };
    } else {
        compute = (elem: Contact) => {
            return (
                <div>
                    <ContactView
                        parent={parent!}
                        contact={elem}
                        canDelete={canEdit}
                        onDelete={() => {
                            updateRemoveCategoryShow(true);
                            updateRemoveCategory(elem);
                        }}
                    />
                </div>
            );
        };
    }

    return (
        <div>
            <ComplexSectionList
                title={title}
                list={list}
                field={field}
                loading={loading}
                canFusion={canFusion && list.length >= 2}
                canAdd={canAdd}
                textNothing={textNothing}
                onFusion={() => {
                    updateFusionCategoryShow(true);
                }}
                onAdd={() => {
                    updateSelectCategoryShow(true);
                }}
                compute={compute}
                computeAll={computeAll}
                classNameList={'flex flex-row gap-2 flex-wrap'}
            />
            {canFusion && fusionCategoryShow && (
                <FusionCategoryModal
                    refList={list}
                    field={field}
                    show={fusionCategoryShow}
                    handleClose={() => {
                        updateFusionCategoryShow(false);
                    }}
                />
            )}
            {field === 'product' && (
                <ProductModal
                    parent={parent}
                    show={selectCategoryShow}
                    action="add"
                    onObjectChange={(obj: string) => {}}
                    onClose={() => {
                        updateSelectCategoryShow(false);
                    }}
                />
            )}
            {(field === 'category' || field === 'label') && (
                <CategoryModal
                    action={parent == undefined ? 'new' : 'join'}
                    parent={parent}
                    field={field}
                    placeholder={title}
                    title={titleAdd}
                    button={buttonAdd}
                    successError={addSuccessError}
                    show={selectCategoryShow}
                    handleClose={() => updateSelectCategoryShow(false)}
                />
            )}
            {field === 'contact' && (
                <AddContactModal
                    parent={parent!}
                    field={field}
                    title={titleAdd}
                    edit={false}
                    button={buttonAdd}
                    successError={addSuccessError}
                    show={selectCategoryShow}
                    handleClose={() => updateSelectCategoryShow(false)}
                />
            )}
            {field === 'agence' && (
                <AgenceModal
                    action="add"
                    onObjectChange={(id: string) => {}}
                    show={selectCategoryShow}
                    onClose={() => updateSelectCategoryShow(false)}
                />
            )}
            {field === 'template' && (
                <AddTemplateModal
                    action="add"
                    field={field}
                    show={selectCategoryShow}
                    handleClose={() => updateSelectCategoryShow(false)}
                />
            )}
            {(field === 'address' || field === 'facturation') && (
                <AddressModal
                    parent={parent!}
                    field={field}
                    title={titleAdd}
                    button={buttonAdd}
                    successError={addSuccessError}
                    show={selectCategoryShow}
                    handleClose={() => updateSelectCategoryShow(false)}
                />
            )}
            {removeCategory != null && (
                <RemoveCategoryModal
                    elem={removeCategory}
                    parent={parent}
                    field={field}
                    title={titleDel}
                    textDelete={textDelete}
                    show={removeCategoryShow}
                    successError={delSuccessError}
                    handleClose={() => updateRemoveCategoryShow(false)}
                />
            )}
        </div>
    );
};
