import { Box, Modal } from '@mui/material';
import { AJOList, AJOState } from 'mp-js-react-auto-json-object';
import { Button } from 'flowbite-react';
import { Component } from 'react';
import { Spinner } from '../../commun/component/Spinner';
import { get } from '../../commun/HTTP';
import Result from '../../commun/Result';
import { JSXInternal } from 'preact/src/jsx';
import Label from '../../object/tag/Label';
import MpModal, { IBaseModalProps } from '../extends/MpModal';
import { SelectLabelList } from './SelectLabelList';

export interface ISelectLabelModalProps extends IBaseModalProps {
    selectList: Label[];
    onSelectList: (list: Label[]) => void;
}

export interface ISelectLabelModalState {
    selectList: Label[];
    loading: boolean;
    list: AJOState<AJOList<Label>>;
}

export default class SelectLabelModal extends Component<ISelectLabelModalProps, ISelectLabelModalState> {
    constructor(props: ISelectLabelModalProps) {
        super(props);

        this.state = this.initState();
    }

    initState() {
        const p: ISelectLabelModalState = {
            selectList: this.props.selectList,
            loading: false,
            list: new AJOState<AJOList<Label>>(new AJOList<Label>(Label)),
        };
        p.list.setUpdate((a) => {
            this.setState({
                list: a,
            });
        });
        if (this.props.open) {
            p.loading = true;
            get('/label/list', {}).then((res: any) => {
                this.setState({
                    loading: false,
                });
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, this.state.list, true);
                }
            });
        }
        return p;
    }

    componentDidUpdate(
        prevProps: Readonly<ISelectLabelModalProps>,
        prevState: Readonly<ISelectLabelModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.open !== this.props.open) {
            this.setState(this.initState());
        }
    }

    render(): JSXInternal.Element {
        const { open, onClose } = this.props;
        const { loading } = this.state;
        return (
            <MpModal
                open={open}
                onClose={onClose}
                onSubmit={() => {
                    this.props.onSelectList(this.state.selectList);
                    onClose();
                }}
                title="Sélectionner des labels"
                done="Sélectionner"
            >
                {loading ? (
                    <Box className="flex items-center justify-center py-12">
                        <Spinner color="blue" className="" />
                    </Box>
                ) : (
                    <SelectLabelList
                        list={this.state.list.get()!.getList()}
                        selectList={this.state.selectList}
                        onSelectList={(list) => {
                            this.setState({
                                selectList: [...list],
                            });
                        }}
                    />
                )}
            </MpModal>
        );
    }
}
