import { Link } from 'react-router-dom';
import AppManager from '../../commun/AppManager';
import { Button } from '../../commun/component/Button';
import { Spinner } from '../../commun/component/Spinner';
import { post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import { Col } from '../../commun/table/Col';
import Table from '../../commun/table/Table';
import { TableView } from '../../commun/table/TableView';
import Toast from '../../commun/toast/Toast';
import Utils from '../../commun/Utils';
import { errorManager } from '../../modal/vendor/CategoryErrorManager';
import { Timer } from '../../object/Timer';
import User from '../../object/User';
import { EditSvg } from '../EditSvg';
import { TimerView } from '../TimerView';

interface ITableCpRecupProps {
    list: Timer[];
}

export const TableCpRecup: React.FC<ITableCpRecupProps> = (props) => {
    const { list } = props;

    const table = new Table<Timer>('user');
    let nomCol = new Col<Timer>('Nom');
    nomCol.render = (timer: Timer) => {
        let user = timer.getAjoParent()!.getAjoParent() as User;
        return (
            <div className="flex flex-row gap-2 items-center">
                <p className="font-semibold text-black dark:text-white flex gap-1">{user.getFullName()}</p>
            </div>
        );
    };
    table.addCol(nomCol);
    let objectCol = new Col<Timer>('Objet');
    objectCol.render = (timer: Timer) => {
        return (
            <div className="flex flex-row gap-2 items-center">
                {timer.isMaladie() ? 'Arrêt maladie' : timer.isCp() ? 'Congés payés' : 'Récupération'}
            </div>
        );
    };
    table.addCol(objectCol);
    let dateCol = new Col<Timer>('Date');
    dateCol.render = (timer: Timer) => {
        return (
            <div className="flex flex-row gap-2 items-center">
                <p className="font-semibold text-black dark:text-white flex gap-1">
                    {Utils.toDDMMYY(timer.getStartDate().toISOString())}
                </p>
            </div>
        );
    };
    table.addCol(dateCol);
    let dureeCol = new Col<Timer>('Durée');
    dureeCol.render = (timer: Timer) => {
        return (
            <div className="flex flex-row gap-2 items-center">
                <TimerView date={new Date(0)} canJour={false} dateEnd={new Date(timer.getDuration())} />
            </div>
        );
    };
    table.addCol(dureeCol);
    let dispoCol = new Col<Timer>('Disponible');
    dispoCol.render = (timer: Timer) => {
        let user = timer.getAjoParent()!.getAjoParent() as User;

        if (user.recup.get() === undefined) {
            return <p>Chargement ...</p>;
        } else {
            return <TimerView date={new Date(0)} dateEnd={new Date(user.recup.get() * 1000)}></TimerView>;
        }
    };
    table.addCol(dispoCol);
    let actionCol = new Col<Timer>('Action');
    actionCol.render = (timer: Timer) => {
        if (timer.load) {
            return <Spinner color="blue" />;
        } else {
            return (
                <div className="flex flex-row gap-2 items-center">
                    <Button
                        color="green"
                        onClick={() => {
                            timer.load = true;
                            AppManager.updater().makeUpdate();
                            post('/timer/accept', { id: timer.getAjoIdentifier() }).then((res: any) => {
                                timer.load = false;
                                AppManager.updater().makeUpdate();
                                if (Result.isSuccess(res)) {
                                    Result.applyResult(res, AppManager.updater(), true);
                                    AppManager.addToast(new Toast("L'élément a été accepté.", 'success'));
                                } else {
                                    AppManager.addToast(new Toast(errorManager.get(Result.getError(res)), 'error'));
                                }
                            });
                        }}
                    >
                        Accepter
                    </Button>
                    <Button
                        color="red"
                        onClick={() => {
                            timer.load = true;
                            AppManager.updater().makeUpdate();
                            post('/timer/refuse', { id: timer.getAjoIdentifier() }).then((res: any) => {
                                timer.load = false;
                                AppManager.updater().makeUpdate();
                                if (Result.isSuccess(res)) {
                                    Result.applyResult(res, AppManager.updater(), true);
                                    AppManager.addToast(new Toast("L'élément a été refusé.", 'success'));
                                } else {
                                    AppManager.addToast(new Toast(errorManager.get(Result.getError(res)), 'error'));
                                }
                            });
                        }}
                    >
                        Refuser
                    </Button>
                </div>
            );
        }
    };
    table.addCol(actionCol);
    table.list = list;
    table.bonusFilter = (timer: Timer) => {
        return timer.isCpRecupFerie() && timer.isPendding();
    };

    return <TableView rounded={true} table={table}></TableView>;
};
