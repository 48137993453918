import { CheckBox } from '@mui/icons-material';
import { Card } from '@mui/material';
import { useEffect, useState } from 'react';
import Client from 'src/object/profile/Client';

export interface ISimpleClientListProps {
    list: Client[];
    selectList: Client[];
    onSelectList: (list: Client[]) => void;
}

export const SelectClientList = (props: ISimpleClientListProps) => {
    const [selected, setSelected] = useState<Client[]>(props.selectList);

    useEffect(() => {
        setSelected(props.selectList);
    }, [props.selectList.length]);

    useEffect(() => {
        props.onSelectList(selected);
    }, [selected]);

    return (
        <div className="flex gap-2 flex-wrap">
            {props.list.map((Client: Client, index: number) => {
                return (
                    <div className={'border rounded-lg px-2 py-1 '}>
                        <div className="flex items-center gap-2">
                            <input
                                checked={
                                    selected.find((item) => item.getAjoIdentifier() === Client.getAjoIdentifier()) !==
                                    undefined
                                }
                                onChange={() => {
                                    if (selected.includes(Client)) {
                                        setSelected(
                                            selected.filter(
                                                (value: Client) =>
                                                    value.getAjoIdentifier() !== Client.getAjoIdentifier(),
                                            ),
                                        );
                                    } else {
                                        setSelected([...selected, Client]);
                                    }
                                }}
                                id={Client.getAjoIdentifier()}
                                type="checkbox"
                                value=""
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label htmlFor={Client.getAjoIdentifier()} className=" text-sm">
                                {Client.name.get()}
                            </label>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
