import { Title } from '../commun/component/Title';
import { BasePage } from '../component/BasePage';

export function Customer() {
    return (
        <BasePage>
            <Title />
            <p>Customer</p>
        </BasePage>
    );
}
