import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { AJOList, AJOState } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PerfectTable } from 'src/commun/table/PerfectTable';
import { RecapTimerView } from 'src/component/horaire/RecapTimerView';
import { UserTimerModal } from 'src/component/horaire/UserTimerModal';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';
import { AddIcon } from 'src/icons/AddIcon';
import { AddFerieModal } from 'src/modal/timer/AddFerieModal';
import { Timer } from 'src/object/Timer';
import { SERVER_URL } from 'src/utils/Constant';
import AppManager from '../commun/AppManager';
import { Spinner } from '../commun/component/Spinner';
import { get, post } from '../commun/HTTP';
import Result from '../commun/Result';
import Utils from '../commun/Utils';
import { BasePage } from '../component/BasePage';
import { TimerView } from '../component/TimerView';
import User from '../object/User';
import { AddCongeModal } from 'src/modal/timer/AddCongeModal';

interface IExportButtonProps {
    user: User | null;
    current: Date;
}
const ExportButton = (props: IExportButtonProps) => {
    const { user, current } = props;

    const [loading, setLoading] = useState(false);

    let res = undefined;
    if (loading) {
        res = (
            <Box>
                <Spinner color="blue" />
            </Box>
        );
    } else if (user != null) {
        res = (
            <Box>
                <Button
                    variant="contained"
                    color="primary"
                    className="!pl-3 gap-2"
                    onClick={() => {
                        setLoading(true);
                        post('/export/user', {
                            date: current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate(),
                            id_user: user.getAjoIdentifier(),
                        }).then((res: any) => {
                            setLoading(false);
                            if (Result.isSuccess(res)) {
                                const link = document.createElement('a');
                                link.href = SERVER_URL + '/' + res['result']['where'];
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }
                        });
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path
                            fillRule="evenodd"
                            d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                            clipRule="evenodd"
                        />
                    </svg>
                    Exporter
                </Button>
            </Box>
        );
    } else {
        res = (
            <Box>
                <Button
                    variant="contained"
                    color="primary"
                    className="!pl-3 gap-2"
                    onClick={() => {
                        setLoading(true);
                        post('/export/all', {
                            date: current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate(),
                        }).then((res: any) => {
                            setLoading(false);
                            if (Result.isSuccess(res)) {
                                const link = document.createElement('a');
                                link.href = SERVER_URL + '/' + res['result']['where'];
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }
                        });
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path
                            fillRule="evenodd"
                            d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                            clipRule="evenodd"
                        />
                    </svg>
                    Exporter
                </Button>
            </Box>
        );
    }
    return res;
};

export function HorairePage() {
    const [date, setDate] = useState(new Date());
    const [diff, setDiff] = useState(0);
    let finalDate = new Date(date.getTime() + diff * 7 * 24 * 60 * 60 * 1000);

    const [stateUserList, setStateUserList] = useState(new AJOState(new AJOList<User>(User)));
    stateUserList.setUpdate(setStateUserList);
    const [loadingUser, setLoadingUser] = useState([] as Date[]);
    AppManager.updater().add(stateUserList);

    const fetchElem = () => {
        if (loadingUser.find((d: Date) => d.getTime() === finalDate.getDate()) === undefined) {
            get('/timer/user_list', {
                date: finalDate.getFullYear() + '-' + (finalDate.getMonth() + 1) + '-' + finalDate.getDate(),
            })
                .then((result) => {
                    if (Result.isSuccess(result)) {
                        Result.applyResult(result, stateUserList);
                    }

                    setLoadingUser([...loadingUser, finalDate]);

                    for (let user of stateUserList.get()!.getList()) {
                        if (user.global_recup.get() === undefined) {
                            post('/timer/recup', {
                                id: user.getAjoIdentifier(),
                            })
                                .then((res: any) => {
                                    if (Result.isSuccess(res)) {
                                        Result.applyResult(res, stateUserList, true);
                                        AppManager.makeUpdate();
                                    }
                                })
                                .catch((err) => {});
                        }
                    }

                    /*post('/recup', {
                        id_list: haveIdList,
                    }).then((res: any) => {
                        if (Result.isSuccess(res)) {
                            Result.applyResult(res, stateUserList);
                        }
                    });
                    post('/recup_comming', {
                        id_list: haveIdList,
                    }).then((res: any) => {
                        if (Result.isSuccess(res)) {
                            Result.applyResult(res, stateUserList);
                        }
                    });*/
                })
                .catch((e) => {});
        }
    };

    const reset = () => {
        setStateUserList(new AJOState(new AJOList<User>(User)));
        setLoadingUser([] as Date[]);
        fetchElem();
    };

    useEffect(() => {
        fetchElem();
    }, [diff]);

    const [editUser, setEditUser] = useState<User | null>(null);
    const [showAddFerie, setShowAddFerie] = useState(false);
    const [showAddConge, setShowAddConge] = useState(false);

    let userList = (stateUserList.get()?.getList() ?? []).filter((user) => {
        return (user.delete.get() ?? false) === false;
    });

    let fullTimerList = [] as Timer[];
    userList.forEach((user) => {
        fullTimerList = fullTimerList.concat(user.timerList.getList() ?? []);
    });

    const loadingTab = loadingUser.find((d: Date) => d.getTime() === finalDate.getTime()) === undefined;

    return (
        <BasePage className="flex-col flex items-center">
            <DashboardNavBar />
            <Container maxWidth={false}>
                <Box
                    className="flex flex-row"
                    sx={{
                        gap: 3,
                    }}
                >
                    <Box className="flex-1" sx={{ mb: 3, mt: 3 }}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                m: -1,
                            }}
                        >
                            <Typography sx={{ m: 1 }} variant="h4">
                                Heure
                            </Typography>
                            <Box sx={{ m: 1 }} className="flex flex-col gap-3">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className="!pl-3 gap-2"
                                    onClick={() => {
                                        setShowAddFerie(true);
                                    }}
                                >
                                    <AddIcon />
                                    Ajouter un jour non travaillé
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className="!pl-3 gap-2"
                                    onClick={() => {
                                        setShowAddConge(true);
                                    }}
                                >
                                    <AddIcon />
                                    Ajouter une période de congé
                                </Button>
                                <AddFerieModal
                                    show={showAddFerie}
                                    onClose={() => {
                                        setShowAddFerie(false);
                                    }}
                                />
                                <AddCongeModal
                                    show={showAddConge}
                                    onClose={() => {
                                        setShowAddConge(false);
                                    }}
                                    reset={() => {
                                        reset();
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <Card>
                                <CardContent className="">
                                    <Box key={0} className="flex gap-4 flex-row items-center">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setDiff(diff - 1);
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </Button>
                                        <p className="font-medium text-center flex-1 text-xl flex flex-row gap-3 justify-center items-center">
                                            {Utils.getCurrentWeek(Utils.getMonday(finalDate))}
                                            <ExportButton current={finalDate} user={null} />
                                        </p>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setDiff(diff + 1);
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <Card>
                                <PerfectTable
                                    list={stateUserList
                                        .get()!
                                        .getList()
                                        .filter((user) => {
                                            return (
                                                true ||
                                                user
                                                    .getTimerFull(
                                                        Utils.getMonday(finalDate),
                                                        Utils.getSunday(finalDate),
                                                    )
                                                    .getTime() > 0
                                            );
                                        })}
                                    nothing={
                                        <Box className="p-20 flex items-center justify-center">
                                            <Typography variant="h5">
                                                Aucun utilisateur n'a travaillé cette semaine
                                            </Typography>
                                        </Box>
                                    }
                                    loading={loadingTab}
                                    columns={[
                                        {
                                            name: 'Nom',

                                            canSort: true,
                                            sort: 1,
                                            funSort: (a: User, b: User) => {
                                                return a.getFullName().localeCompare(b.getFullName());
                                            },

                                            render: (d: User) => {
                                                return (
                                                    <Box className="flex flex-row gap-2">
                                                        <Link to={'/calendar/' + d.getAjoIdentifier()}>
                                                            <Box className="flex flex-row gap-2 items-center">
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    sx={{ padding: '0.35rem', minWidth: '1rem' }}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 24 24"
                                                                        fill="currentColor"
                                                                        className="w-6 h-6"
                                                                    >
                                                                        <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                                                                            clipRule="evenodd"
                                                                        />
                                                                    </svg>
                                                                </Button>
                                                                <Typography variant="h6">{d.getFullName()}</Typography>
                                                            </Box>
                                                        </Link>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => {
                                                                setEditUser(d);
                                                            }}
                                                            sx={{ padding: '0.35rem', minWidth: '1rem' }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                fill="currentColor"
                                                                className="w-6 h-6"
                                                            >
                                                                <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                                                <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                                            </svg>
                                                        </Button>
                                                    </Box>
                                                );
                                            },
                                        },
                                        {
                                            name: 'Contrat',

                                            canSort: true,
                                            sort: 0,
                                            funSort: (a: User, b: User) => {
                                                return a.nb_heure.get() - b.nb_heure.get();
                                            },

                                            render: (user: User) => {
                                                return (
                                                    <TimerView
                                                        date={new Date(0)}
                                                        canJour={false}
                                                        dateEnd={new Date(user.nb_heure.get() * 60 * 60 * 1000)}
                                                    ></TimerView>
                                                );
                                            },
                                        },
                                        {
                                            name: 'Total',

                                            canSort: true,
                                            sort: 0,
                                            funSort: (a: User, b: User) => {
                                                return (
                                                    a
                                                        .getTimerFull(
                                                            Utils.getMonday(finalDate),
                                                            Utils.getSunday(finalDate),
                                                        )
                                                        .getTime() -
                                                    b
                                                        .getTimerFull(
                                                            Utils.getMonday(finalDate),
                                                            Utils.getSunday(finalDate),
                                                        )
                                                        .getTime()
                                                );
                                            },

                                            render: (user: User) => {
                                                return (
                                                    <TimerView
                                                        date={new Date(0)}
                                                        canJour={false}
                                                        dateEnd={user.getTimerFull(
                                                            Utils.getMonday(finalDate),
                                                            Utils.getSunday(finalDate),
                                                        )}
                                                    ></TimerView>
                                                );
                                            },
                                        },
                                        {
                                            canSort: true,
                                            sort: 0,
                                            funSort: (a: User, b: User) => {
                                                if (a.global_recup.get() === undefined) {
                                                    return 0;
                                                }
                                                if (b.global_recup.get() === undefined) {
                                                    return 0;
                                                }

                                                return -a.global_recup.get() + b.global_recup.get();
                                            },

                                            name: 'Récup',
                                            render: (user: User) => {
                                                if (user.global_recup.get() === undefined) {
                                                    return <p>Chargement ...</p>;
                                                } else {
                                                    return (
                                                        <TimerView
                                                            date={new Date(0)}
                                                            dateEnd={new Date(user.global_recup.get() * 1000)}
                                                        ></TimerView>
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            canSort: true,
                                            sort: 0,
                                            funSort: (a: User, b: User) => {
                                                if (a.global_take_recup.get() === undefined) {
                                                    return 0;
                                                }
                                                if (b.global_take_recup.get() === undefined) {
                                                    return 0;
                                                }

                                                return -a.global_take_recup.get() + b.global_take_recup.get();
                                            },

                                            name: 'Récup posé',
                                            render: (user: User) => {
                                                if (user.global_take_recup.get() === undefined) {
                                                    return <p>Chargement ...</p>;
                                                } else {
                                                    return (
                                                        <TimerView
                                                            date={new Date(0)}
                                                            dateEnd={new Date(user.global_take_recup.get() * 1000)}
                                                        ></TimerView>
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            canSort: true,
                                            sort: 0,
                                            funSort: (a: User, b: User) => {
                                                if (a.recup.get() === undefined) {
                                                    return 0;
                                                }
                                                if (b.recup.get() === undefined) {
                                                    return 0;
                                                }

                                                return (
                                                    -(a.global_recup.get() * 1000 - a.global_take_recup.get() * 1000) +
                                                    (b.global_recup.get() * 1000 - b.global_take_recup.get() * 1000)
                                                );
                                            },

                                            name: 'Récup à venir',
                                            render: (user: User) => {
                                                if (user.global_take_recup.get() === undefined) {
                                                    return <p>Chargement ...</p>;
                                                } else {
                                                    return (
                                                        <TimerView
                                                            date={new Date(0)}
                                                            dateEnd={
                                                                new Date(
                                                                    user.global_recup.get() * 1000 -
                                                                        user.global_take_recup.get() * 1000,
                                                                )
                                                            }
                                                        ></TimerView>
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            name: 'Action',
                                            render: (user: User) => {
                                                return (
                                                    <div className="flex flex-row gap-3">
                                                        <ExportButton current={finalDate} user={user} />
                                                    </div>
                                                );
                                            },
                                        },
                                    ]}
                                />
                            </Card>
                        </Box>
                        <UserTimerModal
                            handleClose={() => {
                                setEditUser(null);
                            }}
                            show={editUser !== null}
                            user={editUser ?? new User()}
                        />
                    </Box>
                    <Box>
                        <RecapTimerView
                            loading={loadingTab}
                            baseClass=""
                            startDate={Utils.getMonday(finalDate)}
                            endDate={Utils.getSunday(finalDate)}
                            timerList={fullTimerList}
                        />
                    </Box>
                </Box>
            </Container>
        </BasePage>
    );
}
