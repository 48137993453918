import { AJOObject } from 'mp-js-react-auto-json-object';
import { Link } from 'react-router-dom';
import { AccordeonTable } from '../../component/AccordeonTable';
import { NoteList } from '../../component/NoteList';
import { DoubleDateModal } from '../../modal/vendor/DoubleDateModal';
import Note from '../../object/Note';
import { Col } from '../table/Col';
import Table from '../table/Table';

export interface ComColInterface {
    ComColInterface: boolean;
    getNoteList(): Note[];
}

export class ComCol<Type extends AJOObject> extends Col<Type> {
    constructor(table: Table<Type>, get: (elem: Type) => AJOObject = (elem) => elem, name: string = 'Commentaire') {
        super(name);
        this.render = (elem: Type) => {
            if ('ComColInterface' in get(elem)) {
                let inter = get(elem) as unknown as ComColInterface;
                let res;
                res = <NoteList noteList={inter.getNoteList()} />;
                return res;
            } else {
                throw new Error('ComColInterface not found in elem');
            }
        };
        this.sort = (a: Type, b: Type) => {
            if ('ComColInterface' in get(a) && 'ComColInterface' in get(b)) {
                let aInter = get(a) as unknown as ComColInterface;
                let bInter = get(b) as unknown as ComColInterface;
                return -aInter.getNoteList().length + bInter.getNoteList().length;
            } else {
                throw new Error('ComColInterface not found in a or b');
            }
        };
        this.table = table;
        this.type = 'sort';
        this.senseSort = 0;
        this.typeRender = 'info';
    }
}
