import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { Grid, Pagination } from '@mui/material';
import { PerfectTable } from '../../commun/table/PerfectTable';
import Utils from '../../commun/Utils';
import { AddIcon } from '../../icons/AddIcon';
import { SearchIcon } from '../../icons/SearchIcon';
import Category from '../../object/tag/Category';
import { BinIcon } from '../../icons/BinIcon';
import { EditIcon } from '../../icons/EditIcon';
import { SwitchIcon } from '../../icons/SwitchIcon';
import FusionCategoryModal from '../category/FusionCategoryModal';
import AddCategoryModal from '../category/AddCategoryModal';
import DelCategoryModal from '../category/DelCategoryModal ';
import CategoryEvent from 'src/object/tag/CategoryEvent';
import { Agence } from 'src/object/Agence';
import { AgenceModal } from 'src/modal/agence/AgenceModal';

export interface IClientAgenceViewProps {
    list: Agence[];
    loading: boolean;
}

export const ClientAgenceView = (props: IClientAgenceViewProps) => {
    const [search, setSearch] = useState('');

    const list = props.list.filter((category: Agence) => {
        return (
            Utils.isSearch(search, category.code.get()) ||
            Utils.isSearch(search, category.display_name.get()) ||
            Utils.isSearch(search, category.juridique_name.get())
        );
    });

    const [agence, setAgence] = useState<Agence | undefined>(undefined);
    const [addAgenceShow, setAddAgenceShow] = useState(false);

    return (
        <Container maxWidth={false}>
            <Box sx={{ mb: 3, mt: 3 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        m: -1,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        Agence
                    </Typography>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Card>
                        <CardContent className="w-full">
                            <Box className="flex w-full items-center flex-row">
                                <Box sx={{ maxWidth: 500 }}>
                                    <TextField
                                        fullWidth
                                        value={search}
                                        onChange={(e) => {
                                            setSearch((e.target as any).value);
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon color="action" fontSize="small">
                                                        <SearchIcon />
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder="Rechercher une agence"
                                        variant="outlined"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            <Box>
                <Grid container spacing={3}>
                    {list.map((value: Agence, i: number) => {
                        return (
                            <Grid key={i} item lg={4} md={4} xs={6}>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    <CardContent className={'bg-slate-600 flex items-center justify-center'}>
                                        <Box>
                                            <Typography className="text-white !text-lg py-5 !font-bold text-center">
                                                {value.code.get()}
                                            </Typography>
                                        </Box>
                                    </CardContent>

                                    <Box className="flex flex-row p-2 gap-2">
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className="!pl-3 flex-1 gap-2"
                                            onClick={() => {
                                                setAgence(value);
                                                setAddAgenceShow(true);
                                            }}
                                        >
                                            <EditIcon />
                                            Modifier
                                        </Button>
                                    </Box>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
            {
                <AgenceModal
                    action="edit"
                    onClose={() => {
                        setAddAgenceShow(false);
                    }}
                    onObjectChange={(agence: string) => {
                        //setAgence(agence);
                    }}
                    show={addAgenceShow}
                    elem={agence}
                />
            }
        </Container>
    );
};
