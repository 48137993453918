import AppManager from '../AppManager';

export default class Toast {
    public message: string;
    public type: 'success' | 'error' | 'warning' | 'info';

    public time: number = -1;

    constructor(message: string, type: 'success' | 'error' | 'warning' | 'info') {
        this.message = message;
        this.type = type;
    }
    public start(): void {
        setTimeout(() => {
            this.stop();
        }, AppManager.TOAST_TIME);
    }
    public stop(): void {
        AppManager.removeToast(this);
    }
}
