import { Route, Routes, useNavigate } from 'react-router-dom';
import { Home } from './page/Home';
import { Connection } from './page/Connection';
import { Quotation } from './page/Quotation';
import { UserPage } from './page/UserPage';
import { Customer } from './page/Customer';
import AppManager from './commun/AppManager';
import { RolePage } from './page/RolePage';
import { VendorPage } from './page/VendorPage';
import { PrestationPage } from './page/PrestationPage';
import { ClientPage } from './page/ClientPage';
import { HelpPage } from './page/HelpPage';
import { BDDVendorPage } from './page/BDDVendorPage';
import { BDDClientPage } from './page/BDDClientPage';
import { TemplatePage } from './page/TemplatePage';
import { DevisPage } from './page/DevisPage';
import { AccountPage } from './page/AccountPage';
import { CalendarPage } from './page/CalendarPage';
import { BDDCommandPage } from './page/BDDCommandPage';
import { ManagePage } from './page/ManagePage';
import { MessagePage } from './page/MessagePage';
import { PricePage } from './page/PricePage';
import { RecupPage } from './page/RecupPage';
import { HorairePage } from './page/HorairePage';
import { BoardPage } from './page/BoardPage';
import { BoardListPage } from './page/BoardListPage';
import { ClosePage } from './page/ClosePage';

export function AppRoutes() {
    const navigate = useNavigate();
    AppManager.setNavigate(navigate);
    return (
        <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/quotation" element={<Quotation />} />
            <Route path="/connection" element={<Connection />} />
            <Route path="/customer" element={<Customer />} />

            <Route path="/vendor" element={<BDDVendorPage />} />
            <Route path="/client" element={<BDDClientPage />} />
            <Route path="/command" element={<BDDCommandPage />} />

            <Route path="/vendor/:id" element={<VendorPage />} />
            <Route path="/devis/:id" element={<DevisPage />} />
            <Route path="/prestation/:id" element={<PrestationPage />} />
            <Route path="/client/:id" element={<ClientPage />} />
            <Route path="/template/:id" element={<TemplatePage />} />

            <Route path="/recup" element={<RecupPage />} />
            <Route path="/heure" element={<HorairePage />} />

            <Route path="/price" element={<PricePage />} />
            <Route path="/message" element={<MessagePage />} />
            <Route path="/user" element={<UserPage />} />
            <Route path="/role" element={<RolePage />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/me" element={<AccountPage />} />
            <Route path="/manage" element={<ManagePage />} />

            <Route path="/board" element={<BoardListPage />} />
            <Route path="/board/:id" element={<BoardPage />} />

            <Route path="/close" element={<ClosePage />} />

            <Route path="/calendar/:id" element={<CalendarPage />} />
            <Route path="/calendar/:id/:date" element={<CalendarPage />} />
            <Route path="*" element={<Home />} />
        </Routes>
    );
}
