export default class ErrorHTTP {
    public static LOGOUT() {
        return new ErrorHTTP('logout');
    }
    public static NO_ERROR() {
        return new ErrorHTTP('no_error');
    }
    public static WRONG_PASSWORD() {
        return new ErrorHTTP('wrong_password');
    }
    public static NOT_FOUND() {
        return new ErrorHTTP('not_found');
    }
    public static NO_PERMISSION() {
        return new ErrorHTTP('no_permission');
    }
    public static BAD_VALUE() {
        return new ErrorHTTP('bad_value');
    }
    public static EXISTS() {
        return new ErrorHTTP('exists');
    }

    /**
     * FIRSTNAME
     */
    public static BAD_FIRSTNAME_TYPE() {
        return new ErrorHTTP('bad_firstname_type');
    }
    public static BAD_FIRSTNAME_MIN() {
        return new ErrorHTTP('bad_firstname_min');
    }
    public static BAD_FIRSTNAME_MAX() {
        return new ErrorHTTP('bad_firstname_max');
    }
    public static BAD_FIRSTNAME_ENTER() {
        return new ErrorHTTP('bad_firstname_enter');
    }

    /**
     * LASTNAME
     */
    public static BAD_LASTNAME_TYPE() {
        return new ErrorHTTP('bad_lastname_type');
    }
    public static BAD_LASTNAME_MIN() {
        return new ErrorHTTP('bad_lastname_min');
    }
    public static BAD_LASTNAME_MAX() {
        return new ErrorHTTP('bad_lastname_max');
    }
    public static BAD_LASTNAME_ENTER() {
        return new ErrorHTTP('bad_lastname_enter');
    }

    /**
     * PHONE
     */
    public static BAD_PHONE_TYPE() {
        return new ErrorHTTP('bad_phone_type');
    }
    public static BAD_PHONE_MIN() {
        return new ErrorHTTP('bad_phone_min');
    }
    public static BAD_PHONE_MAX() {
        return new ErrorHTTP('bad_phone_max');
    }
    public static BAD_PHONE_ENTER() {
        return new ErrorHTTP('bad_phone_enter');
    }

    /**
     * LASTNAME
     */
    public static BAD_NAME_TYPE() {
        return new ErrorHTTP('bad_name_type');
    }
    public static BAD_NAME_MIN() {
        return new ErrorHTTP('bad_name_min');
    }
    public static BAD_NAME_MAX() {
        return new ErrorHTTP('bad_name_max');
    }
    public static BAD_NAME_ENTER() {
        return new ErrorHTTP('bad_name_enter');
    }

    /**
     * DESCRIPTION
     */
    public static BAD_DESCRIPTION_TYPE() {
        return new ErrorHTTP('bad_description_type');
    }
    public static BAD_DESCRIPTION_MIN() {
        return new ErrorHTTP('bad_description_min');
    }
    public static BAD_DESCRIPTION_MAX() {
        return new ErrorHTTP('bad_description_max');
    }
    public static BAD_DESCRIPTION_ENTER() {
        return new ErrorHTTP('bad_description_enter');
    }

    /**
     * POSTAL CODE
     */
    public static BAD_POSTAL_CODE_TYPE() {
        return new ErrorHTTP('bad_postal_code_type');
    }
    public static BAD_POSTAL_CODE_ENTER() {
        return new ErrorHTTP('bad_postal_code_enter');
    }

    /**
     * City
     */
    public static BAD_city_ENTER() {
        return new ErrorHTTP('bad_city_enter');
    }

    /**
     * EMAIL
     */
    public static BAD_EMAIL_TYPE() {
        return new ErrorHTTP('bad_email_type');
    }
    public static BAD_EMAIL_MIN() {
        return new ErrorHTTP('bad_email_min');
    }
    public static BAD_EMAIL_MAX() {
        return new ErrorHTTP('bad_email_max');
    }
    public static BAD_EMAIL_ENTER() {
        return new ErrorHTTP('bad_email_enter');
    }

    /**
     * PASSWORD
     */
    public static BAD_PASSWORD_TYPE() {
        return new ErrorHTTP('bad_password_type');
    }
    public static BAD_PASSWORD_MIN() {
        return new ErrorHTTP('bad_password_min');
    }
    public static BAD_PASSWORD_MAX() {
        return new ErrorHTTP('bad_password_max');
    }
    public static BAD_PASSWORD_ENTER() {
        return new ErrorHTTP('bad_password_enter');
    }

    public static NO_CHANGE() {
        return new ErrorHTTP('no_change');
    }
    public static SUCCESS() {
        return new ErrorHTTP('success');
    }
    public static NO_PERMISSION_ROOT() {
        return new ErrorHTTP('no_permission_root');
    }
    public static NOT_MATCH() {
        return new ErrorHTTP('not_match');
    }
    public static USER_DELETED() {
        return new ErrorHTTP('user_deleted');
    }
    public static USER_PASSWORD_INIT() {
        return new ErrorHTTP('user_password_init');
    }
    public static USER_PASSWORD_CHANGE() {
        return new ErrorHTTP('user_password_change');
    }

    private name: string;
    private color: string;
    private darkColor: string;

    constructor(name: string, color: string = 'red-500', darkColor: string = 'red-300') {
        this.name = name;
        this.color = color;
        this.darkColor = darkColor;
    }
    public setName(name: string): ErrorHTTP {
        this.name = name;
        return this;
    }
    public setColor(color: string): ErrorHTTP {
        this.color = color;
        return this;
    }
    public setColorDark(darkColor: string): ErrorHTTP {
        this.darkColor = darkColor;
        return this;
    }
    public getName(): string {
        return this.name;
    }
    public getColor(): string {
        return this.color;
    }
    public getDarkColor(): string {
        return this.darkColor;
    }
    public equals(error: ErrorHTTP): boolean {
        return this.name === error.getName();
    }
}
