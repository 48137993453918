import Budget from '../object/Budget';
import { Marge } from '../object/Marge';
import { Reduc } from '../object/marge_reduc/Reduc';
import { Paper } from '../object/Paper';

export type Color =
    | 'white'
    | 'slate'
    | 'gray'
    | 'zinc'
    | 'neutral'
    | 'stone'
    | 'red'
    | 'orange'
    | 'amber'
    | 'yellow'
    | 'lime'
    | 'green'
    | 'emerald'
    | 'teal'
    | 'cyan'
    | 'sky'
    | 'blue'
    | 'indigo'
    | 'violet'
    | 'purple'
    | 'fuchsia'
    | 'pink'
    | 'rose';
export const COLOR_LIST = [
    'event-com',
    'event-interne',
    'event-externe',
    'stone',
    'slate',
    'red',
    'orange',
    'yellow',
    'lime',
    'green',
    'emerald',
    'teal',
    'cyan',
    'sky',
    'blue',
    'indigo',
    'violet',
    'fuchsia',
    'pink',
    'rose',
];
export const COLOR_LIST_TAB = [
    'red',
    'orange',
    'yellow',
    'lime',
    'green',
    'emerald',
    'teal',
    'cyan',
    'sky',
    'blue',
    'indigo',
    'violet',
    'fuchsia',
    'pink',
    'rose',
    'stone',
    'slate',
];
export const COLOR_LIST_HORAIRE = [
    'stone',
    'slate',
    'red',
    'orange',
    'yellow',
    'lime',
    'green',
    'emerald',
    'teal',
    'cyan',
    'sky',
    'blue',
    'indigo',
    'violet',
    'fuchsia',
    'pink',
    'rose',
];
export type ModalBottomMode = 'close' | 'action';
export type Size = 'sm' | 'md' | 'lg' | 'xl';
export type ElemBudget = Paper | Budget | Marge | Reduc;

export const sortElemBudget = (a: ElemBudget, b: ElemBudget) => {
    let res = 0;
    if (a instanceof Reduc && b instanceof Reduc) {
        res = 0;
    } else if (a instanceof Marge && b instanceof Marge) {
        res = 0;
    } else if (a instanceof Paper && b instanceof Paper) {
        res = a.func.get() - b.func.get();
    } else if (a instanceof Budget && b instanceof Budget) {
        if (a.option.get() == b.option.get()) {
            res = -new Date(a.date_now.get()).getTime() + new Date(b.date_now.get()).getTime();
        } else {
            if (a.option.get()) {
                res = 1;
            } else {
                res = -1;
            }
        }
    } else if (a instanceof Budget) {
        return -1;
    } else if (a instanceof Paper) {
        if (b instanceof Marge || b instanceof Reduc) {
            return -1;
        } else {
            return 1;
        }
    } else if (a instanceof Marge) {
        if (b instanceof Reduc) {
            return -1;
        } else {
            return 1;
        }
    } else if (a instanceof Reduc) {
        return 1;
    }
    return res;
};
