import ErrorHTTP from './ErrorHTTP';

export default class ErrorMessage {
    /**
     * FIRSTNAME
     */
    public static BAD_FIRSTNAME_TYPE() {
        return new ErrorMessage(ErrorHTTP.BAD_FIRSTNAME_TYPE(), '');
    }
    public static BAD_FIRSTNAME_MIN() {
        return new ErrorMessage(ErrorHTTP.BAD_FIRSTNAME_MIN(), "Veuillez entrer un prénom d'au moins un caractère.");
    }
    public static BAD_FIRSTNAME_MAX() {
        return new ErrorMessage(ErrorHTTP.BAD_FIRSTNAME_MAX(), 'Veuillez entrer un prénom de moins de 40 caractères.');
    }
    public static BAD_FIRSTNAME_ENTER() {
        return new ErrorMessage(ErrorHTTP.BAD_FIRSTNAME_ENTER(), 'Veuillez saisir un prénom.');
    }

    /**
     * LASTNAME
     */
    public static BAD_NAME_TYPE() {
        return new ErrorMessage(ErrorHTTP.BAD_NAME_TYPE(), '');
    }
    public static BAD_NAME_MIN() {
        return new ErrorMessage(ErrorHTTP.BAD_NAME_MIN(), "Veuillez entrer un nom d'au moins un caractère.");
    }
    public static BAD_NAME_MAX() {
        return new ErrorMessage(ErrorHTTP.BAD_NAME_MAX(), 'Veuillez entrer un nom de moins de 40 caractères.');
    }
    public static BAD_NAME_ENTER() {
        return new ErrorMessage(ErrorHTTP.BAD_NAME_ENTER(), 'Veuillez saisir un nom.');
    }
    /**
     * DESCRIPTION
     */
    public static BAD_DESCRIPTION_TYPE() {
        return new ErrorMessage(ErrorHTTP.BAD_DESCRIPTION_TYPE(), '');
    }
    public static BAD_DESCRIPTION_MIN() {
        return new ErrorMessage(
            ErrorHTTP.BAD_DESCRIPTION_MIN(),
            "Veuillez entrer une description d'au moins un caractère.",
        );
    }
    public static BAD_DESCRIPTION_MAX() {
        return new ErrorMessage(
            ErrorHTTP.BAD_DESCRIPTION_MAX(),
            'Veuillez entrer une description de moins de 250 caractères.',
        );
    }
    public static BAD_DESCRIPTION_ENTER() {
        return new ErrorMessage(ErrorHTTP.BAD_DESCRIPTION_ENTER(), 'Veuillez saisir une description.');
    }

    /**
     * POSTAL CODE
     */
    public static BAD_POSTAL_CODE_TYPE() {
        return new ErrorMessage(ErrorHTTP.BAD_POSTAL_CODE_TYPE(), 'Le code postal doit être un nombre.');
    }
    public static BAD_POSTAL_CODE_ENTER() {
        return new ErrorMessage(ErrorHTTP.BAD_POSTAL_CODE_ENTER(), 'Veuillez saisir un code postal.');
    }
    /**
     * LASTNAME
     */
    public static BAD_LASTNAME_TYPE() {
        return new ErrorMessage(ErrorHTTP.BAD_LASTNAME_TYPE(), '');
    }
    public static BAD_LASTNAME_MIN() {
        return new ErrorMessage(ErrorHTTP.BAD_LASTNAME_MIN(), "Veuillez entrer un nom d'au moins un caractère.");
    }
    public static BAD_LASTNAME_MAX() {
        return new ErrorMessage(ErrorHTTP.BAD_LASTNAME_MAX(), 'Veuillez entrer un nom de moins de 40 caractères.');
    }
    public static BAD_LASTNAME_ENTER() {
        return new ErrorMessage(ErrorHTTP.BAD_LASTNAME_ENTER(), 'Veuillez saisir un nom.');
    }
    /**
     * PHONE
     */
    public static BAD_PHONE_TYPE() {
        return new ErrorMessage(ErrorHTTP.BAD_PHONE_TYPE(), '');
    }
    public static BAD_PHONE_MIN() {
        return new ErrorMessage(ErrorHTTP.BAD_PHONE_MIN(), 'Veuillez entrer un numéro de téléphone.');
    }
    public static BAD_PHONE_MAX() {
        return new ErrorMessage(
            ErrorHTTP.BAD_PHONE_MAX(),
            'Veuillez entrer un numéro de téléphone de moins de 20 caractères.',
        );
    }
    public static BAD_PHONE_ENTER() {
        return new ErrorMessage(ErrorHTTP.BAD_PHONE_ENTER(), 'Veuillez saisir un numéro de téléphone valide.');
    }

    /**
     * EMAIL
     */
    public static BAD_EMAIL_TYPE() {
        return new ErrorMessage(ErrorHTTP.BAD_EMAIL_TYPE(), 'Veuillez saisir une adresse email valide.');
    }
    public static BAD_EMAIL_MIN() {
        return new ErrorMessage(
            ErrorHTTP.BAD_EMAIL_MIN(),
            "Veuillez entrer une adresse email d'au moins un caractère.",
        );
    }
    public static BAD_EMAIL_MAX() {
        return new ErrorMessage(
            ErrorHTTP.BAD_EMAIL_MAX(),
            'Veuillez entrer une adresse email de moins de 150 caractères.',
        );
    }
    public static BAD_EMAIL_ENTER() {
        return new ErrorMessage(ErrorHTTP.BAD_EMAIL_ENTER(), 'Veuillez saisir une adresse email.');
    }

    /**
     * PASSWORD
     */
    public static BAD_PASSWORD_TYPE() {
        return new ErrorMessage(
            ErrorHTTP.BAD_PASSWORD_TYPE(),
            'Veuillez saisir un mot de passe valide. Il doit contenir au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial.',
        );
    }
    public static BAD_PASSWORD_MIN() {
        return new ErrorMessage(
            ErrorHTTP.BAD_PASSWORD_MIN(),
            "Veuillez entrer un mot de passe d'au moins 8 caractères.",
        );
    }
    public static BAD_PASSWORD_MAX() {
        return new ErrorMessage(
            ErrorHTTP.BAD_PASSWORD_MAX(),
            'Veuillez entrer un mot de passe de moins de 150 caractères.',
        );
    }
    public static BAD_PASSWORD_ENTER() {
        return new ErrorMessage(ErrorHTTP.BAD_PASSWORD_ENTER(), 'Veuillez saisir une mot de passe.');
    }

    public static NO_PERMISSION() {
        return new ErrorMessage(
            ErrorHTTP.NO_PERMISSION(),
            'Vous ne disposez pas des autorisations nécessaires pour effectuer cette action.',
        );
    }

    public static NO_CHANGE() {
        return new ErrorMessage(ErrorHTTP.NO_CHANGE(), 'Veuillez saisir un changement.');
    }

    public static BAD_VALUE() {
        return new ErrorMessage(ErrorHTTP.BAD_VALUE(), 'Les informations saisies sont incorrectes.');
    }

    public static NO_PERMISSION_ROOT() {
        return new ErrorMessage(
            ErrorHTTP.NO_PERMISSION_ROOT(),
            'Vous ne pouvez pas effectuer cette action sur un utilisateur racine.',
        );
    }
    public static WRONG_PASSWORD() {
        return new ErrorMessage(
            ErrorHTTP.WRONG_PASSWORD(),
            'Les informations saisies ne correspondent pas à celles de notre base de données.',
        );
    }
    public static USER_DELETED() {
        return new ErrorMessage(
            ErrorHTTP.USER_DELETED(),
            'Votre compte a été supprimé, vous ne pouvez plus réaliser aucune action.',
        );
    }
    public static USER_PASSWORD_INIT() {
        return new ErrorMessage(
            ErrorHTTP.USER_PASSWORD_INIT(),
            "Première connexion. Vous ne pourrez pas utiliser votre compte tant que vous n'aurez pas changé votre mot de passe.",
        );
    }
    public static USER_PASSWORD_CHANGE() {
        return new ErrorMessage(
            ErrorHTTP.USER_PASSWORD_CHANGE(),
            "Demande de changement de mot de passe. Vous ne pourrez pas utiliser votre compte tant que vous n'aurez pas changé votre mot de passe.",
        );
    }

    private error: ErrorHTTP;
    private message: string;

    constructor(error: ErrorHTTP, message: string) {
        this.error = error;
        this.message = message;
    }
    public getError(): ErrorHTTP {
        return this.error;
    }
    public getMessage(): string {
        return this.message;
    }
}
