import { AJOObject, AJOProperties, AJOSimple } from 'mp-js-react-auto-json-object';
import User from './User';

export default class Note extends AJOObject {
    public static _TYPE: string = 'Note';

    public rate: AJOProperties<any>;
    public comment: AJOProperties<any>;
    public user: AJOSimple<User>;

    constructor() {
        super(Note._TYPE);

        this.rate = new AJOProperties('rate');
        this.comment = new AJOProperties('comment');
        this.user = new AJOSimple<User>(User, 'has_note', this);
    }

    public static build(): Note {
        return new Note();
    }
}
