import { AJOList, AJOSimple } from 'mp-js-react-auto-json-object';
import { errorManager } from '../modal/vendor/CategoryErrorManager';
import { AJOProperties } from 'mp-js-react-auto-json-object';
import { AJOObject } from 'mp-js-react-auto-json-object';
import AppManager from '../commun/AppManager';
import { post } from '../commun/HTTP';
import Result from '../commun/Result';
import Toast from '../commun/toast/Toast';
import { Agence } from './Agence';
import Address from './Address';
import BasePrice from './BasePrice';
import { Color } from '../commun/Constant';
import Budget from './Budget';
import { Paper } from './Paper';
import Vendor from './profile/Vendor';
import Category from './tag/Category';
export class FlexBonCommande extends BasePrice {
    public static _TYPE: string = 'FlexBonCommande';

    public laoding: boolean = false;

    public isGoodVa() {
        return false;
    }

    public name: AJOProperties<any>;
    public date: AJOProperties<any>;
    public agence: AJOSimple<Agence>;
    public address: AJOSimple<Address>;
    public budgetList: AJOList<Budget>;

    public isBlock(): boolean {
        return false;
    }
    public getBudgetCommandList(): string[] {
        const budegtList = [] as string[];
        for (const budget of this.budgetList.getList()) {
            if (budget.fullComplite() && budget.bon_commande.get() == null) {
                budegtList.push(budget.getAjoIdentifier());
            }
        }
        return budegtList;
    }
    public getCategoryList(): Category[] {
        let res: Category[] = [];
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget && budget.prestation.get() != null) {
                let cateList = budget.prestation.get()!.getCategoryList();
                for (const cate of cateList) {
                    if (res.find((c: Category) => cate.getAjoIdentifier() === c.getAjoIdentifier()) === undefined) {
                        res.push(cate);
                    }
                }
            }
        }
        return res;
    }

    public getVendorList(): Vendor[] {
        let vendorList = [] as Vendor[];
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget && budget.choice.get() !== false && budget.vendor.get() != null) {
                let found = vendorList.find(
                    (v: Vendor) => v.getAjoIdentifier() === budget.vendor.get()?.getAjoIdentifier() ?? '',
                );
                if (!found) {
                    vendorList.push(budget.vendor.get()!);
                }
            } else if ((budget as any) instanceof Paper) {
                let childList = (budget as any).getVendorList();
                for (const child of childList) {
                    let found = vendorList.find((v: Vendor) => v.getAjoIdentifier() === child.getAjoIdentifier());
                    if (!found) {
                        vendorList.push(child);
                    }
                }
            }
        }
        return vendorList;
    }

    public removeAllFocus() {
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                if (budget.focus) {
                    budget.focus = false;
                    budget.rowState?.makeUpdate();
                } else if ((budget as any) instanceof Paper) {
                    (budget as any as Paper).removeFocus();
                }
            }
        }
    }

    public getTitle(): string {
        return this.name.get();
    }

    public getColor(): Color {
        return 'rose';
    }

    public get() {}

    constructor() {
        super(FlexBonCommande._TYPE);

        this.laoding = false;
        this.name = new AJOProperties('name');
        this.date = new AJOProperties('date');
        this.agence = new AJOSimple<Agence>(Agence, 'has_agence', this);
        this.address = new AJOSimple<Address>(Address, 'has_address', this);
        this.budgetList = new AJOList<Budget>(Budget, 'has_budget', this);
    }

    public static build(): FlexBonCommande {
        return new FlexBonCommande();
    }

    public isStep1(): boolean {
        return this.date.get() === undefined || this.agence.get() == null || this.address.get() == null;
    }

    public getDevis(): FlexBonCommande {
        return this;
    }

    public getVendorBudgetList(vendor: Vendor): Budget[] {
        let res: Budget[] = [];
        for (const budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                if (vendor.getAjoIdentifier() === budget.vendor.get()?.getAjoIdentifier()) {
                    res.push(budget);
                }
            } else if ((budget as any) instanceof Paper) {
                res = res.concat((budget as any).getVendorBudgetList(vendor));
            }
        }
        return res;
    }
}
