import { Box, Button, Card, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { PerfectTable } from 'src/commun/table/PerfectTable';
import Utils from 'src/commun/Utils';
import { BonCommande } from 'src/object/BonCommande';
import { SERVER_URL } from 'src/utils/Constant';

export interface BonCommandTableProps {
    loading: boolean;
    list: BonCommande[];
    search: string;
}

export const BonCommandTable = (props: BonCommandTableProps) => {
    const { loading, list, search } = props;
    return (
        <PerfectTable
            loading={loading}
            list={list}
            nothing={
                <Box className="flex flex-col gap-3 items-center justify-center">
                    <Typography>{'Aucun fournisseur trouvé.'}</Typography>
                </Box>
            }
            columns={[
                {
                    canSort: true,
                    sort: 1,
                    funSort: (e1: BonCommande, e2: BonCommande) => {
                        return -e1.dateCreate.get().localeCompare(e2.dateCreate.get());
                    },

                    name: 'Date',
                    render: (elem: BonCommande) => {
                        return (
                            <Box
                                sx={{
                                    gap: 2,
                                }}
                                className="font-semibold flex flex-row items-center"
                            >
                                <Typography variant="h6">
                                    {new Date(elem.dateCreate.get()).toLocaleDateString('fr')}
                                </Typography>
                            </Box>
                        );
                    },
                },
                {
                    canSort: true,
                    sort: 0,
                    funSort: (e1: BonCommande, e2: BonCommande) => {
                        return e1.name.get().localeCompare(e2.name.get());
                    },

                    name: 'Nom',

                    hasFilter: true,
                    funFilter: (vendor: BonCommande) => {
                        const finalSearch = Utils.clean(search.toLowerCase());
                        let can =
                            Utils.clean(vendor.name.get()).toLowerCase().includes(finalSearch) || search.length === 0;
                        if (!can) {
                            can = Utils.clean(vendor.budgetList.getList()[0].getDevis()?.getName() ?? '')
                                .toLowerCase()
                                .includes(finalSearch);
                            can =
                                can ||
                                Utils.clean(vendor.budgetList.getList()[0].vendor.get()?.getName() ?? '')
                                    .toLowerCase()
                                    .includes(finalSearch);
                        }
                        return can;
                    },
                    render: (elem: BonCommande) => {
                        return (
                            <Box
                                sx={{
                                    gap: 2,
                                }}
                                className="font-semibold flex flex-row items-center"
                            >
                                <a href={SERVER_URL + `/command/${elem.code.get()}.pdf`}>
                                    <Button
                                        tabIndex={-1}
                                        sx={{ padding: '0.5rem', minWidth: '1.5rem' }}
                                        variant="contained"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z" />
                                            <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                                        </svg>
                                    </Button>
                                </a>
                                <a href={SERVER_URL + `/command/${elem.code.get()}.docx`}>
                                    <Button
                                        tabIndex={-1}
                                        sx={{ padding: '0.5rem', minWidth: '1.5rem' }}
                                        variant="contained"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M1.5 5.625c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v12.75c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 18.375V5.625zM21 9.375A.375.375 0 0020.625 9h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zM10.875 18.75a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5zM3.375 15h7.5a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375zm0-3.75h7.5a.375.375 0 00.375-.375v-1.5A.375.375 0 0010.875 9h-7.5A.375.375 0 003 9.375v1.5c0 .207.168.375.375.375z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </Button>
                                </a>
                                <Typography variant="h6">{elem.code.get()}</Typography>
                            </Box>
                        );
                    },
                },
                {
                    name: 'Prix de vente',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: BonCommande, e2: BonCommande) => {
                        return (
                            e1.budgetList
                                .getList()[0]
                                .getDevis()
                                ?.getName()
                                ?.localeCompare(e2.budgetList.getList()[0].getDevis()?.getName() || '') || 0
                        );
                    },
                    render: (elem: BonCommande) => {
                        return (
                            <Link to={`/devis/${elem.budgetList.getList()[0].getDevis()?.getAjoIdentifier()}`}>
                                <Card
                                    sx={{
                                        p: 1.5,
                                    }}
                                    className={
                                        'break-inside-avoid-column flex items-center flex-row gap-2 flex-wrap rounded-lg w-full border border-' +
                                        elem.budgetList.getList()[0].getDevis()?.getColorSpec() +
                                        '-200 dark:border-' +
                                        elem.budgetList.getList()[0].getDevis()?.getColorSpec() +
                                        '-500 p-3 !bg-' +
                                        elem.budgetList.getList()[0].getDevis()?.getColorSpec() +
                                        '-100 dark:bg-gray-800'
                                    }
                                >
                                    <Typography
                                        className={
                                            '!text-' + elem.budgetList.getList()[0].getDevis()?.getColorSpec() + '-700'
                                        }
                                    >
                                        {elem.budgetList.getList()[0].getDevis()?.getName()}
                                    </Typography>
                                </Card>
                            </Link>
                        );
                    },
                },
                {
                    name: 'Fournisseur',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: BonCommande, e2: BonCommande) => {
                        return (
                            e1.budgetList
                                .getList()[0]
                                .vendor.get()
                                ?.getName()
                                ?.localeCompare(e2.budgetList.getList()[0].vendor.get()?.getName() || '') || 0
                        );
                    },
                    render: (elem: BonCommande) => {
                        return <Typography>{elem.budgetList.getList()[0].vendor.get()?.getName()}</Typography>;
                    },
                },
            ]}
        />
    );
};
