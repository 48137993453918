import { Box, Typography } from '@mui/material';
import { AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { Spinner } from 'src/commun/component/Spinner';
import MpModal from 'src/component/extends/MpModal';
import BasePrice from 'src/object/BasePrice';
import AppManager from '../../commun/AppManager';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import { PaperList } from '../../component/paper/PaperList';
import Devis from '../../object/Devis';
import { Paper } from '../../object/Paper';
import Template from '../../object/Template';
import { errorManager, paperAddPaperSuccess } from './CategoryErrorManager';

export interface IInsertPaperModalProps {
    show: boolean;
    paper: Paper;
    template: BasePrice;

    handleClose: () => void;
}

export const InsertPaperModal: React.FC<IInsertPaperModalProps> = (props) => {
    const { show, template, paper, handleClose } = props;

    const [selectPaperId, setSelectPaperId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorHTTP>(ErrorHTTP.NO_ERROR());

    let list = [] as Paper[];

    for (let i = 0; i < template.paperList.size(); i++) {
        let paper = template.paperList.get(i);
        if (paper.archive.get() !== true) {
            list.push(template.paperList.get(i));
        }
    }

    useEffect(() => {
        if (show) {
            setSelectPaperId('');
            setLoading(false);
            setError(ErrorHTTP.NO_ERROR());
        }
    }, [props.show]);

    return (
        <MpModal
            onSubmit={() => {
                setLoading(true);
                setError(ErrorHTTP.NO_ERROR());
                post('/paper/add/paper', {
                    id: paper.getAjoIdentifier(),
                    id_child: selectPaperId,
                }).then((res: any) => {
                    if (Result.isSuccess(res)) {
                        Result.applyResult(res, AppManager.updater(), true);

                        for (const paperChild of paper.paperList.getList()) {
                            if (paperChild.getAjoIdentifier() === selectPaperId) {
                                paperChild.fetch();
                                break;
                            }
                        }

                        setError(ErrorHTTP.NO_ERROR());

                        AppManager.addToast(new Toast(errorManager.get(paperAddPaperSuccess), 'success'));
                        props.handleClose();
                    } else {
                        setLoading(false);
                        setError(Result.getError(res));
                    }
                });
            }}
            open={show}
            done={'Ajouter'}
            title={'Choisir la feuille à inclure'}
            onClose={handleClose}
        >
            {loading ? (
                <Box className="flex items-center justify-center py-12">
                    <Spinner color="blue" className="" />
                </Box>
            ) : (
                <div className="text-base font-normal">
                    <PaperList
                        className="flex-wrap"
                        basePrice={template}
                        id={template.getAjoIdentifier()}
                        field={template.getAjoType().toLowerCase()}
                        paperList={list}
                        selectedPaper={selectPaperId}
                        onClick={(e: Paper) => {
                            setSelectPaperId(e.getAjoIdentifier());
                        }}
                    />

                    {!error.equals(ErrorHTTP.NO_ERROR()) && (
                        <Typography
                            component={'p'}
                            className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                        >
                            {errorManager.get(error)}
                        </Typography>
                    )}
                </div>
            )}
        </MpModal>
    );
};
