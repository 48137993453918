import PageObject from './PageObject';

export default class PageManager {
    private static instance = new PageManager();

    private list: PageObject[];

    constructor() {
        this.list = [];
    }

    private static getInstance(): PageManager {
        if (PageManager.instance == null) {
            PageManager.instance = new PageManager();
        }
        return PageManager.instance;
    }
    private setLocation(path: string) {
        for (const pageObject of this.getList()) {
            pageObject.setSelect(pageObject.getPath() == path);
        }
    }
    public static setLocation(path: string) {
        return PageManager.getInstance().setLocation(path);
    }

    private getList(): PageObject[] {
        return this.list;
    }
    public static getList(): PageObject[] {
        return PageManager.getInstance().getList();
    }

    private get(i: number): PageObject {
        return this.list[i];
    }
    public static get(i: number): PageObject {
        return PageManager.getInstance().get(i);
    }

    private add(pageObject: PageObject) {
        this.list.push(pageObject);
    }
    public static add(pageObject: PageObject) {
        PageManager.getInstance().add(pageObject);
    }

    private reset() {
        this.list = [];
    }
    public static reset() {
        PageManager.getInstance().reset();
    }

    private getCurrent(path: string): PageObject {
        let res: PageObject | null = null;

        let i = 0;
        while (res == null && i < this.list.length) {
            if (this.list[i].isSelect(path)) {
                res = this.list[i];
            }
            i++;
        }
        return res ?? this.list[0];
    }

    public static getCurrent(path: string): PageObject {
        return PageManager.getInstance().getCurrent(path);
    }
}
