import { useState } from 'react';
import { Button } from '../commun/component/Button';
import { AddContactModal } from '../modal/vendor/AddContactModal';
import Contact from '../object/Contact';
import { DeleteSvg } from './DeleteSvg';
import { EditSvg } from './EditSvg';
import { contactEditSuccess, facturationAddSuccess } from '../modal/vendor/CategoryErrorManager';
import { AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { Card, Typography } from '@mui/material';

export interface IContactViewProps {
    contact: Contact;
    onDelete: () => void;
    canDelete: boolean;
    parent?: AJOObject;
}

export const ContactView: React.FunctionComponent<IContactViewProps> = (props) => {
    //onDelete
    const { contact, canDelete, parent, onDelete } = props;

    const [showModal, updateShowModal] = useState(false);

    if (contact.getAjoIdentifier() === undefined) {
        return <Typography>Aucun contact</Typography>;
    }
    return (
        <Card className="flex flex-row p-2 gap-2 rounded-lg w-full">
            <div className="flex flex-col justify-center flex-1">
                <span className="text-sm font-medium text-black dark:text-white">{contact.name.get()}</span>
                <span className="text-sm text-gray-600 dark:text-gray-300 break-all">{contact.email.get()}</span>
            </div>
            {canDelete && (
                <div className="flex justify-center items-center">
                    <Button
                        color="blue"
                        pb={1.5}
                        pt={1.5}
                        pr={1.5}
                        pl={1.5}
                        onClick={() => {
                            updateShowModal(true);
                        }}
                        className={'focus:ring-4 rounded-md text-black dark:text-white'}
                    >
                        <EditSvg className="h-5 w-5" />
                    </Button>
                </div>
            )}
            {canDelete && (
                <div className="flex justify-center items-center">
                    <Button
                        color="red"
                        pb={1.5}
                        pt={1.5}
                        pr={1.5}
                        pl={1.5}
                        onClick={onDelete}
                        className={'focus:ring-4 rounded-md text-black dark:text-white'}
                    >
                        <DeleteSvg className="h-5 w-5" />
                    </Button>
                    {parent && canDelete && (
                        <AddContactModal
                            successError={contactEditSuccess}
                            button="Modifier"
                            title="Modifier un contact"
                            field="contact"
                            edit={true}
                            show={showModal}
                            contact={contact}
                            handleClose={() => updateShowModal(false)}
                            parent={parent}
                        />
                    )}
                </div>
            )}
        </Card>
    );
};
