import { AJOObject } from 'mp-js-react-auto-json-object';
import { Link } from 'react-router-dom';
import { AccordeonTable } from '../../component/AccordeonTable';
import { DoubleDateModal } from '../../modal/vendor/DoubleDateModal';
import { Col } from '../table/Col';
import Table from '../table/Table';

export interface NbPrestaColInterface {
    NbPrestaColInterface: boolean;
    getNbPresta(): number;
}

export class NbPrestaCol<Type extends AJOObject> extends Col<Type> {
    constructor(table: Table<Type>, get: (elem: Type) => AJOObject = (elem) => elem, name: string = 'Nb presta') {
        super(name);
        this.render = (elem: Type) => {
            if ('NbPrestaColInterface' in get(elem)) {
                let inter = get(elem) as unknown as NbPrestaColInterface;
                let res;
                res = <p className="break-all">{inter.getNbPresta()}</p>;
                return res;
            } else {
                throw new Error('NbPrestaColInterface not found in elem');
            }
        };
        this.sort = (a: Type, b: Type) => {
            if ('NbPrestaColInterface' in get(a) && 'NbPrestaColInterface' in get(b)) {
                let aInter = get(a) as unknown as NbPrestaColInterface;
                let bInter = get(b) as unknown as NbPrestaColInterface;
                return -aInter.getNbPresta() + bInter.getNbPresta();
            } else {
                throw new Error('NbPrestaColInterface not found in a or b');
            }
        };
        this.type = 'sort';
        this.typeRender = 'stat';
        this.senseSort = 0;
        this.table = table;
    }
}
