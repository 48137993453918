import { AJOElement, AJOList, AJOObject } from 'mp-js-react-auto-json-object';
import AppManager from '../AppManager';
import { get } from '../HTTP';
import Result from '../Result';

export class PerfectAJOList<Type extends AJOObject> extends AJOList<Type> {
    public url: string;
    public makePrams: () => any;
    public start: boolean;
    public loading: boolean;
    public first: boolean;

    constructor(
        url: string,
        makePrams: () => any,
        type?: (new (...args: any) => Type) | (new (...args: any) => Type)[],
        sortFunc?: ((a: Type, b: Type) => number) | null,
    ) {
        super(type, '', null, sortFunc);

        this.url = url;
        this.makePrams = makePrams;
        this.start = false;
        this.loading = false;
        this.first = false;
    }

    public need() {
        if (!this.first && !this.start && !this.loading) {
            this.start = true;
            this.loading = true;
            this.fetch();
        }
    }

    public fetch() {
        get(this.url, this.makePrams()).then((data) => {
            if (Result.isSuccess(data)) {
                this.first = true;
                this.start = false;
                this.loading = false;
                Result.applyResult(data, this, true);
                AppManager.makeUpdate();
            }
        });
    }
}
