import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ComplexInput } from '../commun/component/ComplexInput';
import { Input } from '../commun/component/Input';
import { Button } from '../commun/component/Button';
import { Form } from '../commun/component/Form';
import { Content } from '../commun/component/Content';
import { get, post } from '../commun/HTTP';
import PageManager from '../commun/PageManager';
import { BasePage } from '../component/BasePage';
import Result from '../commun/Result';
import ErrorHTTP from '../commun/ErrorHTTP';
import { ErrorText } from '../commun/component/ErrorText';
import ErrorManager from '../commun/ErrorManager';
import { Spinner } from '../commun/component/Spinner';
import AppManager from '../commun/AppManager';
import User from '../object/User';
import { JSXInternal } from 'preact/src/jsx';
import { ButtonGradiant } from '../commun/component/ButtonGradiant';

const errorManager = new ErrorManager('login');
errorManager.add(
    ErrorHTTP.WRONG_PASSWORD(),
    'Les informations saisies ne correspondent pas à celles de notre base de données.',
);
errorManager.add(ErrorHTTP.NOT_FOUND(), 'Aucun utilisateur ne correspond à cette adresse e-mail.');
errorManager.add(ErrorHTTP.BAD_VALUE(), 'Les informations saisies sont incorrectes.');
errorManager.add(
    ErrorHTTP.LOGOUT().setColor('green-600').setColorDark('green-300'),
    'Vous avez été déconnecté avec succès.',
);

export interface IConnectionProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    basePage?: boolean;
}

export const Connection: React.FunctionComponent<IConnectionProps> = (props) => {
    const { basePage = true, ...rest } = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(ErrorHTTP.NO_ERROR());
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const location = useLocation();
    const currentPage = PageManager.getCurrent(location.pathname);

    let page;
    if (!AppManager.isAuthenticated()) {
        const onClick = (event: any) => {
            event.preventDefault();
            setError(ErrorHTTP.NO_ERROR());
            setLoading(true);
            post('/login', {
                email: email,
                password: password,
            }).then((res: any) => {
                setLoading(false);
                if (Result.isSuccess(res)) {
                    setError(ErrorHTTP.NO_ERROR());

                    AppManager.setAuthenticated(true);
                    AppManager.setUser(User.build());

                    Result.applyResult(res, AppManager.getUser()!);

                    if (AppManager.getUser() as User) {
                        let user = AppManager.getUser() as User;
                        if (user.first.get() === true || user.set.get() === true) {
                        } else {
                            window.location.reload();
                        }
                    }
                } else {
                    setError(Result.getError(res));
                }
            });
        };

        page = (
            <Form className="text-center w-4/5 max-w-md mb-8">
                <h1 className="text-4xl font-bold text-black dark:text-white">Connexion</h1>

                <ComplexInput
                    placeholder="Adresse email"
                    name="email"
                    value={email}
                    typeValue="string"
                    type="email"
                    setValue={setEmail}
                ></ComplexInput>

                <ComplexInput
                    placeholder="Mot de passe"
                    name="password"
                    value={password}
                    typeValue="string"
                    type="password"
                    setValue={setPassword}
                ></ComplexInput>

                {loading && (
                    <div className="flex items-center justify-center">
                        <Spinner color="purple"></Spinner>
                    </div>
                )}

                <ErrorText topSpacing={1} manager={errorManager} error={error}></ErrorText>

                <div className="flex items-center justify-center">
                    {false && (
                        <p className={'bg-clip-text  text-lg font-semibold text-purple-400 dark:text-purple-300'}>
                            Mot de passe oublié ?
                        </p>
                    )}
                    <div className="flex-1"></div>
                    <ButtonGradiant
                        onClick={onClick}
                        selected={true}
                        ringColor={currentPage.getRing()}
                        toColor={currentPage.getTo()}
                        fromColor={currentPage.getFrom()}
                    >
                        Connexion
                    </ButtonGradiant>
                </div>
            </Form>
        );
    } else {
        const logOut = () => {
            setError(ErrorHTTP.NO_ERROR());
            setLoading(true);
            post('/logout').then((res: any) => {
                setLoading(false);
                if (Result.isSuccess(res)) {
                    setError(ErrorHTTP.LOGOUT());

                    AppManager.setAuthenticated(false);
                } else {
                    setError(Result.getError(res));
                }
            });
        };
        const goHome = () => {
            setError(ErrorHTTP.NO_ERROR());
            setLoading(false);
            AppManager.navigate('/');
        };

        let user: User = AppManager.getUser() as User;

        page = (
            <Content className="w-4/5 text-center max-w-md mb-8">
                <h1 className="text-4xl font-bold text-black dark:text-white">Connexion</h1>

                <p className="text-black dark:text-white mb-1">
                    {'Vous êtes déjà connecté en tant que ' +
                        user.firstname.get() +
                        ' ' +
                        user.lastname.get() +
                        ", souhaitez vous déconnecter ou revenir à la page d'accueil."}
                </p>

                {loading && (
                    <div className="flex items-center justify-center">
                        <Spinner color="purple"></Spinner>
                    </div>
                )}

                <ErrorText topSpacing={0} bottomSpacing={2} manager={errorManager} error={error}></ErrorText>

                <div className="flex items-center justify-center gap-2 sm:gap-6">
                    <Link className="flex flex-1" to={'/'}>
                        <ButtonGradiant
                            className="flex-1"
                            onClick={goHome}
                            selected={true}
                            ringColor={currentPage.getRing()}
                            toColor={currentPage.getTo()}
                            fromColor={currentPage.getFrom()}
                        >
                            Accueil
                        </ButtonGradiant>
                    </Link>
                    <ButtonGradiant
                        className="flex-1"
                        onClick={logOut}
                        selected={true}
                        ringColor={currentPage.getRing()}
                        toColor={currentPage.getTo()}
                        fromColor={currentPage.getFrom()}
                    >
                        Déconnexion
                    </ButtonGradiant>
                </div>
            </Content>
        );
    }
    if (basePage) {
        page = <BasePage className="w-full flex items-center justify-center">{page}</BasePage>;
    }
    return page;
};
