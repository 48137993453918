import { AJOList, AJOObject } from 'mp-js-react-auto-json-object';
import { JSXInternal } from 'preact/src/jsx';
import ErrorHTTP from '../commun/ErrorHTTP';
import Utils from '../commun/Utils';
import { BaseProfile } from './profile/BaseProfile';
import { Section } from './Section';
import { Timer } from './Timer';

export abstract class TimerParent extends BaseProfile {
    public timerList: AJOList<Timer>;
    public loadingTimer: boolean = false;
    public loadingModalTimer: boolean = false;
    public showTimer: boolean = false;
    public errorTimer: ErrorHTTP = ErrorHTTP.NO_ERROR();
    public sectionTimer: Section | null = null;

    constructor(type: string) {
        super(type);

        this.timerList = new AJOList<Timer>(Timer, 'has_timer_object', this);
    }

    public getColorSpec(): string {
        return Utils.getColorFromStr(this.getName());
    }

    public specialRender(): JSXInternal.Element {
        return (
            <span className="flex gap-2 items-center flex-row">
                <span>{this.name.get()}</span>
                <div className={'bg-' + this.getColor() + '-500 pl-2 pt-0.5 pb-0.5 pr-2 rounded-full text-white'}>
                    {this.getTitle()}
                </div>
            </span>
        );
    }

    public mostRecent(): Date {
        let mostRecentRes: Date = new Date(0);
        for (const timer of this.timerList.getList()) {
            let mostRecent = timer.mostRecent();
            if (mostRecent.getTime() > mostRecentRes.getTime()) {
                mostRecentRes = mostRecent;
            }
        }
        return mostRecentRes;
    }

    public getSectionList(): Section[] {
        let list: Section[] = [];
        for (const timer of this.timerList.getList()) {
            if (timer.section.get()) {
                if (
                    list.find((s: Section) => {
                        return timer.section.get()!.getAjoIdentifier() === s.getAjoIdentifier();
                    }) === undefined
                ) {
                    list.push(timer.section.get()!);
                }
            }
        }
        return list;
    }

    public setShowTimer(show: boolean) {
        this.showTimer = show;
        if (!show) {
            this.setSectionTimer(null);
            this.setLoadingModalTimer(false);
            this.setErrorTimer(ErrorHTTP.NO_ERROR());
        }
        this.makeUpdate(true);
    }

    public setSectionTimer(section: Section | null) {
        this.sectionTimer = section;
        this.makeUpdate(true);
    }
    public getSectionTimer() {
        return this.sectionTimer;
    }

    public setErrorTimer(error: ErrorHTTP) {
        this.errorTimer = error;
        this.makeUpdate(true);
    }
    public getErrorTimer() {
        return this.errorTimer;
    }

    public isShowTimer() {
        return this.showTimer;
    }

    public setLoadingTimer(section: Section | null, loading: boolean) {
        if (section == null) {
            this.loadingTimer = loading;
        } else {
            section.loadingTimer = loading;
        }
        this.makeUpdate(true);
    }

    public isLoadingTimer(section: Section | null) {
        if (section == null) {
            return this.loadingTimer;
        } else {
            return section.loadingTimer;
        }
    }

    public setLoadingModalTimer(loading: boolean) {
        this.loadingModalTimer = loading;
        this.makeUpdate(true);
    }

    public isLoadingModalTimer() {
        return this.loadingModalTimer;
    }

    public isPlaying(section: Section | null): boolean {
        let playing = false;
        let i = 0;
        while (!playing && i < this.timerList.size()) {
            let timer = this.timerList.get(i);
            if ((timer.section.get()?.getAjoIdentifier() ?? '') === (section?.getAjoIdentifier() ?? '')) {
                playing = timer.end.get() === undefined;
            }
            i += 1;
        }
        return playing;
    }

    public getStartTimer(section: Section | null): Date | null {
        let start: Date | null = null;
        let i = 0;
        while (start === null && i < this.timerList.size()) {
            let timer = this.timerList.get(i);
            if (
                timer.end.get() === undefined &&
                (timer.section.get()?.getAjoIdentifier() ?? '') === (section?.getAjoIdentifier() ?? '')
            ) {
                start = new Date(timer.start.get());
            }
            i += 1;
        }
        return start;
    }
}
