import { JSXInternal } from 'preact/src/jsx';

export interface ITextAreaProps extends JSXInternal.HTMLAttributes<HTMLTextAreaElement> {}

export const TextArea: React.FunctionComponent<ITextAreaProps> = (props) => {
    return (
        <textarea
            className="pl-2 pr-2 pb-1 pt-1 text-black rounded-lg bg-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:text-white dark:focus:ring-red-900 dark:focus:border-red-900 outline-none focus:ring focus:ring-blue-700 focus:dark:ring-blue-800 focus:dark:border-blue-600 placeholder:text-gray-500 dark:placeholder:text-gray-300"
            {...props}
        ></textarea>
    );
};
