import { useState } from 'react';
import { RemoveUserModal } from 'src/modal/user/RemoveUserModal';
import { Button } from '../commun/component/Button';
import User from '../object/User';
import { DeleteSvg } from './DeleteSvg';

export interface IUserElemProps {
    user: User;
    canDelete?: boolean;
}
export const UserElem = (props: IUserElemProps) => {
    const [show, setShow] = useState(false);
    const user = props.user;
    return (
        <div className="p-2 flex items-center justify-center gap-2 border border-slate-500 dark:border-slate-400 rounded-lg">
            <span>{user.getFullName()}</span>
            {props.canDelete && (
                <div>
                    <Button
                        pb={1}
                        pl={1}
                        pr={1}
                        pt={1}
                        color="red"
                        onClick={() => {
                            setShow(true);
                        }}
                    >
                        <DeleteSvg className="h-5 w-5" />
                    </Button>
                    <RemoveUserModal
                        open={show}
                        handleClose={() => {
                            setShow(false);
                        }}
                        user={user}
                    />
                </div>
            )}
        </div>
    );
};
