import { AJOList, AJOObject } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import BasePrice from 'src/object/BasePrice';
import Nego from 'src/object/Nego';
import AppManager from '../../commun/AppManager';
import Autocomplete from '../../commun/component/Autocomplite';
import { post } from '../../commun/HTTP';
import { PerfectAJOList } from '../../commun/object/PerfectAJOList';
import Budget from '../../object/Budget';
import Vendor from '../../object/profile/Vendor';
import Valeur from '../../object/Valeur';
import VendorModal from '../vendor/VendorModal';
import { CustomSelect } from './CustomSelect';

export interface INegoSelectProps {
    negoList: PerfectAJOList<Nego>;
    budget: Budget;
    basePrice: BasePrice;
}

export const NegoSelect = (props: INegoSelectProps) => {
    const { negoList, budget, basePrice } = props;

    return (
        <>
            <CustomSelect
                className=" w-32 "
                id={'va-' + budget.getAjoIdentifier()}
                disabled={budget.bon_commande.get() !== null || !basePrice.canAct()}
                list={negoList.getList()}
                value={budget.nego.get() ?? budget.nego_flat.get()}
                getValue={(elem: Nego | undefined | number) => {
                    if (elem == undefined) {
                        return '';
                    } else if (typeof elem === 'number') {
                        return elem + ' %';
                    } else {
                        return elem.pourcent.get() + ' %' ?? '';
                    }
                }}
                placeholder="Négociation"
                onBlur={() => {
                    budget.leaveFocus();
                }}
                onFocus={() => {
                    budget.getFocus();
                    negoList?.need();
                }}
                onCreate={(str: string) => {
                    str = str.replace(' %', '');
                    str = str.replace('%', '');
                    str = str.replace(',', '.');

                    budget.nego_flat.set(parseFloat(str));

                    if (budget.nego.get() !== null) {
                        budget.addEdit({
                            nego_flat: parseFloat(str),
                            nego: -1,
                        });
                        budget.nego.set(null);
                    } else {
                        budget.addEdit({
                            nego_flat: parseFloat(str),
                        });
                    }
                }}
                onSelect={(item: any) => {
                    if (item instanceof Nego) {
                        budget.addEdit({
                            nego: item.getAjoIdentifier(),
                        });
                        budget.nego_flat.set(undefined);
                        budget.nego.set(item);

                        AppManager.makeUpdate();
                    } else if (budget.nego.get() !== null) {
                        budget.addEdit({
                            nego: -1,
                        });
                        budget.nego_flat.set(undefined);
                        budget.nego.set(null);
                        AppManager.makeUpdate();
                    } else {
                        budget.addEdit({
                            nego_flat: '',
                        });
                        budget.nego_flat.set(undefined);
                        AppManager.makeUpdate();
                    }
                }}
            />
        </>
    );
};
