import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { AJOState } from 'mp-js-react-auto-json-object';
import { useState } from 'preact/hooks';
import { Link } from 'react-router-dom';
import AppManager from 'src/commun/AppManager';
import { Spinner } from 'src/commun/component/Spinner';
import { post } from 'src/commun/HTTP';
import { PerfectAJOList } from 'src/commun/object/PerfectAJOList';
import Result from 'src/commun/Result';
import Toast from 'src/commun/toast/Toast';
import { SearchIcon } from 'src/icons/SearchIcon';
import { errorManager } from 'src/modal/vendor/CategoryErrorManager';
import Address from 'src/object/Address';
import Client from 'src/object/profile/Client';
import Vendor from 'src/object/profile/Vendor';
import { AddressFlexSelect } from '../budget/AddressFlexSelect';
import { AddressSelect } from '../budget/AddressSelect';
import MpModal from '../extends/MpModal';

export const ClientFacturationView = (props: { client: Client | Vendor }) => {
    const { client } = props;

    const [search, setSearch] = useState('');

    const [openFacturation, setOpenFacturation] = useState(false);
    const [addressList, setAddressList] = useState(
        new AJOState(
            new PerfectAJOList<Address>(
                '/address/list',
                () => {
                    return {};
                },
                Address,
                undefined,
            ),
        ),
    );
    const [address, setAddress] = useState<Address | null>(null);
    addressList.setUpdate(setAddressList);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    return (
        <Container maxWidth={false}>
            <Box sx={{ mb: 3, mt: 3 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        m: -1,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        {client.getName()} - Adresse de facturation
                    </Typography>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Card>
                        <CardContent className="w-full">
                            <Box className="flex w-full items-center flex-row">
                                {client.facturation.get() !== null ? (
                                    <Box className="flex flex-col gap-1">
                                        <Typography sx={{ flex: 1 }} variant="h5">
                                            {client.facturation.get()?.name.get() ??
                                                client.facturation.get()?.fullAddress()}
                                        </Typography>
                                        {client.facturation.get()?.name.get() != '' &&
                                            client.facturation.get()?.name.get() != undefined && (
                                                <Typography sx={{ flex: 1 }}>
                                                    {client.facturation.get()?.fullAddress() ?? ''}
                                                </Typography>
                                            )}
                                    </Box>
                                ) : (
                                    <Box className="flex flex-col gap-1">
                                        <Typography sx={{ flex: 1 }} variant="h5">
                                            Adresse de facturation
                                        </Typography>
                                    </Box>
                                )}
                                <Box className="flex-1 flex justify-end items-center">
                                    {client.facturation.get() !== null ? (
                                        <Button
                                            className="!pl-3 gap-2"
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                setOpenFacturation(true);
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-6 h-6"
                                            >
                                                <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                                <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                            </svg>
                                            Modifier
                                        </Button>
                                    ) : (
                                        <Button
                                            className="!pl-3 gap-2"
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                setOpenFacturation(true);
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-6 h-6"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            Ajouter
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            <MpModal
                onSubmit={() => {
                    setLoading(true);
                    setError('');

                    const callBack = (res: any) => {
                        setLoading(false);
                        if (Result.isSuccess(res)) {
                            Result.applyResult(res, AppManager.updater(), true);
                            setOpenFacturation(false);
                            AppManager.addToast(
                                new Toast("L'adresse de facturation a été modifiée avec succès.", 'success'),
                            );
                        } else {
                            setError(errorManager.get(Result.getError(res)));
                        }
                    };

                    if (address === null) {
                        post('/' + client.getAjoType().toLowerCase() + '/update', {
                            id: client.getAjoIdentifier(),
                            facturation: {
                                id_del: client.facturation.get()?.getAjoIdentifier() ?? '',
                            },
                        }).then(callBack);
                    } else {
                        post('/' + client.getAjoType().toLowerCase() + '/update', {
                            id: client.getAjoIdentifier(),
                            facturation: {
                                id: address.getAjoIdentifier(),
                            },
                        }).then(callBack);
                    }
                }}
                overflow={false}
                open={openFacturation}
                onClose={() => {
                    setOpenFacturation(false);
                }}
                title={
                    client.facturation.get() === null
                        ? 'Ajouter une adresse de facturation'
                        : "Modifier l'adresse de facturation"
                }
                done={client.facturation.get() === null ? 'Ajouter' : 'Modifier'}
            >
                {loading ? (
                    <Box className="flex items-center justify-center p-12">
                        <Spinner color="blue" />
                    </Box>
                ) : (
                    <Box className="flex flex-col gap-2">
                        <Box className="flex flex-col gap-0">
                            <AddressFlexSelect
                                address={client.facturation.get()}
                                addressList={addressList.get()!}
                                onAddressSelect={(address) => {
                                    setAddress(address);
                                }}
                            />
                        </Box>
                        {error !== '' && (
                            <Typography
                                component={'p'}
                                className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                            >
                                {error}
                            </Typography>
                        )}
                    </Box>
                )}
            </MpModal>
        </Container>
    );
};
