import { IObjectSelectable } from '../component/select/ObjectSelect';
import {
    devisCommandSuccess,
    devisUnValidateSuccess,
    devisStartSuccess,
    devisTerminateSuccess,
    devisCloseSuccess,
} from '../modal/vendor/CategoryErrorManager';
import { Agence } from './Agence';
import { AJOList, AJOObject, AJOProperties, AJOSimple } from 'mp-js-react-auto-json-object';
import { Color } from '../commun/Constant';
import Budget from './Budget';
import Product from './tag/Product';
import Client from './profile/Client';
import User from './User';
import { get, post } from '../commun/HTTP';
import Result from '../commun/Result';
import Utils from '../commun/Utils';
import Category from './tag/Category';
import BasePrice from './BasePrice';
import Address from './Address';
import { Paper } from './Paper';
import AppManager from '../commun/AppManager';
import Toast from '../commun/toast/Toast';
import { devisValidateSuccess, errorManager } from '../modal/vendor/CategoryErrorManager';
import Permission from './Permission';
import { Timer } from './Timer';
import { ProductColInterface } from '../commun/col/ProduitCol';
import { ClientColInterface } from '../commun/col/ClientCol';

export default class Devis extends BasePrice implements ClientColInterface, IObjectSelectable, ProductColInterface {
    public static _TYPE: string = 'Devis';

    public CategoryColInterface = true;
    public UserColInterface = true;
    public ProductColInterface = true;
    public NameColInterface = true;
    public NumColInterface = true;
    public ClientColInterface = true;

    public loadingHonoraire: boolean = false;
    public loadHonoraire: boolean = false;

    public fetchHonoraire() {
        if (!this.loadingHonoraire && !this.loadHonoraire) {
            this.loadingHonoraire = true;
            get(
                '/devis/get/timer',
                {
                    id: this.getAjoIdentifier(),
                },
                () => {
                    this.timerList?.setList([]);
                },
                true,
            ).then((res: any) => {
                this.loadHonoraire = true;
                this.loadingHonoraire = false;
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, AppManager.updater(), true);
                }
            });
        }
    }

    public getClient(): Client {
        return this.client.get() ?? new Client();
    }

    public getNum(): number {
        return this.getAjoIdentifier();
    }
    public getNumLink(): string {
        return this.getLink();
    }

    public getName(): string {
        return this.name.get();
    }

    public getLink(): string {
        return `/devis/${this.getAjoIdentifier()}`;
    }

    public getColor(): Color {
        return 'purple';
    }
    public getTitle(): string {
        return 'Prix de vente';
    }

    public num: AJOProperties<any>;
    public client: AJOSimple<Client>;
    public agence: AJOSimple<Agence>;
    public address: AJOSimple<Address>;
    public date_now: AJOProperties<any>;
    public report: AJOProperties<any>;
    public userList: AJOList<User>;

    public start_date: AJOProperties<any>;
    public end_date: AJOProperties<any>;
    public pre_date: AJOProperties<any>;
    public close: AJOProperties<any>;

    public minute_full: AJOProperties<any>;

    constructor() {
        super(Devis._TYPE);

        this.start_date = new AJOProperties('start_date', this);
        this.pre_date = new AJOProperties('pre_date', this);
        this.end_date = new AJOProperties('end_date', this);

        this.date = new AJOProperties('date', this);
        this.report = new AJOProperties('report', this);
        this.date_now = new AJOProperties('date_now', this);
        this.num = new AJOProperties('num', this);

        this.close = new AJOProperties('close', this);

        this.agence = new AJOSimple<Agence>(Agence, 'has_agence', this);
        this.address = new AJOSimple<Address>(Address, 'has_address', this);
        this.userList = new AJOList<User>(User, 'manage_devis', this);
        this.client = new AJOSimple<Client>(Client, 'has_devis', this);

        this.timerList = new AJOList<Timer>(Timer, 'has_timer_object', this);

        this.minute_full = new AJOProperties('minute_full', this);
    }

    public isBlock(): boolean {
        return this.report.get() === true;
    }

    public minuteTimer(): number {
        let res = 0;
        for (let timer of this.timerList.getList()) {
            res += timer.getDuration();
        }
        return res / 1000 / 60;
    }

    public jourTimer(): number {
        let res = 0;
        for (let timer of this.timerList.getList()) {
            res += timer.getDuration();
        }
        return res / 1000 / 60 / 60 / 7;
    }
    public mbHEstime(): number {
        return this.marge() / (this.minute() / 60);
    }

    public newMinuteTimer(): number {
        console.log('this.minute_full.get()', this.minute_full.get());
        console.log('this.minute_full.get()APPLY', parseFloat((this.minute_full.get() ?? 0) + ''));
        return parseFloat((this.minute_full.get() ?? 0) + '');
    }
    public newJourTimer(): number {
        return this.newMinuteTimer() / 60 / 7;
    }

    public mbHReel(): number {
        return this.marge() / (this.newMinuteTimer() / 60);
    }

    public nego(): number {
        return this.getComptablePaper()?.nego() ?? 0;
    }
    public forfaitTimer(): number {
        return (this.newMinuteTimer() * (88 / 60) * (100 - this.nego())) / 100;
    }

    public minuteEcart(): number {
        let res = this.minute() - this.newMinuteTimer();
        return res;
    }

    public jourEcart(): number {
        let res = this.jour() - this.jourTimer();
        return res;
    }

    public ecart(): number {
        return this.forfaitHonoraire() - this.forfaitTimer();
    }
    public static build(): Devis {
        return new Devis();
    }

    public getProductList(): Product[] {
        if (this.product.get()) {
            return [this.product.get()!];
        }
        return [];
    }

    public serviceDevisListFetch = false;
    public serviceDevisListStart = false;
    public serviceDevisListLoading = true;

    public fetchDevisListInfo(): void {
        if (!this.serviceDevisListFetch && !this.serviceDevisListStart) {
            this.serviceDevisListLoading = true;
            this.serviceDevisListStart = true;
            get(
                '/devis/budget',
                {
                    id: this.getAjoIdentifier(),
                },
                () => {
                    this.paperList.setList([]);
                },
            ).then((res: any) => {
                if (Result.isSuccess(res)) {
                    this.serviceDevisListFetch = true;
                    this.serviceDevisListLoading = false;
                    this.serviceDevisListStart = false;
                    Result.applyResult(res, AppManager.updater(), true);
                    AppManager.makeUpdate();
                }
            });
        }
    }

    public isGoodVa() {
        let res = true;

        if (this.product.get() != null) {
            let min = this.product.get()?.min.get() ?? 25;
            let max = this.product.get()?.max.get() ?? 100;
            res = this.va() >= min && this.va() <= max;
        }

        return res;
    }

    public canAct(): boolean {
        let res = true;
        let me = AppManager.getUser() as User;
        if (me.root.get() !== true) {
            let userList = this.userList.getList() ?? [];
            res = userList.findIndex((user) => user.getAjoIdentifier() === me.getAjoIdentifier()) !== -1;
        }
        return res && this.close.get() !== true && AppManager.hasPermission(Permission.MANAGE_DEVIS);
    }

    public canActNoStatus(): boolean {
        let res = true;
        let me = AppManager.getUser() as User;
        if (me.root.get() !== true) {
            let userList = this.userList.getList() ?? [];
            res = userList.findIndex((user) => user.getAjoIdentifier() === me.getAjoIdentifier()) !== -1;
        }
        return res;
    }

    public static bonusFilter = (search: string) => {
        return (devis: Devis) => {
            const finalSearch = Utils.clean(search.toLowerCase());
            let can = Utils.clean(devis.name.get()).toLowerCase().includes(finalSearch);
            let j = 0;
            while (!can && j < devis.getUserList().length) {
                let user = devis.getUserList()[j];
                can = Utils.clean(user.getFullName()).toLowerCase().includes(finalSearch);
                j++;
            }
            j = 0;
            while (!can && j < devis.getProductList().length) {
                let user = devis.getProductList()[j];
                can = Utils.clean(user.name.get()).toLowerCase().includes(finalSearch);
                j++;
            }
            return can;
        };
    };

    public getUserList(): User[] {
        return this.userList.getList();
    }

    public getCategoryList(): Category[] {
        return this.getComptablePaper()?.getCategoryListRec() ?? [];
    }

    public get(id: any, callback: (res: any) => void = (res: any) => {}) {
        let obj = this;
        get(
            '/devis/get',
            {
                id: id,
            },
            () => {
                this.paperList?.setList([]);
            },
            true,
        ).then((res: any) => {
            if (Result.isSuccess(res)) {
                Result.applyResult(res, obj, true);
                callback(res);
            }
        });
    }

    public respectOrder(paper: Paper): boolean {
        let res = false;
        if (this.product.get() != null) {
            res =
                parseFloat(this.product.get()!.min.get()) <= paper.va() &&
                parseFloat(this.product.get()!.max.get()) >= paper.va();
        }
        return res;
    }

    public canValidate(): boolean {
        let res = this.canValidateExceptOffre();

        res = res && this.getRootPaper() != null && this.respectOrder(this.getRootPaper()!);

        return res;
    }

    public canValidateExceptOffre(): boolean {
        let res = true;

        res = res && this.product.get() != null;
        res = res && this.agence.get() != null;
        res = res && this.address.get() != null;
        res = res && this.product.get() != null;
        res = res && !(this.start_date.get() == null || this.start_date.get() == '');
        res = res && !(this.end_date.get() == null || this.end_date.get() == '');

        return res;
    }

    private _stateLoading: boolean = false;
    public get stateLoading(): boolean {
        return this._stateLoading;
    }
    public set stateLoading(value: boolean) {
        this._stateLoading = value;
        this.makeUpdate(true);
    }

    public validate(callback = () => {}): void {
        if (this.canValidateExceptOffre()) {
            let obj = this;
            if (!obj.stateLoading) {
                obj.stateLoading = true;
                post('/devis/validate', {
                    id: obj.getAjoIdentifier(),
                }).then((res: any) => {
                    callback();
                    obj.stateLoading = false;
                    if (Result.isSuccess(res)) {
                        Result.applyResult(res, obj, true);
                        obj.selectedPaper = '';
                        obj.removeAllFocus();
                        obj.makeUpdate(true);
                        obj.resetLoad();
                        AppManager.addToast(new Toast(errorManager.get(devisValidateSuccess), 'success'));
                    } else {
                        AppManager.addToast(new Toast(errorManager.get(Result.getError(res)), 'error'));
                    }
                });
            }
        } else {
            callback();
            AppManager.addToast(new Toast(this.getMessageValidate(), 'error'));
        }
    }

    public getPaperComptable(): Paper | null {
        let res = null;
        for (let paper of this.paperList.getList()) {
            if (paper.comptable.get() === true) {
                res = paper;
                break;
            }
        }
        return res;
    }

    public getPaperRoot(): Paper | null {
        let res = null;
        for (let paper of this.paperList.getList()) {
            if (paper.root.get() === true) {
                res = paper;
                break;
            }
        }
        return res;
    }

    public resetPaperValidate() {
        for (let paper of this.paperList.getList()) {
            paper.validate.set(undefined);
        }
    }

    public editCours(callback: () => void = () => {}): void {
        let obj = this;
        if (!obj.stateLoading) {
            obj.stateLoading = true;
            post('/devis/edit_cours', {
                id: obj.getAjoIdentifier(),
            }).then((res: any) => {
                callback();
                obj.stateLoading = false;
                if (Result.isSuccess(res)) {
                    for (let vendor of obj.vendorList?.getList() ?? []) {
                        vendor.contactList.setList([]);
                    }
                    this.resetPaperValidate();
                    Result.applyResult(res, obj.vendorList, true);
                    Result.applyResult(res, obj, true);
                    obj.selectedPaper = '';
                    obj.removeAllFocus();
                    obj.makeUpdate(true);
                    obj.resetLoad();
                    AppManager.addToast(new Toast(errorManager.get(devisUnValidateSuccess), 'success'));
                } else {
                    AppManager.addToast(new Toast(errorManager.get(Result.getError(res)), 'error'));
                }
            });
        }
    }

    public terminate(calback: () => void): void {
        let obj = this;
        if (!obj.stateLoading) {
            obj.stateLoading = true;
            post('/devis/terminate', {
                id: obj.getAjoIdentifier(),
            }).then((res: any) => {
                obj.stateLoading = false;
                calback();
                if (Result.isSuccess(res)) {
                    for (let vendor of obj.vendorList?.getList() ?? []) {
                        vendor.contactList.setList([]);
                    }
                    Result.applyResult(res, obj.vendorList, true);
                    Result.applyResult(res, obj, true);
                    obj.selectedPaper = '';
                    obj.removeAllFocus();
                    obj.makeUpdate(true);
                    obj.resetLoad();
                    AppManager.addToast(new Toast(errorManager.get(devisTerminateSuccess), 'success'));
                } else {
                    AppManager.addToast(new Toast(errorManager.get(Result.getError(res)), 'error'));
                }
            });
        }
    }

    public resetLoad() {
        this.loadComment = false;
        this.startComment = false;

        this.loadCommand = false;
        this.startCommand = false;

        this.loadOption = false;
        this.startOption = false;
    }

    public removeAllFocus() {
        for (let paper of this.paperList.getList()) {
            paper.removeAllFocus();
        }
    }

    public getRootPaper(): Paper | null {
        let res = null;
        let i = 0;
        while (res == null && i < this.paperList.size()) {
            let paper = this.paperList.get(i);
            if (paper.root.get()) {
                res = paper;
            }
            i += 1;
        }
        return res;
    }

    public getPrestationList(): Budget[] {
        let res: Budget[] = [];
        for (const paper of this.paperList.getList()) {
            res.push(...paper.getPrestationList());
        }
        return res;
    }

    public getMessageValidate(): string {
        let explain: string = '';
        if (this.product.get() == null) {
            explain = 'Pour valider le prix de vente, vous devez sélectionner une offre.';
        } else if (this.agence.get() == null) {
            explain = 'Pour valider le prix de vente, vous devez sélectionner une agence.';
        } else if (this.address.get() == null) {
            explain = 'Pour valider le prix de vente, vous devez sélectionner une adresse.';
        } else if (this.start_date.get() == null || this.start_date.get() == '') {
            explain = "Pour valider le prix de vente, vous devez mentionner une date de début d'événement.";
        } else if (this.end_date.get() == null || this.end_date.get() == '') {
            explain = "Pour valider le prix de vente, vous devez mentionner une date de fin d'événement.";
        } else if (this.getRootPaper() == null) {
            explain = "La feuille principale n'a pas été trouvée.";
        } else if (!this.respectOrder(this.getRootPaper()!)) {
            explain =
                "Pour valider le prix de vente vous devez respecter les contraintes de l'offre. Pour l'offre " +
                this.getProductList()[0].name.get() +
                ', il faut que le taux de marge brute soit compris entre ' +
                this.getProductList()[0].min.get() +
                ' % et ' +
                this.getProductList()[0].max.get() +
                ' %.';
        } else {
            explain =
                "Pour l'offre " +
                this.getProductList()[0].name.get() +
                ', il faut un taux de marge brute entre ' +
                this.getProductList()[0].min.get() +
                ' % et ' +
                this.getProductList()[0].max.get() +
                ' %.';
        }
        return explain;
    }

    public getSelectTitle() {
        return this.name.get();
    }
    public getSelectDescription() {
        return '';
    }

    _statStart: boolean = false;
    public get statStart(): boolean {
        return this._statStart;
    }
    public set statStart(value: boolean) {
        this._statStart = value;
        this.makeUpdate(true);
    }

    _loadStat: boolean = true;
    public get loadStat(): boolean {
        return this._loadStat;
    }
    public set loadStat(value: boolean) {
        this._loadStat = value;
        this.makeUpdate(true);
    }

    public getStat(callback: () => void): void {
        let obj = this;
        if (!obj.statStart && obj.loadStat) {
            obj.statStart = true;
            get(
                '/devis/stat/get',
                {
                    id: obj.getAjoIdentifier(),
                },
                () => {},
                true,
            ).then((res: any) => {
                obj.loadStat = false;
                obj.statStart = false;
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, obj, true);
                    AppManager.updater().makeUpdate();
                    callback();
                } else {
                    AppManager.addToast(new Toast(errorManager.get(Result.getError(res)), 'error'));
                }
            });
        }
    }

    editeurStart = false;
    editeurLoad = false;

    getEditeur(callback: () => void) {
        if (!this.editeurStart && !this.editeurLoad) {
            this.editeurStart = true;
            get(
                '/devis/get/editeur',
                {
                    id: this.getAjoIdentifier(),
                },
                () => {},
                true,
            ).then((res: any) => {
                this.editeurStart = false;
                this.editeurLoad = true;
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, AppManager.updater(), true);
                    callback();
                }
            });
        }
    }
}
