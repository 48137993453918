import { Box, Typography } from '@mui/material';
import { Color } from '../../commun/Constant';
import { IPerfectTableGroup, PerfectTable } from '../../commun/table/PerfectTable';
import Budget from '../../object/Budget';
import { EuroSpan } from '../../commun/component/EuroSpan';
import { PourcentSpan } from '../../commun/component/PourcentSpan';
import { Paper } from '../../object/Paper';
import BasePrice from '../../object/BasePrice';
import { PrestationSelect } from './PrestationSelect';
import { VendorSelect } from './VendorSelect';
import { QuantityInput } from './QuantityInput';
import { VaSelect } from './VaSelect';
import { DeleteSvg } from '../DeleteSvg';
import { Button } from 'src/commun/component/Button';
import { SERVER_URL } from 'src/utils/Constant';
import { SimpleLabelList } from '../label/SimpleLabelList';
import { useState } from 'preact/hooks';
import MpModal from '../extends/MpModal';
import { Spinner } from 'src/commun/component/Spinner';
import { post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import AppManager from 'src/commun/AppManager';
import Toast from 'src/commun/toast/Toast';
import { PriceInput } from './PriceInput';
import { InsertPaperModal } from 'src/modal/vendor/InsertPaperModal';

export interface IPaperFillTableProps {
    list: Paper[];
    basePrice: BasePrice;
    paper: Paper;
    loading: boolean;
    color: Color;
}

export const PaperFillTable = (props: IPaperFillTableProps) => {
    const { loading, basePrice, paper, color, list } = props;

    paper.fetch();

    const [deletePaper, setDeletePaper] = useState<Paper | null>(null);
    const [errorDelete, setErrorDelete] = useState<string>('');
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
    return (
        <Box>
            <PerfectTable
                loading={loading}
                list={list}
                color={props.color ?? 'white'}
                nothing={
                    <Box className="flex flex-col gap-3 items-center justify-center">
                        <Typography>{'Aucune feuille trouvée.'}</Typography>
                    </Box>
                }
                bonusTrClass={(elem: any) => {
                    return elem.focus && '!border-8 !border-blue-600';
                }}
                bonusTdClass={(elem: any) => {
                    return elem.focus && '!bg-blue-200';
                }}
                columns={[
                    {
                        name: '',

                        render: (elem: Paper) => {
                            return (
                                <Box className="flex flex-row gap-3">
                                    {basePrice.canAct() && (
                                        <Button
                                            color="red"
                                            pb={2}
                                            tabIndex={-1}
                                            pl={2}
                                            pr={2}
                                            pt={2}
                                            onClick={() => {
                                                setDeletePaper(elem);
                                            }}
                                        >
                                            <DeleteSvg className={'text-white h-5 w-5'} />
                                        </Button>
                                    )}
                                    <Button
                                        color="blue"
                                        pb={2}
                                        tabIndex={-1}
                                        pl={2}
                                        pr={2}
                                        pt={2}
                                        onClick={() => {
                                            basePrice.selectedPaper = elem.getAjoIdentifier();
                                            if (elem.func.get() === 0) {
                                                basePrice.onTab?.('prestation');
                                            } else {
                                                basePrice.onTab?.('honoraire');
                                            }
                                            AppManager.makeUpdate();
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className="w-5 h-5"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625zM7.5 15a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 017.5 15zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H8.25z"
                                                clipRule="evenodd"
                                            />
                                            <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                                        </svg>
                                    </Button>
                                </Box>
                            );
                        },
                    },
                    {
                        name: 'Nom',

                        render: (elem: Paper) => {
                            return <Typography>{elem.name.get()}</Typography>;
                        },
                    },
                    {
                        name: 'Achat Total',

                        render: (elem: Paper) => {
                            return elem.func.get() === 0 ? (
                                <Typography>
                                    <EuroSpan value={elem.achat()} />
                                </Typography>
                            ) : (
                                <></>
                            );
                        },
                    },
                    {
                        name: 'VA',

                        render: (elem: Paper) => {
                            return elem.func.get() === 0 ? (
                                <Typography>
                                    <PourcentSpan value={elem.va()} />
                                </Typography>
                            ) : (
                                <></>
                            );
                        },
                    },
                    {
                        name: 'PV HT',

                        render: (elem: Paper) => {
                            return (
                                <Typography>
                                    <EuroSpan value={elem.prixVenteHT()} />
                                </Typography>
                            );
                        },
                    },
                    {
                        name: 'TVA',

                        render: (elem: Paper) => {
                            return (
                                <Typography>
                                    <PourcentSpan value={elem.tax()} />
                                </Typography>
                            );
                        },
                    },
                    {
                        name: 'PV TTC',

                        render: (elem: Paper) => {
                            return (
                                <Typography>
                                    <EuroSpan value={elem.prixVenteTTC()} />
                                </Typography>
                            );
                        },
                    },
                ]}
            />
            {
                <MpModal
                    done="Retirer"
                    doneColor="failure"
                    open={deletePaper != null}
                    onClose={() => {
                        setDeletePaper(null);
                    }}
                    title="Retirer la feuille"
                    onSubmit={() => {
                        setLoadingDelete(true);
                        setErrorDelete('');

                        post('/paper/delete/paper', {
                            id: paper.getAjoIdentifier(),
                            id_child: deletePaper!.getAjoIdentifier(),
                        }).then((res: any) => {
                            setLoadingDelete(false);

                            if (Result.isSuccess(res)) {
                                setDeletePaper(null);
                                AppManager.addToast(new Toast('La feuille a été retirée avec succès.', 'success'));
                                Result.applyResult(res, AppManager.updater(), true);
                            } else {
                                setErrorDelete(
                                    'Une erreur est survenue lors de la suppression de la feuille. Veuillez réessayer ultérieurement.',
                                );
                            }
                        });
                    }}
                >
                    {loadingDelete ? (
                        <Box className="flex items-center justify-center py-12">
                            <Spinner color="red" className="" />
                        </Box>
                    ) : (
                        <Box className="flex flex-col !text-gray-600 gap-2">
                            <Typography className="!text-sm !font-semibold">
                                Êtes-vous sûr de vouloir supprimer cette ligne, cette action est irréversible.
                            </Typography>
                            {errorDelete !== '' && (
                                <Typography
                                    component={'p'}
                                    className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                                >
                                    {errorDelete}
                                </Typography>
                            )}
                        </Box>
                    )}
                </MpModal>
            }
        </Box>
    );
};
