import { IObjectSelectable } from './../component/select/ObjectSelect';
import { AJOObject, AJOProperties } from 'mp-js-react-auto-json-object';

export class Section extends AJOObject implements IObjectSelectable {
    public static _TYPE: string = 'Section';

    public name: AJOProperties<any>;

    public loadingTimer: boolean = false;

    constructor() {
        super(Section._TYPE);

        this.name = new AJOProperties('name');
    }

    public static build(): Section {
        return new Section();
    }

    public getSelectTitle(): string {
        return this.name.get();
    }
    public getSelectDescription(): string {
        return '';
    }
}
