import { Fragment, useEffect, useRef, useState } from 'react';
import { ComplexInput } from './ComplexInput';
import AppManager from '../AppManager';
import { AJOElement, AJOObject } from 'mp-js-react-auto-json-object';
import Utils from '../Utils';
import { JSXInternal } from 'preact/src/jsx';

export interface IComplexSelectProps extends JSXInternal.HTMLAttributes<HTMLInputElement> {
    color: 'red' | 'blue' | 'green' | 'yellow' | 'purple' | 'orange' | 'gray' | 'black' | 'white' | 'base';
    elemList?: AJOObject[];

    selectId: string;
    onSelectIdChange: (select: string) => void;

    filter?: (elem: any, search: string) => boolean;

    loadDescription?: (elem: any) => string;
    loadTitle?: (elem: any) => string;

    newTitle?: string;
    onAdd?: (name: string) => void;
    cancelAdd?: () => void;
    add?: boolean;
    onConfirmChange?: (selectName: string, selectId: string, tabOrder: boolean) => void;
    focusInput?: boolean;
    baseSearch?: string;

    onBlur?: (e: JSXInternal.TargetedFocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: JSXInternal.TargetedFocusEvent<HTMLInputElement>) => void;
}

export const ComplexSelect: React.FunctionComponent<IComplexSelectProps> = (props) => {
    const {
        color,
        selectId = '',
        elemList = [],
        newTitle = '',
        filter = () => true,
        loadDescription = () => '',
        loadTitle = () => '',
        onConfirmChange = () => {},
        focusInput = false,
        onAdd,
        onBlur = () => {},
        onFocus = () => {},
        cancelAdd,
        add = false,
        onSelectIdChange,
        placeholder,
        ...rest
    } = props;

    useEffect(() => {
        if (focusInput) {
            input.current?.focus();
        }
    }, [focusInput]);

    let hasSelect = false;

    const [show, setShow] = useState(false);
    const input = useRef<HTMLInputElement>(null);

    let selectElem: any = null;
    let i = 0;
    while (selectElem == null && i < elemList.length) {
        if (elemList[i].getAjoIdentifier() === selectId) {
            selectElem = elemList[i];
        }
        i++;
    }

    const [search, setSearch] = useState(selectElem != null ? loadTitle(selectElem) : '');

    useEffect(() => {
        if (!focusInput && selectElem != null) {
            setSearch(selectElem != null ? loadTitle(selectElem) : '');
        }
    }, [selectElem]);

    useEffect(() => {
        let selectElem = null;
        let i = 0;
        while (selectElem == null && i < elemList.length) {
            if (elemList[i].getAjoIdentifier() === selectId) {
                selectElem = elemList[i];
            }
            i++;
        }

        setSearch(selectElem != null ? loadTitle(selectElem) : '');
    }, [elemList]);

    const [focus, setFocus] = useState(false);
    useEffect(() => {
        if (!focus) {
            setTimeout(() => {
                if (!hasSelect) {
                    complite(false);
                }
                setShow(input.current === document.activeElement);
                if (search === '' && selectId === '') {
                    onConfirmChange('', '', false);
                }
            }, 250);
        } else {
            setShow(focus);
        }
    }, [focus]);

    const [compliteIndex, setCompliteIndex] = useState(0);

    const filteredElemList = search === '' ? elemList : elemList.filter((elem) => filter(elem, search));

    let compliteText = '';
    if (search != '' && show) {
        if (compliteIndex == filteredElemList.length) {
            compliteText = '';
        } else {
            try {
                compliteText = loadTitle(filteredElemList[compliteIndex]).slice(search.length);
            } catch (e) {}
        }
    }
    const selectNew = () => {
        if (add && onAdd != undefined) {
            onAdd(search);
            setShow(false);
            setCompliteIndex(filteredElemList.length);
            hasSelect = true;
            onConfirmChange(search, '', true);
        }
    };
    const complite = (tabOrder: boolean) => {
        let res = false;
        if (selectId === '' && (search != '' || tabOrder) && filteredElemList.length > compliteIndex) {
            select(filteredElemList[compliteIndex], tabOrder);
            setShow(false);
            setCompliteIndex(0);
            res = true;
            hasSelect = true;
        } else if ((search != '' || tabOrder) && filteredElemList.length == compliteIndex) {
            if (add && onAdd != undefined) {
                onAdd(search);
                setShow(false);
                setCompliteIndex(filteredElemList.length);
                hasSelect = true;
                res = true;
                onConfirmChange(search, '', tabOrder);
            }
        }
        return res;
    };
    const onChange = () => {
        setCompliteIndex(0);
    };
    const select = (elem: AJOElement, tabOrder: boolean = true) => {
        //setShow(false);
        onSelectIdChange(elem.getAjoIdentifier());

        setSearch(loadTitle(elem));

        hasSelect = true;
        onConfirmChange('', elem.getAjoIdentifier(), tabOrder);
    };
    const startInput = () => {
        if (cancelAdd != undefined) {
            cancelAdd();
        }
        setShow(true);
        onSelectIdChange('');
        setCompliteIndex(0);
        setFocus(true);
    };
    let onFocusFinal = (event: JSXInternal.TargetedFocusEvent<HTMLInputElement>) => {
        onFocus(event);
        startInput();

        (event.target as any)?.select();
    };
    let onBlurFinal = (event: JSXInternal.TargetedFocusEvent<HTMLInputElement>) => {
        onBlur(event);
        setFocus(false);
        //complite(false);
    };

    let inList = false;
    let index = 0;
    while (!inList && filteredElemList.length > index) {
        inList = Utils.clean(search) === Utils.clean(loadTitle(filteredElemList[index]));
        index++;
    }

    const newLine = () => {
        let inList = false;
        let index = 0;
        while (!inList && filteredElemList.length > index) {
            inList = Utils.clean(search) === Utils.clean(loadTitle(filteredElemList[index]));
            index++;
        }
        return !inList && Utils.clean(search) != '' && add;
    };

    const nothing = () => {
        return !newLine() && filteredElemList.length == 0;
    };

    const onKeyDown = (event: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => {
        if (event.code === AppManager.TAB_KEY || event.code === AppManager.ENTER_KEY) {
            let res: boolean = complite(true);
            if (res) {
                event.preventDefault();
                event.stopPropagation();
            }
        } else if (event.code === AppManager.ESCAPE_KEY) {
            setSearch('');

            onSelectIdChange('');

            setCompliteIndex(0);
            if (search === '') {
                let cc = input.current;
                if (cc != undefined) {
                    (cc as HTMLInputElement).blur();
                }
            }
        } else if (!show) {
            startInput();
        }
    };

    const isSelect = (elem: AJOElement): boolean => {
        let equal = false;
        if (filteredElemList.length > compliteIndex) {
            equal = filteredElemList[compliteIndex].getAjoIdentifier() === elem.getAjoIdentifier();
        }
        return equal;
    };
    const onArrowDownKey = (event?: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => {
        if (event?.type === 'keydown') {
            if (compliteIndex < filteredElemList.length - 1 || (newLine() && compliteIndex < filteredElemList.length)) {
                setCompliteIndex(compliteIndex + 1);
                event?.preventDefault();
            }
        }
    };

    const onCtrlArrowDownKey = (event?: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => {
        if (event?.type === 'keydown') {
            if (
                (!newLine() && compliteIndex != filteredElemList.length - 1) ||
                (newLine() && compliteIndex != filteredElemList.length)
            ) {
                setCompliteIndex(filteredElemList.length - 1);
                event?.preventDefault();
            }
        }
    };
    const onArrowUpKey = (event?: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => {
        if (event?.type === 'keydown') {
            if (compliteIndex > 0) {
                setCompliteIndex(compliteIndex - 1);
                event?.preventDefault();
            }
        }
    };
    const onCtrlArrowUpKey = (event?: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => {
        if (event?.type === 'keydown') {
            if (compliteIndex != 0) {
                setCompliteIndex(0);
                event?.preventDefault();
            }
        }
    };

    let complexInput;
    complexInput = (
        <ComplexInput
            inputRef={input}
            focusInput={focusInput}
            onArrowDownKey={onArrowDownKey}
            onArrowUpKey={onArrowUpKey}
            onCtrlArrowDownKey={onCtrlArrowDownKey}
            onCtrlArrowUpKey={onCtrlArrowUpKey}
            beforeText={search}
            compliteText={compliteText}
            onKeyDown={onKeyDown}
            onFocus={onFocusFinal}
            onBlur={onBlurFinal}
            setValue={setSearch}
            placeholder={placeholder}
            autoComplete={'nope'}
            onChange={onChange}
            typeValue="select_search"
            {...rest}
            value={search}
        ></ComplexInput>
    );

    let contentList;
    contentList = (
        <div
            className={
                'absolute z-[250] min-w-[16rem] right-0 left-0 mt-2 flex flex-col cursor-default gap-1 py-1 px-1 bg-gray-300 dark:bg-gray-600 rounded-lg text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm'
            }
        >
            <div className="flex flex-col">
                {filteredElemList.map((value, index) => (
                    <div
                        onClick={() => {
                            select(value);
                        }}
                        className={
                            (isSelect(value) ? 'bg-blue-400  dark:bg-blue-500' : '') +
                            ' px-1.5 flex flex-col hover:bg-blue-700 hover:dark:bg-blue-600 rounded-md text-black dark:text-white hover:text-white py-1'
                        }
                        key={value.getAjoIdentifier()}
                    >
                        <div className="flex gap-1 flex-row">
                            {isSelect(value) && (
                                <svg
                                    className="w-5 h-5"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                                    ></path>
                                </svg>
                            )}
                            <p className="font-medium">{loadTitle(value)}</p>
                        </div>
                        <span className="text-sm">{loadDescription(value)}</span>
                    </div>
                ))}
                {(newLine() || nothing()) && (
                    <div
                        onClick={() => {
                            if (!nothing()) {
                                selectNew();
                            }
                        }}
                        className={
                            (compliteIndex == filteredElemList.length ? 'bg-blue-400  dark:bg-blue-500' : '') +
                            ' px-1.5 flex flex-col hover:bg-blue-700 hover:dark:bg-blue-600 rounded-md text-black dark:text-white hover:text-white py-1'
                        }
                    >
                        <div className="flex gap-1 flex-row">
                            {compliteIndex == filteredElemList.length && (
                                <svg
                                    className="w-5 h-5"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                                    ></path>
                                </svg>
                            )}
                            <p>{nothing() ? 'Aucun résultat' : search}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
    return (
        <div>
            <div>
                {complexInput}
                <div
                    className={
                        (show ? '' : 'hidden') + ' ' + ' relative overflow-x-visible overflow-y-visible z-[150] h-0'
                    }
                >
                    {contentList}
                </div>
            </div>
        </div>
    );
};
