import { AJOList, AJOUpdater } from 'mp-js-react-auto-json-object';
import { JSXInternal } from 'preact/src/jsx';
import { get } from '../../commun/HTTP';
import Result from '../../commun/Result';
import { AddElemVendorModal } from '../../modal/vendor/AddElemVendorModal';
import Permission from '../../object/Permission';
import Vendor from '../../object/profile/Vendor';
import { IObjectSelectProps, ObjectSelect } from './ObjectSelect';

export interface IVendorSelectProps extends IObjectSelectProps<Vendor> {
    placeholder: string;
    vendorAjoList?: AJOList<Vendor>;
}

export class VendorSelect extends ObjectSelect<Vendor, IVendorSelectProps> {
    createGenericObject() {
        return Vendor;
    }
    constructor(props: IVendorSelectProps) {
        /**
         * DEFAULT PROPS
         */

        super(props, Permission.MANAGE_VENDOR, 'vendor');

        /**
         * CREATE STATE
         */
        this.state = this.initState();

        // FUNCTION USE TO NOTIFY PARENT COMPONENT
        this.state.ajoList.setUpdate(() => this.forceUpdate());
    }

    fetchList() {
        if (this.state.isAjoListInflate) {
            get('/vendor/list').then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, this.state.ajoList, true);
                }
                this.setState({
                    isAjoListInflate: true,
                });
            });
        }
    }

    renderModal(): JSXInternal.Element {
        const { vendorAjoList: VendorAjoList } = this.props;
        const { elem } = this.state;
        let obj = this;
        return (
            <AddElemVendorModal
                mode="vendor"
                baseName={this.state.search}
                onCreate={(elem: any) => {
                    this.props.onObjectSelect(elem);
                }}
                show={this.state.showModal}
                handleClose={() => this.setState({ showModal: false })}
            />
        );
    }

    render(): JSXInternal.Element {
        return super.render();
    }
}
