import { AJOObject, AJOProperties } from 'mp-js-react-auto-json-object';

export default class Contact extends AJOObject {
    public static _TYPE: string = 'Contact';

    public name: AJOProperties<any>;
    public email: AJOProperties<any>;

    constructor() {
        super(Contact._TYPE);

        this.name = new AJOProperties('name');
        this.email = new AJOProperties('email');
    }

    public static build(): Contact {
        return new Contact();
    }
}
