import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { AJOList, AJOState } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import { PerfectTable } from 'src/commun/table/PerfectTable';
import Utils from 'src/commun/Utils';
import { BasePage } from 'src/component/BasePage';
import { CloseWeekWorkButton } from 'src/component/CloseWeekWorkButton';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';
import { TimerView } from 'src/component/TimerView';
import { SearchIcon } from 'src/icons/SearchIcon';
import User from 'src/object/User';
import { SERVER_URL } from 'src/utils/Constant';
import { WeekWork } from './BoardPage';

export const ClosePage = () => {
    const [search, setSearch] = useState('');
    const [loading, updateLoading] = useState(true);
    const [stateList, updateStateList] = useState(new AJOState<AJOList<User>>(new AJOList<User>(User)));
    stateList.setUpdate(updateStateList);

    useEffect(() => {
        get(
            '/timer/tocloselist',
            {
                search: '',
            },
            () => {
                for (const user of list) {
                    user.week_action.set([]);
                }
            },
        ).then((res: any) => {
            if (Result.isSuccess(res)) {
                updateLoading(false);

                stateList.get()!.reset();

                Result.applyResult(res, stateList, true);
                for (const user of list) {
                    for (const week of user.week_action.get() ?? []) {
                        week.user = user;
                    }
                }
            }
        });
    }, []);

    const list: User[] = [];
    const cleanSearchList: string[] = Utils.clean(search).split(' ');

    for (let i = 0; i < stateList.get()!.size(); i++) {
        const elem = stateList.get()!.get(i);

        let good = cleanSearchList.length == 1 && cleanSearchList[0] == '';
        let y = 0;

        while (!good && y < cleanSearchList.length) {
            const cleanSearch = cleanSearchList[y];

            if (
                cleanSearch.length != 0 &&
                (Utils.clean(elem.firstname.get()).includes(cleanSearch) ||
                    Utils.clean(elem.lastname.get()).includes(cleanSearch))
            ) {
                good = true;
            }
            y++;
        }

        if (good) {
            list.push(elem);
        }
    }

    const finalList: WeekWork[] = [];
    for (const user of list) {
        for (const week of user.week_action.get() ?? []) {
            week.user = user;
        }
        finalList.push(...(user.week_action.get() ?? []));
    }

    return (
        <BasePage className="flex-row w-full justify-center gap-4">
            <DashboardNavBar />

            <Container>
                <Box sx={{ mb: 3, mt: 3 }}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            m: -1,
                        }}
                    >
                        <Typography sx={{ m: 1 }} variant="h4">
                            Fermeture de semaine
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Card>
                            <CardContent className="">
                                <Box className="flex  items-center flex-row">
                                    <Box sx={{ maxWidth: 700 }}>
                                        <TextField
                                            fullWidth
                                            value={search}
                                            onChange={(e) => {
                                                setSearch((e.target as any).value);
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SvgIcon color="action" fontSize="small">
                                                            <SearchIcon />
                                                        </SvgIcon>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            placeholder="Rechercher un utilisateur"
                                            variant="outlined"
                                        />
                                    </Box>
                                    <Box className="flex-1"></Box>
                                    <Box></Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
                <Card
                    sx={{
                        mb: 3,
                    }}
                >
                    <PerfectTable
                        columns={[
                            {
                                name: 'Période',
                                sort: 1,
                                canSort: true,
                                funSort: (a: WeekWork, b: WeekWork) => {
                                    return -new Date(a.start).getTime() + new Date(b.start).getTime();
                                },
                                render: (d: WeekWork) => {
                                    const date = new Date(d.start);
                                    // get the number of week without toLocaleDateString
                                    const numWeek = Math.ceil(
                                        (date.getTime() - new Date(date.getFullYear(), 0, 1).getTime()) /
                                            (7 * 24 * 60 * 60 * 1000),
                                    );
                                    return (
                                        <Box>
                                            Semaine {numWeek} - {date.toLocaleDateString('fr')} -{' '}
                                            {new Date(date.getTime() + 6 * 24 * 60 * 60 * 1000).toLocaleDateString(
                                                'fr',
                                            )}
                                        </Box>
                                    );
                                },
                            },
                            {
                                name: 'Nom',
                                render: (d: WeekWork) => {
                                    return <Box>{d.user?.getFullName() ?? ''}</Box>;
                                },
                            },
                            {
                                name: 'Contrat',
                                render: (d: WeekWork) => {
                                    // get the number of week without toLocaleDateString
                                    return <Box>35 h</Box>;
                                },
                            },
                            {
                                name: 'Total',
                                sort: 0,
                                canSort: true,
                                funSort: (a: WeekWork, b: WeekWork) => {
                                    return -a.duration + b.duration;
                                },
                                render: (d: WeekWork) => {
                                    return (
                                        <Box>
                                            <TimerView
                                                show={true}
                                                canJour={false}
                                                date={new Date(0)}
                                                dateEnd={new Date(d.duration * 1000)}
                                            />
                                        </Box>
                                    );
                                },
                            },
                            {
                                name: 'Recup utilisé',
                                sort: 0,
                                canSort: true,
                                funSort: (a: WeekWork, b: WeekWork) => {
                                    return -a.recup + b.recup;
                                },
                                render: (d: WeekWork) => {
                                    // get the number of week without toLocaleDateString
                                    return (
                                        <Box>
                                            <TimerView
                                                show={true}
                                                canJour={false}
                                                date={new Date(0)}
                                                dateEnd={new Date(d.recup * 1000)}
                                            />
                                        </Box>
                                    );
                                },
                            },
                            {
                                name: '+/- Recup',
                                sort: 0,
                                funSort: (a: WeekWork, b: WeekWork) => {
                                    return -a.recup_plus_moin + b.recup_plus_moin;
                                },
                                render: (d: WeekWork) => {
                                    // get the number of week without toLocaleDateString
                                    return (
                                        <Box>
                                            <TimerView
                                                show={true}
                                                canJour={true}
                                                date={new Date(0)}
                                                dateEnd={new Date(d.recup_plus_moin * 1000)}
                                            />
                                        </Box>
                                    );
                                },
                            },
                            {
                                name: 'Recup Total',
                                render: (d: WeekWork) => {
                                    // get the number of week without toLocaleDateString
                                    return (
                                        <Box>
                                            <TimerView
                                                show={true}
                                                canJour={true}
                                                date={new Date(0)}
                                                dateEnd={new Date(d.recup_before * 1000)}
                                            />
                                        </Box>
                                    );
                                },
                            },
                            {
                                name: 'STATUT',
                                render: (d: WeekWork) => {
                                    // get the number of week without toLocaleDateString
                                    return (
                                        <Box className="flex gap-3 flex-wrap">
                                            <Link to={'/calendar/' + d.user.getAjoIdentifier() + '/' + d.start}>
                                                <Button variant="contained">Détails</Button>
                                            </Link>
                                            <CloseWeekWorkButton
                                                weekwork={d}
                                                onDelete={() => {
                                                    const user = d.user;
                                                    const week = d.start;

                                                    const index = (user.week_action.get() ?? []).findIndex(
                                                        (e) => e.start === week,
                                                    );

                                                    if (index !== -1) {
                                                        // Remove the week
                                                        user.week_action.get()?.splice(index, 1);
                                                    }

                                                    stateList.makeUpdate();
                                                }}
                                            />
                                        </Box>
                                    );
                                },
                            },
                        ]}
                        loading={loading}
                        list={finalList}
                        nothing={
                            <Box className="flex flex-col items-center p-12">
                                <Typography variant="h6">Aucun utilisateur</Typography>
                            </Box>
                        }
                    />
                </Card>
            </Container>
        </BasePage>
    );
};
