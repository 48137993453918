import { AJOElement, AJOObject, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import { CheckBox } from '../commun/component/CheckBox';
import { Color, Size } from '../commun/Constant';
import {
    categoryEditSuccess,
    labelDeleteSuccess,
    labelRemoveSuccess,
    productEditSuccess,
} from '../modal/vendor/CategoryErrorManager';
import { CategoryModal } from '../modal/vendor/CategoryModal';
import { RemoveCategoryModal } from '../modal/vendor/RemoveCategoryModal';
import Category from '../object/tag/Category';
import Label from '../object/tag/Label';
import Product from '../object/tag/Product';
import { TagObject } from '../object/tag/TagObject';
import { EditSvg } from './EditSvg';

export interface ITagProps {
    elem?: Category | Label;

    canDelete: boolean;
    canEdit: boolean;

    range?: boolean;

    check?: boolean;
    checked?: boolean;

    title?: string;
    color?: Color;

    size?: Size;

    onDelete?: (elem: TagObject) => void;
    onCheck?: (elem: TagObject) => void;
}

export const Tag: React.FC<ITagProps> = (props) => {
    const {
        elem,
        canEdit,
        size = 'base',
        range = false,
        title,
        color,
        onDelete = () => {},
        onCheck,
        checked = false,
        check = false,
        canDelete,
    } = props;

    const [editShow, updateEditShow] = useState(false);

    const onEdit = () => {
        updateEditShow(true);
    };

    let content = (
        <div className="flex items-center justify-ccenter gap-1">
            {check && (
                <div className="border-2 rounded-md flex border-white">
                    <CheckBox
                        ring={false}
                        checked={checked}
                        onChange={(e) => {
                            if (onCheck != undefined && elem !== undefined) {
                                onCheck(elem);
                            }
                        }}
                    />
                </div>
            )}
            <span className={'font-medium ' + 'text-' + size}>{elem?.name.get() ?? title}</span>
            {canEdit && (
                <button
                    onClick={onEdit}
                    className={
                        ' focus:ring-4 rounded-sm focus:ring-' +
                        elem?.getColor() +
                        '-400 dark:focus:ring-' +
                        elem?.getColor() +
                        '-300'
                    }
                >
                    <EditSvg className="h-5 w-5"></EditSvg>
                </button>
            )}

            {canDelete && (
                <button
                    onClick={() => (elem instanceof Label || elem instanceof Category) && onDelete(elem)}
                    className={
                        ' focus:ring-4 rounded-sm focus:ring-' +
                        elem?.getColor() +
                        '-400 dark:focus:ring-' +
                        elem?.getColor() +
                        '-300'
                    }
                >
                    <svg
                        className="cursor-pointer h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>
            )}

            <div
                className="absolute"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                {elem instanceof Category && (
                    <CategoryModal
                        action={'edit'}
                        elem={elem}
                        field="category"
                        title={'Modifier une catégorie'}
                        button={'Modifier'}
                        placeholder="Catégorie"
                        successError={categoryEditSuccess}
                        show={editShow}
                        handleClose={() => updateEditShow(false)}
                    />
                )}
                {elem instanceof Label && (
                    <CategoryModal
                        action={'edit'}
                        elem={elem}
                        field="label"
                        title={'Modifier un label'}
                        button={'Modifier'}
                        placeholder="Label"
                        successError={categoryEditSuccess}
                        show={editShow}
                        handleClose={() => updateEditShow(false)}
                    />
                )}
                {elem instanceof Product && (
                    <CategoryModal
                        action={'edit'}
                        elem={elem}
                        field="product"
                        title={'Modifier une offre'}
                        button={'Modifier'}
                        placeholder="Offre"
                        successError={productEditSuccess}
                        show={editShow}
                        handleClose={() => updateEditShow(false)}
                    />
                )}
            </div>
        </div>
    );

    if (check) {
        return (
            <label
                className={
                    'px-1.5' +
                    ' rounded-lg flex text-white py-1 bg-' +
                    elem?.getColor() +
                    '-600 dark:bg-' +
                    elem?.getColor() +
                    '-500'
                }
            >
                {content}
            </label>
        );
    } else {
        let px = 'px-2.5 rounded-lg';
        if (size === 'sm') {
            px = 'px-2 rounded-md';
        }
        let py = 'py-1 rounded-lg';
        if (size === 'sm') {
            py = 'py-0.5 rounded-md';
        }

        return (
            <div
                className={
                    (canDelete ? 'pl-2.5 pr-1.5' : px) +
                    ' flex text-white ' +
                    py +
                    ' bg-' +
                    elem?.getColor() +
                    '-600 dark:bg-' +
                    elem?.getColor() +
                    '-500'
                }
            >
                {content}
            </div>
        );
    }
};
