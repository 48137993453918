import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from '../commun/component/Button';
import { TextRaimbow } from '../commun/component/TextRaimbow';
import { BasePage } from '../component/BasePage';
import { CalendarView } from '../component/CalendarView';

export function CalendarPage() {
    /*useEffect(()=>{
            window.scrollTo(0, document.body.scrollHeight / 2
    }, [])*/

    const { id, date } = useParams();

    return (
        <BasePage>
            <CalendarView id={id ?? ''} date={date !== undefined ? new Date(date) : undefined} />
        </BasePage>
    );
}
