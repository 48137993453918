import { AJOObject } from 'mp-js-react-auto-json-object';
import { MutableRef } from 'preact/hooks';
import { useEffect, useState } from 'react';
import Autocomplete from '../../commun/component/Autocomplite';
import Prestation from '../../object/profile/Prestation';

export interface ICustomSelectProps {
    list: any[];
    value: AJOObject | null;
    getValue: (elem: any) => string;
    placeholder: string;
    onCreate: (str: string) => void;
    onSelect: (str: any) => void;
    onFocus: () => void;
    onBlur: () => void;
    className?: string;
    disabled?: boolean;
    ref?: MutableRef<HTMLInputElement | undefined>;
    id: string;
}

export const CustomSelect = (props: ICustomSelectProps) => {
    const { id = '', className = '', disabled = false, onCreate, onFocus, onBlur, onSelect, getValue } = props;

    const [search, setSearch] = useState(props.value !== null ? getValue(props.value) : '');

    useEffect(() => {
        setSearch(props.value !== null ? getValue(props.value) : '');
    }, [props.value, props.value !== null && getValue(props.value)]);

    const list: any[] = [...props.list];
    if (search.length !== 0) {
        const searchLower = search.toLowerCase();

        let found = false;
        let i = 0;
        while (!found && i < list.length) {
            if (getValue(list[i]).toLowerCase() === searchLower) {
                found = true;
            }
            i++;
        }

        if (!found) {
            list.push({
                name: search,
            });
        }
    }

    const getItemValue = (item: any) => {
        if (item instanceof AJOObject) {
            return getValue(item as any);
        } else {
            return item['name'];
        }
    };

    return (
        <Autocomplete
            autoHighlight={true}
            selectOnBlur={true}
            items={list}
            getItemValue={getItemValue}
            renderItem={(item: any, isHighlighted: boolean, styles: Object, index: number) => {
                let res;
                if (isHighlighted) {
                    res = (
                        <div className={'bg-gray-300'}>
                            <p className="font-medium m-1 px-2 py-1 rounded-md text-white bg-blue-700">
                                {getItemValue(item)}
                            </p>
                        </div>
                    );
                } else {
                    res = (
                        <div className={'bg-gray-300'}>
                            <p className="font-medium m-1 px-2 py-1 rounded-md text-black">{getItemValue(item)}</p>
                        </div>
                    );
                }
                return res;
            }}
            renderInput={(props: any) => {
                return (
                    <div className="relative z-[1]">
                        <input {...props} className={props.className + ' ' + className + ' '} />
                        <div className="absolute flex items-center justify-center top-0 bottom-0 text-gray-600 right-0 pr-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="w-5 h-5"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                    </div>
                );
            }}
            inputProps={{
                onFocus: onFocus,
                onBlur: onBlur,
                disabled: disabled,
                ref: props.ref,
                id: props.id,
                placeholder: props.placeholder,
                className:
                    'bg-gray-300 placeholder:text-gray-600 border-gray-400 border rounded-lg py-2 px-3 focus:!border focus:!ring focus:!ring-blue-500 focus:!border-blue-500',
            }}
            sortItems={(itemA: any, itemB: any) => {
                return getItemValue(itemA).localeCompare(getItemValue(itemB));
            }}
            shouldItemRender={(item: any, value: string) => {
                return getItemValue(item)?.toLowerCase().startsWith(value.toLowerCase());
            }}
            value={search}
            onChange={(event: Event, value: string) => {
                setSearch(value);
            }}
            onSelect={(value: string, item: any) => {
                setSearch(value);
                if (item instanceof AJOObject) {
                    onSelect(item);
                } else if (item === undefined) {
                    onSelect(undefined);
                } else {
                    onCreate(item['name']);
                }
            }}
        />
    );
};
