import { AJOObject } from 'mp-js-react-auto-json-object';
import { CaretDownFill, CaretRightFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { AccordeonTable } from '../../component/AccordeonTable';
import { ListComplexSelect } from '../../component/ListComplexSelect';
import { DoubleDateModal } from '../../modal/vendor/DoubleDateModal';
import Vendor from '../../object/profile/Vendor';
import { Col } from '../table/Col';
import Table from '../table/Table';

export interface NameColInterface {
    NameColInterface: boolean;
    getName(): string;
    getLink(): string;
}

export class NameCol<Type extends AJOObject> extends Col<Type> {
    private parent: AJOObject | null = null;
    constructor(
        table: Table<Type>,
        accordeon: boolean = false,
        get: (elem: Type) => AJOObject | null = (elem) => elem,
        name: string = 'Nom',
        parent: AJOObject | null = null,
        textNothing = 'Aucun nom',
        updatePrestationName?: React.StateUpdater<string>,
        updatePrestationId?: React.StateUpdater<string>,
    ) {
        super(name);
        this.table = table;
        this.parent = parent;
        this.render = (elem: Type) => {
            if (get(elem) === null) {
                return <p>{textNothing}</p>;
            } else if ('NameColInterface' in get(elem)!) {
                let inter = get(elem) as unknown as NameColInterface;
                let res;
                if (accordeon) {
                    res = (
                        <div className="flex items-center gap-1">
                            <div
                                className="flex items-center gap-1"
                                onClick={() => {
                                    // Check if selectList of table contain there is elem
                                    if (
                                        table.selectList.find(
                                            (e: AJOObject) => elem.getAjoIdentifier() === e.getAjoIdentifier(),
                                        ) !== undefined
                                    ) {
                                        table.removeFromSelectList(elem);
                                    } else {
                                        table.addToSelectList(elem);
                                    }
                                }}
                            ></div>
                            <Link to={inter.getLink()}>
                                <p className="break-word">{inter.getName()}</p>
                            </Link>
                        </div>
                    );
                } else {
                    res = (
                        <Link to={inter.getLink()}>
                            <p className="break-word">{inter.getName()}</p>
                        </Link>
                    );
                }
                return res;
            } else {
                throw new Error('NameColInterface not found in elem');
            }
        };
        this.sort = (a: Type, b: Type) => {
            if (get(a) == null || get(b) == null) {
                return ''.localeCompare('');
            }
            if ('NameColInterface' in get(a)! && 'NameColInterface' in get(b)!) {
                let aInter = get(a) as unknown as NameColInterface;
                let bInter = get(b) as unknown as NameColInterface;
                return aInter.getName().localeCompare(bInter.getName());
            } else {
                throw new Error('NameColInterface not found in a or b');
            }
        };
        this.table = table;
        this.update(updatePrestationId, updatePrestationName);
        this.type = 'sort';
        this.senseSort = 1;
    }

    public update(updatePrestationName?: React.StateUpdater<string>, updatePrestationId?: React.StateUpdater<string>) {
        let obj = this;
        this.renderTopRow = () => {
            if (obj.parent instanceof Vendor) {
                return (
                    updatePrestationName &&
                    updatePrestationId && (
                        <ListComplexSelect
                            canAdd={true}
                            onSelectName={(name) => {
                                updatePrestationName(name);
                            }}
                            onSelectId={(id) => {
                                updatePrestationId(id);
                            }}
                            placeholder="Prestation"
                            field="prestation"
                        />
                    )
                );
            } else {
                return (
                    updatePrestationName &&
                    updatePrestationId && (
                        <ListComplexSelect
                            canAdd={true}
                            onSelectName={(name) => {
                                updatePrestationName(name);
                            }}
                            onSelectId={(id) => {
                                updatePrestationId(id);
                            }}
                            placeholder="Fournisseur"
                            field="vendor"
                        />
                    )
                );
            }
        };
    }
}
