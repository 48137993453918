import { AJOList, AJOObject, AJOUpdater } from 'mp-js-react-auto-json-object';
import React from 'react';
import AppManager from '../../commun/AppManager';
import { Button } from '../../commun/component/Button';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { JSXInternal } from 'preact/src/jsx';
import { EditSvg } from '../EditSvg';
import { VirgineListComplexSelect } from '../VirgineListComplexSelect';

export interface IObjectSelectable {
    getSelectTitle(): string;
    getSelectDescription(): string;
}
export interface IObjectSelectProps<T extends AJOObject> {
    onObjectSelect: (agence: T | null) => void;
    onFocus?: () => void;
    placeholder: string;
    canAdd: boolean;

    elem?: T;
    haveEditButton?: boolean;
    ajoList?: AJOList<T>;
    block?: boolean;
    special?: boolean;
    focusInput?: boolean;
    forceFocus?: boolean;
}
export interface IObjectSelectState<T extends AJOObject> {
    ajoList: AJOList<T>;
    isAjoListInflate: boolean;
    elem: T | null;

    search: string;
    name: string;
    id: string;

    showModal: boolean;
    loadingModal: boolean;
    errorModal: ErrorHTTP;
}

export abstract class ObjectSelect<T extends AJOObject, P extends IObjectSelectProps<T>> extends React.Component<
    P,
    IObjectSelectState<T>
> {
    private permission: number;
    private field: string;
    constructor(props: P, permission: number, field: string) {
        /**
         * DEFAULT PROPS
         */

        super(props);

        this.permission = permission;
        this.field = field;

        /**
         * CREATE STATE
         */
        this.state = this.initState();

        // FUNCTION USE TO NOTIFY PARENT COMPONENT
        this.state.ajoList.setUpdate(() => this.forceUpdate());
    }
    abstract createGenericObject(): any;
    compatible(elem: T) {
        try {
            (elem as any as IObjectSelectable).getSelectTitle();
        } catch {
            throw new Error('elem must implement IObjectSelectable');
        }
    }
    typeList(): any[] {
        if (Array.isArray(this.createGenericObject())) {
            return this.createGenericObject();
        }
        return [this.createGenericObject()];
    }
    initState(): IObjectSelectState<T> {
        let res: IObjectSelectState<T>;

        let elem: T | null = this.props.elem ?? null;

        if (elem != null) {
            this.compatible(elem);
            res = {
                isAjoListInflate: this.props.ajoList === undefined,
                ajoList: this.props.ajoList ?? new AJOList<T>(this.createGenericObject()),
                elem: elem,
                search: (elem as any as IObjectSelectable).getSelectTitle(),
                name: '',
                id: elem.getAjoIdentifier(),
                showModal: false,
                loadingModal: false,
                errorModal: ErrorHTTP.NO_ERROR(),
            };
        } else {
            res = {
                isAjoListInflate: this.props.ajoList === undefined,
                ajoList: this.props.ajoList ?? new AJOList<T>(this.createGenericObject()),
                elem: null,
                search: '',
                name: '',
                id: '',
                showModal: false,
                loadingModal: false,
                errorModal: ErrorHTTP.NO_ERROR(),
            };
        }
        return res;
    }

    abstract renderModal(): JSXInternal.Element;
    abstract fetchList(): void;

    componentDidMount() {
        this.fetchList();
    }
    makeAction(selectName: string, selectId: string, tabOrder: boolean) {
        if (selectId !== '' && selectId !== undefined) {
            const ajoList = this.state.ajoList;

            let elem: T | null = null;
            let i = 0;
            while (elem === null && i < ajoList.size()) {
                if (ajoList.get(i).getAjoIdentifier() === selectId) {
                    elem = ajoList.get(i);
                }
                i++;
            }

            if (elem !== null) {
                this.setSearch(elem);
                this.props.onObjectSelect(elem);
            }
        } else if (selectName !== '' && selectName !== undefined) {
            // Now modal is open to add a new agence
            this.setState({
                name: selectName,
                showModal: true,
                elem: null,
                search: selectName,
            });
            this.props.onObjectSelect(null);
        } else {
            this.setSearch(null);
            this.props.onObjectSelect(null);
        }
    }
    componentDidUpdate(
        prevProps: Readonly<IObjectSelectProps<T>>,
        prevState: Readonly<IObjectSelectState<T>>,
        snapshot?: any,
    ): void {
        let prevTitle = (prevProps.elem as any as IObjectSelectable)?.getSelectTitle() ?? '';
        let title = (this.props.elem as any as IObjectSelectable)?.getSelectTitle() ?? '';
        if (this.props.elem != prevProps.elem || prevTitle !== title) {
            this.setSearch(this.props.elem ?? null);
        }
    }
    setSearch(elem: T | null) {
        if (elem == null) {
            this.setState({
                search: '',
                id: '',
                elem: null,
            });
        } else {
            this.compatible(elem);
            this.setState({
                search: (elem as any as IObjectSelectable).getSelectTitle(),
                id: elem.getAjoIdentifier(),
                elem: elem,
            });
        }
    }
    onElemChange(id: string) {
        const ajoList = this.state.ajoList;

        let elem: T | null = null;
        let i = 0;
        while (elem === null && i < ajoList.size()) {
            if (ajoList.get(i).getAjoIdentifier() === id) {
                elem = ajoList.get(i);
            }
            i++;
        }

        if (elem !== null) {
            this.props.onObjectSelect(elem);
            this.setSearch(elem);
        }
    }
    render(): JSXInternal.Element {
        const { search, name, id, ajoList, elem } = this.state;
        const { haveEditButton = true, block = false, canAdd } = this.props;
        let canEdit = AppManager.hasPermission(this.permission);
        let editBlock;

        if (canEdit && !block && haveEditButton && elem != null) {
            editBlock = (
                <Button
                    onClick={() =>
                        this.setState({
                            showModal: true,
                        })
                    }
                    type="button"
                    color="blue"
                    pt={2}
                    pr={2}
                    pb={2}
                    pl={2}
                >
                    <EditSvg className="w-5 h-5" />
                </Button>
            );
        }
        return (
            <div className="relative">
                <div className="flex flex-row items-center gap-2">
                    <div className="flex-1">
                        <VirgineListComplexSelect
                            special={this.props.special ?? false}
                            canAdd={canAdd}
                            ajoList={ajoList}
                            focusInput={this.props.focusInput ?? false}
                            forceFocus={this.props.forceFocus ?? false}
                            onFocus={() => {
                                if (this.props.onFocus !== undefined) {
                                    this.props.onFocus();
                                }
                            }}
                            search={search}
                            name={name}
                            id={id}
                            setSearch={(search) => this.setState({ search: search })}
                            onSelectName={() => {}}
                            onSelectId={() => {}}
                            onConfirmChange={(selectName: string, selectId: string, tabOrder: boolean) => {
                                this.makeAction(selectName, selectId, tabOrder);
                            }}
                            field={this.field}
                            placeholder={this.props.placeholder}
                        />
                    </div>
                    {editBlock}
                </div>
                <div className="absolute">{this.renderModal()}</div>
                {block && <div className="text-center inset-0 absolute z-[40]" onClick={() => {}}></div>}
            </div>
        );
    }
}
