import React from 'react';

export interface IDivProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const Content: React.FunctionComponent<IDivProps> = (props) => {
    return (
        <div
            {...props}
            className={
                'rounded-xl p-2 sm:p-6 bg-white  border border-gray-200 dark:border-slate-500 dark:bg-gray-800 flex flex-col gap-5 ' +
                props.className
            }
        >
            {props.children}
        </div>
    );
};
