import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'preact/hooks';
import Utils from 'src/commun/Utils';
import { AddIcon } from 'src/icons/AddIcon';
import { BinIcon } from 'src/icons/BinIcon';
import { EditIcon } from 'src/icons/EditIcon';
import { SearchIcon } from 'src/icons/SearchIcon';
import Contact from 'src/object/Contact';
import Client from 'src/object/profile/Client';
import Vendor from 'src/object/profile/Vendor';
import ContactModal from '../contact/ContactModal';
import DelContactModal from '../contact/DelContactModal';

export interface IClientContactViewProps {
    client: Client | Vendor;
}

export const ClientContactView = (props: IClientContactViewProps) => {
    const { client } = props;
    const [search, setSearch] = useState('');

    const list = client.contactList.getList().filter((category: Contact) => {
        return Utils.isSearch(search, category.name.get());
    });

    const [contact, setContact] = useState<Contact | null>(null);
    const [show, setShow] = useState<boolean>(false);
    const [showDel, setShowDel] = useState<boolean>(false);

    return (
        <Container maxWidth={false}>
            <Box sx={{ mb: 3, mt: 3 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        m: -1,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        {client.getName()} - Contact
                    </Typography>
                    <Box sx={{ m: 1 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            className="!pl-3 gap-2"
                            onClick={() => {
                                setContact(null);
                                setShow(true);
                            }}
                        >
                            <AddIcon />
                            Ajouter un contact
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Card>
                        <CardContent className="w-full">
                            <Box className="flex w-full items-center flex-row">
                                <Box sx={{ maxWidth: 500 }}>
                                    <TextField
                                        fullWidth
                                        value={search}
                                        onChange={(e) => {
                                            setSearch((e.target as any).value);
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon color="action" fontSize="small">
                                                        <SearchIcon />
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder="Rechercher un contact"
                                        variant="outlined"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            <Box>
                <Grid container spacing={3}>
                    {list.map((value: Contact, i: number) => {
                        return (
                            <Grid key={i} item lg={4} md={4} xs={6}>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    <CardContent className={'bg-slate-600 flex items-center justify-center'}>
                                        <Box>
                                            <Typography className="text-white !text-lg py-5 !font-bold text-center">
                                                {value.name.get()}
                                            </Typography>
                                        </Box>
                                    </CardContent>

                                    <Box className="flex flex-row p-2 gap-2">
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className="!pl-3 flex-1 gap-2"
                                            onClick={() => {
                                                setContact(value);
                                                setShow(true);
                                            }}
                                        >
                                            <EditIcon />
                                            Modifier
                                        </Button>
                                        <Button
                                            color="error"
                                            variant="contained"
                                            className="!pl-3 flex-1 gap-2"
                                            onClick={() => {
                                                setContact(value);
                                                setShowDel(true);
                                            }}
                                        >
                                            <BinIcon />
                                            Supprimer
                                        </Button>
                                    </Box>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
                {list.length === 0 && (
                    <Card sx={{ mt: 3 }}>
                        <CardContent>
                            <Box className="flex items-center flex-col justify-center gap-5 p-5">
                                <Typography>Aucun contact renseigné pour ce client</Typography>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className="!pl-3 gap-2"
                                    onClick={() => {
                                        setContact(null);
                                        setShow(true);
                                    }}
                                >
                                    <AddIcon />
                                    Ajouter un contact
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                )}
            </Box>
            {
                <ContactModal
                    open={show}
                    onClose={() => {
                        setShow(false);
                    }}
                    vendor={client}
                    contact={contact}
                    onCreate={() => {}}
                />
            }
            {
                <DelContactModal
                    open={showDel}
                    onClose={() => {
                        setShowDel(false);
                    }}
                    vendor={client}
                    contact={contact}
                    onCreate={() => {}}
                />
            }
        </Container>
    );
};
