import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { AJOState } from 'mp-js-react-auto-json-object';
import { Spinner } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppManager from 'src/commun/AppManager';
import Utils from 'src/commun/Utils';
import { BasePage } from 'src/component/BasePage';
import { ClientContactView } from 'src/component/client/ClientContactView';
import { ClientDevisView } from 'src/component/client/ClientDevisView';
import { ClientFacturationView } from 'src/component/client/ClientFacturationView';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';
import { ServiceTable } from 'src/component/prestation/ServiceTable';
import { AddIcon } from 'src/icons/AddIcon';
import { SearchIcon } from 'src/icons/SearchIcon';
import Vendor from 'src/object/profile/Vendor';
import Client from '../object/profile/Client';
import { ProfilePage } from './ProfilePage';

export function VendorPage() {
    const { id } = useParams();

    const [client, updateVendor] = useState(new AJOState<Vendor>(Vendor.build()));
    client.setUpdate(updateVendor);

    AppManager.updater().add(client);

    const [tab, setTab] = useState('prestation');

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        client.get()?.get(id, () => {
            setLoading(false);
            client.get()?.fetchPrestationListInfo();
        });
    }, []);

    useEffect(() => {}, [tab]);

    const [search, setSearch] = useState('');

    return (
        <BasePage className="flex-col flex items-center">
            <DashboardNavBar
                tab={tab}
                onTab={setTab}
                tabList={[
                    {
                        label: 'Prestation',
                        value: 'prestation',
                    },
                    {
                        label: 'Contact',
                        value: 'contact',
                    },
                    {
                        label: 'Facturation',
                        value: 'facturation',
                    },
                ]}
            />
            {loading ? (
                <Container maxWidth={false}>
                    <Box className="w-full h-full">
                        <Spinner color={'blue'} />
                    </Box>
                </Container>
            ) : (
                <Box className="w-full ">
                    {tab === 'prestation' && (
                        <Container maxWidth={false}>
                            <Box sx={{ mb: 3, mt: 3 }}>
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexWrap: 'wrap',
                                        m: -1,
                                    }}
                                >
                                    <Typography sx={{ m: 1 }} variant="h4">
                                        {client.get()?.getName() ?? ''} - Prestation
                                    </Typography>
                                </Box>
                                <Box sx={{ mt: 3 }}>
                                    <Card>
                                        <CardContent className="">
                                            <Box className="flex  items-center flex-row">
                                                <Box sx={{ maxWidth: 500 }}>
                                                    <TextField
                                                        fullWidth
                                                        value={search}
                                                        onChange={(e) => {
                                                            setSearch((e.target as any).value);
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SvgIcon color="action" fontSize="small">
                                                                        <SearchIcon />
                                                                    </SvgIcon>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        placeholder="Rechercher un prestation"
                                                        variant="outlined"
                                                    />
                                                </Box>
                                                <Box className="flex-1"></Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Box>
                            <Card>
                                <ServiceTable
                                    loading={client.get()!.prestationListLoading}
                                    list={client
                                        .get()!
                                        .serviceList.getList()
                                        .filter((v) => {
                                            return Utils.isSearch(search, v.prestation.get()?.name.get() ?? '');
                                        })}
                                />
                            </Card>
                        </Container>
                    )}
                    {tab === 'contact' && <ClientContactView client={client.get()!} />}
                    {tab === 'facturation' && <ClientFacturationView client={client.get()!} />}
                </Box>
            )}
        </BasePage>
    );
}
