import { Alert, Snackbar } from '@mui/material';
import { Component } from 'react';
import AppManager from '../../commun/AppManager';
import { JSXInternal } from 'preact/src/jsx';

export interface IToastManagerViewProps {}

export interface IToastManagerViewState {}

export default class ToastManagerView extends Component<IToastManagerViewProps, IToastManagerViewState> {
    public render(): JSXInternal.Element {
        return (
            <div className="p-absolute">
                {AppManager.getToastList().map((toast, index) => {
                    return (
                        <Snackbar
                            key={index}
                            open={true}
                            autoHideDuration={6000}
                            onClose={() => {
                                AppManager.removeToast(toast);
                            }}
                        >
                            <Alert
                                onClose={() => {
                                    AppManager.removeToast(toast);
                                }}
                                severity="success"
                                sx={{ width: '100%' }}
                            >
                                {toast.message}
                            </Alert>
                        </Snackbar>
                    );
                })}
            </div>
        );
    }
}
