import { AJOList, AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { title } from 'process';
import { useEffect, useState } from 'react';
import AppManager from '../../commun/AppManager';
import { ComplexInput } from '../../commun/component/ComplexInput';
import { ComplexSelect } from '../../commun/component/ComplexSelect';
import { Color, ModalBottomMode } from '../../commun/Constant';
import ErrorHTTP from '../../commun/ErrorHTTP';
import ErrorManager from '../../commun/ErrorManager';
import ErrorMessage from '../../commun/ErrorMessage';
import { get, post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import Utils from '../../commun/Utils';
import { SelectColor } from '../../component/SelectColor';
import Contact from '../../object/Contact';
import { categoryAddSuccess, errorManager } from './CategoryErrorManager';

export interface IAddContactModalProps {
    handleClose: () => void;
    parent: AJOObject;
    show: boolean;
    field: string;
    title: string;
    button: string;

    edit?: boolean;
    contact?: Contact;

    successError: ErrorHTTP;
}

export const AddContactModal: React.FC<IAddContactModalProps> = (props) => {
    const { field, title, edit = false, button, handleClose, contact, show, parent, successError } = props;

    const [stateList, updateStateList] = useState(new AJOState<AJOList<AJOObject>>(new AJOList<AJOObject>()));
    stateList.setUpdate(updateStateList);

    const [loading, updateLoad] = useState(false);
    const [error, updateError] = useState(ErrorHTTP.NO_ERROR());

    const [name, updateName] = useState(contact?.name.get() ?? '');
    const [email, updateEmail] = useState(contact?.email.get() ?? '');

    useEffect(() => {
        if (show) {
            updateLoad(false);
            updateError(ErrorHTTP.NO_ERROR());
            updateEmail(contact?.email.get() ?? '');
            updateName(contact?.name.get() ?? '');
        }
    }, [show]);

    const onAddCategory = () => {
        updateLoad(true);
        updateError(ErrorHTTP.NO_ERROR());
        if (!edit) {
            const params: any = {
                id: parent.getAjoIdentifier(),
            };

            const cat: any = {};
            cat['name'] = name;
            cat['email'] = email;

            params[field] = cat;
            post('/' + parent.getAjoType().toLowerCase() + '/update', params).then((res: any) => {
                updateLoad(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, parent, true);
                    AppManager.addToast(new Toast(errorManager.get(successError), 'success'));
                    handleClose();
                } else {
                    updateError(Result.getError(res));
                }
            });
        } else {
            const params: any = {
                id: contact!.getAjoIdentifier(),
                name: name,
                email: email,
            };

            post('/contact/update', params).then((res: any) => {
                updateLoad(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, parent, true);
                    AppManager.addToast(new Toast(errorManager.get(successError), 'success'));
                    handleClose();
                } else {
                    updateError(Result.getError(res));
                }
            });
        }
    };

    return (
        <Modal
            onDone={onAddCategory}
            show={show}
            done={button}
            title={title}
            loading={loading}
            color={'blue'}
            error={error}
            errorManager={errorManager}
            handleClose={() => {
                handleClose();
                updateError(ErrorHTTP.NO_ERROR());
            }}
        >
            <ComplexInput typeValue="string" placeholder="Nom" value={name} setValue={updateName} />
            <ComplexInput typeValue="string" placeholder="Email" value={email} setValue={updateEmail} />
        </Modal>
    );
};
