import { Box, Typography } from '@mui/material';
import { useState } from 'preact/hooks';
import AppManager from 'src/commun/AppManager';
import { Button } from 'src/commun/component/Button';
import { Spinner } from 'src/commun/component/Spinner';
import { post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import Toast from 'src/commun/toast/Toast';
import { budgetCommandSuccess, errorManager } from 'src/modal/vendor/CategoryErrorManager';
import Budget from 'src/object/Budget';
import Devis from 'src/object/Devis';

export interface ICommandButtonProps {
    elem: Budget;
    basePrice: Devis;
}

export const CommandButton = (props: ICommandButtonProps) => {
    const { basePrice, elem } = props;

    const [loading, setLoading] = useState(false);
    if (loading) {
        return (
            <Box className="flex flex-col gap-3">
                <Spinner color="blue" className={'w-8 h-8'} />
                <Typography>Cette action peut prendre du temps. Merci de bien vouloir patienter.</Typography>
            </Box>
        );
    }
    return (
        <Button
            onClick={() => {
                setLoading(true);

                post('/command/element', {
                    devis_id: basePrice.getAjoIdentifier(),
                    budget_list_id: [props.elem.getAjoIdentifier()],
                }).then((res: any) => {
                    setLoading(false);
                    if (Result.isSuccess(res)) {
                        AppManager.addToast(new Toast('La ligne a été commandée avec succès.', 'success'));
                        Result.applyResult(res, AppManager.updater(), true);
                    } else {
                        AppManager.addToast(new Toast(errorManager.get(Result.getError(res)), 'error'));
                    }
                });
            }}
            color="blue"
        >
            Commander
        </Button>
    );
};

export interface ICommandButtonListProps {
    elemList: Budget[];
    basePrice: Devis;
}

export const CommandButtonList = (props: ICommandButtonListProps) => {
    const { basePrice } = props;

    const [loading, setLoading] = useState(false);
    if (loading) {
        return (
            <Box className="flex flex-col gap-3">
                <Spinner color="blue" className={'w-8 h-8'} />
                <Typography>Cette action peut prendre du temps. Merci de bien vouloir patienter.</Typography>
            </Box>
        );
    }
    return (
        <Button
            onClick={() => {
                setLoading(true);
                const elemList = [];
                for (const elem of props.elemList) {
                    elemList.push(elem.getAjoIdentifier());
                }
                post('/command/element', {
                    devis_id: basePrice.getAjoIdentifier(),
                    budget_list_id: elemList,
                }).then((res: any) => {
                    setLoading(false);
                    if (Result.isSuccess(res)) {
                        AppManager.addToast(new Toast('Les lignes ont été commandées avec succès.', 'success'));
                        Result.applyResult(res, AppManager.updater(), true);
                    } else {
                        AppManager.addToast(new Toast(errorManager.get(Result.getError(res)), 'error'));
                    }
                });
            }}
            color="blue"
        >
            Commander
        </Button>
    );
};
