import { Box, Modal } from '@mui/material';
import { Button } from 'flowbite-react';
import { Component } from 'react';
import { JSXInternal } from 'preact/src/jsx';

export interface IBaseModalProps {
    open: boolean;
    onClose: () => void;
}

export interface IModalProps extends IBaseModalProps {
    children: JSXInternal.Element | JSXInternal.Element[];
    title: string;
    done: string;
    cancel?: string;
    doneColor?: string;
    onSubmit: () => void;
    overflow?: boolean;
    menu?: boolean;
}

export default class MpModal extends Component<IModalProps> {
    keyAction(e: KeyboardEvent) {
        if (this.props.open) {
            if (e.key === 'Escape') {
                this.props.onClose();
            } else if (e.key === 'Enter') {
                //this.props.onSubmit();
            }
        }
    }
    componentDidMount() {
        document.addEventListener('keydown', this.keyAction.bind(this), false);
    }

    componentDidUpdate(prevProps: Readonly<IModalProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.open !== this.props.open) {
            document.removeEventListener('keydown', this.keyAction.bind(this), false);

            if (this.props.open) {
                setTimeout(() => {
                    if (this.props.open) {
                        document.addEventListener('keydown', this.keyAction.bind(this), false);
                    }
                });
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyAction.bind(this), false);
    }
    render(): JSXInternal.Element {
        const {
            children,
            title,
            done,
            open,
            cancel,
            doneColor,
            onClose,
            onSubmit,
            menu = false,
            overflow = true,
        } = this.props;
        if (!menu) {
            return (
                <div className="p-absolute">
                    <Modal open={open ?? false} onClose={onClose}>
                        <div
                            className="flex flex-col h-full w-full max-h-[80%] items-center m-16"
                            onMouseDown={onClose}
                        >
                            <form
                                method="post"
                                action="/quiz/add"
                                className={
                                    'bg-white py-4 px-4 max-w-[80%] lg:max-w-[50%] min-w-[28rem] ' +
                                    (overflow && ' overflow-auto ') +
                                    ' rounded-xl'
                                }
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                                onMouseDown={(e) => e.stopPropagation()}
                            >
                                <div className="pb-3 border-b border-slate-300">
                                    <h3 className="text-xl font-medium text-gray-900 dark:text-white">{title}</h3>
                                </div>
                                <div className="py-3 border-b border-slate-300 flex flex-col gap-3">{children}</div>
                                <div className="pt-3">
                                    <div className="flex w-full gap-4 items-end justify-end">
                                        <Button color="zinc" className="font-medium border-0" onClick={onClose}>
                                            {cancel ?? 'Annuler'}
                                        </Button>
                                        <Button
                                            type="submit"
                                            onClick={onSubmit}
                                            className="font-semibold py-0.5 px-1 border-0"
                                            color={doneColor ?? 'info'}
                                        >
                                            {done}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal>
                </div>
            );
        } else {
            if (open) {
                return (
                    <div
                        className="fixed z-[1250] top-0 left-0 bottom-0 right-0 flex items-center justify-center"
                        onMouseDown={(e) => {
                            if (open) {
                                onClose();
                            }
                        }}
                    >
                        <div className={'z-[1500] fixed w-screen h-screen bg-black opacity-20'}></div>
                        <div
                            className={
                                'z-[1500] bg-slate-100 border overflow-hidden rounded-2xl border-slate-300 py-1.5 px-1.5'
                            }
                            onMouseDown={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            {children}
                        </div>
                    </div>
                );
            } else {
                return <></>;
            }
        }
    }
}
