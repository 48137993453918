import {
    Avatar,
    Box,
    Button,
    Card,
    Container,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { JSXInternal } from 'preact/src/jsx';
import { useState } from 'react';
import { ArrowDownCircle } from '../../icons/ArrowDownCircle';
import { ArrowRightCircle } from '../../icons/ArrowRightCircle';
import { ArrowUpCircle } from '../../icons/ArrowUpCircle';
import { BinIcon } from '../../icons/BinIcon';
import { FilterIcon } from '../../icons/FilterIcon';
import { Spinner } from '../component/Spinner';
import { Color } from '../Constant';

export interface IPerfectTableGroup {
    render: () => JSXInternal.Element;
    id: string;
    color?: Color;
    getList: (list: any[]) => any[];
    specificCondition?: (() => JSXInternal.Element)[];
}
export interface IPerfectTableColumn {
    name: string;
    render: (elem: any) => JSXInternal.Element;

    canFilter?: boolean;
    hasFilter?: boolean;
    onDeletePress?: () => void;
    onFilterPress?: () => void;
    funFilter?: (elem: any) => boolean;

    canSort?: boolean;
    sort?: 0 | 1 | -1;
    funSort?: (elem1: any, elem2: any) => number;
}

export interface IPerfectTableProps {
    columns: IPerfectTableColumn[];
    groups?: IPerfectTableGroup[];
    list: any[];
    loading: boolean;
    nothing?: JSXInternal.Element;
    color?: Color;
    bonusRender?: (elem: any) => JSXInternal.Element;
    bonusTdClass?: (elem: any) => string;
    bonusTrClass?: (elem: any) => string;
}

export const PerfectTable: React.FunctionComponent<IPerfectTableProps> = (props) => {
    const sortListTemp: number[] = [];
    for (const col of props.columns) {
        sortListTemp.push(col.sort ?? 0);
    }

    const [sortList, setSortList] = useState(sortListTemp);

    const setSort = (i: number) => {
        for (let j = 0; j < sortList.length; j++) {
            if (j !== i) {
                sortList[j] = 0;
            }
        }

        sortList[i] = sortList[i] === 1 ? -1 : 1;

        setSortList([...sortList]);
    };

    let list: any[] = [...props.list];

    for (let i = 0; i < props.columns.length; i++) {
        const col = props.columns[i];
        if (col.funSort && col.canSort && sortList[i] !== 0) {
            list.sort(col.funSort);
            if (sortList[i] === -1) {
                list.reverse();
            }
        }
    }

    for (const col of props.columns) {
        if (col.funFilter && col.hasFilter) {
            list = list.filter(col.funFilter);
        }
    }

    const table = (
        <Table
            className={
                '' +
                (props.color === undefined
                    ? 'overflow-hidden bg-white'
                    : 'bg-' + props.color + '-100 text-' + props.color + '-800')
            }
        >
            <TableHead
                className={
                    '!sticky ' +
                    (props.color !== undefined ? '!bg-' + props.color + '-200 overflow-hidden rounded-lg' : '') +
                    ''
                }
            >
                {props.columns.map((value: IPerfectTableColumn, i: number) => {
                    return (
                        <TableCell key={i}>
                            <Box className="flex flex-row items-center gap-2">
                                {value.canSort && (
                                    <Box
                                        onClick={() => {
                                            setSort(i);
                                        }}
                                    >
                                        {sortList[i] === -1 && <ArrowUpCircle />}
                                        {sortList[i] === 0 && <ArrowRightCircle />}
                                        {sortList[i] === 1 && <ArrowDownCircle />}
                                    </Box>
                                )}
                                {value.name}
                                {value.canFilter && (
                                    <Box className="flex flex-row items-center gap-2">
                                        <Button
                                            onClick={() => value.onFilterPress?.()}
                                            sx={{ padding: '0.35rem', minWidth: '1rem' }}
                                            color="primary"
                                            variant="contained"
                                        >
                                            <FilterIcon />
                                        </Button>
                                        {value.hasFilter && (
                                            <Button
                                                onClick={() => value.onDeletePress?.()}
                                                sx={{ padding: '0.35rem', minWidth: '1rem' }}
                                                color="error"
                                                variant="contained"
                                            >
                                                <BinIcon />
                                            </Button>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </TableCell>
                    );
                })}
            </TableHead>
            {(props.groups?.length ?? 0) === 0 && (
                <TableBody className={props.color !== undefined ? 'bg-' + props.color + '-100' : ''}>
                    {!props.loading &&
                        list.map((elem: any, i: number) => {
                            return [
                                <TableRow key={'0-' + elem.getAjoIdentifier?.()}>
                                    <TableCell colSpan={props.columns.length} sx={{ borderBottom: 0, padding: '0' }}>
                                        <Divider
                                            className={
                                                props.color !== undefined
                                                    ? '!border-' + props.color + '-200'
                                                    : '!border-gray-300'
                                            }
                                        />
                                    </TableCell>
                                </TableRow>,
                                <TableRow
                                    hover
                                    key={'1-' + elem.getAjoIdentifier?.()}
                                    className={'' + props.bonusTrClass?.(elem)}
                                >
                                    {props.columns.map((col: IPerfectTableColumn, i_c: number) => {
                                        return (
                                            <TableCell
                                                sx={{ borderBottom: 0 }}
                                                key={elem.getAjoIdentifier?.() + '-' + i_c}
                                                className={'' + props.bonusTdClass?.(elem)}
                                            >
                                                {col.render(elem)}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>,
                                props.bonusRender?.(elem) !== undefined && (
                                    <TableRow key={'2-' + elem.getAjoIdentifier?.()}>
                                        <TableCell
                                            colSpan={props.columns.length}
                                            sx={{ borderBottom: 0, padding: '0' }}
                                        >
                                            <Divider />
                                        </TableCell>
                                    </TableRow>
                                ),
                                props.bonusRender?.(elem) !== undefined && (
                                    <TableRow key={'3-' + elem.getAjoIdentifier?.()}>
                                        <TableCell
                                            colSpan={props.columns.length}
                                            className="!border-gray-700 !border-b-4"
                                            sx={{ borderBottom: 0, padding: '0' }}
                                        >
                                            {props.bonusRender?.(elem)}
                                        </TableCell>
                                    </TableRow>
                                ),
                            ];
                        })}

                    {props.loading && (
                        <TableRow>
                            <TableCell colSpan={props.columns.length}>
                                <Box className="flex items-center justify-center py-24">
                                    <Spinner color="blue" />
                                </Box>
                            </TableCell>
                        </TableRow>
                    )}

                    {!props.loading && list.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={props.columns.length}>
                                <Box className="flex items-center justify-center py-24">{props.nothing}</Box>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            )}
            {(props.groups?.length ?? 0) !== 0 && [
                !props.loading &&
                    props.groups!.map((group, i_g: number) => {
                        const classNameTop = group.color !== undefined ? '!bg-' + group.color + '-700' : '!bg-gray-700';
                        const classNameBorder =
                            group.color !== undefined ? '!border-' + group.color + '-200' : '!border-gray-200';
                        const classNameContent =
                            group.color !== undefined ? '!bg-' + group.color + '-100' : '!bg-gray-100';
                        if (group.getList(list).length === 0) {
                            return null;
                        }
                        return (
                            <TableBody key={'group' + i_g}>
                                <TableRow>
                                    <TableCell
                                        className={classNameTop}
                                        colSpan={props.columns.length - (group.specificCondition?.length ?? 0)}
                                        sx={{ borderBottom: 0, padding: '0', paddingLeft: 3 }}
                                    >
                                        {group.render()}
                                    </TableCell>
                                    {group.specificCondition !== undefined &&
                                        group.specificCondition!.map((spec, index) => {
                                            return (
                                                <TableCell
                                                    sx={{ borderBottom: 0 }}
                                                    className={classNameTop}
                                                    key={index}
                                                >
                                                    {spec()}
                                                </TableCell>
                                            );
                                        })}
                                </TableRow>
                                {!props.loading &&
                                    group.getList(list).map((elem: any, i: number) => {
                                        return [
                                            <TableRow key={'0-' + elem.getAjoIdentifier?.()}>
                                                <TableCell
                                                    colSpan={props.columns.length}
                                                    sx={{ borderBottom: 0, padding: '0' }}
                                                >
                                                    <Divider className={classNameBorder} />
                                                </TableCell>
                                            </TableRow>,
                                            <TableRow
                                                hover
                                                key={'1-' + elem.getAjoIdentifier?.()}
                                                className={' ' + props.bonusTrClass?.(elem)}
                                            >
                                                {props.columns.map((col: IPerfectTableColumn, i_c: number) => {
                                                    return (
                                                        <TableCell
                                                            sx={{ borderBottom: 0 }}
                                                            key={elem.getAjoIdentifier?.() + '-' + i_c}
                                                            className={
                                                                classNameContent + ' ' + props.bonusTdClass?.(elem)
                                                            }
                                                        >
                                                            {col.render(elem)}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>,
                                            props.bonusRender?.(elem) !== undefined && (
                                                <TableRow key={'2-' + elem.getAjoIdentifier?.()}>
                                                    <TableCell
                                                        colSpan={props.columns.length}
                                                        sx={{ borderBottom: 0, padding: '0' }}
                                                    >
                                                        <Divider className={classNameBorder} />
                                                    </TableCell>
                                                </TableRow>
                                            ),
                                            props.bonusRender?.(elem) !== undefined && (
                                                <TableRow key={'3-' + elem.getAjoIdentifier?.()}>
                                                    <TableCell
                                                        colSpan={props.columns.length}
                                                        className="!border-gray-700 !border-b-4"
                                                        sx={{ borderBottom: 0, padding: '0' }}
                                                    >
                                                        {props.bonusRender?.(elem)}
                                                    </TableCell>
                                                </TableRow>
                                            ),
                                        ];
                                    })}
                            </TableBody>
                        );
                    }),

                props.loading && (
                    <TableBody key={'1'}>
                        <TableRow>
                            <TableCell colSpan={props.columns.length}>
                                <Box className="flex items-center justify-center py-24">
                                    <Spinner color="blue" />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ),

                !props.loading && list.length === 0 && (
                    <TableBody key={'2'}>
                        <TableRow>
                            <TableCell colSpan={props.columns.length}>
                                <Box className="flex items-center justify-center py-24">{props.nothing}</Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ),
            ]}
        </Table>
    );

    return table;
};
