import { useState } from 'react';
import { PerfectTable } from '../../commun/table/PerfectTable';
import Prestation from '../../object/profile/Prestation';
import Vendor from '../../object/profile/Vendor';
import Category from '../../object/tag/Category';
import Label from '../../object/tag/Label';
import { JSXInternal } from 'preact/src/jsx';
import SelectCategoryModal from '../category/SelectCategoryModal';
import { SimpleCategoryList } from '../category/SimpleCategoryList';
import SelectLabelModal from '../label/SelectLabelModal';
import { SimpleLabelList } from '../label/SimpleLabelList';
import SelectPrestationModal from '../prestation/SelectPrestationModal';
import { SimplePrestationList } from '../prestation/SimplePrestationList';
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    Typography,
    Container,
} from '@mui/material';
import { SearchIcon } from '../../icons/SearchIcon';
import Utils from '../../commun/Utils';
import { StatistiqueIcon } from '../../icons/StatistiqueIcon';
import { AddIcon } from '../../icons/AddIcon';
import AddVendorModal from './AddVendorModal';
import { ArrowRightCircle } from '../../icons/ArrowRightCircle';
import { ArrowDownCircle } from '../../icons/ArrowDownCircle';
import { ServiceTable } from '../prestation/ServiceTable';
import { Link } from 'react-router-dom';

export interface IVendorTableProps {
    list: Vendor[];
    loading: boolean;
    onChangeType: () => void;
}

export const VendorTable = (props: IVendorTableProps) => {
    const { list, loading } = props;

    const [prestationList, setPrestationList] = useState([] as Prestation[]);
    const [categoryList, setCategoryList] = useState([] as Category[]);
    const [labelList, setLabelList] = useState([] as Label[]);

    const [prestationShow, setPrestationShow] = useState(false);
    const [categoryShow, setCategoryShow] = useState(false);
    const [labelShow, setLabelShow] = useState(false);

    const [openElement, setOpenElement] = useState<Vendor | null>(null);

    const [addVendorShow, setAddVendorShow] = useState(false);

    const [search, setSearch] = useState('');

    return (
        <Box>
            <Box sx={{ mb: 3, mt: 3 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        m: -1,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        Fournisseur
                    </Typography>
                    <Box sx={{ m: 1 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            className="!pl-3 gap-2"
                            onClick={() => {
                                setAddVendorShow(true);
                            }}
                        >
                            <AddIcon />
                            Ajouter un fournisseur
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Card>
                        <CardContent className="">
                            <Box className="flex  items-center flex-row">
                                <Box sx={{ maxWidth: 500 }}>
                                    <TextField
                                        fullWidth
                                        value={search}
                                        onChange={(e) => {
                                            setSearch((e.target as any).value);
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon color="action" fontSize="small">
                                                        <SearchIcon />
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder="Rechercher un fournisseur"
                                        variant="outlined"
                                    />
                                </Box>
                                <Box className="flex-1"></Box>
                                <Box>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className="!pl-3 gap-2"
                                        onClick={() => {
                                            props.onChangeType();
                                        }}
                                    >
                                        <StatistiqueIcon />
                                        Statistique
                                    </Button>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            <Card>
                <PerfectTable
                    loading={loading}
                    list={list}
                    nothing={
                        <Box className="flex flex-col gap-3 items-center justify-center">
                            <Typography>{'Aucun fournisseur trouvé.'}</Typography>
                            <Button
                                onClick={() => {
                                    setAddVendorShow(true);
                                }}
                                color="primary"
                                variant="contained"
                            >
                                Ajouter un fournisseur
                            </Button>
                        </Box>
                    }
                    columns={[
                        {
                            canSort: true,
                            sort: 1,
                            funSort: (e1: Vendor, e2: Vendor) => {
                                return e1.name.get().localeCompare(e2.name.get());
                            },

                            name: 'Nom',

                            hasFilter: true,
                            funFilter: (vendor: Vendor) => {
                                const finalSearch = Utils.clean(search.toLowerCase());
                                let can =
                                    Utils.clean(vendor.name.get()).toLowerCase().includes(finalSearch) ||
                                    search.length === 0;
                                if (!can) {
                                    let i = 0;
                                    while (!can && i < vendor.getPrestationList().length) {
                                        can = Utils.clean(vendor.getPrestationList()[i].name.get())
                                            .toLowerCase()
                                            .includes(finalSearch);
                                        i++;
                                    }
                                    i = 0;
                                    while (!can && i < vendor.getCategoryList().length) {
                                        can = Utils.clean(vendor.getCategoryList()[i].name.get())
                                            .toLowerCase()
                                            .includes(finalSearch);
                                        i++;
                                    }
                                    i = 0;
                                    while (!can && i < vendor.getLabelList().length) {
                                        can = Utils.clean(vendor.getLabelList()[i].name.get())
                                            .toLowerCase()
                                            .includes(finalSearch);
                                        i++;
                                    }
                                }
                                return can;
                            },
                            render: (elem: Vendor) => {
                                return (
                                    <Box className="font-semibold flex flex-row items-center gap-1">
                                        {openElement?.getAjoIdentifier() !== elem.getAjoIdentifier() ? (
                                            <ArrowRightCircle
                                                onClick={() => {
                                                    setOpenElement(elem);
                                                }}
                                            />
                                        ) : (
                                            <ArrowDownCircle
                                                onClick={() => {
                                                    setOpenElement(null);
                                                }}
                                            />
                                        )}
                                        <Link to={'/vendor/' + elem.getAjoIdentifier()}>{elem.name.get()}</Link>
                                    </Box>
                                );
                            },
                        },
                        {
                            name: 'Prestation',

                            canSort: true,
                            sort: 0,
                            funSort: (e1: Vendor, e2: Vendor) => {
                                return -e1.getPrestationList().length + e2.getPrestationList().length;
                            },

                            canFilter: true,
                            hasFilter: prestationList.length !== 0,
                            funFilter: (elem: Vendor) => {
                                let can = false;
                                let i = 0;
                                while (!can && i < prestationList.length) {
                                    const prestation = prestationList[i];
                                    can =
                                        elem.getPrestationList().find((p: Prestation) => {
                                            return p.getAjoIdentifier() === prestation.getAjoIdentifier();
                                        }) !== undefined;
                                    i += 1;
                                }
                                return can;
                            },
                            onDeletePress: () => {
                                setPrestationList([]);
                            },
                            onFilterPress: () => {
                                setPrestationShow(true);
                            },
                            render: (elem: Vendor) => {
                                return <SimplePrestationList list={elem.getPrestationList()} />;
                            },
                        },
                        {
                            name: 'Catégorie',

                            canSort: true,
                            sort: 0,
                            funSort: (e1: Vendor, e2: Vendor) => {
                                return -e1.getCategoryList().length + e2.getCategoryList().length;
                            },

                            canFilter: true,
                            hasFilter: categoryList.length !== 0,
                            funFilter: (elem: Vendor) => {
                                let can = false;
                                let i = 0;
                                while (!can && i < categoryList.length) {
                                    const Category = categoryList[i];
                                    can =
                                        elem.getCategoryList().find((p: Category) => {
                                            return p.getAjoIdentifier() === Category.getAjoIdentifier();
                                        }) !== undefined;
                                    i += 1;
                                }
                                return can;
                            },
                            onDeletePress: () => {
                                setCategoryList([]);
                            },
                            onFilterPress: () => {
                                setCategoryShow(true);
                            },
                            render: (elem: Vendor) => {
                                return <SimpleCategoryList list={elem.getCategoryList()} />;
                            },
                        },
                        {
                            name: 'Label',

                            canSort: true,
                            sort: 0,
                            funSort: (e1: Vendor, e2: Vendor) => {
                                return -e1.getLabelList().length + e2.getLabelList().length;
                            },

                            canFilter: true,
                            hasFilter: labelList.length !== 0,
                            funFilter: (elem: Vendor) => {
                                let can = false;
                                let i = 0;
                                while (!can && i < labelList.length) {
                                    const Label = labelList[i];
                                    can =
                                        elem.getLabelList().find((p: Label) => {
                                            return p.getAjoIdentifier() === Label.getAjoIdentifier();
                                        }) !== undefined;
                                    i += 1;
                                }
                                return can;
                            },
                            onDeletePress: () => {
                                setLabelList([]);
                            },
                            onFilterPress: () => {
                                setLabelShow(true);
                            },
                            render: (elem: Vendor) => {
                                return <SimpleLabelList list={elem.getLabelList()} />;
                            },
                        },
                    ]}
                    bonusRender={(elem: Vendor) => {
                        let res: JSXInternal.Element = <></>;
                        if (elem.getAjoIdentifier() === openElement?.getAjoIdentifier()) {
                            elem.fetchPrestationListInfo();

                            res = (
                                <ServiceTable
                                    color="red"
                                    loading={elem.prestationListLoading}
                                    list={elem.serviceList.getList()}
                                />
                            );
                        }
                        return res;
                    }}
                />
            </Card>
            <SelectPrestationModal
                onClose={() => {
                    setPrestationShow(false);
                }}
                open={prestationShow}
                selectList={prestationList}
                onSelectList={(l) => {
                    setPrestationList([...l]);
                }}
            />
            <SelectCategoryModal
                onClose={() => {
                    setCategoryShow(false);
                }}
                open={categoryShow}
                selectList={categoryList}
                onSelectList={(l) => {
                    setCategoryList([...l]);
                }}
            />
            <SelectLabelModal
                onClose={() => {
                    setLabelShow(false);
                }}
                open={labelShow}
                selectList={labelList}
                onSelectList={(l) => {
                    setLabelList([...l]);
                }}
            />
            <AddVendorModal
                open={addVendorShow}
                onClose={() => {
                    setAddVendorShow(false);
                }}
            />
        </Box>
    );
};
