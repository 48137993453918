import { IObjectSelectable } from './../../component/select/ObjectSelect';
import { AJOObject, AJOProperties } from 'mp-js-react-auto-json-object';
import { TagObject } from './TagObject';

export default class CategoryEvent extends TagObject implements IObjectSelectable {
    public static _TYPE: string = 'CategoryEvent';

    constructor() {
        super(CategoryEvent._TYPE);
    }

    public static build(): CategoryEvent {
        return new CategoryEvent();
    }

    public getSelectTitle(): string {
        return this.name.get() ?? '';
    }
    public getSelectDescription(): string {
        return '';
    }
}
