import { CheckBox } from '@mui/icons-material';
import { Card } from '@mui/material';
import { useEffect, useState } from 'react';
import Category from '../../object/tag/Category';

export interface ISelectCategoryListProps {
    list: Category[];
    selectList: Category[];
    single?: boolean;
    onSelectList: (list: Category[]) => void;
}

export const SelectCategoryList = (props: ISelectCategoryListProps) => {
    const [selected, setSelected] = useState<Category[]>(props.selectList);

    useEffect(() => {
        setSelected(props.selectList);
    }, [props.selectList.length, selected.length !== props.selectList.length]);

    return (
        <div className="flex gap-2 flex-wrap">
            {props.list.map((category: Category, index: number) => {
                return (
                    <div className={'rounded-lg px-2 py-1 bg-' + category.getColor() + '-600'}>
                        <div className="flex items-center gap-2">
                            <input
                                checked={
                                    selected.find((item) => item.getAjoIdentifier() === category.getAjoIdentifier()) !==
                                    undefined
                                }
                                onChange={() => {
                                    let list;
                                    if (props.single ?? false) {
                                        list = [category];
                                    } else {
                                        if (selected.includes(category)) {
                                            list = selected.filter(
                                                (value: Category) =>
                                                    value.getAjoIdentifier() !== category.getAjoIdentifier(),
                                            );
                                        } else {
                                            list = [...selected, category];
                                        }
                                    }
                                    setSelected(list);
                                    props.onSelectList(list);
                                }}
                                id={category.getAjoIdentifier()}
                                name={category.getAjoIdentifier()}
                                type="checkbox"
                                value=""
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label htmlFor={category.getAjoIdentifier()} className="font-medium text-white text-sm">
                                {category.name.get()}
                            </label>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
