import { AJOUpdater } from 'mp-js-react-auto-json-object';
import { Button } from '../commun/component/Button';
import { Spinner } from '../commun/component/Spinner';
import { NothingBlock } from './NothingBlock';
import { Separator } from './Separator';
import { TagList } from './TagList';
import { TitleSection } from './TitleSection';

export interface IComplexSectonListProps {
    title: string;
    canFusion: boolean;
    canAdd: boolean;
    list: any[];
    onAdd?: () => void;
    onFusion?: () => void;
    compute?: ((elem: any) => JSX.Element) | undefined;
    computeAll?: (() => JSX.Element) | undefined;
    classNameList?: string;

    field: string;

    textNothing: string;
    loading: boolean;
}
export const ComplexSectonList: React.FC<IComplexSectonListProps> = (props) => {
    const {
        classNameList = '',
        textNothing,
        onAdd,
        onFusion,
        title,
        canAdd,
        canFusion,
        list,
        compute,
        computeAll,
        loading,
        field,
    } = props;
    return (
        <div className="flex flex-col gap-3">
            <TitleSection title={title} onAdd={onAdd} canAdd={canAdd} onFusion={onFusion} canFusion={canFusion} />
            <Separator></Separator>
            <div>
                {loading ? (
                    <div className="w-full flex justify-center items-center">
                        <Spinner color="blue" />
                    </div>
                ) : (
                    <div>
                        <div className={classNameList}>
                            {compute != undefined &&
                                list.map((elem, index) => {
                                    return (
                                        <div
                                            key={title + '-' + index}
                                            className={
                                                field !== 'category' && field !== 'label' && field !== 'type'
                                                    ? 'w-full'
                                                    : ''
                                            }
                                        >
                                            {compute(elem)}
                                        </div>
                                    );
                                })}
                            {computeAll != undefined && computeAll()}
                        </div>
                        {field != 'agence' &&
                            field != 'product' &&
                            field != 'category_event' &&
                            list.length === 0 &&
                            !loading &&
                            canAdd && <NothingBlock textNothing={textNothing} canAdd={canAdd} onAdd={onAdd} />}
                    </div>
                )}
            </div>
        </div>
    );
};
