import { Box, Button, ButtonBase, Card, Typography } from '@mui/material';
import { useState } from 'preact/hooks';
import AppManager from 'src/commun/AppManager';
import { Spinner } from 'src/commun/component/Spinner';
import { post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import Toast from 'src/commun/toast/Toast';
import { errorManager } from 'src/modal/vendor/CategoryErrorManager';
import Prestation from 'src/object/profile/Prestation';
import Service from 'src/object/Service';
import Label from '../../object/tag/Label';
import { AddSvg } from '../AddSvg';
import { LabelSelect } from '../budget/LabelSelect';
import { DeleteSvg } from '../DeleteSvg';
import MpModal from '../extends/MpModal';

export interface ISimpleLabelListProps {
    list: Label[];
    add?: boolean;
    service?: Service;
}

export const SimpleLabelList = (props: ISimpleLabelListProps) => {
    const { add = false, service } = props;

    const [show, setShow] = useState(false);
    const [deleteLabel, setDeleteLabel] = useState<Label | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [label, setLabel] = useState<Label | null>(null);

    return (
        <div className="flex gap-2 flex-wrap">
            {props.list.map((label: Label, index: number) => {
                return (
                    <div
                        key={label.getAjoIdentifier()}
                        className={
                            'rounded-lg flex items-center flex-row gap-1 px-2 py-1 bg-' +
                            label.getColor() +
                            '-600 ' +
                            (add && 'px-3 py-2')
                        }
                    >
                        <p className="text-sm text-white font-medium">{label.name.get()}</p>
                        {add && (
                            <ButtonBase tabIndex={-1}>
                                <DeleteSvg
                                    className={'h-5 w-5 text-white'}
                                    onClick={() => {
                                        setDeleteLabel(label);
                                    }}
                                />
                            </ButtonBase>
                        )}
                    </div>
                );
            })}
            {add && (
                <Button
                    variant="contained"
                    color="primary"
                    tabIndex={-1}
                    className={'rounded-lg px-3 py-2.5 flex flex-row gap-1 bg-blue-600'}
                    onClick={() => {
                        setShow(true);
                    }}
                >
                    <AddSvg className={'h-5 w-5 text-white'} />
                    <p className="text-sm text-white font-medium">Ajouter</p>
                </Button>
            )}
            {add && deleteLabel && service && (
                <MpModal
                    title={'Supprimer un label'}
                    open={deleteLabel !== null}
                    onClose={() => {
                        setDeleteLabel(null);
                    }}
                    doneColor="failure"
                    done="Supprimer"
                    onSubmit={() => {
                        setLoading(true);
                        setError('');
                        let params = {
                            id: service.getAjoIdentifier(),
                            label: [
                                {
                                    id_del: deleteLabel.getAjoIdentifier(),
                                },
                            ],
                        };
                        post('/service/update', params)
                            .then((res: any) => {
                                setLoading(false);
                                if (Result.isSuccess(res)) {
                                    setShow(false);
                                    setDeleteLabel(null);
                                    Result.applyResult(res, AppManager.updater(), true);
                                    AppManager.addToast(new Toast('Le label a été supprimé avec succès.', 'success'));
                                } else {
                                    setError(errorManager.get(Result.getError(res)));
                                }
                            })
                            .catch((err) => {
                                setError('Une erreur est survenue, veuillez réséssayer plus tard.');
                            });
                    }}
                >
                    {loading ? (
                        <Box className="flex items-center justify-center py-12">
                            <Spinner color="red" className="" />
                        </Box>
                    ) : (
                        <Box className="flex flex-col !text-gray-600 gap-2">
                            <Typography className="!text-sm !font-semibold">
                                Êtes-vous sûr de vouloir supprimer ce label, cette action est irréversible.
                            </Typography>
                            {error !== '' && (
                                <Typography
                                    component={'p'}
                                    className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                                >
                                    {error}
                                </Typography>
                            )}
                        </Box>
                    )}
                </MpModal>
            )}
            {add && show && service && (
                <MpModal
                    title={'Ajouter un label'}
                    open={show}
                    onClose={() => {
                        setShow(false);
                    }}
                    onSubmit={() => {
                        setLoading(true);
                        setError('');

                        if (label) {
                            let link = '/service/update';
                            let params = {
                                id: service.getAjoIdentifier(),
                                label: [
                                    {
                                        id: label.getAjoIdentifier(),
                                    },
                                ],
                            };
                            post('/service/update', params)
                                .then((res: any) => {
                                    setLoading(false);
                                    if (Result.isSuccess(res)) {
                                        setShow(false);
                                        setDeleteLabel(null);
                                        Result.applyResult(res, AppManager.updater(), true);
                                        AppManager.addToast(new Toast('Le label a été ajouté avec succès.', 'success'));
                                    } else {
                                        setError(errorManager.get(Result.getError(res)));
                                    }
                                })
                                .catch((err) => {
                                    setError('Une erreur est survenue, veuillez réséssayer plus tard.');
                                });
                        } else {
                            setError('Veuillez sélectionner un label');
                        }
                    }}
                    done={'Ajouter'}
                >
                    {loading ? (
                        <Box className="flex items-center justify-center py-12">
                            <Spinner color="blue" className="" />
                        </Box>
                    ) : (
                        <Box className="flex flex-col !text-gray-600 gap-2">
                            <Typography className="!text-sm !font-semibold">LABEL</Typography>
                            <LabelSelect
                                label={label}
                                selectLabel={(label: Label | null) => {
                                    setLabel(label);
                                }}
                            />
                            {error !== '' && (
                                <Typography
                                    component={'p'}
                                    className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                                >
                                    {error}
                                </Typography>
                            )}
                        </Box>
                    )}
                </MpModal>
            )}
        </div>
    );
};
