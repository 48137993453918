import Taxe from '../../object/Taxe';
import Valeur from '../../object/Valeur';

export interface IPourcentSpanProps {
    value: number | Valeur | Taxe;
    className?: string;
}
export const PourcentSpan: React.FC<IPourcentSpanProps> = (props) => {
    const { value, className = '' } = props;
    if (value instanceof Valeur) {
        return <span className={className}>{value.pourcent.get()}&nbsp;%</span>;
    } else if (value instanceof Taxe) {
        return <span className={className}>{value.pourcent.get()}&nbsp;%</span>;
    } else if (isNaN(value)) {
        return <span className={className}>0 %</span>;
    } else {
        return <span className={className}>{Math.round(value * 100) / 100}&nbsp;%</span>;
    }
};
