import { JSXInternal } from 'preact/src/jsx';
import { RefObject } from 'react';
import AppManager from '../AppManager';

export interface IInputProps extends JSXInternal.HTMLAttributes<HTMLInputElement> {
    inputRef?: RefObject<HTMLInputElement>;
    selected?: any;
    onKeyUp?: (e: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => void;
}

export const Input: React.FunctionComponent<IInputProps> = (props) => {
    const { inputRef, selected, ...rest } = props;
    const onKeyUp = (e: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => {
        let parentOnKeyUp = props.onKeyUp;
        if (parentOnKeyUp != undefined) {
            parentOnKeyUp(e);
        }
        AppManager.onKeyUp(e);
    };
    const onKeyDown = (e: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => {
        AppManager.onKeyDown(e);
        let parentOnKeyDown = props.onKeyDown;
        if (parentOnKeyDown != undefined) {
            parentOnKeyDown(e);
        }
    };
    return <input {...rest} ref={props.inputRef} onKeyDown={onKeyDown} onKeyUp={onKeyUp} autoComplete={'nope'}></input>;
};
