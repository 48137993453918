import { Timer } from './Timer';
import { IObjectSelectable } from './../component/select/ObjectSelect';
import { AJOList, AJOObject, AJOProperties, AJOSimple } from 'mp-js-react-auto-json-object';
import AppManager from '../commun/AppManager';
import Role from './Role';
import Permission from './Permission';
import Devis from './Devis';
import Client from './profile/Client';
import { Jour } from './Jour';
import { Message } from './Message';
import { get } from '../commun/HTTP';
import Result from '../commun/Result';
import { WeekWork } from 'src/page/BoardPage';

export default class User extends AJOObject implements IObjectSelectable {
    static _TYPE: string = 'User';

    public email: AJOProperties<any>;
    public firstname: AJOProperties<any>;
    public lastname: AJOProperties<any>;
    public email_send: AJOProperties<any>;
    public password_send: AJOProperties<any>;
    public phone: AJOProperties<any>;
    public active: AJOProperties<any>;
    public delete: AJOProperties<any>;
    public set: AJOProperties<any>;
    public first: AJOProperties<any>;
    public root: AJOProperties<any>;
    public nb_heure: AJOProperties<any>;

    public week_action: AJOProperties<WeekWork[]>;

    public start_calcul_recup: AJOProperties<any>;
    public start_calcul_cp: AJOProperties<any>;
    public base_recup: AJOProperties<any>;
    public recup: AJOProperties<any>;
    public global_duration: AJOProperties<any>;
    public recup_comming: AJOProperties<any>;
    public base_cp: AJOProperties<any>;
    public end_calcul: AJOProperties<any>;

    public last_seen: AJOProperties<any>;
    public last_message: AJOProperties<any>;

    public global_take_recup: AJOProperties<any>;
    public global_recup: AJOProperties<any>;

    public role: AJOSimple<Role>;

    public messageList: AJOList<Message>;
    public timerList: AJOList<Timer>;
    public devisList: AJOList<Devis>;
    public closedList: AJOList<User>;

    public _show = false;
    public get show() {
        return this._show;
    }
    public set show(value) {
        this._show = value;
    }

    public _fetchDevis = false;
    public get fetchDevis() {
        return this._fetchDevis;
    }
    public set fetchDevis(value) {
        this._fetchDevis = value;
    }

    public _loading = false;
    public get loading() {
        return this._loading;
    }
    public set loading(value) {
        this._loading = value;
    }

    public isNotif(): boolean {
        let lastSeenDate = new Date(this.last_seen.get() ?? '1970-01-01T00:00:00Z');
        let lastMessageDate = new Date(this.last_message.get() ?? '1970-01-01T00:00:00Z');
        return lastSeenDate.toISOString() < lastMessageDate.toISOString();
    }

    public fetchDevisListInfo() {
        if (!this.fetchDevis) {
            this.loading = true;
            this.fetchDevis = true;
            AppManager.updater().makeUpdate();
            get('/user/devis/timer', {
                id_user: this.getAjoIdentifier(),
            }).then((res: any) => {
                this.loading = false;
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, AppManager.updater(), true);
                }
                AppManager.updater().makeUpdate();
            });
        }
    }

    public copyTimer: Timer | null = null;

    constructor() {
        super(User._TYPE);

        this.email = new AJOProperties('email', this);
        this.firstname = new AJOProperties('firstname', this);
        this.lastname = new AJOProperties('lastname', this);
        this.active = new AJOProperties('active', this);
        this.delete = new AJOProperties('delete', this);
        this.root = new AJOProperties('root', this);
        this.set = new AJOProperties('set', this);
        this.phone = new AJOProperties('phone', this);
        this.base_recup = new AJOProperties('base_recup', this);
        this.base_cp = new AJOProperties('base_cp', this);
        this.first = new AJOProperties('first', this);
        this.role = new AJOSimple(Role, 'has_role', this);

        this.global_take_recup = new AJOProperties<any>('global_take_recup_to_date', this);
        this.global_recup = new AJOProperties<any>('global_recup_to_date', this);

        this.password_send = new AJOProperties('password_send', this);
        this.email_send = new AJOProperties('email_send', this);

        this.start_calcul_recup = new AJOProperties('start_calcul_recup', this);
        this.recup = new AJOProperties('recup', this);
        this.global_duration = new AJOProperties('global_duration', this);
        this.recup_comming = new AJOProperties('recup_comming', this);
        this.start_calcul_cp = new AJOProperties('start_calcul_cp', this);
        this.end_calcul = new AJOProperties('end_calcul', this);
        this.nb_heure = new AJOProperties('nb_heure', this);

        this.last_seen = new AJOProperties('last_seen', this);
        this.last_seen.set('1970-01-01T00:00:00Z');

        this.last_message = new AJOProperties('last_message', this);
        this.last_message.set('1970-01-01T00:00:00Z');

        this.messageList = new AJOList(Message, 'has_message', this, (a, b) => {
            return new Date(a.date.get()).getTime() - new Date(b.date.get()).getTime();
        });
        this.timerList = new AJOList(Timer, 'has_user_timer', this);
        this.devisList = new AJOList(Devis, 'manage_devis', this);

        this.closedList = new AJOList(User, 'closed', this);

        this.week_action = new AJOProperties('week_action', this);
    }

    public getFullName() {
        return this.firstname.get() + ' ' + this.lastname.get();
    }

    public canPerform(): boolean {
        return !this.set.get() && !this.delete.get() && !this.first.get();
    }

    public hasPermission(type: number, ignorePerform: boolean = false): boolean {
        let res = this.canPerform() || ignorePerform;
        if (res) {
            res = this.root.get();
            if (!res && this.role.get() != null) {
                res = this.role.get()!.hasPermission(type) || this.role.get()!.hasPermission(Permission.ADMINISTRATOR);
            }
        }
        return res;
    }

    public static build(): User {
        return new User();
    }

    public isMe(user: User): boolean {
        return user.getAjoIdentifier() === this.getAjoIdentifier();
    }

    public isMeApp(): boolean {
        return AppManager.getUser()?.getAjoIdentifier() === this.getAjoIdentifier();
    }

    public getSelectTitle(): string {
        return this.getFullName();
    }
    public getSelectDescription(): string {
        return '';
    }

    getLastDay() {
        let date = new Date(this.getToday().getTime() + 24 * 60 * 60 * 1000);
        date.setTime(date.getTime());
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }

    getFirstDayRecup() {
        let date = new Date(this.start_calcul_recup.get());
        date.setTime(date.getTime());
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }

    getFirstDayCp() {
        let date = new Date(this.start_calcul_cp.get());
        date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }

    getTimerFull(borneDown: Date, borneUp: Date, cond: (t: Timer) => boolean = () => true): Date {
        return Timer.getTimerFull(this.timerList.getList(), borneDown, borneUp, cond);
    }

    getLittleTimer() {
        let timer: Timer | null = null;
        for (const ccTimer of this.timerList.getList()) {
            if (timer === null) {
                timer = ccTimer;
            } else {
                if (timer.getStartDate().getTime() > ccTimer.getStartDate().getTime()) {
                    timer = ccTimer;
                }
            }
        }
        return timer;
    }

    /*getFullRecup(){
        return this.getFlexRecup(this.getFirstDayRecup(), this.getLastDay());
    }*/
    getFullRecupDispo(end: Date = this.getLastDay(), all: boolean = false) {
        return this.getFlexRecup(this.getFirstDayRecup(), end, all);
    }
    /*getFullCp(){
        return this.getFlexCP(this.getFirstDayCp(), this.getLastDay());
    }*/
    getFullCpDispo() {
        return this.getFlexCP(this.getFirstDayCp(), this.getLastDay(), true);
    }
    getFlexRecup(debutRecup: Date, finRecup: Date, dispo: boolean = false) {
        let recup = this.base_recup.get() ?? 0;

        debutRecup.setHours(0);
        debutRecup.setMinutes(0);
        debutRecup.setSeconds(0);
        debutRecup.setMilliseconds(0);

        finRecup.setHours(0);
        finRecup.setMinutes(0);
        finRecup.setSeconds(0);
        finRecup.setMilliseconds(0);

        for (let timer of this.timerList.getList()) {
            if (
                timer.isRecup() &&
                (dispo ||
                    (timer.getEndDate().getTime() <= new Date().getTime() &&
                        timer.getEndDate().getTime() <= finRecup.getTime() &&
                        timer.isValidate()))
            ) {
                recup -= timer.getDuration() / 60 / 60 / 1000;
            }
        }

        for (let i = debutRecup.getTime(); i <= finRecup.getTime(); i += 24 * 60 * 60 * 1000) {
            let date = new Date(i);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);

            let dateEnd = new Date(i + 24 * 60 * 60 * 1000);
            dateEnd.setHours(0);
            dateEnd.setMinutes(0);
            dateEnd.setSeconds(0);
            dateEnd.setMilliseconds(0);

            let jourSpec: Jour | null = null;

            if (jourSpec == null) {
                let countHeure = this.getHeureDay(date);

                if (date.getTime() >= this.getFirstDayRecup().getTime() && dateEnd.getTime() <= finRecup.getTime()) {
                    if (![1, 2, 3, 4, 5].includes(date.getDay())) {
                        recup += countHeure;
                    } else if (
                        this.getToday().getFullYear() == date.getFullYear() &&
                        this.getToday().getMonth() == date.getMonth() &&
                        this.getToday().getDate() == date.getDate()
                    ) {
                        //recup += countHeure-7;
                        recup += Math.max(0, countHeure - 7);
                    } else {
                        recup += countHeure - 7;
                    }
                }
            }
            i += 1;
        }

        return recup;
    }

    getToday() {
        let date: Date;
        if (this.end_calcul.get() === undefined) {
            date = new Date();
        } else {
            if (new Date().toISOString() >= this.end_calcul.get()) {
                date = new Date(this.end_calcul.get());
            } else {
                date = new Date();
            }
        }
        date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
        return date;
    }

    getHeureDay(date: Date): number {
        let countHeure = 0;
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        let dateEnd = new Date(date.getTime());
        dateEnd.setTime(date.getTime() + 24 * 60 * 60 * 1000);

        for (let timer of this.timerList.getList()) {
            let duration = 0;

            let start = timer.getStartDate();
            let end = timer.getEndDate();

            // check if start and end are in the day
            if (
                start.toISOString() >= date.toISOString() &&
                start.toISOString() <= dateEnd.toISOString() &&
                end.toISOString() >= date.toISOString() &&
                end.toISOString() <= dateEnd.toISOString()
            ) {
                duration += timer.getDuration() / 60 / 60 / 1000;
            } else if (
                start.toISOString() <= date.toISOString() &&
                end.toISOString() >= date.toISOString() &&
                end.toISOString() <= dateEnd.toISOString()
            ) {
                duration += (end.getTime() - date.getTime()) / (60 * 60 * 1000);
            } else if (
                start.toISOString() >= date.toISOString() &&
                start.toISOString() <= dateEnd.toISOString() &&
                end.toISOString() >= dateEnd.toISOString()
            ) {
                duration += (dateEnd.getTime() - start.getTime()) / (60 * 60 * 1000);
            } else if (start.toISOString() <= date.toISOString() && end.toISOString() >= dateEnd.toISOString()) {
                duration += (dateEnd.getTime() - date.getTime()) / (60 * 60 * 1000);
            }

            countHeure += duration;
        }
        return countHeure;
    }

    getFlexCP(debutRecup: Date, finRecup: Date, dispo: boolean = false) {
        let cp = this.base_cp.get() ?? 0;

        debutRecup.setHours(0);
        debutRecup.setMinutes(0);
        debutRecup.setSeconds(0);
        debutRecup.setMilliseconds(0);

        finRecup.setHours(0);
        finRecup.setMinutes(0);
        finRecup.setSeconds(0);
        finRecup.setMilliseconds(0);

        for (let i = debutRecup.getTime(); i <= finRecup.getTime(); i += 24 * 60 * 60 * 1000) {
            let date = new Date(i);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);

            let dateEnd = new Date(i + 24 * 60 * 60 * 1000);
            dateEnd.setHours(0);
            dateEnd.setMinutes(0);
            dateEnd.setSeconds(0);
            dateEnd.setMilliseconds(0);

            if (date.getTime() >= this.getFirstDayCp().getTime() && dateEnd.getTime() < this.getLastDay().getTime()) {
                let nbDays = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
                cp += (2.5 / nbDays) * 24;
            }
            i += 1;
        }

        for (let timer of this.timerList.getList()) {
            if (timer.isCp() && (!timer.isPendding() || !dispo || timer.isValidate())) {
                cp -= timer.getDuration() / 60 / 60 / 1000;
            }
        }

        return cp;
    }
}
