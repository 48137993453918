import { AJOList } from 'mp-js-react-auto-json-object';
import Address from '../Address';
import Budget from '../Budget';
import Category from '../tag/Category';
import Label from '../tag/Label';
import { BaseProfile } from './BaseProfile';
import Service from '../Service';

export default abstract class ParentSevice extends BaseProfile {
    public serviceList: AJOList<Service>;
    constructor(type: string) {
        super(type);
        if (type === 'Prestation') {
            this.serviceList = new AJOList<Service>(Service, 'has_prestation_service', this, (a, b) => {
                let res = 0;
                if (a instanceof Service && b instanceof Service) {
                    if (new Date(a.date_now.get()).getTime() > new Date(b.date_now.get()).getTime()) {
                        res = -1;
                    } else if (new Date(a.date_now.get()).getTime() == new Date(b.date_now.get()).getTime()) {
                        res = 0;
                    } else {
                        res = 1;
                    }
                }
                return res;
            });
        } else {
            this.serviceList = new AJOList<Service>(Service, 'has_vendor_service', this, (a, b) => {
                let res = 0;
                if (a instanceof Service && b instanceof Service) {
                    if (new Date(a.date_now.get()).getTime() > new Date(b.date_now.get()).getTime()) {
                        res = -1;
                    } else if (new Date(a.date_now.get()).getTime() == new Date(b.date_now.get()).getTime()) {
                        res = 0;
                    } else {
                        res = 1;
                    }
                }
                return res;
            });
        }
    }

    public getCategoryList(): Category[] {
        // get all category of service list
        let categoryList: Category[] = [];
        for (let service of this.serviceList.getList()) {
            if (
                service.getCategoryList().length > 0 &&
                !categoryList.find(
                    (c: Category) => (service.getCategoryList()[0].getAjoIdentifier() ?? '') == c.getAjoIdentifier(),
                )
            ) {
                categoryList.push(service.getCategoryList()[0]);
            }
        }
        return categoryList;
    }

    public getLabelList(): Label[] {
        // get all label of service list
        let labelList: Label[] = [];
        for (let service of this.serviceList.getList()) {
            for (let label of service.labelList.getList()) {
                if (!labelList.find((c: Label) => label.getAjoIdentifier() == c.getAjoIdentifier())) {
                    labelList.push(label);
                }
            }
        }
        return labelList;
    }

    public getAddressList(): Address[] {
        // get all label of service list
        let addressList: Address[] = [];
        for (let service of this.serviceList.getList()) {
            let addressServiceList = service.getAddressList();
            for (let address of addressServiceList) {
                if (!addressList.find((c: Address) => address.getAjoIdentifier() == c.getAjoIdentifier())) {
                    addressList.push(address);
                }
            }
        }
        return addressList;
    }

    public getBudgetList(): Budget[] {
        // get all label of service list
        let budgetList: Budget[] = [];
        for (let service of this.serviceList.getList()) {
            let budgetServiceList = service.budgetList.getList();
            for (let budget of budgetServiceList) {
                if (!budgetList.find((c: Budget) => budget.getAjoIdentifier() == c.getAjoIdentifier())) {
                    budgetList.push(budget as Budget);
                }
            }
        }
        return budgetList;
    }
}
