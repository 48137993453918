import React from 'react';
import { Modal } from '../commun/modal/Modal';
import Utils from '../commun/Utils';
import { DevisClientSelect } from '../component/select/DevisClientSelect';
import { SectionSelect } from '../component/select/SectionSelect';
import Devis from '../object/Devis';
import { JSXInternal } from 'preact/src/jsx';
import { Section } from '../object/Section';
import { Timer } from '../object/Timer';
import { Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { ComplexInput } from '../commun/component/ComplexInput';
import moment from 'moment';
import ErrorHTTP from '../commun/ErrorHTTP';
import { dateEqual, dateStartSuppEnd, errorManager, fillAllDate } from './vendor/CategoryErrorManager';
import { post } from '../commun/HTTP';
import Result from '../commun/Result';
import { AJOUpdater } from 'mp-js-react-auto-json-object';
import AppManager from '../commun/AppManager';
import Toast from '../commun/toast/Toast';
import { throws } from 'assert';
import { Action } from '../object/Action';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import User from '../object/User';
import MpModal from 'src/component/extends/MpModal';
import { Spinner } from 'src/commun/component/Spinner';

export interface ITimerModalProps {
    action: 'add' | 'edit';
    show: boolean;
    user: User;

    timer: Timer | null;
    startDate: Date | null;
    endDate: Date | null;
    startHour: Date | null;
    endHour: Date | null;

    handleClose: () => void;
}

export interface ITimerModalState {
    elem: Devis | Action | null;
    section: Section | null;

    startDate: Date | null;
    endDate: Date | null;

    startHeure: Date | null;
    endHeure: Date | null;

    loading: boolean;
    error: ErrorHTTP;
    force: boolean;
    type: string;

    forceDestroy: number;
    forceMove: number;
}
export class TimerModal extends React.Component<ITimerModalProps, ITimerModalState> {
    showDay1 = false;
    showDay2 = false;

    showHeure1 = false;
    showHeure2 = false;

    focusInput1 = false;
    focusInput2 = false;

    constructor(props: ITimerModalProps) {
        /**
         * DEFAULT PROPS
         */
        super(props);

        this.state = this.initState();
    }

    closeAll(): void {
        this.showDay1 = false;
        this.showDay2 = false;
        this.showHeure1 = false;
        this.showHeure2 = false;
        this.focusInput1 = false;
        this.focusInput2 = false;
        AppManager.makeUpdate();
    }

    initState(): ITimerModalState {
        let res: ITimerModalState;
        res = {
            elem: this.props.timer?.object.get() ?? null,
            section: this.props.timer?.section.get() ?? null,

            endDate: this.props.timer?.getEndDate() ?? this.props.endDate ?? null,
            startDate: this.props.timer?.getStartDate() ?? this.props.startDate ?? null,

            startHeure:
                this.props.timer?.getStartDate() ?? this.props.startHour ?? new Date(25200 * 1000 + 3600 * 1000),
            endHeure: this.props.timer?.getEndDate() ?? this.props.endHour ?? new Date(57600 * 1000),

            error: ErrorHTTP.NO_ERROR(),
            loading: false,
            force: false,

            forceDestroy: 0,
            forceMove: 0,
            type: this.props.timer?.getTypeStr() ?? 'travail',
        };
        return res;
    }

    componentDidUpdate(
        prevProps: Readonly<ITimerModalProps>,
        prevState: Readonly<ITimerModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.show !== this.props.show) {
            this.setState(this.initState());
            Utils.blur();
        }
    }

    render(): JSXInternal.Element {
        const { show, handleClose } = this.props;
        const { loading, error } = this.state;

        let title: string = '';
        let done: string = '';
        if (this.props.action === 'add') {
            title = 'Ajouter un horaire';
            done = 'Ajouter';
        } else {
            title = "Modifier l'horaire";
            done = 'Modifier';
        }

        const finalHandleClose = () => {
            handleClose();
            Utils.blur();
        };

        return (
            <div
                className="absolute"
                onMouseUp={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onMouseDown={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onClick={(e) => {
                    this.closeAll();
                    AppManager.makeUpdate();
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <MpModal
                    title={title}
                    onClose={finalHandleClose}
                    onSubmit={() => {
                        this.setState({
                            error: ErrorHTTP.NO_ERROR(),
                            loading: false,
                            force: false,
                        });

                        if (
                            this.state.startDate != null &&
                            this.state.endDate != null &&
                            this.state.startHeure != null &&
                            this.state.endHeure != null
                        ) {
                            let startDate = new Date(this.state.startDate?.toISOString() ?? '');

                            startDate.setHours(this.state.startHeure?.getHours() ?? 0);
                            startDate.setMinutes(this.state.startHeure?.getMinutes() ?? 0);

                            let endDate = new Date(this.state.endDate?.toISOString() ?? '');

                            endDate.setHours(this.state.endHeure?.getHours() ?? 0);
                            endDate.setMinutes(this.state.endHeure?.getMinutes() ?? 0);

                            if (startDate.getTime() < endDate.getTime()) {
                                this.setState({
                                    loading: true,
                                });
                                post('/timer/edit', {
                                    force: this.state.force,
                                    type: this.state.type,
                                    id: this.props.timer?.getAjoIdentifier(),
                                    start: startDate.toISOString(),
                                    end: endDate.toISOString(),
                                    id_user: this.props.user.getAjoIdentifier(),
                                    id_object: this.state.elem?.getAjoIdentifier(),
                                    id_section: this.state.section?.getAjoIdentifier(),
                                }).then((res: any) => {
                                    this.setState({
                                        loading: false,
                                    });
                                    if (Result.isSuccess(res)) {
                                        Result.applyResult(res, AppManager.updater(), true);
                                        finalHandleClose();
                                        if (this.props.timer == null) {
                                            AppManager.addToast(
                                                new Toast("L'horaire a été ajouté avec succès.", 'success'),
                                            );
                                        } else {
                                            AppManager.addToast(
                                                new Toast("L'horaire a été modifié avec succès.", 'success'),
                                            );
                                        }
                                        this.props.timer?.setShow(false, 0, 0);
                                    } else {
                                        let err = Result.getError(res);
                                        if (err.getName() === 'timer_in') {
                                            this.setState({
                                                forceDestroy: res['destroy_timer'],
                                                forceMove: res['move_timer'],
                                                force: true,
                                            });
                                        } else {
                                            this.setState({
                                                error: Result.getError(res),
                                            });
                                        }
                                    }
                                });
                            } else if (startDate.getTime() == endDate.getTime()) {
                                this.setState({
                                    error: dateEqual,
                                });
                            } else {
                                this.setState({
                                    error: dateStartSuppEnd,
                                });
                            }
                        } else {
                            this.setState({
                                error: fillAllDate,
                            });
                        }
                    }}
                    done={done}
                    open={show}
                >
                    {loading ? (
                        <Box className="flex items-center justify-center p-10">
                            <Spinner color="blue" />
                        </Box>
                    ) : (
                        <Box className="flex flex-col gap-3">
                            {this.state.type === 'travail' && (
                                <DevisClientSelect
                                    special={true}
                                    focusInput={this.focusInput1}
                                    forceFocus={true}
                                    onObjectSelect={(e) => {
                                        this.setState({
                                            force: false,
                                            elem: e,
                                        });
                                    }}
                                    onFocus={() => {
                                        this.closeAll();
                                        this.focusInput1 = true;
                                        AppManager.makeUpdate();
                                    }}
                                    elem={this.state.elem ?? undefined}
                                    canAdd={true}
                                    placeholder="Devis / Action"
                                />
                            )}

                            {this.state.type === 'travail' && (
                                <SectionSelect
                                    onObjectSelect={(e) => {
                                        this.setState({
                                            force: false,
                                            section: e,
                                        });
                                    }}
                                    forceFocus={true}
                                    focusInput={this.focusInput2}
                                    onFocus={() => {
                                        this.closeAll();
                                        this.focusInput2 = true;
                                        AppManager.makeUpdate();
                                    }}
                                    elem={this.state.section ?? undefined}
                                    canAdd={true}
                                    placeholder="Section"
                                />
                            )}

                            <div className="flex flex-row gap-3">
                                <DatePicker
                                    onOpen={() => {
                                        this.closeAll();
                                        this.showDay1 = true;
                                        AppManager.makeUpdate();
                                    }}
                                    open={this.showDay1}
                                    className="flex-1"
                                    inputFormat="DD/MM/YYYY"
                                    renderInput={(params: any) => {
                                        return (
                                            <TextField
                                                onClick={(e: Event) => {
                                                    e.stopPropagation();
                                                }}
                                                {...params}
                                            />
                                        );
                                    }}
                                    onChange={(e: any) => {
                                        if (e !== null) {
                                            this.setState({
                                                force: false,
                                                startDate: (e as any).toDate(),
                                            });
                                        } else {
                                            this.setState({
                                                force: false,
                                                startDate: null,
                                            });
                                        }
                                    }}
                                    value={this.state.startDate}
                                />

                                <TimePicker
                                    onOpen={() => {
                                        this.closeAll();
                                        this.showHeure1 = true;
                                        AppManager.makeUpdate();
                                    }}
                                    open={this.showHeure1}
                                    className="flex-1"
                                    ampm={false}
                                    renderInput={(params: any) => {
                                        return (
                                            <TextField
                                                onClick={(e: Event) => {
                                                    e.stopPropagation();
                                                }}
                                                {...params}
                                            />
                                        );
                                    }}
                                    onChange={(e: any) => {
                                        if (e !== null) {
                                            this.setState({
                                                force: false,
                                                startHeure: (e as any).toDate(),
                                            });
                                        } else {
                                            this.setState({
                                                force: false,
                                                startHeure: null,
                                            });
                                        }
                                    }}
                                    value={moment(this.state.startHeure)}
                                />
                            </div>

                            <div className="flex flex-row gap-3">
                                <DatePicker
                                    onOpen={() => {
                                        this.closeAll();
                                        this.showDay2 = true;
                                        AppManager.makeUpdate();
                                    }}
                                    open={this.showDay2}
                                    className="flex-1"
                                    inputFormat="DD/MM/YYYY"
                                    renderInput={(params: any) => {
                                        return (
                                            <TextField
                                                onClick={(e: Event) => {
                                                    e.stopPropagation();
                                                }}
                                                {...params}
                                            />
                                        );
                                    }}
                                    onChange={(e: any) => {
                                        if (e !== null) {
                                            this.setState({
                                                force: false,
                                                endDate: (e as any).toDate(),
                                            });
                                        } else {
                                            this.setState({
                                                force: false,
                                                endDate: null,
                                            });
                                        }
                                    }}
                                    value={this.state.endDate}
                                />

                                <TimePicker
                                    onOpen={() => {
                                        this.closeAll();
                                        this.showHeure2 = true;
                                        AppManager.makeUpdate();
                                    }}
                                    open={this.showHeure2}
                                    className="flex-1"
                                    ampm={false}
                                    renderInput={(params: any) => {
                                        return (
                                            <TextField
                                                onClick={(e: Event) => {
                                                    e.stopPropagation();
                                                }}
                                                {...params}
                                            />
                                        );
                                    }}
                                    onChange={(e: any) => {
                                        if (e !== null) {
                                            this.setState({
                                                force: false,
                                                endHeure: (e as any).toDate(),
                                            });
                                        } else {
                                            this.setState({
                                                force: false,
                                                endHeure: null,
                                            });
                                        }
                                    }}
                                    value={moment(this.state.endHeure)}
                                />
                            </div>

                            <RadioGroup
                                row
                                className="flex flex-row justify-center gap-3"
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={this.state.type}
                                onChange={(e) => {
                                    this.setState({
                                        type: (e.target as any).value,
                                    });
                                }}
                            >
                                <FormControlLabel
                                    onClick={() => {
                                        this.closeAll();
                                        this.setState({
                                            type: 'travail',
                                        });
                                    }}
                                    value="travail"
                                    control={<Radio />}
                                    label="Travail"
                                />
                                <FormControlLabel
                                    onClick={() => {
                                        this.closeAll();
                                        this.setState({
                                            type: 'recup',
                                        });
                                    }}
                                    value="recup"
                                    control={<Radio />}
                                    label="Recup"
                                />

                                <FormControlLabel
                                    onClick={() => {
                                        this.closeAll();
                                        this.setState({
                                            type: 'maladie',
                                        });
                                    }}
                                    value="maladie"
                                    control={<Radio />}
                                    label="Arrêt maladie"
                                />
                            </RadioGroup>

                            {!error.equals(ErrorHTTP.NO_ERROR()) && (
                                <Typography
                                    component={'p'}
                                    className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                                >
                                    {errorManager.get(error)}
                                </Typography>
                            )}

                            {this.state.force && (
                                <p className="text-center w-full text-red-600 ">{this.getMessage()}</p>
                            )}
                        </Box>
                    )}
                </MpModal>
            </div>
        );
    }

    getMessage() {
        let res = 'Cette action impliquera ';
        if (this.state.forceDestroy !== 0 && this.state.forceDestroy !== undefined) {
            res += 'la suppresion de ' + this.state.forceDestroy;
            if (this.state.forceDestroy > 1) {
                res += ' horaires';
            } else {
                res += ' horaire';
            }
        }

        if (this.state.forceMove !== 0 && this.state.forceMove !== undefined) {
            if (this.state.forceDestroy !== 0 && this.state.forceDestroy !== undefined) {
                res += ' et ';
            }
            res += 'la modification de ' + this.state.forceMove;
            if (this.state.forceMove > 1) {
                res += ' horaires';
            } else {
                res += ' horaire';
            }
        }

        if (this.props.timer == null) {
            res += ". Pour valider le changement cliquez sur le bouton 'Ajouter' à nouveau.";
        } else {
            res += ". Pour valider le changement cliquez sur le bouton 'Modifier' à nouveau.";
        }
        return res;
    }
}
