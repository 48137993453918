import { useLocation } from 'react-router-dom';
import PageManager from '../PageManager';

export function Title() {
    const location = useLocation();
    const currentPage = PageManager.getCurrent(location.pathname);
    return (
        <div>
            <div className="text-5xl font-extrabold ...">
                <span className="body bg-clip-text text-transparent bg-gradient-to-r">{currentPage.getName()}</span>
            </div>
        </div>
    );
}
