import ErrorHTTP from '../../commun/ErrorHTTP';
import ErrorManager from '../../commun/ErrorManager';
import ErrorMessage from '../../commun/ErrorMessage';

export const errorManager = new ErrorManager('/all');

export const cantAddReversePaper = new ErrorHTTP('cant_add_reverse_paper');
errorManager.add(
    cantAddReversePaper,
    'Vous ne pouvez pas ajouter cette feuille, elle est déjà parente de cette feuille.',
);

export const closed = new ErrorHTTP('closed');
errorManager.add(closed, 'Cette semaine est déjà cloturée, vous ne pouvez plus la modifier.');

/**
 * DEVIS
 */

export const cantDeleteEndRecup = new ErrorHTTP('cant_delete_end_recup');
errorManager.add(cantDeleteEndRecup, 'Vous ne pouvez pas supprimer une récupération terminée.');

export const devisAddSuccess = new ErrorHTTP('devis_add_success');
errorManager.add(
    devisAddSuccess.setColor('green-600').setColorDark('green-300'),
    'Le prix de vente a été ajouté avec succès.',
);
export const devisNotAllCommand = new ErrorHTTP('not_all_command');
errorManager.add(devisNotAllCommand, "Vous n'avez pas encore commandé tous les prestations.");

/**
 * REDUC
 */
export const addReducSuccess = new ErrorHTTP('add_reduc_success');
export const editReducSuccess = new ErrorHTTP('edit_reduc_success');
errorManager.add(addReducSuccess, 'La remise commerciale a été ajoutée avec succès.');
errorManager.add(editReducSuccess, 'La remise commerciale a été modifiée avec succès.');

/**
 * BUDGET
 */
export const budgetNotFound = new ErrorHTTP('budget_not_found');
errorManager.add(budgetNotFound, "La prestation n'a pas été trouvé.");

/**
 * TIMER
 */
export const cantEditAcceptHoraire = new ErrorHTTP('cant_edit_accept_horaire');
errorManager.add(
    cantEditAcceptHoraire,
    "Cette action consiste à modifier un cp ou une recup déjà accepté. Une fois l'élément accepté, il ne peut plus être modifié.",
);

/**
 * FLEX BON COMMANDE
 */
export const flexbonCommandAddSuccess = new ErrorHTTP('flexbonCommand_add');
export const flexbonCommandEditSuccess = new ErrorHTTP('flexbonCommand_edit');
errorManager.add(
    flexbonCommandAddSuccess.setColor('green-600').setColorDark('green-300'),
    'Le groupe de commande a été ajoutée avec succès.',
);
errorManager.add(
    flexbonCommandEditSuccess.setColor('green-600').setColorDark('green-300'),
    'Le groupe de commande a été modifiée avec succès.',
);

/**
 * ACTION
 */
export const actionEditSuccess = new ErrorHTTP('action_edit_success');
errorManager.add(
    actionEditSuccess.setColor('green-600').setColorDark('green-300'),
    "L'action a été modifiée avec succès.",
);
export const actionAddSuccess = new ErrorHTTP('action_add_success');
errorManager.add(
    actionAddSuccess.setColor('green-600').setColorDark('green-300'),
    "L'action a été ajoutée avec succès.",
);

/**
 * AGENCE
 */
export const agenceAddSuccess = new ErrorHTTP('agence_add');
export const agenceEditSuccess = new ErrorHTTP('agence_edit');
errorManager.add(
    agenceAddSuccess.setColor('green-600').setColorDark('green-300'),
    "L'agence a été ajoutée avec succès.",
);
errorManager.add(
    agenceEditSuccess.setColor('green-600').setColorDark('green-300'),
    "L'agence a été modifiée avec succès.",
);
/**
 * USER
 */
export const selectUserError = new ErrorHTTP('select_user');
export const userAddSuccess = new ErrorHTTP('user_add');
errorManager.add(selectUserError, 'Veuillez sélectionner un utilisateur.');
errorManager.add(
    userAddSuccess.setColor('green-600').setColorDark('green-300'),
    "L'utilisateur a été ajouté avec succès.",
);

/**
 * BUDGET
 */
export const noBudgetReady = new ErrorHTTP('no_budget_ready');
errorManager.add(noBudgetReady, "Aucune ligne sélectionnée n'est prête à être commandée.");
export const budgetCommandSuccess = new ErrorHTTP('budget_command');
errorManager.add(
    budgetCommandSuccess.setColor('green-600').setColorDark('green-300'),
    'Les lignes sélectionnées ont été commandées avec succès.',
);

/**
 * CLIENT
 */
export const clientNotSelect = new ErrorHTTP('client_not_select');
errorManager.add(clientNotSelect, 'Veuiillez sélectionner un client.');
/**
 * PRESTATION
 */
export const prestationNotSelect = new ErrorHTTP('prestation_not_select');
errorManager.add(prestationNotSelect, 'Veuiillez sélectionner une prestation.');
/**
 * Vendor
 */
export const vendorNotSelect = new ErrorHTTP('vendor_not_select');
errorManager.add(vendorNotSelect, 'Veuiillez sélectionner un fournisseur.');
/**
 * PASSWORD
 */
export const passwordChangeSuccess = new ErrorHTTP('password_change_success');
errorManager.add(
    passwordChangeSuccess.setColor('green-600').setColorDark('green-300'),
    'Votre mot de passe a été modifié avec succès.',
);
export const notMatch = ErrorHTTP.NOT_MATCH();
errorManager.add(notMatch, 'Les nouveaux mots de passe ne correspondent pas.');
/**
 * SECTION
 */
export const sectionAddSuccess = new ErrorHTTP('section_add_success');
errorManager.add(
    sectionAddSuccess.setColor('green-600').setColorDark('green-300'),
    'La section a été ajoutée avec succès.',
);
export const sectionNotSelect = new ErrorHTTP('section_not_select');
errorManager.add(sectionNotSelect, 'Veuiillez sélectionner une section.');
export const sectionEditSuccess = new ErrorHTTP('section_edit_success');
errorManager.add(
    sectionEditSuccess.setColor('green-600').setColorDark('green-300'),
    'La section a été modifiée avec succès.',
);
/**
 * XLSX
 */
export const uploadSuccess = new ErrorHTTP('upload_success');
errorManager.add(uploadSuccess.setColor('green-600').setColorDark('green-300'), 'Le fichier a été envoyé avec succès.');
export const notXlsx = new ErrorHTTP('not_xlsx');
errorManager.add(notXlsx, "Le fichier n'est pas un fichier XLSX.");
export const bonCommandeNotFound = new ErrorHTTP('bon_commande_not_found');
errorManager.add(bonCommandeNotFound, "Le bon de commande n'a pas été trouvé.");
/**
 * ELEMENT
 */
export const elementAddSuccess = new ErrorHTTP('element_add_success');
errorManager.add(
    elementAddSuccess.setColor('green-600').setColorDark('green-300'),
    "L'élément a été ajouté avec succès.",
);
/**
 * ELEMENT
 */
export const dateEqual = new ErrorHTTP('date_equal');
errorManager.add(dateEqual, 'La date de début ne peut pas être égale à la date de fin.');
export const dateStartSuppEnd = new ErrorHTTP('date_start_supp_end');
errorManager.add(dateStartSuppEnd, 'La date de début ne peut pas être supérieure à la date de fin.');
export const fillAllDate = new ErrorHTTP('fill_all_date');
errorManager.add(fillAllDate, 'Veuillez remplir le début et la fin de votre horaire.');

export const wrongPassword = ErrorHTTP.WRONG_PASSWORD();
errorManager.addMessage(ErrorMessage.WRONG_PASSWORD());

export const badPasswordEnter = ErrorHTTP.BAD_PASSWORD_ENTER();
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_ENTER());

export const badPasswordMax = ErrorHTTP.BAD_PASSWORD_MAX();
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_MAX());

export const badPasswordMin = ErrorHTTP.BAD_PASSWORD_MIN();
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_MIN());

export const badPasswordType = ErrorHTTP.BAD_PASSWORD_TYPE();
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_TYPE());
/**
 * EMAIL
 */

/**
 * DEVIS ET CLIENT
 */
export const devisClientNoSelect = new ErrorHTTP('devis_client_no_select');
errorManager.add(devisClientNoSelect, 'Veuillez sélectionner un client ou un prix de vente.');
export const devisEditSuccess = new ErrorHTTP('devis_edit_success');
errorManager.add(devisEditSuccess, 'Le prix de vente a été mis à jour avec succès.');

export const devisCannotDoAtThisState = new ErrorHTTP('devis_connot_do_at_this_state');

export const productAddSuccess = new ErrorHTTP('product_add');
export const productDelSuccess = new ErrorHTTP('product_del');
export const productRemoveSuccess = new ErrorHTTP('product_remove');
export const productEditSuccess = new ErrorHTTP('product_edit');

export const templateAddSuccess = new ErrorHTTP('template_add');
export const templateRemoveSuccess = new ErrorHTTP('template_remove');
export const templateDelSuccess = new ErrorHTTP('template_del');

export const paperDeleteSuccess = new ErrorHTTP('paper_del');
export const paperCannotDelete = new ErrorHTTP('paper_cannot_delete');
export const paperDuplicateSuccess = new ErrorHTTP('paper_duplicate');
export const paperEditSuccess = new ErrorHTTP('paper_edit');

export const categoryAddSuccess = new ErrorHTTP('category_add');
export const categoryRemoveSuccess = new ErrorHTTP('category_remove');
export const categoryDeleteSuccess = new ErrorHTTP('category_delete');

export const labelAddSuccess = new ErrorHTTP('label_add');
export const labelRemoveSuccess = new ErrorHTTP('label_remove');
export const labelDeleteSuccess = new ErrorHTTP('label_delete');

export const contactAddSuccess = new ErrorHTTP('contact_add');
export const contactRemoveSuccess = new ErrorHTTP('contact_remove');

export const addressAddSuccess = new ErrorHTTP('address_add');
export const addressRemoveSuccess = new ErrorHTTP('address_remove');

export const facturationAddSuccess = new ErrorHTTP('facturation_add');
export const facturationRemoveSuccess = new ErrorHTTP('facturation_remove');

export const contactEditSuccess = new ErrorHTTP('contact_edit');
export const addressEditSuccess = new ErrorHTTP('address_edit');
export const vendorEditSuccess = new ErrorHTTP('vendor_edit');
export const vendorAddSuccess = new ErrorHTTP('vendor_add');

export const valeurAddSuccess = new ErrorHTTP('valeur_add');

export const taxeAddSuccess = new ErrorHTTP('taxe_add');

export const paperAddPaperSuccess = new ErrorHTTP('paper_add_paper');
export const cantAddSamePaper = new ErrorHTTP('cant_add_same_paper');

export const clientAddSuccess = new ErrorHTTP('client_add');
export const clientEditSuccess = new ErrorHTTP('client_edit');
export const categoryEditSuccess = new ErrorHTTP('category_edit');
export const labelEditSuccess = new ErrorHTTP('label_edit');
export const prestationEditSuccess = new ErrorHTTP('prestation_edit');

export const prestationAddSuccess = new ErrorHTTP('prestation_add');

export const fusionCategorySuccess = new ErrorHTTP('fusion_category');
export const fusionLabelSuccess = new ErrorHTTP('fusion_label');
export const vendorAlreadyExists = new ErrorHTTP('vendor_already_exists');
export const noDataFusion = new ErrorHTTP('no_data_fusion');

export const categoryChangeSuccess = new ErrorHTTP('category_change_success');

export const paperAddSuccess = new ErrorHTTP('paper_add');

export const devisValidateSuccess = new ErrorHTTP('devis_validate');
export const devisCommandSuccess = new ErrorHTTP('devis_command');
export const devisUnValidateSuccess = new ErrorHTTP('devis_unvalidate');
export const devisStartSuccess = new ErrorHTTP('devis_start');
export const devisTerminateSuccess = new ErrorHTTP('devis_terminate');
export const devisCloseSuccess = new ErrorHTTP('devis_close');

export const paperIncluded = new ErrorHTTP('paper_included');

export const categoryNeeded = new ErrorHTTP('category_needed');
export const addMargeSuccess = new ErrorHTTP('add_marge_success');
export const editMargeSuccess = new ErrorHTTP('edit_marge_success');
export const delMargeSuccess = new ErrorHTTP('del_marge_success');
export const honoraireAddSuccess = new ErrorHTTP('honoraire_add_success');

/**
 * EMAIL
 */
errorManager.addMessage(ErrorMessage.BAD_EMAIL_ENTER());
errorManager.addMessage(ErrorMessage.BAD_EMAIL_MIN());
errorManager.addMessage(ErrorMessage.BAD_EMAIL_MAX());
errorManager.addMessage(ErrorMessage.BAD_EMAIL_TYPE());
/**
 * FIRSTNAME
 */
errorManager.addMessage(ErrorMessage.BAD_FIRSTNAME_ENTER());
errorManager.addMessage(ErrorMessage.BAD_FIRSTNAME_MIN());
errorManager.addMessage(ErrorMessage.BAD_FIRSTNAME_MAX());
errorManager.addMessage(ErrorMessage.BAD_FIRSTNAME_TYPE());
/**
 * PASSWORD
 */
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_ENTER());
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_MIN());
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_MAX());
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_TYPE());
/**
 * LASTNAME
 */
errorManager.addMessage(ErrorMessage.BAD_LASTNAME_ENTER());
errorManager.addMessage(ErrorMessage.BAD_LASTNAME_MIN());
errorManager.addMessage(ErrorMessage.BAD_LASTNAME_MAX());
errorManager.addMessage(ErrorMessage.BAD_LASTNAME_TYPE());

/**
 * NAME
 */
errorManager.addMessage(ErrorMessage.BAD_NAME_ENTER());
errorManager.addMessage(ErrorMessage.BAD_NAME_MIN());
errorManager.addMessage(ErrorMessage.BAD_NAME_MAX());
errorManager.addMessage(ErrorMessage.BAD_NAME_TYPE());
/**
 * REST
 */
errorManager.addMessage(ErrorMessage.NO_PERMISSION());
errorManager.addMessage(ErrorMessage.NO_CHANGE());
errorManager.addMessage(ErrorMessage.BAD_VALUE());
errorManager.addMessage(ErrorMessage.NO_PERMISSION_ROOT());

errorManager.add(ErrorHTTP.NOT_FOUND(), "Ce fournisseur n'existe pas.");

errorManager.add(new ErrorHTTP('not_found_vendor'), "Ce fournisseur n'existe pas.");
errorManager.add(new ErrorHTTP('not_found_category'), "Cette catégorie n'existe pas.");
errorManager.add(new ErrorHTTP('not_found_prestation'), "Cette prestation n'existe pas.");
errorManager.add(new ErrorHTTP('not_found_label'), "Ce label n'existe pas.");
errorManager.add(new ErrorHTTP('not_found_address'), "Cette adresse n'existe pas.");
errorManager.add(new ErrorHTTP('not_found_contact'), "Ce contact n'existe pas.");

errorManager.add(new ErrorHTTP('vendor_category_already_exists'), 'Ce fournisseur à déjà cette catégorie.');
errorManager.add(new ErrorHTTP('category_not_exists'), "Cette catégorie n'existe pas.");
errorManager.add(new ErrorHTTP('category_already_exists'), 'Cette catégorie existe déjà.');
errorManager.add(new ErrorHTTP('vendor_category_not_exists'), "Ce fournisseur n'a pas cette catégorie.");

errorManager.add(new ErrorHTTP('vendor_label_already_exists'), 'Ce fournisseur à déjà ce label.');
errorManager.add(new ErrorHTTP('label_not_exists'), "Ce label n'existe pas.");
errorManager.add(new ErrorHTTP('label_already_exists'), 'Ce label existe déjà.');
errorManager.add(new ErrorHTTP('vendor_label_not_exists'), "Ce fournisseur n'a pas ce label.");

errorManager.add(new ErrorHTTP('vendor_contact_already_exists'), 'Ce fournisseur à déjà ce contact.');
errorManager.add(new ErrorHTTP('contact_not_exists'), "Ce contact n'existe pas.");
errorManager.add(new ErrorHTTP('contact_already_exists'), 'Ce contact existe déjà.');
errorManager.add(new ErrorHTTP('vendor_contact_not_exists'), "Ce fournisseur n'a pas ce contact.");

errorManager.add(new ErrorHTTP('vendor_address_already_exists'), 'Ce fournisseur à déjà cette adresse de prestation.');
errorManager.add(new ErrorHTTP('address_not_exists'), "Cette adresse n'existe pas.");
errorManager.add(new ErrorHTTP('address_already_exists'), 'Cette adresse existe déjà.');
errorManager.add(new ErrorHTTP('vendor_address_not_exists'), "Ce fournisseur n'a pas cette adresse de prestation.");

errorManager.add(
    new ErrorHTTP('vendor_facturation_already_exists'),
    'Ce fournisseur à déjà cette adresse de facturation.',
);
errorManager.add(new ErrorHTTP('facturation_not_exists'), "Cette adresse n'existe pas.");
errorManager.add(new ErrorHTTP('facturation_already_exists'), 'Cette adresse existe déjà.');
errorManager.add(
    new ErrorHTTP('vendor_facturation_not_exists'),
    "Ce fournisseur n'a pas cette adresse de facturation.",
);

errorManager.addMessage(ErrorMessage.BAD_VALUE());
errorManager.add(
    categoryAddSuccess.setColor('green-600').setColorDark('green-300'),
    'La catégorie a été ajoutée avec succès.',
);
errorManager.add(
    categoryRemoveSuccess.setColor('green-600').setColorDark('green-300'),
    'La catégorie a été supprimée avec succès.',
);

errorManager.add(labelAddSuccess.setColor('green-600').setColorDark('green-300'), 'Le label a été ajouté avec succès.');
errorManager.add(
    labelRemoveSuccess.setColor('green-600').setColorDark('green-300'),
    'Le label a été supprimé avec succès.',
);

errorManager.add(
    contactAddSuccess.setColor('green-600').setColorDark('green-300'),
    'Le contact a été ajouté avec succès.',
);
errorManager.add(
    contactRemoveSuccess.setColor('green-600').setColorDark('green-300'),
    'Le contact a été supprimé avec succès.',
);

errorManager.add(
    addressAddSuccess.setColor('green-600').setColorDark('green-300'),
    "L'adresse de prestation a été ajoutée avec succès.",
);
errorManager.add(
    addressRemoveSuccess.setColor('green-600').setColorDark('green-300'),
    "L'adresse de prestation a été supprimée avec succès.",
);

errorManager.add(
    facturationAddSuccess.setColor('green-600').setColorDark('green-300'),
    "L'adresse de facturation a été ajoutée avec succès.",
);
errorManager.add(
    facturationRemoveSuccess.setColor('green-600').setColorDark('green-300'),
    "L'adresse de facturation a été supprimée avec succès.",
);

errorManager.add(
    contactEditSuccess.setColor('green-600').setColorDark('green-300'),
    'Le contact a été modifié avec succès.',
);

errorManager.add(
    addressEditSuccess.setColor('green-600').setColorDark('green-300'),
    "L'adresse a été modifié avec succès.",
);

errorManager.add(
    vendorEditSuccess.setColor('green-600').setColorDark('green-300'),
    'Le fournisseur a été modifié avec succès.',
);

errorManager.add(
    categoryEditSuccess.setColor('green-600').setColorDark('green-300'),
    'La catégorie a été modifiée avec succès.',
);
errorManager.add(
    labelEditSuccess.setColor('green-600').setColorDark('green-300'),
    'Le label a été modifié avec succès.',
);

errorManager.add(
    categoryDeleteSuccess.setColor('green-600').setColorDark('green-300'),
    'La catégorie a été supprimée avec succès.',
);
errorManager.add(
    labelDeleteSuccess.setColor('green-600').setColorDark('green-300'),
    'Le label a été supprimé avec succès.',
);
errorManager.add(
    prestationAddSuccess.setColor('green-600').setColorDark('green-300'),
    'La prestation a été ajoutée avec succès.',
);
errorManager.add(
    prestationEditSuccess.setColor('green-600').setColorDark('green-300'),
    'La prestation a été modifiée avec succès.',
);
errorManager.add(
    vendorAddSuccess.setColor('green-600').setColorDark('green-300'),
    'Le fournisseur a été ajouté avec succès.',
);
errorManager.add(
    clientAddSuccess.setColor('green-600').setColorDark('green-300'),
    'Le client a été ajouté avec succès.',
);
errorManager.add(
    fusionCategorySuccess.setColor('green-600').setColorDark('green-300'),
    'Les catégories ont été fusionnées avec succès.',
);
errorManager.add(
    fusionLabelSuccess.setColor('green-600').setColorDark('green-300'),
    'Les labels ont été fusionnés avec succès.',
);
errorManager.add(vendorAlreadyExists, 'Ce fournisseur existe déjà.');
errorManager.add(noDataFusion, 'Aucune donnée à fusionner.');
errorManager.add(
    categoryChangeSuccess.setColor('green-600').setColorDark('green-300'),
    'La catégorie a été changée avec succès.',
);
errorManager.add(new ErrorHTTP('bad_marge_type'), 'Veuillez entrer une marge valide.');
errorManager.add(new ErrorHTTP('bad_marge_compatible'), 'La marge minimale doit être inférieure à la marge maximale.');
errorManager.add(new ErrorHTTP('bad_marge_min'), 'Une marge doit être supérieure ou égal à 0.');
errorManager.add(new ErrorHTTP('bad_marge_max'), 'Une marge doit être inférieure ou égale à 100.');
errorManager.add(productAddSuccess, 'Le offre a été ajouté avec succès.');
errorManager.add(productEditSuccess, 'Le offre a été modifié avec succès.');
errorManager.add(productDelSuccess, 'Le offre a été supprimé avec succès.');
errorManager.add(productRemoveSuccess, 'Le offre a été supprimé avec succès.');
errorManager.add(clientEditSuccess, 'Le client a été modifié avec succès.');
errorManager.add(templateAddSuccess, 'Le template a été ajouté avec succès.');
errorManager.add(templateRemoveSuccess, 'Le template a été supprimé avec succès.');
errorManager.add(templateDelSuccess, 'Le template a été supprimé avec succès.');
errorManager.add(
    paperAddSuccess.setColor('green-600').setColorDark('green-300'),
    'La feuille a été ajouté avec succès.',
);

errorManager.add(taxeAddSuccess.setColor('green-600').setColorDark('green-300'), 'La TVA a été ajoutée avec succès.');

errorManager.add(
    valeurAddSuccess.setColor('green-600').setColorDark('green-300'),
    'La valeur ajoutée a été ajoutée avec succès.',
);

errorManager.add(
    paperDeleteSuccess.setColor('green-600').setColorDark('green-300'),
    'La feuille a été supprimée avec succès.',
);

errorManager.add(paperIncluded, 'Cette feuille est incluse dans une autre feuille, il ne peut être supprimée.');

errorManager.add(
    paperAddPaperSuccess.setColor('green-600').setColorDark('green-300'),
    'La feuille a été ajoutée avec succès.',
);

errorManager.add(cantAddSamePaper, "La feuille ne peut s'inclure dans elle-même.");

errorManager.add(
    devisValidateSuccess.setColor('green-600').setColorDark('green-300'),
    'Le prix de vente a été validé avec succès.',
);

errorManager.add(
    devisUnValidateSuccess.setColor('green-600').setColorDark('green-300'),
    'Le prix de vente est denouveau modifiable.',
);
errorManager.add(categoryNeeded, 'Veuillez choisir une catégorie.');
errorManager.add(
    addMargeSuccess.setColor('green-600').setColorDark('green-300'),
    'La marge a été ajoutée avec succès.',
);
errorManager.add(
    delMargeSuccess.setColor('green-600').setColorDark('green-300'),
    'La marge a été supprimée avec succès.',
);
errorManager.add(
    honoraireAddSuccess.setColor('green-600').setColorDark('green-300'),
    'Le honoraire a été ajouté avec succès.',
);
errorManager.add(
    paperDuplicateSuccess.setColor('green-600').setColorDark('green-300'),
    'La feuille a été dupliquée avec succès.',
);
errorManager.add(paperCannotDelete, 'La feuille ne peut être supprimée.');
errorManager.add(devisCannotDoAtThisState, 'Vous ne pouvez pas effectuer cette action au stade du prix de vente.');
errorManager.add(
    paperEditSuccess.setColor('green-600').setColorDark('green-300'),
    'La feuille a été modifiée avec succès.',
);
errorManager.add(
    devisCommandSuccess.setColor('green-600').setColorDark('green-300'),
    'Le prix de vente est passée au stade de commande avec succès.',
);
errorManager.add(
    devisStartSuccess.setColor('green-600').setColorDark('green-300'),
    "L'événement a été démarré avec succès.",
);
errorManager.add(
    devisTerminateSuccess.setColor('green-600').setColorDark('green-300'),
    "L'événement a été terminé avec succès.",
);
errorManager.add(
    devisCloseSuccess.setColor('green-600').setColorDark('green-300'),
    'Le prix de vente a été fermé avec succès.',
);
