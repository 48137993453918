import { Card } from '@mui/material';
import Note from '../object/Note';

export interface INoteElementViewProps {
    note: Note;
}

export const NoteElementView: React.FunctionComponent<INoteElementViewProps> = (props) => {
    //onDelete
    const { note } = props;

    return (
        <Card className="flex flex-row py-1.5 px-2.5 bg-white rounded-lg gap-2 w-full">
            <div className="flex flex-col justify-center flex-1">
                <div className="flex flex-row">
                    <span className="text-sm font-medium text-black dark:text-white">
                        {note.user.get()!.firstname.get()}
                    </span>
                    {note.rate.get() != undefined && (
                        <span className="text-sm flex-1 text-right text-black dark:text-white">
                            {note.rate.get().toFixed(1)}
                        </span>
                    )}
                </div>
                <span className="text-sm text-gray-600 dark:text-gray-300">{note.comment.get()}</span>
            </div>
        </Card>
    );
};
