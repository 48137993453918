import { Link } from 'react-router-dom';
import { Color } from '../Constant';
import { JSXInternal } from 'preact/src/jsx';

export interface IButtonProps extends JSXInternal.HTMLAttributes<HTMLButtonElement> {
    selected?: boolean;
    focus?: boolean;
    outline?: boolean;
    border?: number;
    position?: string;

    pt?: number;
    pb?: number;
    pl?: number;
    pr?: number;

    gap?: number;

    color: Color | 'cancel';

    roundedSize?: 'lg' | 'md';
    roundedPosition?: '' | 'r' | 'l' | 't' | 'b';
    textSize?: 'lg' | 'md' | 'sm';
}
export const Button: React.FunctionComponent<IButtonProps> = (props) => {
    const {
        focus = false,
        outline = false,
        textSize = 'base',
        selected = false,
        children,
        className,
        position = 'relative',
        pt = 2,
        color = 'blue',
        pb = 2,
        pl = 4,
        pr = 4,
        border = 0,
        roundedPosition = '',
        gap = 0,
        roundedSize = 'md',
        ...rest
    } = props;

    let roundClass = 'rounded';
    if (roundedPosition != '') {
        roundClass += '-' + roundedPosition;
    }
    roundClass += '-' + roundedSize;

    let colorClass = '';
    if (!outline) {
        colorClass +=
            ' text-' +
            textSize +
            ' transition-colors text-white border border-' +
            border +
            ' border-' +
            color +
            '-600 dark:border-' +
            color +
            '-500  bg-' +
            color +
            '-600 hover:bg-' +
            color +
            '-700 focus:ring-4 focus:ring-' +
            color +
            '-300 dark:bg-' +
            color +
            '-500 dark:hover:bg-' +
            color +
            '-600 dark:focus:ring-' +
            color +
            '-900 ';
    } else {
        colorClass +=
            ' text-' +
            textSize +
            ' transition-colors text-' +
            color +
            '-600 dark:text-' +
            color +
            '-500 border border-2 border-' +
            color +
            '-600 dark:border-' +
            color +
            '-500 hover:bg-' +
            color +
            '-200 focus:ring-4 focus:ring-' +
            color +
            '-300 dark:hover:bg-' +
            color +
            '-900 dark:focus:ring-' +
            color +
            '-600 ';
    }

    return (
        <button
            className={
                'inline-flex disabled:opacity-50  items-center justify-center overflow-hidden font-medium ' +
                roundClass +
                colorClass +
                position +
                ' ' +
                roundClass +
                ' ' +
                className
            }
            {...rest}
        >
            <span
                className={
                    ' relative gap-' +
                    gap +
                    ' flex items-center justify-center rounded-md gap-' +
                    gap +
                    ' pl-' +
                    pl +
                    ' pr-' +
                    pr +
                    ' pt-' +
                    pt +
                    ' pb-' +
                    pb
                }
            >
                {children}
            </span>
        </button>
    );
};
