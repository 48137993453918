import ErrorHTTP from './ErrorHTTP';
import ErrorMessage from './ErrorMessage';

export default class ErrorManager {
    private list: ErrorMessage[];
    private action: string;
    constructor(action: string) {
        this.action = action;
        this.list = [
            ErrorMessage.USER_DELETED(),
            ErrorMessage.USER_PASSWORD_CHANGE(),
            ErrorMessage.USER_PASSWORD_INIT(),
        ];
    }
    private getError(error: ErrorHTTP): ErrorMessage | null {
        let resError: ErrorMessage | null = null;
        let i = 0;
        while (resError == null && i < this.list.length) {
            if (this.list[i].getError().equals(error)) {
                resError = this.list[i];
            }
            i++;
        }
        return resError;
    }
    public add(error: ErrorHTTP, message: string) {
        if (this.getError(error) == null) {
            let errorMessage = new ErrorMessage(error, message);
            this.list.push(errorMessage);
        } else {
            throw new Error('Error already exists');
        }
    }
    public addMessage(errorMessage: ErrorMessage) {
        this.list.push(errorMessage);
    }
    public get(error: ErrorHTTP): string {
        return this.getError(error)?.getMessage() ?? 'Une erreur est survenue.';
    }
    public getColor(error: ErrorHTTP): string {
        return this.getError(error)?.getError().getColor() ?? 'red-500';
    }
    public getDarkColor(error: ErrorHTTP): string {
        return this.getError(error)?.getError().getDarkColor() ?? 'red-300';
    }
}
