import { errorManager, uploadSuccess } from '../modal/vendor/CategoryErrorManager';
import { AJOList, AJOProperties } from 'mp-js-react-auto-json-object';
import { AJOObject } from 'mp-js-react-auto-json-object';
import AppManager from '../commun/AppManager';
import { post, postFile } from '../commun/HTTP';
import Result from '../commun/Result';
import Toast from '../commun/toast/Toast';
import { TagObject } from './tag/TagObject';
import { Color } from '../commun/Constant';
import Utils from '../commun/Utils';
import { BonCommandeView } from '../component/BonCommandeView';
import { JSXInternal } from 'preact/src/jsx';
import Budget from './Budget';
export class BonCommande extends TagObject {
    public static _TYPE: string = 'BonCommande';

    public laoding: boolean = false;

    public code: AJOProperties<any>;
    public email: AJOProperties<any>;
    public dateCreate: AJOProperties<any>;
    public content: AJOProperties<any>;
    public subject: AJOProperties<any>;
    public status_email: AJOProperties<any>;

    public budgetList: AJOList<Budget>;

    constructor() {
        super(BonCommande._TYPE);

        this.laoding = false;
        this.code = new AJOProperties('code');
        this.name = new AJOProperties('code');
        this.content = new AJOProperties('content');
        this.email = new AJOProperties('email');
        this.dateCreate = new AJOProperties('date_create');
        this.subject = new AJOProperties('subject');
        this.status_email = new AJOProperties('status_email');

        this.budgetList = new AJOList<Budget>(Budget, 'has_bon_commande', this);
    }

    public static build(): BonCommande {
        return new BonCommande();
    }

    public getColor(): Color {
        return Utils.getColorFromStr(this.name.get() ?? '') as Color;
    }

    public isBonus(): boolean {
        return true;
    }

    public getBonus(): JSXInternal.Element {
        return (
            <div className={'px-2 pb-2 bg-' + this.getColor() + '-700 text-base text-white'}>{this.getMessage()}</div>
        );
    }

    public reSend(callback: () => void) {
        let status_email = this.status_email.get();

        post('/mail/resend', {
            id: this.getAjoIdentifier(),
        }).then((res: any) => {
            callback();

            if (Result.isSuccess(res)) {
                if (status_email === undefined) {
                    AppManager.addToast(new Toast("Le serveur à essayé de envoyer l'email.", 'success'));
                } else {
                    AppManager.addToast(new Toast("Le serveur à essayé de réenvoyer l'email.", 'success'));
                }

                Result.applyResult(res, AppManager.updater(), true);
            } else {
                AppManager.addToast(new Toast(errorManager.get(Result.getError(res)), 'error'));
            }
        });
    }

    public getMessage(): string {
        if (this.status_email.get() === -1) {
            return "Le bon de commande n'a pas encore été envoyé.";
        } else if (this.status_email.get() === 1) {
            return "Le serveur n'a pas réussit à se connecter à la boite mail.";
        } else if (this.status_email.get() === 0) {
            return "L'email a été envoyé avec succès, ce qui ne signifie pas que le contact a reçu l'email.";
        } else if (this.status_email.get() === 2) {
            return "Une erreur est survenue lors de l'envoi du email.";
        }
        return '';
    }
}
