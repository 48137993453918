import { JSXInternal } from 'preact/src/jsx';
import { Link } from 'react-router-dom';

export interface IButtonGradiantProps extends JSXInternal.HTMLAttributes<HTMLButtonElement> {
    fromColor?: string;
    toColor?: string;

    darkRingColor?: string;
    ringColor?: string;

    selected?: boolean;
    focus?: boolean;
    position?: string;

    pt?: number;
    pb?: number;
    pl?: number;
    pr?: number;

    gap?: number;

    roundedSize?: 'lg' | 'md';
    roundedPosition?: '' | 'r' | 'l' | 't' | 'b';
    textSize?: 'lg' | 'md' | 'sm';
}
export const ButtonGradiant: React.FunctionComponent<IButtonGradiantProps> = (props) => {
    const {
        focus = false,
        textSize = 'base',
        fromColor = 'green-400',
        toColor = 'blue-600',
        ringColor = 'green-200',
        selected = false,
        children,
        className,
        position = 'relative',
        pt = 2,
        pb = 2,
        darkRingColor = ringColor,
        pl = 4,
        pr = 4,
        roundedPosition = '',
        gap = 0,
        roundedSize = 'lg',
        ...rest
    } = props;

    let roundClass = 'rounded';
    if (roundedPosition != '') {
        roundClass += '-' + roundedPosition;
    }
    roundClass += '-' + roundedSize;

    let hoverClass: string;
    if (!selected) {
        hoverClass = 'hover:bg-gradient-to-br';
    } else {
        hoverClass = 'text-white bg-gradient-to-br';
    }
    return (
        <button
            {...rest}
            className={
                'inline-flex items-center justify-center overflow-hidden text-md font-medium text-gray-900 ' +
                roundClass +
                ' group bg-white dark:bg-gray-800 ' +
                hoverClass +
                ' hover:from-' +
                fromColor +
                ' hover:to-' +
                toColor +
                ' from-' +
                toColor +
                ' to-' +
                fromColor +
                ' hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-' +
                ringColor +
                ' dark:focus:ring-' +
                darkRingColor +
                ' ' +
                position +
                ' ' +
                roundClass +
                ' ' +
                className
            }
        >
            <span
                className={
                    'text-' + textSize + ' relative rounded-md pl-' + pl + ' pr-' + pr + ' pt-' + pt + ' pb-' + pb
                }
            >
                {children}
            </span>
        </button>
    );
};
