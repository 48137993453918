import { CheckBox } from '@mui/icons-material';
import { Card } from '@mui/material';
import { useEffect, useState } from 'react';
import Product from 'src/object/tag/Product';

export interface ISimpleProductListProps {
    list: Product[];
    selectList: Product[];
    onSelectList: (list: Product[]) => void;
}

export const SelectProductList = (props: ISimpleProductListProps) => {
    const [selected, setSelected] = useState<Product[]>(props.selectList);

    useEffect(() => {
        setSelected(props.selectList);
    }, [props.selectList.length]);

    useEffect(() => {
        props.onSelectList(selected);
    }, [selected]);

    return (
        <div className="flex gap-2 flex-wrap">
            {props.list.map((product: Product, index: number) => {
                return (
                    <div
                        className={
                            'border rounded-lg px-2 py-1 bg-' + (product.category.get()?.getColor() ?? '') + '-700'
                        }
                    >
                        <div className="flex items-center gap-2">
                            <input
                                checked={
                                    selected.find((item) => item.getAjoIdentifier() === product.getAjoIdentifier()) !==
                                    undefined
                                }
                                onChange={() => {
                                    if (selected.includes(product)) {
                                        setSelected(
                                            selected.filter(
                                                (value: Product) =>
                                                    value.getAjoIdentifier() !== product.getAjoIdentifier(),
                                            ),
                                        );
                                    } else {
                                        setSelected([...selected, product]);
                                    }
                                }}
                                id={product.getAjoIdentifier()}
                                type="checkbox"
                                value=""
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label htmlFor={product.getAjoIdentifier()} className="text-white text-sm">
                                {product.name.get()}
                            </label>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
