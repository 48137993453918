import { AJOObject, AJOProperties } from 'mp-js-react-auto-json-object';
import { TagObject } from './TagObject';

export default class Label extends TagObject {
    public static _TYPE: string = 'Label';

    constructor() {
        super(Label._TYPE);
    }

    public static build(): Label {
        return new Label();
    }
}
