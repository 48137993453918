import { AJOList, AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { title } from 'process';
import { useEffect, useState } from 'react';
import AppManager from '../../commun/AppManager';
import { ComplexInput } from '../../commun/component/ComplexInput';
import { ComplexSelect } from '../../commun/component/ComplexSelect';
import { Color, ModalBottomMode } from '../../commun/Constant';
import ErrorHTTP from '../../commun/ErrorHTTP';
import ErrorManager from '../../commun/ErrorManager';
import ErrorMessage from '../../commun/ErrorMessage';
import { get, post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import Utils from '../../commun/Utils';
import { AddressView } from '../../component/AddressView';
import { SelectColor } from '../../component/SelectColor';
import Address from '../../object/Address';
import { categoryAddSuccess, errorManager } from './CategoryErrorManager';

export interface IAddressModalProps {
    handleClose: () => void;
    parent: AJOObject;
    show: boolean;
    field: string;
    button: string;
    title: string;
    successError: ErrorHTTP;

    address?: Address;
    edit?: boolean;
}

export const AddressModal: React.FC<IAddressModalProps> = (props) => {
    const { field, title, button, handleClose, show, parent, successError, edit, address } = props;

    const [stateList, updateStateList] = useState(new AJOState<AJOList<Address>>(new AJOList<Address>(Address)));
    stateList.setUpdate(updateStateList);

    const [loading, updateLoad] = useState(false);
    const [error, updateError] = useState(ErrorHTTP.NO_ERROR());

    const [id, updateId] = useState(address?.name.get() ?? '');
    const [name, updateName] = useState(address?.name.get() ?? '');
    const [bonus, updateBonus] = useState(address?.bonus.get() ?? '');
    const [numberAndStreet, updateNumberAndStreet] = useState(address?.number_and_street.get() ?? '');
    const [city, updateCity] = useState(address?.city.get() ?? '');
    const [postalCode, updatePostalCode] = useState(address?.postal_code.get() ?? '');

    useEffect(() => {
        if (show) {
            updateLoad(false);
            updateError(ErrorHTTP.NO_ERROR());
            updateName(address?.name.get() ?? '');
            updateNumberAndStreet(address?.number_and_street.get() ?? '');
            updateCity(address?.city.get() ?? '');
            updateBonus(address?.bonus.get() ?? '');
            updatePostalCode(address?.postal_code.get() ?? '');
        }
    }, [show]);

    const fetchList = () => {
        if (!edit) {
            let url = field;
            if (field === 'facturation') {
                url = 'address';
            }
            get('/' + url + '/list', {}).then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, stateList, true);
                }
            });
        }
    };

    useEffect(() => {
        if (show) {
            stateList.get()!.reset();
            fetchList();
        }
    }, [show]);

    const onAddCategory = () => {
        updateLoad(true);
        updateError(ErrorHTTP.NO_ERROR());

        if (!edit) {
            const params: any = {
                id: parent.getAjoIdentifier(),
            };

            const cat: any = {};

            let id = '';
            if (name != '') {
                for (let i = 0; i < stateList.get()!.size(); i++) {
                    if (
                        (stateList.get()!.get(i).name.get() ?? '') === name &&
                        (stateList.get()!.get(i).number_and_street.get() ?? '') === numberAndStreet &&
                        (stateList.get()!.get(i).city.get() ?? '') === city &&
                        (stateList.get()!.get(i).postal_code.get() ?? '') === postalCode &&
                        (stateList.get()!.get(i).bonus.get() ?? '') === bonus
                    ) {
                        id = stateList.get()!.get(i)!.getAjoIdentifier();
                        break;
                    }
                }
            }
            if (id != '') {
                cat['id'] = id;
            } else {
                cat['name'] = name;
                cat['number_and_street'] = numberAndStreet;
                cat['postal_code'] = parseInt(postalCode);
                cat['bonus'] = bonus;
                cat['city'] = city;
            }

            params[field] = cat;
            post('/' + parent!.getAjoType().toLowerCase() + '/update', params).then((res: any) => {
                updateLoad(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, parent, true);

                    AppManager.addToast(new Toast(errorManager.get(successError), 'success'));
                    props.handleClose();
                } else {
                    updateError(Result.getError(res));
                }
            });
        } else {
            const params: any = {
                id: address!.getAjoIdentifier(),
                name: name,
                number_and_street: numberAndStreet,
                postal_code: postalCode,
                bonus: bonus,
                city: city,
            };
            post('/address/update', params).then((res: any) => {
                updateLoad(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, parent, true);

                    AppManager.addToast(new Toast(errorManager.get(successError), 'success'));
                    props.handleClose();
                } else {
                    updateError(Result.getError(res));
                }
            });
        }
    };

    const filterList = [];
    const list = stateList.get()!.getList();
    // remove multiple address from the list
    for (let i = 0; i < list.length; i++) {
        let count = 0;
        let j = i;
        while (count < 2 && j < list.length) {
            if (
                list[i].name.get() === list[j].name.get() &&
                list[i].number_and_street.get() === list[j].number_and_street.get() &&
                list[i].city.get() === list[j].city.get() &&
                list[i].postal_code.get() === list[j].postal_code.get()
            ) {
                count++;
            }
            j++;
        }
        if (count <= 1) {
            filterList.push(list[i]);
        }
    }
    const filterListFinal = [];
    for (let i = 0; i < filterList.length; i++) {
        let can = true;
        const elem = filterList[i];
        can = can && Utils.clean(elem.name.get() ?? '').startsWith(Utils.clean(name));
        can = can && Utils.clean(elem.city.get() ?? '').startsWith(Utils.clean(city));
        can = can && Utils.clean(elem.number_and_street.get() ?? '').startsWith(Utils.clean(numberAndStreet));
        can = can && Utils.clean(elem.postal_code.get() + '' ?? '').startsWith(Utils.clean(postalCode));
        if (can) {
            filterListFinal.push(elem);
        }
    }

    const selectAddress = (address: Address) => {
        updateName(address.name.get() ?? '');
        updateNumberAndStreet(address.number_and_street.get() ?? '');
        updateCity(address.city.get() ?? '');
        updatePostalCode(address.postal_code.get() ?? '');
    };

    return (
        <Modal
            onDone={onAddCategory}
            show={show}
            done={button}
            title={title}
            loading={loading}
            color={'blue'}
            error={error}
            errorManager={errorManager}
            handleClose={() => {
                handleClose();
                updateError(ErrorHTTP.NO_ERROR());
            }}
        >
            <ComplexInput typeValue="string" placeholder="Nom" value={name} setValue={updateName} />
            <ComplexInput
                typeValue="string"
                placeholder="Batiment, appartement ..."
                value={bonus}
                setValue={updateBonus}
            />
            <ComplexInput
                typeValue="string"
                placeholder="N° et nom de rue"
                value={numberAndStreet}
                setValue={updateNumberAndStreet}
            />
            <ComplexInput typeValue="string" placeholder="Code postal" value={postalCode} setValue={updatePostalCode} />
            <ComplexInput typeValue="string" placeholder="Ville" value={city} setValue={updateCity} />
            {filterListFinal.length != 0 && (
                <div className="flex flex-col gap-3 flex-wrap">
                    {filterListFinal.map((item, index) => {
                        return (
                            <div
                                key={'complite-address-' + index}
                                className="w-full"
                                onClick={() => selectAddress(item)}
                            >
                                <AddressView canDelete={false} canEdit={false} address={item as Address} />
                            </div>
                        );
                    })}
                </div>
            )}
        </Modal>
    );
};
