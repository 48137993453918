import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { AJOList, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';
import { AddIcon } from 'src/icons/AddIcon';
import { EditIcon } from 'src/icons/EditIcon';
import { SearchIcon } from 'src/icons/SearchIcon';
import { AddElemClientModal } from 'src/modal/vendor/AddElemClientModal';
import { TimerParent } from 'src/object/TimerInterface';
import AppManager from '../commun/AppManager';
import { Spinner } from '../commun/component/Spinner';
import { TextRaimbow } from '../commun/component/TextRaimbow';
import { Title } from '../commun/component/Title';
import ErrorHTTP from '../commun/ErrorHTTP';
import { get, post } from '../commun/HTTP';
import { Modal } from '../commun/modal/Modal';
import Result from '../commun/Result';
import Utils from '../commun/Utils';
import { BasePage } from '../component/BasePage';
import { HonoraireView } from '../component/HonoraireView';
import { TimerView } from '../component/TimerView';
import { AddTimerModal } from '../modal/vendor/AddTimerModal';
import { clientNotSelect, errorManager } from '../modal/vendor/CategoryErrorManager';
import { Action } from '../object/Action';
import Devis from '../object/Devis';
import Client from '../object/profile/Client';
import { Timer } from '../object/Timer';
import User from '../object/User';

export function Home() {
    const [search, setSearch] = useState('');

    const [loading, setLoading] = useState(true);
    const [stateDevisList, updateStateDevisList] = useState(new AJOState<AJOList<Devis>>(new AJOList(Devis)));
    stateDevisList.setUpdate(updateStateDevisList);

    AppManager.startUpdater();
    AppManager.updater().add(stateDevisList);

    const [loadingTimer, setLoadingTimer] = useState(true);
    const [stateTimerList, updateStateTimerList] = useState(
        new AJOState<AJOList<Devis | Action>>(new AJOList<Devis | Action>([Devis, Action])),
    );
    stateTimerList.setUpdate(updateStateTimerList);

    AppManager.updater().add(stateTimerList);

    useEffect(() => {
        get('/timer/get', {}).then((res: any) => {
            setLoadingTimer(false);
            Result.applyResult(res, stateTimerList, true);
            stateTimerList
                .get()!
                .getList()
                .sort((a, b) => {
                    let mostRecentA = a.mostRecent();
                    let mostRecentB = b.mostRecent();
                    return -mostRecentA.getTime() + mostRecentB.getTime();
                });
        });
    }, []);

    useEffect(() => {
        get('/devis/mylist', {}).then((res: any) => {
            setLoading(false);
            Result.applyResult(res, stateDevisList, true);
        });
    }, []);

    let timerList = new Array<Timer>();
    for (let elem of stateTimerList.get()!.getList()) {
        for (let timer of elem.timerList.getList()) {
            timerList.push(timer);
        }
    }

    const [tab, setTab] = useState('activity');
    const [showTimer, setShowTimer] = useState(false);
    const [showAddDevis, setShowAddDevis] = useState(false);

    let listTimerFinal = stateTimerList
        .get()!
        .getList()
        .filter((timer: Devis | Action) => {
            return Utils.isSearch(search, timer.name.get());
        });

    return (
        <BasePage className="flex-col flex items-center">
            <DashboardNavBar
                tab={tab}
                onTab={setTab}
                tabList={[
                    {
                        label: 'Activité',
                        value: 'activity',
                    },
                    {
                        label: 'Prix de vente',
                        value: 'price',
                    },
                ]}
            />
            {tab === 'activity' && (
                <Container>
                    <Box sx={{ mb: 3, mt: 3 }}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                m: -1,
                            }}
                        >
                            <Typography sx={{ m: 1 }} variant="h4">
                                Vos activités
                            </Typography>
                            <Box sx={{ m: 1 }}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className="!pl-3 gap-2"
                                    onClick={() => {
                                        setShowTimer(true);
                                    }}
                                >
                                    <AddIcon />
                                    Ajouter un élément
                                </Button>
                            </Box>
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <Card>
                                <CardContent className="">
                                    <Box className="flex  items-center flex-row">
                                        <Box sx={{ maxWidth: 500 }}>
                                            <TextField
                                                fullWidth
                                                value={search}
                                                onChange={(e) => {
                                                    setSearch((e.target as any).value);
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SvgIcon color="action" fontSize="small">
                                                                <SearchIcon />
                                                            </SvgIcon>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                placeholder="Rechercher un prix de vente"
                                                variant="outlined"
                                            />
                                        </Box>
                                        <Box className="flex-1"></Box>
                                        <Box>
                                            <Typography variant="h5">
                                                {'Semaine ' + Utils.getNumWeek(new Date())} |
                                                <TimerView
                                                    date={new Date(0)}
                                                    canJour={false}
                                                    dateEnd={
                                                        new Date(
                                                            Timer.getTimerFull(
                                                                timerList,
                                                                Utils.getMonday(new Date()),
                                                                Utils.getSunday(new Date()),
                                                            ),
                                                        )
                                                    }
                                                />
                                            </Typography>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                    {loadingTimer && (
                        <Card className="flex p-16 items-center justify-center">
                            <Spinner color="blue" />
                        </Card>
                    )}
                    {!loadingTimer && listTimerFinal.length === 0 && (
                        <div className="flex break-inside-avoid-column pb-8 items-center justify-center">
                            <Card
                                className="w-full flex flex-col"
                                sx={{
                                    p: 5,
                                    gap: 3,
                                }}
                            >
                                <p className="text-2xl font-semibold w-full text-center flex-1">Commencer à pointer</p>
                                <p className="flex-1 text-center">
                                    Votre prix de vente n'est pas sur cette page, <br></br>ajoutez-le pour commencer à
                                    pointer vos heures.
                                </p>
                                <div className={'flex items-center justify-center'}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className="!pl-3 gap-2"
                                        onClick={() => {
                                            setShowTimer(true);
                                        }}
                                    >
                                        <AddIcon />
                                        Ajouter un prix de vente
                                    </Button>
                                </div>
                            </Card>
                        </div>
                    )}
                    {!loadingTimer && listTimerFinal.length !== 0 && (
                        <div className={' w-full flex-1 columns-2xs '}>
                            {listTimerFinal.map((value, index) => {
                                return (
                                    <HonoraireView value={value} stateTimerList={stateTimerList as any} key={index} />
                                );
                            })}
                        </div>
                    )}
                    <div className="absolute">
                        {showTimer && (
                            <AddTimerModal
                                stateTimerList={stateTimerList}
                                show={showTimer}
                                onClose={() => {
                                    setShowTimer(false);
                                }}
                            />
                        )}
                    </div>
                </Container>
            )}
            {tab === 'price' && (
                <Container>
                    <Box sx={{ mb: 3, mt: 3 }}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                m: -1,
                            }}
                        >
                            <Typography sx={{ m: 1 }} variant="h4">
                                Vos prix de vente
                            </Typography>
                            <Box sx={{ m: 1 }}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className="!pl-3 gap-2"
                                    onClick={() => {
                                        setShowAddDevis(true);
                                    }}
                                >
                                    <AddIcon />
                                    Ajouter un prix de vente
                                </Button>
                            </Box>
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <Card>
                                <CardContent className="">
                                    <Box className="flex  items-center flex-row">
                                        <Box sx={{ maxWidth: 500 }}>
                                            <TextField
                                                fullWidth
                                                value={search}
                                                onChange={(e) => {
                                                    setSearch((e.target as any).value);
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SvgIcon color="action" fontSize="small">
                                                                <SearchIcon />
                                                            </SvgIcon>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                placeholder="Rechercher un prix de vente"
                                                variant="outlined"
                                            />
                                        </Box>
                                        <Box className="flex-1"></Box>
                                        <Box></Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            {!loadingTimer && stateDevisList.get()!.size() === 0 && (
                                <div className="flex break-inside-avoid-column pb-8 items-center justify-center">
                                    <Card
                                        className="w-full flex flex-col"
                                        sx={{
                                            p: 5,
                                            gap: 3,
                                        }}
                                    >
                                        <p className="text-2xl font-semibold w-full text-center flex-1">
                                            Créer votre prix de vente
                                        </p>
                                        <div className={'flex items-center justify-center'}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                className="!pl-3 gap-2"
                                                onClick={() => {
                                                    setShowAddDevis(true);
                                                }}
                                            >
                                                <AddIcon />
                                                Ajouter un prix de vente
                                            </Button>
                                        </div>
                                    </Card>
                                </div>
                            )}
                            <Box className={' w-full flex-1 columns-2xs '}>
                                {!loading &&
                                    stateDevisList
                                        .get()!
                                        .getList()
                                        .map((value, index) => {
                                            return (
                                                <Link
                                                    key={value.getAjoIdentifier()}
                                                    to={'/devis/' + value.getAjoIdentifier()}
                                                >
                                                    <Card
                                                        sx={{
                                                            p: 3,
                                                        }}
                                                        className={
                                                            'break-inside-avoid-column flex items-center flex-row gap-2 flex-wrap rounded-lg w-full mb-3 border border-' +
                                                            value.getColorSpec() +
                                                            '-200 dark:border-' +
                                                            value.getColorSpec() +
                                                            '-500 p-3 !bg-' +
                                                            value.getColorSpec() +
                                                            '-100 dark:bg-gray-800'
                                                        }
                                                    >
                                                        <Typography
                                                            variant="h6"
                                                            className={'flex-1 text-' + value.getColorSpec() + '-700 '}
                                                        >
                                                            {value.getName()}
                                                        </Typography>
                                                        <Button className="p-0.5" variant="contained">
                                                            <EditIcon />
                                                        </Button>
                                                    </Card>
                                                </Link>
                                            );
                                        })}
                            </Box>

                            {loading && (
                                <div className="flex pt-8 pb-8 items-center justify-center">
                                    <Spinner color="blue" />
                                </div>
                            )}
                        </Box>
                    </Box>
                    <div className="absolute">
                        {showAddDevis && (
                            <AddElemClientModal
                                show={showAddDevis}
                                mode="devis"
                                clientBase={null}
                                templateList={[]}
                                handleClose={() => {
                                    setShowAddDevis(false);
                                }}
                            />
                        )}
                    </div>
                </Container>
            )}
        </BasePage>
    );
}
