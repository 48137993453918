import { Box, Typography } from '@mui/material';
import { Color } from '../../commun/Constant';
import { IPerfectTableGroup, PerfectTable } from '../../commun/table/PerfectTable';
import Budget from '../../object/Budget';
import { EuroSpan } from '../../commun/component/EuroSpan';
import { PourcentSpan } from '../../commun/component/PourcentSpan';
import { Paper } from '../../object/Paper';
import BasePrice from '../../object/BasePrice';
import { PrestationSelect } from './PrestationSelect';
import { VendorSelect } from './VendorSelect';
import { QuantityInput } from './QuantityInput';
import { VaSelect } from './VaSelect';
import { DeleteSvg } from '../DeleteSvg';
import { Button } from 'src/commun/component/Button';
import { SERVER_URL } from 'src/utils/Constant';
import { SimpleLabelList } from '../label/SimpleLabelList';
import { useState } from 'preact/hooks';
import MpModal from '../extends/MpModal';
import { Spinner } from 'src/commun/component/Spinner';
import { post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import AppManager from 'src/commun/AppManager';
import Toast from 'src/commun/toast/Toast';
import { PriceInput } from './PriceInput';
import { BudgetActionModal } from './BudgetActionModal';
import { DelBudgetModal } from './DelBudgetModal';
import { BonCommandeView } from '../BonCommandeView';
import { AddressSelect } from './AddressSelect';
import { StartDeliveryInput } from './StartDeliveryInput';
import { EndDeliveryInput } from './EndDeliveryInput';
import { ContactSelect } from './ContactSelect';
import { ChefProjectSelect } from './ChefProjectSelect';
import Devis from 'src/object/Devis';
import { TvaVendorSelect } from './TaxeVendorSelect';
import { RefDevisInput } from './RefDevisInput';
import { DateDevisInput } from './DateDevisInput';
import { CommandButton, CommandButtonList } from './CommandButton';

export interface IBudgetCommandCompliteTableProps {
    list: Budget[];
    basePrice: Devis;
    paper: Paper;
    loading: boolean;
    color: Color;
}

export const BudgetCommandCompliteTable = (props: IBudgetCommandCompliteTableProps) => {
    const { loading, basePrice, paper, color, list } = props;

    const [selectBudgetList, setSelectBudgetList] = useState<Budget[]>([]);

    paper.fetch();

    const [deleteBudget, setDeleteBudget] = useState<Budget | null>(null);

    let groups: IPerfectTableGroup[] = [];
    for (const b of list) {
        const cat = b.prestation.get()?.category.get() ?? null;
        if (groups.find((e) => e.id === (cat?.getAjoIdentifier() ?? '')) === undefined) {
            const budgetList = [] as Budget[];
            for (const b of list) {
                const cbCat = b.prestation.get()?.category.get() ?? null;
                if ((cbCat?.getAjoIdentifier() ?? '') == (cat?.getAjoIdentifier() ?? '')) {
                    budgetList.push(b);
                }
            }

            groups.push({
                id: cat?.getAjoIdentifier() ?? '',
                render: () => (
                    <Box className="flex flex-col gap-3 items-center justify-center">
                        <Typography className={'p-3 !font-semibold w-full !text-white '}>
                            {cat?.name.get() ?? 'Sans catégorie'}
                        </Typography>
                    </Box>
                ),
                color: cat?.getColor() ?? 'gray',
                getList: (list: any[]) => {
                    return budgetList;
                },
            });
        }
    }
    groups.sort((a, b) => {
        if (a.id === '') {
            return -1;
        } else if (b.id === '') {
            return 1;
        } else {
            return b.id < a.id ? 1 : -1;
        }
    });
    return (
        <Box>
            <PerfectTable
                loading={loading}
                list={list}
                color={props.color ?? 'white'}
                nothing={
                    <Box className="flex flex-col gap-3 items-center justify-center">
                        <Typography>{'Aucune prestation prête à être commandée.'}</Typography>
                    </Box>
                }
                bonusTrClass={(elem: any) => {
                    return elem.focus && '!border-8 !border-blue-600';
                }}
                bonusTdClass={(elem: any) => {
                    return elem.focus && '!bg-blue-200';
                }}
                bonusRender={(elem: Budget) => {
                    return basePrice.canAct() && elem.bon_commande.get() === null ? (
                        <Box className="p-3 bg-white">
                            {elem.fullComplite() ? (
                                <CommandButton basePrice={basePrice} elem={elem} />
                            ) : (
                                <Typography>{'Compléter la ligne pour pouvoir créer le bon de commande.'}</Typography>
                            )}
                        </Box>
                    ) : (
                        <></>
                    );
                }}
                groups={groups}
                columns={[
                    {
                        name: 'Selection',

                        render: (elem: Budget) => {
                            return (
                                <Box className="flex flex-col items-center justify-center">
                                    <input
                                        type="checkbox"
                                        checked={
                                            selectBudgetList.find(
                                                (e) => e.getAjoIdentifier() === elem.getAjoIdentifier(),
                                            ) !== undefined
                                        }
                                        onChange={(e) => {
                                            if ((e?.target as any).checked) {
                                                selectBudgetList.push(elem);
                                            } else {
                                                selectBudgetList.splice(selectBudgetList.indexOf(elem), 1);
                                            }
                                            setSelectBudgetList([...selectBudgetList]);
                                        }}
                                    />
                                </Box>
                            );
                        },
                    },
                    {
                        name: 'Prestation',

                        render: (elem: Budget) => {
                            return (
                                <Box className="flex flex-col items-center justify-center">
                                    {elem.alias.get() !== undefined &&
                                        elem.alias.get() !== undefined &&
                                        elem.alias.get() !== '' && (
                                            <Typography className="mb-3">{elem.alias.get()}</Typography>
                                        )}
                                    <Typography className="mb-3">{elem.prestation.get()?.name.get()}</Typography>
                                </Box>
                            );
                        },
                    },
                    {
                        name: 'Fournisseur',

                        render: (elem: Budget) => {
                            return <Typography className="mb-3">{elem.vendor.get()?.name.get()}</Typography>;
                        },
                    },
                    {
                        name: 'Adresse du fournisseur',

                        render: (elem: Budget) => {
                            return (
                                <AddressSelect
                                    basePrice={basePrice}
                                    vendor={true}
                                    addressList={basePrice.addressList!}
                                    budget={elem}
                                />
                            );
                        },
                    },
                    {
                        name: 'Début de livraison',

                        render: (elem: Budget) => {
                            return <StartDeliveryInput basePrice={basePrice} budget={elem} />;
                        },
                    },
                    {
                        name: 'Fin de livraison',

                        render: (elem: Budget) => {
                            return <EndDeliveryInput basePrice={basePrice} budget={elem} />;
                        },
                    },
                    {
                        name: 'Contact',

                        render: (elem: Budget) => {
                            return (
                                <ContactSelect basePrice={basePrice} vendorList={basePrice.vendorList!} budget={elem} />
                            );
                        },
                    },
                    {
                        name: 'Chef de Projet',

                        render: (elem: Budget) => {
                            return (
                                <ChefProjectSelect basePrice={basePrice} userList={basePrice.userList} budget={elem} />
                            );
                        },
                    },
                    {
                        name: 'Adresse de livraison',

                        render: (elem: Budget) => {
                            return (
                                <AddressSelect
                                    basePrice={basePrice}
                                    addressList={basePrice.addressList!}
                                    budget={elem}
                                />
                            );
                        },
                    },
                    {
                        name: 'TVA (Fournisseur)',

                        render: (elem: Budget) => {
                            return (
                                <TvaVendorSelect basePrice={basePrice} budget={elem} taxeList={basePrice.taxeList!} />
                            );
                        },
                    },
                    {
                        name: 'Référence devis',

                        render: (elem: Budget) => {
                            return <RefDevisInput basePrice={basePrice} budget={elem} />;
                        },
                    },
                    {
                        name: 'Date devis',

                        render: (elem: Budget) => {
                            return <DateDevisInput basePrice={basePrice} budget={elem} />;
                        },
                    },
                ]}
            />
            <DelBudgetModal
                budget={deleteBudget}
                handleClose={() => {
                    setDeleteBudget(null);
                }}
                open={deleteBudget !== null}
                paper={paper}
            />
            {selectBudgetList.length !== 0 ? (
                <Box
                    sx={{
                        p: 3,
                    }}
                >
                    <Typography>{'Vous pouvez commander ' + selectBudgetList.length + " d'un seul coup."}</Typography>
                    <CommandButtonList basePrice={basePrice} elemList={selectBudgetList} />
                </Box>
            ) : (
                <Box
                    sx={{
                        p: 3,
                    }}
                >
                    <Typography>
                        {'Vous pouvez sélectionner plusieurs lignes pour faire une commande groupée.'}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};
