import { Title } from '../commun/component/Title';
import { BasePage } from '../component/BasePage';

export function Quotation() {
    return (
        <BasePage>
            <Title />
            <p>Quotation</p>
        </BasePage>
    );
}
