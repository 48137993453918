import { Box, Typography } from '@mui/material';
import { Color } from '../../commun/Constant';
import { IPerfectTableGroup, PerfectTable } from '../../commun/table/PerfectTable';
import Budget from '../../object/Budget';
import { EuroSpan } from '../../commun/component/EuroSpan';
import { PourcentSpan } from '../../commun/component/PourcentSpan';
import { Paper } from '../../object/Paper';
import BasePrice from '../../object/BasePrice';
import { PrestationSelect } from '../budget/PrestationSelect';
import { VendorSelect } from './VendorSelect';
import { QuantityInput } from './QuantityInput';
import { VaSelect } from './VaSelect';
import { DeleteSvg } from '../DeleteSvg';
import { Button } from 'src/commun/component/Button';
import { SERVER_URL } from 'src/utils/Constant';
import { SimpleLabelList } from '../label/SimpleLabelList';
import { useState } from 'preact/hooks';
import MpModal from '../extends/MpModal';
import { Spinner } from 'src/commun/component/Spinner';
import { post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import AppManager from 'src/commun/AppManager';
import Toast from 'src/commun/toast/Toast';
import { PriceInput } from './PriceInput';
import { BudgetActionModal } from './BudgetActionModal';
import { DelBudgetModal } from './DelBudgetModal';

export interface IBudgetStatTableProps {
    list: Budget[];
    basePrice: BasePrice;
    paper: Paper;
    loading: boolean;
    color: Color;
    option?: boolean;
}

export const BudgetFillTable = (props: IBudgetStatTableProps) => {
    const { loading, basePrice, option = false, paper, color, list } = props;

    paper.fetch();

    const [deleteBudget, setDeleteBudget] = useState<Budget | null>(null);

    let groups: IPerfectTableGroup[] = [];
    for (const b of list) {
        const cat = b.prestation.get()?.category.get() ?? null;
        if (groups.find((e) => e.id === (cat?.getAjoIdentifier() ?? '')) === undefined) {
            const budgetList = [] as Budget[];
            for (const b of list) {
                const cbCat = b.prestation.get()?.category.get() ?? null;
                if ((cbCat?.getAjoIdentifier() ?? '') == (cat?.getAjoIdentifier() ?? '')) {
                    budgetList.push(b);
                }
            }

            groups.push({
                id: cat?.getAjoIdentifier() ?? '',
                render: () => (
                    <Box className="flex flex-col gap-3 items-center justify-center">
                        <Typography className={'!font-semibold w-full !text-white '}>
                            {cat?.name.get() ?? 'Sans catégorie'}
                        </Typography>
                    </Box>
                ),
                color: cat?.getColor() ?? 'gray',
                getList: (list: any[]) => {
                    return budgetList;
                },
                specificCondition: [
                    () => {
                        let achat = 0;
                        for (const b of budgetList) {
                            achat += b.achat();
                        }
                        return (
                            <Typography className="text-white">
                                <EuroSpan value={achat} />
                            </Typography>
                        );
                    },
                    () => {
                        let prix = 0;
                        let achat = 0;
                        for (const b of budgetList) {
                            prix += b.prixVenteHT();
                            achat += b.achat();
                        }
                        return (
                            <Typography className="text-white">
                                <PourcentSpan value={(prix / achat - 1) * 100} />
                            </Typography>
                        );
                    },
                    () => {
                        return <></>;
                    },
                    () => {
                        let prix = 0;
                        for (const b of budgetList) {
                            prix += b.prixVenteHT();
                        }
                        return (
                            <Typography className="text-white">
                                <EuroSpan value={prix} />
                            </Typography>
                        );
                    },
                    () => {
                        let prixTTC = 0;
                        let prix = 0;
                        for (const b of budgetList) {
                            prixTTC += b.prixVenteTTC();
                            prix += b.prixVenteHT();
                        }
                        return (
                            <Typography className="text-white">
                                <PourcentSpan value={(prixTTC / prix - 1) * 100} />
                            </Typography>
                        );
                    },
                    () => {
                        let prix = 0;
                        for (const b of budgetList) {
                            prix += b.prixVenteTTC();
                        }
                        return (
                            <Typography className="text-white">
                                <EuroSpan value={prix} />
                            </Typography>
                        );
                    },
                ],
            });
        }
    }
    groups.sort((a, b) => {
        if (a.id === '') {
            return -1;
        } else if (b.id === '') {
            return 1;
        } else {
            return b.id < a.id ? 1 : -1;
        }
    });
    return (
        <Box>
            <PerfectTable
                loading={loading}
                list={list}
                color={props.color ?? 'white'}
                nothing={
                    <Box className="flex flex-col gap-3 items-center justify-center">
                        <Typography>{'Aucune prestation trouvée.'}</Typography>
                    </Box>
                }
                bonusTrClass={(elem: any) => {
                    return elem.focus && '!border-8 !border-blue-600';
                }}
                bonusTdClass={(elem: any) => {
                    return elem.focus && '!bg-blue-200';
                }}
                groups={groups}
                columns={[
                    {
                        name: '',

                        render: (elem: Budget) => {
                            if (paper.archive.get() === true) {
                                return <></>;
                            } else if (option) {
                                return (
                                    <Box className="flex flex-row gap-2">
                                        {basePrice.canAct() && (
                                            <Button
                                                onClick={() => {
                                                    post('/paper/update', {
                                                        id: elem.getPaper()!.getAjoIdentifier(),
                                                        budget: [
                                                            {
                                                                id_del: elem!.getAjoIdentifier(),
                                                                full_delete: true,
                                                            },
                                                        ],
                                                    }).then((res: any) => {
                                                        Result.applyResult(res, AppManager.updater(), true);
                                                    });
                                                    elem.getPaper()?.budgetList.setList(
                                                        elem
                                                            .getPaper()
                                                            ?.budgetList.getList()
                                                            .filter(
                                                                (e) => e.getAjoIdentifier() !== elem.getAjoIdentifier(),
                                                            ) ?? [],
                                                    );
                                                    AppManager.makeUpdate();
                                                }}
                                                color="red"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                    className="w-6 h-6"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </Button>
                                        )}
                                        {basePrice.canAct() && (
                                            <Button
                                                onClick={() => {
                                                    elem.option.set(false);
                                                    AppManager.makeUpdate();
                                                    elem.addEdit({
                                                        option: false,
                                                    });
                                                }}
                                                color="green"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                    className="w-6 h-6"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </Button>
                                        )}
                                    </Box>
                                );
                            } else {
                                return (
                                    <Box className="flex flex-row gap-2">
                                        {basePrice.canAct() && elem.bon_commande.get() === null ? (
                                            <Button
                                                color="red"
                                                pb={2}
                                                tabIndex={-1}
                                                pl={2}
                                                pr={2}
                                                pt={2}
                                                onClick={() => {
                                                    setDeleteBudget(elem);
                                                }}
                                            >
                                                <DeleteSvg className={'text-white h-5 w-5'} />
                                            </Button>
                                        ) : (
                                            basePrice.canAct() && (
                                                <a
                                                    href={
                                                        SERVER_URL +
                                                        '/command/' +
                                                        elem.bon_commande.get()!.code.get() +
                                                        '.pdf'
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    tabIndex={-1}
                                                >
                                                    <Button tabIndex={-1} color="blue" pb={2} pl={2} pr={2} pt={2}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-5 w-5"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </Button>
                                                </a>
                                            )
                                        )}
                                        {basePrice.canAct() && elem.bon_commande.get() === null ? (
                                            <BudgetActionModal budget={elem} />
                                        ) : (
                                            <></>
                                        )}
                                    </Box>
                                );
                            }
                        },
                    },
                    {
                        name: 'Prestation',

                        render: (elem: Budget) => {
                            return (
                                <Box className="flex flex-col items-center justify-center">
                                    {elem.alias.get() !== undefined &&
                                        elem.alias.get() !== undefined &&
                                        elem.alias.get() !== '' && (
                                            <Typography className="mb-3">{elem.alias.get()}</Typography>
                                        )}
                                    <PrestationSelect
                                        basePrice={basePrice}
                                        budget={elem}
                                        prestationList={basePrice.prestationList!}
                                    />
                                </Box>
                            );
                        },
                    },
                    {
                        name: 'Fournisseur',

                        render: (elem: Budget) => {
                            return (
                                <VendorSelect basePrice={basePrice} budget={elem} vendorList={basePrice.vendorList!} />
                            );
                        },
                    },
                    {
                        name: 'Label',

                        render: (elem: Budget) => {
                            return elem.service.get() != null ? (
                                <SimpleLabelList
                                    add={basePrice.canAct()}
                                    service={elem.service.get()!}
                                    list={elem.service.get()?.getLabelList() ?? []}
                                />
                            ) : (
                                <></>
                            );
                        },
                    },
                    {
                        name: 'Quantité',

                        render: (elem: Budget) => {
                            return <QuantityInput basePrice={basePrice} budget={elem} />;
                        },
                    },
                    {
                        name: 'PU Achat',

                        render: (elem: Budget) => {
                            return <PriceInput basePrice={basePrice} budget={elem} />;
                        },
                    },
                    {
                        name: 'Achat Total',

                        render: (elem: Budget) => {
                            return (
                                <Typography>
                                    <EuroSpan value={elem.achat()} />
                                </Typography>
                            );
                        },
                    },
                    {
                        name: 'VA',

                        render: (elem: Budget) => {
                            return <VaSelect basePrice={basePrice} budget={elem} vaList={basePrice.valeurList!} />;
                        },
                    },
                    {
                        name: 'PV Unitaire',

                        render: (elem: Budget) => {
                            return (
                                <Typography>
                                    <EuroSpan value={elem.prixVenteUnitaire()} />
                                </Typography>
                            );
                        },
                    },
                    {
                        name: 'PV HT',

                        render: (elem: Budget) => {
                            return (
                                <Box className="flex flex-row gap-2 items-center justify-center">
                                    <Typography>
                                        <EuroSpan value={elem.prixVenteHT()} />
                                    </Typography>
                                    {basePrice.canAct() && (
                                        <div className="flex flex-col gap-1">
                                            <Button
                                                className={'p-0'}
                                                pb={0.5}
                                                pt={0.5}
                                                pl={0.5}
                                                pr={0.5}
                                                color={'blue'}
                                                onClick={() => {
                                                    elem.removeDecimale(paper);
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </Button>
                                            <Button
                                                pb={0.5}
                                                pt={0.5}
                                                pl={0.5}
                                                pr={0.5}
                                                color={'blue'}
                                                onClick={() => {
                                                    elem.addDecimale(paper);
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </Button>
                                        </div>
                                    )}
                                </Box>
                            );
                        },
                    },
                    {
                        name: 'TVA',

                        render: (elem: Budget) => {
                            return (
                                <Typography>
                                    <PourcentSpan value={elem.tax()} />
                                </Typography>
                            );
                        },
                    },
                    {
                        name: 'PV TTC',

                        render: (elem: Budget) => {
                            return (
                                <Typography>
                                    <EuroSpan value={elem.prixVenteTTC()} />
                                </Typography>
                            );
                        },
                    },
                ]}
            />
            <DelBudgetModal
                budget={deleteBudget}
                handleClose={() => {
                    setDeleteBudget(null);
                }}
                open={deleteBudget !== null}
                paper={paper}
            />
        </Box>
    );
};
