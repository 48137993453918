import { AJOObject } from 'mp-js-react-auto-json-object';
import { Link } from 'react-router-dom';
import { AccordeonTable } from '../../component/AccordeonTable';
import { DownloadSvg } from '../../component/DownloadSvg';
import { DoubleDateModal } from '../../modal/vendor/DoubleDateModal';
import Budget from '../../object/Budget';
import { Button } from '../component/Button';
import { Col } from '../table/Col';
import Table from '../table/Table';
import { SERVER_URL } from '../../utils/Constant';
import { MultipleFilterModal } from '../../modal/vendor/MultipleFilterModal';

export class IconRow extends Col<any> {
    constructor(table: Table<any>) {
        super('');
        this.table = table;
        this.render = (elem: any) => {
            if (elem instanceof Budget && elem.bon_commande.get() != null) {
                return (
                    <div className="flex justify-end">
                        <a
                            href={SERVER_URL + '/command/' + elem.bon_commande.get()!.code.get() + '.pdf'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button pb={1} pt={1} pl={1} pr={1} color="blue">
                                <DownloadSvg />
                            </Button>
                        </a>
                    </div>
                );
            } else {
                return undefined;
            }
        };
        let obj = this;
        this.renderFilterModal = () => {
            return <MultipleFilterModal col={obj} />;
        };
        this.type = 'switch';
        this.senseSort = 0;
    }
}
