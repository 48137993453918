import { Button } from '@mui/material';
import { useState } from 'react';
import AppManager from 'src/commun/AppManager';
import { Spinner } from 'src/commun/component/Spinner';
import ErrorHTTP from 'src/commun/ErrorHTTP';
import { post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import Toast from 'src/commun/toast/Toast';
import { errorManager } from 'src/modal/vendor/CategoryErrorManager';
import { WeekWork } from 'src/page/BoardPage';

export interface ICloseWeekWorkButtonProps {
    weekwork: WeekWork;
    onDelete: () => void;
}

export const CloseWeekWorkButton = (props: ICloseWeekWorkButtonProps) => {
    const [loading, setLoading] = useState(false);
    if (loading) {
        return <Spinner className={'w-5 h-5'} color="blue" />;
    }
    return (
        <Button
            variant="contained"
            color="error"
            onClick={() => {
                setLoading(true);
                post('/timer/close', {
                    date: props.weekwork.start.split('T')[0],
                    id: props.weekwork.user.getAjoIdentifier(),
                })
                    .then((res) => {
                        setLoading(false);
                        if (Result.isSuccess(res)) {
                            props.onDelete();
                            AppManager.addToast(new Toast('La semaine a été fermée avec succès.', 'success'));
                        } else {
                            AppManager.addToast(new Toast(errorManager.get(Result.getError(res)), 'error'));
                        }
                    })
                    .catch((err) => {
                        setLoading(false);
                        AppManager.addToast(new Toast(errorManager.get(new ErrorHTTP('went_wrong')), 'error'));
                    });
            }}
        >
            Fermer
        </Button>
    );
};
