import { AJOObject } from 'mp-js-react-auto-json-object';
import { Link } from 'react-router-dom';
import { AccordeonTable } from '../../component/AccordeonTable';
import { AddressView } from '../../component/AddressView';
import { ContactView } from '../../component/ContactView';
import { PrestationList } from '../../component/PrestationList';
import { DoubleDateModal } from '../../modal/vendor/DoubleDateModal';
import { SelectPrestationListModal } from '../../modal/vendor/SelectPrestationListModal';
import Address from '../../object/Address';
import Contact from '../../object/Contact';
import Prestation from '../../object/profile/Prestation';
import { Col } from '../table/Col';
import Table from '../table/Table';

export interface AddressColInterface {
    AddressColInterface: boolean;
    getAddress(): Address | null;
}

export class AddressCol<Type extends AJOObject> extends Col<Type> {
    constructor(table: Table<Type>, get: (elem: Type) => AJOObject = (elem) => elem, name: string = 'Adresse') {
        super(name);
        this.table = table;
        this.render = (elem: Type) => {
            if ('AddressColInterface' in get(elem)) {
                let inter = get(elem) as unknown as AddressColInterface;
                let res;
                if (inter.getAddress() != null) {
                    res = (
                        <AddressView
                            canEdit={false}
                            canDelete={false}
                            onDelete={() => {}}
                            address={inter.getAddress()!}
                        />
                    );
                } else {
                    res = <div>Aucune adresse</div>;
                }
                return res;
            } else {
                throw new Error('AddressColInterface not found in elem');
            }
        };
        const obj = this;
        this.renderFilterModal = () => {
            return <SelectPrestationListModal col={obj} />;
        };
        this.filter = (elem: Type) => {
            if ('AddressColInterface' in get(elem)) {
                let inter = get(elem) as unknown as AddressColInterface;
                return (
                    obj.filterList.length === 0 ||
                    obj.filterList.find(
                        (e: AJOObject) => e.getAjoIdentifier() === (inter.getAddress()?.getAjoIdentifier() ?? ''),
                    ) !== undefined
                );
            } else {
                throw new Error('AddressColInterface not found in elem');
            }
        };
        this.sort = (a: Type, b: Type) => {
            if ('AddressColInterface' in get(a) && 'AddressColInterface' in get(b)) {
                let aInter = get(a) as unknown as AddressColInterface;
                let bInter = get(b) as unknown as AddressColInterface;
                return (aInter.getAddress()?.name.get() ?? '').localeCompare(bInter.getAddress()?.name.get() ?? '');
            } else {
                throw new Error('AddressColInterface not found in a or b');
            }
        };
        this.typeRender = 'info';
        this.type = 'sort';
        this.senseSort = 0;
    }
}
