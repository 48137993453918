import { Box, Card } from '@mui/material';
import { Spinner } from '../../commun/component/Spinner';
import { Timer } from '../../object/Timer';
import User from '../../object/User';
import { TimerView } from '../TimerView';

interface IRecapTimerViewProps {
    user?: User;
    timerList: Timer[];
    startDate: Date;
    endDate: Date;
    baseClass?: string;
    loading?: boolean;
}

export const RecapTimerView = (props: IRecapTimerViewProps) => {
    const { user, loading = false, timerList, startDate, endDate, baseClass = 'right-[2rem] w-full fixed' } = props;

    if (loading) {
        return (
            <Card
                sx={{
                    mt: 3,
                    mb: 3,
                    p: 8,
                }}
            >
                <Spinner color="blue" />
            </Card>
        );
    } else {
        return (
            <Card
                sx={{
                    mt: 3,
                    mb: 3,
                }}
                className="!max-h-[calc(100vh-7rem)] !overflow-y-auto"
            >
                {(user?.closedList.getList().length ?? 0) > 0 && (
                    <Box className="flex flex-row" sx={{ p: 2 }}>
                        <div className={'rounded-lg px-2 py-1 bg-red-600'}>
                            <p className="text-sm text-white font-medium">NON ÉDITABLE</p>
                        </div>
                    </Box>
                )}
                <Box className="flex flex-row" sx={{ p: 2 }}>
                    <p className=" font-semibold">Récapitulatif</p>
                    <div className="flex-1"></div>
                    {user === undefined && (
                        <TimerView
                            show={true}
                            date={new Date(0)}
                            dateEnd={new Date(Timer.getTimerFull(timerList, startDate, endDate))}
                        />
                    )}
                </Box>
                {Timer.getClientDevisList(timerList, startDate, endDate).map((client, index) => {
                    return (
                        <Box key={index} sx={{ p: 2 }} className={'flex bg-' + client.getColorSpec() + '-100 flex-col'}>
                            <div className="flex flex-row">
                                <p className={'text-' + client.getColorSpec() + '-700 font-semibold'}>
                                    {client.name.get()}
                                </p>
                                <div className="flex-1"></div>
                                <TimerView
                                    date={new Date(0)}
                                    canJour={false}
                                    dateEnd={Timer.getTimerByAny(timerList, startDate, endDate, client)}
                                />
                            </div>

                            {Timer.getSectionByAny(timerList, startDate, endDate, client).map((section, index) => {
                                return (
                                    <div key={index} className="flex flex-row">
                                        <p className={'text-' + client.getColorSpec() + '-600 font-medium'}>
                                            {section.name.get()}
                                        </p>
                                        <div className="flex-1"></div>
                                        <TimerView
                                            date={new Date(0)}
                                            canJour={false}
                                            dateEnd={Timer.getTimerByAnySection(
                                                timerList,
                                                startDate,
                                                endDate,
                                                client,
                                                section,
                                            )}
                                        />
                                    </div>
                                );
                            })}
                        </Box>
                    );
                })}
                {user && [
                    <div key={'0'} className="flex pl-3 pr-3 pt-2 pb-2 flex-row">
                        <p className={' font-semibold'}>{'Total '}</p>
                        <div className="flex-1"></div>
                        <TimerView
                            show={true}
                            canJour={false}
                            date={new Date(0)}
                            dateEnd={user.getTimerFull(startDate, endDate)}
                        />
                    </div>,
                    <div key={'1'} className="flex pl-3 pr-3 pt-2 pb-2 flex-row">
                        <p className={' font-semibold'}>{'Contrat  '}</p>
                        <div className="flex-1"></div>
                        <TimerView
                            show={true}
                            canJour={false}
                            date={new Date(0)}
                            dateEnd={new Date((user.nb_heure.get() ?? 0) * 60 * 60 * 1000)}
                        />
                    </div>,
                    <div key={'2'} className="flex pl-3 pr-3 pt-2 pb-2 flex-row">
                        <p className={' font-semibold'}>{'Recup  '}</p>
                        <div className="flex-1"></div>
                        {user.global_recup.get() === undefined ? (
                            <p>Chargement ...</p>
                        ) : (
                            <TimerView
                                date={new Date(0)}
                                dateEnd={new Date(user.global_recup.get() * 1000)}
                            ></TimerView>
                        )}
                    </div>,
                    <div key={'3'} className="flex pl-3 pr-3 pt-2 pb-2 flex-row">
                        <p className={' font-semibold'}>{'Recup posé'}</p>
                        <div className="flex-1"></div>
                        {user.global_take_recup.get() === undefined ? (
                            <p>Chargement ...</p>
                        ) : (
                            <TimerView
                                date={new Date(0)}
                                dateEnd={new Date(user.global_take_recup.get() * 1000)}
                            ></TimerView>
                        )}
                    </div>,
                    <div key={'4'} className="flex pl-3 pr-3 pt-2 pb-2 flex-row">
                        <p className={' font-semibold'}>{'Recup venir'}</p>
                        <div className="flex-1"></div>
                        {user.global_take_recup.get() === undefined || user.global_recup.get() === undefined ? (
                            <p>Chargement ...</p>
                        ) : (
                            <TimerView
                                date={new Date(0)}
                                dateEnd={new Date(user.global_recup.get() * 1000 - user.global_take_recup.get() * 1000)}
                            ></TimerView>
                        )}
                    </div>,
                ]}
            </Card>
        );
    }
};
