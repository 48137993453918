import { Box, Typography, Container, Button, Card, CardContent } from '@mui/material';
import { useState } from 'react';
import { InsertPaperModal } from 'src/modal/vendor/InsertPaperModal';
import AppManager from '../../commun/AppManager';
import { EuroSpan } from '../../commun/component/EuroSpan';
import { PourcentSpan } from '../../commun/component/PourcentSpan';
import { AddIcon } from '../../icons/AddIcon';
import { BinIcon } from '../../icons/BinIcon';
import { EditIcon } from '../../icons/EditIcon';
import { StatistiqueIcon } from '../../icons/StatistiqueIcon';
import BasePrice from '../../object/BasePrice';
import Budget from '../../object/Budget';
import { Paper } from '../../object/Paper';
import { BudgetFillTable } from '../budget/BudgetFillTable';
import { BudgetTable } from '../budget/BudgetTable';
import { PaperFillTable } from '../budget/PaperFillTable';
import PaperModal from '../budget/PaperModal';
import { PaperStatView } from '../budget/PaperStatView';

export const DevisArchiveView = (props: { basePrice: BasePrice }) => {
    const { basePrice } = props;

    const [openInsert, setOpenInsert] = useState(false);

    const paper = basePrice.getSelectedPaper() ?? new Paper();

    return (
        <Container maxWidth={false}>
            <Box sx={{ mb: 1.5, mt: 3 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: 3,
                        flexWrap: 'wrap',
                        m: -1,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        {'Feuille archivée'}
                    </Typography>
                </Box>
            </Box>

            <Box className="flex overflow-auto flex-wrap" sx={{ gap: 1.5, pt: 1.5, pb: 1.5 }}>
                {basePrice.paperList
                    .getList()
                    .filter((p: Paper) => {
                        return p.archive.get() === true;
                    })
                    .map((p: Paper, index: number) => {
                        return (
                            <PaperStatView
                                key={p.getAjoIdentifier()}
                                basePrice={basePrice}
                                selected={p.getAjoIdentifier() === paper.getAjoIdentifier()}
                                paper={p}
                            />
                        );
                    })}
                {basePrice.paperList.getList().filter((p: Paper) => {
                    return p.archive.get() === true;
                }).length === 0 && (
                    <Card>
                        <Typography className="p-5">Aucune feuille a été archivée</Typography>
                    </Card>
                )}
            </Box>
            <Box sx={{ my: 3 }}>
                <Typography variant="h4">{paper.getName()}</Typography>
            </Box>
            {paper.getAjoIdentifier() !== undefined && (
                <Box>
                    <PaperStatView paper={paper} detailed={true} basePrice={basePrice} selected={false} />
                </Box>
            )}
            {paper.getAjoIdentifier() !== undefined && (
                <Box sx={{ mt: 3, mb: 3 }}>
                    <Card>
                        <CardContent className="">
                            <Box className="flex gap-8 items-center flex-row">
                                <Box>
                                    <Typography variant="h5">
                                        {paper.func.get() === 0 ? 'Prestation' : 'Honoraire'}
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            )}
            {paper.getAjoIdentifier() !== undefined && (
                <Box className="shadow-xl">
                    <BudgetFillTable
                        basePrice={basePrice}
                        paper={paper}
                        list={paper?.stricBudgetList.getList() ?? []}
                        loading={!paper?.load}
                        color="white"
                    />
                </Box>
            )}
            {paper.getAjoIdentifier() !== undefined && (
                <Box sx={{ mt: 3, mb: 3 }}>
                    <Card>
                        <CardContent className="">
                            <Box className="flex gap-8 items-center flex-row">
                                <Box>
                                    <Typography variant="h5">Feuille jointe</Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            )}
            {paper.getAjoIdentifier() !== undefined && (
                <Card>
                    <PaperFillTable
                        basePrice={basePrice}
                        paper={paper}
                        list={paper?.paperList.getList() ?? []}
                        loading={!paper.load}
                        color="white"
                    />
                </Card>
            )}
            <Box sx={{ mb: '50vh' }}></Box>
        </Container>
    );
};
