import { Button } from '../commun/component/Button';

export interface INothingBlockProps {
    textNothing: string;
    canAdd: boolean;
    onAdd?: () => void;
}
export const NothingBlock: React.FC<INothingBlockProps> = (props) => {
    const { textNothing, canAdd, onAdd } = props;
    return (
        <div className="flex flex-col gap-3">
            <p className="text-sm text-black dark:text-white">{textNothing}</p>
            {canAdd && (
                <div>
                    <Button onClick={onAdd} pl={1.5} pr={3.5} pt={1.5} pb={1.5} color="blue">
                        <svg
                            className="text-white h-5 w-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <span className="text-sm">Ajouter</span>
                    </Button>
                </div>
            )}
        </div>
    );
};
