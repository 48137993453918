import { useEffect, useState } from 'react';
import { get } from '../commun/HTTP';
import { BasePage } from '../component/BasePage';
import { SearchInput } from '../commun/component/SearchInput';
import AppManager from '../commun/AppManager';
import { AJOList, AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import User from '../object/User';
import Result from '../commun/Result';
import { Spinner } from '../commun/component/Spinner';
import Utils from '../commun/Utils';
import Table from '../commun/table/Table';
import { Col } from '../commun/table/Col';
import { TableView } from '../commun/table/TableView';
import { CheckBox } from '../commun/component/CheckBox';
import { Modal } from '../commun/modal/Modal';
import { ComplexInput } from '../commun/component/ComplexInput';
import { EditUserModal } from '../modal/EditUserModal';
import { DeleteUserModal } from '../modal/DeleteUserModal';
import Permission from '../object/Permission';
import { AddUserModal } from '../modal/AddUserModal';
import { ReinitPasswordModal } from '../modal/ReinitPasswordModal';
import ActionManager from '../commun/action/ActionManager';
import MakeAction from '../commun/action/Action';
import Role from '../object/Role';
import { BonCommande } from '../object/BonCommande';
import { SERVER_URL } from '../utils/Constant';
import { FlexBonCommandeModal } from '../modal/flex_bon_command/FlexBonCommandModal';
import { FlexBonCommande } from '../object/FlexBonCommande';
import { Link } from 'react-router-dom';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';
import { Box, Button, Card, CardContent, InputAdornment, SvgIcon, TextField, Typography } from '@mui/material';
import { SearchIcon } from 'src/icons/SearchIcon';
import { BonCommandTable } from 'src/component/bon_command/BonCommandTable';

export function BDDCommandPage() {
    const [loading, updateLoading] = useState(true);
    const [year, setYear] = useState(new Date().getFullYear());
    //const [search, updateSearch] = useState('');

    const [stateList, updateStateList] = useState(
        new AJOState<AJOList<BonCommande>>(new AJOList<BonCommande>(BonCommande)),
    );
    stateList.setUpdate(updateStateList);

    AppManager.startUpdater();
    AppManager.updater().add(stateList);

    useEffect(() => {
        get('/command/get', {
            search: '',
        }).then((res: any) => {
            if (Result.isSuccess(res)) {
                updateLoading(false);

                stateList.get()!.reset();

                Result.applyResult(res, stateList, true);
            }
        });
    }, []);

    const [search, setSearch] = useState('');

    const yearList = [];
    let begin = 2022;
    while (begin <= new Date().getFullYear()) {
        yearList.push(begin);
        begin++;
    }

    return (
        <BasePage className="h-full items-start">
            <Box sx={{ mb: 3, mx: 3 }} className="flex-col">
                <DashboardNavBar />
                <Box sx={{ mb: 3, mt: 3 }}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            m: -1,
                        }}
                    >
                        <Typography sx={{ m: 1 }} variant="h4">
                            Bon de commande
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Card>
                            <CardContent className="">
                                <Box className="flex  items-center flex-row">
                                    <Box sx={{ maxWidth: 700 }}>
                                        <TextField
                                            fullWidth
                                            value={search}
                                            onChange={(e) => {
                                                setSearch((e.target as any).value);
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SvgIcon color="action" fontSize="small">
                                                            <SearchIcon />
                                                        </SvgIcon>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            placeholder="Rechercher un bon de commande"
                                            variant="outlined"
                                        />
                                    </Box>
                                    <Box className="flex-1"></Box>
                                    <Box></Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
                <Box className="flex flex-row flex-wrap" sx={{ mb: 3, gap: 3 }}>
                    {yearList.reverse().map((ccYear) => {
                        return (
                            <Button
                                variant={year == ccYear ? 'contained' : 'outlined'}
                                onClick={() => {
                                    setYear(ccYear);
                                }}
                            >
                                {ccYear}
                            </Button>
                        );
                    })}
                </Box>
                <Card>
                    <BonCommandTable
                        loading={loading}
                        list={stateList
                            .get()!
                            .getList()
                            .filter((bc: BonCommande) => {
                                let date = new Date(bc.dateCreate.get());
                                return date.getFullYear() == year;
                            })}
                        search={search}
                    />
                </Card>
            </Box>
        </BasePage>
    );
}
