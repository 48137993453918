import { AJOList, AJOObject, AJOUpdater } from 'mp-js-react-auto-json-object';
import React from 'react';
import AppManager from '../../commun/AppManager';
import { ComplexInput } from '../../commun/component/ComplexInput';
import { Color } from '../../commun/Constant';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { get, post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import Utils from '../../commun/Utils';
import { JSXInternal } from 'preact/src/jsx';
import { VirgineListComplexSelect } from '../../component/VirgineListComplexSelect';
import CategoryEvent from '../../object/tag/CategoryEvent';
import Product from '../../object/tag/Product';
import { ActionModal, IActionModalProps, IActionModalState } from '../base/ActionModal';
import { agenceAddSuccess, agenceEditSuccess, errorManager } from '../vendor/CategoryErrorManager';

export interface IProductModalProps extends IActionModalProps<Product> {
    parent?: AJOObject;
    action: 'add' | 'edit';
}

export interface IProductModalState extends IActionModalState {
    name: string;
    min: string;
    max: string;

    categoryEventList: AJOList<CategoryEvent>;

    searchCategory: string;
    idCategory: string;
}

export class ProductModal extends ActionModal<Product, IProductModalProps, IProductModalState> {
    constructor(props: IProductModalProps) {
        /**
         * DEFAULT PROPS
         */
        super(props, agenceAddSuccess, agenceEditSuccess);

        this.state = this.initState();
    }

    getSuccess() {
        const { action } = this.props;
        let res: ErrorHTTP;
        if (action === 'add') {
            res = agenceAddSuccess;
        } else {
            res = agenceEditSuccess;
        }
        return res;
    }

    initState(): IProductModalState {
        const { action, elem, baseName } = this.props;
        let res: IProductModalState;
        if (action === 'add') {
            res = {
                name: baseName ?? '',
                min: '',
                max: '',
                error: ErrorHTTP.NO_ERROR(),
                loading: false,
                searchCategory: '',
                idCategory: '',
                categoryEventList: new AJOList<CategoryEvent>(CategoryEvent),
            };
        } else {
            res = {
                name: elem?.name.get() ?? '',
                min: elem?.min.get() ?? '',
                max: elem?.max.get() ?? '',
                error: ErrorHTTP.NO_ERROR(),
                loading: false,
                searchCategory: elem?.category.get()?.name.get() ?? '',
                idCategory: elem?.category.get()?.getAjoIdentifier() ?? '',
                categoryEventList: new AJOList<CategoryEvent>(CategoryEvent),
            };
        }
        return res;
    }

    getUrl(): string {
        const { parent } = this.props;
        let res: string;
        if (parent === undefined) {
            res = '/product/update';
        } else {
            res = '/' + parent.getAjoType().toLowerCase() + '/update';
        }
        return res;
    }

    getSendParams(): { [key: string]: any } {
        const { parent, action } = this.props;
        let params: { [key: string]: any } = {};

        if (parent === undefined) {
            params = { ...this.state };
            params.min = parseFloat(params.min);
            params.max = parseFloat(params.max);
            params.category = {
                id: this.state.idCategory,
            };
            delete params.error;
        } else {
            params = {
                id: parent.getAjoIdentifier(),
                product: { ...this.state },
            };
            params.product.min = parseFloat(params.min);
            params.product.max = parseFloat(params.max);
            delete params.product.error;
            params.product.category = {
                id: this.state.idCategory,
            };
        }
        delete params.categoryEventList;
        delete params.searchCategory;
        delete params.loading;
        if (action === 'edit') {
            params['id'] = this.props.elem!.getAjoIdentifier();
        }
        return params;
    }

    componentDidUpdate(
        prevProps: Readonly<IProductModalProps>,
        prevState: Readonly<IProductModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.show !== this.props.show) {
            this.setState(this.initState());
            get('/category_event/list', {}).then((res: any) => {
                Result.applyResult(res, this.state.categoryEventList, true);
            });
            if (this.props.show) {
                Utils.blur();
            }
        }
    }

    render(): JSXInternal.Element {
        return super.render();
    }

    contentModal(): JSXInternal.Element | JSXInternal.Element[] {
        const { name, min, max, categoryEventList, searchCategory: searchCategory, idCategory } = this.state;
        let obj = this;
        return [
            <ComplexInput
                key="0"
                typeValue="string"
                setValue={(name) => {
                    this.setState({ name: name });
                }}
                value={name}
                placeholder={'Nom'}
            />,
            <VirgineListComplexSelect
                key="1"
                ajoList={categoryEventList}
                field="category"
                search={searchCategory}
                setSearch={(search: string) => {
                    this.setState({ searchCategory: search });
                }}
                focusInput={false}
                onSelectId={(id) => {
                    this.setState({ idCategory: id });
                }}
                onSelectName={() => {}}
                canAdd={false}
                name=""
                id={idCategory}
                placeholder="Catégorie"
            />,
            <ComplexInput
                key="2"
                typeValue="pourcent"
                setValue={(pourcent) => {
                    this.setState({ min: pourcent });
                }}
                value={min}
                placeholder={'Marge minimum'}
            />,
            <ComplexInput
                key="3"
                typeValue="pourcent"
                setValue={(pourcent) => {
                    this.setState({ max: pourcent });
                }}
                value={max}
                placeholder={'Marge maximum'}
            />,
        ];
    }

    getTitle(): string {
        const { action } = this.props;
        let res: string;
        if (action === 'add') {
            res = 'Ajouter une offre';
        } else {
            res = 'Modifier une offre';
        }
        return res;
    }

    getDone(): string {
        const { action } = this.props;
        let res: string;
        if (action === 'add') {
            res = 'Ajouter';
        } else {
            res = 'Modifier';
        }
        return res;
    }
    public createGeneric() {
        return Product;
    }
}
