import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { EuroSpan } from '../../commun/component/EuroSpan';
import { PourcentSpan } from '../../commun/component/PourcentSpan';
import { Color } from '../../commun/Constant';
import { PerfectTable } from '../../commun/table/PerfectTable';
import Utils from '../../commun/Utils';
import { ArrowDownCircle } from '../../icons/ArrowDownCircle';
import { JSXInternal } from 'preact/src/jsx';
import { ArrowRightCircle } from '../../icons/ArrowRightCircle';
import Service from '../../object/Service';
import Category from '../../object/tag/Category';
import Label from '../../object/tag/Label';
import { BudgetStatTable } from '../budget/BudgetStatTable';
import { SimpleCategoryList } from '../category/SimpleCategoryList';
import { SimpleLabelList } from '../label/SimpleLabelList';
import { NoteView } from '../note/NoteView';
import { NoteList } from '../NoteList';
import Budget from 'src/object/Budget';

export interface IServiceStatTableProps {
    list: Service[];
    loading: boolean;
    color?: Color;
}

export const ServiceStatTable = (props: IServiceStatTableProps) => {
    const { list, loading } = props;

    const [categoryList, setCategoryList] = useState([] as Category[]);
    const [labelList, setLabelList] = useState([] as Label[]);

    const [categoryShow, setCategoryShow] = useState(false);
    const [labelShow, setLabelShow] = useState(false);

    const [search, setSearch] = useState('');
    const [openElement, setOpenElement] = useState<Service | null>(null);

    return (
        <PerfectTable
            loading={loading}
            list={list}
            color={props.color ?? 'white'}
            nothing={
                <Box className="flex flex-col gap-3 items-center justify-center">
                    <Typography>{'Aucune prestation trouvée.'}</Typography>
                </Box>
            }
            columns={[
                {
                    canSort: true,
                    sort: 1,
                    funSort: (e1: Service, e2: Service) => {
                        return e1.prestation.get()!.name.get().localeCompare(e2.prestation.get()!.name.get());
                    },

                    name: 'Nom',

                    hasFilter: true,
                    funFilter: (service: Service) => {
                        const finalSearch = Utils.clean(search.toLowerCase());
                        let can =
                            Utils.clean(service.prestation.get()!.name.get()).toLowerCase().includes(finalSearch) ||
                            search.length === 0;
                        return can;
                    },
                    render: (elem: Service) => {
                        return (
                            <Box className="font-semibold flex flex-row items-center gap-1">
                                {openElement?.getAjoIdentifier() !== elem.getAjoIdentifier() ? (
                                    <ArrowRightCircle
                                        onClick={() => {
                                            setOpenElement(elem);
                                        }}
                                    />
                                ) : (
                                    <ArrowDownCircle
                                        onClick={() => {
                                            setOpenElement(null);
                                        }}
                                    />
                                )}
                                {elem.prestation.get()!.name.get()}
                            </Box>
                        );
                    },
                },
                {
                    name: 'Note',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Service, e2: Service) => {
                        if (isNaN(e1.getAverageNote())) {
                            return 1;
                        }
                        if (isNaN(e2.getAverageNote())) {
                            return -1;
                        }
                        return -e1.getAverageNote() + e2.getAverageNote();
                    },

                    render: (elem: Service) => {
                        return (
                            <Typography>
                                <NoteView note={elem.getAverageNote()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'Nb prestation',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Service, e2: Service) => {
                        return -e1.budgetList.getList().length + e2.budgetList.getList().length;
                    },

                    render: (elem: Service) => {
                        return <Typography>{elem.budgetList.getList().length}</Typography>;
                    },
                },
                {
                    name: 'Quantité',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Service, e2: Service) => {
                        return -e1.quantite() + e2.quantite();
                    },

                    render: (elem: Service) => {
                        return <Typography>{elem.quantite()}</Typography>;
                    },
                },
                {
                    name: 'Achat total',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Service, e2: Service) => {
                        return -e1.achat() + e2.achat();
                    },

                    render: (elem: Service) => {
                        return (
                            <Typography>
                                <EuroSpan value={elem.achat()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'VA',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Service, e2: Service) => {
                        return -e1.va() + e2.va();
                    },

                    render: (elem: Service) => {
                        return (
                            <Typography>
                                <PourcentSpan value={elem.va()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'PV HT',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Service, e2: Service) => {
                        return -e1.prixVenteHT() + e2.prixVenteHT();
                    },

                    render: (elem: Service) => {
                        return (
                            <Typography>
                                <EuroSpan value={elem.prixVenteHT()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'TVA',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Service, e2: Service) => {
                        return -e1.tax() + e2.tax();
                    },

                    render: (elem: Service) => {
                        return (
                            <Typography>
                                <PourcentSpan value={elem.tax()} />
                            </Typography>
                        );
                    },
                },
                {
                    name: 'PV TTC',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Service, e2: Service) => {
                        return -e1.prixVenteTTC() + e2.prixVenteTTC();
                    },

                    render: (elem: Service) => {
                        return (
                            <Typography>
                                <EuroSpan value={elem.prixVenteTTC()} />
                            </Typography>
                        );
                    },
                },
            ]}
            bonusRender={(elem: Service) => {
                let res: JSXInternal.Element = <></>;
                if (elem.getAjoIdentifier() === openElement?.getAjoIdentifier()) {
                    elem.fetchClientListInfo();
                    res = (
                        <BudgetStatTable
                            color="blue"
                            loading={elem.serviceClientListLoading}
                            list={elem.budgetList.getList() as Budget[]}
                        />
                    );
                }
                return res;
            }}
        />
    );
};
