import { useState } from 'react';
import { CaretDownFill, CaretRightFill } from 'react-bootstrap-icons';

export interface IActivityPointerViewProps {}

export let addActivityView = (id: number, params: any) => {};
export let removeActivityView = (id: number, actionNew: '1' | '2', response: any) => {};

export const ActivityPointerView = () => {
    const [open, setOpen] = useState(true);

    const [actionList, setActionList] = useState<
        {
            id: number;
            action: '0' | '1' | '2';
            response: any;
            params: any;
            time: number;
        }[]
    >([]);
    addActivityView = (id: number, params: any) => {
        setActionList([
            ...actionList,
            {
                id: id,
                action: '0',
                response: null,
                params: params,
                time: Date.now(),
            },
        ]);
    };
    removeActivityView = (id: number, actionNew: '1' | '2', response: any) => {
        let action = actionList.find((action) => action.id === id);
        if (action) {
            action.action = actionNew;
            action.response = response;
            setActionList([...actionList]);
        }
    };
    return (
        <div className="fixed bottom-0 right-0 w-96 px-4 py-4 bg-slate-100 rounded-lg border border-slate-500 mb-4  z-[5555] mr-4 flex flex-col gap-2">
            <div className={'flex flex-row justify-between items-center'}>
                <h1>Journal d'activité</h1>
                <div>
                    <button
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        {open ? <CaretDownFill /> : <CaretRightFill />}
                    </button>
                </div>
            </div>
            {open && (
                <div className={'border-t p-2 flex flex-col-reverse h-48 overflow-y-auto gap-2 border-slate-500'}>
                    {actionList
                        .sort((a, b) => {
                            return a.time - b.time;
                        })
                        .reverse()
                        .map((value, index) => {
                            let bg = '';
                            let text = '';
                            let responseList = null;
                            if (value.action === '0') {
                                bg = 'bg-amber-100';
                                text = 'Votre demande est en cours de traitement.';
                                responseList = null;
                            } else if (value.action === '1') {
                                bg = 'bg-green-100';
                                text = 'Votre demande a été traité avec succès.';
                                const paper = value.response.pathAct1[0].on_paper[0];
                                let messageListStr = [];
                                let i = 0;
                                let notFound = false;
                                while (!notFound) {
                                    if (paper['message' + i]) {
                                        messageListStr.push(paper['message' + i]);
                                    } else {
                                        notFound = true;
                                    }
                                    i += 1;
                                }
                                responseList = messageListStr.map((value, index) => {
                                    return (
                                        <p className={'text-green-800 text-sm'} key={index}>
                                            {value}
                                        </p>
                                    );
                                });
                            } else {
                                bg = 'bg-red-100';
                                text = 'Votre demande a échoué.';
                                responseList = null;
                            }
                            return (
                                <div className={' p-2 rounded-lg ' + bg}>
                                    <p className={'text-sm'}>{text}</p>
                                    {responseList}
                                </div>
                            );
                        })}
                    <p className={'text-sm'}>Vous trouvez ici tout vos actions sur le budget de ce prix de vente.</p>
                </div>
            )}
        </div>
    );
};
