import { useState } from 'react';
import AppManager from 'src/commun/AppManager';
import BasePrice from 'src/object/BasePrice';
import { post } from '../../commun/HTTP';
import Budget from '../../object/Budget';

export interface IQuantityProps {
    budget: Budget;
    basePrice: BasePrice;
}

export const QuantityInput = (props: IQuantityProps) => {
    const { budget, basePrice } = props;

    const [val, setVal] = useState(budget.quantity.get());

    return (
        <input
            disabled={budget.bon_commande.get() !== null || !basePrice.canAct()}
            type={'number'}
            value={val + ''}
            onBlur={() => {
                budget.leaveFocus();
            }}
            onFocus={() => {
                budget.getFocus();
                budget.addEdit({
                    quantity: isNaN(budget.quantity.get()) ? '' : budget.quantity.get(),
                });
            }}
            onChange={(e) => {
                let newVal = parseInt((e.target as any).value);
                budget.quantity.set(newVal);
                budget.addEdit({
                    quantity: isNaN(budget.quantity.get()) ? '' : budget.quantity.get(),
                });
                setVal(newVal);
                AppManager.makeUpdate();
            }}
            placeholder="Quantité"
            className="bg-gray-300 w-32 placeholder:text-gray-600 border-gray-400 border rounded-lg py-2 px-3 focus:!border focus:!ring focus:!ring-blue-500 focus:!border-blue-500"
        />
    );
};
