import { tab } from '@testing-library/user-event/dist/tab';
import { AJOObject } from 'mp-js-react-auto-json-object';
import { JSXInternal } from 'preact/src/jsx';
import React, { useEffect } from 'react';
import { CaretDownFill, CaretRight, CaretRightFill, CaretUpFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { DeleteSvg } from '../../component/DeleteSvg';
import { FilterSvg } from '../../component/FilterSvg';
import { LittleAddButton } from '../../component/LittleAddButton';
import { TableRowNextInterface } from '../../object/interface/TableRowNextInterface';
import { TagObject } from '../../object/tag/TagObject';
import { Button } from '../component/Button';
import { CheckBox } from '../component/CheckBox';
import { ErrorText } from '../component/ErrorText';
import { EuroSpan } from '../component/EuroSpan';
import { Spinner } from '../component/Spinner';
import { COLOR_LIST } from '../Constant';
import ErrorHTTP from '../ErrorHTTP';
import { Col } from './Col';
import { Row } from './Row';
import { SectionTBody } from './SectionTBody';
import { SectionTr } from './SectionTr';
import Table from './Table';

export interface ITableViewProps extends JSXInternal.HTMLAttributes<HTMLDivElement> {
    table: Table<any>;
    rounded?: boolean;
    selectedList?: string[];
    updateSelectedList?: (selectList: string[]) => void;
}
export const TableView: React.FunctionComponent<ITableViewProps> = (props) => {
    const { table, rounded = false, selectedList = [], updateSelectedList = () => {}, className, ...rest } = props;

    let same = true;
    let i = 0;
    while (same && i < table.list.length) {
        const id = table.list[i].getAjoIdentifier();
        if (selectedList.indexOf(id) === -1) {
            same = false;
        }
        i += 1;
    }

    const fullCheck = () => {
        if (same) {
            updateSelectedList([]);
        } else {
            const array = [];
            for (let i = 0; i < table.list.length; i++) {
                array.push(table.list[i].getAjoIdentifier());
            }
            updateSelectedList([...array]);
        }
    };

    // Apply all filter to the list
    let list: any[] = table.list;

    i = 0;
    while (i < table.colList.length) {
        const col = table.colList[i];
        if (col.hasFilter()) {
            list = list.filter(col.filter);
        }
        i += 1;
    }
    list = list.filter(table.bonusFilter);

    let sort = false;
    i = 0;
    while (!sort && i < table.colList.length) {
        if (table.colList[i].senseSort !== 0) {
            list.sort(table.colList[i].sort);
            if (table.colList[i].senseSort === -1) {
                list.reverse();
            }
        }
        i += 1;
    }

    const isLast = (list: any[], index: number) => {
        return index === list.length - 1;
    };
    //border-b border-gray-200 dark:border-slate-500
    const bgTop = (index: number) => {
        index = index - 1;
        let res = '';
        res = ' bg-gray-100 dark:bg-gray-700 ' + ' dark:border-slate-500 ';
        if (table.bg !== undefined) {
            res = 'bg-' + table.bg + '-300 dark:bg-' + table.bg + '-700';
        }
        return res + ' border-b-4 border-gray-500 dark:border-slate-400 ';
    };
    const getBgRow = (tag: TagObject | null, elem: any) => {
        let res = '';
        if (!table.backgoroundRow) {
        } else {
            res = 'bg-white dark:bg-gray-800';
        }
        if (tag == null) {
            if (table.taggedTable) {
                if (table.isStrong(elem)) {
                    res = 'bg-slate-200 dark:bg-slate-800';
                } else {
                    res = 'bg-slate-100 dark:bg-slate-900';
                }
            } else {
                if (table.isStrong(elem)) {
                    res = 'bg-' + table.bg + '-200 dark:bg-' + table.bg + '-800';
                } else {
                    res = 'bg-' + table.bg + '-100 dark:bg-' + table.bg + '-900';
                }
            }
        } else {
            if (table.isStrong(elem)) {
                res = 'bg-' + tag.getColor() + '-300 dark:bg-' + tag.getColor() + '-700';
            } else {
                res = 'bg-' + tag.getColor() + '-100 dark:bg-' + tag.getColor() + '-900';
            }
        }
        return res;
    };

    let classNameFinal = className;
    if (table.size === 'sm') {
        classNameFinal += 'text-sm';
    } else if (table.size === 'md') {
        classNameFinal += 'text-base';
    }
    if (table.backgoroundRow) {
        classNameFinal += ' bg-white dark:bg-slate-900 ';
    }
    let count = 0;

    const getListCat = (cate: TagObject, list: any[]): any[] => {
        let finalList: any[] = [];
        for (let i = 0; i < list.length; i++) {
            if (table.filterTag(list[i], cate) && !table.outOfTag(list[i])) {
                finalList.push(list[i]);
            }
        }
        return finalList.sort(table.bonusSort);
    };
    const getInNothing = (cateList: TagObject[], list: any[]): any[] => {
        let finalList: any[] = [];
        for (let i = 0; i < list.length; i++) {
            let can = true;
            for (const cate of cateList) {
                if (table.filterTag(list[i], cate)) {
                    can = false;
                }
            }
            if (can && !table.outOfTag(list[i])) {
                finalList.push(list[i]);
            }
        }
        return finalList.sort(table.bonusSort);
    };

    const getOutTag = (list: any[]): any[] => {
        let finalList: any[] = [];
        for (let i = 0; i < list.length; i++) {
            if (table.outOfTag(list[i])) {
                finalList.push(list[i]);
            }
        }
        return finalList.sort(table.bonusSort);
    };

    const renderElem = (
        tag: TagObject | null,
        elem: any,
        list: any[],
        indexElem: number,
        bonusFunc: (e: any, list: any[], i: number) => void = () => {},
    ) => {
        bonusFunc(elem, list, i);
        return [
            <Row key="0" elem={elem} table={table} i={indexElem} bg={getBgRow(tag, elem)} />,
            table.subTableRender(elem) && (
                <tr
                    className={
                        (!isLast(list, indexElem) && '  border-b-4 ') +
                        ' dark:text-white border-t-2 border-gray-500 dark:border-slate-400 ' +
                        ' ' +
                        table.lineStyle(elem)
                    }
                    key={table.key + '2-' + indexElem}
                >
                    <td className=" p-0 " colSpan={table.numCol()}>
                        {table.subTableRender(elem)}
                    </td>
                </tr>
            ),
            table.subRender(elem) && (
                <tr
                    className={
                        ' dark:text-white ' +
                        getBgRow(tag, elem) +
                        ' ' +
                        'border-b-2 border-gray-500 dark:border-slate-400 ' +
                        table.lineStyle(elem) +
                        ' ' +
                        table.bgFunc(elem)
                    }
                    key={table.key + '2-' + indexElem}
                >
                    <td className=" p-0 " colSpan={table.numCol()}>
                        {table.subRender(elem)}
                    </td>
                </tr>
            ),
        ];
    };

    let listTag = [...table.listTag];
    listTag.sort((a: TagObject, b: TagObject) => {
        if (a.pos.get() === undefined && b.pos.get() === undefined) {
            return a.name.get().localeCompare(b.name.get());
        } else {
            return a.pos.get() - b.pos.get();
        }
    });

    return (
        <div
            className={
                (table.bg !== undefined ? 'bg-' + table.bg + '-100 dark:bg-' + table.bg + '-900' : '') +
                '  w-full ' +
                ' dark:border-slate-500 ' +
                classNameFinal +
                ' '
            }
        >
            {table.loading ? (
                <div className=" flex items-center justify-center p-24">
                    <Spinner color="blue"></Spinner>
                </div>
            ) : (
                <table {...rest} className="w-full border-collapse table-auto">
                    <thead className="sticky z-[45] top-0">
                        <tr className={'h-3 w-full bg-slate-200'}>
                            <td colSpan={table.colList.length}></td>
                        </tr>
                        <tr className={' text-left dark:text-white'}>
                            {table.colList.map((row, index) => {
                                let res;
                                if (row.isVisible() && row.superRender() === undefined) {
                                    count++;
                                    if (row.type === 'add') {
                                        res = (
                                            <th key={table.key + '-row-' + index} className={bgTop(count) + ' p-2'}>
                                                {
                                                    <div className="flex justify-end gap-2">
                                                        <LittleAddButton onAdd={() => row.act && row.act()} text="" />
                                                    </div>
                                                }
                                            </th>
                                        );
                                    } else if (row.type === 'switch') {
                                        res = (
                                            <th key={table.key + '-row-' + index} className={bgTop(count) + ' p-2'}>
                                                {
                                                    <div className="flex justify-end gap-2">
                                                        {
                                                            /*table.type === "stat" &&
                                                    <Button onClick={()=>row.openModal()} color='blue' pb={1.5} pl={1.5} pt={1.5} pr={1.5}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                            <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
                                                        </svg>
                                                    </Button>
                                                    /*/ undefined
                                                        }
                                                        {
                                                            /*table.hasGlobalFilter()&&<Button onClick={()=>row.resetFilter()} color='red' pb={1.5} pl={1.5} pt={1.5} pr={1.5}>
                                                        <DeleteSvg className='text-white h-5 w-5'></DeleteSvg>
                                                    </Button>*/ undefined
                                                        }
                                                        {table.type === 'info' ? (
                                                            <Button
                                                                onClick={() => table.passToStat()}
                                                                color="blue"
                                                                pb={1.5}
                                                                pl={1.5}
                                                                pt={1.5}
                                                                pr={1.5}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-5 w-5"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                >
                                                                    <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
                                                                </svg>
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                onClick={() => table.passToInfo()}
                                                                color="blue"
                                                                pb={1.5}
                                                                pl={1.5}
                                                                pt={1.5}
                                                                pr={1.5}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-5 w-5"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                                        clipRule="evenodd"
                                                                    />
                                                                </svg>
                                                            </Button>
                                                        )}
                                                    </div>
                                                }
                                            </th>
                                        );
                                    } else if (row.type === 'sort_filter') {
                                        res = (
                                            <th key={table.key + '-row-' + index} className={bgTop(count) + ' p-2'}>
                                                <div className="flex items-center gap-2">
                                                    <Button
                                                        onClick={() => row.openModal()}
                                                        color="blue"
                                                        pb={1.5}
                                                        pl={1.5}
                                                        pt={1.5}
                                                        pr={1.5}
                                                    >
                                                        <FilterSvg className="text-white h-5 w-5"></FilterSvg>
                                                    </Button>
                                                    <div
                                                        onClick={() => row.nextSenseSort()}
                                                        className="flex items-center gap-2"
                                                    >
                                                        {row.getSenseSortJsx()}
                                                        {row.name}
                                                    </div>
                                                    {row.hasFilter() && (
                                                        <Button
                                                            onClick={() => row.resetFilter()}
                                                            color="red"
                                                            pb={1.5}
                                                            pl={1.5}
                                                            pt={1.5}
                                                            pr={1.5}
                                                        >
                                                            <DeleteSvg className="text-white h-5 w-5"></DeleteSvg>
                                                        </Button>
                                                    )}
                                                    {row.showFilterModal && row.renderFilterModal()}
                                                </div>
                                            </th>
                                        );
                                    } else if (row.type === 'filter') {
                                        res = (
                                            <th key={table.key + '-row-' + index} className={bgTop(count) + ' p-2'}>
                                                <div className="flex items-center gap-2">
                                                    <Button
                                                        onClick={() => row.openModal()}
                                                        color="blue"
                                                        pb={1.5}
                                                        pl={1.5}
                                                        pt={1.5}
                                                        pr={1.5}
                                                    >
                                                        <FilterSvg className="text-white h-5 w-5"></FilterSvg>
                                                    </Button>
                                                    {row.name}
                                                    {row.hasFilter() && (
                                                        <Button
                                                            onClick={() => row.resetFilter()}
                                                            color="red"
                                                            pb={1.5}
                                                            pl={1.5}
                                                            pt={1.5}
                                                            pr={1.5}
                                                        >
                                                            <DeleteSvg className="text-white h-5 w-5"></DeleteSvg>
                                                        </Button>
                                                    )}
                                                    {row.showFilterModal && row.renderFilterModal()}
                                                </div>
                                            </th>
                                        );
                                    } else if (row.type === 'sort') {
                                        res = (
                                            <th key={table.key + '-row-' + index} className={bgTop(count) + ' p-2'}>
                                                <div
                                                    onClick={() => row.nextSenseSort()}
                                                    className="flex items-center gap-2"
                                                >
                                                    {row.getSenseSortJsx()}
                                                    {row.name}
                                                </div>
                                            </th>
                                        );
                                    } else if (row.type === 'string') {
                                        res = (
                                            <th key={table.key + '-row-' + index} className={bgTop(count) + ' p-2'}>
                                                {row.name}
                                            </th>
                                        );
                                    } else {
                                        res = (
                                            <th key={table.key + '-row-' + index} className={bgTop(count) + ' p-2'}>
                                                <CheckBox
                                                    id={'table-' + table.key + '-full-check'}
                                                    name={'table-' + table.key + '-full-check'}
                                                    checked={same}
                                                    onChange={fullCheck}
                                                />
                                            </th>
                                        );
                                    }
                                } else if (row.isVisible() && row.superRender() !== undefined) {
                                    count++;
                                    res = (
                                        <th key={table.key + '-row-' + index} className={bgTop(count) + ' p-2'}>
                                            {row.superRender()}
                                        </th>
                                    );
                                }
                                return res;
                            })}
                        </tr>
                        <tr className={'h-1.5 w-full bg-slate-500'}>
                            <td colSpan={table.colList.length}></td>
                        </tr>
                    </thead>
                    {table.showTopRow && table.loadTopRow && (
                        <tbody key={table.key + '-top-loading'}>
                            <tr>
                                <td
                                    className={
                                        'bg-white dark:bg-gray-800' + ' border-b border-gray-200 dark:border-slate-500'
                                    }
                                    colSpan={table.numCol()}
                                >
                                    <div className="flex justify-center p-5 items-center">
                                        <Spinner color="blue" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    )}
                    {table.showTopRow && !table.errorTopRow.equals(ErrorHTTP.NO_ERROR()) && (
                        <tbody key={table.key + '-top-loading'}>
                            <tr>
                                <td
                                    className={
                                        'bg-white dark:bg-gray-800' + ' border-b border-gray-200 dark:border-slate-500'
                                    }
                                    colSpan={table.numCol()}
                                >
                                    <div className="flex justify-center p-5 items-center">
                                        <ErrorText manager={table.errorManager} error={table.errorTopRow} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    )}
                    {table.showTopRow && (
                        <tbody key={table.key + '-top-action'}>
                            <tr>
                                <td
                                    className={
                                        'bg-white dark:bg-gray-800' + ' border-b border-gray-200 dark:border-slate-500'
                                    }
                                    colSpan={table.numCol()}
                                >
                                    {table.concludeTopTableRender != undefined && table.concludeTopTableRender()}
                                </td>
                            </tr>
                        </tbody>
                    )}
                    {!table.loading && list.length == 0 && (
                        <tbody key={table.key + '-nothing'}>
                            <tr>
                                <td className={''} colSpan={table.numCol()}>
                                    {table.nothingRender()}
                                </td>
                            </tr>
                        </tbody>
                    )}
                    {getInNothing(listTag, list).length != 0 && table.taggedTable && (
                        <tbody key={table.key + '-tag-nocat'} className={' bg-gray-200 dark:bg-gray-900 '}>
                            <tr
                                className={
                                    'bg-gray-600 dark:bg-gray-500 border-b border-gray-200 dark:border-slate-500 text-base text-white '
                                }
                                key={table.key + '-'}
                            >
                                {table.colList.map((col, indexCol) => col.skip && <td className="p-2"></td>)}
                                <td className="p-2">
                                    <p className="text-base font-medium text-white">{'Sans catégorie'}</p>
                                </td>
                                {table.type === 'info' &&
                                    table.colList
                                        .slice(1 + (table.colList[0].skip ? 1 : 0), table.colList.length)
                                        .map(
                                            (row, indexRow) =>
                                                !row.skip && row.isVisible() && <td className="p-2"></td>,
                                        )}
                                {table.type === 'stat' &&
                                    table.colList
                                        .slice(1 + (table.colList[0].skip ? 1 : 0), table.colList.length)
                                        .map(
                                            (row, indexRow) =>
                                                !row.skip &&
                                                row.isVisible() && (
                                                    <td className="p-2">
                                                        {row.sumRender(getInNothing(listTag, list))}
                                                    </td>
                                                ),
                                        )}
                            </tr>
                            {getInNothing(listTag, list).map((elem, indexElem) =>
                                renderElem(null, elem, getInNothing(listTag, list), indexElem, (e, cclist, i) => {
                                    if ('TableRowNextInterface' in e) {
                                        if (indexElem === cclist.length - 1) {
                                            if (listTag.length != 0) {
                                                (e as unknown as TableRowNextInterface).next = getListCat(
                                                    listTag[0],
                                                    list,
                                                )[0];
                                            }
                                        } else {
                                            (e as unknown as TableRowNextInterface).next = cclist[indexElem + 1];
                                        }
                                    }
                                }),
                            )}
                        </tbody>
                    )}
                    {listTag.map((tag: TagObject, indexCategory) => (
                        <SectionTBody
                            key={table.key + '-tag-' + indexCategory}
                            nextList={
                                indexCategory === listTag.length - 1 ? [] : getListCat(listTag[indexCategory + 1], list)
                            }
                            index={indexCategory}
                            renderElem={renderElem as any}
                            tag={tag}
                            table={table}
                            list={getListCat(tag, list)}
                        />
                    ))}
                    {!table.taggedTable && listTag.length === 0 && (
                        <tbody key={table.key + '-bodey'}>
                            {list.map((elem, indexElem) => renderElem(null, elem, list, indexElem))}
                        </tbody>
                    )}
                    {table.taggedTable && getOutTag(list).length !== 0 && (
                        <tbody key={table.key + '-bodey'} className={'bg-white dark:bg-gray-800'}>
                            {getOutTag(list).map((elem, indexElem) =>
                                renderElem(null, elem, getOutTag(list), indexElem),
                            )}
                        </tbody>
                    )}
                    {table.colList.length > 0 && table.colList[0].renderBottomRow() !== undefined && (
                        <tbody key={table.key + '-top'}>
                            <tr
                                className={
                                    'bg-white dark:bg-gray-800' + ' border-b border-gray-200 dark:border-slate-500'
                                }
                            >
                                {table.colList.map(
                                    (row, indexRow) =>
                                        row.isVisible() && (
                                            <td className="p-2" key={table.key + '-top' + '-' + indexRow}>
                                                {row.renderBottomRow()}
                                            </td>
                                        ),
                                )}
                            </tr>
                        </tbody>
                    )}
                    {table.bottomTableRender != undefined && table.bottomTableRender() != undefined && (
                        <tbody key={table.key + '-bottom-action'}>
                            <tr>
                                <td
                                    className={
                                        'bg-white dark:bg-gray-800' + ' border-b border-gray-200 dark:border-slate-500'
                                    }
                                    colSpan={table.numCol()}
                                >
                                    {table.bottomTableRender()}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            )}
        </div>
    );
};
