import { useState } from 'react';
import DatePicker from 'react-date-picker';
import { Modal } from '../../commun/modal/Modal';
import { Col } from '../../commun/table/Col';

export interface IMultipleFilterModalProps {
    col: Col<any>;
}

export const MultipleFilterModal: React.FunctionComponent<IMultipleFilterModalProps> = (props) => {
    const { col: row } = props;

    const [start, onChangeDebut] = useState(new Date());
    const [end, onChangeEnd] = useState(new Date());

    return (
        <Modal
            onDone={() => {
                row.startDate = start;
                row.endDate = end;
                row.closeModal();
            }}
            show={row.showFilterModal}
            done={'Sélectionner'}
            title={'Choisir une période'}
            color={'blue'}
            handleClose={() => {
                row.startDate = start;
                row.endDate = end;
                row.closeModal();
            }}
        >
            <div className="flex text-base font-normal items-center justify-center flex-row gap-3">
                <p>Début</p>
                <DatePicker
                    calendarClassName={'rounded-md mt-1'}
                    className={
                        'pl-1 pr-1 pb-1 pt-1 placeholder:text-gray-500 dark:placeholder:text-gray-300 outline-0 rounded-md flex-1 text-black bg-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:text-white dark:focus:ring-red-900 dark:focus:border-red-900 focus:outline-none focus:ring focus:ring-blue-700 focus:dark:ring-blue-600 border-0'
                    }
                    onChange={onChangeDebut}
                    value={start}
                />
            </div>
            <div className="flex text-base font-normal  items-center justify-center flex-row gap-3">
                <p>Fin</p>
                <DatePicker
                    calendarClassName={'rounded-md mt-1'}
                    className={
                        'pl-1 pr-1 pb-1 pt-1 placeholder:text-gray-500 dark:placeholder:text-gray-300 outline-0 rounded-md flex-1 text-black bg-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:text-white dark:focus:ring-red-900 dark:focus:border-red-900 focus:outline-none focus:ring focus:ring-blue-700 focus:dark:ring-blue-600 border-0'
                    }
                    onChange={onChangeEnd}
                    value={end}
                />
            </div>
        </Modal>
    );
};
