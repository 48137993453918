import { Button } from '../commun/component/Button';
import { Spinner } from '../commun/component/Spinner';
import { Color } from '../commun/Constant';

export interface ITitleSectionProps {
    color?: Color;
    title: string;
    canAdd: boolean;
    canFusion: boolean;
    loading?: boolean;
    onAdd?: () => void;
    onFusion?: () => void;
}

export const TitleSection: React.FC<ITitleSectionProps> = (props) => {
    const { color = 'blue', loading = false, children, title, canAdd, canFusion, onAdd, onFusion } = props;
    return (
        <div className={`flex gap-2 w-full items-center`}>
            <span className="text-lg font-medium text-black dark:text-white">{title}</span>
            {children}

            {canAdd && !loading && (
                <Button color="blue" pb={1} pt={1} pl={1} pr={1} onClick={onAdd}>
                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </Button>
            )}

            {canAdd && loading && <Spinner className="h-5 w-5" color="blue"></Spinner>}

            {canFusion && (
                <Button color="blue" pb={1} pt={1} pl={1} pr={1} onClick={onFusion}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                        />
                    </svg>
                </Button>
            )}
        </div>
    );
};
