import { Box, Button, Card, Typography } from '@mui/material';
import { useState } from 'react';
import AppManager from 'src/commun/AppManager';
import { ElemBudget } from 'src/commun/Constant';
import { post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import { MargeReducModal } from 'src/modal/marge/MargeModal';
import { Marge } from 'src/object/Marge';
import { Reduc } from 'src/object/marge_reduc/Reduc';
import { SERVER_URL } from 'src/utils/Constant';
import { EuroSpan } from '../../commun/component/EuroSpan';
import { PourcentSpan } from '../../commun/component/PourcentSpan';
import { BinIcon } from '../../icons/BinIcon';
import { EditIcon } from '../../icons/EditIcon';
import BasePrice from '../../object/BasePrice';
import Devis from '../../object/Devis';
import { Paper } from '../../object/Paper';
import { LittleAddButton } from '../LittleAddButton';
import { MargeReducView } from '../MargeView';
import { Separator } from '../Separator';
import ArchivePaperModal from './ArchivePaperModal';
import ChoosePaperModal from './ChoosePaperModal';
import DelPaperModal from './DelPaperModal';
import DuplicatePaperModal from './DuplicatePaperModal';
import PaperModal from './PaperModal';
import Budget from 'src/object/Budget';

export interface IPaperStatViewProps {
    paper: Paper;
    basePrice: BasePrice;
    selected: boolean;
    detailed?: boolean;
    action?: boolean;
    exportPaper?: boolean;
}

export const PaperStatView = (props: IPaperStatViewProps) => {
    const { paper, basePrice, exportPaper = false, detailed = false, action = false } = props;

    const [openEdit, setOpenEdit] = useState(false);
    const [openDel, setOpenDel] = useState(false);
    const [openDuplicate, setOpenDuplicate] = useState(false);
    const [openSelect, setOpenSelect] = useState(false);
    const [openArchive, setOpenArchive] = useState(false);

    const [showAddMarge, setShowAddMarge] = useState(false);
    const [showAddReduc, setShowAddReduc] = useState(false);

    return (
        <>
            <Card
                key={paper.getAjoIdentifier()}
                sx={{ p: 3 }}
                onClick={() => {
                    if (!detailed) {
                        basePrice.selectedPaper = paper.getAjoIdentifier();
                        AppManager.makeUpdate();
                    }
                }}
                className={
                    (props.selected ? 'border-8 border-blue-600' : '') +
                    ' flex gap-3 flex-col !min-w-[22rem] justify-center ' +
                    (detailed ? ' !max-w-[32rem]' : '')
                }
            >
                <Box className="flex flex-row">
                    <Box sx={{ gap: 3 }} className="flex flex-1 items-center flex-row">
                        <Typography variant="h5">{paper.getName()}</Typography>
                    </Box>

                    {paper.root.get() === true && (
                        <Box className="flex items-center justify-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className={
                                    (basePrice.isGoodVa() ? 'text-green-600' : 'text-red-600') +
                                    ' text-emerald-600 w-10 h-10'
                                }
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </Box>
                    )}
                </Box>

                {detailed && (
                    <Box className="flex flex-row">
                        <Typography className="!font-semibold">Chiffre d'affaire par personne sans option</Typography>
                        <Typography
                            className={
                                (basePrice.isGoodVa() ? 'text-green-600' : 'text-red-600') +
                                ' flex-1 text-end !font-bold'
                            }
                        >
                            <EuroSpan value={paper.prixVenteHTNoOption() / basePrice.nb_personne.get()} />
                        </Typography>
                    </Box>
                )}
                {detailed && (
                    <Box className="flex flex-row">
                        <Typography className="!font-semibold">Chiffre d'affaire par personne</Typography>
                        <Typography
                            className={
                                (basePrice.isGoodVa() ? 'text-green-600' : 'text-red-600') +
                                ' flex-1 text-end !font-bold'
                            }
                        >
                            <EuroSpan value={paper.prixVenteHT() / basePrice.nb_personne.get()} />
                        </Typography>
                    </Box>
                )}

                {detailed && paper.func.get() === 0 && (
                    <Box className="flex flex-row">
                        <Typography>Achat</Typography>
                        <Typography className="flex-1 text-end">
                            <EuroSpan value={paper.achat()} />
                        </Typography>
                    </Box>
                )}

                {detailed && paper.func.get() === 1 && (
                    <Box className="flex flex-row">
                        <Typography>Nb minute</Typography>
                        <Typography className="flex-1 text-end">{paper.minute().toFixed(2)} m</Typography>
                    </Box>
                )}

                {detailed && paper.func.get() === 1 && (
                    <Box className="flex flex-row">
                        <Typography>Nb jour</Typography>
                        <Typography className="flex-1 text-end">{paper.jour().toFixed(2)} j</Typography>
                    </Box>
                )}

                {detailed && (
                    <Box className="flex flex-row">
                        <Typography className="!font-semibold">
                            {paper.func.get() === 0 ? "Chiffre d'affaire sans option" : 'Honoraire sans option'}
                        </Typography>
                        <Typography
                            className={
                                (basePrice.isGoodVa() ? 'text-green-600' : 'text-red-600') +
                                ' flex-1 text-end !font-bold'
                            }
                        >
                            <EuroSpan value={paper.prixVenteHTNoOptionNoReducNoMarge()} />
                        </Typography>
                    </Box>
                )}

                {detailed && (
                    <Box className="flex flex-row">
                        <Typography className="!font-semibold">
                            {paper.func.get() === 0 ? "Chiffre d'affaire total" : 'Honoraire total'}
                        </Typography>
                        <Typography
                            className={
                                (basePrice.isGoodVa() ? 'text-green-600' : 'text-red-600') +
                                ' flex-1 text-end !font-bold'
                            }
                        >
                            {detailed ? (
                                <EuroSpan value={paper.prixVenteHTNoReducNoMarge()} />
                            ) : (
                                <EuroSpan value={paper.prixVenteHT()} />
                            )}
                        </Typography>
                    </Box>
                )}

                {detailed && (
                    <Box className="flex flex-col" sx={{ gap: 1.5 }}>
                        {(paper.canAct() || paper.margeList.size() !== 0) && <Separator />}
                        {paper.margeList.map((m: Marge, index: number) => {
                            return <MargeReducView key={'marge-' + index} index={index} paper={paper} marge={m} />;
                        })}
                        {paper.canAct() && (
                            <div
                                className="flex flex-row items-center gap-3"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowAddMarge(true);
                                }}
                            >
                                <LittleAddButton text="" onAdd={() => {}} />
                                <p className="text-lg text-left font-medium">Ajouter une marge</p>
                            </div>
                        )}
                        {(paper.canAct() || paper.reducList.size() !== 0) && <Separator />}
                        {paper.reducList.map((m: Reduc, index: number) => {
                            return <MargeReducView key={'marge-' + index} index={index} paper={paper} marge={m} />;
                        })}
                        {paper.canAct() && (
                            <div
                                className="flex flex-row items-center gap-3"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowAddReduc(true);
                                }}
                            >
                                <LittleAddButton text="" onAdd={() => {}} />
                                <p className="text-lg text-left font-medium">Ajouter une remise commerciale</p>
                            </div>
                        )}
                        <Separator />
                    </Box>
                )}

                {detailed && (
                    <Box className="flex flex-row">
                        <Typography className="!font-semibold !text-xl">
                            {paper.func.get() === 0
                                ? "Chiffre d'affaire final sans option"
                                : 'Honoraire final sans option'}
                        </Typography>
                        <Typography
                            className={
                                (basePrice.isGoodVa() ? 'text-green-600' : 'text-red-600') +
                                ' flex-1 !text-xl text-end !font-bold'
                            }
                        >
                            <EuroSpan value={paper.prixVenteHTNoOption()} />
                        </Typography>
                    </Box>
                )}

                {detailed && paper.func.get() === 0 && (
                    <Box className="flex flex-row">
                        <Typography>Marge brute sans option</Typography>
                        <Typography
                            className={
                                (basePrice.isGoodVa() ? 'text-green-600' : 'text-red-600') +
                                ' flex-1 text-end !font-semibold'
                            }
                        >
                            <EuroSpan value={paper.margeNoOption()} />
                        </Typography>
                    </Box>
                )}

                {detailed && paper.func.get() === 0 && (
                    <Box className="flex flex-row">
                        <Typography className="!text-xl !font-semibold">Taux MB sans option</Typography>
                        <Typography
                            className={
                                (basePrice.isGoodVa() ? 'text-green-600' : 'text-red-600') +
                                ' !text-xl flex-1 text-end !font-bold'
                            }
                        >
                            <PourcentSpan value={paper.vaNoOption()} />
                        </Typography>
                    </Box>
                )}

                {detailed && (
                    <Box className="flex flex-row">
                        <Typography className="!font-semibold !text-xl">
                            {paper.func.get() === 0 ? "Chiffre d'affaire final" : 'Honoraire final'}
                        </Typography>
                        <Typography
                            className={
                                (basePrice.isGoodVa() ? 'text-green-600' : 'text-red-600') +
                                ' flex-1 !text-xl text-end !font-bold'
                            }
                        >
                            <EuroSpan value={paper.prixVenteHT()} />
                        </Typography>
                    </Box>
                )}

                {detailed && paper.func.get() === 0 && (
                    <Box className="flex flex-row">
                        <Typography>Marge brute</Typography>
                        <Typography
                            className={
                                (basePrice.isGoodVa() ? 'text-green-600' : 'text-red-600') +
                                ' flex-1 text-end !font-semibold'
                            }
                        >
                            <EuroSpan value={paper.marge()} />
                        </Typography>
                    </Box>
                )}

                {detailed && paper.func.get() === 0 && (
                    <Box className="flex flex-row">
                        <Typography className="!text-xl !font-semibold">Taux MB</Typography>
                        <Typography
                            className={
                                (basePrice.isGoodVa() ? 'text-green-600' : 'text-red-600') +
                                ' !text-xl flex-1 text-end !font-bold'
                            }
                        >
                            <PourcentSpan value={paper.va()} />
                        </Typography>
                    </Box>
                )}
                {exportPaper && basePrice.canAct() && (
                    <Box className="flex gap-3 flex-row">
                        <Button
                            color="info"
                            variant="contained"
                            className="!pl-3 flex-1 gap-2"
                            onClick={() => {
                                const baseSelect = (paper: Paper): ElemBudget[] => {
                                    let elemList = [] as ElemBudget[];

                                    if (paper.getAjoParent()?.getAjoParent() instanceof Paper) {
                                        elemList.push(paper);
                                    }

                                    for (let budget of paper.budgetList.getList()) {
                                        if (budget instanceof Paper) {
                                            elemList.push(...baseSelect(budget));
                                        } else if (budget.getAjoParent()?.getAjoParent() instanceof Paper) {
                                            let paperParent = budget.getAjoParent()!.getAjoParent() as Paper;
                                            if (paperParent.func.get() === 0) {
                                                elemList.push(budget);
                                            }
                                        }
                                    }
                                    return elemList;
                                };
                                let elemList = baseSelect(paper);
                                let id_list: string[] = [];
                                for (let elem of elemList) {
                                    id_list.push(elem.getAjoIdentifier());
                                }
                                post('/devis/export', {
                                    id: basePrice.getAjoIdentifier(),
                                    id_paper: paper.getAjoIdentifier(),
                                    id_list: id_list,
                                }).then((res: any) => {
                                    if (Result.isSuccess(res)) {
                                        const link = document.createElement('a');
                                        link.href = SERVER_URL + '/' + res['result']['where'];
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }
                                });
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            Exporter
                        </Button>
                    </Box>
                )}
                {detailed && basePrice.canAct() && paper.archive.get() !== true && (
                    <Box>
                        <div
                            className="flex gap-3 flex-col "
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Box className="flex gap-3 flex-row">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className="!pl-3 flex-1 gap-2"
                                    onClick={() => {
                                        setOpenEdit(true);
                                    }}
                                >
                                    <EditIcon />
                                    Modifier
                                </Button>
                                {paper.canDelete() && (
                                    <Button
                                        color="error"
                                        variant="contained"
                                        className="!pl-3 flex-1 gap-2"
                                        onClick={() => {
                                            setOpenDel(true);
                                        }}
                                    >
                                        <BinIcon />
                                        Supprimer
                                    </Button>
                                )}
                            </Box>
                            <Box className="flex gap-3 flex-row">
                                <Button
                                    color="warning"
                                    variant="contained"
                                    className="!pl-3 flex-1 gap-2"
                                    onClick={() => {
                                        setOpenDuplicate(true);
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z" />
                                        <path d="M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z" />
                                    </svg>
                                    Dupliquer
                                </Button>
                                {paper.canDelete() && (
                                    <Button
                                        color="success"
                                        variant="contained"
                                        className="!pl-3 flex-1 gap-2"
                                        onClick={() => {
                                            setOpenSelect(true);
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        Choisir
                                    </Button>
                                )}
                            </Box>
                            <Box className="flex gap-3 flex-row">
                                <Button
                                    color="info"
                                    variant="contained"
                                    className="!pl-3 flex-1 gap-2"
                                    onClick={() => {
                                        setOpenArchive(true);
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375z" />
                                        <path
                                            fillRule="evenodd"
                                            d="M3.087 9l.54 9.176A3 3 0 006.62 21h10.757a3 3 0 002.995-2.824L20.913 9H3.087zM12 10.5a.75.75 0 01.75.75v4.94l1.72-1.72a.75.75 0 111.06 1.06l-3 3a.75.75 0 01-1.06 0l-3-3a.75.75 0 111.06-1.06l1.72 1.72v-4.94a.75.75 0 01.75-.75z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    Archiver
                                </Button>
                            </Box>
                        </div>
                    </Box>
                )}
            </Card>
            <Box
                className="p-absolute"
                onClick={(e: any) => {
                    e.stopPropagation();
                }}
            >
                <DelPaperModal paper={paper} open={openDel} onClose={() => setOpenDel(false)} />

                <DuplicatePaperModal paper={paper} open={openDuplicate} onClose={() => setOpenDuplicate(false)} />

                <ChoosePaperModal paper={paper} open={openSelect} onClose={() => setOpenSelect(false)} />

                <ArchivePaperModal paper={paper} open={openArchive} onClose={() => setOpenArchive(false)} />

                <PaperModal
                    basePrice={paper.getBasePrice() ?? new Devis()}
                    paper={paper}
                    func={paper.func.get()}
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                />

                <MargeReducModal
                    onMargeReducChange={() => {}}
                    action="add"
                    type="marge"
                    paper={paper}
                    show={showAddMarge}
                    onClose={() => {
                        setShowAddMarge(false);
                    }}
                />
                <MargeReducModal
                    onMargeReducChange={() => {}}
                    action="add"
                    type="reduc"
                    paper={paper}
                    show={showAddReduc}
                    onClose={() => {
                        setShowAddReduc(false);
                    }}
                />
            </Box>
        </>
    );
};
