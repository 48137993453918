import { useState } from 'react';
import BasePrice from 'src/object/BasePrice';
import { post } from '../../commun/HTTP';
import Budget from '../../object/Budget';

export interface IRefDevisProps {
    budget: Budget;
    basePrice: BasePrice;
}

export const RefDevisInput = (props: IRefDevisProps) => {
    const { budget, basePrice } = props;

    const [val, setVal] = useState(budget.ref_devis.get() ?? '');

    return (
        <input
            disabled={budget.bon_commande.get() !== null || !basePrice.canAct()}
            value={val ?? ''}
            onBlur={() => {
                budget.leaveFocus();
            }}
            onFocus={() => {
                budget.getFocus();
            }}
            onChange={(e) => {
                let newVal = (e.target as any).value;
                budget.ref_devis.set(newVal + '');
                budget.addEdit({
                    ref_devis: budget.ref_devis.get(),
                });
                setVal(newVal);
            }}
            placeholder="Référence devis"
            className="bg-gray-300 w-32 placeholder:text-gray-600 border-gray-400 border rounded-lg py-2 px-3 focus:!border focus:!ring focus:!ring-blue-500 focus:!border-blue-500"
        />
    );
};
