import { AJOUpdater } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../commun/component/Button';
import { CheckBox } from '../commun/component/CheckBox';
import { AddTemplateModal } from '../modal/vendor/AddTemplateModal';
import { templateRemoveSuccess } from '../modal/vendor/CategoryErrorManager';
import { RemoveCategoryModal } from '../modal/vendor/RemoveCategoryModal';
import Template from '../object/Template';
import { DeleteSvg } from './DeleteSvg';
import { EditSvg } from './EditSvg';
import { Tag } from './Tag';

export interface ITemplateListProps {
    templateList: Template[];
    canEdit: boolean;
    canDelete: boolean;
    loading: boolean;

    select?: boolean;
    checkList?: Template[];
    onSelect?: (t: Template) => void;
}
export const TemplateList: React.FC<ITemplateListProps> = (props) => {
    const { templateList, checkList, select = false, onSelect = () => {}, canEdit, canDelete } = props;

    const [showModal, updateShowModal] = useState(false);
    const [elem, updateElem] = useState<null | Template>(null);
    const onDelete = (e: Template) => {
        updateElem(e);
        updateShowModal(true);
    };
    return (
        <div className="flex gap-2 w-full flex-row flex-wrap">
            {select
                ? templateList.map((template, index) => {
                      return (
                          <div key={'template-' + index}>
                              <label
                                  htmlFor={'select-template-' + index}
                                  className="flex flex-row p-2 border border-slate-500 dark:border-slate-400 gap-2 rounded-lg w-full"
                              >
                                  <div className="flex flex-row items-center justify-center gap-2 flex-1">
                                      <CheckBox
                                          checked={
                                              checkList!.find(
                                                  (e: Template) => e.getAjoIdentifier() === template.getAjoIdentifier(),
                                              ) !== undefined
                                          }
                                          id={'select-template-' + index}
                                          onChange={() => {
                                              onSelect(template);
                                          }}
                                      />

                                      <div className="flex flex-col justify-center gap-2 flex-1">
                                          <span className="text-base text-black dark:text-white">
                                              {template.name.get()}
                                          </span>
                                          {template.product.get() && (
                                              <div className="flex">
                                                  <Tag
                                                      size="sm"
                                                      canDelete={false}
                                                      canEdit={false}
                                                      elem={template.product.get()!}
                                                  />
                                              </div>
                                          )}
                                      </div>
                                  </div>
                              </label>
                          </div>
                      );
                  })
                : templateList.map((template, index) => {
                      return (
                          <div key={'template-' + index} className="w-full">
                              <Link to={template.getLink()} className="flex flex-col gap-2 flex-1">
                                  <div className="flex flex-row p-2 items-center border border-slate-500 dark:border-slate-400 gap-2 rounded-lg w-full">
                                      <span className="text-base text-black dark:text-white">
                                          {template.name.get()}
                                      </span>
                                      {template.product.get() && (
                                          <div className="flex">
                                              <Tag
                                                  size="sm"
                                                  canDelete={false}
                                                  canEdit={false}
                                                  elem={template.product.get()!}
                                              />
                                          </div>
                                      )}
                                      {canEdit && (
                                          <div className="flex flex-1 justify-end items-center">
                                              <Button
                                                  color="blue"
                                                  pb={1.5}
                                                  pt={1.5}
                                                  pr={1.5}
                                                  pl={1.5}
                                                  onClick={() => {
                                                      updateShowModal(true);
                                                  }}
                                                  className={'focus:ring-4 rounded-md text-black dark:text-white'}
                                              >
                                                  <EditSvg className="h-5 w-5" />
                                              </Button>
                                          </div>
                                      )}
                                  </div>
                              </Link>
                          </div>
                      );
                  })}
            {canDelete && elem && (
                <RemoveCategoryModal
                    field={'template'}
                    title={'Supprimer le template'}
                    textDelete={'Voulez-vous vraiment supprimer ce template ?'}
                    successError={templateRemoveSuccess}
                    show={showModal}
                    elem={elem}
                    handleClose={() => updateShowModal(false)}
                />
            )}
        </div>
    );
};
