import { AJOElement, AJOList, AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import MakeAction from '../commun/action/Action';
import ActionManager from '../commun/action/ActionManager';
import AppManager from '../commun/AppManager';
import { ComplexInput } from '../commun/component/ComplexInput';
import { ErrorText } from '../commun/component/ErrorText';
import { Spinner } from '../commun/component/Spinner';
import ErrorHTTP from '../commun/ErrorHTTP';
import ErrorManager from '../commun/ErrorManager';
import ErrorMessage from '../commun/ErrorMessage';
import { post } from '../commun/HTTP';
import { IModalProps, Modal } from '../commun/modal/Modal';
import Result from '../commun/Result';
import Toast from '../commun/toast/Toast';
import Utils from '../commun/Utils';
import Role from '../object/Role';
import User from '../object/User';

export interface IDeleteRoleModalProps extends IModalProps {
    currentRole?: Role;
    selectIdList?: string[];
    type: 'simple' | 'multiple';
    stateList: AJOState<AJOList<Role>>;
}

export const DeleteRoleModal: React.FunctionComponent<IDeleteRoleModalProps> = (props) => {
    const { currentRole, type, stateList, selectIdList = [], ...rest } = props;

    const errorManager = new ErrorManager('role/delete');

    /**
     * REST
     */
    const [count, setCount] = useState(0);

    errorManager.addMessage(ErrorMessage.NO_PERMISSION());
    errorManager.addMessage(ErrorMessage.NO_PERMISSION_ROOT());

    if (type == 'simple') {
        errorManager.add(
            ErrorHTTP.SUCCESS().setColor('green-600').setColorDark('green-300'),
            'Le rôle a été supprimé avec succès.',
        );
    } else {
        if (count > 0) {
            if (count == 1) {
                errorManager.add(
                    ErrorHTTP.SUCCESS().setColor('green-600').setColorDark('green-300'),
                    'Un rôle a été supprimé avec succès.',
                );
            } else {
                errorManager.add(
                    ErrorHTTP.SUCCESS().setColor('green-600').setColorDark('green-300'),
                    count + ' rôles ont été supprimés avec succès.',
                );
            }
        } else {
            errorManager.add(ErrorHTTP.SUCCESS(), 'Aucun utilisateur demandé.');
        }
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(ErrorHTTP.NO_ERROR());

    const onDone = () => {
        setError(ErrorHTTP.NO_ERROR());
        setLoading(true);
        if (type === 'simple') {
            post('/role/delete', {
                id_list: [currentRole!.getAjoIdentifier()],
            }).then((res: any) => {
                setLoading(false);
                if (Result.isSuccess(res)) {
                    setError(ErrorHTTP.SUCCESS());
                    Result.applyResult(res, AppManager.updater(), true);
                    AppManager.addToast(new Toast(errorManager.get(ErrorHTTP.SUCCESS()), 'success'));
                    props.handleClose();
                } else {
                    setError(Result.getError(res));
                }
            });
        } else {
            post('/role/delete', {
                id_list: selectIdList,
            }).then((res: any) => {
                setLoading(false);
                if (Result.isSuccess(res)) {
                    setCount(Result.getResult(res)['user'].length);
                    AppManager.addToast(new Toast(errorManager.get(ErrorHTTP.SUCCESS()), 'success'));
                    props.handleClose();
                    Result.applyResult(res, AppManager.updater(), true);
                } else {
                    setError(Result.getError(res));
                }
            });
        }
    };

    let doneText = '';
    let titleText = '';
    let descriptionText = '';
    let bonus: any;
    if (type === 'simple') {
        doneText = 'Supprimer';
        titleText = 'Supprimer ' + currentRole!.name.get();
        descriptionText =
            'Voulez-vous vraiment supprimer ce rôle ? Une fois le rôle supprimé, toutes les personnes qui possèdent ce rôle recuperront le rôle par défault.';
    } else {
        titleText = 'Supprimer des rôles';
        doneText = 'Supprimer';

        const list = ActionManager.listDoAction(
            MakeAction.DELETE_ROLE,
            AppManager.getUser(),
            Utils.listFromId(stateList.get()!, selectIdList),
        );

        descriptionText =
            'Voulez-vous vraiment supprimer ces rôles ? Une fois le rôle supprimé, toutes les personnes qui possèdent ce rôle recuperront le rôle par défault.';

        bonus = (
            <p className="text-black dark:text-white">
                Suppression de :{' '}
                {list.length != 0
                    ? list.map((user, index) => {
                          return (
                              <span key={index}>
                                  {user.getFullName()}
                                  {index < list.length - 1 ? ', ' : ''}
                              </span>
                          );
                      })
                    : selectIdList.length == 0
                    ? 'Aucun utilisateur sélectionné'
                    : 'Les utilisateurs sélectionnés sont déjà supprimés ou vous ne posséder pas les permissions sur les utilisateurs sélectionnés.'}
            </p>
        );
    }

    return (
        <Modal color="red" done={doneText} title={titleText} {...rest} bottomMode="action" onDone={onDone}>
            <p className="text-black dark:text-white">{descriptionText}</p>
            {bonus}

            <Spinner color="red"></Spinner>

            <ErrorText topSpacing={2} manager={errorManager} error={error}></ErrorText>
        </Modal>
    );
};
