import { AJOObject, AJOProperties, AJOSimple } from 'mp-js-react-auto-json-object';
import AppManager from '../commun/AppManager';
import { post } from '../commun/HTTP';
import Result from '../commun/Result';
import Toast from '../commun/toast/Toast';
import { TimerCalendarView } from '../component/TimerCalendarView';
import Devis from './Devis';
import { Action } from './Action';
import Client from './profile/Client';
import { Section } from './Section';
import User from './User';
import { JSXInternal } from 'preact/src/jsx';

export class Timer extends AJOObject {
    public static _TYPE: string = 'Timer';

    public end: AJOProperties<any>;
    public start: AJOProperties<any>;

    public alias: AJOProperties<any>;

    public endEdit: AJOProperties<any>;
    public startEdit: AJOProperties<any>;

    public ask: AJOProperties<any>;

    public type: AJOProperties<any>;
    public edit: AJOProperties<any>;
    public state: AJOProperties<any>;

    public _load: boolean = false;

    public get load(): boolean {
        return this._load;
    }
    public set load(load: boolean) {
        this._load = load;
    }

    public section: AJOSimple<Section>;
    public object: AJOSimple<Devis | Action>;

    public view: TimerCalendarView | null;

    public static MOVE = 0;
    public static UP = 1;
    public static DOWN = 2;

    public static WORK = 0;
    public static RECUP = 1;
    public static CP = 2;
    public static MALADIE = 3;

    public startEvent: boolean = false;
    public startTimeStamp: number = 0;
    public typeMove: number = Timer.MOVE;
    public startEventX: number = 0;
    public startEventY: number = 0;
    public quartHour: number = 0;

    public isWork(): boolean {
        return this.type.get() === 0 || this.type.get() === undefined;
    }

    public isRecup(): boolean {
        return this.type.get() === 1;
    }

    public isCp(): boolean {
        return this.type.get() === 2;
    }

    public isFerie(): boolean {
        return this.type.get() === 3;
    }

    public isMaladie(): boolean {
        return this.type.get() === 4;
    }

    public isCpRecupFerie(): boolean {
        return this.isCp() || this.isRecup() || this.isFerie() || this.isMaladie();
    }

    public editHoraire(user: User) {
        this.start.set(this.getStartDate().toISOString());
        this.end.set(this.getEndDate().toISOString());

        this.startEdit.set(undefined);
        this.endEdit.set(undefined);

        post('/timer/edit', {
            force: true,
            type: this.getTypeStr(),
            id: this.getAjoIdentifier(),
            start: this.getStartDate().toISOString(),
            end: this.getEndDate().toISOString(),
            id_user: user.getAjoIdentifier(),
            id_object: this.object.get()?.getAjoIdentifier() ?? '',
            id_section: this.section.get()?.getAjoIdentifier() ?? '',
        }).then((res: any) => {
            if (Result.isSuccess(res)) {
                //Result.applyResult(res, AppManager.updater(), true);
                /*AppManager.addToast(new Toast(
                    "L'horaire a été modifié avec succès.",
                    "success"
                ));*/
            }
        });
    }

    public restartAction(user: User): boolean {
        let make = false;
        if (this.startEvent) {
            make = true;
            this.editHoraire(user);
        }
        this.startEvent = false;
        return make;
    }

    public onMouseMove(e: JSXInternal.TargetedMouseEvent<HTMLTableSectionElement>) {
        if (this.startEvent) {
            let moveY = e.pageY - this.startEventY;
            let begQuartChange = moveY / this.quartHour;
            let quartChange = 0;
            if (begQuartChange == 0) {
            } else if (begQuartChange > 0) {
                quartChange = Math.floor(begQuartChange);
            } else {
                quartChange = Math.ceil(begQuartChange);
            }
            if (quartChange !== 0) {
                let end = this.getEndDate();
                end.setTime(end.getTime() + quartChange * 15 * 60 * 1000);
                let start = this.getStartDate();
                start.setTime(start.getTime() + quartChange * 15 * 60 * 1000);

                let sameStart = this.getBaseStartDate().toISOString() === this.getStartDate().toISOString();
                let sameEnd = this.getBaseEndDate().toISOString() === this.getEndDate().toISOString();

                if (this.typeMove == Timer.MOVE) {
                    this.startEdit.set(start.toISOString());
                    this.endEdit.set(end.toISOString());
                } else if (this.typeMove == Timer.DOWN) {
                    if (sameStart && end.toISOString() > this.getEndDate().toISOString()) {
                        if (this.getStartDate().toISOString() <= end.toISOString()) {
                            this.endEdit.set(end.toISOString());
                        } else {
                            this.endEdit.set(this.getStartDate().toISOString());
                        }
                    } else if (
                        sameStart &&
                        end.toISOString() < this.getEndDate().toISOString() &&
                        end.toISOString() >= this.getBaseStartDate().toISOString()
                    ) {
                        if (this.getStartDate().toISOString() <= end.toISOString()) {
                            this.endEdit.set(end.toISOString());
                        } else {
                            this.endEdit.set(this.getStartDate().toISOString());
                        }
                    } else if (start.toISOString() < this.getStartDate().toISOString()) {
                        if (this.getEndDate().toISOString() >= start.toISOString()) {
                            this.startEdit.set(start.toISOString());
                        } else {
                            this.startEdit.set(this.getEndDate().toISOString());
                        }
                    } else if (
                        start.toISOString() > this.getStartDate().toISOString() &&
                        start.toISOString() <= this.getBaseEndDate().toISOString()
                    ) {
                        if (this.getEndDate().toISOString() >= start.toISOString()) {
                            this.startEdit.set(start.toISOString());
                        } else {
                            this.startEdit.set(this.getEndDate().toISOString());
                        }
                    }
                } else if (this.typeMove == Timer.UP) {
                    if (sameEnd && start.toISOString() < this.getStartDate().toISOString()) {
                        if (this.getEndDate().toISOString() >= start.toISOString()) {
                            this.startEdit.set(start.toISOString());
                        } else {
                            this.startEdit.set(this.getEndDate().toISOString());
                        }
                    } else if (
                        sameEnd &&
                        start.toISOString() > this.getStartDate().toISOString() &&
                        start.toISOString() <= this.getBaseEndDate().toISOString()
                    ) {
                        if (this.getEndDate().toISOString() >= start.toISOString()) {
                            this.startEdit.set(start.toISOString());
                        } else {
                            this.startEdit.set(this.getEndDate().toISOString());
                        }
                    } else if (end.toISOString() > this.getEndDate().toISOString()) {
                        if (this.getStartDate().toISOString() <= end.toISOString()) {
                            this.endEdit.set(end.toISOString());
                        } else {
                            this.endEdit.set(this.getStartDate().toISOString());
                        }
                    } else if (
                        end.toISOString() < this.getEndDate().toISOString() &&
                        end.toISOString() >= this.getBaseStartDate().toISOString()
                    ) {
                        if (this.getStartDate().toISOString() <= end.toISOString()) {
                            this.endEdit.set(end.toISOString());
                        } else {
                            this.endEdit.set(this.getStartDate().toISOString());
                        }
                    }
                    /*else if(start.toISOString()<this.getStartDate().toISOString()){
                        
                        if(this.getEndDate().toISOString()>=start.toISOString()){
                            this.startEdit.set(start.toISOString());
                        }
                        else {
                            this.startEdit.set(this.getEndDate().toISOString());
                        }

                    }
                    else if(start.toISOString()>this.getStartDate().toISOString()&&start.toISOString()<=this.getBaseEndDate().toISOString()){

                        if(this.getEndDate().toISOString()>=start.toISOString()){
                            this.startEdit.set(start.toISOString());
                        }
                        else {
                            this.startEdit.set(this.getEndDate().toISOString());
                        }

                    }*/
                }

                AppManager.makeUpdate();

                this.startEventY = this.startEventY + quartChange * this.quartHour;
            }
        }
    }

    public startAction(
        e: JSXInternal.TargetedMouseEvent<HTMLTableCellElement | HTMLTableRowElement>,
        quartHour: number,
        typeMove: number,
    ) {
        if (!this.startEvent) {
            this.startEvent = true;
            this.startTimeStamp = e.timeStamp;
            this.typeMove = typeMove;
            this.startEventX = e.pageX;
            this.startEventY = e.pageY;
            this.quartHour = quartHour;
        }
    }

    public stopAction() {
        this.startEvent = false;
        this.startTimeStamp = 0;
        this.typeMove = 0;
        this.startEventX = 0;
        this.startEventY = 0;
        this.quartHour = 0;
    }

    constructor() {
        super(Timer._TYPE);

        this.view = null;

        this.end = new AJOProperties('end');
        this.start = new AJOProperties('start');

        this.ask = new AJOProperties('ask');

        this.endEdit = new AJOProperties('end');
        this.startEdit = new AJOProperties('start');
        this.alias = new AJOProperties('alias');

        this.object = new AJOSimple<Devis | Action>([Devis, Action], 'has_timer_object', this);
        this.section = new AJOSimple<Section>(Section, 'has_timer_section', this);
        this.type = new AJOProperties('type', this);
        this.edit = new AJOProperties('edit', this);
        this.state = new AJOProperties('state', this);
    }

    public show: boolean = false;
    public day: number = 0;
    public topModal: number = 0;

    public isValidate(): boolean {
        return this.state.get() === true;
    }
    public isPendding(): boolean {
        return this.state.get() === undefined;
    }
    public isRefuse(): boolean {
        return this.state.get() === false;
    }

    public getTypeStr(): string {
        if (this.type.get() === 0 || this.type.get() === undefined) {
            return 'travail';
        } else if (this.type.get() === 1) {
            return 'recup';
        } else {
            return 'cp';
        }
    }

    public getColor(): string {
        return this.object.get()?.getColorSpec() ?? 'red';
    }

    public getDuration(): number {
        let start = this.getStartDate();
        let end = this.getEndDate();

        let res;

        if (
            this.isCpRecupFerie() &&
            ((start.getHours() === 9 && start.getMinutes() === 0 && end.getHours() === 17 && end.getMinutes() === 0) ||
                (start.getHours() === 8 &&
                    start.getMinutes() === 0 &&
                    end.getHours() === 16 &&
                    end.getMinutes() === 0) ||
                (start.getHours() === 6 &&
                    start.getMinutes() === 0 &&
                    end.getHours() === 14 &&
                    end.getMinutes() === 0) ||
                (start.getHours() === 7 && start.getMinutes() === 0 && end.getHours() === 15 && end.getMinutes() === 0))
        ) {
            res = end.getTime() - start.getTime() - 3600000;
        } else {
            res = end.getTime() - start.getTime();
        }
        return res;
    }

    public getName(): string {
        if (this.type.get() === 0 || this.type.get() === undefined) {
            return this.object.get()!.name.get();
        } else if (this.isFerie()) {
            return this.alias.get() ?? 'Jour non travaillé';
        } else if (this.type.get() === 1) {
            return 'Recup';
        } else if (this.type.get() === 2) {
            return 'CP';
        } else if (this.type.get() === 3) {
            return 'Arrêt maladie';
        }
        return '';
    }

    public setShow(show: boolean, day: number, topModal: number): Timer {
        this.show = show;
        this.day = day;
        this.topModal = topModal;
        return this;
    }

    public isShow(day: number): boolean {
        return this.day === day && this.show;
    }

    reset() {
        this.startEdit.set(undefined);
        this.endEdit.set(undefined);
        this.startEvent = false;
    }

    getAskDate(): Date {
        return new Date(this.ask.get());
    }

    getStartDate(): Date {
        if (this.startEdit.get() === undefined) {
            return new Date(this.start.get());
        } else {
            return new Date(this.startEdit.get());
        }
    }

    getBaseStartDate(): Date {
        return new Date(this.start.get());
    }

    getBaseEndDate(): Date {
        let timerEnd = new Date();
        if (this.end.get() !== undefined) {
            timerEnd = new Date(this.end.get());
        }
        return timerEnd;
    }

    getEndDate(): Date {
        let timerEnd = new Date();
        if (this.endEdit.get() === undefined) {
            if (this.end.get() !== undefined) {
                timerEnd = new Date(this.end.get());
            }
        } else {
            timerEnd = new Date(this.endEdit.get());
        }
        return timerEnd;
    }

    mostRecent(): Date {
        if (this.end.get() !== undefined) {
            return new Date(this.end.get()!);
        }
        return new Date(this.start.get()!);
    }

    public static build(): Timer {
        return new Timer();
    }

    public getDurationIn(start: Date, end: Date) {
        let timerStart = this.getStartDate();
        let timerEnd = this.getEndDate();

        let res = 0;

        if (timerStart < start) {
            timerStart = start;
        }

        if (timerEnd > end) {
            timerEnd = end;
        }

        if (timerStart < timerEnd) {
            res = timerEnd.getTime() - timerStart.getTime();
        }

        return res;
    }

    public static getClientDevisList(timerList: Timer[], startDay: Date, endDay: Date): (Devis | Action)[] {
        let list: (Devis | Action)[] = [];
        for (let timer of timerList) {
            if (
                list.find((e) => {
                    return (timer.object.get()?.getAjoIdentifier() ?? '') === e.getAjoIdentifier();
                }) === undefined
            ) {
                let start = timer.getStartDate().getTime();
                let end = timer.getEndDate().getTime();

                if (
                    (start < startDay.getTime() && end < startDay.getTime()) ||
                    (start > endDay.getTime() && end > endDay.getTime())
                ) {
                    continue;
                }

                if (
                    timer.object.get() != null &&
                    (timer.object.get() instanceof Devis || timer.object.get() instanceof Action)
                ) {
                    list.push(timer.object.get()! as Devis | Action);
                }
            }
        }
        return list;
    }

    public static getTimerByAny(timerList: Timer[], startDay: Date, endDay: Date, elem: Client | Devis | Action): Date {
        let res = 0;
        for (let timer of timerList) {
            if ((timer.object.get()?.getAjoIdentifier() ?? '') === elem.getAjoIdentifier()) {
                let startDate = timer.getStartDate();
                let endDate = timer.getEndDate();

                let start = startDate.getTime();
                let end = endDate.getTime();

                if (
                    (start < startDay.getTime() && end < startDay.getTime()) ||
                    (start > endDay.getTime() && end > endDay.getTime())
                ) {
                    continue;
                }

                if (start < startDay.getTime()) {
                    start = startDay.getTime();
                }

                if (end > endDate.getTime()) {
                    end = endDate.getTime();
                }

                res += end - start;
            }
        }
        return new Date(res);
    }

    public static getSectionByAny(
        timerList: Timer[],
        startDay: Date,
        endDay: Date,
        elem: Client | Devis | Action,
    ): Section[] {
        let list: Section[] = [];
        for (let timer of timerList) {
            if (
                (timer.object.get()?.getAjoIdentifier() ?? '') === elem.getAjoIdentifier() &&
                timer.section.get() != null
            ) {
                if (
                    list.find((e) => {
                        return (timer.section.get()?.getAjoIdentifier() ?? '') === e.getAjoIdentifier();
                    }) === undefined
                ) {
                    let start = timer.getStartDate().getTime();
                    let end = timer.getEndDate().getTime();

                    if (
                        (start < startDay.getTime() && end < startDay.getTime()) ||
                        (start > endDay.getTime() && end > endDay.getTime())
                    ) {
                        continue;
                    }

                    list.push(timer.section.get()!);
                }
            }
        }
        return list;
    }

    public static getTimerByAnySection(
        timerList: Timer[],
        startDay: Date,
        endDay: Date,
        elem: Client | Devis | Action,
        section: Section,
    ): Date {
        let res = 0;
        for (let timer of timerList) {
            if (
                (timer.object.get()?.getAjoIdentifier() ?? '') === elem.getAjoIdentifier() &&
                (timer.section.get()?.getAjoIdentifier() ?? '') === section.getAjoIdentifier()
            ) {
                let startDate = timer.getStartDate();
                let endDate = timer.getEndDate();

                let start = startDate.getTime();
                let end = endDate.getTime();

                if (
                    (start < startDay.getTime() && end < startDay.getTime()) ||
                    (start > endDay.getTime() && end > endDay.getTime())
                ) {
                    continue;
                }

                if (start < startDay.getTime()) {
                    start = startDay.getTime();
                }

                if (end > endDate.getTime()) {
                    end = endDate.getTime();
                }

                res += end - start;
            }
        }
        return new Date(res);
    }

    public static getTimerFull(
        timerList: Timer[],
        borneDown: Date,
        borneUp: Date,
        cond: (t: Timer) => boolean = () => true,
    ): Date {
        let res = 0;
        for (let timer of timerList) {
            if (!cond(timer)) {
                continue;
            }
            let startDate = timer.getStartDate();
            let endDate = timer.getEndDate();

            let start = startDate.getTime();
            let end = endDate.getTime();

            if (
                (start < borneDown.getTime() && end < borneDown.getTime()) ||
                (start > borneUp.getTime() && end > borneUp.getTime())
            ) {
                continue;
            }
            let change = false;

            if (start < borneDown.getTime()) {
                change = true;
                start = borneDown.getTime();
            }

            if (end > borneUp.getTime()) {
                change = true;
                end = borneUp.getTime();
            }

            if (!change) {
                res += timer.getDuration();
            } else {
                res += end - start;
            }
        }
        return new Date(res);
    }
}
