import { AJOList, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import AppManager from '../../commun/AppManager';
import { Button } from '../../commun/component/Button';
import { ComplexInput } from '../../commun/component/ComplexInput';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { get, post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import { ListComplexSelect } from '../../component/ListComplexSelect';
import { PaperView } from '../../component/paper/PaperView';
import { TagListFetch } from '../../component/TagListFetch';
import { TemplateList } from '../../component/TemplateList';
import { UserList } from '../../component/UserList';
import { Paper } from '../../object/Paper';
import Permission from '../../object/Permission';
import Prestation from '../../object/profile/Prestation';
import Vendor from '../../object/profile/Vendor';
import Template from '../../object/Template';
import User from '../../object/User';
import {
    categoryNeeded,
    clientAddSuccess,
    clientEditSuccess,
    errorManager,
    paperAddSuccess,
    prestationAddSuccess,
    vendorAddSuccess,
} from './CategoryErrorManager';

export interface IAddElemVendorModalProps {
    show: boolean;
    baseName?: string;
    mode: 'vendor' | 'prestation' | 'both';
    handleClose: () => void;
    onCreate?: (elam: Vendor | Prestation) => void;
}
export const AddElemVendorModal: React.FC<IAddElemVendorModalProps> = (props) => {
    const { handleClose, mode, baseName = '', show, onCreate = () => {} } = props;

    let baseFunc = 0;
    if (mode === 'vendor') {
        baseFunc = 0;
    } else if (mode === 'prestation') {
        baseFunc = 1;
    } else {
        baseFunc = 0;
    }

    const [name, updateName] = useState(baseName);
    const [func, updateFunc] = useState<0 | 1 | -1>(baseFunc as 0 | 1 | -1);
    const [error, updateError] = useState(ErrorHTTP.NO_ERROR());
    const [loading, updateLoad] = useState(false);

    const [categoryList, setCategoryList] = useState<any>([]);

    useEffect(() => {
        updateError(ErrorHTTP.NO_ERROR());
        updateLoad(false);
        updateName(baseName);
        updateFunc(baseFunc as 0 | 1 | -1);
    }, [show]);

    const paperPestation = new Paper();
    paperPestation.name.set('Fournisseur');
    paperPestation.func.set(0);
    paperPestation.default.set(false);

    const paperHonoraire = new Paper();
    paperHonoraire.name.set('Prestation');
    paperHonoraire.func.set(1);
    paperHonoraire.default.set(false);

    let list = [paperPestation, paperHonoraire];

    const select = (p: Paper) => {
        for (const elem of list) {
            elem.default.set(p === elem);
            if (p === elem) {
                updateFunc(elem.func.get());
            }
        }
    };

    // State for category list
    const [stateUserList, updateStateUserList] = useState(new AJOState<AJOList<User>>(new AJOList(User)));
    stateUserList.setUpdate(updateStateUserList);

    const [userList, updateUserList] = useState([] as User[]);
    const checkUser = (user: User) => {
        if (userList.find((elem) => elem.getAjoIdentifier() == user.getAjoIdentifier()) != undefined) {
            updateUserList(userList.filter((elem) => elem.getAjoIdentifier() != user.getAjoIdentifier()));
        } else {
            updateUserList([...userList, user]);
        }
    };

    const [checkTemplateList, updateCheckTemplateList] = useState([] as Template[]);
    const checkTemplate = (user: Template) => {
        updateCheckTemplateList([user]);
    };

    useEffect(() => {
        get('/user/list').then((res: any) => {
            if (Result.isSuccess(res)) {
                Result.applyResult(res, stateUserList.get()!, true);
            }
        });
    }, []);

    const onAdd = () => {
        updateError(ErrorHTTP.NO_ERROR());
        updateLoad(true);
        if (func === 0) {
            post('/vendor/update', {
                name: name,
            }).then((res: any) => {
                updateLoad(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, AppManager.updater(), true);
                    updateError(vendorAddSuccess);

                    let elem: any;
                    elem = new Vendor();
                    Result.applyResult(res, elem, true);
                    onCreate(elem);

                    AppManager.addToast(new Toast(errorManager.get(vendorAddSuccess), 'success'));
                    props.handleClose();
                } else {
                    updateError(Result.getError(res));
                }
            });
        } else {
            if (categoryList.length > 0) {
                post('/prestation/update', {
                    name: name,
                    category: [
                        {
                            id: categoryList[0].getAjoIdentifier(),
                        },
                    ],
                }).then((res: any) => {
                    updateLoad(false);
                    if (Result.isSuccess(res)) {
                        Result.applyResult(res, AppManager.updater(), true);
                        updateError(prestationAddSuccess);

                        let elem: any;
                        elem = new Prestation();
                        Result.applyResult(res, elem, true);
                        onCreate(elem);

                        AppManager.addToast(new Toast(errorManager.get(prestationAddSuccess), 'success'));
                        props.handleClose();
                    } else {
                        updateError(Result.getError(res));
                    }
                });
            } else {
                updateLoad(false);
                updateError(categoryNeeded);
            }
        }
    };

    let title;
    if (mode === 'vendor') {
        title = 'Nouveau fournisseur';
    } else if (mode === 'prestation') {
        title = 'Nouvelle prestation';
    } else {
        title = 'Nouvel élément dans la base fournisseur';
    }

    return (
        <Modal
            title={title}
            handleClose={handleClose}
            show={show}
            loading={loading}
            error={error}
            errorManager={errorManager}
            done="Ajouter"
            onDone={onAdd}
        >
            <ComplexInput
                placeholder={mode === 'vendor' ? 'Nom du fournisseur' : 'Nom de la prestation'}
                name="name_paper"
                value={name}
                setValue={updateName}
                typeValue="string"
            />
            {mode == 'both' && (
                <div className="grid grid-cols-2 gap-2">
                    <Button
                        border={2}
                        outline={paperPestation.func.get() !== func}
                        pb={1.5}
                        pt={1.5}
                        pr={2.5}
                        pl={2.5}
                        gap={1}
                        color={'red'}
                        onClick={() => select(paperPestation)}
                    >
                        <span>{paperPestation.name.get()}</span>
                    </Button>

                    <Button
                        border={2}
                        outline={paperHonoraire.func.get() !== func}
                        pb={1.5}
                        pt={1.5}
                        pr={2.5}
                        pl={2.5}
                        gap={1}
                        color={'violet'}
                        onClick={() => select(paperHonoraire)}
                    >
                        <span>{paperHonoraire.name.get()}</span>
                    </Button>
                </div>
            )}
            {func === 1 && (
                <TagListFetch
                    canAdd={AppManager.hasPermission(Permission.MANAGE_CATEGORY)}
                    onSelectList={setCategoryList}
                    selectList={categoryList}
                    simple={true}
                    field="category"
                />
            )}
        </Modal>
    );
};
