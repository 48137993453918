import { IObjectSelectable } from './../component/select/ObjectSelect';
import { AJOProperties, AJOSimple } from 'mp-js-react-auto-json-object';
import { AJOObject } from 'mp-js-react-auto-json-object';
import Address from './Address';
export class Agence extends AJOObject implements IObjectSelectable {
    public static _TYPE: string = 'Agence';

    public code: AJOProperties<any>;
    public siret: AJOProperties<any>;
    public code_ape: AJOProperties<any>;
    public email: AJOProperties<any>;
    public licence_de_voyage: AJOProperties<any>;
    public capital_social: AJOProperties<any>;
    public juridique_name: AJOProperties<any>;
    public display_name: AJOProperties<any>;
    public num: AJOProperties<any>;
    public address: AJOSimple<Address>;

    constructor() {
        super(Agence._TYPE);

        this.code = new AJOProperties('code', this);
        this.siret = new AJOProperties('siret', this);
        this.code_ape = new AJOProperties('code_ape', this);
        this.email = new AJOProperties('email', this);
        this.licence_de_voyage = new AJOProperties('licence_de_voyage', this);
        this.capital_social = new AJOProperties('capital_social', this);
        this.juridique_name = new AJOProperties('juridique_name', this);
        this.display_name = new AJOProperties('display_name', this);
        this.num = new AJOProperties('num', this);
        this.address = new AJOSimple(Address, 'has_address', this);
    }

    public static build(): Agence {
        return new Agence();
    }
    public getSelectTitle(): string {
        return this.code.get() ?? '';
    }
    public getSelectDescription(): string {
        return '';
    }
}
