import { Card } from '@mui/material';
import Category from '../../object/tag/Category';

export interface ISimpleCategoryListProps {
    list: Category[];
}

export const SimpleCategoryList = (props: ISimpleCategoryListProps) => {
    return (
        <div className="flex gap-2 flex-wrap">
            {props.list.map((category: Category, index: number) => {
                return (
                    <div
                        key={category.getAjoIdentifier()}
                        className={'rounded-lg px-2 py-1 bg-' + category.getColor() + '-600'}
                    >
                        <p className="text-sm text-white font-medium">{category.name.get()}</p>
                    </div>
                );
            })}
        </div>
    );
};
