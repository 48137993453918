import { AJOState } from 'mp-js-react-auto-json-object';
import { AJOProperties } from 'mp-js-react-auto-json-object';
import { Comptable } from '../Comptable';
import { TableRowInterface } from '../interface/TableRowInterface';
import { Paper } from '../Paper';
import { TagObject } from '../tag/TagObject';

export class MargeReduc extends TagObject implements TableRowInterface, Comptable {
    public Comptable = true;
    public TableRowInterface = true;

    public rowState: AJOState<MargeReduc> | null = null;
    public value: AJOProperties<any>;
    public func: AJOProperties<any>;
    public comment: AJOProperties<any>;

    constructor(type: string) {
        super(type);

        this.value = new AJOProperties('value', this);
        this.func = new AJOProperties('func', this);
        this.comment = new AJOProperties('comment', this);
    }

    public quantite(): number {
        return 0;
    }
    public prixUnitaire(): number {
        return 0;
    }
    public achat(): number {
        return 0;
    }
    public va(): number {
        return this.value.get();
    }
    public prixVenteHT(): number {
        let res = 1;
        if (this.func.get() === 0) {
            if (this.getAjoParent()?.getAjoParent() instanceof Paper) {
                let paper = this.getAjoParent()!.getAjoParent() as Paper;
                res = paper.prixVenteHTNoReducNoMarge() * (this.va() / 100);
            }
        } else {
            res = this.value.get();
        }
        return res;
    }
    public tax(): number {
        return 20;
    }
    public prixVenteTTC(): number {
        return this.prixVenteHT() * 1.2;
    }

    private _openDelete: boolean = false;
    public get openDelete(): boolean {
        return this._openDelete;
    }
    public set openDelete(value: boolean) {
        this._openDelete = value;
        this.rowState?.makeUpdate();
    }
}
