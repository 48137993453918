import { Box, Card, Typography } from '@mui/material';
import { AJOList, AJOState } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppManager from 'src/commun/AppManager';
import { get, post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import { PerfectTable } from 'src/commun/table/PerfectTable';
import Toast from 'src/commun/toast/Toast';
import Utils from 'src/commun/Utils';
import { BasePage } from 'src/component/BasePage';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';
import { TimerView } from 'src/component/TimerView';
import { Timer } from 'src/object/Timer';
import User from 'src/object/User';

export interface WeekWork {
    start: string;
    end: string;
    recup: number;
    recup_before: number;
    duration: number;
    recup_plus_moin: number;
    user: User;
    edit: boolean;
    started: boolean;
}

export const BoardPage = () => {
    const { id } = useParams();

    const [user, updateUser] = useState(new AJOState<User>(User.build()));
    user.setUpdate(updateUser);
    const [loading, setLoading] = useState(true);
    const [loadingRecup, setLoadingRecup] = useState(true);

    useEffect(() => {
        setLoading(true);
        get(
            '/timer/list',
            {
                id: id as any,
            },
            () => {},
            true,
        )
            .then((res) => {
                setLoading(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, user, true);
                    AppManager.makeUpdate();
                }
            })
            .catch((err) => {
                setLoading(false);
                AppManager.addToast(new Toast('Une erreur est survenue', 'error'));
            });
        post('/timer/recup', {
            id: id,
        })
            .then((res: any) => {
                setLoadingRecup(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, user, true);
                    AppManager.makeUpdate();
                }
            })
            .catch((err) => {
                setLoadingRecup(false);
            });
        /*post('/recup', {
            id_list: [id as any],
        }).then((res: any) => {
            setLoadingRecup(false)
            if (Result.isSuccess(res)) {
                Result.applyResult(res, user, true);
                AppManager.makeUpdate();
            }
        }).catch((err)=>{
            setLoadingRecup(false)
        })
            
        post('/recup_comming', {
            id_list: [id as any],
        }).then((res: any) => {
            setLoadingRecup(false)
            if (Result.isSuccess(res)) {
                Result.applyResult(res, user, true);
                AppManager.makeUpdate();
            }
        }).catch((err)=>{
            setLoadingRecup(false)
            AppManager.addToast(new Toast("Une erreur est survenue", "error"));  
        })*/
    }, []);

    return (
        <BasePage className="flex-row w-full justify-center gap-4">
            <DashboardNavBar />
            {!loading && !loadingRecup && (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 3,
                        mt: 3,
                        marginRight: 3,
                        marginLeft: 3,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        {user.get()!.getFullName() ?? ''}
                    </Typography>
                </Box>
            )}
            {!loading && !loadingRecup && (
                <Card
                    sx={{
                        mt: 3,
                        marginRight: 3,
                        marginLeft: 3,
                        p: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1.5,
                    }}
                >
                    <Typography>
                        Heure réalisé :{' '}
                        {
                            <TimerView
                                show={true}
                                canJour={true}
                                date={new Date(0)}
                                dateEnd={new Date(user.get()!.global_duration.get() * 1000)}
                            />
                        }
                    </Typography>
                    <Typography>
                        Recupération :{' '}
                        {
                            <TimerView
                                show={true}
                                date={new Date(0)}
                                dateEnd={new Date(user.get()!.global_recup.get() * 1000)}
                            />
                        }
                    </Typography>
                    <Typography>
                        Recupération posé :{' '}
                        {
                            <TimerView
                                show={true}
                                date={new Date(0)}
                                dateEnd={new Date(user.get()!.global_take_recup.get() * 1000)}
                            />
                        }
                    </Typography>
                    <Typography>
                        Recupération à venir :{' '}
                        {
                            <TimerView
                                show={true}
                                date={new Date(0)}
                                dateEnd={
                                    new Date(
                                        user.get()!.global_recup.get() * 1000 -
                                            user.get()!.global_take_recup.get() * 1000,
                                    )
                                }
                            />
                        }
                    </Typography>
                </Card>
            )}
            <Card sx={{ m: 3 }}>
                <PerfectTable
                    columns={[
                        {
                            name: 'Période',
                            sort: 1,
                            canSort: true,
                            funSort: (a: WeekWork, b: WeekWork) => {
                                return -new Date(a.start).getTime() + new Date(b.start).getTime();
                            },
                            render: (d: WeekWork) => {
                                const date = new Date(d.start);
                                // get the number of week without toLocaleDateString
                                const numWeek = Math.ceil(
                                    (date.getTime() - new Date(date.getFullYear(), 0, 1).getTime()) /
                                        (7 * 24 * 60 * 60 * 1000),
                                );
                                return (
                                    <Box>
                                        Semaine {numWeek} - {date.toLocaleDateString('fr')} -{' '}
                                        {new Date(date.getTime() + 6 * 24 * 60 * 60 * 1000).toLocaleDateString('fr')}
                                    </Box>
                                );
                            },
                        },
                        {
                            name: 'Contrat',
                            render: (d: WeekWork) => {
                                // get the number of week without toLocaleDateString
                                return <Box>35 h</Box>;
                            },
                        },
                        {
                            name: 'Total',
                            sort: 0,
                            canSort: true,
                            funSort: (a: WeekWork, b: WeekWork) => {
                                return -a.duration + b.duration;
                            },
                            render: (d: WeekWork) => {
                                return (
                                    <Box>
                                        <TimerView
                                            show={true}
                                            canJour={false}
                                            date={new Date(0)}
                                            dateEnd={new Date(d.duration * 1000)}
                                        />
                                    </Box>
                                );
                            },
                        },
                        {
                            name: 'Recup utilisé',
                            sort: 0,
                            canSort: true,
                            funSort: (a: WeekWork, b: WeekWork) => {
                                return -a.recup + b.recup;
                            },
                            render: (d: WeekWork) => {
                                // get the number of week without toLocaleDateString
                                if (user.get()?.start_calcul_recup.get() === undefined)
                                    return (
                                        <Box>
                                            <p>NON COMMENCÉ</p>
                                        </Box>
                                    );
                                if (d.started === false)
                                    return (
                                        <Box>
                                            <p>NON COMMENCÉ</p>
                                        </Box>
                                    );
                                return (
                                    <Box>
                                        <TimerView
                                            show={true}
                                            canJour={false}
                                            date={new Date(0)}
                                            dateEnd={new Date(d.recup * 1000)}
                                        />
                                    </Box>
                                );
                            },
                        },
                        {
                            name: '+/- Recup',
                            sort: 0,
                            funSort: (a: WeekWork, b: WeekWork) => {
                                return -a.recup_plus_moin + b.recup_plus_moin;
                            },
                            render: (d: WeekWork) => {
                                // get the number of week without toLocaleDateString
                                if (user.get()?.start_calcul_recup.get() === undefined)
                                    return (
                                        <Box>
                                            <p>NON COMMENCÉ</p>
                                        </Box>
                                    );
                                if (d.started === false)
                                    return (
                                        <Box>
                                            <p>NON COMMENCÉ</p>
                                        </Box>
                                    );
                                console.log('recup_plus_moin : ', d.recup_plus_moin);
                                return (
                                    <Box>
                                        <TimerView
                                            show={true}
                                            canJour={true}
                                            date={new Date(0)}
                                            dateEnd={new Date(d.recup_plus_moin * 1000)}
                                        />
                                    </Box>
                                );
                            },
                        },
                        {
                            name: 'Recup Total',
                            render: (d: WeekWork) => {
                                // get the number of week without toLocaleDateString
                                if (user.get()?.start_calcul_recup.get() === undefined)
                                    return (
                                        <Box>
                                            <p>NON COMMENCÉ</p>
                                        </Box>
                                    );
                                if (d.started === false)
                                    return (
                                        <Box>
                                            <p>NON COMMENCÉ</p>
                                        </Box>
                                    );
                                return (
                                    <Box>
                                        <TimerView
                                            show={true}
                                            canJour={true}
                                            date={new Date(0)}
                                            dateEnd={new Date(d.recup_before * 1000)}
                                        />
                                    </Box>
                                );
                            },
                        } /*,{
                    name: "STATUT",
                    render: (d:WeekWork) => {
                        // get the number of week without toLocaleDateString
                        if(d.edit){
                            return <Box className="flex">
                                <div
                                    className={'rounded-lg px-2 py-1 bg-emerald-600'}
                                >
                                    <p className="text-sm text-white font-medium">ÉDITABLE</p>
                                </div>
                            </Box>
                        }
                        else {
                            return <Box className="flex">
                                <div
                                    className={'rounded-lg px-2 py-1 bg-red-600'}
                                >
                                    <p className="text-sm text-white font-medium">NON ÉDITABLE</p>
                                </div>
                            </Box>
                        }
                    }
                }*/,
                    ]}
                    list={user.get()?.week_action.get() ?? []}
                    loading={loadingRecup || loading}
                />
            </Card>
        </BasePage>
    );
};
