import { Link } from 'react-router-dom';
import AppManager from '../commun/AppManager';
import User from '../object/User';

export function NavBarProfil() {
    let user = AppManager.getUser() as User;
    return (
        <Link to={'/me'}>
            <div className="rounded-xl border border-gray-200 dark:border-slate-500 pt-1 pb-1 pl-2 pr-2 dark:bg-gray-800 flex gap-3 items-center flex-rol h-full text-black dark:text-white">
                <div className="flex flex-col">
                    <div className="flex flex-row gap-2">
                        <span>{user.firstname.get()}</span>
                        <span>{user.lastname.get()}</span>
                    </div>
                </div>
            </div>
        </Link>
    );
}
