import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ComplexInput } from '../commun/component/ComplexInput';
import { Input } from '../commun/component/Input';
import { Button } from '../commun/component/Button';
import { Form } from '../commun/component/Form';
import { Content } from '../commun/component/Content';
import { get, post } from '../commun/HTTP';
import PageManager from '../commun/PageManager';
import { BasePage } from '../component/BasePage';
import Result from '../commun/Result';
import ErrorHTTP from '../commun/ErrorHTTP';
import { ErrorText } from '../commun/component/ErrorText';
import ErrorManager from '../commun/ErrorManager';
import { Spinner } from '../commun/component/Spinner';
import AppManager from '../commun/AppManager';
import User from '../object/User';
import ErrorMessage from '../commun/ErrorMessage';
import Toast from '../commun/toast/Toast';
const errorManager = new ErrorManager('user/update/password');

errorManager.addMessage(ErrorMessage.WRONG_PASSWORD());
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_ENTER());
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_MIN());
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_MAX());
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_TYPE());
errorManager.add(ErrorHTTP.NOT_MATCH(), 'Les nouveaux mots de passe ne correspondent pas.');
errorManager.add(
    ErrorHTTP.SUCCESS().setColor('green-600').setColorDark('green-300'),
    'Votre mot de passe a été modifié avec succès.',
);

export function PasswordPage({}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(ErrorHTTP.NO_ERROR());

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConf, setNewPasswordConf] = useState('');

    const onClick = (event: any) => {
        event.preventDefault();
        setError(ErrorHTTP.NO_ERROR());
        setLoading(true);
        post('/user/password/update', {
            old_password: oldPassword,
            new_password: newPassword,
            new_password_conf: newPasswordConf,
        }).then((res: any) => {
            setLoading(false);
            if (Result.isSuccess(res)) {
                setError(ErrorHTTP.SUCCESS());
                AppManager.addToast(new Toast('Votre mot de passe a été modifié avec succès.', 'success'));

                Result.applyResult(res, AppManager.getUser(), true);

                AppManager.navigate('/');
                window.location.reload();

                AppManager.makeUpdate();
            } else {
                setError(Result.getError(res));
            }
        });
    };

    let page = (
        <Form className="text-center w-4/5 max-w-md mb-8">
            <h1 className="text-4xl font-bold text-black dark:text-white">Mot de passe</h1>

            <h4 className="text-md font-medium text-black dark:text-white">
                Vous devez changer votre mot de passe pour continuer à accéder au service.
            </h4>

            <ComplexInput
                placeholder="Mot de passe actuel"
                name="password"
                value={oldPassword}
                typeValue="string"
                type="password"
                setValue={setOldPassword}
            ></ComplexInput>
            <ComplexInput
                placeholder="Nouveau mot de passe"
                name="new_password"
                value={newPassword}
                typeValue="string"
                type="password"
                setValue={setNewPassword}
            ></ComplexInput>
            <ComplexInput
                placeholder="Confirmer le nouveau mot de passe"
                name="new_password_conf"
                value={newPasswordConf}
                typeValue="string"
                type="password"
                setValue={setNewPasswordConf}
            ></ComplexInput>

            {loading && (
                <div className="flex items-center justify-center">
                    <Spinner color="purple"></Spinner>
                </div>
            )}

            <ErrorText topSpacing={1} manager={errorManager} error={error}></ErrorText>

            <div className="flex items-center justify-center">
                <div className="flex-1"></div>
                <Button onClick={onClick} selected={true} color="purple">
                    Changer
                </Button>
            </div>
        </Form>
    );
    return page;
}
