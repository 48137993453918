import { AJOList, AJOObject } from 'mp-js-react-auto-json-object';
import Address from './Address';
import Budget from './Budget';
import { Comptable } from './Comptable';
import Note from './Note';

export abstract class BudgetManager extends AJOObject implements Comptable {
    public Comptable = true;
    public budgetList: AJOList<any>;
    constructor(type: string, labelBudget: string) {
        super(type);
        this.budgetList = new AJOList<Budget>([Budget], labelBudget, this);
    }

    abstract getBudgetName(): string;

    public quantite(): number {
        let quantite = 0;
        for (let budget of this.budgetList.getList()) {
            if ('Comptable' in budget) {
                quantite += (budget as unknown as Comptable).quantite();
            }
        }
        if (!isFinite(quantite)) {
            quantite = 0;
        }
        return quantite;
    }
    public prixUnitaire(): number {
        let res = 0;
        for (let budget of this.budgetList.getList()) {
            if ('Comptable' in budget) {
                res += (budget as unknown as Comptable).achat();
            }
        }
        if (!isFinite(res)) {
            res = 0;
        }
        return res;
    }
    public abstract facteurPage(): number;
    public achat(): number {
        return this.prixUnitaire();
    }
    public va(): number {
        let res = 0;
        res = -100 + (this.prixVenteHT() * 100) / this.achat();
        if (!isFinite(res)) {
            res = 0;
        }
        return res;
    }
    public prixVenteHT(): number {
        let res = 0;
        for (let budget of this.budgetList.getList()) {
            if ('Comptable' in budget) {
                res += (budget as unknown as Comptable).prixVenteHT();
            }
        }
        if (!isFinite(res)) {
            res = 0;
        }
        return res;
    }
    public tax(): number {
        let res = 0;
        res = (this.prixVenteTTC() / this.prixVenteHT() - 1) * 100;
        if (!isFinite(res)) {
            res = 0;
        }
        return res;
    }
    public prixVenteTTC(): number {
        let res = 0;
        for (let budget of this.budgetList.getList()) {
            if ('Comptable' in budget) {
                res += (budget as unknown as Comptable).prixVenteTTC();
            }
        }
        if (!isFinite(res)) {
            res = 0;
        }
        return res;
    }

    public isAverageNote(): boolean {
        let count = 0;
        let i = 0;
        while (count == 0 && i < this.budgetList.getList().length) {
            let budget = this.budgetList.getList()[i];
            if (budget instanceof Budget) {
                if (budget.isAverageNote()) {
                    count++;
                }
            }
            i++;
        }
        return count > 0;
    }
    public getAverageNote(): number {
        let sum = 0;
        let count = 0;
        for (let budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                if (budget.isAverageNote()) {
                    sum += budget.getAverageNote();
                    count++;
                }
            }
        }
        return sum / count;
    }

    public getNoteList(): Note[] {
        let noteList: Note[] = [];
        for (let budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                for (let note of budget.noteList.getList()) {
                    noteList.push(note);
                }
            }
        }
        return noteList;
    }

    public getAddressList(): Address[] {
        let addressList: Address[] = [];
        for (let budget of this.budgetList.getList()) {
            if (budget instanceof Budget) {
                let budgetCast = budget as unknown as Budget;
                if (
                    budgetCast.address.get() != undefined &&
                    !addressList.find(
                        (c: Address) => budgetCast.address.get()!.getAjoIdentifier() === c.getAjoIdentifier(),
                    )
                ) {
                    addressList.push(budgetCast.address.get()!);
                }
            }
        }
        return addressList;
    }
}
