import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { Grid, Pagination } from '@mui/material';
import { PerfectTable } from '../../commun/table/PerfectTable';
import Utils from '../../commun/Utils';
import { AddIcon } from '../../icons/AddIcon';
import { SearchIcon } from '../../icons/SearchIcon';
import Category from '../../object/tag/Category';
import { BinIcon } from '../../icons/BinIcon';
import { EditIcon } from '../../icons/EditIcon';
import { SwitchIcon } from '../../icons/SwitchIcon';
import Product from 'src/object/tag/Product';
import { ProductModal } from 'src/modal/product/ProductModal';
import DelProductModal from './DelProductModal';

export interface IClientOffreViewProps {
    list: Product[];
    loading: boolean;
}

export const ClientOffreView = (props: IClientOffreViewProps) => {
    const [search, setSearch] = useState('');
    const [product, setProduct] = useState<Product | undefined>(undefined);

    const [addProductShow, setAddProductShow] = useState(false);
    const [delProductShow, setDelProductShow] = useState(false);
    const [fusionProductShow, setFusionProductShow] = useState(false);

    const list = props.list.filter((product: Product) => {
        return Utils.isSearch(search, product.name.get());
    });

    return (
        <Container maxWidth={false}>
            <Box sx={{ mb: 3, mt: 3 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        m: -1,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        Offre
                    </Typography>
                    <Box sx={{ m: 1 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            className="!pl-3 gap-2"
                            onClick={() => {
                                setProduct(undefined);
                                setAddProductShow(true);
                            }}
                        >
                            <AddIcon />
                            Ajouter une offre
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Card>
                        <CardContent className="w-full">
                            <Box className="flex w-full items-center flex-row">
                                <Box sx={{ maxWidth: 500 }}>
                                    <TextField
                                        fullWidth
                                        value={search}
                                        onChange={(e) => {
                                            setSearch((e.target as any).value);
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon color="action" fontSize="small">
                                                        <SearchIcon />
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder="Rechercher une offre"
                                        variant="outlined"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            <Box>
                <Grid container spacing={3}>
                    {list.map((value: Product, i: number) => {
                        return (
                            <Grid key={i} item lg={4} md={4} xs={6}>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    <CardContent
                                        className={
                                            'bg-' +
                                            (value.category.get()?.getColor() ?? '') +
                                            '-600 flex-1 flex items-center justify-center'
                                        }
                                    >
                                        <Box>
                                            <Typography className="text-white !text-lg py-5 !font-bold text-center">
                                                {value.name.get()}
                                            </Typography>
                                        </Box>
                                    </CardContent>

                                    <Box className="flex flex-row p-2 gap-2">
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className="!pl-3 flex-1 gap-2"
                                            onClick={() => {
                                                setProduct(value);
                                                setAddProductShow(true);
                                            }}
                                        >
                                            <EditIcon />
                                            Modifier
                                        </Button>
                                        <Button
                                            color="error"
                                            variant="contained"
                                            className="!pl-3 flex-1 gap-2"
                                            onClick={() => {
                                                setProduct(value);
                                                setDelProductShow(true);
                                            }}
                                        >
                                            <BinIcon />
                                            Supprimer
                                        </Button>
                                    </Box>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
            <ProductModal
                onObjectChange={(product: string) => {}}
                action={product ? 'edit' : 'add'}
                elem={product ?? new Product()}
                show={addProductShow}
                onClose={() => {
                    setAddProductShow(false);
                }}
            />
            {
                <DelProductModal
                    product={product ?? new Product()}
                    open={delProductShow}
                    onClose={() => {
                        setDelProductShow(false);
                    }}
                />
            }
        </Container>
    );
};
