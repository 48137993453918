import { Box, Typography } from '@mui/material';
import { Color } from '../../commun/Constant';
import { DateView } from '../../commun/DateView';
import { PerfectTable } from '../../commun/table/PerfectTable';
import Address from '../../object/Address';
import Budget from '../../object/Budget';
import Contact from '../../object/Contact';
import { AddressView } from '../AddressView';
import { ContactView } from '../ContactView';
import { NoteList } from '../NoteList';
import { NoteView } from '../note/NoteView';
import { Link } from 'react-router-dom';

export interface IBudgetTableProps {
    list: Budget[];
    loading: boolean;
    color: Color;
}

export const BudgetTable = (props: IBudgetTableProps) => {
    const { loading, color, list } = props;
    return (
        <PerfectTable
            loading={loading}
            list={list}
            color={props.color ?? 'white'}
            nothing={
                <Box className="flex flex-col gap-3 items-center justify-center">
                    <Typography>{'Aucun devis trouvé.'}</Typography>
                </Box>
            }
            columns={[
                {
                    canSort: true,
                    sort: 1,
                    funSort: (e1: Budget, e2: Budget) => {
                        return (e1.prestation.get()?.name.get() ?? '').localeCompare(
                            e2.prestation.get()?.name.get() ?? '',
                        );
                    },

                    name: 'Date',

                    render: (elem: Budget) => {
                        return <DateView date={new Date(elem.getDate() ?? '')} />;
                    },
                },
                {
                    name: 'Client',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Budget, e2: Budget) => {
                        return (e1.getDevis()?.client.get()?.name.get() ?? '').localeCompare(
                            e2.getDevis()?.client.get()?.name.get() ?? '',
                        );
                    },

                    render: (elem: Budget) => {
                        return (
                            <Link to={'/client/' + elem.getDevis()?.client.get()?.getAjoIdentifier()}>
                                <Typography>{elem.getDevis()?.client.get()?.name.get() ?? ''}</Typography>
                            </Link>
                        );
                    },
                },
                {
                    name: 'Prix de vente',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Budget, e2: Budget) => {
                        return -(e1.getDevis()?.name.get() ?? '').localeCompare(e2.getDevis()!.name.get());
                    },

                    render: (elem: Budget) => {
                        return (
                            <Link to={'/devis/' + elem.getDevis()?.getAjoIdentifier()}>
                                <Typography>{elem.getDevis()?.name.get() ?? ''}</Typography>
                            </Link>
                        );
                    },
                },
                {
                    name: 'Contact',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Budget, e2: Budget) => {
                        return -e1.getAverageNote() + e2.getAverageNote();
                    },

                    render: (elem: Budget) => {
                        return (
                            <ContactView
                                onDelete={() => {}}
                                canDelete={false}
                                contact={elem.contact.get() ?? new Contact()}
                            />
                        );
                    },
                },
                {
                    name: 'Adresse',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Budget, e2: Budget) => {
                        return -e1.getNoteList().length + e2.getNoteList().length;
                    },

                    render: (elem: Budget) => {
                        return (
                            <AddressView
                                canEdit={false}
                                onDelete={() => {}}
                                canDelete={false}
                                address={elem.getAddress() ?? new Address()}
                            />
                        );
                    },
                },
                {
                    name: 'Note',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Budget, e2: Budget) => {
                        return -e1.getAverageNote() + e2.getAverageNote();
                    },

                    render: (elem: Budget) => {
                        return <NoteView note={elem.getAverageNote()} />;
                    },
                },
                {
                    name: 'Commentaire',

                    canSort: true,
                    sort: 0,
                    funSort: (e1: Budget, e2: Budget) => {
                        return -e1.getNoteList().length + e2.getNoteList().length;
                    },

                    render: (elem: Budget) => {
                        return <NoteList noteList={elem.getNoteList()} />;
                    },
                },
            ]}
        />
    );
};
