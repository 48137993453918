import { Box, Typography } from '@mui/material';
import { AJOElement, AJOList, AJOObject, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { Spinner } from 'src/commun/component/Spinner';
import MpModal from 'src/component/extends/MpModal';
import AppManager from '../../commun/AppManager';
import { ComplexSelect } from '../../commun/component/ComplexSelect';
import { ModalBottomMode } from '../../commun/Constant';
import ErrorHTTP from '../../commun/ErrorHTTP';
import ErrorManager from '../../commun/ErrorManager';
import ErrorMessage from '../../commun/ErrorMessage';
import { get, post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import Utils from '../../commun/Utils';
import Category from '../../object/tag/Category';
import Label from '../../object/tag/Label';
import Product from '../../object/tag/Product';
import Template from '../../object/Template';
import {
    categoryDeleteSuccess,
    errorManager,
    labelDeleteSuccess,
    productDelSuccess,
    templateDelSuccess,
    templateRemoveSuccess,
} from './CategoryErrorManager';

export interface IAddCategoryModalProps {
    handleClose: () => void;
    parent?: AJOElement;
    elem: AJOObject;
    show: boolean;
    title: string;
    field: string;

    textDelete: string;
    successError: ErrorHTTP;
}

export const RemoveCategoryModal: React.FC<IAddCategoryModalProps> = (props) => {
    const { handleClose, textDelete, field, title, elem, show, parent, successError } = props;

    const [loading, updateLoad] = useState(false);
    const [error, updateError] = useState(ErrorHTTP.NO_ERROR());

    useEffect(() => {
        updateError(ErrorHTTP.NO_ERROR());
    }, [show]);

    const onDelCategory = () => {
        updateLoad(true);
        updateError(ErrorHTTP.NO_ERROR());
        if (parent != undefined) {
            const params: any = {
                id: parent.getAjoIdentifier(),
            };
            if (field === 'marge') {
                params[field] = {
                    id_del: elem.getAjoIdentifier(),
                    full_delete: true,
                };
            } else {
                params[field] = {
                    id_del: elem.getAjoIdentifier(),
                };
            }
            post('/' + parent.getAjoType().toLowerCase() + '/update', params).then((res: any) => {
                updateLoad(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, parent, true);
                    updateError(successError);

                    AppManager.addToast(new Toast(errorManager.get(successError), 'success'));
                    props.handleClose();
                } else {
                    updateError(Result.getError(res));
                }
            });
        } else {
            post('/' + field + '/update', {
                id_del: elem.getAjoIdentifier(),
                full_delete: true,
            }).then((res: any) => {
                updateLoad(false);
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, AppManager.updater(), true);
                    if (elem instanceof Category) {
                        updateError(categoryDeleteSuccess);

                        AppManager.addToast(new Toast(errorManager.get(categoryDeleteSuccess), 'success'));
                        props.handleClose();
                    } else if (elem instanceof Label) {
                        updateError(labelDeleteSuccess);

                        AppManager.addToast(new Toast(errorManager.get(labelDeleteSuccess), 'success'));
                        props.handleClose();
                    } else if (elem instanceof Product) {
                        updateError(productDelSuccess);

                        AppManager.addToast(new Toast(errorManager.get(productDelSuccess), 'success'));
                        props.handleClose();
                    } else if (elem instanceof Template) {
                        updateError(productDelSuccess);

                        AppManager.addToast(new Toast(errorManager.get(templateDelSuccess), 'success'));
                        props.handleClose();
                    }
                } else {
                    updateError(Result.getError(res));
                }
            });
        }
    };
    /*
    
loading={loading}
            error={error}
            errorManager={errorManager} */
    return (
        <MpModal
            onSubmit={onDelCategory}
            open={show}
            done={'Supprimer'}
            title={title}
            doneColor={'failure'}
            onClose={() => {
                handleClose();
                updateError(ErrorHTTP.NO_ERROR());
            }}
        >
            {loading ? (
                <Box className="flex items-center justify-center py-12">
                    <Spinner color="blue" className="" />
                </Box>
            ) : (
                <Box className="flex flex-col !text-gray-600 gap-2">
                    <Typography className="!text-sm !font-semibold">{textDelete}</Typography>
                    {!error.equals(ErrorHTTP.NO_ERROR()) && (
                        <Typography
                            component={'p'}
                            className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                        >
                            {errorManager.get(error)}
                        </Typography>
                    )}
                </Box>
            )}
        </MpModal>
    );
};
