import { AJOObject } from 'mp-js-react-auto-json-object';
import { Link } from 'react-router-dom';
import { AccordeonTable } from '../../component/AccordeonTable';
import { DoubleDateModal } from '../../modal/vendor/DoubleDateModal';
import { Comptable } from '../../object/Comptable';
import { EuroSpan } from '../component/EuroSpan';
import { Col } from '../table/Col';
import Table from '../table/Table';

export class QuantiteCol<Type extends AJOObject> extends Col<Type> {
    constructor(table: Table<Type>, get: (elem: Type) => AJOObject = (elem) => elem, name: string = 'Quant') {
        super(name);
        this.render = (elem: Type) => {
            if ('Comptable' in get(elem)) {
                let inter = get(elem) as unknown as Comptable;
                let res;
                res = <span>{inter.quantite()}</span>;
                return res;
            } else {
                throw new Error('Comptable not found in elem');
            }
        };
        this.sort = (a: Type, b: Type) => {
            if ('Comptable' in get(a) && 'Comptable' in get(b)) {
                let aInter = get(a) as unknown as Comptable;
                let bInter = get(b) as unknown as Comptable;
                if (isNaN(bInter.quantite())) {
                    return -1;
                } else if (isNaN(aInter.quantite())) {
                    return 1;
                } else {
                    return bInter.quantite() - aInter.quantite();
                }
            } else {
                throw new Error('Comptable not found in a or b');
            }
        };
        this.type = 'sort';
        this.typeRender = 'stat';
        this.senseSort = 0;
        this.table = table;
    }
}
