import { AJOList } from 'mp-js-react-auto-json-object';
import React from 'react';
import AppManager from '../commun/AppManager';
import { COLOR_LIST_TAB } from '../commun/Constant';
import { get, post } from '../commun/HTTP';
import Result from '../commun/Result';
import Utils from '../commun/Utils';
import { TimerModal } from '../modal/TimerModal';
import Devis from '../object/Devis';
import { Jour } from '../object/Jour';
import Client from '../object/profile/Client';
import { Section } from '../object/Section';
import { Timer } from '../object/Timer';
import { Action } from '../object/Action';
import User from '../object/User';
import { LittleAddButton } from './LittleAddButton';
import { TimerCalendarView } from './TimerCalendarView';
import { TimerView } from './TimerView';
import Toast from '../commun/toast/Toast';
import { cantEditAcceptHoraire, errorManager } from '../modal/vendor/CategoryErrorManager';
import { RecapTimerView } from './horaire/RecapTimerView';
import { Spinner } from '../commun/component/Spinner';
import { JSXInternal } from 'preact/src/jsx';
import { DashboardNavBar } from './nav/DashboardNavBar';
import { Box } from '@mui/material';

interface ICalendarProps {
    id: string;
    date?: Date;
}
interface ICalendarState {
    date: Date;
    diff: number;
    loading: boolean;
    time: number;

    user: User;

    width: number;
    height: number;
    recupTotal: number;

    showAddTimer: boolean;
    dateAddTimer: Date | null;
    startAddTimer: Date | null;
    endAddTimer: Date | null;
    type: 'add' | 'recup' | 'cp';
}

export class CalendarView extends React.Component<ICalendarProps, ICalendarState> {
    _ismounted = false;

    dateList = [] as string[];

    listRef: React.RefObject<HTMLTableDataCellElement>[] = [];
    lineRef: React.RefObject<HTMLTableRowElement>[] = [];
    tbodyRef: React.RefObject<HTMLTableSectionElement>;

    constructor(props: ICalendarProps) {
        super(props);

        this.state = this.initState();

        this.fetch();

        this.tbodyRef = React.createRef<HTMLTableSectionElement>();

        this.listRef.push(React.createRef<HTMLTableDataCellElement>());
        this.listRef.push(React.createRef<HTMLTableDataCellElement>());
        this.listRef.push(React.createRef<HTMLTableDataCellElement>());
        this.listRef.push(React.createRef<HTMLTableDataCellElement>());
        this.listRef.push(React.createRef<HTMLTableDataCellElement>());
        this.listRef.push(React.createRef<HTMLTableDataCellElement>());
        this.listRef.push(React.createRef<HTMLTableDataCellElement>());

        for (let i = 0; i < 24; i++) {
            this.lineRef.push(React.createRef<HTMLTableRowElement>());
        }
    }

    initState(): ICalendarState {
        return {
            date: this.props.date ?? new Date(),
            time: this.props.date?.getTime() ?? Date.now(),
            diff: 0,
            loading: true,
            user: new User(),

            width: 0,
            height: 0,
            recupTotal: 0,

            showAddTimer: false,
            dateAddTimer: null,
            startAddTimer: null,
            endAddTimer: null,
            type: 'add',
        };
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    clickAnyWhere = () => {
        for (let timer of this.state.user.timerList.getList()) {
            timer.setShow(false, 0, 0);
        }
        AppManager.makeUpdate();
    };

    public interval: NodeJS.Timer | null = null;

    componentDidMount(): void {
        this._ismounted = true;
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000 * 60);

        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('scroll', this.clickAnyWhere, true);

        this.lineRef[6].current?.scrollIntoView();

        post('/timer/recup', {
            id: this.props.id,
        }).then((res: any) => {
            if (Result.isSuccess(res)) {
                Result.applyResult(res, this.state.user, true);
                AppManager.makeUpdate();
            }
        });
        /*post('/recup_comming', {
            id_list: [this.props.id],
        }).then((res: any) => {
            if (Result.isSuccess(res)) {
                Result.applyResult(res, this.state.user, true);
                AppManager.makeUpdate();
            }
        });*/
    }
    componentWillUnmount(): void {
        this._ismounted = false;
        if (this.interval != null) {
            clearInterval(this.interval);
        }
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('scroll', this.clickAnyWhere, true);
    }

    getTimerList(): Timer[] {
        return this.state.user.timerList.getList();
    }

    onMouseMove(e: JSXInternal.TargetedMouseEvent<HTMLTableSectionElement>) {
        if (this.canEdit()) {
            for (let timer of this.getTimerList()) {
                if (timer.onMouseMove != null) {
                    timer.onMouseMove(e);
                }
            }
        }
    }

    onMouseUp(e: Event) {
        let timerList = this.getTimerList();

        for (let timer of timerList) {
            if (timer.endEdit.get() !== undefined || timer.startEdit.get() !== undefined) {
                let can = true;

                let startDateChange = timer.getStartDate();
                let endDateChange = timer.getEndDate();

                for (let timerEdit of timerList) {
                    if (timerEdit.getAjoIdentifier() !== timer.getAjoIdentifier()) {
                        let startDate = timerEdit.getStartDate();
                        let endDate = timerEdit.getEndDate();

                        let startBetween =
                            startDate.toISOString() >= startDateChange.toISOString() &&
                            startDate.toISOString() <= endDateChange.toISOString();

                        let endBetween =
                            endDate.toISOString() >= startDateChange.toISOString() &&
                            endDate.toISOString() <= endDateChange.toISOString();

                        // if end date is between startDateChange and endDateChange and
                        // if start date is between startDateChange and endDateChange
                        if (startBetween && endBetween) {
                            can = can && !timerEdit.isValidate();
                        }
                        // if end date is between startDateChange and endDateChange
                        else if (endBetween) {
                            can = can && !timerEdit.isValidate();
                        }
                        // if start date is between startDateChange and endDateChange
                        else if (startBetween) {
                            can = can && !timerEdit.isValidate();
                        }
                    }
                }
                if (can) {
                    let make = timer.restartAction(this.state.user);
                    if (make) {
                        for (let timerEdit of timerList) {
                            if (timerEdit.getAjoIdentifier() !== timer.getAjoIdentifier()) {
                                let startDate = timerEdit.getStartDate();
                                let endDate = timerEdit.getEndDate();

                                let startBetween =
                                    startDate.toISOString() >= startDateChange.toISOString() &&
                                    startDate.toISOString() <= endDateChange.toISOString();

                                let endBetween =
                                    endDate.toISOString() >= startDateChange.toISOString() &&
                                    endDate.toISOString() <= endDateChange.toISOString();

                                // if end date is between startDateChange and endDateChange and
                                // if start date is between startDateChange and endDateChange
                                if (startBetween && endBetween) {
                                    this.state.user.timerList.setList(
                                        timerList.filter((timer) => {
                                            return timer.getAjoIdentifier() !== timerEdit.getAjoIdentifier();
                                        }),
                                    );
                                }
                                // if end date is between startDateChange and endDateChange
                                else if (endBetween) {
                                    timerEdit.end.set(startDateChange.toISOString());
                                    timerEdit.endEdit.set(undefined);
                                }
                                // if start date is between startDateChange and endDateChange
                                else if (startBetween) {
                                    timerEdit.start.set(endDateChange.toISOString());
                                    timerEdit.startEdit.set(undefined);
                                }
                            }
                        }
                    }
                } else {
                    timer.reset();
                    AppManager.addToast(new Toast(errorManager.get(cantEditAcceptHoraire), 'error'));
                }
            }
        }
        AppManager.makeUpdate();
    }
    fetch() {
        let date = new Date(new Date(this.state.date).getTime() + this.state.diff * 24 * 60 * 60 * 1000 * 7);

        if (!this.dateList.includes(date.toISOString())) {
            this.dateList.push(date.toISOString());
        }
        let diff = -date.getDay() + 0 + 1;

        let monday = new Date(date.getTime() + diff * 24 * 60 * 60 * 1000);

        let params = {
            date: monday.getFullYear() + '-' + (monday.getMonth() + 1) + '-' + monday.getDate(),
            id: this.props.id,
        };
        this.state.user.closedList.setList([]);
        get('/timer/week', params, () => {}, true).then((res: any) => {
            if (Result.isSuccess(res)) {
                this.state.user.closedList.setList([]);
                Result.applyResult(res, this.state.user, true);
                if (this._ismounted) {
                    this.setState({
                        loading: false,
                    });
                }

                AppManager.makeUpdate();
            }
        });
    }
    closeAll() {
        for (let i = 0; i < 7; i++) {
            for (let timer of this.state.user.timerList.getList()) {
                timer.setShow(false, i, 0);
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<ICalendarProps>, prevState: Readonly<ICalendarState>, snapshot?: any): void {
        if (prevState.diff !== this.state.diff) {
            this.fetch();
            this.setState({ width: window.innerWidth, height: window.innerHeight });
        }
    }

    getStartDayWeek(): Date {
        let date = new Date(new Date(this.state.date).getTime() + this.state.diff * 24 * 60 * 60 * 1000 * 7);

        return Utils.getMonday(date);
    }

    getEndDayWeek(): Date {
        let date = new Date(new Date(this.state.date).getTime() + this.state.diff * 24 * 60 * 60 * 1000 * 7);

        let diff = this.getDiff(date, 0);

        let monday = new Date(date.getTime() + diff * 24 * 60 * 60 * 1000);
        let sunday = new Date(monday.getTime() + 7 * 24 * 60 * 60 * 1000);

        sunday.setHours(0);
        sunday.setMinutes(0);
        sunday.setSeconds(0);
        sunday.setMilliseconds(0);

        return sunday;
    }

    getDiff(date: Date, index: number): number {
        let diff = 0;
        if (date.getDay() === 0) {
            if (index === 6) {
                diff = 0;
            } else {
                diff = -6 + index;
            }
        } else {
            diff = -date.getDay() + index + 1;
        }
        return diff;
    }

    currentTimerList(): Timer[] {
        let timerList = [] as Timer[];
        for (let timer of this.getTimerList()) {
            let startDate = timer.getStartDate();
            let endDate = timer.getEndDate();

            let start = startDate.getTime();
            let end = endDate.getTime();

            if (
                (start < this.getStartDayWeek().getTime() && end < this.getStartDayWeek().getTime()) ||
                (start > this.getEndDayWeek().getTime() && end > this.getEndDayWeek().getTime())
            ) {
                continue;
            }

            timerList.push(timer);
        }
        return timerList;
    }

    getContrat(): Date {
        return new Date((this.state.user.nb_heure.get() ?? 0) * 1000 * 60 * 60);
    }

    getBetweenStartTimer(start: Date, end: Date): Date {
        let date: Date | null = null;

        let startFinal = new Date(start.getTime());
        let endFinal = new Date(end.getTime());

        for (let timer of this.currentTimerList()) {
            if (
                startFinal.toISOString() <= timer.getEndDate().toISOString() &&
                endFinal.toISOString() >= timer.getEndDate().toISOString()
            ) {
                if (date == null) {
                    date = timer.getEndDate();
                } else if (date.toISOString() > timer.getEndDate().toISOString()) {
                    date = timer.getEndDate();
                }
            }
        }
        return new Date((date ?? start).getTime());
    }

    getBetweenEndTimer(start: Date, end: Date): Date {
        let date: Date | null = null;

        let startFinal = new Date(start.getTime());
        let endFinal = new Date(end.getTime());

        for (let timer of this.currentTimerList()) {
            if (
                startFinal.toISOString() <= timer.getStartDate().toISOString() &&
                endFinal.toISOString() >= timer.getStartDate().toISOString()
            ) {
                if (date == null) {
                    date = timer.getStartDate();
                } else if (date.toISOString() > timer.getStartDate().toISOString()) {
                    date = timer.getStartDate();
                }
            }
        }
        return new Date((date ?? end).getTime());
    }

    getMonth(month: number) {
        switch (month) {
            case 0:
                return 'Janvier';
            case 1:
                return 'Février';
            case 2:
                return 'Mars';
            case 3:
                return 'Avril';
            case 4:
                return 'Mai';
            case 5:
                return 'Juin';
            case 6:
                return 'Juillet';
            case 7:
                return 'Août';
            case 8:
                return 'Septembre';
            case 9:
                return 'Octobre';
            case 10:
                return 'Novembre';
            case 11:
                return 'Décembre';
        }
    }

    getUserText(): string {
        let name = '';
        if (!this.state.loading) {
            name = this.state.user.getFullName() + ' - ';
        }
        return name;
    }

    render() {
        AppManager.startUpdater();
        AppManager.updater().add(this.state.user);
        const DAY_LIST = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
        const COUNT_CELL = 24;
        let date = new Date(new Date(this.state.date).getTime() + this.state.diff * 24 * 60 * 60 * 1000 * 7);
        let obj = this;

        return (
            <div className="relative">
                <DashboardNavBar />
                <Box
                    className={' relative pr-3 pb-3 pl-3 flex bg-slate-200 gap-4 flex-row w-full items-start h-full'}
                    onClick={(e: any) => {
                        this.clickAnyWhere();
                    }}
                >
                    <table className=" select-none w-full flex-1 border-collapse table-fixed rounded-xl ">
                        <thead className="sticky z-[7] top-0 ">
                            <tr className="bg-slate-200 h-16">
                                <th colSpan={9}></th>
                            </tr>
                            <tr className="bg-slate-200">
                                <th colSpan={9}>
                                    <div className="flex flex-row w-full items-center bg-slate-200 pt-1 pb-2">
                                        <button
                                            className="bg-white block rounded-lg pl-6 pr-6 pt-2 pb-2 border border-slate-400"
                                            onClick={() => {
                                                obj.setState({
                                                    diff: 0,
                                                });
                                            }}
                                        >
                                            <p>Aujourd'hui</p>
                                        </button>

                                        <div className="flex-1"></div>

                                        <p className="font-medium text-lg">
                                            {this.getUserText()}
                                            {Utils.getCurrentWeek(this.getStartDayWeek())}
                                        </p>
                                    </div>
                                </th>
                            </tr>
                            <tr className=" ">
                                <th
                                    className="bg-clip-padding bg-white border-slate-200 border-r with-14-force cursor-pointer rounded-t-l-xl"
                                    onClick={() => {
                                        this.setState({
                                            diff: this.state.diff - 1,
                                        });
                                    }}
                                >
                                    <div className="flex items-center justify-center text-slate-600">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                </th>
                                {DAY_LIST.map((day, index) => {
                                    let diff: number = this.getDiff(date, index);

                                    let finalDate = new Date(date.getTime() + diff * 24 * 60 * 60 * 1000);
                                    let dateCC = new Date();

                                    let badge;
                                    if (
                                        diff === 0 &&
                                        dateCC.getDate() === finalDate.getDate() &&
                                        dateCC.getMonth() === finalDate.getMonth() &&
                                        dateCC.getFullYear() === finalDate.getFullYear()
                                    ) {
                                        badge = (
                                            <div className="bg-clip-padding bg-blue-500 pl-2 pt-0.5 pb-0.5 pr-2 rounded-full text-white">
                                                {finalDate.getDate()}
                                            </div>
                                        );
                                    } else {
                                        badge = <div>{finalDate.getDate()}</div>;
                                    }
                                    return (
                                        <th
                                            className=" bg-clip-padding p-3  border-slate-200 border-r bg-white"
                                            onClick={() => {
                                                this.closeAll();
                                                if (this.canEdit()) {
                                                    finalDate.setHours(0);
                                                    finalDate.setMinutes(0);
                                                    finalDate.setSeconds(0);
                                                    finalDate.setMilliseconds(0);
                                                    this.setState({
                                                        showAddTimer: true,
                                                        dateAddTimer: finalDate,
                                                    });
                                                }
                                            }}
                                            key={index}
                                        >
                                            <h2 className="flex flex-row items-center gap-2 justify-center">
                                                <span className="font-medium text-sm text-slate-600">{day}</span>
                                                {badge}
                                            </h2>
                                            <TimerView
                                                date={new Date(0)}
                                                canJour={false}
                                                dateEnd={
                                                    new Date(this.state.user.getHeureDay(finalDate) * 60 * 60 * 1000)
                                                }
                                            />
                                        </th>
                                    );
                                })}
                                <th
                                    className="with-14-force bg-clip-padding cursor-pointer bg-white"
                                    onClick={() => {
                                        this.closeAll();
                                        this.setState({
                                            diff: this.state.diff + 1,
                                        });
                                    }}
                                >
                                    <div className="flex items-center justify-center text-slate-600">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                </th>
                            </tr>
                            <tr className="h-[0.1rem] bg-clip-padding w-full absolute bg-slate-200"></tr>
                        </thead>
                        <tbody
                            ref={this.tbodyRef}
                            onMouseMove={(e) => {
                                this.onMouseMove(e);
                            }}
                            onMouseUp={(e) => {
                                this.onMouseUp(e);
                            }}
                            className="h-96 relative"
                        >
                            <tr className="bg-slate-200">
                                <td
                                    colSpan={9}
                                    onMouseDown={(e) => {
                                        e.stopPropagation();
                                    }}
                                    onMouseMove={(e) => {
                                        e.stopPropagation();
                                    }}
                                    onMouseUp={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <TimerModal
                                        user={this.state.user}
                                        startDate={this.state.dateAddTimer}
                                        endDate={this.state.dateAddTimer}
                                        startHour={this.state.startAddTimer}
                                        endHour={this.state.endAddTimer}
                                        timer={null}
                                        action="add"
                                        handleClose={() => {
                                            this.setState({
                                                showAddTimer: false,
                                                dateAddTimer: null,
                                                startAddTimer: null,
                                                endAddTimer: null,
                                            });
                                        }}
                                        show={this.state.showAddTimer}
                                    />
                                </td>
                            </tr>

                            {Array.from(Array(COUNT_CELL).keys()).map((indexHour) => {
                                return (
                                    <tr className=" " key={indexHour} ref={this.lineRef[indexHour]}>
                                        <td className=" bg-clip-padding h-10 border-slate-200 border-r bg-white w-14 relative">
                                            {indexHour != 23 && (
                                                <span className="absolute text-right pr-2 w-full bottom-[-0.625rem] z-[5] font-medium text-sm text-slate-600">
                                                    {indexHour + 1}:00
                                                </span>
                                            )}
                                        </td>
                                        {DAY_LIST.map((day, index) => {
                                            let res;

                                            let diff = this.getDiff(date, index);

                                            let finalDate = new Date(date.getTime() + diff * 24 * 60 * 60 * 1000);
                                            finalDate.setHours(0);
                                            finalDate.setMinutes(0);
                                            finalDate.setSeconds(0);
                                            finalDate.setMilliseconds(0);

                                            let startDate = new Date(finalDate.getTime() + indexHour * 60 * 60 * 1000);
                                            let endDate = new Date(
                                                finalDate.getTime() + (indexHour + 1) * 60 * 60 * 1000,
                                            );

                                            const onDoubleClick = (i: number) => {
                                                this.closeAll();

                                                if (i == -1) {
                                                    startDate = new Date(
                                                        finalDate.getTime() + indexHour * 60 * 60 * 1000,
                                                    );
                                                    endDate = new Date(
                                                        finalDate.getTime() + (indexHour + 1) * 60 * 60 * 1000,
                                                    );
                                                } else {
                                                    startDate = new Date(
                                                        finalDate.getTime() + (indexHour + i * 0.25) * 60 * 60 * 1000,
                                                    );
                                                    endDate = new Date(
                                                        finalDate.getTime() +
                                                            (indexHour + 1 + i * 0.25) * 60 * 60 * 1000,
                                                    );
                                                }
                                                if (this.canEdit()) {
                                                    if (this.state.user.copyTimer == null) {
                                                        if (
                                                            this.getBetweenStartTimer(
                                                                startDate,
                                                                endDate,
                                                            ).toISOString() >
                                                            this.getBetweenEndTimer(startDate, endDate).toISOString()
                                                        ) {
                                                            this.setState({
                                                                showAddTimer: true,
                                                                dateAddTimer: finalDate,
                                                                startAddTimer: startDate,
                                                                endAddTimer: endDate,
                                                            });
                                                        } else {
                                                            this.setState({
                                                                showAddTimer: true,
                                                                dateAddTimer: finalDate,
                                                                startAddTimer: this.getBetweenStartTimer(
                                                                    startDate,
                                                                    endDate,
                                                                ),
                                                                endAddTimer: this.getBetweenEndTimer(
                                                                    startDate,
                                                                    endDate,
                                                                ),
                                                            });
                                                        }
                                                    } else {
                                                        AppManager.addToast(
                                                            new Toast('Duplication en cours ...', 'info'),
                                                        );
                                                        let end = new Date(
                                                            startDate.getTime() +
                                                                this.state.user.copyTimer.getDuration(),
                                                        );
                                                        post('/timer/edit', {
                                                            force: true,
                                                            type: this.state.user.copyTimer.getTypeStr(),
                                                            start: startDate.toISOString(),
                                                            end: end.toISOString(),
                                                            id_user: this.state.user.getAjoIdentifier(),
                                                            id_object:
                                                                this.state.user.copyTimer.object
                                                                    .get()
                                                                    ?.getAjoIdentifier() ?? '',
                                                            id_section:
                                                                this.state.user.copyTimer.section
                                                                    .get()
                                                                    ?.getAjoIdentifier() ?? '',
                                                        }).then((res: any) => {
                                                            if (Result.isSuccess(res)) {
                                                                Result.applyResult(res, AppManager.updater(), true);
                                                                this.state.user.copyTimer = null;
                                                                AppManager.addToast(
                                                                    new Toast(
                                                                        "L'horaire a été ajouté avec succès.",
                                                                        'success',
                                                                    ),
                                                                );
                                                            }
                                                        });
                                                    }
                                                }
                                            };

                                            if (indexHour === 0) {
                                                res = (
                                                    <td
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            onDoubleClick(-1);
                                                        }}
                                                        ref={this.listRef[index]}
                                                        key={index}
                                                        className=" bg-clip-padding border-slate-200 border-r border-b bg-white flex-1 h-12"
                                                    >
                                                        <div
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onDoubleClick(0);
                                                            }}
                                                            className="w-full h-1/4"
                                                        ></div>
                                                        <div
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onDoubleClick(1);
                                                            }}
                                                            className="w-full h-1/4"
                                                        ></div>
                                                        <div
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onDoubleClick(2);
                                                            }}
                                                            className="w-full h-1/4"
                                                        ></div>
                                                        <div
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onDoubleClick(3);
                                                            }}
                                                            className="w-full h-1/4"
                                                        ></div>
                                                    </td>
                                                );
                                            } else {
                                                res = (
                                                    <td
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            onDoubleClick(-1);
                                                        }}
                                                        key={index}
                                                        className=" bg-clip-padding border-slate-200 border-r border-b bg-white flex-1 h-12"
                                                    >
                                                        <div
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onDoubleClick(0);
                                                            }}
                                                            className="w-full h-1/4"
                                                        ></div>
                                                        <div
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onDoubleClick(1);
                                                            }}
                                                            className="w-full h-1/4"
                                                        ></div>
                                                        <div
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onDoubleClick(2);
                                                            }}
                                                            className="w-full h-1/4"
                                                        ></div>
                                                        <div
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onDoubleClick(3);
                                                            }}
                                                            className="w-full h-1/4"
                                                        ></div>
                                                    </td>
                                                );
                                            }
                                            return res;
                                        })}
                                        <td className="bg-clip-padding h-12 w-14 relative border-slate-200 border-r bg-white ">
                                            {indexHour != 23 && (
                                                <span className="absolute text-left pl-2 w-full bottom-[-0.625rem] z-[5] font-medium text-sm text-slate-600">
                                                    {indexHour + 1}:00
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}

                            {DAY_LIST.map((day, indexDay) => {
                                let diff = this.getDiff(date, indexDay);

                                let finalTime = date.getTime() + diff * 24 * 60 * 60 * 1000;

                                let startDate = new Date(finalTime);
                                startDate.setHours(0);
                                startDate.setMinutes(0);
                                startDate.setSeconds(0);
                                startDate.setMilliseconds(0);

                                let endDate = new Date(finalTime + 24 * 60 * 60 * 1000);
                                endDate.setHours(0);
                                endDate.setMinutes(0);
                                endDate.setSeconds(0);
                                endDate.setMilliseconds(0);

                                const isIn = (timer: Timer) => {
                                    let timerStart = timer.getStartDate();
                                    let timerEnd = timer.getEndDate();

                                    return (
                                        (timerStart.getTime() >= startDate.getTime() &&
                                            timerEnd.getTime() <= endDate.getTime()) ||
                                        (timerStart.getTime() <= startDate.getTime() &&
                                            timerEnd.getTime() >= endDate.getTime()) ||
                                        (timerStart.getTime() >= startDate.getTime() &&
                                            timerStart.getTime() <= endDate.getTime()) ||
                                        (timerEnd.getTime() >= startDate.getTime() &&
                                            timerEnd.getTime() <= endDate.getTime())
                                    );
                                };
                                const isInJour = (jour: Jour) => {
                                    let start = jour.getDate();

                                    return (
                                        startDate.getFullYear() === start.getFullYear() &&
                                        startDate.getMonth() === start.getMonth() &&
                                        startDate.getDate() === start.getDate()
                                    );
                                };

                                const getTop = (timer: Timer) => {
                                    let top = 0;
                                    let timerStart = timer.getStartDate();
                                    if (timerStart.getTime() < startDate.getTime()) {
                                        top = 0;
                                    } else {
                                        top =
                                            ((timerStart.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)) *
                                            100;
                                    }
                                    return top;
                                };

                                const getBottom = (timer: Timer) => {
                                    let bottom = 0;
                                    let timerEnd = timer.getEndDate();
                                    if (timerEnd.getTime() > endDate.getTime()) {
                                        bottom = 0;
                                    } else {
                                        bottom =
                                            (-(timerEnd.getTime() - endDate.getTime()) / (24 * 60 * 60 * 1000)) * 100;
                                    }
                                    return bottom;
                                };

                                let left = 0;
                                let i = 0;
                                while (i < indexDay) {
                                    left += this.listRef[i].current?.getBoundingClientRect().width ?? 0.0;
                                    i += 1;
                                }
                                left += (this.listRef[0].current?.getBoundingClientRect().width ?? 0.0) + 5;

                                return [
                                    this.getTimerList().map((timer, index) => {
                                        if (isIn(timer)) {
                                            let top = getTop(timer);
                                            let bottom = getBottom(timer);

                                            return (
                                                <TimerCalendarView
                                                    user={this.state.user}
                                                    canEdit={this.canEdit()}
                                                    key={index + '_timer_calendar_view'}
                                                    quartHour={
                                                        (this.tbodyRef.current?.getBoundingClientRect().height ?? 0) /
                                                        (24 * 4)
                                                    }
                                                    timerList={this.getTimerList()}
                                                    color={timer.getColor()}
                                                    left={left}
                                                    top={top}
                                                    bottom={bottom}
                                                    width={
                                                        (this.listRef[indexDay].current?.getBoundingClientRect()
                                                            .width ?? 0) - 10
                                                    }
                                                    timer={timer}
                                                    day={indexDay}
                                                />
                                            );
                                        } else {
                                            return undefined;
                                        }
                                    }),
                                ];
                            })}
                        </tbody>
                    </table>
                    <Box className={'w-[16rem] h-full'}>
                        <Box className={'fixed'} sx={{ pr: 4 }}>
                            <div className={'relative'}>
                                <RecapTimerView
                                    loading={this.state.loading}
                                    startDate={this.getStartDayWeek()}
                                    endDate={this.getEndDayWeek()}
                                    user={this.state.user}
                                    timerList={this.state.user.timerList.getList()}
                                />
                            </div>
                        </Box>
                    </Box>
                    <Box className="w-4"></Box>
                </Box>
            </div>
        );
    }

    canEdit(): boolean {
        return (
            (this.state.user.isMe(AppManager.getUser() as User) ||
                (AppManager.getUser() as User).root.get() === true) &&
            this.state.user.closedList.getList().length === 0
        );
    }

    getFullRecup() {
        return this.state.user.getFullRecupDispo();
    }

    getFullCP() {
        return this.state.user.getFullCpDispo();
    }
}
