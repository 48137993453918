import { ClientIcon } from '../../icons/ClientIcon';
import { CommandIcon } from '../../icons/CommandIcon';
import { HelpIcon } from '../../icons/HelpIcon';
import { HomeIcon } from '../../icons/HomeIcon';
import { UserListIcon } from '../../icons/UserListIcon';
import { StoreIcon } from '../../icons/StoreIcon';
import { Box, Button, Divider, Drawer, IconButton, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { NavItem } from './NavItem';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from './Logo';
import AppManager from '../../commun/AppManager';
import MenuIcon from '@mui/icons-material/Menu';
import User from 'src/object/User';
import { NavBarProfil } from '../NavBarProfil';

export interface ITabProps {
    label: string;
    value: string;
}

export interface DashboardNavBarProps {
    tab?: string;
    onTab?: (tab: string) => void;
    tabList?: ITabProps[];
}

export const DashboardNavBar = (props: DashboardNavBarProps) => {
    const { tab, onTab, tabList } = props;

    return (
        <Box sx={{ mb: 0 }}>
            <Box
                sx={{
                    mb: 0,
                    width: {
                        lg: 'calc(100% - 280px)',
                    },
                }}
                className="w-full z-[1110] bg-white h-[64px] right-0 !pl-4 !pr-4 fixed flex flex border-b border-blue-700 justify-center"
            >
                <Box className="flex items-center">
                    <IconButton
                        onClick={() => {
                            AppManager.get().openSideBar();
                        }}
                        sx={{
                            display: {
                                xs: 'inline-flex',
                                lg: 'none',
                            },
                        }}
                        className=""
                    >
                        <MenuIcon fontSize="small" />
                    </IconButton>
                </Box>

                {onTab !== undefined && onTab !== undefined && tabList !== undefined ? (
                    <Box className="flex-1">
                        <Tabs
                            onChange={(event, value) => {
                                onTab(value);
                            }}
                            sx={{
                                height: '64px',
                            }}
                            centered
                            value={tab}
                        >
                            {tabList.map((value: ITabProps, i: number) => {
                                return (
                                    <Tab
                                        key={i}
                                        sx={{ flex: 1, height: '64px', p: 0, wordBreak: 'break-all' }}
                                        className="!break-all"
                                        label={value.label}
                                        value={value.value}
                                    />
                                );
                            })}
                        </Tabs>
                    </Box>
                ) : (
                    <Box className=" w-full flex-1"></Box>
                )}
                <Box className="flex flex-col justify-center">
                    <Box className="flex justify-end flex-row items-center gap-3">
                        {AppManager.isAuthenticated() && <NavBarProfil></NavBarProfil>}
                        {AppManager.isAuthenticated() && (
                            <div className="relative">
                                <Link to="/message">
                                    <Button variant="contained">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </Button>
                                </Link>

                                {((AppManager.getUser() as User).isNotif() ?? false) && (
                                    <div className="absolute rounded-full left-[-0.25rem] bottom-[-0.25rem] bg-red-700">
                                        <span className="text-white text-base px-2.5 py-0.5">!</span>
                                    </div>
                                )}
                            </div>
                        )}
                        {AppManager.isAuthenticated() && (
                            <div className="relative">
                                <Link to={'/calendar/' + (AppManager.getUser() as User).getAjoIdentifier()}>
                                    <Button variant="contained">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </Button>
                                </Link>
                            </div>
                        )}
                        {AppManager.isAuthenticated() && (
                            <div className="relative">
                                <Link to={'/board/' + (AppManager.getUser() as User).getAjoIdentifier()}>
                                    <Button variant="contained">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className="w-5 h-5"
                                        >
                                            <path d="M5.566 4.657A4.505 4.505 0 016.75 4.5h10.5c.41 0 .806.055 1.183.157A3 3 0 0015.75 3h-7.5a3 3 0 00-2.684 1.657zM2.25 12a3 3 0 013-3h13.5a3 3 0 013 3v6a3 3 0 01-3 3H5.25a3 3 0 01-3-3v-6zM5.25 7.5c-.41 0-.806.055-1.184.157A3 3 0 016.75 6h10.5a3 3 0 012.683 1.657A4.505 4.505 0 0018.75 7.5H5.25z" />
                                        </svg>
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </Box>
                </Box>
            </Box>

            <Box sx={{ height: '64px', mb: 0 }}></Box>
        </Box>
    );
};
