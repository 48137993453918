import { Box, Typography } from '@mui/material';
import { Color } from '../../commun/Constant';
import { IPerfectTableGroup, PerfectTable } from '../../commun/table/PerfectTable';
import Budget from '../../object/Budget';
import { EuroSpan } from '../../commun/component/EuroSpan';
import { PourcentSpan } from '../../commun/component/PourcentSpan';
import { Paper } from '../../object/Paper';
import BasePrice from '../../object/BasePrice';
import { PrestationSelect } from '../budget/PrestationSelect';
import { VendorSelect } from './VendorSelect';
import { QuantityInput } from './QuantityInput';
import { VaSelect } from './VaSelect';
import { DeleteSvg } from '../DeleteSvg';
import { Button } from 'src/commun/component/Button';
import { SERVER_URL } from 'src/utils/Constant';
import { SimpleLabelList } from '../label/SimpleLabelList';
import { useState } from 'preact/hooks';
import MpModal from '../extends/MpModal';
import { Spinner } from 'src/commun/component/Spinner';
import { post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import AppManager from 'src/commun/AppManager';
import Toast from 'src/commun/toast/Toast';
import { PriceInput } from './PriceInput';
import { BudgetActionModal } from './BudgetActionModal';
import { DelBudgetModal } from './DelBudgetModal';
import { BonCommandeView } from '../BonCommandeView';
import { BonCommande } from 'src/object/BonCommande';

export interface IBudgetCommandFinishTableProps {
    list: Budget[];
    basePrice: BasePrice;
    paper: Paper;
    loading: boolean;
    color: Color;
}

export const BudgetCommandFinishTable = (props: IBudgetCommandFinishTableProps) => {
    const { loading, basePrice, paper, color, list } = props;

    paper.fetch();

    const [deleteBudget, setDeleteBudget] = useState<Budget | null>(null);

    const bcList = [] as BonCommande[];
    for (const b of list) {
        const bc = b.bon_commande.get() ?? null;
        if (bc !== null) {
            if (!bcList.find((e) => e.getAjoIdentifier() === bc.getAjoIdentifier())) {
                bcList.push(bc);
            }
        }
    }

    let groups: IPerfectTableGroup[] = [];
    for (const bc of bcList) {
        const budgetList = [] as Budget[];
        for (const b of list) {
            if (b.bon_commande.get()?.getAjoIdentifier() === bc.getAjoIdentifier()) {
                budgetList.push(b);
            }
        }
        groups.push({
            id: bc?.getAjoIdentifier() ?? '',
            render: () => <BonCommandeView basePrice={basePrice} bonCommande={bc} />,
            color: bc?.getColor() ?? 'gray',
            getList: (list: any[]) => {
                return budgetList;
            },
        });
    }
    groups.sort((a, b) => {
        if (a.id === '') {
            return -1;
        } else if (b.id === '') {
            return 1;
        } else {
            return b.id < a.id ? 1 : -1;
        }
    });
    return (
        <Box>
            <PerfectTable
                loading={loading}
                list={list}
                color={props.color ?? 'white'}
                nothing={
                    <Box className="flex flex-col gap-3 items-center justify-center">
                        <Typography>{'Aucune prestation commandée.'}</Typography>
                    </Box>
                }
                bonusTrClass={(elem: any) => {
                    return elem.focus && '!border-8 !border-blue-600';
                }}
                bonusTdClass={(elem: any) => {
                    return elem.focus && '!bg-blue-200';
                }}
                groups={groups}
                columns={[
                    {
                        name: '',

                        render: (elem: Budget) => {
                            return (
                                <Box className="flex flex-row gap-2">
                                    {basePrice.canAct() && elem.bon_commande.get() === null ? (
                                        <Button
                                            color="red"
                                            pb={2}
                                            tabIndex={-1}
                                            pl={2}
                                            pr={2}
                                            pt={2}
                                            onClick={() => {
                                                setDeleteBudget(elem);
                                            }}
                                        >
                                            <DeleteSvg className={'text-white h-5 w-5'} />
                                        </Button>
                                    ) : (
                                        basePrice.canAct() && (
                                            <a
                                                href={
                                                    SERVER_URL +
                                                    '/command/' +
                                                    elem.bon_commande.get()!.code.get() +
                                                    '.pdf'
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                tabIndex={-1}
                                            >
                                                <Button tabIndex={-1} color="blue" pb={2} pl={2} pr={2} pt={2}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-5 w-5"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </Button>
                                            </a>
                                        )
                                    )}
                                    {basePrice.canAct() && <BudgetActionModal budget={elem} />}
                                </Box>
                            );
                        },
                    },
                    {
                        name: 'Prestation',

                        render: (elem: Budget) => {
                            return (
                                <Box className="flex flex-col items-center justify-center">
                                    {elem.alias.get() !== undefined &&
                                        elem.alias.get() !== undefined &&
                                        elem.alias.get() !== '' && (
                                            <Typography className="mb-3">{elem.alias.get()}</Typography>
                                        )}
                                    <PrestationSelect
                                        basePrice={basePrice}
                                        budget={elem}
                                        prestationList={basePrice.prestationList!}
                                    />
                                </Box>
                            );
                        },
                    },
                    {
                        name: 'Fournisseur',

                        render: (elem: Budget) => {
                            return (
                                <VendorSelect basePrice={basePrice} budget={elem} vendorList={basePrice.vendorList!} />
                            );
                        },
                    },
                    {
                        name: 'Label',

                        render: (elem: Budget) => {
                            return elem.service.get() != null ? (
                                <SimpleLabelList
                                    add={true}
                                    service={elem.service.get()!}
                                    list={elem.service.get()?.getLabelList() ?? []}
                                />
                            ) : (
                                <></>
                            );
                        },
                    },
                    {
                        name: 'Quantité',

                        render: (elem: Budget) => {
                            return <QuantityInput basePrice={basePrice} budget={elem} />;
                        },
                    },
                    {
                        name: 'PU Achat',

                        render: (elem: Budget) => {
                            return <PriceInput basePrice={basePrice} budget={elem} />;
                        },
                    },
                    {
                        name: 'Achat Total',

                        render: (elem: Budget) => {
                            return (
                                <Typography>
                                    <EuroSpan value={elem.achat()} />
                                </Typography>
                            );
                        },
                    },
                    {
                        name: 'VA',

                        render: (elem: Budget) => {
                            return <VaSelect basePrice={basePrice} budget={elem} vaList={basePrice.valeurList!} />;
                        },
                    },
                    {
                        name: 'PV Unitaire',

                        render: (elem: Budget) => {
                            return (
                                <Typography>
                                    <EuroSpan value={elem.prixVenteUnitaire()} />
                                </Typography>
                            );
                        },
                    },
                    {
                        name: 'PV HT',

                        render: (elem: Budget) => {
                            return (
                                <Typography>
                                    <EuroSpan value={elem.prixVenteHT()} />
                                </Typography>
                            );
                        },
                    },
                    {
                        name: 'TVA',

                        render: (elem: Budget) => {
                            return (
                                <Typography>
                                    <PourcentSpan value={elem.tax()} />
                                </Typography>
                            );
                        },
                    },
                    {
                        name: 'PV TTC',

                        render: (elem: Budget) => {
                            return (
                                <Typography>
                                    <EuroSpan value={elem.prixVenteTTC()} />
                                </Typography>
                            );
                        },
                    },
                ]}
            />
            <DelBudgetModal
                budget={deleteBudget}
                handleClose={() => {
                    setDeleteBudget(null);
                }}
                open={deleteBudget !== null}
                paper={paper}
            />
        </Box>
    );
};
