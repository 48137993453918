import { AJOList, AJOObject } from 'mp-js-react-auto-json-object';
import { useEffect, useRef, useState } from 'react';
import BasePrice from 'src/object/BasePrice';
import AppManager from '../../commun/AppManager';
import Autocomplete from '../../commun/component/Autocomplite';
import { post } from '../../commun/HTTP';
import { PerfectAJOList } from '../../commun/object/PerfectAJOList';
import Budget from '../../object/Budget';
import Prestation from '../../object/profile/Prestation';
import PrestationModal from '../prestation/PrestationModal';
import { CustomSelect } from './CustomSelect';

export interface IPrestationSelectProps {
    prestationList: PerfectAJOList<Prestation>;
    budget: Budget;
    basePrice: BasePrice;
}

export const PrestationSelect = (props: IPrestationSelectProps) => {
    const { prestationList, budget, basePrice } = props;
    const ref = useRef<HTMLInputElement>();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');

    return (
        <>
            <CustomSelect
                ref={ref}
                id={'prestation-' + budget.getAjoIdentifier()}
                disabled={budget.bon_commande.get() !== null || !basePrice.canAct()}
                list={prestationList.getList()}
                value={budget.prestation.get()}
                getValue={(elem: any) => elem.name.get() ?? ''}
                placeholder="Prestation"
                onBlur={() => {
                    budget.leaveFocus();
                }}
                onFocus={() => {
                    setName('');
                    prestationList?.need();
                    budget.getFocus();
                }}
                onCreate={(str: string) => {
                    setName(str);
                    setOpen(true);
                }}
                onSelect={(item: any) => {
                    if (item instanceof Prestation) {
                        budget.prestation.set(item);
                        budget.addEdit({
                            temp_prestation: item.getAjoIdentifier(),
                            temp_vendor: budget.vendor.get()?.getAjoIdentifier() ?? null,
                        });
                        AppManager.makeUpdate();
                    } else if (budget.prestation.get() !== null) {
                        budget.prestation.set(null);
                        budget.addEdit({
                            temp_prestation: '-1',
                        });
                        AppManager.makeUpdate();
                    }
                }}
            />
            <PrestationModal
                prestation={null}
                baseName={name}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                onCreate={(prestation: Prestation) => {
                    budget.addEdit({
                        temp_prestation: prestation.getAjoIdentifier(),
                        temp_vendor: budget.vendor.get()?.getAjoIdentifier() ?? null,
                    });
                    budget.prestation.set(prestation);
                    AppManager.makeUpdate();
                }}
            />
        </>
    );
};
