import { CheckBox } from '@mui/icons-material';
import { Card } from '@mui/material';
import { useEffect, useState } from 'react';
import Label from '../../object/tag/Label';

export interface ISelectLabelListProps {
    list: Label[];
    selectList: Label[];
    single?: boolean;
    onSelectList: (list: Label[]) => void;
}

export const SelectLabelList = (props: ISelectLabelListProps) => {
    const [selected, setSelected] = useState<Label[]>(props.selectList);

    useEffect(() => {
        setSelected(props.selectList);
    }, [props.selectList.length]);

    useEffect(() => {
        props.onSelectList(selected);
    }, [selected]);

    return (
        <div className="flex gap-2 flex-wrap">
            {props.list.map((label: Label, index: number) => {
                return (
                    <div className={'rounded-lg px-2 py-1 bg-' + label.getColor() + '-600'}>
                        <div className="flex items-center gap-2">
                            <input
                                checked={
                                    selected.find((item) => item.getAjoIdentifier() === label.getAjoIdentifier()) !==
                                    undefined
                                }
                                onChange={() => {
                                    if (props.single ?? false) {
                                        setSelected([label]);
                                    } else {
                                        if (selected.includes(label)) {
                                            setSelected(
                                                selected.filter(
                                                    (value: Label) =>
                                                        value.getAjoIdentifier() !== label.getAjoIdentifier(),
                                                ),
                                            );
                                        } else {
                                            setSelected([...selected, label]);
                                        }
                                    }
                                }}
                                id={label.getAjoIdentifier()}
                                type="checkbox"
                                value=""
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label htmlFor={label.getAjoIdentifier()} className="font-medium text-white text-sm">
                                {label.name.get()}
                            </label>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
