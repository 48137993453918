import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ComplexInput } from '../commun/component/ComplexInput';
import { Input } from '../commun/component/Input';
import { Button } from '../commun/component/Button';
import { Form } from '../commun/component/Form';
import { Content } from '../commun/component/Content';
import { get, post } from '../commun/HTTP';
import PageManager from '../commun/PageManager';
import { BasePage } from '../component/BasePage';
import Result from '../commun/Result';
import ErrorHTTP from '../commun/ErrorHTTP';
import { ErrorText } from '../commun/component/ErrorText';
import ErrorManager from '../commun/ErrorManager';
import { Spinner } from '../commun/component/Spinner';
import AppManager from '../commun/AppManager';
import User from '../object/User';
import { ButtonGradiant } from '../commun/component/ButtonGradiant';

const errorManager = new ErrorManager('login');
errorManager.add(
    ErrorHTTP.WRONG_PASSWORD(),
    'Les informations saisies ne correspondent pas à celles de notre base de données.',
);
errorManager.add(ErrorHTTP.NOT_FOUND(), 'Aucun utilisateur ne correspond à cette adresse e-mail.');
errorManager.add(ErrorHTTP.BAD_VALUE(), 'Les informations saisies sont incorrectes.');
errorManager.add(
    ErrorHTTP.LOGOUT().setColor('green-600').setColorDark('green-300'),
    'Vous avez été déconnecté avec succès.',
);

export function DeletePage() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(ErrorHTTP.NO_ERROR());

    const location = useLocation();
    PageManager.setLocation(location.pathname);
    const currentPage = PageManager.getCurrent(location.pathname);

    const logOut = () => {
        setError(ErrorHTTP.NO_ERROR());
        setLoading(true);
        post('/logout').then((res: any) => {
            AppManager.navigate('/connection');
            setLoading(false);
            if (Result.isSuccess(res)) {
                setError(ErrorHTTP.LOGOUT());

                AppManager.setAuthenticated(false);
                AppManager.setUser(User.build());
            } else {
                setError(Result.getError(res));
            }
        });
    };

    let user: User = AppManager.getUser() as User;

    return (
        <Content className="w-4/5 text-center max-w-md mb-8">
            <h1 className="text-4xl font-bold text-black dark:text-white">Utilisateur supprimé</h1>

            <p className="text-black dark:text-white mb-1">
                {'Votre compte a été supprimé, vous ne pouvez plus réaliser aucune action.'}
            </p>

            <Spinner color="purple"></Spinner>

            <ErrorText topSpacing={0} bottomSpacing={2} manager={errorManager} error={error}></ErrorText>

            <div className="flex items-center justify-center gap-2 sm:gap-6">
                <ButtonGradiant
                    className="flex-1"
                    onClick={logOut}
                    selected={true}
                    ringColor={currentPage.getRing()}
                    toColor={currentPage.getTo()}
                    fromColor={currentPage.getFrom()}
                >
                    Déconnexion
                </ButtonGradiant>
            </div>
        </Content>
    );
}
