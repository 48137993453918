import { AJOState } from 'mp-js-react-auto-json-object';
import { AJOProperties } from 'mp-js-react-auto-json-object';
import { Paper } from '../Paper';
import { MargeReduc } from './MargeReduc';

export class Reduc extends MargeReduc {
    public static _TYPE: string = 'Reduc';

    constructor() {
        super(Reduc._TYPE);
    }

    public static build(): Reduc {
        return new Reduc();
    }

    public prixVenteHT(): number {
        let res = 1;
        if (this.func.get() === 0) {
            if (this.getAjoParent()?.getAjoParent() instanceof Paper) {
                let paper = this.getAjoParent()!.getAjoParent() as Paper;
                res = paper.prixVenteHTNoReduc() * (this.va() / 100);
            }
        } else {
            res = this.value.get();
        }
        return -res;
    }
}
