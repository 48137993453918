import { IObjectSelectable } from './../component/select/ObjectSelect';
import { AJOObject, AJOProperties } from 'mp-js-react-auto-json-object';

export default class Address extends AJOObject implements IObjectSelectable {
    public static _TYPE: string = 'Address';

    public name: AJOProperties<any>;
    public postal_code: AJOProperties<any>;
    public city: AJOProperties<any>;
    public bonus: AJOProperties<any>;
    public fullAddress(): string {
        let res: string = '';
        if (this.bonus.get() !== '' && this.bonus.get() !== undefined) {
            res += this.bonus.get() + '';
        }
        if (this.number_and_street.get() !== '' && this.number_and_street.get() !== undefined) {
            if (res !== '') {
                res += ', ';
            }
            res += this.number_and_street.get() + '';
        }
        if (this.postal_code.get() !== '' && this.postal_code.get() !== undefined) {
            if (res !== '') {
                res += ', ';
            }
            res += this.postal_code.get() + '';
        }
        if (this.city.get() !== '' && this.city.get() !== undefined) {
            if (res !== '') {
                res += ' ';
            }
            res += this.city.get() + '';
        }
        return res;
    }
    public fullNameAddress(): string {
        let res: string = '';
        if (this.name.get() !== '' && this.name.get() !== undefined) {
            res += this.name.get() + '';
        }
        if (this.bonus.get() !== '' && this.bonus.get() !== undefined) {
            if (res !== '') {
                res += ', ';
            }
            res += this.bonus.get() + '';
        }
        if (this.number_and_street.get() !== '' && this.number_and_street.get() !== undefined) {
            if (res !== '') {
                res += ', ';
            }
            res += this.number_and_street.get() + '';
        }
        if (this.postal_code.get() !== '' && this.postal_code.get() !== undefined) {
            if (res !== '') {
                res += ', ';
            }
            res += this.postal_code.get() + '';
        }
        if (this.city.get() !== '' && this.city.get() !== undefined) {
            if (res !== '') {
                res += ' ';
            }
            res += this.city.get() + '';
        }
        return res;
    }
    public number_and_street: AJOProperties<any>;

    constructor() {
        super(Address._TYPE);

        this.name = new AJOProperties('name');
        this.postal_code = new AJOProperties('postal_code');
        this.city = new AJOProperties('city');
        this.bonus = new AJOProperties('bonus');
        this.number_and_street = new AJOProperties('number_and_street');
    }

    public static build(): Address {
        return new Address();
    }

    public getTitle(): string {
        let res: string = '';
        if (this.name.get() !== '' && this.name.get() !== undefined) {
            res = this.name.get();
        } else {
            res = this.fullAddress();
        }
        return res;
    }

    public getDescription(): string {
        let res: string = '';
        if (this.name.get() !== '' && this.name.get() !== undefined) {
            res = this.fullAddress();
        } else {
            res = '';
        }
        return res;
    }

    public getSelectTitle(): string {
        return this.getTitle();
    }
    public getSelectDescription(): string {
        return this.fullAddress();
    }
}
