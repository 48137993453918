import { Box, Button, CardContent, Container, Typography } from '@mui/material';
import { Card } from 'flowbite-react';
import { AddIcon } from 'src/icons/AddIcon';
import BasePrice from 'src/object/BasePrice';
import { Paper } from 'src/object/Paper';
import { PaperStatView } from '../budget/PaperStatView';

export const DevisExportView = (props: { basePrice: BasePrice }) => {
    const { basePrice } = props;
    const paper = basePrice.getSelectedPaper() ?? new Paper();
    return (
        <Container maxWidth={false}>
            <Box sx={{ mb: 1.5, mt: 3 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: 3,
                        flexWrap: 'wrap',
                        m: -1,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        {'Feuille'}
                    </Typography>
                </Box>
            </Box>

            <Box className="flex flex-wrap" sx={{ gap: 1.5, pt: 1.5, pb: 1.5 }}>
                {basePrice.paperList
                    .getList()
                    .filter((p: Paper) => {
                        return (p.archive.get() !== true || p.comptable.get() === true) && p.func.get() === 0;
                    })
                    .sort((a: Paper, b: Paper) => {
                        let res: number;
                        if (a.func.get() === b.func.get()) {
                            res = a.name.get().localeCompare(b.name.get());
                        } else {
                            res = a.func.get() - b.func.get();
                        }
                        return res;
                    })
                    .map((p: Paper, index: number) => {
                        return (
                            <PaperStatView
                                exportPaper={true}
                                action={false}
                                key={p.getAjoIdentifier()}
                                basePrice={basePrice}
                                selected={false}
                                paper={p}
                            />
                        );
                    })}
            </Box>
        </Container>
    );
};
