import { AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useRef, useState } from 'react';
import AppManager from '../commun/AppManager';
import { Button } from '../commun/component/Button';
import { SearchInput } from '../commun/component/SearchInput';
import { Toggle } from '../commun/component/Toggle';
import { ProfileLeft } from '../commun/component/ProfileLeft';
import { BasePage } from '../component/BasePage';
import ParentSevice from '../object/profile/ParentSevice';
import Permission from '../object/Permission';
import User from '../object/User';
import Client from '../object/profile/Client';
import Vendor from '../object/profile/Vendor';

import { LittleAddButton } from '../component/LittleAddButton';
import Prestation from '../object/profile/Prestation';
import { TablePrestationList } from '../component/TablePrestationList';
import { TableView } from '../commun/table/TableView';
import Table, { TypeTable } from '../commun/table/Table';
import Service from '../object/Service';
import Utils from '../commun/Utils';
import Devis from '../object/Devis';
import { AddServiceModal } from '../modal/vendor/AddServiceModal';
import { AddElemClientModal } from '../modal/vendor/AddElemClientModal';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';

export interface IVendorPrestPageProps {
    elem: ParentSevice | Client;
    id: string;
}
export const ProfilePage: React.FC<IVendorPrestPageProps> = (props) => {
    const { elem, id } = props;

    const updater = new AJOUpdater();
    AppManager.updater().add(elem);

    const [type, setType] = useState<TypeTable>('info');

    const [search, updateSearch] = useState('');
    const canEdit = (AppManager.getUser() as User)?.hasPermission(Permission.MANAGE_VENDOR, false) ?? false;
    const [show, setShow] = useState(true);

    const [showAddModal, setShowAddModal] = useState(false);

    const [loading, setLoading] = useState(true);
    const [loadingStat, setLoadingStat] = useState(true);

    let tableObj = new Table<Service>('prestation');

    const [stateTable, setStateTable] = useState(new AJOState(tableObj));
    stateTable.setUpdate(setStateTable);

    tableObj = stateTable.get()!;
    tableObj.state = stateTable;

    const getPlaceholderTab = () => {
        if (elem instanceof Vendor) {
            return 'Rechercher une prestations';
        } else if (elem instanceof Client) {
            return 'Rechercher un devis';
        } else {
            return 'Rechercher un fournisseurs';
        }
    };

    const getTitleTab = () => {
        if (elem instanceof Vendor) {
            return 'Prestations';
        } else if (elem instanceof Client) {
            return 'Prix de vente';
        } else {
            return 'Fournisseurs';
        }
    };

    const fetch = () => {
        if (elem instanceof Vendor) {
            elem.get(id, () => {
                setLoading(false);
            });
        } else if (elem instanceof Prestation) {
            elem.get(id, () => {
                setLoading(false);
            });
        } else if (elem instanceof Client) {
            elem.get(id, () => {
                setLoading(false);
            });
        }
    };

    const fetchStat = () => {
        if (elem instanceof Vendor) {
            elem.getStat(id, () => {
                setLoadingStat(false);
            });
        } else if (elem instanceof Prestation) {
            elem.getStat(id, () => {
                setLoadingStat(false);
            });
        } else if (elem instanceof Client) {
            elem.getStat(id, () => {
                setLoadingStat(false);
            });
        }
    };

    const onTypeChange = (type: TypeTable) => {
        if (type === 'info') {
            setShow(true);
            fetch();
        } else {
            setShow(false);
            fetchStat();
        }
        setType(type);
    };

    let loadingFinal: boolean;
    if (type === 'info') {
        loadingFinal = loading;
    } else {
        loadingFinal = loadingStat;
    }

    let table;
    if (elem instanceof Vendor || elem instanceof Prestation) {
        table = (
            <TablePrestationList
                loading={loadingFinal}
                size="md"
                bonusFilter={Service.bonusFilter(search)}
                canAction={true}
                sourceTable={tableObj}
                rounded={true}
                parentObj={elem}
                onTypeChange={onTypeChange}
                serviceList={elem.serviceList.getList()}
            />
        );
    }

    return (
        <BasePage className="flex-row p-3 gap-3 items-start">
            {show && <ProfileLeft loading={loadingFinal} canEdit={canEdit} profile={elem} />}
            <div className="flex gap-3 flex-1 flex-col">
                <div className="flex flex-row items-center gap-3">
                    <SearchInput placeholder={getPlaceholderTab()} value={search} setValue={updateSearch} />
                    <LittleAddButton
                        size="md"
                        text=""
                        onAdd={() => {
                            setShowAddModal(true);
                        }}
                    />
                </div>
                <div className="rounded-lg flex flex-col gap-3  border border-slate-300 dark:border-gray-500 bg-white dark:bg-gray-800">
                    {table}
                </div>
            </div>
            {elem instanceof Client && showAddModal && (
                <AddElemClientModal
                    show={show}
                    mode="devis"
                    clientBase={elem}
                    templateList={[]}
                    handleClose={() => {
                        setShowAddModal(false);
                    }}
                />
            )}
            {elem instanceof Vendor && showAddModal && (
                <div className="absolute">
                    <AddServiceModal
                        parent={elem}
                        show={showAddModal}
                        handleClose={() => {
                            setShowAddModal(false);
                        }}
                    />
                </div>
            )}
            {elem instanceof Prestation && showAddModal && (
                <div className="absolute">
                    <AddServiceModal
                        parent={elem}
                        show={showAddModal}
                        handleClose={() => {
                            setShowAddModal(false);
                        }}
                    />
                </div>
            )}
        </BasePage>
    );
};
