import { AJOList, AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { ComplexSelect } from '../commun/component/ComplexSelect';
import { VirgineComplexSelect } from '../commun/component/VirgineComplexSelect';
import { get } from '../commun/HTTP';
import Result from '../commun/Result';
import Utils from '../commun/Utils';
import Address from '../object/Address';
import Taxe from '../object/Taxe';
import Valeur from '../object/Valeur';

export interface IVirgineListComplexSelectProps {
    field: string;
    placeholder: string;

    search: string;
    setSearch: (search: string) => void;
    name: string;
    id: string;

    onSelectId: (id: string) => void;
    onSelectName: (name: string) => void;

    onBlur?: () => void;
    onFocus?: () => void;
    onClick?: () => void;

    onConfirmChange?: (selectName: string, selectId: string, tabOrder: boolean) => void;

    canAdd: boolean;
    flex?: boolean;
    special?: boolean;
    focusInput: boolean;
    forceFocus?: boolean;

    ajoList: AJOList<any>;
}

export const VirgineListComplexSelect: React.FC<IVirgineListComplexSelectProps> = (props) => {
    const {
        canAdd,
        field,
        focusInput,
        forceFocus = false,

        onFocus = () => {},
        onBlur = () => {},
        onClick = () => {},

        onSelectId,
        onSelectName,
        onConfirmChange = () => {},
        placeholder,
        ajoList,
        special = false,

        search,
        setSearch,
        name,
        id,
        flex = false,
    } = props;

    // get function title
    let list = ajoList.getList();
    let loadTitle;
    let loadDescription;
    let filter;
    let finalBaseName = '';
    let removeDuplicate: any = undefined;
    if (field === 'taxe' || field === 'vendortaxe') {
        loadTitle = (elem: Taxe) => elem.code.get() + '';
        loadDescription = (elem: Taxe) => '' + elem.pourcent.get() + ' %';
        list.sort((a: any, b: any) => {
            return a.code.get() - b.code.get();
        });
        if (name != undefined) {
            finalBaseName = name + '';
        }
        filter = (elem: Taxe, search: string) =>
            Utils.clean(elem.code.get() + '').startsWith(Utils.clean(search)) ||
            Utils.clean(elem.pourcent.get() + ' %').startsWith(Utils.clean(search));
    } else if (field === 'address' || field === 'facturation') {
        loadTitle = (elem: Address) => elem.getTitle();
        loadDescription = (elem: Address) => elem.getDescription();

        removeDuplicate = (address: Address, elem: Address) => {
            try {
                return (
                    elem.getAjoIdentifier() === address.getAjoIdentifier() ||
                    elem.fullAddress() !== address.fullAddress() ||
                    elem.getTitle() !== address.getTitle()
                );
            } catch (e) {
                return false;
            }
        };

        if (name != undefined) {
            finalBaseName = name;
        }
        filter = (elem: Address, search: string) => {
            try {
                return (
                    Utils.clean(elem.getTitle()).startsWith(Utils.clean(search)) ||
                    Utils.clean(elem.getDescription()).startsWith(Utils.clean(search))
                );
            } catch (e) {
                return false;
            }
        };
    } else if (field === 'valeur') {
        loadTitle = (elem: Valeur) => elem.pourcent.get() + ' %';
        loadDescription = (elem: Valeur) => elem.description.get() + '';
        if (name != undefined) {
            finalBaseName = name + ' %';
        }
        list.sort((a: any, b: any) => {
            return a.pourcent.get() - b.pourcent.get();
        });
        filter = (elem: Valeur, search: string) => {
            return Utils.clean(elem.pourcent.get() + ' %').startsWith(Utils.clean(search));
        };
    } else if (field === 'nego') {
        loadTitle = (elem: Valeur) => -elem.pourcent.get() + ' %';
        loadDescription = (elem: Valeur) => '';
        if (name != undefined) {
            finalBaseName = name + ' %';
        }
        list.sort((a: any, b: any) => {
            return -a.pourcent.get() + b.pourcent.get();
        });
        filter = (elem: Valeur, search: string) => {
            return Utils.clean(-elem.pourcent.get() + ' %').startsWith(Utils.clean(search));
        };
    } else if (field === 'user') {
        if (name != undefined) {
            finalBaseName = name;
        }
        loadTitle = (elem: any) => {
            return elem.getFullName();
        };
        loadDescription = (elem: any) => '';
        filter = (elem: any, search: string) => Utils.clean(elem.getFullName()).startsWith(Utils.clean(search));
    } else if (field === 'contact') {
        if (name != undefined) {
            finalBaseName = name;
        }
        loadTitle = (elem: any) => {
            return elem.name.get();
        };
        loadDescription = (elem: any) => elem.email.get();
        filter = (elem: any, search: string) => Utils.clean(elem.name.get()).startsWith(Utils.clean(search));
    } else if (field === 'agence') {
        if (name != undefined) {
            finalBaseName = name;
        }
        loadTitle = (elem: any) => {
            return elem.code.get();
        };
        loadDescription = (elem: any) => '';
        filter = (elem: any, search: string) => Utils.clean(elem.code.get()).startsWith(Utils.clean(search));
    } else {
        if (special) {
            if (name != undefined) {
                finalBaseName = name;
            }
            loadTitle = (elem: any) => {
                return elem.specialRender();
            };
        } else {
            if (name != undefined) {
                finalBaseName = name;
            }
            loadTitle = (elem: any) => {
                return elem.name.get();
            };
        }
        loadDescription = (elem: any) => '';
        filter = (elem: any, search: string) => Utils.clean(elem.name.get()).startsWith(Utils.clean(search));
    }
    return (
        <VirgineComplexSelect
            value={search}
            name={name}
            id={id}
            removeDuplicate={removeDuplicate}
            onConfirmChange={onConfirmChange}
            focusInput={focusInput}
            forceFocus={forceFocus}
            onClick={() => {
                onClick();
            }}
            onIdChange={(select) => {
                onSelectId(select);
                onSelectName('');
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            elemList={list}
            color="base"
            add={canAdd}
            onAdd={(name) => {
                onSelectName(name);
                onSelectId('');
            }}
            setValue={setSearch}
            placeholder={placeholder}
            filter={filter}
            loadDescription={loadDescription}
            loadTitle={loadTitle}
        ></VirgineComplexSelect>
    );
};
