import React from 'react';
import AppManager from '../../commun/AppManager';
import { ComplexInput } from '../../commun/component/ComplexInput';
import { CustomDatePicker } from '../../commun/component/CustomDatePicker';
import { EuroSpan } from '../../commun/component/EuroSpan';
import { PourcentSpan } from '../../commun/component/PourcentSpan';
import { Color } from '../../commun/Constant';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import Utils from '../../commun/Utils';
import { JSXInternal } from 'preact/src/jsx';
import { UserSelectModal } from '../../modal/user/SelectUserModal';
import { devisEditSuccess, errorManager } from '../../modal/vendor/CategoryErrorManager';
import Address from '../../object/Address';
import { Agence } from '../../object/Agence';
import BasePrice from '../../object/BasePrice';
import Devis from '../../object/Devis';
import Product from '../../object/tag/Product';
import { AddSvg } from '../AddSvg';
import { CaretDownSvg, CaretRightSvg } from '../CaretSvg';
import { EditSvg } from '../EditSvg';
import { ExportSvg } from '../ExportSvg';
import { RainbowButton } from '../RainbowButton';
import { AddressSelect } from '../select/AddressSelect';
import { AgenceSelect } from '../select/AgenceSelect';
import { ProductSelect } from '../select/OffreSelect';
import { Separator } from '../Separator';
import { UserList } from '../UserList';
import MpModal from '../extends/MpModal';
import { Box, Button, TextField, Typography } from '@mui/material';
import { Spinner } from 'src/commun/component/Spinner';
import { DatePicker } from '@mui/x-date-pickers';

interface IBasePriceProfilProps {
    basePrice: BasePrice;
}

type ModalType = 'participant' | 'start' | 'end' | 'address' | 'agence' | 'product' | 'name' | 'none';

interface IBasePriceProfilState {
    modal: ModalType;
    show: boolean;

    openHonoraire: boolean;
    openBudget: boolean;
    openUser: boolean;
    addUser: boolean;

    loading: boolean;
    error: ErrorHTTP;

    name: string;
    participant: string;

    start: Date | null;
    end: Date | null;

    address: Address | null;
    agence: Agence | null;
    product: Product | null;
}

export class BasePriceProfilView extends React.Component<IBasePriceProfilProps, IBasePriceProfilState> {
    public constructor(props: IBasePriceProfilProps) {
        super(props);
        this.state = this.initState();
    }

    private initState(): IBasePriceProfilState {
        const { basePrice } = this.props;
        return {
            modal: 'none',
            show: false,
            loading: false,
            error: ErrorHTTP.NO_ERROR(),
            name: basePrice.getName(),
            openHonoraire: false,
            openBudget: true,
            openUser: true,
            addUser: false,
            participant: basePrice instanceof Devis ? basePrice.nb_personne.get() : '',
            start: basePrice.start_date.get() ? new Date(basePrice.start_date.get()) : null,
            end: basePrice.end_date.get() ? new Date(basePrice.end_date.get()) : null,
            address: basePrice instanceof Devis ? basePrice.address.get() : null,
            agence: basePrice instanceof Devis ? basePrice.agence.get() : null,
            product: basePrice instanceof Devis ? basePrice.product.get() : null,
        };
    }

    private getClassName(defined: boolean): string {
        let res: string = '';
        if (defined) {
            res = 'text-base font-normal text-black';
        } else {
            res = 'text-base font-medium text-red-700';
        }
        return res;
    }

    private getIcon(defined: boolean): JSXInternal.Element {
        let res;
        if (defined) {
            res = <EditSvg className="h-5 w-5" />;
        } else {
            res = <AddSvg className="h-5 w-5" />;
        }
        return res;
    }

    getText(start: string, elem: string): string {
        let res = '';
        if (elem == '') {
            res = start;
        } else {
            res = start + ' : ' + elem;
        }
        return res;
    }

    getButtonColor(defined: boolean) {
        if (defined) {
            return 'primary';
        } else {
            return 'error';
        }
    }

    openModal(type: ModalType) {
        const state = this.initState();
        state['modal'] = type;
        state['show'] = true;
        this.setState(state);
    }

    getDone() {
        const { basePrice } = this.props;
        const { modal } = this.state;
        let res = '';
        if (modal === 'start') {
            if (basePrice.start_date.get() !== undefined) {
                res = 'Modifier';
            } else {
                res = 'Ajouter';
            }
        } else if (modal === 'end') {
            if (basePrice.end_date.get() !== undefined) {
                res = 'Modifier';
            } else {
                res = 'Ajouter';
            }
        } else if (modal === 'address') {
            if (basePrice instanceof Devis && basePrice.address.get() != null) {
                res = 'Modifier';
            } else {
                res = 'Ajouter';
            }
        } else if (modal === 'agence') {
            if (basePrice instanceof Devis && basePrice.agence.get() != null) {
                res = 'Modifier';
            } else {
                res = 'Ajouter';
            }
        } else if (modal === 'product') {
            if (basePrice instanceof Devis && basePrice.product.get() != null) {
                res = 'Modifier';
            } else {
                res = 'Ajouter';
            }
        } else if (modal === 'name') {
            res = 'Modifier';
        } else if (modal === 'participant') {
            if (basePrice instanceof Devis && basePrice.nb_personne.get() !== undefined) {
                res = 'Modifier';
            } else {
                res = 'Ajouter';
            }
        }
        return res;
    }
    getTitle() {
        const { modal } = this.state;
        let res = '';
        if (modal === 'start') {
            res = 'Date de début';
        } else if (modal === 'end') {
            res = 'Date de fin';
        } else if (modal === 'address') {
            res = 'Adresse du prix de vente';
        } else if (modal === 'agence') {
            res = 'Agence du prix de vente';
        } else if (modal === 'product') {
            res = 'Offre du prix de vente';
        } else if (modal === 'name') {
            res = 'Nom du prix de vente';
        } else if (modal === 'participant') {
            res = 'Nombre de participant';
        }
        return res;
    }

    render(): JSXInternal.Element {
        const { basePrice } = this.props;
        return (
            <div className="flex flex-col gap-2">
                <div className="flex flex-row items-center gap-2">
                    <p className="font-semibold">{basePrice.getName()}</p>
                    {basePrice.canAct() && (
                        <Button
                            variant="contained"
                            onClick={() => {
                                this.openModal('name');
                            }}
                        >
                            <div className="absolute" onClick={() => {}}>
                                <MpModal
                                    overflow={false}
                                    title={this.getTitle()}
                                    done={this.getDone()}
                                    open={this.state.show}
                                    onSubmit={() => {
                                        this.onDone();
                                    }}
                                    onClose={() => {
                                        this.onCancel();
                                        this.setState({ show: false });
                                    }}
                                >
                                    {this.getContent()}
                                </MpModal>
                            </div>
                            <EditSvg className="w-5 h-5" />
                        </Button>
                    )}
                </div>

                {basePrice instanceof Devis && (
                    <div className="flex flex-row items-center gap-2">
                        <p className={this.getClassName(basePrice.nb_personne.get() !== undefined)}>
                            {this.getText(
                                'Participant',
                                basePrice.nb_personne.get() !== undefined ? basePrice.nb_personne.get() : '',
                            )}
                        </p>
                        {basePrice.canAct() && (
                            <Button
                                variant="contained"
                                color={this.getButtonColor(basePrice.nb_personne.get() !== undefined)}
                                onClick={() => {
                                    this.openModal('participant');
                                }}
                            >
                                {this.getIcon(basePrice.nb_personne.get() !== undefined)}
                            </Button>
                        )}
                    </div>
                )}

                {basePrice instanceof Devis && (
                    <div className="flex flex-1 flex-row items-center gap-2">
                        <p className={this.getClassName(basePrice.start_date.get() !== undefined)}>
                            {this.getText(
                                'Début',
                                basePrice.start_date.get() != undefined
                                    ? Utils.toDDMMYY(basePrice.start_date.get())
                                    : '',
                            )}
                        </p>
                        {basePrice.canAct() && (
                            <Button
                                variant="contained"
                                color={this.getButtonColor(basePrice.start_date.get() !== undefined)}
                                onClick={() => {
                                    this.openModal('start');
                                }}
                            >
                                {this.getIcon(basePrice.start_date.get() !== undefined)}
                            </Button>
                        )}
                    </div>
                )}

                {basePrice instanceof Devis && (
                    <div className="flex flex-1 flex-row items-center gap-2">
                        <p className={this.getClassName(basePrice.end_date.get() !== undefined)}>
                            {this.getText(
                                'Fin',
                                basePrice.end_date.get() != undefined ? Utils.toDDMMYY(basePrice.end_date.get()) : '',
                            )}
                        </p>
                        {basePrice.canAct() && (
                            <Button
                                variant="contained"
                                color={this.getButtonColor(basePrice.end_date.get() !== undefined)}
                                onClick={() => {
                                    this.openModal('end');
                                }}
                            >
                                {this.getIcon(basePrice.end_date.get() !== undefined)}
                            </Button>
                        )}
                    </div>
                )}

                {basePrice instanceof Devis && (
                    <div className="flex flex-1 flex-row items-center gap-2">
                        <p className={this.getClassName(basePrice.address.get() != null)}>
                            {this.getText('Adresse', basePrice.address.get()?.fullNameAddress() ?? '')}
                        </p>
                        {basePrice.canAct() && (
                            <Button
                                variant="contained"
                                color={this.getButtonColor(basePrice.address.get() != null)}
                                onClick={() => {
                                    this.openModal('address');
                                }}
                            >
                                {this.getIcon(basePrice.address.get() != null)}
                            </Button>
                        )}
                    </div>
                )}

                {basePrice instanceof Devis && (
                    <div className="flex flex-1 flex-row items-center gap-2">
                        <p className={this.getClassName(basePrice.agence.get() != null)}>
                            {this.getText('Agence', basePrice.agence.get()?.code.get() ?? '')}
                        </p>
                        {basePrice.canAct() && (
                            <Button
                                variant="contained"
                                color={this.getButtonColor(basePrice.agence.get() != null)}
                                onClick={() => {
                                    this.openModal('agence');
                                }}
                            >
                                {this.getIcon(basePrice.agence.get() != null)}
                            </Button>
                        )}
                    </div>
                )}

                {basePrice instanceof Devis && (
                    <div className="flex flex-1 flex-row items-center gap-2">
                        <p className={this.getClassName(basePrice.product.get() != null)}>
                            {this.getText('Offre', basePrice.product.get()?.name.get() ?? '')}
                        </p>
                        {basePrice.canAct() && (
                            <Button
                                variant="contained"
                                color={this.getButtonColor(basePrice.product.get() != null)}
                                onClick={() => {
                                    this.openModal('product');
                                }}
                            >
                                {this.getIcon(basePrice.product.get() != null)}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        );
    }

    onDone() {
        const { basePrice } = this.props;
        const { modal, start, end, participant, name, agence, product, address } = this.state;

        let params: any = {};

        if (modal === 'start') {
            let realStart = basePrice.start_date.get();
            if (realStart === undefined) {
                realStart = '';
            } else {
                realStart = Utils.isoString(new Date(realStart));
            }
            if (Utils.isoString(start ?? new Date()) !== realStart) {
                params['id'] = basePrice.getAjoIdentifier();
                params['start_date'] = Utils.isoString(start ?? new Date());
                if (basePrice.end_date.get() == undefined) {
                    params['end_date'] = Utils.isoString(start ?? new Date());
                }
            }
        } else if (modal === 'end') {
            let realEnd = basePrice.end_date.get();
            if (realEnd === undefined) {
                realEnd = '';
            } else {
                realEnd = Utils.isoString(new Date(realEnd));
            }
            if (Utils.isoString(end ?? new Date()) ?? '' !== realEnd) {
                params['id'] = basePrice.getAjoIdentifier();
                params['end_date'] = Utils.isoString(end ?? new Date());
            }
        } else if (modal === 'participant') {
            if (participant !== basePrice.nb_personne.get()) {
                params['id'] = basePrice.getAjoIdentifier();
                params['nb_personne'] = parseInt(participant);
            }
        } else if (modal === 'name') {
            if (name !== basePrice.nb_personne.get()) {
                params['id'] = basePrice.getAjoIdentifier();
                params['name'] = name;
            }
        } else if (modal === 'agence') {
            if (
                basePrice instanceof Devis &&
                (agence?.getAjoIdentifier() ?? '') !== (basePrice.agence.get()?.getAjoIdentifier() ?? '')
            ) {
                params['id'] = basePrice.getAjoIdentifier();
                if (agence == null) {
                    params['agence'] = {
                        id_del: basePrice.agence.get()?.getAjoIdentifier() ?? '',
                    };
                } else {
                    params['agence'] = {
                        id: agence.getAjoIdentifier(),
                    };
                }
            }
        } else if (modal === 'product') {
            if (
                basePrice instanceof Devis &&
                (product?.getAjoIdentifier() ?? '') !== (basePrice.product.get()?.getAjoIdentifier() ?? '')
            ) {
                params['id'] = basePrice.getAjoIdentifier();
                if (product == null) {
                    params['product'] = {
                        id_del: basePrice.product.get()?.getAjoIdentifier() ?? '',
                    };
                } else {
                    params['product'] = {
                        id: product.getAjoIdentifier(),
                    };
                }
            }
        } else if (modal === 'address') {
            if (
                basePrice instanceof Devis &&
                (address?.getAjoIdentifier() ?? '') !== (basePrice.address.get()?.getAjoIdentifier() ?? '')
            ) {
                params['id'] = basePrice.getAjoIdentifier();
                if (address == null) {
                    params['address'] = {
                        id_del: basePrice.address.get()?.getAjoIdentifier() ?? '',
                    };
                } else {
                    params['address'] = {
                        id: address.getAjoIdentifier(),
                    };
                }
            }
        }

        if (params['id'] !== undefined) {
            this.setState({ loading: true, error: ErrorHTTP.NO_ERROR() });
            post('/devis/update', params).then((res: any) => {
                this.setState({ loading: false, error: ErrorHTTP.NO_ERROR() });
                if (Result.isSuccess(res)) {
                    this.setState({ show: false });
                    AppManager.addToast(new Toast(errorManager.get(devisEditSuccess), 'success'));
                    Result.applyResult(res, AppManager.updater(), true);
                } else {
                    this.setState({ error: Result.getError(res) });
                }
            });
        }
    }
    onCancel() {
        this.setState(this.initState());
    }

    getContent() {
        const { basePrice } = this.props;
        const { modal, start, end, agence, address, product } = this.state;
        let res: JSXInternal.Element = <></>;
        if (modal === 'start') {
            res = (
                <DatePicker
                    className="flex-1 w-full"
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params: any) => {
                        return (
                            <TextField
                                variant="outlined"
                                onClick={(e: Event) => {
                                    e.stopPropagation();
                                }}
                                {...params}
                                onBlur={() => {}}
                                tabIndex={-1}
                                className="bg-gray-300 w-full placeholder:text-gray-600 !border-gray-600 border rounded-lg py-2 px-3 focus:!border focus:!ring focus:!ring-blue-500 focus:!border-blue-500"
                            />
                        );
                    }}
                    onChange={(e: any) => {
                        let newVal;
                        if (e !== null) {
                            newVal = (e as any).toDate();
                        } else {
                            newVal = null;
                        }
                        this.setState({
                            start: newVal,
                        });
                    }}
                    value={start}
                />
            );
        } else if (modal === 'end') {
            res = (
                <DatePicker
                    className="flex-1 w-full"
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params: any) => {
                        return (
                            <TextField
                                variant="outlined"
                                onClick={(e: Event) => {
                                    e.stopPropagation();
                                }}
                                {...params}
                                onBlur={() => {}}
                                tabIndex={-1}
                                className="bg-gray-300 w-full placeholder:text-gray-600 !border-gray-600 border rounded-lg py-2 px-3 focus:!border focus:!ring focus:!ring-blue-500 focus:!border-blue-500"
                            />
                        );
                    }}
                    onChange={(e: any) => {
                        let newVal;
                        if (e !== null) {
                            newVal = (e as any).toDate();
                        } else {
                            newVal = null;
                        }
                        this.setState({
                            end: newVal,
                        });
                    }}
                    value={end}
                />
            );
        } else if (modal === 'address') {
            res = (
                <AddressSelect
                    elem={address ?? undefined}
                    canAdd={true}
                    onObjectSelect={(a) => {
                        this.setState({ address: a });
                    }}
                    placeholder="Adresse de l'événement"
                />
            );
        } else if (modal === 'agence') {
            res = (
                <AgenceSelect
                    elem={agence ?? undefined}
                    canAdd={false}
                    onObjectSelect={(a) => {
                        this.setState({ agence: a });
                    }}
                    placeholder="Agence"
                />
            );
        } else if (modal === 'product') {
            res = (
                <ProductSelect
                    elem={product ?? undefined}
                    canAdd={true}
                    onObjectSelect={(a) => {
                        this.setState({ product: a });
                    }}
                    placeholder="Offre"
                />
            );
        } else if (modal === 'name') {
            res = (
                <ComplexInput
                    type={'text'}
                    typeValue="string"
                    placeholder="Nom du prix de vente"
                    value={this.state.name}
                    setValue={(e) => {
                        this.setState({ name: e });
                    }}
                />
            );
        } else if (modal === 'participant') {
            res = (
                <ComplexInput
                    type={'text'}
                    typeValue="number"
                    placeholder="Nombre de participant"
                    value={this.state.participant}
                    setValue={(e) => {
                        this.setState({ participant: e });
                    }}
                />
            );
        }
        return this.state.loading ? (
            <Box className="flex w-full items-center justify-center p-8">
                <Spinner color="blue" className={'w-10 h-10'} />
            </Box>
        ) : (
            <Box className="flex flex-col w-full">
                {res}

                {!this.state.error.equals(ErrorHTTP.NO_ERROR()) && (
                    <Typography component={'p'} className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center">
                        {errorManager.get(this.state.error)}
                    </Typography>
                )}
            </Box>
        );
    }
}
