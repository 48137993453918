import { Box, TextField, Typography } from '@mui/material';
import { Component, createRef } from 'react';
import Address from 'src/object/Address';
import AppManager from '../../commun/AppManager';
import { Spinner } from '../../commun/component/Spinner';
import { post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import { getErrorMessage } from '../../utils/Error';
import MpModal, { IBaseModalProps } from '../extends/MpModal';

export interface IAddressModalProps extends IBaseModalProps {
    address: Address | null;
    baseName?: string;
    onCreate?: (address: Address) => void;
}

export interface IAddressModalState {
    name: string;
    city: string;
    numberAndStreet: string;
    postalCode: string;
    bonus: string;
    loading: boolean;
    error: string;
}

export default class AddressModal extends Component<IAddressModalProps, IAddressModalState> {
    inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: IAddressModalProps) {
        super(props);

        this.state = this.initState();
        this.inputRef = createRef();
    }

    initState() {
        const p: IAddressModalState = {
            name: this.props.address?.name.get() ?? this.props.baseName ?? '',
            city: this.props.address?.city.get() ?? '',
            numberAndStreet: this.props.address?.number_and_street.get() ?? '',
            postalCode: this.props.address?.postal_code.get() ?? '',
            bonus: this.props.address?.bonus.get() ?? '',
            loading: false,
            error: '',
        };
        return p;
    }

    componentDidUpdate(
        prevProps: Readonly<IAddressModalProps>,
        prevState: Readonly<IAddressModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.open !== this.props.open) {
            this.setState(this.initState());
        }
        if (prevProps.open !== this.props.open && this.props.open) {
            setTimeout(() => {
                this.inputRef.current?.focus();
            });
        }
    }

    addPrestation() {
        const { loading, bonus, name, city, numberAndStreet, postalCode } = this.state;
        const { address } = this.props;
        this.setState({
            loading: true,
            error: '',
        });
        let params: any;
        let url: string;
        if (address === null) {
            url = '/address/update';
            params = {
                name: name,
                city: city,
                number_and_street: numberAndStreet,
                bonus: bonus,
                postal_code: parseInt(postalCode),
            };
        } else {
            url = '/address/update';
            params = {
                id: address.getAjoIdentifier(),
                name: name,
                city: city,
                number_and_street: numberAndStreet,
                bonus: bonus,
                postal_code: parseInt(postalCode),
            };
        }
        if (address !== null) {
            params['id'] = address.getAjoIdentifier();
        }
        post(url, params).then((res: any) => {
            this.setState({
                loading: false,
            });
            if (Result.isSuccess(res)) {
                Result.applyResult(res, AppManager.updater(), true);

                const p = Address.build();
                Result.applyResult(res, p, true);
                this.props.onCreate?.(p);

                this.props.onClose();
                if (address === null) {
                    AppManager.addToast(new Toast("L'adresse a été ajoutée avec succès.", 'success'));
                } else {
                    AppManager.addToast(new Toast("L'adresse a été modifiée avec succès.", 'success'));
                }
            } else {
                this.setState({
                    error: getErrorMessage(Result.getErrorStr(res)),
                });
            }
        });
    }

    render() {
        const { open, address, onClose } = this.props;
        const { loading, name, city, bonus, numberAndStreet, postalCode, error } = this.state;

        return (
            <MpModal
                open={open}
                onClose={onClose}
                onSubmit={() => {
                    this.addPrestation();
                }}
                title={address === null ? 'Ajouter une adresse' : 'Modifier une adresse'}
                done={address === null ? 'Ajouter' : 'Modifier'}
            >
                {loading ? (
                    <Box className="flex items-center justify-center py-12">
                        <Spinner color="blue" className="" />
                    </Box>
                ) : (
                    <Box className="flex flex-col !text-gray-600 gap-2">
                        <Typography className="!text-sm !font-semibold">NOM DE L'ADRESSE</Typography>
                        <TextField
                            inputProps={{
                                ref: this.inputRef,
                            }}
                            fullWidth
                            value={name}
                            onChange={(e) => {
                                this.setState({ name: (e.target as any)?.value ?? '' });
                            }}
                            placeholder="Nom de l'adresse"
                            variant="outlined"
                        />

                        <Typography className="!text-sm !font-semibold">NUMÉRO ET RUE</Typography>
                        <TextField
                            fullWidth
                            value={numberAndStreet}
                            onChange={(e) => {
                                this.setState({ numberAndStreet: (e.target as any)?.value ?? '' });
                            }}
                            placeholder="Numéro et rue"
                            variant="outlined"
                        />
                        <Typography className="!text-sm !font-semibold">CODE POSTAL</Typography>
                        <TextField
                            fullWidth
                            value={postalCode}
                            onChange={(e) => {
                                this.setState({ postalCode: (e.target as any)?.value ?? '' });
                            }}
                            type="number"
                            placeholder="Code postal"
                            variant="outlined"
                        />

                        <Typography className="!text-sm !font-semibold">VILLE</Typography>
                        <TextField
                            fullWidth
                            value={city}
                            onChange={(e) => {
                                this.setState({ city: (e.target as any)?.value ?? '' });
                            }}
                            placeholder="Ville"
                            variant="outlined"
                        />

                        <Typography className="!text-sm !font-semibold">BONUS</Typography>
                        <TextField
                            fullWidth
                            value={bonus}
                            onChange={(e) => {
                                this.setState({ bonus: (e.target as any)?.value ?? '' });
                            }}
                            placeholder="Bonus"
                            variant="outlined"
                        />

                        {error !== '' && (
                            <Typography
                                component={'p'}
                                className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                            >
                                {error}
                            </Typography>
                        )}
                    </Box>
                )}
            </MpModal>
        );
    }
}
