import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { Grid, Pagination } from '@mui/material';
import { PerfectTable } from '../../commun/table/PerfectTable';
import Utils from '../../commun/Utils';
import { AddIcon } from '../../icons/AddIcon';
import { SearchIcon } from '../../icons/SearchIcon';
import Category from '../../object/tag/Category';
import AddCategoryModal from './AddCategoryModal';
import { BinIcon } from '../../icons/BinIcon';
import { EditIcon } from '../../icons/EditIcon';
import DelCategoryModal from './DelCategoryModal ';
import { SwitchIcon } from '../../icons/SwitchIcon';
import FusionCategoryModal from './FusionCategoryModal';

export interface ICategoryTableProps {
    list: Category[];
    loading: boolean;
}

export const CategoryTable = (props: ICategoryTableProps) => {
    const [search, setSearch] = useState('');
    const [category, setCategory] = useState<Category | undefined>(undefined);

    const [addCategoryShow, setAddCategoryShow] = useState(false);
    const [delCategoryShow, setDelCategoryShow] = useState(false);
    const [fusionCategoryShow, setFusionCategoryShow] = useState(false);

    const list = props.list.filter((category: Category) => {
        return Utils.isSearch(search, category.name.get());
    });

    return (
        <Container maxWidth={false}>
            <Box sx={{ mb: 3, mt: 3 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        m: -1,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        Categorie
                    </Typography>
                    <Box sx={{ m: 1 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            className="!pl-3 gap-2"
                            onClick={() => {
                                setCategory(undefined);
                                setAddCategoryShow(true);
                            }}
                        >
                            <AddIcon />
                            Ajouter une catégorie
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Card>
                        <CardContent className="w-full">
                            <Box className="flex w-full items-center flex-row">
                                <Box sx={{ maxWidth: 500 }}>
                                    <TextField
                                        fullWidth
                                        value={search}
                                        onChange={(e) => {
                                            setSearch((e.target as any).value);
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SvgIcon color="action" fontSize="small">
                                                        <SearchIcon />
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder="Rechercher une catégorie"
                                        variant="outlined"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            <Box>
                <Grid container spacing={3}>
                    {list.map((value: Category, i: number) => {
                        return (
                            <Grid key={i} item lg={4} md={4} xs={6}>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    <CardContent
                                        className={'bg-' + value.getColor() + '-600 flex items-center justify-center'}
                                    >
                                        <Box>
                                            <Typography className="text-white !text-lg py-5 !font-bold text-center">
                                                {value.name.get()}
                                            </Typography>
                                        </Box>
                                    </CardContent>

                                    <Box className="flex flex-row p-2 gap-2">
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className="!pl-3 flex-1 gap-2"
                                            onClick={() => {
                                                setCategory(value);
                                                setAddCategoryShow(true);
                                            }}
                                        >
                                            <EditIcon />
                                            Modifier
                                        </Button>
                                        <Button
                                            color="success"
                                            variant="contained"
                                            className="!pl-3 flex-1 gap-2"
                                            onClick={() => {
                                                setCategory(value);
                                                setFusionCategoryShow(true);
                                            }}
                                        >
                                            <SwitchIcon />
                                            Fusionner
                                        </Button>
                                        <Button
                                            color="error"
                                            variant="contained"
                                            className="!pl-3 flex-1 gap-2"
                                            onClick={() => {
                                                setCategory(value);
                                                setDelCategoryShow(true);
                                            }}
                                        >
                                            <BinIcon />
                                            Supprimer
                                        </Button>
                                    </Box>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
            <AddCategoryModal
                category={category}
                open={addCategoryShow}
                onClose={() => {
                    setAddCategoryShow(false);
                }}
            />
            <FusionCategoryModal
                list={props.list}
                category={category ?? new Category()}
                open={fusionCategoryShow}
                onClose={() => {
                    setFusionCategoryShow(false);
                }}
            />
            <DelCategoryModal
                category={category ?? new Category()}
                open={delCategoryShow}
                onClose={() => {
                    setDelCategoryShow(false);
                }}
            />
        </Container>
    );
};
