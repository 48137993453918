import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { AJOList, AJOState } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'preact/hooks';
import AppManager from 'src/commun/AppManager';
import { Spinner } from 'src/commun/component/Spinner';
import { get, post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import { PerfectTable } from 'src/commun/table/PerfectTable';
import Toast from 'src/commun/toast/Toast';
import Utils from 'src/commun/Utils';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';
import { DashboardSideBar } from 'src/component/nav/DashboardSideBar';
import { TimerView } from 'src/component/TimerView';
import { AddIcon } from 'src/icons/AddIcon';
import { SearchIcon } from 'src/icons/SearchIcon';
import { errorManager } from 'src/modal/vendor/CategoryErrorManager';
import { Timer } from 'src/object/Timer';
import User from 'src/object/User';
import { BasePage } from '../component/BasePage';

export const RecupPage = () => {
    const [search, setSearch] = useState('');

    const [stateUserList, setStateUserList] = useState(new AJOState(new AJOList<User>(User)));
    stateUserList.setUpdate(setStateUserList);
    const [loadingUser, setLoadingUser] = useState(true);

    const fetchNow = () => {
        const d = new Date();
        d.setTime(d.getTime() - 1000 * 60 * 60 * 24 * 31);
        get('/timer/incomming_recup', {
            date: d.toISOString(),
        })
            .then((result) => {
                setLoadingUser(false);
                if (Result.isSuccess(result)) {
                    Result.applyResult(result, stateUserList);

                    for (let user of stateUserList.get()!.getList()) {
                        if (user.recup.get() === undefined) {
                            post('/timer/recup', {
                                id: user.getAjoIdentifier(),
                            })
                                .then((res: any) => {
                                    if (Result.isSuccess(res)) {
                                        Result.applyResult(res, stateUserList);
                                    }
                                })
                                .catch((err) => {});
                        }
                    }

                    /*post('/recup', {
                    id_list: haveIdList,
                }).then((res: any) => {
                    if (Result.isSuccess(res)) {
                        Result.applyResult(res, stateUserList);
                    }
                }).catch((err)=>{

                })
                post('/recup_comming', {
                    id_list: haveIdList,
                }).then((res: any) => {
                    if (Result.isSuccess(res)) {
                        Result.applyResult(res, stateUserList);
                    }
                }).catch((err)=>{
                })*/
                }
            })
            .catch((err) => {});
    };

    useEffect(() => {
        fetchNow();
    }, []);

    let list = [];
    for (const user of stateUserList.get()!.getList()) {
        for (const timer of user.timerList.getList()) {
            //if(timer.recup){
            list.push(timer);
        }
    }

    list = list.sort((a, b) => {
        return -a.getStartDate().getTime() + b.getStartDate().getTime();
    });

    return (
        <BasePage>
            <DashboardNavBar />
            <Container>
                <Box sx={{ mb: 3, mt: 3 }}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            m: -1,
                        }}
                    >
                        <Typography sx={{ m: 1 }} variant="h4">
                            {'Demande de récupération'}
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Card>
                            <CardContent className="">
                                <Box className="flex  items-center flex-row">
                                    <Box sx={{ maxWidth: 700 }}>
                                        <TextField
                                            fullWidth
                                            value={search}
                                            onChange={(e) => {
                                                setSearch((e.target as any).value);
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SvgIcon color="action" fontSize="small">
                                                            <SearchIcon />
                                                        </SvgIcon>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            placeholder="Rechercher une récupération"
                                            variant="outlined"
                                        />
                                    </Box>
                                    <Box className="flex-1"></Box>
                                    <Box></Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
                <Card sx={{ mb: 3 }}>
                    <PerfectTable
                        list={list}
                        columns={[
                            {
                                name: 'Demande',

                                canSort: true,
                                sort: 1,
                                funSort: (a: Timer, b: Timer) => {
                                    return a.ask.get() - b.ask.get();
                                },

                                render: (timer: Timer) => {
                                    return (
                                        <Typography className="!font-semibold !break-words text-black flex gap-1">
                                            {Utils.toDDMMYY(timer.getAskDate().toISOString())}
                                        </Typography>
                                    );
                                },
                            },
                            {
                                name: 'Nom',
                                hasFilter: true,
                                funFilter: (timer: Timer) => {
                                    return Utils.isSearch(
                                        search,
                                        (timer.getAjoParent()?.getAjoParent() as any)?.getFullName(),
                                    );
                                },

                                canSort: true,
                                sort: 0,
                                funSort: (a: Timer, b: Timer) => {
                                    return (a.getAjoParent()?.getAjoParent() as any)
                                        ?.getFullName()
                                        .localeCompare((b.getAjoParent()?.getAjoParent() as any)?.getFullName());
                                },

                                render: (d: Timer) => {
                                    return (
                                        <Typography variant="h6" className="!break-words">
                                            {(d.getAjoParent()?.getAjoParent() as any)?.getFullName()}
                                        </Typography>
                                    );
                                },
                            },
                            {
                                name: 'Date',

                                canSort: true,
                                sort: 0,
                                funSort: (a: Timer, b: Timer) => {
                                    return a.start.get() - b.start.get();
                                },

                                render: (timer: Timer) => {
                                    return (
                                        <Typography className="!font-semibold !break-words text-black flex gap-1">
                                            {Utils.toDDMMYY(timer.getStartDate().toISOString())}
                                        </Typography>
                                    );
                                },
                            },
                            {
                                name: 'Durée',

                                render: (timer: Timer) => {
                                    return (
                                        <div className="flex !break-words flex-row gap-2 items-center">
                                            <TimerView
                                                date={new Date(0)}
                                                canJour={false}
                                                dateEnd={new Date(timer.getDuration())}
                                            />
                                        </div>
                                    );
                                },
                            },
                            /*{
                                canSort: true,
                                sort: 0,
                                funSort: (a: Timer, b: Timer) => {
                                    let aUser = a.getAjoParent()!.getAjoParent() as User;
                                    let bUser = b.getAjoParent()!.getAjoParent() as User;

                                    if (aUser.recup.get() === undefined) {
                                        return 0;
                                    }
                                    if (bUser.recup.get() === undefined) {
                                        return 0;
                                    }

                                    return -aUser.global_recup.get() + bUser.global_recup.get();
                                },

                                name: 'Disponible',
                                render: (timer: Timer) => {
                                    let user = timer.getAjoParent()!.getAjoParent() as User;
                                    if (user.global_recup.get() === undefined) {
                                        return <p className={"!break-words"}>Chargement ...</p>;
                                    } else {
                                        return (
                                            <TimerView
                                                breakWord
                                                date={new Date(0)}
                                                dateEnd={new Date(user.global_recup.get() * 1000)}
                                            ></TimerView>
                                        );
                                    }
                                },
                            },
                            {
                                canSort: true,
                                sort: 0,
                                funSort: (a: Timer, b: Timer) => {
                                    let aUser = a.getAjoParent()!.getAjoParent() as User;
                                    let bUser = b.getAjoParent()!.getAjoParent() as User;

                                    if (aUser.global_take_recup.get() === undefined) {
                                        return 0;
                                    }
                                    if (bUser.global_take_recup.get() === undefined) {
                                        return 0;
                                    }

                                    return -aUser.global_take_recup.get() + bUser.global_take_recup.get();
                                },

                                name: 'Disponible posé',
                                render: (timer: Timer) => {
                                    let user = timer.getAjoParent()!.getAjoParent() as User;
                                    if (user.global_take_recup.get() === undefined) {
                                        return <p className={"!break-words"}>Chargement ...</p>;
                                    } else {
                                        return (
                                            <TimerView
                                            breakWord
                                                date={new Date(0)}
                                                dateEnd={new Date(user.global_take_recup.get() * 1000)}
                                            ></TimerView>
                                        );
                                    }
                                },
                            },*/
                            {
                                canSort: true,
                                sort: 0,
                                funSort: (a: Timer, b: Timer) => {
                                    let aUser = a.getAjoParent()!.getAjoParent() as User;
                                    let bUser = b.getAjoParent()!.getAjoParent() as User;

                                    if (aUser.global_take_recup.get() === undefined) {
                                        return 0;
                                    }
                                    if (bUser.global_take_recup.get() === undefined) {
                                        return 0;
                                    }

                                    return -aUser.global_take_recup.get() + bUser.global_take_recup.get();
                                },

                                name: 'Disponible à venir',
                                render: (timer: Timer) => {
                                    let user = timer.getAjoParent()!.getAjoParent() as User;
                                    if (user.global_recup.get() === undefined) {
                                        return <p className={'!break-words'}>Chargement ...</p>;
                                    } else {
                                        return (
                                            <TimerView
                                                breakWord
                                                date={new Date(0)}
                                                dateEnd={
                                                    new Date(
                                                        user.global_recup.get() * 1000 -
                                                            user.global_take_recup.get() * 1000,
                                                    )
                                                }
                                            ></TimerView>
                                        );
                                    }
                                },
                            },
                            {
                                name: 'Action',
                                render: (timer: Timer) => {
                                    if (timer.load) {
                                        return <Spinner color="blue" />;
                                    } else if (timer.state.get() !== undefined) {
                                        if (timer.state.get() === true) {
                                            return (
                                                <Typography className="!break-words text-green-700 !font-semibold">
                                                    Accepté
                                                </Typography>
                                            );
                                        } else {
                                            return (
                                                <Typography className="!break-words text-red-700 !font-semibold">
                                                    Refusé
                                                </Typography>
                                            );
                                        }
                                    } else {
                                        return (
                                            <div className="flex flex-row !break-words gap-2 items-center">
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    onClick={() => {
                                                        timer.load = true;
                                                        AppManager.updater().makeUpdate();
                                                        post('/timer/accept', { id: timer.getAjoIdentifier() }).then(
                                                            (res: any) => {
                                                                timer.load = false;
                                                                if (Result.isSuccess(res)) {
                                                                    Result.applyResult(res, AppManager.updater(), true);
                                                                    timer.state.set(true);
                                                                    AppManager.updater().makeUpdate();
                                                                    AppManager.addToast(
                                                                        new Toast(
                                                                            "L'élément a été accepté.",
                                                                            'success',
                                                                        ),
                                                                    );
                                                                } else {
                                                                    AppManager.addToast(
                                                                        new Toast(
                                                                            errorManager.get(Result.getError(res)),
                                                                            'error',
                                                                        ),
                                                                    );
                                                                }
                                                            },
                                                        );
                                                    }}
                                                >
                                                    Accepter
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => {
                                                        timer.load = true;
                                                        AppManager.updater().makeUpdate();
                                                        post('/timer/refuse', { id: timer.getAjoIdentifier() }).then(
                                                            (res: any) => {
                                                                timer.load = false;
                                                                AppManager.updater().makeUpdate();
                                                                if (Result.isSuccess(res)) {
                                                                    timer.state.set(false);
                                                                    Result.applyResult(res, AppManager.updater(), true);
                                                                    AppManager.addToast(
                                                                        new Toast("L'élément a été refusé.", 'success'),
                                                                    );
                                                                } else {
                                                                    AppManager.addToast(
                                                                        new Toast(
                                                                            errorManager.get(Result.getError(res)),
                                                                            'error',
                                                                        ),
                                                                    );
                                                                }
                                                            },
                                                        );
                                                    }}
                                                >
                                                    Refuser
                                                </Button>
                                            </div>
                                        );
                                    }
                                },
                            },
                        ]}
                        loading={loadingUser}
                    />
                </Card>
            </Container>
        </BasePage>
    );
};
