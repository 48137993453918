import React, { createRef, Fragment, useEffect, useRef, useState } from 'react';
import { ComplexInput } from './ComplexInput';
import AppManager from '../AppManager';
import { AJOElement, AJOObject } from 'mp-js-react-auto-json-object';
import Utils from '../Utils';
import { JSXInternal } from 'preact/src/jsx';

export interface IVirgineComplexSelectProps {
    color: 'red' | 'blue' | 'green' | 'yellow' | 'purple' | 'orange' | 'gray' | 'black' | 'white' | 'base';
    elemList?: AJOObject[];

    name: string;
    value: string;
    setValue: (value: string) => void;
    id: string;
    onIdChange: (select: string) => void;

    filter?: (elem: any, search: string) => boolean;

    loadDescription?: (elem: any) => string;
    loadTitle?: (elem: any) => string;

    newTitle?: string;

    onAdd?: (name: string) => void;
    cancelAdd?: () => void;
    add?: boolean;

    removeDuplicate?: (e1: any, e2: any) => boolean;
    onConfirmChange?: (selectName: string, selectId: string, tabOrder: boolean) => void;
    focusInput?: boolean;
    forceFocus?: boolean;

    onClick?: () => void;
    onFocus?: (e: JSXInternal.TargetedFocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: JSXInternal.TargetedFocusEvent<HTMLInputElement>) => void;
    placeholder?: string;
}

export const VirgineComplexSelect: React.FunctionComponent<IVirgineComplexSelectProps> = (props) => {
    const {
        removeDuplicate,
        color,

        id = '',
        onIdChange,
        name = '',
        value = '',
        setValue,

        elemList = [],
        newTitle = '',
        filter = () => true,
        loadDescription = () => '',
        loadTitle = () => '',
        onConfirmChange = () => {},
        focusInput = false,
        forceFocus = false,
        onAdd,
        onBlur = () => {},
        onFocus = () => {},
        cancelAdd,
        add = false,
        placeholder,
        ...rest
    } = props;

    useEffect(() => {
        if (focusInput) {
            input.current?.focus();
        }
    }, [focusInput]);

    let hasSelect = false;

    const [show, setShow] = useState(false);
    const input = useRef<HTMLInputElement>(null);

    let selectElem: any = null;
    let i = 0;
    while (selectElem == null && i < elemList.length) {
        if (elemList[i].getAjoIdentifier() === id) {
            selectElem = elemList[i];
        }
        i++;
    }

    const onChange = () => {
        setCompliteIndex(0);
    };
    const select = (elem: AJOElement, tabOrder: boolean = true) => {
        setShow(false);

        onIdChange(elem.getAjoIdentifier());
        setCompliteIndex(0);

        setValue(loadTitle(elem));
        hasSelect = true;

        onConfirmChange('', elem.getAjoIdentifier(), tabOrder);
    };
    const onKeyDown = (event: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => {
        if (event.code === AppManager.TAB_KEY || event.code === AppManager.ENTER_KEY) {
            let res: boolean = complite(true);
            if (res) {
                event.preventDefault();
                event.stopPropagation();
            }
        } else if (event.code === AppManager.ESCAPE_KEY) {
            setShow(false);

            let cc = input.current;
            if (cc != undefined) {
                (cc as HTMLInputElement).blur();
            }
        } else if (!show) {
            startInput();
        }
    };

    const selectNew = () => {
        if (add && onAdd != undefined) {
            onAdd(value);
            setShow(false);
            setCompliteIndex(filteredElemList.length);
            hasSelect = true;
            onConfirmChange(value, '', true);
        }
    };

    const startInput = () => {
        if (cancelAdd != undefined) {
            cancelAdd();
        }
        setShow(true);
        onIdChange('');
        setCompliteIndex(0);
        setFocus(true);
    };

    const complite = (tabOrder: boolean) => {
        let res = false;

        if (show) {
            if ((value != '' || tabOrder) && filteredElemList.length > compliteIndex) {
                select(filteredElemList[compliteIndex], tabOrder);
                res = true;
            } else if ((value != '' || tabOrder) && filteredElemList.length == compliteIndex) {
                if (add && onAdd != undefined) {
                    onAdd(value);
                    setShow(false);
                    setCompliteIndex(filteredElemList.length);
                    hasSelect = true;
                    res = true;
                    onConfirmChange(value, '', tabOrder);
                }
            }
        }
        return res;
    };

    const newLine = () => {
        let inList = false;
        let index = 0;
        while (!inList && filteredElemList.length > index) {
            inList = Utils.clean(value) === Utils.clean(loadTitle(filteredElemList[index]));
            index++;
        }
        return !inList && Utils.clean(value) != '' && add;
    };

    const [focus, setFocus] = useState(false);
    useEffect(() => {
        if (!focus) {
            setTimeout(() => {
                if (!hasSelect) {
                    if (!complite(false)) {
                        if (value === '') {
                            onConfirmChange('', '', false);
                        } else if (id == '' && value != '') {
                            if (add) {
                                onConfirmChange(value, id, false);
                            }
                        } else {
                            onConfirmChange(value, id, false);
                        }
                    }
                }
                setShow(input.current === document.activeElement);
            }, 350);
        } else {
            setShow(focus);
        }
    }, [focus]);

    const [compliteIndex, setCompliteIndex] = useState(0);

    let filteredElemList = value === '' ? elemList : elemList.filter((elem) => filter(elem, value));

    let compliteText = '';
    if (value != '' && show) {
        if (compliteIndex == filteredElemList.length) {
            compliteText = '';
        } else {
            try {
                compliteText = loadTitle(filteredElemList[compliteIndex]).slice(value.length);
            } catch (e) {}
        }
    }

    if (removeDuplicate !== undefined) {
        let i2 = 0;
        while (i2 < filteredElemList.length) {
            let elem = filteredElemList[i2];
            filteredElemList = filteredElemList.filter((e: any) => removeDuplicate(e, elem));
            i2++;
        }
    }

    let onFocusFinal = (event: JSXInternal.TargetedFocusEvent<HTMLInputElement>) => {
        onFocus(event);
        startInput();

        input.current?.scrollIntoView({
            behavior: 'auto',
            block: 'nearest',
            inline: 'nearest',
        });

        (event.target as any)?.select();
    };
    let onBlurFinal = (event: JSXInternal.TargetedFocusEvent<HTMLInputElement>) => {
        onBlur(event);
        setFocus(false);
    };

    const isSelect = (elem: AJOElement): boolean => {
        let equal = false;
        if (filteredElemList.length > compliteIndex) {
            equal = filteredElemList[compliteIndex].getAjoIdentifier() === elem.getAjoIdentifier();
        }
        return equal;
    };
    const onArrowDownKey = (event?: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => {
        if (event?.type === 'keydown') {
            if (compliteIndex < filteredElemList.length - 1 || (newLine() && compliteIndex < filteredElemList.length)) {
                setCompliteIndex(compliteIndex + 1);
                event?.preventDefault();
            }
        }
    };

    const onCtrlArrowDownKey = (event?: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => {
        if (event?.type === 'keydown') {
            if (
                (!newLine() && compliteIndex != filteredElemList.length - 1) ||
                (newLine() && compliteIndex != filteredElemList.length)
            ) {
                setCompliteIndex(filteredElemList.length - 1);
                event?.preventDefault();
            }
        }
    };
    const onArrowUpKey = (event?: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => {
        if (event?.type === 'keydown') {
            if (compliteIndex > 0) {
                setCompliteIndex(compliteIndex - 1);
                event?.preventDefault();
            }
        }
    };
    const onCtrlArrowUpKey = (event?: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>) => {
        if (event?.type === 'keydown') {
            if (compliteIndex != 0) {
                setCompliteIndex(0);
                event?.preventDefault();
            }
        }
    };

    let complexInput;
    complexInput = (
        <ComplexInput
            inputRef={input}
            focusInput={focusInput}
            onArrowDownKey={onArrowDownKey}
            onArrowUpKey={onArrowUpKey}
            onCtrlArrowDownKey={onCtrlArrowDownKey}
            onCtrlArrowUpKey={onCtrlArrowUpKey}
            beforeText={value}
            compliteText={compliteText}
            onKeyDown={onKeyDown}
            onFocus={onFocusFinal}
            onBlur={onBlurFinal}
            setValue={(e) => {
                setValue(e);
            }}
            forceFocus={forceFocus}
            placeholder={placeholder}
            autoComplete={'nope'}
            onChange={onChange}
            typeValue="select_search"
            {...rest}
            value={value}
        ></ComplexInput>
    );
    /*
 ref.current?.scrollIntoView({
            behavior: 'auto',
            block: "bottom",
            inline: 'center'
        });
*/
    const nothing = () => {
        return !newLine() && filteredElemList.length == 0;
    };

    let contentList;
    contentList = (
        <div
            className={
                'absolute z-[250] min-w-[16rem] right-0 left-0 mt-2 flex flex-col cursor-default gap-1 py-1 px-1 bg-gray-300 dark:bg-gray-600 rounded-lg text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm'
            }
        >
            <div className="flex flex-col">
                {filteredElemList.map((value, index) => (
                    <SelectElemView
                        value={value}
                        select={select}
                        isSelect={isSelect(value)}
                        title={loadTitle(value)}
                        description={loadDescription(value)}
                        key={index + '-' + value.getAjoIdentifier()}
                    />
                ))}
                {(newLine() || nothing()) && (
                    <div
                        onClick={() => {
                            if (!nothing()) {
                                selectNew();
                            }
                        }}
                        className={
                            (newLine() && compliteIndex == filteredElemList.length
                                ? 'bg-blue-400  dark:bg-blue-500'
                                : '') +
                            ' px-1.5 flex flex-col hover:bg-blue-700 hover:dark:bg-blue-600 rounded-md text-black dark:text-white hover:text-white py-1'
                        }
                    >
                        <div className="flex gap-1 flex-row">
                            {newLine() && compliteIndex == filteredElemList.length && (
                                <svg
                                    className="w-5 h-5"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                                    ></path>
                                </svg>
                            )}
                            <p>{nothing() ? 'Aucun résultat' : value}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
    return (
        <div>
            <div>
                {complexInput}
                <div
                    className={
                        (show ? '' : 'hidden') + ' ' + ' relative overflow-x-visible overflow-y-visible z-[150] h-0'
                    }
                >
                    {contentList}
                </div>
            </div>
        </div>
    );
};

interface ISelectElemViewProps {
    value: AJOObject;
    select: (value: AJOObject) => void;
    isSelect: boolean;
    title: string;
    description: string;
}

interface ISelectElemViewState {}

class SelectElemView extends React.Component<ISelectElemViewProps, ISelectElemViewState> {
    private ref: React.MutableRefObject<HTMLDivElement | null>;

    constructor(props: ISelectElemViewProps) {
        super(props);

        this.ref = createRef<HTMLDivElement>();
    }

    componentDidUpdate(
        prevProps: Readonly<ISelectElemViewProps>,
        prevState: Readonly<ISelectElemViewState>,
        snapshot?: any,
    ): void {
        if (this.props.isSelect != prevProps.isSelect && this.props.isSelect && this.ref.current) {
            if (this.ref.current != null && !Utils.isInViewport(this.ref.current)) {
                this.ref.current.scrollIntoView({
                    behavior: 'auto',
                    block: 'nearest',
                    inline: 'nearest',
                });
            }
        }
    }

    render() {
        const { value, select, isSelect, title, description } = this.props;

        return (
            <div
                ref={this.ref}
                onClick={() => {
                    select(value);
                }}
                className={
                    (isSelect ? 'bg-blue-400  dark:bg-blue-500' : '') +
                    ' px-1.5 flex flex-col hover:bg-blue-700 hover:dark:bg-blue-600 rounded-md text-black dark:text-white hover:text-white py-1'
                }
            >
                <div className="flex gap-1 flex-row">
                    {isSelect && (
                        <svg
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                            ></path>
                        </svg>
                    )}
                    <div className="font-medium">{title}</div>
                </div>
                <span className="text-sm">{description}</span>
            </div>
        );
    }
}
