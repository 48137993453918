import { AJOList, AJOProperties, AJOSimple } from 'mp-js-react-auto-json-object';
import { CategoryColInterface } from '../commun/col/CategoryCol';
import { ComColInterface } from '../commun/col/ComCol';
import { LabelColInterface } from '../commun/col/LabelCol';
import { NbPrestaColInterface } from '../commun/col/NbPresta';
import { NoteAverageColInterface } from '../commun/col/NoteAverageCol';
import { get } from '../commun/HTTP';
import Result from '../commun/Result';
import Budget from './Budget';
import { BudgetManager } from './BudgetManager';
import Category from './tag/Category';
import Label from './tag/Label';
import Prestation from './profile/Prestation';
import Utils from '../commun/Utils';
import AppManager from '../commun/AppManager';
import VendorLight from './profile/VendorLight';

export default class Service
    extends BudgetManager
    implements CategoryColInterface, LabelColInterface, NoteAverageColInterface, ComColInterface, NbPrestaColInterface
{
    public NbPrestaColInterface = true;
    public ComColInterface = true;
    public CategoryColInterface = true;
    public LabelColInterface = true;
    public NoteAverageColInterface = true;

    public static _TYPE: string = 'Service';

    public date_now: AJOProperties<any>;
    public vendor: AJOSimple<VendorLight>;
    public labelList: AJOList<Label>;
    public prestation: AJOSimple<Prestation>;
    public budgetList: AJOList<any>;

    constructor() {
        super(Service._TYPE, 'has_promesse');

        this.date_now = new AJOProperties('date_now', this);
        this.vendor = new AJOSimple(VendorLight, 'has_vendor_service', this);
        this.prestation = new AJOSimple(Prestation, 'has_prestation_service', this);
        this.budgetList = new AJOList(Budget, 'has_promesse', this) as any;
        this.labelList = new AJOList(Label, 'has_service_label', this);
    }

    override getBudgetName(): string {
        return this.prestation.get()?.name.get() ?? '';
    }

    public static build(): Service {
        return new Service();
    }

    public getCategoryList(): Category[] {
        return this.prestation.get()?.getCategoryList() ?? [];
    }

    public getLabelList(): Label[] {
        return this.labelList.getList();
    }

    public facteurPage(): number {
        return 1;
    }

    public serviceClientListFetch = false;
    public serviceClientListStart = false;
    public serviceClientListLoading = true;

    public fetchClientListInfo(): void {
        if (!this.serviceClientListFetch && !this.serviceClientListStart) {
            this.serviceClientListLoading = true;
            this.serviceClientListStart = true;
            get('/service/client', {
                id: this.getAjoIdentifier(),
            }).then((res: any) => {
                if (Result.isSuccess(res)) {
                    this.serviceClientListFetch = true;
                    this.serviceClientListLoading = false;
                    this.serviceClientListStart = false;
                    Result.applyResult(res, this, true);
                    Result.applyResult(res, AppManager.updater(), true);
                    AppManager.updater().makeUpdate();
                }
            });
        }
    }

    public getNbPresta(): number {
        return this.budgetList.size();
    }

    public static bonusFilter = (search: string) => {
        return (service: Service) => {
            const finalSearch = Utils.clean(search.toLowerCase());
            let can = false;
            if (service.prestation.get() != null) {
                can = Utils.clean(service.prestation.get()!.name.get()).toLowerCase().includes(finalSearch);
            } else if (service.vendor.get() != null) {
                can = Utils.clean(service.vendor.get()!.name.get()).toLowerCase().includes(finalSearch);
            }
            if (!can) {
                let i = 0;
                while (!can && i < service.getCategoryList().length) {
                    can = Utils.clean(service.getCategoryList()[i].name.get()).toLowerCase().includes(finalSearch);
                    i++;
                }
                i = 0;
                while (!can && i < service.getLabelList().length) {
                    can = Utils.clean(service.getLabelList()[i].name.get()).toLowerCase().includes(finalSearch);
                    i++;
                }
            }
            return can;
        };
    };
}
