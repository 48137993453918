import { get } from '../../commun/HTTP';
import Result from '../../commun/Result';
import Permission from '../../object/Permission';
import User from '../../object/User';
import { JSXInternal } from 'preact/src/jsx';
import { IObjectSelectProps, ObjectSelect } from './ObjectSelect';

export interface IUserSelectProps extends IObjectSelectProps<User> {
    placeholder: string;
}

export class UserSelect extends ObjectSelect<User, IUserSelectProps> {
    createGenericObject() {
        return User;
    }

    constructor(props: IUserSelectProps) {
        /**
         * DEFAULT PROPS
         */

        super(props, Permission.MANAGE_USER, 'user');

        /**
         * CREATE STATE
         */
        this.state = this.initState();

        // FUNCTION USE TO NOTIFY PARENT COMPONENT
        this.state.ajoList.setUpdate(() => this.forceUpdate());
    }

    fetchList() {
        if (this.state.isAjoListInflate) {
            get('/user/list').then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, this.state.ajoList, true);
                }
                this.setState({
                    isAjoListInflate: true,
                });
            });
        }
    }

    renderModal(): JSXInternal.Element {
        const { elem } = this.state;
        let obj = this;
        return <></>;
    }

    render(): JSXInternal.Element {
        return super.render();
    }
}
