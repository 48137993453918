import { AJOList, AJOObject, AJOProperties, AJOSimple, AJOUpdater } from 'mp-js-react-auto-json-object';
import AppManager from '../../commun/AppManager';
import { CategoryColInterface } from '../../commun/col/CategoryCol';
import { LabelColInterface } from '../../commun/col/LabelCol';
import { NameColInterface } from '../../commun/col/NameCol';
import { NbPrestaColInterface } from '../../commun/col/NbPresta';
import { PrestationColInterface } from '../../commun/col/PrestationCol';
import { Color } from '../../commun/Constant';
import { get, post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import { IObjectSelectable } from '../../component/select/ObjectSelect';
import Address from '../Address';
import { Comptable } from '../Comptable';
import Contact from '../Contact';
import ParentSevice from './ParentSevice';
import Prestation from './Prestation';

export default class VendorLight extends AJOObject {
    public static _TYPE: string = 'Vendor';

    public description: AJOProperties<any>;

    public contactList: AJOList<Contact>;
    public facturation: AJOSimple<Address>;

    public name: AJOProperties<any>;

    constructor() {
        super(VendorLight._TYPE);

        this.name = new AJOProperties('name');
        this.description = new AJOProperties('description', this);

        this.contactList = new AJOList(Contact, 'has_vendor_contact', this);
        this.facturation = new AJOSimple(Address, 'has_facturation', this);
    }

    public get(id: any, callback: () => void): void {
        const obj = this;
        get('/vendor/get', { id: id }).then((data) => {
            Result.applyResult(data, obj, true);
            callback();
        });
    }
    public getStat(id: any, callback: () => void): void {
        const obj = this;
        get('/vendor/get/stat', { id: id }).then((data) => {
            Result.applyResult(data, obj, true);
            callback();
        });
    }
    public getName(): string {
        return this.name.get();
    }
    public getLink(): string {
        return `/vendor/${this.getAjoIdentifier()}`;
    }
    public prestationListFetch = false;
    public prestationListStart = false;
    public prestationListLoading = true;

    public fetchPrestationListInfo(): void {
        if (!this.prestationListFetch && !this.prestationListStart) {
            this.prestationListLoading = true;
            this.prestationListStart = true;
            get('/vendor/prestation', {
                id: this.getAjoIdentifier(),
            }).then((res: any) => {
                if (Result.isSuccess(res)) {
                    this.prestationListFetch = true;
                    this.prestationListLoading = false;
                    this.prestationListStart = false;
                    Result.applyResult(res, this, true);
                    Result.applyResult(res, AppManager.updater(), true);
                    AppManager.updater().makeUpdate();
                }
            });
        }
    }
}
