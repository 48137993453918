import AppManager from '../../commun/AppManager';
import { Button } from '../../commun/component/Button';
import { get } from '../../commun/HTTP';
import Result from '../../commun/Result';
import { AddElemClientModal } from '../../modal/vendor/AddElemClientModal';
import { Action } from '../../object/Action';
import Address from '../../object/Address';
import Devis from '../../object/Devis';
import Permission from '../../object/Permission';
import Client from '../../object/profile/Client';
import { JSXInternal } from 'preact/src/jsx';
import { IObjectSelectProps, IObjectSelectState, ObjectSelect } from './ObjectSelect';

export interface IDevisClientSelectProps extends IObjectSelectProps<Devis | Action> {
    placeholder: string;
}

export class DevisClientSelect extends ObjectSelect<Devis | Action, IDevisClientSelectProps> {
    createGenericObject() {
        return [Devis, Action];
    }
    typeList(): string[] {
        return ['Devis', 'Action'];
    }
    constructor(props: IDevisClientSelectProps) {
        /**
         * DEFAULT PROPS
         */

        super(props, Permission.MANAGE_CLIENT, 'devis_client');

        /**
         * CREATE STATE
         */
        this.state = this.initState();

        // FUNCTION USE TO NOTIFY PARENT COMPONENT
        this.state.ajoList.setUpdate(() => this.forceUpdate());
    }

    fetchList() {
        if (this.state.isAjoListInflate) {
            get('/devis_client/list').then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, this.state.ajoList, true);
                    this.state.ajoList.getList().sort((a, b) => {
                        let res: number = 0;
                        if (a instanceof Devis) {
                            res = -1;
                        } else if (a instanceof Client && b instanceof Devis) {
                            res = 1;
                        } else if (a instanceof Client && b instanceof Action) {
                            res = -1;
                        } else if (a instanceof Action) {
                            res = 1;
                        }
                        return res;
                    });
                }
                this.setState({
                    isAjoListInflate: true,
                });
            });
        }
    }

    renderModal(): JSXInternal.Element {
        const { elem } = this.state;
        let obj = this;
        if (elem instanceof Devis) {
            return (
                <AddElemClientModal
                    show={this.state.showModal}
                    mode="devis"
                    refElem={elem}
                    onCreate={(elem: any) => {
                        this.props.onObjectSelect(elem);
                    }}
                    clientBase={null}
                    baseName={this.state.search}
                    templateList={[]}
                    handleClose={() => {
                        this.setState({
                            showModal: false,
                        });
                    }}
                />
            );
        } else if (elem instanceof Client) {
            return (
                <AddElemClientModal
                    show={this.state.showModal}
                    mode="client"
                    refElem={elem}
                    onCreate={(elem: any) => {
                        this.props.onObjectSelect(elem);
                    }}
                    clientBase={null}
                    baseName={this.state.search}
                    templateList={[]}
                    handleClose={() => {
                        this.setState({
                            showModal: false,
                        });
                    }}
                />
            );
        } else if (elem instanceof Action) {
            return (
                <AddElemClientModal
                    show={this.state.showModal}
                    mode="client"
                    refElem={elem}
                    onCreate={(elem: any) => {
                        this.props.onObjectSelect(elem);
                    }}
                    clientBase={null}
                    baseName={this.state.search}
                    templateList={[]}
                    handleClose={() => {
                        this.setState({
                            showModal: false,
                        });
                    }}
                />
            );
        } else {
            return (
                <AddElemClientModal
                    show={this.state.showModal}
                    mode="both_action"
                    onCreate={(elem: any) => {
                        this.props.onObjectSelect(elem);
                    }}
                    clientBase={null}
                    baseName={this.state.search}
                    templateList={[]}
                    handleClose={() => {
                        this.setState({
                            showModal: false,
                        });
                    }}
                />
            );
        }
    }

    render(): JSXInternal.Element {
        AppManager.updater().add(this.state.ajoList);
        return super.render();
    }
}
