import { AJOElement, AJOObject, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { JSXInternal } from 'preact/src/jsx';
import React from 'react';
import { TagObject } from '../../object/tag/TagObject';
import { Color, Size } from '../Constant';
import ErrorHTTP from '../ErrorHTTP';
import ErrorManager from '../ErrorManager';
import { Col } from './Col';
import SubTable from './SubTable';

export type TypeTable = 'info' | 'stat';

export default class Table<Type extends AJOObject> extends AJOElement {
    public applyData() {
        return true;
    }
    public count(): number {
        return 0;
    }
    public removeChild(child: AJOElement): void {}
    public accept(child: AJOElement): boolean {
        return true;
    }
    public replaceChild(oldChild: AJOElement, newChild: AJOElement): void {}
    public applyDataPartiel() {
        return true;
    }
    public getAjoType() {
        return '';
    }
    public getAjoIdentifier() {
        return '';
    }
    public clear(): void {
        // Nothing to do
    }

    private _isStrong: (elem: Type) => boolean = () => false;
    public get isStrong(): (elem: Type) => boolean {
        return this._isStrong;
    }
    public set isStrong(value: (elem: Type) => boolean) {
        this._isStrong = value;
    }

    private _isAlpha: (elem: Type) => boolean = () => false;
    public get isAlpha(): (elem: Type) => boolean {
        return this._isAlpha;
    }
    public set isAlpha(value: (elem: Type) => boolean) {
        this._isAlpha = value;
    }

    private _bonusContour: ((elem: Type, content: JSXInternal.Element) => JSXInternal.Element) | undefined;
    public get bonusContour(): ((elem: Type, content: JSXInternal.Element) => JSXInternal.Element) | undefined {
        return this._bonusContour;
    }
    public set bonusContour(value: ((elem: Type, content: JSXInternal.Element) => JSXInternal.Element) | undefined) {
        this._bonusContour = value;
    }

    private _filterTag: (elem: Type, tag: TagObject) => boolean = () => false;
    public get filterTag(): (elem: Type, tag: TagObject) => boolean {
        return this._filterTag;
    }
    public set filterTag(value: (elem: Type, tag: TagObject) => boolean) {
        this._filterTag = value;
    }

    private _focused: (elem: Type) => boolean = () => false;
    public get focused(): (elem: Type) => boolean {
        return this._focused;
    }
    public set focused(value: (elem: Type) => boolean) {
        this._focused = value;
    }

    // Fonction used to determine if elment is out of tag
    private _outOfTag: (elem: Type) => boolean = () => false;
    public get outOfTag(): (elem: Type) => boolean {
        return this._outOfTag;
    }
    public set outOfTag(value: (elem: Type) => boolean) {
        this._outOfTag = value;
    }
    // boolean indicates if table got multiple section
    private _taggedTable: boolean = false;
    public get taggedTable(): boolean {
        return this._taggedTable;
    }
    public set taggedTable(value: boolean) {
        this._taggedTable = value;
        this.makeUpdate();
    }

    private _listTag: TagObject[] = [];
    public get listTag(): TagObject[] {
        return this._listTag;
    }
    public set listTag(value: TagObject[]) {
        this._listTag = value;
    }

    // boolean indicate if info start to load
    private _child: Table<any> | null = null;
    public get child(): Table<any> | null {
        return this._child;
    }
    public set child(value: Table<any> | null) {
        this._child = value;
    }

    // boolean indicate if info start to load
    private _infoStart: boolean = false;
    public get infoStart(): boolean {
        return this._infoStart;
    }
    public set infoStart(value: boolean) {
        this._infoStart = value;
    }

    // boolean indicate if info is load
    private _infoLoad: boolean = false;
    public get infoLoad(): boolean {
        return this._infoLoad;
    }
    public set infoLoad(value: boolean) {
        this._infoLoad = value;
    }

    // boolean indicate if stat start to load
    private _statStart: boolean = false;
    public get statStart(): boolean {
        return this._statStart;
    }
    public set statStart(value: boolean) {
        this._statStart = value;
    }

    // boolean indicate if stat is load
    private _statLoad: boolean = false;
    public get statLoad(): boolean {
        return this._statLoad;
    }
    public set statLoad(value: boolean) {
        this._statLoad = value;
    }

    // Bg apply to table
    private _bg: Color | undefined;
    public get bg(): Color | undefined {
        return this._bg;
    }
    public set bg(value: Color | undefined) {
        this._bg = value;
        this.makeUpdate();
    }

    // Table owner is the table that contains the subTable (this)
    private _parent: Table<AJOObject> | null = null;
    public get parent(): Table<AJOObject> | null {
        return this._parent;
    }
    public set parent(value: Table<AJOObject> | null) {
        this._parent = value;
        this.makeUpdate();
    }

    // type of table
    private _type: TypeTable = 'info';
    public get type(): TypeTable {
        if (this.parent == null) {
            return this._type;
        } else {
            return this.parent.type;
        }
    }
    public set type(value: TypeTable) {
        this._type = value;
        this.makeUpdate();
    }
    // Columns of the table
    private _colList: Col<Type>[] = [];
    public get colList(): Col<Type>[] {
        return this._colList;
    }
    public set colList(value: Col<Type>[]) {
        this._colList = value;
        this.makeUpdate();
    }

    // List of element to display in the table
    private _list: Type[] = [];
    public get list(): Type[] {
        return this._list;
    }
    public set list(value: Type[]) {
        this._list = value;
        this.makeUpdate();
    }

    // Key of the table
    private _key: string;
    public get key(): string {
        return this._key;
    }
    public set key(value: string) {
        this._key = value;
        this.makeUpdate();
    }

    // Show top first row
    private _showTopRow: boolean = false;
    public get showTopRow(): boolean {
        return this._showTopRow;
    }
    public set showTopRow(value: boolean) {
        this._showTopRow = value;
        this.makeUpdate();
    }

    // Is the first row is loading
    private _loadTopRow: boolean = false;
    public get loadTopRow(): boolean {
        return this._loadTopRow;
    }
    public set loadTopRow(value: boolean) {
        this._loadTopRow = value;
        this.makeUpdate();
    }

    // Is the table is loading
    private _loading: boolean = false;
    public get loading(): boolean {
        return this._loading;
    }
    public set loading(value: boolean) {
        this._loading = value;
        this.makeUpdate();
    }

    // List of elem selected
    private _selectList: Type[] = [];
    public get selectList(): Type[] {
        return this._selectList;
    }
    public set selectList(value: Type[]) {
        this._selectList = value;
        this.makeUpdate();
    }

    // State of the table
    private _state: AJOState<Table<Type>> | null = null;
    public get state(): AJOState<Table<Type>> | null {
        return this._state;
    }
    public set state(value: AJOState<Table<Type>> | null) {
        this._state = value;
        for (let col of this._colList) {
            col.state = value;
        }
        //this.makeUpdate();
    }

    // Error top row
    private _errorTopRow: ErrorHTTP = ErrorHTTP.NO_ERROR();
    public get errorTopRow(): ErrorHTTP {
        return this._errorTopRow;
    }
    public set errorTopRow(value: ErrorHTTP) {
        this._errorTopRow = value;
        this.makeUpdate();
    }

    // Error manager of the table
    private _errorManager: ErrorManager = new ErrorManager('');
    public get errorManager(): ErrorManager {
        return this._errorManager;
    }
    public set errorManager(value: ErrorManager) {
        this._errorManager = value;
        this.makeUpdate();
    }

    // boolean that indicates if table contain subTable
    private _subTable: boolean = false;
    public get subTable(): boolean {
        return this._subTable;
    }
    public set subTable(value: boolean) {
        this._subTable = value;
    }

    private _bgFunc: (elem: Type) => Color | string = (elem: Type) => '';
    public get bgFunc(): (elem: Type) => Color | string {
        return this._bgFunc;
    }
    public set bgFunc(value: (elem: Type) => Color | string) {
        this._bgFunc = value;
        this.makeUpdate();
    }

    // sub Table list render
    private _subTableRender: (elem: any) => JSX.Element | undefined = () => {
        return undefined;
    };
    public get subTableRender(): (elem: any) => JSX.Element | undefined {
        return this._subTableRender;
    }
    public set subTableRender(value: (elem: any) => JSX.Element | undefined) {
        this._subTableRender = value;
        this.makeUpdate();
    }

    // sub list render
    private _subRender: (elem: any) => JSX.Element | undefined = () => {
        return undefined;
    };
    public get subRender(): (elem: any) => JSX.Element | undefined {
        return this._subRender;
    }
    public set subRender(value: (elem: any) => JSX.Element | undefined) {
        this._subRender = value;
        this.makeUpdate();
    }

    // render top table
    private _topTableRender: () => JSX.Element | undefined = () => {
        return undefined;
    };
    public get topTableRender(): () => JSX.Element | undefined {
        return this._topTableRender;
    }
    public set topTableRender(value: () => JSX.Element | undefined) {
        this._topTableRender = value;
        this.makeUpdate();
    }

    // render bottom table
    private _bottomTableRender: () => JSX.Element | undefined = () => {
        return undefined;
    };
    public get bottomTableRender(): () => JSX.Element | undefined {
        return this._bottomTableRender;
    }
    public set bottomTableRender(value: () => JSX.Element | undefined) {
        this._bottomTableRender = value;
        this.makeUpdate();
    }

    // render conclude top table
    private _concludeTopTableRender: () => JSX.Element | undefined = () => {
        return undefined;
    };
    public get concludeTopTableRender(): () => JSX.Element | undefined {
        return this._concludeTopTableRender;
    }
    public set concludeTopTableRender(value: () => JSX.Element | undefined) {
        this._concludeTopTableRender = value;
        this.makeUpdate();
    }

    // get line style based of elem
    private _lineStyle: (elem: Type) => string = () => '';
    public get lineStyle(): (elem: Type) => string {
        return this._lineStyle;
    }
    public set lineStyle(value: (elem: Type) => string) {
        this._lineStyle = value;
        this.makeUpdate();
    }

    // get link based of elem
    private _link: (elem: Type) => string = () => '';
    public get link(): (elem: Type) => string {
        return this._link;
    }
    public set link(value: (elem: Type) => string) {
        this._link = value;
        this.makeUpdate();
    }

    // render when no element
    private _nothingRender: () => JSX.Element | undefined = () => {
        return undefined;
    };
    public get nothingRender(): () => JSX.Element | undefined {
        return this._nothingRender;
    }
    public set nothingRender(value: () => JSX.Element | undefined) {
        this._nothingRender = value;
        this.makeUpdate();
    }

    // bonus filter for the table
    private _bonusFilter: (elem: any) => boolean = () => {
        return true;
    };
    public get bonusFilter(): (elem: any) => boolean {
        return this._bonusFilter;
    }
    public set bonusFilter(value: (elem: any) => boolean) {
        this._bonusFilter = value;
        this.makeUpdate();
    }

    // bonus sort for the table
    private _bonusSort: (a: any, b: any) => number = () => {
        return 0;
    };
    public get bonusSort(): (a: any, b: any) => number {
        return this._bonusSort;
    }
    public set bonusSort(value: (a: any, b: any) => number) {
        this._bonusSort = value;
        this.makeUpdate();
    }

    // Show param for table
    private _showParam: boolean = false;
    public get showParam(): boolean {
        return this._showParam;
    }
    public set showParam(value: boolean) {
        this._showParam = value;
        this.makeUpdate();
    }

    // Should row have background
    private _backgoroundRow: boolean = false;
    public get backgoroundRow(): boolean {
        return this._backgoroundRow;
    }
    public set backgoroundRow(value: boolean) {
        this._backgoroundRow = value;
    }

    // boolean indicate if change have to be notidy
    private _preventUpdate: boolean = false;
    public get preventUpdate(): boolean {
        return this._preventUpdate;
    }
    public set preventUpdate(value: boolean) {
        this._preventUpdate = value;
    }

    // boolean indicate if change have to be notidy
    private _size: Size = 'md';
    public get size(): Size {
        return this._size;
    }
    public set size(value: Size) {
        this._size = value;
    }

    constructor(key: string) {
        super();
        this._key = key;
    }
    public addCol(col: Col<Type>): Table<Type> {
        this.colList.push(col);
        this.makeUpdate();
        return this;
    }

    public makeUpdate() {
        if (this.state && !this.preventUpdate) {
            this.state.makeUpdate();
        }
    }

    public isSelect(obj: Type): boolean {
        return this.selectList.find((e: Type) => obj.getAjoIdentifier() === e.getAjoIdentifier()) !== undefined;
    }

    public removeFromSelectList(obj: Type) {
        this.selectList = this.selectList.filter((elem) => elem !== obj);
        this.makeUpdate();
    }

    public fit(obj: Table<any>): void {
        this.showTopRow = obj.showTopRow;
    }

    public addToSelectList(obj: Type) {
        this.selectList = [];
        this.selectList.push(obj);
        this.makeUpdate();
    }

    public hasGlobalFilter(): boolean {
        return false;
    }

    public resetSenseSort() {
        this.colList.forEach((col) => {
            col.senseSort = 0;
        });
    }

    public resetFilter() {
        this.colList.forEach((col) => {
            col.resetFilter();
        });
    }

    public numCol(): number {
        let count = 0;
        this.colList.forEach((col) => {
            if (col.isVisible()) {
                count++;
            }
        });
        return count;
    }

    public passToStat() {
        let tete: Table<any> | null = this;
        while (tete.parent != null) {
            tete.resetFilter();
            tete = tete.parent;
        }
        tete.resetFilter();
        tete.type = 'stat';
    }

    public passToInfo() {
        let tete: Table<any> | null = this;
        while (tete.parent != null) {
            tete.resetFilter();
            tete = tete.parent;
        }
        tete.resetFilter();
        tete.type = 'info';
    }
}
