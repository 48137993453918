import { AJOList, AJOObject } from 'mp-js-react-auto-json-object';
import { useEffect, useRef, useState } from 'react';
import Address from 'src/object/Address';
import BasePrice from 'src/object/BasePrice';
import AppManager from '../../commun/AppManager';
import Autocomplete from '../../commun/component/Autocomplite';
import { post } from '../../commun/HTTP';
import { PerfectAJOList } from '../../commun/object/PerfectAJOList';
import Budget from '../../object/Budget';
import AddressModal from '../address/AddressModal';
import { CustomSelect } from './CustomSelect';

export interface IAddressSelectProps {
    addressList: PerfectAJOList<Address>;
    vendor?: boolean;
    budget: Budget;
    basePrice: BasePrice;
}

export const AddressSelect = (props: IAddressSelectProps) => {
    const { addressList, vendor = false, budget, basePrice } = props;
    const ref = useRef<HTMLInputElement>();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');

    return (
        <>
            <CustomSelect
                ref={ref}
                id={'address-' + budget.getAjoIdentifier()}
                disabled={budget.bon_commande.get() !== null || !basePrice.canAct()}
                list={addressList.getList()}
                value={vendor ? budget.vendor.get()?.facturation.get() ?? null : budget.address.get()}
                getValue={(elem: Address) => elem.fullNameAddress() ?? ''}
                placeholder={vendor ? 'Adresse du fournisseur' : 'Adresse de livraison'}
                onBlur={() => {
                    budget.leaveFocus();
                }}
                onFocus={() => {
                    setName('');
                    addressList?.need();
                    budget.getFocus();
                }}
                onCreate={(str: string) => {
                    setName(str);
                    setOpen(true);
                }}
                onSelect={(item: any) => {
                    if (vendor) {
                        if (item instanceof Address) {
                            budget.vendor.get()?.facturation.set(item);
                            post('/vendor/update', {
                                id: budget.vendor.get()?.getAjoIdentifier(),
                                facturation: {
                                    id: item.getAjoIdentifier(),
                                },
                            });
                            AppManager.makeUpdate();
                            budget.focusVendor();
                        } else if (budget.vendor.get()?.facturation.get() !== null) {
                            budget.vendor.get()?.facturation.set(null);
                            post('/vendor/update', {
                                id: budget.vendor.get()?.getAjoIdentifier(),
                                facturation: {
                                    id_del: item.getAjoIdentifier(),
                                },
                            });
                            AppManager.makeUpdate();
                        }
                    } else {
                        if (item instanceof Address) {
                            budget.address.set(item);
                            budget.addEdit({
                                address: item.getAjoIdentifier(),
                            });
                            AppManager.makeUpdate();
                            budget.focusVendor();
                        } else if (budget.address.get() !== null) {
                            budget.address.set(null);
                            budget.addEdit({
                                address: -1,
                            });
                            AppManager.makeUpdate();
                        }
                    }
                }}
            />
            <AddressModal
                address={null}
                baseName={name}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                onCreate={(address: Address) => {
                    if (vendor) {
                        post('/vendor/update', {
                            id: budget.vendor.getAjoIdentifier(),
                            facturation: {
                                id: address.getAjoIdentifier(),
                            },
                        });
                        budget.vendor.get()?.facturation.set(address);
                        AppManager.makeUpdate();
                    } else {
                        budget.addEdit({
                            address: {
                                id: address.getAjoIdentifier(),
                            },
                        });
                        budget.address.set(address);
                        AppManager.makeUpdate();
                    }
                }}
            />
        </>
    );
};
