import { AJOObject, AJOProperties } from 'mp-js-react-auto-json-object';

export default class Valeur extends AJOObject {
    public static _TYPE: string = 'Valeur';

    public description: AJOProperties<any>;
    public pourcent: AJOProperties<any>;

    constructor() {
        super(Valeur._TYPE);

        this.description = new AJOProperties('description');
        this.pourcent = new AJOProperties('pourcent');
    }

    public pourcentMath(): number {
        return this.pourcent.get() / 100.0 + 1.0;
    }

    public static build(): Valeur {
        return new Valeur();
    }
}
