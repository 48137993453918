import { useState } from 'preact/hooks';
import AppManager from 'src/commun/AppManager';
import { Button } from 'src/commun/component/Button';
import Budget from 'src/object/Budget';
import { Paper } from 'src/object/Paper';
import MpModal from '../extends/MpModal';
import HonoraireModal from '../prestation/HonoraireModal';
import PrestationModal from '../prestation/PrestationModal';
import VendorModal from '../vendor/VendorModal';
import { AliasBudgetModal } from './AliasBudgetModal';
import { DelBudgetModal } from './DelBudgetModal';
import PriceModal from './PriceModal';

interface IBudgetActionModalProps {
    budget: Budget;
}

export const BudgetActionModal = (props: IBudgetActionModalProps) => {
    const { budget } = props;

    const [open, setOpen] = useState(false);

    const [openDelete, setOpenDelete] = useState(false);
    const [openPrestation, setOpenPrestation] = useState(false);
    const [openHonoraire, setOpenHonoraire] = useState(false);
    const [openVendor, setOpenVendor] = useState(false);
    const [openAlias, setOpenAlias] = useState(false);
    const [openPrix, setOpenPrix] = useState(false);

    const classNameMenu =
        'w-full text-center text-base px-4 py-3 hover:bg-blue-700 font-medium hover:text-white text-blue-500 text-sm px-4 py-2 rounded-xl';
    return (
        <>
            {budget.bon_commande.get() === null && (
                <Button
                    color="blue"
                    pb={2}
                    tabIndex={-1}
                    pl={2}
                    pr={2}
                    pt={2}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                        <path
                            fillRule="evenodd"
                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm0 8.625a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM15.375 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zM7.5 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                            clipRule="evenodd"
                        />
                    </svg>
                </Button>
            )}
            {budget.option.get() === true && (
                <Button
                    onClick={() => {
                        budget.option.set(false);
                        budget.addEdit({
                            option: false,
                        });
                        AppManager.makeUpdate();
                    }}
                    color="amber"
                    pb={2}
                    tabIndex={-1}
                    pl={2}
                    pr={2}
                    pt={2}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                        <path d="M6 3a3 3 0 00-3 3v2.25a3 3 0 003 3h2.25a3 3 0 003-3V6a3 3 0 00-3-3H6zM15.75 3a3 3 0 00-3 3v2.25a3 3 0 003 3H18a3 3 0 003-3V6a3 3 0 00-3-3h-2.25zM6 12.75a3 3 0 00-3 3V18a3 3 0 003 3h2.25a3 3 0 003-3v-2.25a3 3 0 00-3-3H6zM17.625 13.5a.75.75 0 00-1.5 0v2.625H13.5a.75.75 0 000 1.5h2.625v2.625a.75.75 0 001.5 0v-2.625h2.625a.75.75 0 000-1.5h-2.625V13.5z" />
                    </svg>
                </Button>
            )}
            {budget.free.get() === true && (
                <Button
                    onClick={() => {
                        budget.free.set(false);
                        budget.addEdit({
                            free: false,
                        });
                        AppManager.makeUpdate();
                    }}
                    color="green"
                    pb={2}
                    tabIndex={-1}
                    pl={2}
                    pr={2}
                    pt={2}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
                        <path
                            fillRule="evenodd"
                            d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z"
                            clipRule="evenodd"
                        />
                    </svg>
                </Button>
            )}
            <MpModal
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                title="Action sur la ligne"
                done="Fermer"
                menu={true}
                onSubmit={() => {
                    setOpen(false);
                }}
            >
                <div className={'min-w-[16rem] flex flex-col items-center justify-center'}>
                    {
                        <p
                            tabIndex={0}
                            onClick={() => {
                                setOpenDelete(true);
                            }}
                            className={classNameMenu}
                        >
                            Supprimer la ligne
                        </p>
                    }
                    {
                        <p
                            tabIndex={0}
                            onClick={() => {
                                setOpenAlias(true);
                            }}
                            className={classNameMenu}
                        >
                            Changer l'alias
                        </p>
                    }
                    {budget.option.get() !== true && (
                        <p
                            tabIndex={0}
                            onClick={() => {
                                budget.option.set(true);
                                AppManager.makeUpdate();
                                budget.addEdit({
                                    option: true,
                                });
                                setOpen(false);
                            }}
                            className={classNameMenu}
                        >
                            Mettre en option
                        </p>
                    )}
                    {budget.option.get() === true && (
                        <p
                            tabIndex={0}
                            onClick={() => {
                                budget.option.set(false);
                                AppManager.makeUpdate();
                                budget.addEdit({
                                    option: false,
                                });
                                setOpen(false);
                            }}
                            className={classNameMenu}
                        >
                            Mettre en obligatoire
                        </p>
                    )}
                    {budget.free.get() !== true && (
                        <p
                            tabIndex={0}
                            onClick={() => {
                                budget.free.set(true);
                                AppManager.makeUpdate();
                                budget.addEdit({
                                    free: true,
                                });
                                setOpen(false);
                            }}
                            className={classNameMenu}
                        >
                            Rendre gratuite
                        </p>
                    )}
                    {budget.free.get() === true && (
                        <p
                            tabIndex={0}
                            onClick={() => {
                                budget.free.set(false);
                                AppManager.makeUpdate();
                                budget.addEdit({
                                    free: false,
                                });
                                setOpen(false);
                            }}
                            className={classNameMenu}
                        >
                            Rendre payante
                        </p>
                    )}

                    {budget.prestation.get() !== null && (
                        <p
                            tabIndex={0}
                            onClick={() => {
                                setOpenPrestation(true);
                            }}
                            className={classNameMenu}
                        >
                            Renommer la prestation
                        </p>
                    )}
                    {budget.prestation.get() !== null && (
                        <p
                            tabIndex={0}
                            onClick={() => {
                                setOpenPrestation(true);
                            }}
                            className={classNameMenu}
                        >
                            Changer la catégorie
                        </p>
                    )}

                    {budget.honoraire.get() !== null && (
                        <p
                            tabIndex={0}
                            onClick={() => {
                                setOpenHonoraire(true);
                            }}
                            className={classNameMenu}
                        >
                            Renommer l'honoraire
                        </p>
                    )}
                    {budget.honoraire.get() !== null && (
                        <p
                            tabIndex={0}
                            onClick={() => {
                                setOpenHonoraire(true);
                            }}
                            className={classNameMenu}
                        >
                            Changer la catégorie
                        </p>
                    )}

                    {budget.vendor.get() !== null && (
                        <p
                            tabIndex={0}
                            onClick={() => {
                                setOpenVendor(true);
                            }}
                            className={classNameMenu}
                        >
                            Renommer le fournisseur
                        </p>
                    )}

                    {budget.price.get() != 0 &&
                        budget.quantity.get() != 0 &&
                        budget.price.get() != null &&
                        budget.quantity.get() != null && (
                            <p
                                tabIndex={0}
                                onClick={() => {
                                    setOpenPrix(true);
                                }}
                                className={classNameMenu}
                            >
                                Choisir le prix de vente
                            </p>
                        )}
                    <DelBudgetModal
                        open={openDelete}
                        handleClose={() => {
                            setOpenDelete(false);
                        }}
                        paper={budget.getPaper() ?? new Paper()}
                        budget={budget}
                    />
                    <AliasBudgetModal
                        open={openAlias}
                        onClose={() => {
                            setOpenAlias(false);
                        }}
                        budget={budget}
                    />
                    <PrestationModal
                        open={openPrestation}
                        onClose={() => {
                            setOpenPrestation(false);
                        }}
                        prestation={budget.prestation.get()!}
                    />
                    <VendorModal
                        open={openVendor}
                        onClose={() => {
                            setOpenVendor(false);
                        }}
                        vendor={budget.vendor.get()!}
                    />
                    <HonoraireModal
                        open={openHonoraire}
                        onClose={() => {
                            setOpenHonoraire(false);
                        }}
                        honoraire={budget.honoraire.get()!}
                    />
                    <PriceModal
                        open={openPrix}
                        onClose={() => {
                            setOpenPrix(false);
                        }}
                        budget={budget}
                    />
                </div>
            </MpModal>
        </>
    );
};
