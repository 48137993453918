import { AJOList, AJOObject } from 'mp-js-react-auto-json-object';
import { useEffect, useRef, useState } from 'react';
import BasePrice from 'src/object/BasePrice';
import { Honoraire } from 'src/object/Honoraire';
import AppManager from '../../commun/AppManager';
import Autocomplete from '../../commun/component/Autocomplite';
import { post } from '../../commun/HTTP';
import { PerfectAJOList } from '../../commun/object/PerfectAJOList';
import Budget from '../../object/Budget';
import Prestation from '../../object/profile/Prestation';
import HonoraireModal from '../prestation/HonoraireModal';
import PrestationModal from '../prestation/PrestationModal';
import { CustomSelect } from './CustomSelect';

export interface IHonoraireSelectProps {
    honoraireList: PerfectAJOList<Honoraire>;
    budget: Budget;
    basePrice: BasePrice;
}

export const HonoraireSelect = (props: IHonoraireSelectProps) => {
    const { honoraireList, budget, basePrice } = props;
    const ref = useRef<HTMLInputElement>();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');

    useEffect(() => {
        if (budget.getAjoIdentifier() === '-1') {
            document.getElementById('honoraire-' + budget.getAjoIdentifier())?.focus();
            document.getElementById('honoraire-' + budget.getAjoIdentifier())?.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
            });
        } else if (budget.focus) {
            document.getElementById('honoraire-' + budget.getAjoIdentifier())?.focus();
        }
    }, [budget.getAjoIdentifier()]);

    return (
        <>
            <CustomSelect
                ref={ref}
                id={'honoraire-' + budget.getAjoIdentifier()}
                disabled={budget.bon_commande.get() !== null || !basePrice.canAct()}
                list={honoraireList.getList()}
                value={budget.honoraire.get()}
                getValue={(elem: any) => elem.name.get() ?? ''}
                placeholder="Nom"
                onBlur={() => {
                    budget.leaveFocus();
                }}
                onFocus={() => {
                    setName('');
                    honoraireList?.need();
                    budget.getFocus();
                }}
                onCreate={(str: string) => {
                    setName(str);
                    setOpen(true);
                }}
                onSelect={(item: any) => {
                    if (item instanceof Honoraire) {
                        budget.honoraire.set(item);
                        budget.addEdit({
                            honoraire: item.getAjoIdentifier(),
                        });
                        AppManager.makeUpdate();
                        budget.focusVendor();
                    } else if (budget.honoraire.get() !== null) {
                        budget.honoraire.set(null);
                        budget.addEdit({
                            honoraire: -1,
                        });
                        AppManager.makeUpdate();
                    }
                }}
            />
            <HonoraireModal
                honoraire={null}
                baseName={name}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                onCreate={(honoraire: Honoraire) => {
                    budget.addEdit({
                        honoraire: {
                            id: honoraire.getAjoIdentifier(),
                        },
                    });
                    budget.honoraire.set(honoraire);
                    AppManager.makeUpdate();
                }}
            />
        </>
    );
};
