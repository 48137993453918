import React, { useEffect, useState } from 'react';
import AppManager from './commun/AppManager';
import { BrowserRouter } from 'react-router-dom';
import PageObject from './commun/PageObject';
import PageManager from './commun/PageManager';
import { AJOInstance, AJOState } from 'mp-js-react-auto-json-object';
import { get, post } from './commun/HTTP';
import { AppRoutes } from './AppRoutes';
import User from './object/User';
import Result from './commun/Result';
import Role from './object/Role';
import Permission from './object/Permission';
import ActionManager from './commun/action/ActionManager';
import Vendor from './object/profile/Vendor';
import Category from './object/tag/Category';
import Service from './object/Service';
import Contact from './object/Contact';
import Address from './object/Address';
import Label from './object/tag/Label';
import Prestation from './object/profile/Prestation';
import Client from './object/profile/Client';
import Budget from './object/Budget';
import Taxe from './object/Taxe';
import Valeur from './object/Valeur';
import Devis from './object/Devis';
import Note from './object/Note';
import Product from './object/tag/Product';
import Toast from './commun/toast/Toast';
import Template from './object/Template';
import { Group } from './object/Group';
import { Paper } from './object/Paper';
import { Action } from './object/Action';
import { Honoraire } from './object/Honoraire';
import { CategoryHonoraire } from './object/CategoryHonoraire';
import { Marge } from './object/Marge';
import CategoryEvent from './object/tag/CategoryEvent';
import { BonCommande } from './object/BonCommande';
import { Agence } from './object/Agence';
import Nego from './object/Nego';
import { Timer } from './object/Timer';
import { Section } from './object/Section';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Reduc } from './object/marge_reduc/Reduc';
import { Jour } from './object/Jour';
import { FlexBonCommande } from './object/FlexBonCommande';
import { Message } from './object/Message';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import { registerChartJs } from './utils/register-chart-js';
import { createEmotionCache } from './utils/create-emotion-cache';
import { theme } from './theme';
import MakeAction from './commun/action/Action';

AppManager.init();

ActionManager.addAction(
    new MakeAction(MakeAction.ADD_USER, (user1: User) => {
        return user1.hasPermission(Permission.ADMINISTRATOR, true);
    }),
);
ActionManager.addAction(
    new MakeAction(MakeAction.EDIT_USER, (user1: User, user2: User) => {
        let res = false;
        if (user1.root.get()) {
            res = !user2.root.get();
        } else if (user1.hasPermission(Permission.ADMINISTRATOR)) {
            res = !user2.hasPermission(Permission.ADMINISTRATOR, true);
        }
        res = res || user1.getAjoIdentifier() === user2.getAjoIdentifier();
        return res && !user2.delete.get();
    }),
);
ActionManager.addAction(
    new MakeAction(MakeAction.DELETE_USER, (user1: User, user2: User) => {
        let res = false;
        if (user1.root.get()) {
            res = !user2.root.get();
        } else if (user1.hasPermission(Permission.ADMINISTRATOR)) {
            res = !user2.hasPermission(Permission.ADMINISTRATOR, true);
        }
        return res && !user2.delete.get() && user1.getAjoIdentifier() !== user2.getAjoIdentifier();
    }),
);
ActionManager.addAction(
    new MakeAction(MakeAction.REVOK_USER, (user1: User, user2: User) => {
        let res = false;
        if (user1.root.get()) {
            res = !user2.root.get();
        } else if (user1.hasPermission(Permission.ADMINISTRATOR)) {
            res = !user2.hasPermission(Permission.ADMINISTRATOR, true);
        }
        return res && user2.delete.get();
    }),
);

registerChartJs();

const clientSideEmotionCache = createEmotionCache();

function App() {
    PageManager.reset();

    PageManager.add(new PageObject('/', ['/'], 'Accueil', 'purple-600', 'cyan-500', 'cyan-400', false, 'left'));
    PageManager.add(
        new PageObject(
            '/bdd/vendor',
            ['/bdd/vendor', '/vendor', '/prestation'],
            'Fournisseur',
            'blue-400',
            'pink-500',
            'pink-400',
            false,
            'left',
        ),
    );
    PageManager.add(
        new PageObject(
            '/bdd/client',
            ['/bdd/client', '/devis', '/client'],
            'Client',
            'lime-400',
            'teal-600',
            'blue-400',
            false,
            'left',
        ),
    );
    PageManager.add(
        new PageObject(
            '/bdd/command',
            ['/bdd/command'],
            'Bon de commande',
            'red-400',
            'rose-600',
            'amber-400',
            false,
            'left',
        ),
    );
    PageManager.add(
        new PageObject(
            '/user',
            ['/user', '/user_list'],
            'Utilisateur',
            'cyan-400',
            'blue-600',
            'teal-400',
            false,
            'left',
        ),
    );
    PageManager.add(new PageObject('/help', ['/help'], 'Aide', 'purple-600', 'blue-500', 'purple-400', false, 'left'));

    const [user, updateUser] = useState(new AJOState<User>(User.build()));
    user.setUpdate(updateUser);
    AppManager.setUserState(user);

    const [authenticated, updateAuthenticated] = useState(AppManager.isAuthenticated());

    useEffect(() => {
        post('/is_authenticated').then((res: any) => {
            if (Result.isSuccess(res)) {
                const result = Result.getResult(res);

                AppManager.setAuthenticated(result['authenticated']);
                AppManager.nowLoad();

                if (result['authenticated']) {
                    AppManager.setUser(User.build());
                    AppManager.getUser()?.applyData(result['user']);
                }

                AppManager.makeUpdate();
            }
        });
        AppManager.setup(document);
        AppManager.setUpdateAuthenticated(updateAuthenticated);
        AJOInstance.setIdentifierField('_id');
        AJOInstance.setTypeField('_type');
        AJOInstance.setDeleteField('_id_del');
        AJOInstance.setDeepEqual(false);

        AJOInstance.add(Role.build());
        AJOInstance.add(User.build());
        AJOInstance.add(Vendor.build());
        AJOInstance.add(Permission.build());
        AJOInstance.add(Category.build());
        AJOInstance.add(Service.build());
        AJOInstance.add(Contact.build());
        AJOInstance.add(Address.build());
        AJOInstance.add(Label.build());
        AJOInstance.add(Prestation.build());
        AJOInstance.add(Client.build());
        AJOInstance.add(Budget.build());
        AJOInstance.add(Taxe.build());
        AJOInstance.add(Valeur.build());
        AJOInstance.add(Note.build());
        AJOInstance.add(Devis.build());
        AJOInstance.add(Product.build());
        AJOInstance.add(Template.build());
        AJOInstance.add(Group.build());
        AJOInstance.add(Paper.build());
        AJOInstance.add(Honoraire.build());
        AJOInstance.add(Marge.build());
        AJOInstance.add(Reduc.build());
        AJOInstance.add(CategoryHonoraire.build());
        AJOInstance.add(CategoryEvent.build());
        AJOInstance.add(BonCommande.build());
        AJOInstance.add(Agence.build());
        AJOInstance.add(Nego.build());
        AJOInstance.add(Timer.build());
        AJOInstance.add(Section.build());
        AJOInstance.add(Jour.build());
        AJOInstance.add(FlexBonCommande.build());
        AJOInstance.add(Message.build());
        AJOInstance.add(Action.build());
    }, []);
    const [time, setTime] = useState(Date.now());

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000 * 60 * 15);
        get('/timer/notif', {}).then((res: any) => {
            (AppManager.getUser() as User).last_message.set(res['last_message']);
        });

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <CacheProvider value={clientSideEmotionCache}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />

                    <BrowserRouter>
                        <AppRoutes />
                    </BrowserRouter>
                </ThemeProvider>
            </LocalizationProvider>
        </CacheProvider>
    );
}

export default App;
