import { Card } from '@mui/material';
import Prestation from '../../object/profile/Prestation';

export interface ISimplePrestationListProps {
    list: Prestation[];
}

export const SimplePrestationList = (props: ISimplePrestationListProps) => {
    return (
        <div className="flex gap-2 flex-wrap">
            {props.list.map((prestation: Prestation, index: number) => {
                return (
                    <div key={prestation.getAjoIdentifier()} className="border rounded-lg px-2 py-1">
                        <p className="text-sm">{prestation.name.get()}</p>
                    </div>
                );
            })}
        </div>
    );
};
