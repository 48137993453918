import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import { AJOList, AJOState } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import { PerfectTable } from 'src/commun/table/PerfectTable';
import Utils from 'src/commun/Utils';
import { BasePage } from 'src/component/BasePage';
import { DashboardNavBar } from 'src/component/nav/DashboardNavBar';
import { TimerView } from 'src/component/TimerView';
import { SearchIcon } from 'src/icons/SearchIcon';
import User from 'src/object/User';
import { WeekWork } from './BoardPage';

export const BoardListPage = () => {
    const [search, setSearch] = useState('');
    const [loading, updateLoading] = useState(true);
    const [stateList, updateStateList] = useState(new AJOState<AJOList<User>>(new AJOList<User>(User)));
    stateList.setUpdate(updateStateList);

    useEffect(() => {
        get('/user/get', {
            search: '',
        }).then((res: any) => {
            if (Result.isSuccess(res)) {
                updateLoading(false);

                stateList.get()!.reset();

                Result.applyResult(res, stateList, true);
            }
        });
    }, []);

    const list: User[] = [];
    const cleanSearchList: string[] = Utils.clean(search).split(' ');

    for (let i = 0; i < stateList.get()!.size(); i++) {
        const elem = stateList.get()!.get(i);

        let good = cleanSearchList.length == 1 && cleanSearchList[0] == '';
        let y = 0;

        while (!good && y < cleanSearchList.length) {
            const cleanSearch = cleanSearchList[y];

            if (
                cleanSearch.length != 0 &&
                (Utils.clean(elem.firstname.get()).includes(cleanSearch) ||
                    Utils.clean(elem.lastname.get()).includes(cleanSearch))
            ) {
                good = true;
            }
            y++;
        }

        if (good) {
            list.push(elem);
        }
    }
    return (
        <BasePage className="flex-row w-full justify-center gap-4">
            <DashboardNavBar />

            <Container>
                <Box sx={{ mb: 3, mt: 3 }}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            m: -1,
                        }}
                    >
                        <Typography sx={{ m: 1 }} variant="h4">
                            Récap horaire
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Card>
                            <CardContent className="">
                                <Box className="flex  items-center flex-row">
                                    <Box sx={{ maxWidth: 700 }}>
                                        <TextField
                                            fullWidth
                                            value={search}
                                            onChange={(e) => {
                                                setSearch((e.target as any).value);
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SvgIcon color="action" fontSize="small">
                                                            <SearchIcon />
                                                        </SvgIcon>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            placeholder="Rechercher un utilisateur"
                                            variant="outlined"
                                        />
                                    </Box>
                                    <Box className="flex-1"></Box>
                                    <Box></Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
                <Card
                    sx={{
                        mb: 3,
                    }}
                >
                    <PerfectTable
                        columns={[
                            {
                                name: 'Nom',
                                sort: 1,
                                canSort: true,
                                funSort: (a: User, b: User) => {
                                    return (a.lastname.get() ?? '').localeCompare(b.lastname.get() ?? '');
                                },
                                hasFilter: false,
                                funFilter: (a: User) => {
                                    return (
                                        Utils.isSearch(search, a.lastname.get()) ||
                                        Utils.isSearch(search, a.firstname.get()) ||
                                        Utils.isSearch(search, a.email.get())
                                    );
                                },
                                render: (user: User) => {
                                    return (
                                        <Box>
                                            <Typography variant="h6">
                                                {user.firstname.get()} {user.lastname.get()}
                                            </Typography>
                                            <Typography>{user.email.get()}</Typography>
                                        </Box>
                                    );
                                },
                            },
                            {
                                name: 'Numéro de téléphone',
                                render: (user: User) => {
                                    return (
                                        <Box>
                                            <Typography>{user.phone.get()}</Typography>
                                        </Box>
                                    );
                                },
                            },
                            {
                                name: 'Rôle',
                                render: (user: User) => {
                                    let content: any;
                                    if (user.delete.get() === true) {
                                        content = (
                                            <Typography className="!font-semibold text-red-700">
                                                Utilisateur supprimé
                                            </Typography>
                                        );
                                    } else if (user.root.get() === true) {
                                        content = (
                                            <Typography className="!font-semibold text-green-700">
                                                Utilisateur racine
                                            </Typography>
                                        );
                                    } else {
                                        content = (
                                            <Typography className="!font-semibold text-blue-700">
                                                Chef de projet
                                            </Typography>
                                        );
                                    }
                                    return <Box>{content}</Box>;
                                },
                            },
                            {
                                name: 'Action',
                                render: (user: User) => {
                                    return (
                                        <Box className="flex flex-row gap-3">
                                            <Link to={'/board/' + user.getAjoIdentifier()}>
                                                <Button
                                                    className="flex items-center flex-row !pl-3 gap-2"
                                                    variant="contained"
                                                    tabIndex={-1}
                                                    color="primary"
                                                    onClick={() => {
                                                        // setRevokUser(user);
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                    Récapitulatif
                                                </Button>
                                            </Link>
                                        </Box>
                                    );
                                },
                            },
                        ]}
                        loading={loading}
                        list={list}
                        nothing={
                            <Box className="flex flex-col items-center p-12">
                                <Typography variant="h6">Aucun utilisateur</Typography>
                            </Box>
                        }
                    />
                </Card>
            </Container>
        </BasePage>
    );
};
