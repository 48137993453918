import { Button } from '../commun/component/Button';
import { JSXInternal } from 'preact/src/jsx';

export interface ILittleAddButtonProps {
    onAdd: () => void;
    text: string;
    size?: 'sm' | 'md' | 'lg';
}

export const LittleAddButton: React.FC<ILittleAddButtonProps> = (props) => {
    const { onAdd, text, size = 'sm' } = props;
    let basePadding = 1;
    if (size === 'md') {
        basePadding = 1;
    }

    let height = 6;
    if (size === 'md') {
        height = 8;
    }
    return (
        <Button
            color="blue"
            pb={1}
            pt={1}
            pl={basePadding}
            pr={text == '' ? basePadding : 3}
            gap={0}
            onClick={onAdd}
            className={'text-' + size}
        >
            <svg
                className={'h-' + height + ' w-' + height}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clipRule="evenodd"
                ></path>
            </svg>
            {text}
        </Button>
    );
};
