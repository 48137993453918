import { useEffect, useState } from 'react';

export let addView = (id: number) => {};
export let removeView = (id: number, actionNew: '1' | '2') => {};

export const ActionManagerView = () => {
    const [actionList, setActionList] = useState<
        {
            id: number;
            action: '0' | '1' | '2';
            time: number;
        }[]
    >([]);

    addView = (id: number) => {
        setActionList([
            ...actionList.filter((action) => {
                if (action.action === '0') {
                    return true;
                } else if (action.action === '1' || action.action === '2') {
                    if (Date.now() - action.time > 3000) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            }),
            {
                id: id,
                action: '0',
                time: Date.now(),
            },
        ]);
    };

    removeView = (id: number, actionNew: '1' | '2') => {
        let action = actionList.find((action) => action.id === id);
        if (action) {
            action.action = actionNew;
            action.time = Date.now();
            setActionList([
                ...actionList.filter((action) => {
                    if (action.action === '0') {
                        return true;
                    } else if (action.action === '1' || action.action === '2') {
                        if (Date.now() - action.time > 3000) {
                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }),
            ]);
        }
    };

    return (
        <div className={'fixed right-8 gap-3 top-20 flex flex-row'}>
            {actionList.map((value, index) => {
                if (value.action === '0') {
                    return (
                        <div className={' rounded-lg z-[5555] bg-amber-100'}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={'h-8 w-8 m-2 animate-pulse text-amber-700'}
                                viewBox="0 0 24 24"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                    );
                } else if (value.action === '1') {
                    return (
                        <div className={' z-[5555] rounded-lg bg-green-100'}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={'h-8 w-8 m-2 text-green-700'}
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                    );
                } else {
                    return (
                        <div className={' z-[5555] rounded-lg bg-red-100'}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={'h-8 w-8 m-2 text-red-700'}
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </div>
                    );
                }
            })}
        </div>
    );
};
