import { TargetedEvent, useState } from 'react';
import { Toggle } from './Toggle';

export interface IDivProps {
    title?: string;
    description?: string;
    type: number;
    checked?: boolean;
    onChange: (checked: boolean) => void;
}

export const PermLine: React.FunctionComponent<IDivProps> = (props) => {
    const { title = '', type, checked = false, description = '', ...rest } = props;
    return (
        <label className="flex w-full items-center flex-row" htmlFor={'perm-' + type}>
            <div className="flex flex-1 flex-col">
                <h4 className="font-semibold text-lg">{title}</h4>
                <p className="text-sm">{description}</p>
            </div>
            <Toggle
                text=""
                onChange={(event: TargetedEvent) => {
                    //props.onChange(event.target.);
                }}
                checked={checked}
                name={'perm-' + type}
                id={'perm-' + type}
            ></Toggle>
        </label>
    );
};
