import { Menu } from '@headlessui/react';
import { AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import AppManager from '../../commun/AppManager';
import { ContextMenu } from '../../commun/component/ContextMenu';
import { post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import {
    errorManager,
    paperDeleteSuccess,
    paperDuplicateSuccess,
    paperIncluded,
} from '../../modal/vendor/CategoryErrorManager';
import { EditVendorModal } from '../../modal/vendor/VendorEditModal';
import { Paper } from '../../object/Paper';

export interface IRightClickPaperProps {
    paper: Paper;
    full: boolean;
    children: any;
}
export const RightClickPaper: React.FC<IRightClickPaperProps> = (props) => {
    const { paper, full, children } = props;

    const [show, setShow] = useState(false);
    const [points, setPoints] = useState({ x: 0, y: 0 });
    const [showModalEdit, setShowModalEdit] = useState(false);

    useEffect(() => {
        const handleClick = () => setShow(false);

        window.addEventListener('click', handleClick);
        window.addEventListener('contextmenu', handleClick);
        window.addEventListener('scroll', handleClick, true);

        return () => {
            window.removeEventListener('contextmenu', handleClick);
            window.removeEventListener('click', handleClick);
            window.removeEventListener('scroll', handleClick, true);
        };
    }, []);

    const editPaper = () => {
        setShowModalEdit(true);
    };

    const deletePaper = () => {
        post('/paper/delete', {
            id: paper.getAjoIdentifier(),
        }).then((res: any) => {
            if (Result.isSuccess(res)) {
                Result.applyResult(res, AppManager.updater(), true);

                AppManager.addToast(new Toast(errorManager.get(paperDeleteSuccess), 'success'));
            } else {
                AppManager.addToast(new Toast(errorManager.get(Result.getError(res)), 'error'));
            }
        });
    };

    const duplicatePaper = () => {
        post('/paper/duplicate', {
            id: paper.getAjoIdentifier(),
        }).then((res: any) => {
            if (Result.isSuccess(res)) {
                Result.applyResult(res, AppManager.updater(), true);

                AppManager.addToast(new Toast(errorManager.get(paperDuplicateSuccess), 'success'));
            } else {
                AppManager.addToast(new Toast(errorManager.get(Result.getError(res)), 'error'));
            }
        });
    };

    return (
        <div>
            <div
                onContextMenu={(e) => {
                    if (paper.canAct()) {
                        e.preventDefault();
                        setTimeout(() => {
                            setShow(true);
                        });
                        setPoints({ x: e.pageX, y: e.pageY });
                    }
                }}
                className={full ? 'w-full' : ''}
            >
                {children}
            </div>
            {show && (
                <div className="static">
                    <ContextMenu className="z-[50]" top={points.y} left={points.x}>
                        <ul className="p-1 rounded-md border border-slate-500 flex gap-1 flex-col shadow-2xl bg-white dark:bg-gray-900">
                            <li
                                onClick={() => {
                                    editPaper();
                                }}
                                className="text-sm rounded-md py-1 px-1.5 hover:bg-blue-700 hover:text-white"
                            >
                                Modifier
                            </li>

                            {paper.canAct() && paper.root.get() !== true && paper.default.get() !== true && (
                                <li
                                    onClick={() => {
                                        deletePaper();
                                    }}
                                    className="text-sm rounded-md py-1 px-1.5 hover:bg-blue-700 hover:text-white"
                                >
                                    Supprimer
                                </li>
                            )}

                            {paper.canAct() && (
                                <li
                                    className="text-sm rounded-md py-1 px-1.5 hover:bg-blue-700 hover:text-white"
                                    onClick={duplicatePaper}
                                >
                                    Dupliquer
                                </li>
                            )}
                        </ul>
                    </ContextMenu>
                </div>
            )}

            <EditVendorModal
                profile={paper}
                handleClose={() => {
                    setShowModalEdit(false);
                    paper.reInit();
                    AppManager.updater().makeUpdate();
                }}
                show={showModalEdit}
            />
        </div>
    );
};
