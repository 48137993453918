import { AJOObject } from 'mp-js-react-auto-json-object';
import { CaretDownFill, CaretRightFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { AccordeonTable } from '../../component/AccordeonTable';
import { DoubleDateModal } from '../../modal/vendor/DoubleDateModal';
import { Col } from '../table/Col';
import Table from '../table/Table';

export interface NoteAverageColInterface {
    NoteAverageColInterface: boolean;
    getAverageNote(): number;
    isAverageNote(): boolean;
}

export class NoteAverageCol<Type extends AJOObject> extends Col<Type> {
    constructor(table: Table<Type>, get: (elem: Type) => AJOObject = (elem) => elem, name: string = 'Note') {
        super(name);
        this.table = table;
        this.render = (elem: Type) => {
            if ('NoteAverageColInterface' in get(elem)) {
                let inter = get(elem) as unknown as NoteAverageColInterface;
                let res;
                if (!inter.isAverageNote()) {
                    res = (
                        <div>
                            <p>{'Aucune note'}</p>
                        </div>
                    );
                } else {
                    res = (
                        <div>
                            <p>{inter.getAverageNote().toFixed(1) + '/5.0'}</p>
                        </div>
                    );
                }
                return res;
            } else {
                throw new Error('NoteAverageColInterface not found in elem');
            }
        };
        this.sort = (a: Type, b: Type) => {
            if ('NoteAverageColInterface' in get(a) && 'NoteAverageColInterface' in get(b)) {
                let aInter = get(a) as unknown as NoteAverageColInterface;
                let bInter = get(b) as unknown as NoteAverageColInterface;
                if (aInter.isAverageNote() && bInter.isAverageNote()) {
                    return -aInter.getAverageNote() + bInter.getAverageNote();
                } else {
                    if (aInter.isAverageNote()) {
                        return -1;
                    } else if (bInter.isAverageNote()) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            } else {
                throw new Error('NoteAverageColInterface not found in a or b');
            }
        };
        this.type = 'sort';
        this.senseSort = 0;
    }
}
