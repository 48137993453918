import { Box, TextField, Typography } from '@mui/material';
import { JSXInternal } from 'preact/src/jsx';
import { Component, createRef } from 'react';
import AppManager from '../../commun/AppManager';
import { Spinner } from '../../commun/component/Spinner';
import { post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import BasePrice from '../../object/BasePrice';
import { Paper } from '../../object/Paper';
import Template from '../../object/Template';
import { getErrorMessage } from '../../utils/Error';
import MpModal, { IBaseModalProps } from '../extends/MpModal';

export interface IPaperModalProps extends IBaseModalProps {
    paper: Paper | null;
    basePrice: BasePrice;
    func: number;
}

export interface IPaperModalState {
    name: string;
    loading: boolean;
    error: string;
}

export default class PaperModal extends Component<IPaperModalProps, IPaperModalState> {
    inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: IPaperModalProps) {
        super(props);

        this.state = this.initState();
        this.inputRef = createRef();
    }

    initState() {
        const p: IPaperModalState = {
            name: this.props.paper?.name.get() ?? '',
            loading: false,
            error: '',
        };
        return p;
    }

    componentDidUpdate(
        prevProps: Readonly<IPaperModalProps>,
        prevState: Readonly<IPaperModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.open !== this.props.open) {
            this.setState(this.initState());
        }
        if (prevProps.open !== this.props.open && this.props.open) {
            setTimeout(() => {
                this.inputRef.current?.focus();
            });
        }
    }

    addVendor() {
        const { loading, name } = this.state;
        const { paper, func, basePrice } = this.props;
        this.setState({
            loading: true,
            error: '',
        });
        let params: any;
        let url: string;
        if (paper === null) {
            url = '/' + basePrice.getAjoType().toLowerCase() + '/paper/create';
            params = {
                id_template: basePrice.getAjoIdentifier(),
                id_devis: basePrice.getAjoIdentifier(),
                name: name,
                func: func,
            };
        } else {
            url = '/paper/update';
            params = {
                id: basePrice.getAjoIdentifier(),
                name: name,
            };
        }
        if (paper !== null) {
            params['id'] = paper.getAjoIdentifier();
        }
        post(url, params).then((res: any) => {
            this.setState({
                loading: false,
            });
            if (Result.isSuccess(res)) {
                Result.applyResult(res, AppManager.updater(), true);
                this.props.onClose();
                if (paper === null) {
                    AppManager.addToast(new Toast('La feuille a été ajoutée avec succès.', 'success'));
                } else {
                    AppManager.addToast(new Toast('La feuille a été modifiée avec succès.', 'success'));
                }
            } else {
                this.setState({
                    error: getErrorMessage(Result.getErrorStr(res)),
                });
            }
        });
    }

    render(): JSXInternal.Element {
        const { open, paper, onClose } = this.props;
        const { loading, name, error } = this.state;
        return (
            <MpModal
                open={open}
                onClose={onClose}
                onSubmit={() => {
                    this.addVendor();
                }}
                title={paper === null ? 'Ajouter une feuille' : 'Modifier une feuille'}
                done={paper === null ? 'Ajouter' : 'Modifier'}
            >
                {loading ? (
                    <Box className="flex items-center justify-center py-12">
                        <Spinner color="blue" className="" />
                    </Box>
                ) : (
                    <Box className="flex flex-col !text-gray-600 gap-2">
                        <Typography className="!text-sm !font-semibold">NOM DE LA FEUILLE</Typography>
                        <TextField
                            inputProps={{
                                ref: this.inputRef,
                            }}
                            fullWidth
                            value={name}
                            onChange={(e) => {
                                this.setState({ name: (e.target as any).value });
                            }}
                            placeholder="Nom de la feuille"
                            variant="outlined"
                        />
                        {error !== '' && (
                            <Typography
                                component={'p'}
                                className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                            >
                                {error}
                            </Typography>
                        )}
                    </Box>
                )}
            </MpModal>
        );
    }
}
