import { Box, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'preact/hooks';
import { useRef } from 'react';
import AppManager from 'src/commun/AppManager';
import Toast from 'src/commun/toast/Toast';
import Budget from 'src/object/Budget';
import MpModal from '../extends/MpModal';

export interface IAliasBudgetModalProps {
    budget: Budget;
    open: boolean;
    onClose: () => void;
}

export const AliasBudgetModal = (props: IAliasBudgetModalProps) => {
    const [name, setName] = useState(props.budget.alias.get());
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (props.open) {
            inputRef.current?.focus();
        }
    }, [props.open]);
    return (
        <MpModal
            open={props.open}
            onClose={props.onClose}
            title="Changer l'alias"
            done="Changer"
            onSubmit={() => {
                props.budget.alias.set(name);
                props.budget.addEdit({
                    alias: name,
                });
                props.onClose();
                AppManager.makeUpdate();
                AppManager.addToast(new Toast("L'alias du budget a été changé avec succès", 'success'));
            }}
        >
            <Box className="flex flex-col !text-gray-600 gap-2">
                <Typography className="!text-sm !font-semibold">ALIAS DE LA PRESTATION</Typography>
                <TextField
                    inputProps={{
                        ref: inputRef,
                        onChange: (e) => {
                            setName((e.target as any)?.value);
                        },
                    }}
                    fullWidth
                    value={name}
                    placeholder="Alias de la prestation"
                    variant="outlined"
                />
            </Box>
        </MpModal>
    );
};
