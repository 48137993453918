import { Box, Typography } from '@mui/material';
import { useState } from 'preact/hooks';
import AppManager from 'src/commun/AppManager';
import { Spinner } from 'src/commun/component/Spinner';
import { post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import Toast from 'src/commun/toast/Toast';
import Budget from 'src/object/Budget';
import { Paper } from 'src/object/Paper';
import MpModal from '../extends/MpModal';

export interface IDelBudgetModalProps {
    open: boolean;
    paper: Paper;
    budget: Budget | null;
    handleClose: () => void;
}

export const DelBudgetModal = (props: IDelBudgetModalProps) => {
    const { open, handleClose, paper, budget: deleteBudget } = props;
    const [errorDelete, setErrorDelete] = useState<string>('');
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
    return (
        <>
            {
                <MpModal
                    done="Supprimer"
                    doneColor="failure"
                    open={open}
                    onClose={handleClose}
                    title="Supprimer la ligne"
                    onSubmit={() => {
                        setLoadingDelete(true);
                        setErrorDelete('');

                        post('/paper/update', {
                            id: paper.getAjoIdentifier(),
                            budget: [
                                {
                                    id_del: deleteBudget!.getAjoIdentifier(),
                                    full_delete: true,
                                },
                            ],
                        }).then((res: any) => {
                            setLoadingDelete(false);

                            if (Result.isSuccess(res)) {
                                handleClose();
                                AppManager.addToast(new Toast('La ligne a été supprimée avec succès.', 'success'));
                                Result.applyResult(res, AppManager.updater(), true);
                            } else {
                                setErrorDelete(
                                    'Une erreur est survenue lors de la suppression de la ligne. Veuillez réessayer ultérieurement.',
                                );
                            }
                        });
                    }}
                >
                    {loadingDelete ? (
                        <Box className="flex items-center justify-center py-12">
                            <Spinner color="red" className="" />
                        </Box>
                    ) : (
                        <Box className="flex flex-col !text-gray-600 gap-2">
                            <Typography className="!text-sm !font-semibold">
                                Êtes-vous sûr de vouloir supprimer cette ligne, cette action est irréversible.
                            </Typography>
                            {errorDelete !== '' && (
                                <Typography
                                    component={'p'}
                                    className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                                >
                                    {errorDelete}
                                </Typography>
                            )}
                        </Box>
                    )}
                </MpModal>
            }
        </>
    );
};
