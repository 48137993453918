import { Box, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Component } from 'react';
import AppManager from '../../commun/AppManager';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { get, post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import { JSXInternal } from 'preact/src/jsx';
import { errorManager } from '../vendor/CategoryErrorManager';
import MpModal from 'src/component/extends/MpModal';
import { Spinner } from 'src/commun/component/Spinner';
import { AJOList } from 'mp-js-react-auto-json-object';
import User from 'src/object/User';

export interface IAddCongeProps {
    show: boolean;
    reset: () => void;
    onClose: () => void;
}
export interface IAddCongeState {
    error: ErrorHTTP;
    loading: boolean;
    date: Date;
    dateEnd: Date;
    userSelect: number;
    userList: AJOList<User>;
}

export class AddCongeModal extends Component<IAddCongeProps, IAddCongeState> {
    constructor(props: IAddCongeProps) {
        super(props);

        this.state = this.initState();
    }

    initState(): IAddCongeState {
        return {
            error: ErrorHTTP.NO_ERROR(),
            loading: true,
            date: new Date(),
            dateEnd: new Date(),
            userSelect: -1,
            userList: new AJOList<User>(User),
        };
    }

    componentDidUpdate(prevProps: Readonly<IAddCongeProps>, prevState: Readonly<IAddCongeState>, snapshot?: any): void {
        if (this.props.show !== prevProps.show) {
            this.setState(this.initState());
            get('/user/list', {})
                .then((result) => {
                    this.setState({
                        loading: false,
                    });
                    if (Result.isSuccess(result)) {
                        Result.applyResult(result, this.state.userList);
                    }
                })
                .catch((e) => {
                    this.setState({
                        loading: false,
                    });
                });
        }
    }

    AddConge() {
        this.setState({
            loading: true,
            error: ErrorHTTP.NO_ERROR(),
        });
        const { date, dateEnd, userSelect } = this.state;
        post('/timer/add_conge', {
            date: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
            date_end: dateEnd.getFullYear() + '-' + (dateEnd.getMonth() + 1) + '-' + dateEnd.getDate(),
            user: userSelect,
        }).then((res: any) => {
            this.setState({
                loading: false,
            });
            if (Result.isSuccess(res)) {
                this.props.onClose();
                Result.applyResult(res, AppManager.updater(), true);
                // Make toast
                AppManager.addToast(new Toast('Congé ajouté', 'success'));
                window.location.reload();
            } else {
                this.setState({
                    error: Result.getError(res),
                });
            }
        });
    }

    render(): JSXInternal.Element {
        const { show, onClose } = this.props;
        const { loading, error } = this.state;
        return (
            <MpModal
                open={show}
                onSubmit={() => {
                    this.AddConge();
                }}
                done="Ajouter"
                title="Ajouter une période de congé"
                onClose={onClose}
            >
                {loading ? (
                    <Box className="p-10 flex items-center justify-center">
                        <Spinner color="blue" />
                    </Box>
                ) : (
                    <Box className="flex flex-col gap-3">
                        <select
                            name="user_list"
                            id="user-select"
                            class={'w-full'}
                            value={this.state.userSelect}
                            onChange={(e) => {
                                this.setState({
                                    userSelect: (e?.target as any)?.value ?? '',
                                });
                            }}
                        >
                            {this.state.userList.map((user) => {
                                return (
                                    <option value={user.getAjoIdentifier()} key={user.getAjoIdentifier()}>
                                        {user.firstname.get()} {user.lastname.get()}
                                    </option>
                                );
                            })}
                        </select>

                        <DatePicker
                            className="flex-1"
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params: any) => {
                                return <TextField {...params} />;
                            }}
                            onChange={(e: any) => {
                                if (e !== null) {
                                    this.setState({
                                        date: (e as any).toDate(),
                                    });
                                } else {
                                    this.setState({
                                        date: new Date(),
                                    });
                                }
                            }}
                            value={this.state.date}
                        />
                        <DatePicker
                            className="flex-1"
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params: any) => {
                                return <TextField {...params} />;
                            }}
                            onChange={(e: any) => {
                                if (e !== null) {
                                    this.setState({
                                        dateEnd: (e as any).toDate(),
                                    });
                                } else {
                                    this.setState({
                                        dateEnd: new Date(),
                                    });
                                }
                            }}
                            value={this.state.dateEnd}
                        />
                        {!error.equals(ErrorHTTP.NO_ERROR()) && (
                            <Typography
                                component={'p'}
                                className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                            >
                                {errorManager.get(error)}
                            </Typography>
                        )}
                    </Box>
                )}
            </MpModal>
        );
    }
}
