import { AJOList, AJOObject } from 'mp-js-react-auto-json-object';
import { useEffect, useRef, useState } from 'react';
import Address from 'src/object/Address';
import BasePrice from 'src/object/BasePrice';
import Taxe from 'src/object/Taxe';
import AppManager from '../../commun/AppManager';
import Autocomplete from '../../commun/component/Autocomplite';
import { post } from '../../commun/HTTP';
import { PerfectAJOList } from '../../commun/object/PerfectAJOList';
import Budget from '../../object/Budget';
import AddressModal from '../address/AddressModal';
import { CustomSelect } from './CustomSelect';

export interface ITvaVendorSelectProps {
    taxeList: PerfectAJOList<Taxe>;
    budget: Budget;
    basePrice: BasePrice;
}

export const TvaVendorSelect = (props: ITvaVendorSelectProps) => {
    const { taxeList, budget, basePrice } = props;
    const ref = useRef<HTMLInputElement>();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');

    return (
        <>
            <CustomSelect
                ref={ref}
                className={'w-20'}
                id={'taxe-vendor-' + budget.getAjoIdentifier()}
                disabled={budget.bon_commande.get() !== null || !basePrice.canAct()}
                list={taxeList.getList()}
                value={budget.taxe_vendor.get()}
                getValue={(elem: Taxe) => elem.pourcent.get() + ' %' ?? ''}
                placeholder={'TVA du fournisseur'}
                onBlur={() => {
                    budget.leaveFocus();
                }}
                onFocus={() => {
                    setName('');
                    taxeList?.need();
                    budget.getFocus();
                }}
                onCreate={(str: string) => {
                    setName(str);
                    setOpen(true);
                }}
                onSelect={(item: any) => {
                    if (item instanceof Taxe) {
                        budget.taxe_vendor.set(item);
                        budget.addEdit({
                            taxe_vendor: item.getAjoIdentifier(),
                        });
                        AppManager.makeUpdate();
                        budget.focusVendor();
                    } else if (budget.taxe_vendor.get() !== null) {
                        budget.taxe_vendor.set(null);
                        budget.addEdit({
                            taxe_vendor: -1,
                        });
                        AppManager.makeUpdate();
                    }
                }}
            />
        </>
    );
};
