import { Box, Typography } from '@mui/material';
import { AJOElement, AJOList, AJOState } from 'mp-js-react-auto-json-object';
import { useEffect, useRef, useState } from 'react';
import MpModal from 'src/component/extends/MpModal';
import AppManager from '../commun/AppManager';
import { CheckBox } from '../commun/component/CheckBox';
import { ComplexInput } from '../commun/component/ComplexInput';
import { ComplexSelect } from '../commun/component/ComplexSelect';
import { ErrorText } from '../commun/component/ErrorText';
import { Spinner } from '../commun/component/Spinner';
import ErrorHTTP from '../commun/ErrorHTTP';
import ErrorManager from '../commun/ErrorManager';
import ErrorMessage from '../commun/ErrorMessage';
import { get, post } from '../commun/HTTP';
import { IModalProps, Modal } from '../commun/modal/Modal';
import Result from '../commun/Result';
import Toast from '../commun/toast/Toast';
import Utils from '../commun/Utils';
import { RoleSelect } from '../component/RoleSelect';
import Role from '../object/Role';
import User from '../object/User';

export interface IAddUserModalProps extends IModalProps {
    stateList: AJOState<AJOElement>;
}

const errorManager = new ErrorManager('/user/add');
/**
 * EMAIL
 */
errorManager.addMessage(ErrorMessage.BAD_EMAIL_ENTER());
errorManager.addMessage(ErrorMessage.BAD_EMAIL_MIN());
errorManager.addMessage(ErrorMessage.BAD_EMAIL_MAX());
errorManager.addMessage(ErrorMessage.BAD_EMAIL_TYPE());
/**
 * FIRSTNAME
 */
errorManager.addMessage(ErrorMessage.BAD_FIRSTNAME_ENTER());
errorManager.addMessage(ErrorMessage.BAD_FIRSTNAME_MIN());
errorManager.addMessage(ErrorMessage.BAD_FIRSTNAME_MAX());
errorManager.addMessage(ErrorMessage.BAD_FIRSTNAME_TYPE());
/**
 * PASSWORD
 */
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_ENTER());
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_MIN());
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_MAX());
errorManager.addMessage(ErrorMessage.BAD_PASSWORD_TYPE());
/**
 * LASTNAME
 */
errorManager.addMessage(ErrorMessage.BAD_LASTNAME_ENTER());
errorManager.addMessage(ErrorMessage.BAD_LASTNAME_MIN());
errorManager.addMessage(ErrorMessage.BAD_LASTNAME_MAX());
errorManager.addMessage(ErrorMessage.BAD_LASTNAME_TYPE());
/**
 * PHONE
 */
errorManager.addMessage(ErrorMessage.BAD_PHONE_ENTER());
errorManager.addMessage(ErrorMessage.BAD_PHONE_MIN());
errorManager.addMessage(ErrorMessage.BAD_PHONE_MAX());
errorManager.addMessage(ErrorMessage.BAD_PHONE_TYPE());
/**
 * REST
 */
errorManager.addMessage(ErrorMessage.NO_PERMISSION());
errorManager.addMessage(ErrorMessage.NO_CHANGE());
errorManager.addMessage(ErrorMessage.BAD_VALUE());
errorManager.addMessage(ErrorMessage.NO_PERMISSION_ROOT());

errorManager.addMessage(new ErrorMessage(new ErrorHTTP('role_not_selected'), 'Veuillez sélectionner un rôle.'));
errorManager.addMessage(new ErrorMessage(ErrorHTTP.EXISTS(), 'Un utilisateur existe déjà avec cette adresse email.'));
errorManager.add(
    ErrorHTTP.SUCCESS().setColor('green-600').setColorDark('green-300'),
    "L'utilisateur a été ajouté avec succès.",
);

export const AddUserModal: React.FunctionComponent<IAddUserModalProps> = (props) => {
    const { stateList, ...rest } = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(ErrorHTTP.NO_ERROR());
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [heure, setHeure] = useState('35');
    const [password, setPassword] = useState(Utils.generatePassword());
    const [roleId, updateRoleId] = useState('104');

    const [emailSend, setEmailSend] = useState('');
    const [passwordSend, setPasswordSend] = useState('');

    const onDone = () => {
        setError(ErrorHTTP.NO_ERROR());
        setLoading(true);

        const param: any = {
            firstname: firstname,
            lastname: lastname,
            email: email,
            email_send: emailSend,
            password: password,
            password_send: passwordSend,
            phone: phone,
            nb_heure: parseInt(heure),
            role_id: roleId,
        };

        post('/user/add', param).then((res: any) => {
            setLoading(false);
            if (Result.isSuccess(res)) {
                setError(ErrorHTTP.SUCCESS());
                AppManager.addToast(new Toast("L'utilisateur a été ajouté avec succès.", 'success'));
                props.handleClose();
                Result.applyResult(res, stateList, true);
            } else {
                setError(Result.getError(res));
            }
        });
    };

    return (
        <MpModal
            done={'Ajouter'}
            onClose={() => {
                props.handleClose();
            }}
            open={props.show}
            title={'Ajouter un utilisateur'}
            onSubmit={onDone}
        >
            {loading ? (
                <Box className="flex items-center justify-center flex-col p-12">
                    <Spinner color="blue" />
                </Box>
            ) : (
                <Box className="flex flex-col gap-3">
                    <Box className="flex flex-col gap-1">
                        <Typography className="pl-1 text-slate-600 !text-sm !font-semibold uppercase">
                            Prénom
                        </Typography>
                        <ComplexInput
                            placeholder="Prénom"
                            name="firstname"
                            value={firstname}
                            typeValue="string"
                            type="text"
                            setValue={setFirstname}
                        ></ComplexInput>
                    </Box>

                    <Box className="flex flex-col gap-1">
                        <Typography className="pl-1 text-slate-600 !text-sm !font-semibold uppercase">Nom</Typography>
                        <ComplexInput
                            placeholder="Nom"
                            name="lastname"
                            value={lastname}
                            typeValue="string"
                            type="text"
                            setValue={setLastname}
                        ></ComplexInput>
                    </Box>

                    <Box className="flex flex-col gap-1">
                        <Typography className="pl-1 text-slate-600 !text-sm !font-semibold uppercase">
                            Adresse email
                        </Typography>
                        <ComplexInput
                            placeholder="Adresse email"
                            name="email"
                            value={email}
                            typeValue="string"
                            type="text"
                            setValue={setEmail}
                        ></ComplexInput>
                    </Box>

                    <Box className="flex flex-col gap-1">
                        <Typography className="pl-1 text-slate-600 !text-sm !font-semibold uppercase">
                            Mot de passe
                        </Typography>
                        <ComplexInput
                            placeholder="Mot de passe"
                            name="password"
                            value={password}
                            typeValue="string"
                            type="text"
                            setValue={setPassword}
                        ></ComplexInput>
                    </Box>

                    <Box className="flex flex-col gap-1">
                        <Typography className="pl-1 text-slate-600 !text-sm !font-semibold uppercase">
                            Numéro de téléphone
                        </Typography>

                        <ComplexInput
                            placeholder="Numéro de téléphone"
                            name="phone"
                            value={phone}
                            typeValue="string"
                            type="text"
                            setValue={setPhone}
                        ></ComplexInput>
                    </Box>

                    <Box className="flex flex-col gap-1">
                        <Typography className="pl-1 text-slate-600 !text-sm !font-semibold uppercase">
                            Email d'envoie
                        </Typography>
                        <ComplexInput
                            placeholder="Email d'envoie"
                            name="email_send"
                            value={emailSend}
                            typeValue="string"
                            type="text"
                            setValue={setEmailSend}
                        ></ComplexInput>
                    </Box>

                    <Box className="flex flex-col gap-1">
                        <Typography className="pl-1 text-slate-600 !text-sm !font-semibold uppercase">
                            Mot de passe email d'envoie
                        </Typography>
                        <ComplexInput
                            placeholder="Mot de passe email d'envoie"
                            name="password_send"
                            value={passwordSend}
                            typeValue="string"
                            type="text"
                            setValue={setPasswordSend}
                        ></ComplexInput>
                    </Box>

                    {!error.equals(ErrorHTTP.NO_ERROR()) && (
                        <Typography
                            component={'p'}
                            className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                        >
                            {errorManager.get(error)}
                        </Typography>
                    )}
                </Box>
            )}
        </MpModal>
    );
};

/*
        <Modal
            errorManager={errorManager}
            error={error}
            loading={loading}
            title="Ajouter un utilisateur"
            done="Ajouter"
            {...rest}
            onDone={onDone}
        >
            <ComplexInput
                placeholder="Prénom"
                name="firstname"
                value={firstname}
                typeValue="string"
                type="text"
                setValue={setFirstname}
            ></ComplexInput>

            <ComplexInput
                placeholder="Nom"
                name="lastname"
                value={lastname}
                typeValue="string"
                type="text"
                setValue={setLastname}
            ></ComplexInput>

            <ComplexInput
                placeholder="Adresse email"
                name="email"
                value={email}
                typeValue="string"
                type="text"
                setValue={setEmail}
            ></ComplexInput>

            <ComplexInput
                placeholder="Nombre d'heures"
                name="heure"
                value={heure}
                typeValue="number"
                type="text"
                setValue={setHeure}
            ></ComplexInput>

            <ComplexInput
                placeholder="Numéro de téléphone"
                name="phone"
                value={phone}
                typeValue="string"
                type="text"
                setValue={setPhone}
            ></ComplexInput>

            <ComplexInput
                placeholder="Mot de passe"
                name="password"
                value={password}
                typeValue="string"
                type="text"
                setValue={setPassword}
            ></ComplexInput>

            <ComplexInput
                placeholder="Email d'envoie"
                name="phone"
                value={emailSend}
                typeValue="string"
                type="text"
                setValue={setEmailSend}
            ></ComplexInput>

            <ComplexInput
                placeholder="Mot de passe email d'envoie"
                name="string"
                value={passwordSend}
                typeValue="string"
                type="text"
                setValue={setPasswordSend}
            ></ComplexInput>

            <RoleSelect selectId={roleId} onSelectIdChange={(value: any) => updateRoleId(value)} color={'base'} />
        </Modal>*/
