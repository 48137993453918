import { Box, Typography } from '@mui/material';
import { AJOElement, AJOList, AJOState } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import MpModal from 'src/component/extends/MpModal';
import MakeAction from '../commun/action/Action';
import ActionManager from '../commun/action/ActionManager';
import AppManager from '../commun/AppManager';
import { CheckBox } from '../commun/component/CheckBox';
import { ComplexInput } from '../commun/component/ComplexInput';
import { ErrorText } from '../commun/component/ErrorText';
import { Spinner } from '../commun/component/Spinner';
import ErrorHTTP from '../commun/ErrorHTTP';
import ErrorManager from '../commun/ErrorManager';
import ErrorMessage from '../commun/ErrorMessage';
import { post } from '../commun/HTTP';
import { IModalProps, Modal } from '../commun/modal/Modal';
import Result from '../commun/Result';
import Toast from '../commun/toast/Toast';
import { RoleSelect } from '../component/RoleSelect';
import Role from '../object/Role';
import User from '../object/User';

export interface IEditUserModalProps extends IModalProps {
    user: User;
    stateList?: AJOState<AJOList<User>>;
}

const errorManager = new ErrorManager('/user/update');
/**
 * EMAIL
 */
errorManager.addMessage(ErrorMessage.BAD_EMAIL_ENTER());
errorManager.addMessage(ErrorMessage.BAD_EMAIL_MIN());
errorManager.addMessage(ErrorMessage.BAD_EMAIL_MAX());
errorManager.addMessage(ErrorMessage.BAD_EMAIL_TYPE());
/**
 * FIRSTNAME
 */
errorManager.addMessage(ErrorMessage.BAD_FIRSTNAME_ENTER());
errorManager.addMessage(ErrorMessage.BAD_FIRSTNAME_MIN());
errorManager.addMessage(ErrorMessage.BAD_FIRSTNAME_MAX());
errorManager.addMessage(ErrorMessage.BAD_FIRSTNAME_TYPE());
/**
 * LASTNAME
 */
errorManager.addMessage(ErrorMessage.BAD_LASTNAME_ENTER());
errorManager.addMessage(ErrorMessage.BAD_LASTNAME_MIN());
errorManager.addMessage(ErrorMessage.BAD_LASTNAME_MAX());
errorManager.addMessage(ErrorMessage.BAD_LASTNAME_TYPE());
/**
 * REST
 */
errorManager.addMessage(ErrorMessage.NO_PERMISSION());
errorManager.addMessage(ErrorMessage.NO_CHANGE());
errorManager.addMessage(ErrorMessage.BAD_VALUE());
errorManager.addMessage(ErrorMessage.NO_PERMISSION_ROOT());
errorManager.add(
    ErrorHTTP.SUCCESS().setColor('green-600').setColorDark('green-300'),
    'Les données ont été modifiées avec succès.',
);

export const EditUserModal: React.FunctionComponent<IEditUserModalProps> = (props) => {
    const { user, stateList = undefined, ...rest } = props;

    const [check, updateCheck] = useState(user.first.get() || user.set.get());
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(ErrorHTTP.NO_ERROR());
    const [firstname, setFirstname] = useState(user.firstname.get());
    const [lastname, setLastname] = useState(user.lastname.get());
    const [email, setEmail] = useState(user.email.get());
    const [phone, setPhone] = useState(user.phone.get());

    const [emailSend, setEmailSend] = useState(user.email_send.get());
    const [passwordSend, setPasswordSend] = useState(user.password_send.get());

    const [roleId, updateRoleId] = useState(user.role.get()?.getAjoIdentifier() ?? '-1');

    const onDone = () => {
        if (
            check == (user.set.get() || user.first.get()) &&
            firstname == user.firstname.get() &&
            lastname == user.lastname.get() &&
            email == user.email.get() &&
            emailSend == user.email_send.get() &&
            passwordSend == user.password_send.get() &&
            phone == user.phone.get() &&
            roleId == (user.role.get()?.getAjoIdentifier() ?? -1)
        ) {
            setError(ErrorHTTP.NO_CHANGE());
        } else {
            setError(ErrorHTTP.NO_ERROR());
            setLoading(true);

            const param: any = {
                id: user.getAjoIdentifier(),
                firstname: firstname,
                lastname: lastname,
                email: email,
                email_send: emailSend,
                password_send: passwordSend,
                phone: phone,
                role_id: roleId,
                set: '',
            };

            if (check) {
                param['set'] = true;
            }

            post('/user/update', param).then((res: any) => {
                setLoading(false);
                if (Result.isSuccess(res)) {
                    AppManager.addToast(new Toast(errorManager.get(ErrorHTTP.SUCCESS()), 'success'));
                    props.handleClose();

                    Result.applyResult(res, user, true);
                    Result.applyResult(res, stateList, true);

                    if (stateList !== undefined) {
                        let i = 0;
                        while (i < stateList.get()!.size()) {
                            const user = stateList.get()!.get(i);
                            if (user instanceof Role) {
                                stateList.get()!.remove(i);
                                i--;
                            }
                            i++;
                        }
                    }
                } else {
                    setError(Result.getError(res));
                }
            });
        }
    };

    return (
        <MpModal
            done={'Modifier'}
            onClose={() => {
                props.handleClose();
            }}
            open={props.show}
            title={'Modifier ' + user.getFullName()}
            onSubmit={onDone}
        >
            {loading ? (
                <Box className="flex items-center justify-center flex-col p-12">
                    <Spinner color="blue" />
                </Box>
            ) : (
                <Box className="flex flex-col gap-3">
                    <Box className="flex flex-col gap-1">
                        <Typography className="pl-1 text-slate-600 !text-sm !font-semibold uppercase">
                            Prénom
                        </Typography>
                        <ComplexInput
                            placeholder="Prénom"
                            name="firstname"
                            value={firstname}
                            typeValue="string"
                            type="text"
                            setValue={setFirstname}
                        ></ComplexInput>
                    </Box>

                    <Box className="flex flex-col gap-1">
                        <Typography className="pl-1 text-slate-600 !text-sm !font-semibold uppercase">Nom</Typography>
                        <ComplexInput
                            placeholder="Nom"
                            name="lastname"
                            value={lastname}
                            typeValue="string"
                            type="text"
                            setValue={setLastname}
                        ></ComplexInput>
                    </Box>

                    <Box className="flex flex-col gap-1">
                        <Typography className="pl-1 text-slate-600 !text-sm !font-semibold uppercase">
                            Adresse email
                        </Typography>
                        <ComplexInput
                            placeholder="Adresse email"
                            name="email"
                            value={email}
                            typeValue="string"
                            type="text"
                            setValue={setEmail}
                        ></ComplexInput>
                    </Box>

                    <Box className="flex flex-col gap-1">
                        <Typography className="pl-1 text-slate-600 !text-sm !font-semibold uppercase">
                            Numéro de téléphone
                        </Typography>

                        <ComplexInput
                            placeholder="Numéro de téléphone"
                            name="phone"
                            value={phone}
                            typeValue="string"
                            type="text"
                            setValue={setPhone}
                        ></ComplexInput>
                    </Box>

                    <Box className="flex flex-col gap-1">
                        <Typography className="pl-1 text-slate-600 !text-sm !font-semibold uppercase">
                            Email d'envoie
                        </Typography>
                        <ComplexInput
                            placeholder="Email d'envoie"
                            name="email_send"
                            value={emailSend}
                            typeValue="string"
                            type="text"
                            setValue={setEmailSend}
                        ></ComplexInput>
                    </Box>

                    <Box className="flex flex-col gap-1">
                        <Typography className="pl-1 text-slate-600 !text-sm !font-semibold uppercase">
                            Mot de passe email d'envoie
                        </Typography>
                        <ComplexInput
                            placeholder="Mot de passe email d'envoie"
                            name="password_send"
                            value={passwordSend}
                            typeValue="string"
                            type="text"
                            setValue={setPasswordSend}
                        ></ComplexInput>
                    </Box>

                    {(AppManager.getUser() as User)!.root.get() && !(AppManager.getUser() as User)!.isMe(user) && (
                        <div
                            className={
                                'flex items-center justify-center ' +
                                ((user.first.get() || user.set.get()) && 'opacity-70')
                            }
                        >
                            <CheckBox
                                disabled={user.first.get() || user.set.get()}
                                id="set"
                                value={''}
                                checked={check}
                                onChange={() => updateCheck(!check)}
                                name="set"
                            />
                            <label htmlFor="set" className="ml-2 text-base text-gray-900 dark:text-gray-300">
                                {user.first.get() || user.set.get()
                                    ? "L'utilisateur doit déjà changé son mot de passe à la prochaine connexion."
                                    : 'Demander une réinitialisation du mot de passe.'}
                            </label>
                        </div>
                    )}

                    {!error.equals(ErrorHTTP.NO_ERROR()) && (
                        <Typography
                            component={'p'}
                            className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                        >
                            {errorManager.get(error)}
                        </Typography>
                    )}
                </Box>
            )}
        </MpModal>
    );
};
