import { AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import { CaretDownFill, CaretRightFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import AppManager from '../../commun/AppManager';
import { IconRow } from '../../commun/col/IconRow';
import { ProductCol } from '../../commun/col/ProduitCol';
import { Button } from '../../commun/component/Button';
import { Spinner } from '../../commun/component/Spinner';
import { post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import { Col } from '../../commun/table/Col';
import Table from '../../commun/table/Table';
import { TableView } from '../../commun/table/TableView';
import Utils from '../../commun/Utils';
import Devis from '../../object/Devis';
import User from '../../object/User';
import { SERVER_URL } from '../../utils/Constant';
import { DownloadSvg } from '../DownloadSvg';
import { EditSvg } from '../EditSvg';
import { Tag } from '../Tag';
import { TimerView } from '../TimerView';
import { TableHoraireActionDevisList } from './TableHoraireActionDevisList';
import { TableHoraireDevisStatList } from './TableHoraireDevisStatList';
import { TableHoraireSectionList } from './TableHoraireSectionList';
import { UserTimerModal } from './UserTimerModal';

interface IExportDevisHorraireButtonProps {
    devis: Devis;
}

const ExportDevisHorraireButton = (props: IExportDevisHorraireButtonProps) => {
    const { devis } = props;

    const [loading, setLoading] = useState(false);

    let res = undefined;
    if (loading) {
        res = <Spinner color="blue" />;
    } else {
        res = (
            <Button
                pb={1}
                pl={1}
                pr={1}
                pt={1}
                color="blue"
                onClick={() => {
                    setLoading(true);
                    post('/devis/export/stat', {
                        id: devis.getAjoIdentifier(),
                    }).then((res: any) => {
                        setLoading(false);
                        if (Result.isSuccess(res)) {
                            const link = document.createElement('a');
                            link.href = SERVER_URL + '/' + res['result']['where'];
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    });
                }}
            >
                <DownloadSvg className="w-5 h-5" />
            </Button>
        );
    }
    return res;
};

interface ITableHoraireDevisProps {
    list: Devis[];
    loading: boolean;
    current: Date;
}

export const TableHoraireDevis: React.FC<ITableHoraireDevisProps> = (props) => {
    const { list, loading, current } = props;

    const makeTable = () => {
        const table = new Table<Devis>('Devis');
        let nomCol = new Col<Devis>('Nom');
        nomCol.render = (elem: Devis) => {
            let res;
            res = (
                <Link to={'/devis/' + elem.getAjoIdentifier()}>
                    <p className={'font-semibold text-' + elem.getColorSpec() + '-700'}>{elem.name.get()}</p>
                </Link>
            );
            return (
                <div className="flex flex-row gap-2 items-center">
                    <div
                        className="flex items-center gap-1"
                        onClick={() => {
                            // Check if selectList of table contain there is elem
                            if (
                                table.selectList.find(
                                    (e: AJOObject) => elem.getAjoIdentifier() === e.getAjoIdentifier(),
                                ) !== undefined
                            ) {
                                table.removeFromSelectList(elem);
                            } else {
                                table.addToSelectList(elem);
                            }
                            AppManager.updater().makeUpdate();
                        }}
                    ></div>
                    {res}
                </div>
            );
        };
        table.addCol(nomCol);

        let clientCol = new Col<Devis>('Client');
        clientCol.render = (elem: Devis) => {
            let res;
            res = (
                <Link to={'/client/' + elem.getAjoIdentifier()}>
                    <p className={'font-medium text-' + elem.getColorSpec() + '-700'}>
                        {elem.client.get()?.name.get()}
                    </p>
                </Link>
            );
            return res;
        };
        table.addCol(clientCol);

        const productCol = new ProductCol<Devis>(table, []);
        productCol.name = 'Offre';
        table.addCol(productCol);

        let iconCol = new IconRow(table);
        table.addCol(iconCol);
        iconCol.type = 'string';
        iconCol.render = (elem: Devis) => {
            return (
                <div className="flex items-end justify-end">
                    <ExportDevisHorraireButton devis={elem} />
                </div>
            );
        };

        return table;
    };

    const [tableState, setTableState] = useState(new AJOState(makeTable()));
    tableState.setUpdate(setTableState);

    let table = tableState.get() as Table<Devis>;

    table.loading = loading;
    table.preventUpdate = true;
    table.bgFunc = (elem: Devis) => {
        return 'bg-' + elem.getColorSpec() + '-100';
    };
    table.list = list;

    table.subTable = true;
    table.subTableRender = (elem: Devis) => {
        let res = undefined;
        if (table.isSelect(elem)) {
            elem.getStat(() => {});
            res = <TableHoraireDevisStatList loading={elem.loadStat} color={elem.getColorSpec()} list={[elem]} />;
        }
        return res;
    };
    table.preventUpdate = false;

    return <TableView rounded={true} table={table}></TableView>;
};
