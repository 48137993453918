import { useState } from 'react';
import { Modal } from '../commun/modal/Modal';
import Note from '../object/Note';
import { LittleAddButton } from './LittleAddButton';
import { NoteElementView } from './NoteView';

export interface INoteListProps {
    noteList: Note[];
}
export const NoteList: React.FC<INoteListProps> = (props) => {
    const { noteList } = props;

    const [show, setShow] = useState(false);

    return (
        <div className="flex flex-col gap-2">
            {noteList.slice(0, 2).map((note: Note) => {
                return (
                    <div key={'note-' + note.getAjoIdentifier()}>
                        <NoteElementView note={note} />
                    </div>
                );
            })}
            {noteList.length == 0 && <p>Aucun commentaire</p>}
            {noteList.length > 2 && (
                <LittleAddButton
                    onAdd={() => {
                        setShow(true);
                    }}
                    text="Voir plus"
                />
            )}
            <Modal
                show={show}
                title="Notes"
                bottomMode="close"
                handleClose={() => {
                    setShow(false);
                }}
            >
                <div className="flex flex-row">
                    <p className="text-xl font-medium">Moyenne</p>
                    <p className="flex-1 text-xl font-medium text-right">3.7/5.0</p>
                </div>
                {noteList.map((note: Note) => {
                    return (
                        <div key={'note-' + note.getAjoIdentifier()}>
                            <NoteElementView note={note} />
                        </div>
                    );
                })}
            </Modal>
        </div>
    );
};
