import AppManager from '../commun/AppManager';
import { Color } from '../commun/Constant';
import { get } from '../commun/HTTP';
import Result from '../commun/Result';
import BasePrice from './BasePrice';
import Permission from './Permission';
import User from './User';

export default class Template extends BasePrice {
    public static _TYPE: string = 'Template';

    constructor() {
        super(Template._TYPE);
    }
    public isBlock(): boolean {
        return false;
    }
    public canAct(): boolean {
        return (AppManager.getUser() as User)?.root.get() === true;
    }
    public getColor(): Color {
        return 'green';
    }
    public getTitle(): Color {
        return this.name.get();
    }

    public static build(): Template {
        return new Template();
    }
    public get(id: any, callback: (res: any) => void = (res: any) => {}) {
        let obj = this;
        get('/template/get', {
            id: id,
        }).then((res: any) => {
            if (Result.isSuccess(res)) {
                Result.applyResult(res, obj, true);
                callback(res);
            }
        });
    }

    public isGoodVa() {
        let res = true;
        return res;
    }
}
