import { IObjectSelectable } from './../../component/select/ObjectSelect';
import { AJOObject, AJOProperties, AJOSimple } from 'mp-js-react-auto-json-object';
import { TagObject } from './TagObject';
import { Color } from '../../commun/Constant';
import Category from './Category';

export default class Product extends TagObject implements IObjectSelectable {
    public static _TYPE: string = 'Product';

    public min: AJOProperties<any>;
    public max: AJOProperties<any>;
    public category: AJOSimple<Category>;

    constructor() {
        super(Product._TYPE);

        this.min = new AJOProperties('min', this);
        this.max = new AJOProperties('max', this);
        this.category = new AJOSimple<Category>(Category, 'has_product_category', this);
    }

    public static build(): Product {
        return new Product();
    }

    public getSelectTitle(): string {
        return this.name.get() ?? '';
    }
    public getSelectDescription(): string {
        return '';
    }

    public override getColor(): Color {
        return (this.category.get()?.getColor() as Color) ?? 'blue';
    }
}
