import { AJOList, AJOObject, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import { useEffect, useState } from 'react';
import { get, post } from '../../commun/HTTP';
import { Modal } from '../../commun/modal/Modal';
import Result from '../../commun/Result';
import { Col } from '../../commun/table/Col';
import { PrestationList } from '../../component/PrestationList';
import { TypeListSelect } from '../../component/TypeListSelect';
import Category from '../../object/tag/Category';
import Label from '../../object/tag/Label';
import Prestation from '../../object/profile/Prestation';

export interface ISelectPrestationListModalProps {
    col: Col<any>;
}
export const SelectPrestationListModal: React.FC<ISelectPrestationListModalProps> = (props) => {
    const { col } = props;

    const [selectList, updateSelectList] = useState(col.filterList);
    const [loading, updateLoad] = useState(true);

    useEffect(() => {
        updateSelectList(col.filterList);
    }, [col.filterList]);

    const [stateList, updateStateList] = useState(new AJOState<AJOList<Prestation>>(new AJOList(Prestation)));
    stateList.setUpdate(updateStateList);

    useEffect(() => {
        updateLoad(true);
        get('/prestation/list', {}).then((res: any) => {
            updateLoad(false);
            Result.applyResult(res, stateList, true);
        });
    }, []);

    const onCheck = (e: Prestation) => {
        if (selectList.find((elem) => elem.getAjoIdentifier() == e.getAjoIdentifier()) != undefined) {
            updateSelectList(selectList.filter((elem) => elem.getAjoIdentifier() != e.getAjoIdentifier()));
        } else {
            updateSelectList([...selectList, e]);
        }
    };

    return (
        <Modal
            onDone={() => {
                col.filterList = selectList;
                col.closeModal();
            }}
            loading={loading}
            show={col.showFilterModal}
            done={'Sélectionner'}
            title={'Sélectionner des prestations'}
            color={'blue'}
            handleClose={() => col.closeModal()}
        >
            <div className="text-md font-normal">
                {stateList.get()!.getList().length !== 0 && (
                    <PrestationList
                        prestationList={stateList.get()!.getList()}
                        checkList={selectList as Prestation[]}
                        onCheck={onCheck}
                        checkView={true}
                    />
                )}
                {!loading && stateList.get()!.getList().length === 0 && <p>{'Aucune prestation'}</p>}
            </div>
        </Modal>
    );
};
