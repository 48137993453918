import AppManager from '../../commun/AppManager';
import { get } from '../../commun/HTTP';
import Result from '../../commun/Result';
import { AddressModal } from '../../modal/addess/AddressModal';
import Address from '../../object/Address';
import Permission from '../../object/Permission';
import { JSXInternal } from 'preact/src/jsx';
import { IObjectSelectProps, IObjectSelectState, ObjectSelect } from './ObjectSelect';

export interface IAddressSelectProps extends IObjectSelectProps<Address> {
    placeholder: string;
}

export class AddressSelect extends ObjectSelect<Address, IAddressSelectProps> {
    createGenericObject() {
        return Address;
    }

    constructor(props: IAddressSelectProps) {
        /**
         * DEFAULT PROPS
         */

        super(props, Permission.MANAGE_ADDRESS, 'address');

        /**
         * CREATE STATE
         */
        this.state = this.initState();

        // FUNCTION USE TO NOTIFY PARENT COMPONENT
        this.state.ajoList.setUpdate(() => this.forceUpdate());
    }

    fetchList() {
        if (this.state.isAjoListInflate) {
            get('/address/list').then((res: any) => {
                if (Result.isSuccess(res)) {
                    Result.applyResult(res, this.state.ajoList, true);
                }
                this.setState({
                    isAjoListInflate: true,
                });
            });
        }
    }

    renderModal(): JSXInternal.Element {
        const { ajoList } = this.state;
        if (ajoList != undefined) {
            AppManager.updater().add(ajoList);
        }
        const { elem } = this.state;
        let obj = this;
        return (
            <AddressModal
                address={elem ?? undefined}
                action={elem == null ? 'add' : 'edit'}
                onAddressChange={(id) => {
                    obj.onElemChange(id);
                }}
                onClose={() => this.setState({ showModal: false })}
                show={this.state.showModal}
                baseName={this.state.name}
            />
        );
    }

    render(): JSXInternal.Element {
        return super.render();
    }
}
