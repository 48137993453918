import { useEffect, useState } from 'react';
import { Color, COLOR_LIST } from '../commun/Constant';

export interface ISelectColorProps {
    onSelect: (color: Color) => void;
    color?: Color;
}

export const SelectColor: React.FC<ISelectColorProps> = (props) => {
    const { onSelect, color = 'blue' } = props;

    const [selectColor, updateSelectColor] = useState<Color>(color);

    useEffect(() => {
        updateSelectColor(color);
    }, [color]);

    return (
        <div>
            <div className="grid grid-cols-10 w-[35rem] gap-3">
                {COLOR_LIST.map((color) => {
                    return (
                        <div
                            key={color}
                            onClick={() => {
                                onSelect(color as Color);
                                updateSelectColor(color as Color);
                            }}
                            className={
                                'flex-1 relative aspect-square rounded-md bg-' +
                                color +
                                '-500 dark:bg-' +
                                color +
                                '-500'
                            }
                        >
                            {selectColor === color && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute bottom-0 right-0 p-1 h-7 w-7 text-white"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
