import { Box, Typography, Container, Button, Card, CardContent } from '@mui/material';
import { useState } from 'react';
import { Spinner } from 'src/commun/component/Spinner';
import { post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import { InsertPaperModal } from 'src/modal/vendor/InsertPaperModal';
import AppManager from '../../commun/AppManager';
import { EuroSpan } from '../../commun/component/EuroSpan';
import { PourcentSpan } from '../../commun/component/PourcentSpan';
import { AddIcon } from '../../icons/AddIcon';
import { BinIcon } from '../../icons/BinIcon';
import { EditIcon } from '../../icons/EditIcon';
import { StatistiqueIcon } from '../../icons/StatistiqueIcon';
import BasePrice from '../../object/BasePrice';
import Budget from '../../object/Budget';
import { Paper } from '../../object/Paper';
import { BudgetFillTable } from '../budget/BudgetFillTable';
import { BudgetTable } from '../budget/BudgetTable';
import { PaperFillTable } from '../budget/PaperFillTable';
import PaperModal from '../budget/PaperModal';
import { PaperStatView } from '../budget/PaperStatView';

export const DevisPrestationView = (props: { basePrice: BasePrice }) => {
    const { basePrice } = props;

    const [openAdd, setOpenAdd] = useState(false);
    const [openInsert, setOpenInsert] = useState(false);

    const paper = basePrice.getSelectedPaper() ?? new Paper();

    return (
        <Container maxWidth={false}>
            <Box sx={{ mb: 1.5, mt: 3 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: 3,
                        flexWrap: 'wrap',
                        m: -1,
                    }}
                >
                    <Typography sx={{ m: 1 }} variant="h4">
                        {'Feuille de prestation'}
                    </Typography>
                    {basePrice.canAct() && (
                        <Box sx={{ m: 1 }}>
                            <Button
                                color="primary"
                                variant="contained"
                                className="!pl-3 gap-2"
                                onClick={() => {
                                    setOpenAdd(true);
                                }}
                            >
                                <AddIcon />
                                Ajouter une feuille
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>

            <Box className="flex overflow-auto flex-wrap" sx={{ gap: 1.5, pt: 1.5, pb: 1.5 }}>
                {basePrice.paperList
                    .getList()
                    .filter((p: Paper) => {
                        return p.func.get() === 0 && p.archive.get() !== true;
                    })
                    .map((p: Paper, index: number) => {
                        return (
                            <PaperStatView
                                key={p.getAjoIdentifier()}
                                basePrice={basePrice}
                                selected={p.getAjoIdentifier() === paper.getAjoIdentifier()}
                                paper={p}
                            />
                        );
                    })}
                {basePrice.canAct() && (
                    <Card
                        sx={{ p: 3 }}
                        className="flex gap-5 flex-col items-center justify-center !min-w-[22rem] !max-w-[22rem]"
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            className="!pl-3 gap-2"
                            onClick={() => {
                                setOpenAdd(true);
                            }}
                        >
                            <AddIcon />
                            Ajouter une feuille
                        </Button>
                    </Card>
                )}
                <PaperModal
                    basePrice={basePrice}
                    paper={null}
                    func={0}
                    open={openAdd}
                    onClose={() => setOpenAdd(false)}
                />
            </Box>
            <Box sx={{ my: 3 }}>
                <Typography variant="h4">{paper.name.get()}</Typography>
            </Box>
            <Box>
                <PaperStatView paper={paper} detailed={true} basePrice={basePrice} selected={false} />
            </Box>
            <Box sx={{ mt: 3, mb: 3 }}>
                <Card>
                    <CardContent className="">
                        <Box className="flex gap-8 items-center flex-row">
                            <Box>
                                <Typography variant="h5">Prestation</Typography>
                            </Box>
                            {basePrice.canAct() && (
                                <Box>
                                    {paper.loadingAdd ? (
                                        <Spinner color="blue" />
                                    ) : (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className="!pl-3 gap-2"
                                            onClick={() => {
                                                paper.loadingAdd = true;
                                                AppManager.makeUpdate();
                                                post('/paper/' + basePrice.getAjoType().toLowerCase() + '/new_budget', {
                                                    id: paper.getAjoIdentifier(),
                                                    id_devis: basePrice.getAjoIdentifier(),
                                                }).then((res: any) => {
                                                    paper.loadingAdd = false;
                                                    if (Result.isSuccess(res)) {
                                                        Result.applyResult(res, AppManager.updater(), true);
                                                    }
                                                });
                                            }}
                                        >
                                            <AddIcon />
                                            Ajouter une prestation
                                        </Button>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Box className="shadow-xl">
                <BudgetFillTable
                    basePrice={basePrice}
                    paper={paper}
                    list={paper?.stricBudgetList.getList() ?? []}
                    loading={!paper.load}
                    color="white"
                />
            </Box>
            <Box sx={{ mt: 3, mb: 3 }}>
                <Card>
                    <CardContent className="">
                        <Box className="flex gap-8 items-center flex-row">
                            <Box>
                                <Typography variant="h5">Feuille jointe</Typography>
                            </Box>

                            {basePrice.canAct() && (
                                <Box>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className="!pl-3 gap-2"
                                        onClick={() => {
                                            setOpenInsert(true);
                                        }}
                                    >
                                        <AddIcon />
                                        Insérer une feuille
                                    </Button>
                                </Box>
                            )}

                            <InsertPaperModal
                                paper={paper}
                                template={basePrice}
                                handleClose={() => {
                                    setOpenInsert(false);
                                }}
                                show={openInsert}
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Card>
                <PaperFillTable
                    basePrice={basePrice}
                    paper={paper}
                    list={paper?.paperList.getList() ?? []}
                    loading={!paper.load}
                    color="white"
                />
            </Card>
            <Box sx={{ mb: '50vh' }}></Box>
        </Container>
    );
};
