import React from 'react';
import { JSXInternal } from 'preact/src/jsx';
import User from '../../object/User';
import ErrorHTTP from '../../commun/ErrorHTTP';
import Utils from '../../commun/Utils';
import { errorManager, userAddSuccess } from '../../modal/vendor/CategoryErrorManager';
import { Modal } from '../../commun/modal/Modal';
import { ComplexInput } from '../../commun/component/ComplexInput';
import { CustomDatePicker } from '../../commun/component/CustomDatePicker';
import { post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import AppManager from '../../commun/AppManager';
import Toast from '../../commun/toast/Toast';
import MpModal from '../extends/MpModal';
import { Spinner } from 'src/commun/component/Spinner';
import { Box, Typography } from '@mui/material';

export interface IUserTimerModalProps {
    show: boolean;
    user: User;
    handleClose: () => void;
}

export interface IUserTimerModalState {
    nbHeure: number;
    baseRecup: string;
    baseCp: string;
    startCalculRecup: Date | null;
    startCalculCp: Date | null;
    endCalcul: Date | null;
    error: ErrorHTTP;
    loading: boolean;
}
export class UserTimerModal extends React.Component<IUserTimerModalProps, IUserTimerModalState> {
    constructor(props: IUserTimerModalProps) {
        /**
         * DEFAULT PROPS
         */
        super(props);

        this.state = this.initState();
    }

    initState(): IUserTimerModalState {
        let res: IUserTimerModalState;
        let startCalculRecup: Date | null = null;
        if (this.props.user.start_calcul_recup.get() !== undefined) {
            startCalculRecup = new Date(this.props.user.start_calcul_recup.get());
        }

        let startCalculCp: Date | null = null;
        if (this.props.user.start_calcul_cp.get() !== undefined) {
            startCalculCp = new Date(this.props.user.start_calcul_cp.get());
        }

        let endCalcul: Date | null = null;
        if (this.props.user.end_calcul.get() !== undefined) {
            endCalcul = new Date(this.props.user.end_calcul.get());
        }
        res = {
            nbHeure: this.props.user.nb_heure.get(),
            baseRecup: this.props.user.base_recup.get() + '',
            baseCp: this.props.user.base_cp.get() + '',
            startCalculRecup: startCalculRecup,
            startCalculCp: startCalculCp,
            endCalcul: endCalcul,

            error: ErrorHTTP.NO_ERROR(),
            loading: false,
        };
        return res;
    }

    componentDidUpdate(
        prevProps: Readonly<IUserTimerModalProps>,
        prevState: Readonly<IUserTimerModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.show !== this.props.show) {
            this.setState(this.initState());
            Utils.blur();
        }
    }

    render(): JSXInternal.Element {
        const { show, handleClose } = this.props;
        const { loading, error } = this.state;

        let title: string = '';
        let done: string = '';

        title = 'Modifier un utilisateur';
        done = 'Modifier';

        return (
            <div className="absolute">
                <MpModal
                    overflow={false}
                    title={title}
                    onClose={handleClose}
                    onSubmit={() => {
                        this.setState({ loading: true, error: ErrorHTTP.NO_ERROR() });
                        post('/user/special_edit', {
                            id: this.props.user.getAjoIdentifier(),
                            nb_heure: this.state.nbHeure,
                            base_recup: parseFloat(this.state.baseRecup),
                            base_cp: parseFloat(this.state.baseCp),
                            start_calcul_recup: this.state.startCalculRecup?.toISOString() ?? undefined,
                            start_calcul_cp: this.state.startCalculCp?.toISOString() ?? undefined,
                            end_calcul: this.state.endCalcul?.toISOString() ?? undefined,
                        }).then((res: any) => {
                            this.setState({ loading: false });
                            if (Result.isSuccess(res)) {
                                Result.applyResult(res, AppManager.updater(), true);
                                AppManager.addToast(new Toast(errorManager.get(userAddSuccess), 'success'));
                                this.props.handleClose();
                            } else {
                                this.setState({
                                    error: Result.getError(res),
                                });
                            }
                        });
                    }}
                    done={done}
                    open={show}
                >
                    {loading ? (
                        <Box className="flex items-center justify-center p-10">
                            <Spinner color="blue" />
                        </Box>
                    ) : (
                        <Box className="flex flex-col gap-3">
                            <p>Contrat</p>
                            <ComplexInput
                                placeholder="Contrat"
                                typeValue="number"
                                value={this.state.nbHeure + ''}
                                setValue={(e) => {
                                    this.setState({ nbHeure: parseInt(e) });
                                }}
                            />

                            <p>Base récup (en heure)</p>
                            <ComplexInput
                                placeholder="Base récup"
                                typeValue="float"
                                value={this.state.baseRecup + ''}
                                setValue={(e) => {
                                    this.setState({ baseRecup: e });
                                }}
                            />

                            <p>Début du calcul de récup</p>
                            <CustomDatePicker
                                className=" w-full "
                                value={this.state.startCalculRecup}
                                onChange={(e) => {
                                    this.setState({ startCalculRecup: e });
                                }}
                            />

                            <p>Fin de contrat</p>
                            <CustomDatePicker
                                className=" w-full "
                                value={this.state.endCalcul}
                                onChange={(e) => {
                                    this.setState({ endCalcul: e });
                                }}
                            />

                            {!error.equals(ErrorHTTP.NO_ERROR()) && (
                                <Typography
                                    component={'p'}
                                    className="!text-sm text-red-600 !mt-4 !mb-1 !font-medium text-center"
                                >
                                    {errorManager.get(error)}
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/*this.state.force&&<p className="text-center w-full text-red-600 ">{this.getMessage()}</p>*/}
                </MpModal>
            </div>
        );
    }
}
