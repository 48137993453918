import { Box, TextField, Typography } from '@mui/material';
import { Component, createRef } from 'react';
import AppManager from '../../commun/AppManager';
import { Spinner } from '../../commun/component/Spinner';
import { post } from '../../commun/HTTP';
import Result from '../../commun/Result';
import Toast from '../../commun/toast/Toast';
import Category from '../../object/tag/Category';
import { getErrorMessage } from '../../utils/Error';
import MpModal, { IBaseModalProps } from '../extends/MpModal';
import { JSXInternal } from 'preact/src/jsx';

export interface IDelCategoryModalProps extends IBaseModalProps {
    category: Category;
}

export interface IDelCategoryModalState {
    loading: boolean;
    error: string;
}

export default class DelCategoryModal extends Component<IDelCategoryModalProps, IDelCategoryModalState> {
    inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: IDelCategoryModalProps) {
        super(props);

        this.state = this.initState();
        this.inputRef = createRef();
    }

    initState() {
        const p: IDelCategoryModalState = {
            loading: false,
            error: '',
        };
        return p;
    }

    componentDidUpdate(
        prevProps: Readonly<IDelCategoryModalProps>,
        prevState: Readonly<IDelCategoryModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.open !== this.props.open) {
            this.setState(this.initState());
        }
        if (prevProps.open !== this.props.open && this.props.open) {
            setTimeout(() => {
                this.inputRef.current?.focus();
            });
        }
    }

    addVendor() {
        this.setState({
            loading: true,
            error: '',
        });
        post('/category/update', {
            id_del: this.props.category.getAjoIdentifier(),
            full_delete: true,
        }).then((res: any) => {
            this.setState({
                loading: false,
            });
            if (Result.isSuccess(res)) {
                Result.applyResult(res, AppManager.updater(), true);
                this.props.onClose();
                AppManager.addToast(new Toast('La catégorie a été supprimée avec succès.', 'success'));
            } else {
                this.setState({
                    error: getErrorMessage(Result.getErrorStr(res)),
                });
            }
        });
    }

    render(): JSXInternal.Element {
        const { open, onClose } = this.props;
        const { loading, error } = this.state;
        return (
            <MpModal
                open={open}
                onClose={onClose}
                onSubmit={() => {
                    this.addVendor();
                }}
                doneColor="failure"
                title={'Supprimer une catégorie'}
                done={'Supprimer'}
            >
                {loading ? (
                    <Box className="flex items-center justify-center py-12">
                        <Spinner color="blue" className="" />
                    </Box>
                ) : (
                    <Box className="flex flex-col !text-gray-700 gap-3">
                        <Typography className="!font-medium">
                            Voulez-vous vraiment supprimer cette catégorie ?<br />
                            Cette action est irréversible.
                        </Typography>

                        {error !== '' && (
                            <Typography
                                component={'p'}
                                className="!text-sm text-red-600 !mt-1 !mb-1 !font-medium text-center"
                            >
                                {error}
                            </Typography>
                        )}
                    </Box>
                )}
            </MpModal>
        );
    }
}
