import { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DashboardSideBar } from './DashboardSideBar';
import AppManager from '../../commun/AppManager';

const DashboardLayoutRoot: any = styled('div')(({ theme }: { theme: any }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 280,
    },
}));

export const DashboardLayout = (props: any) => {
    const { children } = props;
    const [isSidebarOpen, setSidebarOpen] = useState(true);

    AppManager.get().openSideBar = () => setSidebarOpen(true);

    return (
        <div className="h-full">
            <DashboardLayoutRoot className="h-full">
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1 1 auto',
                        flexDirection: 'column',
                        minWidth: '100%',
                        height: '100%',
                    }}
                >
                    {children}
                </Box>
            </DashboardLayoutRoot>
            <DashboardSideBar onClose={() => setSidebarOpen(false)} open={isSidebarOpen} />
        </div>
    );
};
