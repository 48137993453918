import { AJOUpdater } from 'mp-js-react-auto-json-object';
import { ComplexSection } from '../../component/ComplexSection';
import { EditSvg } from '../../component/EditSvg';
import { Separator } from '../../component/Separator';
import { Tag } from '../../component/Tag';
import Vendor from '../../object/profile/Vendor';
import { Button } from './Button';
import {
    categoryAddSuccess,
    categoryRemoveSuccess,
    labelAddSuccess,
    labelRemoveSuccess,
    contactAddSuccess,
    contactRemoveSuccess,
    addressAddSuccess,
    addressRemoveSuccess,
    facturationAddSuccess,
    facturationRemoveSuccess,
    errorManager,
    prestationAddSuccess,
    productAddSuccess,
    productRemoveSuccess,
} from '../../modal/vendor/CategoryErrorManager';
import { useState } from 'react';
import { EditVendorModal } from '../../modal/vendor/VendorEditModal';
import ParentSevice from '../../object/profile/ParentSevice';
import Client from '../../object/profile/Client';
import { ProfilInterface } from '../../object/interface/ProfilInterface';

export interface IProfileLeftProps {
    profile: ParentSevice | Client;
    loading: boolean;
    canEdit: boolean;
}

export const ProfileLeft: React.FunctionComponent<IProfileLeftProps> = (props) => {
    const { profile, loading, canEdit } = props;

    const [show, updateShow] = useState(false);

    const getTitle = () => {
        if (profile instanceof Vendor) {
            return 'Fournisseur';
        } else if (profile instanceof Client) {
            return 'Client';
        } else {
            return 'Prestation';
        }
    };

    const getDetPosTitle = () => {
        if (profile instanceof Vendor) {
            return 'ce fournisseur';
        } else if (profile instanceof Client) {
            return 'ce client';
        } else {
            return 'cette prestation';
        }
    };
    const getDetTitle = () => {
        if (profile instanceof Vendor) {
            return 'un fournisseur';
        } else if (profile instanceof Client) {
            return 'un client';
        } else {
            return 'une prestation';
        }
    };
    return (
        <div className="w-72 rounded-lg flex flex-col gap-3 border border-slate-300 dark:border-gray-500 p-3 bg-white dark:bg-gray-800">
            <div className="flex items-center gap-2">
                <span className="text-lg font-semibold text-black dark:text-white">{profile.name.get()}</span>
                {canEdit && (
                    <Button
                        color="blue"
                        pb={1.5}
                        pt={1.5}
                        pr={1.5}
                        pl={1.5}
                        onClick={() => updateShow(true)}
                        className={'focus:ring-4 rounded-md text-black dark:text-white'}
                    >
                        <EditSvg className="h-5 w-5" />
                    </Button>
                )}
                <div className="flex flex-1 justify-end">
                    <Tag
                        canEdit={false}
                        canDelete={false}
                        color={(profile as ProfilInterface).getColor()}
                        title={(profile as ProfilInterface).getTitle()}
                    />
                </div>
            </div>

            {profile instanceof Vendor && profile.description.get() != undefined && profile.description.get() != '' && (
                <span className="text-black dark:text-white">{profile.description.get()}</span>
            )}

            {profile instanceof ParentSevice && <Separator></Separator>}

            {profile instanceof ParentSevice && (
                <ComplexSection
                    parent={profile}
                    title="Catégorie"
                    canAdd={false}
                    field="category"
                    addSuccessError={categoryAddSuccess}
                    delSuccessError={categoryRemoveSuccess}
                    titleDel="Supprimer une catégorie"
                    titleAdd="Ajouter une catégorie"
                    buttonAdd="Ajouter"
                    loading={loading}
                    textNothing={
                        "Aucune catégorie n'existe encore pour " +
                        getDetPosTitle() +
                        ", les catégories d'" +
                        getDetTitle() +
                        " sont l'ensemble des catégories des services faits."
                    }
                    textDelete={''}
                    list={profile.getCategoryList()}
                />
            )}

            {profile instanceof ParentSevice && <Separator></Separator>}

            {profile instanceof ParentSevice && (
                <ComplexSection
                    parent={profile}
                    title="Label"
                    field="label"
                    canAdd={false}
                    addSuccessError={labelAddSuccess}
                    delSuccessError={labelRemoveSuccess}
                    titleDel="Supprimer un label"
                    titleAdd="Ajouter un label"
                    buttonAdd="Ajouter"
                    loading={loading}
                    textNothing={
                        "Aucun label n'existe encore pour " +
                        getDetPosTitle() +
                        ", les catégories d'" +
                        getDetTitle() +
                        " sont l'ensemble des labels des services faits."
                    }
                    textDelete={'Êtes-vous sûr de vouloir supprimer ce label du fournisseur.'}
                    list={profile.getLabelList()}
                />
            )}

            {(profile instanceof Vendor || profile instanceof Client) && <Separator></Separator>}

            {(profile instanceof Vendor || profile instanceof Client) && (
                <ComplexSection
                    parent={profile}
                    title="Contact"
                    canAdd={true}
                    field="contact"
                    addSuccessError={contactAddSuccess}
                    delSuccessError={contactRemoveSuccess}
                    titleDel="Supprimer un contact"
                    titleAdd="Ajouter un contact"
                    buttonAdd="Ajouter"
                    loading={loading}
                    textNothing={
                        "Aucun contact n'existe encore pour ce " +
                        getTitle().toLowerCase() +
                        ', vous pouvez en ajouter un.'
                    }
                    textDelete={'Êtes-vous sûr de vouloir supprimer ce contact du ' + getTitle().toLowerCase() + '.'}
                    list={profile.contactList.getList()}
                />
            )}

            {(profile instanceof Vendor || profile instanceof Client) && <Separator></Separator>}

            {(profile instanceof Vendor || profile instanceof Client) && (
                <ComplexSection
                    parent={profile}
                    title="Adresse de facturation"
                    field="facturation"
                    canAdd={true}
                    addSuccessError={facturationAddSuccess}
                    delSuccessError={facturationRemoveSuccess}
                    titleDel="Supprimer l'adresse de facturation"
                    titleAdd={
                        profile.facturation.get() != undefined
                            ? "Modifier l'adresse de facturation"
                            : 'Ajouter une adresse de facturation'
                    }
                    buttonAdd={profile.facturation.get() != undefined ? 'Modifier' : 'Ajouter'}
                    loading={loading}
                    textNothing={
                        "Aucun adresse de facturation n'existe encore pour ce " +
                        getTitle().toLowerCase() +
                        ', vous pouvez en ajouter une.'
                    }
                    textDelete={
                        'Êtes-vous sûr de vouloir supprimer cette adresse de facturation du ' +
                        getTitle().toLowerCase() +
                        '.'
                    }
                    list={profile.facturation.get() != undefined ? [profile.facturation.get()] : []}
                />
            )}

            {canEdit && (
                <div className="absolute">
                    <EditVendorModal handleClose={() => updateShow(false)} show={show} profile={profile} />
                </div>
            )}
        </div>
    );
};
