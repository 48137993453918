import { Box } from '@mui/material';
import { AJOElement, AJOList, AJOObject, AJOState } from 'mp-js-react-auto-json-object';
import { useState } from 'react';
import AppManager from 'src/commun/AppManager';
import { ErrorText } from 'src/commun/component/ErrorText';
import { Spinner } from 'src/commun/component/Spinner';
import ErrorHTTP from 'src/commun/ErrorHTTP';
import { post } from 'src/commun/HTTP';
import Result from 'src/commun/Result';
import Toast from 'src/commun/toast/Toast';
import MpModal, { IModalProps } from 'src/component/extends/MpModal';
import User from 'src/object/User';
import { errorManager } from '../vendor/CategoryErrorManager';

export interface IRemoveUserModalProps {
    user: User;
    open: boolean;
    handleClose: () => void;
}

export const RemoveUserModal: React.FunctionComponent<IRemoveUserModalProps> = (props) => {
    const { user, ...rest } = props;
    const [error, setError] = useState<ErrorHTTP | null>(null);
    const [loading, setLoading] = useState(false);
    return (
        <MpModal
            doneColor="failure"
            done={'Supprimer'}
            title={"Retirer l'utilisateur du prix de vente"}
            open={props.open}
            onClose={props.handleClose}
            onSubmit={() => {
                setError(null);
                setLoading(true);
                post('/devis/remove/user', {
                    id_user: user.getAjoIdentifier(),
                    id: user.getAjoParent()?.getAjoParent()?.getAjoIdentifier(),
                }).then((result) => {
                    setLoading(false);
                    if (Result.isSuccess(result)) {
                        Result.applyResult(result, AppManager.updater(), true);
                        AppManager.addToast(
                            new Toast("L'utilisateur a été retiré du prix de vente avec succès.", 'success'),
                        );
                        props.handleClose();
                    } else {
                        setError(Result.getError(result));
                    }
                });
            }}
        >
            <Box className="flex flex-col gap-3">
                <p className="text-black dark:text-white">
                    {"Êtes-vous sûr de vouloir retirer l'utilisateur du prix de vente ?"}
                </p>

                {loading === true && (
                    <div className="flex items-center justify-center">
                        <Spinner color="red" />
                    </div>
                )}

                {error != null && <ErrorText topSpacing={2} manager={errorManager} error={error}></ErrorText>}
            </Box>
        </MpModal>
    );
};
