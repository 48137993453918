import { AJOList, AJOObject, AJOUpdater } from 'mp-js-react-auto-json-object';
import { ComplexInput } from '../../commun/component/ComplexInput';
import { Color } from '../../commun/Constant';
import ErrorHTTP from '../../commun/ErrorHTTP';
import { JSXInternal } from 'preact/src/jsx';
import Utils from '../../commun/Utils';
import { SelectColor } from '../../component/SelectColor';
import CategoryEvent from '../../object/tag/CategoryEvent';
import { ActionModal, IActionModalProps, IActionModalState } from '../base/ActionModal';
import {
    agenceAddSuccess,
    agenceEditSuccess,
    categoryAddSuccess,
    categoryEditSuccess,
    errorManager,
} from '../vendor/CategoryErrorManager';

export interface ICategoryEventModalProps extends IActionModalProps<CategoryEvent> {
    parent?: AJOObject;
    action: 'add' | 'edit';
}

export interface ICategoryEventModalState extends IActionModalState {
    name: string;
    color: Color;
}

export class CategoryEventModal extends ActionModal<CategoryEvent, ICategoryEventModalProps, ICategoryEventModalState> {
    constructor(props: ICategoryEventModalProps) {
        /**
         * DEFAULT PROPS
         */
        super(props, agenceAddSuccess, agenceEditSuccess);

        this.state = this.initState();
    }

    getSuccess() {
        const { action } = this.props;
        let res: ErrorHTTP;
        if (action === 'add') {
            res = categoryAddSuccess;
        } else {
            res = categoryEditSuccess;
        }
        return res;
    }

    initState(): ICategoryEventModalState {
        const { action, elem, baseName } = this.props;
        let res: ICategoryEventModalState;
        if (action === 'add') {
            res = {
                name: baseName ?? '',
                color: 'blue',
                error: ErrorHTTP.NO_ERROR(),
                loading: false,
            };
        } else {
            res = {
                name: elem?.name.get() ?? '',
                color: elem?.getColor() ?? 'blue',
                error: ErrorHTTP.NO_ERROR(),
                loading: false,
            };
        }
        return res;
    }

    getUrl(): string {
        const { parent } = this.props;
        let res: string;
        if (parent === undefined) {
            res = '/category_event/update';
        } else {
            res = '/' + parent.getAjoType().toLowerCase() + '/update';
        }
        return res;
    }

    getSendParams(): { [key: string]: any } {
        const { parent, action } = this.props;
        let params: { [key: string]: any } = {};

        if (parent === undefined) {
            params = { ...this.state };
        } else {
            params = {
                id: parent.getAjoIdentifier(),
                category: { ...this.state },
            };
        }
        if (action === 'edit') {
            params['id'] = this.props.elem!.getAjoIdentifier();
        }
        return params;
    }

    componentDidUpdate(
        prevProps: Readonly<ICategoryEventModalProps>,
        prevState: Readonly<ICategoryEventModalState>,
        snapshot?: any,
    ): void {
        if (prevProps.show !== this.props.show) {
            this.setState(this.initState());
            if (this.props.show) {
                Utils.blur();
            }
        }
    }

    render(): JSXInternal.Element {
        return super.render();
    }

    contentModal(): JSXInternal.Element | JSXInternal.Element[] {
        const { color, name } = this.state;
        return [
            <ComplexInput
                key={'0'}
                typeValue="string"
                setValue={(name) => {
                    this.setState({ name: name });
                }}
                value={name}
                placeholder={this.props.placeholder ?? ''}
            />,
            <div className="flex flex-col gap-4" key={'1'}>
                <SelectColor
                    color={color}
                    onSelect={(color) => {
                        this.setState({ color: color });
                    }}
                />
            </div>,
        ];
    }

    getTitle(): string {
        const { action } = this.props;
        let res: string;
        if (action === 'add') {
            res = 'Ajouter une categorie';
        } else {
            res = 'Modifier une categorie';
        }
        return res;
    }

    getDone(): string {
        const { action } = this.props;
        let res: string;
        if (action === 'add') {
            res = 'Ajouter';
        } else {
            res = 'Modifier';
        }
        return res;
    }
    public createGeneric() {
        return CategoryEvent;
    }
}
