import { AJOProperties } from 'mp-js-react-auto-json-object';
import { AJOObject } from 'mp-js-react-auto-json-object';
export class Group extends AJOObject {
    public static _TYPE: string = 'Group';

    public name: AJOProperties<any>;
    public num: AJOProperties<any>;

    constructor() {
        super(Group._TYPE);

        this.name = new AJOProperties('name', this);
        this.num = new AJOProperties('num', this);
    }

    public static build(): Group {
        return new Group();
    }
}
