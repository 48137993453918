import { AJOElement, AJOObject, AJOState, AJOUpdater } from 'mp-js-react-auto-json-object';
import AppManager from './AppManager';
import ErrorHTTP from './ErrorHTTP';

export default class Result {
    public static getStatus(response: any): string {
        return response['status'];
    }
    public static getError(response: any): ErrorHTTP {
        return new ErrorHTTP(response['error']);
    }
    public static getErrorStr(response: any): string {
        return response['error'];
    }
    public static isSuccess(response: any): boolean {
        return Result.getStatus(response) === 'success';
    }
    public static getResult(response: any) {
        let data = response['result'];
        if (data == undefined) {
            data = {};
        }
        return data;
    }
    public static applyResult(
        result: any,
        ajoElement: AJOElement | AJOState<AJOElement> | AJOUpdater | null | undefined,
        update: boolean = false,
        reverse: boolean = false,
    ) {
        AppManager.get().globalCallBack(result);
        if (ajoElement != null && ajoElement !== undefined) {
            let data = Result.getResult(result);
            if (data == undefined) {
                data = {};
            }
            if (data instanceof Array) {
                for (let i = 0; i < data.length; i++) {
                    Result.applyResult(data[i], ajoElement, update);
                }
            } else {
                let props = Object.keys(data).sort();
                for (let i = 0; i < props.length; i++) {
                    if (reverse) {
                        if (data[props[i]] instanceof Array) {
                            for (let j = 0; j < data[props[i]].length; j++) {
                                if (reverse) {
                                    let key = Object.keys(data[props[i]][j]);
                                    for (let k = 0; k < key.length; k++) {
                                        if (data[props[i]][j][key[k]] instanceof Array) {
                                            let array = data[props[i]][j][key[k]];
                                            for (let l = 0; l < array.length; l++) {
                                                let base = { ...array[l] };

                                                base[key[k]] = [{ ...data[props[i]][j] }];
                                                delete base[key[k]][0][key[k]];

                                                const resUpdate = ajoElement.applyData(base);
                                                ajoElement.makeUpdate(update || resUpdate);
                                            }
                                        }
                                    }
                                }
                                const resUpdate = ajoElement.applyData(data[props[i]][j]);
                                ajoElement.makeUpdate(update || resUpdate);
                            }
                        }
                    }
                    const resUpdate = ajoElement.applyData(data[props[i]]);
                    ajoElement.makeUpdate(update || resUpdate);
                }
                let count = 10;
                while (ajoElement.count() !== 0 && count > 0) {
                    for (let i = 0; i < props.length; i++) {
                        const resUpdate = ajoElement.applyData(data[props[i]]);
                        ajoElement.makeUpdate(update || resUpdate);
                    }
                    count--;
                }
            }
        }
        ajoElement?.clear();
    }
}
